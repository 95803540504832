import { getIsoWeek, getIsoWeekYear, getQuarter, isValidDate } from '../../../utils/date-utils';
import { objectKeys } from 'aim-utils';

export interface DateChange {
  year?: string;
  isoYear?: string;
  month?: string;
  isoWeek?: string;
  quarter?: string;
  day?: string;
}

type LabelIndex = number;

export type DateChanges = Record<LabelIndex, DateChange>;

interface DateChangeParameters {
  date: Date;
  previousDate: Date | undefined;
}

const monthFormatter = new Intl.DateTimeFormat('en', { month: 'short' });
const dayFormatter = new Intl.DateTimeFormat('en', { day: '2-digit' });

const getYearChange = ({ date, previousDate }: DateChangeParameters): string | undefined => {
  const year = date.getFullYear();
  const previousYear = previousDate && isValidDate(previousDate) && previousDate.getFullYear();

  return year !== previousYear ? year.toString() : undefined;
};

const getIsoYearChange = ({ date, previousDate }: DateChangeParameters): string | undefined => {
  const year = getIsoWeekYear(date);
  const previousYear = previousDate && isValidDate(previousDate) && getIsoWeekYear(previousDate);

  return year !== previousYear ? year.toString() : undefined;
};

const getQuarterChange = ({ date, previousDate }: DateChangeParameters): string | undefined => {
  const quarter = getQuarter(date);
  const previousQuarter = previousDate && isValidDate(previousDate) && getQuarter(previousDate);
  const yearChange = getYearChange({ date, previousDate });

  return yearChange || quarter !== previousQuarter ? `Q${quarter}` : undefined;
};

const getMonthChange = ({ date, previousDate }: DateChangeParameters): string | undefined => {
  const month = monthFormatter.format(date);
  const previousMonth = previousDate && isValidDate(previousDate) && monthFormatter.format(previousDate);
  const yearChange = getYearChange({ date, previousDate });

  return yearChange || month !== previousMonth ? month : undefined;
};

const getIsoWeekChange = ({ date, previousDate }: DateChangeParameters): string | undefined => {
  const week = getIsoWeek(date);
  const previousWeek = previousDate && isValidDate(previousDate) && getIsoWeek(previousDate);
  const monthChange = getMonthChange({ date, previousDate });

  return monthChange || week !== previousWeek ? week.toString() : undefined;
};

const getDayChange = ({ date, previousDate }: DateChangeParameters): string | undefined => {
  const day = dayFormatter.format(date);
  const previousDay = previousDate && isValidDate(previousDate) && dayFormatter.format(previousDate);
  const monthChange = getMonthChange({ date, previousDate });

  return monthChange || day !== previousDay ? day : undefined;
};

type DateChangeCacheKey = `${number}-${number}`;
// * We cache the date changes to avoid recalculating them for the same date pair. The calculation is somewhat expensive, but more importantly, can happen very frequently.
const dateChangeCache = new Map<DateChangeCacheKey, DateChange>();
// * We limit the cache size to avoid memory leaks. Likely not necessary, but for safety.
const CACHE_MAX_SIZE = 10_000;

/**
 * @returns information about date changes, e.g. changes in years, months, etc. that occur between ticks.
 *
 * This information is, for instance, used to determine if the year should be included for a certain label or not.
 */
export const getDateChanges = (labels: Date[]) => {
  const changes: DateChanges = {};

  for (let i = 0; i < labels.length; i++) {
    const tickDate = labels[i];
    if (!isValidDate(tickDate)) continue;

    const previousTickDate = i > 0 ? labels[i - 1] : undefined;
    const cacheKey = `${tickDate.getTime()}-${previousTickDate?.getTime() ?? 0}` as const;

    const change = (() => {
      const cachedChange = dateChangeCache.get(cacheKey);
      if (cachedChange) return cachedChange;

      const dateChangeParameters = { date: tickDate, previousDate: previousTickDate };

      const change = {
        year: getYearChange(dateChangeParameters),
        isoYear: getIsoYearChange(dateChangeParameters),
        quarter: getQuarterChange(dateChangeParameters),
        month: getMonthChange(dateChangeParameters),
        isoWeek: getIsoWeekChange(dateChangeParameters),
        day: getDayChange(dateChangeParameters),
      };

      objectKeys(change).forEach((key) => {
        // * Remove undefined values to save space in the cache.
        if (change[key] === undefined) delete change[key];
      });

      if (dateChangeCache.size >= CACHE_MAX_SIZE) {
        dateChangeCache.delete(dateChangeCache.keys().next().value);
      }

      dateChangeCache.set(cacheKey, change);

      return change;
    })();

    if (Object.keys(change).length > 0) {
      changes[i] = change;
    }
  }

  return changes;
};
