import { interpolatePath } from 'd3-interpolate-path';
import { useEffect, useMemo } from 'react';
import { line, scale } from '../utils';
import { animated, to, useSpring } from '@react-spring/web';
interface AnimatedStackProps {
  width: number;
  fromData: number[];
  toData: number[];
  fill: string;
  duration: number;
  startTrigger: number;
}

export const AnimatedStack = ({ width, fromData, toData, fill, duration, startTrigger }: AnimatedStackProps) => {
  const scaleX = scale(fromData.length, width);

  const fromPath = line(fromData.map((y, i) => [scaleX(i), -y]));
  const toPath = line(toData.map((y, i) => [scaleX(i), -y]));

  const pathInterpolator = useMemo(() => {
    if (fromPath && toPath) {
      return interpolatePath(fromPath, toPath);
    }
  }, [fromPath, toPath]);

  const [spring, api] = useSpring(
    () => ({
      config: {
        duration: duration,
      },
      from: { t: 0 },
    }),
    [],
  );

  useEffect(() => {
    if (startTrigger === 1) {
      api.start({ from: { t: 0 }, to: [{ t: 1 }, { t: 0 }] });
    }
  }, [api, startTrigger]);

  if (!pathInterpolator) return null;

  return <animated.path d={to(spring.t, pathInterpolator)} fill={fill} />;
};
