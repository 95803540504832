import type { ComponentProps } from 'react';
import type { DatePickerProps } from '../DatePicker';
import { Button } from '../../Button';
import styles from './DatePickerButton.module.css';
import { getDatePickerButtonLabel } from './utils';

type DatePickerButtonProps = DatePickerProps & {
  onClick: ComponentProps<typeof Button>['onClick'];
  placeholder?: string;
};

export const DatePickerButton = ({ onClick, placeholder, ...props }: DatePickerButtonProps) => {
  const buttonLabel = getDatePickerButtonLabel(props);

  return (
    <Button type='tertiary' color='white' onClick={onClick} startIcon='calendar' className={styles.datePickerButton}>
      {buttonLabel ?? placeholder}
    </Button>
  );
};
