import type { Option } from '../../Input/Select/Select';
import { Legend } from '../Legend';
import type { ChartDataset } from '../types';

export const mapToOptionWithIcon = <TDatasetId extends string>(item: ChartDataset<TDatasetId>): Option<TDatasetId> => ({
  label: item.label,
  value: item.datasetId,
  icon: Legend.getLegendIconFromDataset(item),
});

const sortOptions = (a: Option, b: Option): number => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
};

export const areEqualOptions = (a: Option, b: Option): boolean => {
  return a?.value === b?.value && a?.label === b?.label && a?.tab === b?.tab && a?.id === b?.id;
};

export const getOptionArrayIntersection = (optionsA: Option[] = [], optionsB: Option[] = []): Option[] => {
  return optionsA.filter((a) => optionsB.some((b) => areEqualOptions(a, b)));
};

export const getInitialDatasetOptions = <TDatasetId extends string = string>(
  datasets: ChartDataset<TDatasetId>[] = [],
  singleDatasetOption?: boolean,
  sortDatasetOptions?: (a: Option, b: Option) => number,
): { allOptions: Option<TDatasetId>[]; preselectedOptions: Option<TDatasetId>[] } => {
  const shouldDisplayDataOption = (set: ChartDataset): boolean => {
    return (
      (set.type === 'line' ||
        set.type === 'mountain' ||
        set.type === 'stackedLine' ||
        set.type === 'band' ||
        (set.type === 'bar' && set.data.length > 0)) &&
      set.filterable !== false
    );
  };

  const datasetsToShow = datasets.filter(shouldDisplayDataOption) ?? [];
  const allOptions: Option<TDatasetId>[] =
    datasetsToShow.map((d) => ({ ...mapToOptionWithIcon(d), tab: d.tab, id: d.id })) ?? [];

  if (sortDatasetOptions instanceof Function) {
    allOptions.sort(sortDatasetOptions);
  }

  const preselectedOptions = singleDatasetOption
    ? allOptions.length > 0
      ? [allOptions.sort(sortOptions)[0]]
      : []
    : datasetsToShow
        .filter((set) => set.preselected !== false)
        .map((d) => ({ ...mapToOptionWithIcon(d), tab: d.tab, id: d.id }));

  return { allOptions, preselectedOptions };
};
