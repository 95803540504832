import { AnimatedBar } from './AnimatedBar';
import { useEffect, useMemo } from 'react';
import type { ChartAnimationProps } from '../MissingDataChartAnimation';
import { isLarge } from '../utils';
import { animated, useTrail } from '@react-spring/web';
import { LineColors } from 'aim-utils';

export const BarChartAnimation = ({ size, svgHeight, svgWidth }: ChartAnimationProps) => {
  const padding = 10;

  const bars = useMemo(
    () => [
      { fromHeight: 10, toHeight: isLarge(size) ? 60 : 30, fill: LineColors.magma },
      { fromHeight: 10, toHeight: isLarge(size) ? 80 : 40, fill: LineColors.azure },
      { fromHeight: 10, toHeight: isLarge(size) ? 40 : 15, fill: LineColors['vibrant-blue'] },
    ],
    [size],
  );
  const barWidth = (svgWidth - bars.length * padding) / bars.length;

  const Animated = animated(AnimatedBar);

  const [trails, api] = useTrail(
    bars.length,
    () => {
      return {
        from: { startTrigger: 0 },
        to: { startTrigger: 1 },
        config: {
          duration: 1000,
          mass: 5,
          tension: 500,
          friction: 700,
        },
      };
    },
    [],
  );

  useEffect(() => {
    const interval = setInterval(
      () => api.start({ from: { startTrigger: 0 }, to: { startTrigger: 1 } }),
      isLarge(size) ? 3000 : 9000,
    );

    return () => clearInterval(interval);
  }, [api, size]);

  return (
    <svg width={svgWidth} height={svgHeight}>
      <g transform={`translate(0,${svgHeight})`}>
        {trails.map(({ startTrigger }, i) => (
          <g transform={`translate(${i * (padding + barWidth)},0)`} key={i}>
            <Animated
              width={barWidth}
              fromHeight={bars[i].fromHeight}
              toHeight={bars[i].toHeight}
              fill={bars[i].fill}
              duration={isLarge(size) ? 800 : 1500}
              startTrigger={startTrigger}
            />
          </g>
        ))}
      </g>
    </svg>
  );
};
