import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';
import styles from './ButtonGroup.module.css';
import { Label } from '../Input/Label';
import { Typography } from '../Typography';

export interface ButtonGroupProps<TTitle extends string = string> {
  onClick?: (title: TTitle) => void;
  disabled?: boolean;
  titles: TTitle[] | readonly TTitle[];
  primaryTitle?: string;
  label?: string | ReactNode;
  hint?: string;
}

export function ButtonGroup<TTitle extends string = string>({
  onClick,
  disabled,
  titles,
  primaryTitle,
  label,
  hint,
}: ButtonGroupProps<TTitle>): ReactElement {
  return (
    <div>
      {typeof label === 'string' ? <Label label={label} disabled={disabled} /> : label}
      <div className={styles.container}>
        {titles.map((title, i) => {
          return (
            <button
              key={title + i}
              onClick={() => onClick && onClick(title)}
              disabled={disabled}
              className={cn(
                styles.button,
                primaryTitle === title ? styles.primary : styles.secondary,
                disabled && styles.disabled,
              )}
              type='button'
              aria-pressed={title === primaryTitle}
            >
              {title}
            </button>
          );
        })}
      </div>
      {hint && (
        <div className={styles.hint}>
          <Typography variant='text2' color='secondary'>
            {hint}
          </Typography>
        </div>
      )}
    </div>
  );
}
