import type { BandSeriesDataLabelProvider, IRenderableSeries, TSciChart, FastLineRenderableSeries } from 'scichart';
import {
  FastBandRenderableSeries,
  FastColumnRenderableSeries,
  SmoothStackedMountainRenderableSeries,
  SplineLineRenderableSeries,
  XyDataSeries,
  XyyBezierRenderDataTransform,
} from 'scichart';
import {
  BezierRenderDataTransform,
  EllipsePointMarker,
  FastMountainRenderableSeries,
  LineSeriesDataLabelProvider,
  LineSeriesHitTestProvider,
  XyScatterRenderableSeries,
  XyyDataSeries,
} from 'scichart';
import type { BandDataset, BarDataset, ChartDataset, LineDataset, MountainDataset, SeriesMetadataMap } from '../types';
import { isStackedMountainCollection } from './type-guards';
import { getUnixTimestampInSeconds, padArray } from './data-utils';
import type { MutableRefObject } from 'react';
import { ColorSpectrum, addOpacityToHexColor } from 'aim-utils';
import { HOVERED_POINT_MARKER_STYLE } from '../hooks/hover.hooks';

export const defaultStrokeThickness = 2;
export const hoveredStrokeThickness = 3;

export const createStackedXyDataSeries = (ctx: TSciChart, xValues: Array<number>, dataset: ChartDataset) => {
  return new XyDataSeries(ctx, {
    xValues,
    yValues: padArray({
      array: dataset.data.map((value) => value.y ?? Number.NaN),
      length: xValues.length,
      fillWith: Number.NaN,
    }),
    dataSeriesName: dataset.label,
    isSorted: true,
    dataIsSortedInX: true,
  });
};

export const createXyDataSeries = (ctx: TSciChart, dataset: ChartDataset | undefined) => {
  if (!dataset) {
    return new XyDataSeries(ctx, { xValues: [], yValues: [] });
  }
  return new XyDataSeries(ctx, {
    xValues: dataset.data.map((value) => getUnixTimestampInSeconds(value.x)),
    yValues: dataset.data.map((value) => (value.y !== null ? value.y : Number.NaN)),
    dataSeriesName: dataset.label,
    isSorted: true,
    dataIsSortedInX: true,
  });
};

export const createXyDataSeriesForDataLabelsPoints = (ctx: TSciChart, dataset: ChartDataset | undefined) => {
  if (!dataset) {
    return new XyDataSeries(ctx, { xValues: [], yValues: [] });
  }
  const indexForDataPoints = getIndexForDataPoints(dataset);
  return new XyDataSeries(ctx, {
    xValues: dataset.data
      .map((value) => getUnixTimestampInSeconds(value.x))
      .filter((_, i) => indexForDataPoints.includes(i)),
    yValues: dataset.data
      .map((value) => (value.y !== null ? value.y : Number.NaN))
      .filter((_, i) => indexForDataPoints.includes(i)),
    dataSeriesName: dataset.label,
    isSorted: true,
    dataIsSortedInX: true,
  });
};

const isPoint = (data: ChartDataset['data'], index: number) => {
  if (data.length < 2) return true;
  if (index === 0 && data[1].y === null) {
    return true;
  }
  if (index === data.length - 1 && data[data.length - 2].y === null) {
    return true;
  }

  return (!data[index - 1] || data[index - 1].y === null) && (!data[index + 1] || data[index + 1].y === null);
};

const getIndexForDataPoints = (dataset: ChartDataset) => {
  const indicesForDataLables = dataset.dataLabels?.map((item) => {
    if (typeof item === 'number') {
      return item;
    }
    return item.index;
  });

  const indexForDataPoints: Array<number> = [];

  dataset.data.map((value, index) => {
    if ((value.y !== null && isPoint(dataset.data, index)) || indicesForDataLables?.includes(index)) {
      // This is to handle the zeroline when we add redo color for areas under the zeroline
      indexForDataPoints.push(index);
    }
  });

  return indexForDataPoints;
};

export const createXyyDataSeries = (ctx: TSciChart, dataset: ChartDataset | undefined) => {
  if (!dataset) {
    return new XyyDataSeries(ctx, { xValues: [], yValues: [], y1Values: [] });
  }

  return new XyyDataSeries(ctx, {
    xValues: dataset.data.map((value) => getUnixTimestampInSeconds(value.x)),
    yValues: dataset.data.map((value) => (value.y !== null ? value.y : Number.NaN)),
    y1Values: dataset.data.map(() => 0),
    dataSeriesName: dataset.label,
    isSorted: true,
    dataIsSortedInX: true,
  });
};

export const createStackedMountainRenderableSeries = (
  ctx: TSciChart,
  dataSeries: XyDataSeries,
  dataset: ChartDataset,
  seriesMetadataRef: React.MutableRefObject<SeriesMetadataMap>,
) => {
  const series = new SmoothStackedMountainRenderableSeries(ctx, {
    fill: dataset.color,
    stroke: dataset.color,
    strokeThickness: dataset.strokeThickness ?? defaultStrokeThickness,
    dataSeries,
  });

  seriesMetadataRef.current.set(series.id, { dataset });
  return series;
};

export const barGradient = (color: string) => {
  return {
    gradientStops: [
      { color: addOpacityToHexColor(color, 0.7), offset: 0.1 },
      { color: addOpacityToHexColor(color, 0.3), offset: 1 },
    ],
    startPoint: { x: 0, y: 0 },
    endPoint: { x: 0, y: 0.9 },
  };
};

export const createBarRenderableSeries = (
  ctx: TSciChart,
  dataSeries: XyDataSeries,
  dataset: ChartDataset,
  seriesMetadataRef: React.MutableRefObject<SeriesMetadataMap>,
) => {
  const series = new FastColumnRenderableSeries(ctx, {
    strokeThickness: 0,
    opacity: 0.8,
    fillLinearGradient: barGradient(dataset.color),
    fill: addOpacityToHexColor(dataset.color, 0.3),
    dataSeries,
    yAxisId: dataset.yAxisId,
    dataPointWidth: 0.8,
  });

  seriesMetadataRef.current.set(series.id, { dataset });
  return series;
};

export const createSplineLineRenderableSeries = (
  ctx: TSciChart,
  dataSeries: XyDataSeries,
  dataset: LineDataset,
  seriesMetadataRef: React.MutableRefObject<SeriesMetadataMap>,
) => {
  const series = new SplineLineRenderableSeries(ctx, {
    stroke: dataset.color,
    strokeThickness: dataset.strokeThickness ?? defaultStrokeThickness,
    dataSeries,
    yAxisId: dataset.yAxisId,
    strokeDashArray: dataset.isDotted ? [2, 4] : [],
  });

  seriesMetadataRef.current.set(series.id, { dataset });
  return series;
};

export const createMountainLineRenderableSeries = (
  ctx: TSciChart,
  dataSeries: XyDataSeries,
  dataset: MountainDataset,
  seriesMetadataRef: React.MutableRefObject<SeriesMetadataMap>,
) => {
  const series = new FastMountainRenderableSeries(ctx, {
    stroke: dataset.color,
    strokeThickness: dataset.strokeThickness ?? defaultStrokeThickness,
    dataSeries,
    yAxisId: dataset.yAxisId,
    fillLinearGradient: {
      gradientStops: [
        { color: dataset.color, offset: 0.0 },
        { color: 'Transparent', offset: 1 },
      ],
      startPoint: { x: 0, y: 0 },
      endPoint: { x: 0, y: 1 },
    },
    zeroLineY: Number.MIN_SAFE_INTEGER,
    fill: dataset.color,
  });

  // remove points in data series based on displayPoint in metadata
  const pointDataSeries = createXyDataSeriesForDataLabelsPoints(ctx, dataset);

  const points = new XyScatterRenderableSeries(ctx, {
    stroke: dataset.color,
    strokeThickness: dataset.strokeThickness ?? defaultStrokeThickness,
    dataSeries: pointDataSeries,
    yAxisId: dataset.yAxisId,
    pointMarker: new EllipsePointMarker(ctx, { ...HOVERED_POINT_MARKER_STYLE, fill: dataset.color }),
  });

  // Add to make hover only possible on the line, not the entire area
  series.hitTestProvider = new LineSeriesHitTestProvider(series as unknown as FastLineRenderableSeries, ctx);

  // custom bezeir curve
  const bezierTransform = new BezierRenderDataTransform(series, ctx, [series.drawingProviders[0]]);
  bezierTransform.interpolationPoints = 15;
  series.renderDataTransform = bezierTransform;

  seriesMetadataRef.current.set(series.id, { dataset });
  seriesMetadataRef.current.set(points.id, { dataset, excludeFromTooltip: true });
  return [series, points];
};

export const createSplineBandRenderableSeries = (
  ctx: TSciChart,
  dataSeries: XyyDataSeries,
  dataset: BandDataset,
  seriesMetadataRef: React.MutableRefObject<SeriesMetadataMap>,
) => {
  const series = new FastBandRenderableSeries(ctx, {
    stroke: dataset.color,
    strokeThickness: dataset.strokeThickness ?? defaultStrokeThickness,
    dataSeries,
    yAxisId: dataset.yAxisId,
    fill: addOpacityToHexColor(ColorSpectrum['vivid-red']['500'], 0.3),
    fillY1: addOpacityToHexColor(dataset.color, 0.2),
    strokeY1: 'transparent',
  });

  // Fix bezier curve for band series
  series.renderDataTransform = new XyyBezierRenderDataTransform(series, ctx, [series.drawingProviders[0]], {
    interpolationPoints: 20,
    curvature: 0.3,
  });

  const pointDataSeries = createXyDataSeriesForDataLabelsPoints(ctx, dataset);
  const points = new XyScatterRenderableSeries(ctx, {
    stroke: dataset.color,
    strokeThickness: dataset.strokeThickness ?? defaultStrokeThickness,
    dataSeries: pointDataSeries,
    yAxisId: dataset.yAxisId,
    pointMarker: new EllipsePointMarker(ctx, { ...HOVERED_POINT_MARKER_STYLE, fill: dataset.color }),
  });

  // Add to make hover only possible on the line, not the entire area
  series.hitTestProvider = new LineSeriesHitTestProvider(series as unknown as FastLineRenderableSeries, ctx);

  // Remove labels from zeroline
  (series.dataLabelProvider as BandSeriesDataLabelProvider).getY1Provider = () => {
    return new LineSeriesDataLabelProvider({});
  };

  seriesMetadataRef.current.set(series.id, { dataset });
  seriesMetadataRef.current.set(points.id, { dataset, excludeFromTooltip: true });
  return [series, points];
};

/** Utility function to traverse an array of `IRenderableSeries`. These might be "nested", which this function takes into account. */
export const traverseRenderableSeries = (series: IRenderableSeries[], callback: (serie: IRenderableSeries) => void) => {
  for (const serie of series) {
    if (isStackedMountainCollection(serie)) {
      for (const stackedSerie of serie['items']) {
        callback(stackedSerie);
      }
    } else {
      callback(serie);
    }
  }
};

export const createSeries = (
  dataset: MountainDataset | BandDataset | LineDataset | BarDataset,
  ctx: TSciChart,
  seriesMetadataRef: MutableRefObject<SeriesMetadataMap>,
) => {
  switch (dataset.type) {
    case 'mountain': {
      const dataSeries = createXyDataSeries(ctx, dataset);
      return createMountainLineRenderableSeries(ctx, dataSeries, dataset, seriesMetadataRef);
    }
    case 'band': {
      const dataSeries = createXyyDataSeries(ctx, dataset);
      return createSplineBandRenderableSeries(ctx, dataSeries, dataset, seriesMetadataRef);
    }
    case 'line': {
      const dataSeries = createXyDataSeries(ctx, dataset);
      return createSplineLineRenderableSeries(ctx, dataSeries, dataset, seriesMetadataRef);
    }
    case 'bar': {
      const dataSeries = createXyDataSeries(ctx, dataset);
      return createBarRenderableSeries(ctx, dataSeries, dataset, seriesMetadataRef);
    }
  }
};
