import { Toggle } from 'aim-components';
import { ProfileRow } from '../profile-settings/ProfileRow';
import { useMemoryDebuggerContext } from '@/components/common/memory-debugger/MemoryDebuggerContext';
import { useTranslation } from 'next-i18next';

export const MemoryDebuggerToggle = () => {
  const { t } = useTranslation('settings', { keyPrefix: 'memoryDebugger' });
  const { enabled, setEnabled } = useMemoryDebuggerContext();

  return (
    <ProfileRow title={t('title')} subTitle={t('subtitle')}>
      <Toggle toggledOn={enabled} onToggle={setEnabled} />
    </ProfileRow>
  );
};
