import type { ComponentRef, MouseEvent, TransitionEvent } from 'react';
import React, { forwardRef, useCallback, useMemo, useRef } from 'react';
import { type ComponentProps, type PropsWithChildren, type ReactElement, type ReactNode } from 'react';
import type { IconName } from '../Icon';
import { Icon } from '../Icon';
import cn from 'classnames';
import styles from './NavigationMenu.module.css';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { ScrollHint } from '../ScrollHint/ScrollHint';
import { useNavigationMenuContext } from './hooks';
import { NavigationMenuContext } from './NavigationMenuContext';
import { HoverTooltip } from '../HoverTooltip';
import { useMobile } from 'aim-utils';

type NavigationMenuProps = PropsWithChildren<{
  expanded: boolean;
  onToggle?: () => void;
  onToggleTransitionEnd?: () => void;
}> &
  ComponentProps<'nav'>;

export const NavigationMenu = ({
  children,
  expanded,
  onToggle,
  onToggleTransitionEnd,
  className,
  ...props
}: NavigationMenuProps) => {
  const ref = useRef<ComponentRef<'nav'>>(null);
  const { mobileView } = useMobile();

  const onTransitionEnd = useCallback(
    (e: TransitionEvent) => {
      if (!onToggleTransitionEnd) return;
      if (e.target !== ref.current) return;

      onToggleTransitionEnd();
    },
    [onToggleTransitionEnd],
  );

  const handleOnClick = useCallback(
    (e: MouseEvent) => {
      if (mobileView) return;
      if (!onToggle) return;
      if (e.target !== ref.current) return;

      onToggle(); // * If clicking directly on the menu (not on a child), we toggle the menu
    },
    [onToggle, mobileView],
  );

  const contextValue = useMemo(() => ({ expanded, onToggle }), [expanded, onToggle]);

  return (
    <NavigationMenuContext.Provider value={contextValue}>
      <nav
        ref={ref}
        className={cn(className, styles.menu)}
        onTransitionEnd={onTransitionEnd}
        aria-expanded={expanded}
        onClick={handleOnClick}
        {...props}
      >
        {children}
      </nav>
    </NavigationMenuContext.Provider>
  );
};

const NavigationMenuHeader = ({
  children,
  className,
  ...props
}: { children: ReactNode } & ComponentProps<'header'>) => {
  return (
    <header className={cn(className, styles.header)} {...props}>
      {children}
    </header>
  );
};

const NavigationMenuToggle = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button> & {
    icon?: IconName;
  }
>(({ icon = 'sidebar', className, ...props }, ref) => {
  const { expanded, onToggle } = useNavigationMenuContext();

  return (
    <Button
      ref={ref}
      className={cn(styles.toggleButton, className)}
      size='small'
      type='tertiary'
      color='secondary'
      endIcon={icon}
      onClick={onToggle ? () => onToggle(!expanded) : undefined}
      {...props}
    />
  );
});

NavigationMenuToggle.displayName = 'NavigationMenuToggle';

const NavigationMenuScrollable = ({
  children,
  className,
  ...props
}: { children: ReactNode } & ComponentProps<'div'>) => {
  return (
    <ScrollHint className={cn(className, styles.scrollable)} {...props}>
      {children}
    </ScrollHint>
  );
};

const NavigationMenuSection = ({
  children,
  title,
  className,
  ...props
}: { children: ReactNode; title?: string } & ComponentProps<'section'>) => {
  return (
    <section className={cn(className, styles.section)} {...props}>
      {title && (
        <Typography variant='labelAxis' truncate className={styles.sectionTitle}>
          {title}
        </Typography>
      )}
      {children}
    </section>
  );
};

const NavigationMenuList = ({ children, className, ...props }: { children: ReactNode } & ComponentProps<'ul'>) => {
  return (
    <ul className={cn(className, styles.list)} {...props}>
      {children}
    </ul>
  );
};

type NavigationMenuItemProps = ComponentProps<'li'> & {
  icon?: IconName | ReactElement;
  label?: string;
  tooltip?: string;
  active?: boolean;
  disabled?: boolean;
};

const NavigationMenuItem = ({
  children,
  icon,
  label,
  tooltip,
  active,
  disabled,
  onClick,
  className,
  ...props
}: NavigationMenuItemProps) => {
  return (
    <HoverTooltip
      disabled={disabled || !tooltip}
      message={tooltip}
      position='right'
      renderTrigger={({ setReference, referenceProps }) => (
        <li
          ref={setReference}
          className={cn(className, styles.item)}
          aria-disabled={disabled === true}
          aria-current={active === true}
          onClick={disabled ? undefined : onClick}
          {...props}
          {...referenceProps}
        >
          <>{typeof icon === 'string' ? <Icon icon={icon} /> : icon}</>
          <div className={styles.itemContent}>
            {label && <NavigationMenuListItemLabel>{label}</NavigationMenuListItemLabel>}
            {children}
          </div>
        </li>
      )}
    />
  );
};

const NavigationMenuListItemLabel = ({ children }: { children: ReactNode }) => {
  return (
    <Typography variant='text1' truncate className={styles.menuItemLabel}>
      {children}
    </Typography>
  );
};

const NavigationMenuListItemMenuButton = ({
  className,
  displayMode,
  ...props
}: ComponentProps<typeof Button> & {
  displayMode: 'always' | 'on-hover';
}) => {
  return (
    <Button
      className={cn(styles.menuItemButton, className)}
      data-display-mode={displayMode}
      endIcon='menu-vertical'
      type='tertiary'
      color='white'
      {...props}
    />
  );
};

const NavigationMenuListItemSkeleton = ({ animationDelayMs = 0 }: { animationDelayMs?: number }) => {
  const { expanded } = useNavigationMenuContext();

  const MIN_WIDTH_PERCENTAGE = 30;
  const MAX_WIDTH_PERCENTAGE = 60;

  const labelWidth = useRef(MIN_WIDTH_PERCENTAGE + Math.random() * (MAX_WIDTH_PERCENTAGE - MIN_WIDTH_PERCENTAGE));

  return (
    <li className={styles.itemSkeleton}>
      <div
        className={styles.skeletonLabel}
        style={{ width: expanded ? `${labelWidth.current}%` : undefined, animationDelay: `${animationDelayMs}ms` }}
      />
    </li>
  );
};

NavigationMenuItem.Label = NavigationMenuListItemLabel;
NavigationMenuItem.MenuButton = NavigationMenuListItemMenuButton;
NavigationMenuItem.Skeleton = NavigationMenuListItemSkeleton;

const NavigationMenuFooter = ({
  children,
  className,
  ...props
}: { children: ReactNode } & ComponentProps<'footer'>) => {
  return (
    <footer className={cn(className, styles.footer)} {...props}>
      {children}
    </footer>
  );
};

NavigationMenu.Header = NavigationMenuHeader;
NavigationMenu.Toggle = NavigationMenuToggle;
NavigationMenu.Footer = NavigationMenuFooter;
NavigationMenu.Section = NavigationMenuSection;
NavigationMenu.Scrollable = NavigationMenuScrollable;
NavigationMenu.List = NavigationMenuList;
NavigationMenu.Item = NavigationMenuItem;
