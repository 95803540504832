import {
  ActivityChartKey,
  GrowthChartKey,
  MarketingChartKey,
  RetentionChartKey,
  ScoreKey,
} from '@/components/insights/charts/common/types';
import type { NotificationFilter } from './index.page';
import { UserGrowthDataset } from '../insights/user-growth/utils';
import { ActiveCustomerRetentionMetric } from '../insights/active-customer-retention/index.page';
import { NewCustomerVsMarketingDataset } from '../insights/new-customers-vs-marketing/types';
import { GrowthRateMetric } from '../insights/growth-rate/index.page';
import { RecurringVsNonRecurringRevenueDataset } from '../insights/recurring-vs-non-recurring-revenue/types';
import { CustomerGrowthRateMetric } from '@/api/insights/customer-growth-rate/index.page';

export type NotificationEventId = '1' | '2' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14';

export const NotificationFilterMap = {
  1: {
    chartState: {
      chartKey: RetentionChartKey.ActiveCustomerRetention,
      metric: ActiveCustomerRetentionMetric.RetentionRate,
      timeGranularity: 'days',
      growthMetric: 'monthOverMonth',
    },
  },
  2: {
    chartState: {
      chartKey: ActivityChartKey.UserGrowth,
      datasets: [UserGrowthDataset.ActiveUsers],
      timeGranularity: 'days',
    },
  },
  4: {
    chartState: {
      chartKey: MarketingChartKey.NewCustomersVsMarketing,
      timeGranularity: 'days',
      datasets: [NewCustomerVsMarketingDataset.OpexMarketingDirect],
    },
  },
  5: {
    chartState: {
      chartKey: RetentionChartKey.CustomerGrowthRate,
      timeGranularity: 'days',
      metric: CustomerGrowthRateMetric.NewCustomers,
    },
    attachToDatasetIds: ['New customers'],
  },
  6: {
    chartState: {
      chartKey: GrowthChartKey.GrowthRate,
      timeGranularity: 'days',
      windowSize: '1d',
      metric: GrowthRateMetric.SalesNet,
    },
    attachToDatasetIds: ['Net sales'],
  },
  7: {
    chartState: {
      chartKey: GrowthChartKey.RecurringVsNonRecurringRevenue,
      timeGranularity: 'months',
      datasets: [RecurringVsNonRecurringRevenueDataset.RecurringRevenue],
    },
  },
  8: {
    chartState: {
      chartKey: RetentionChartKey.CustomerGrowthRate,
      timeGranularity: 'months',
      metric: CustomerGrowthRateMetric.NewCustomers,
    },
    attachToDatasetIds: ['New customers'],
  },
  9: { chartState: { chartKey: ScoreKey.Ark, timeGranularity: 'quarters' } },
  10: { chartState: { chartKey: ScoreKey.Growth, timeGranularity: 'quarters' } },
  11: { chartState: { chartKey: ScoreKey.MarketingEfficiency, timeGranularity: 'quarters' } },
  12: { chartState: { chartKey: ScoreKey.Monetisation, timeGranularity: 'quarters' } },
  13: { chartState: { chartKey: ScoreKey.Retention, timeGranularity: 'quarters' } },
  14: { chartState: { chartKey: ScoreKey.FinancialStrength, timeGranularity: 'quarters' } },
} as const satisfies Record<
  NotificationEventId,
  {
    chartState: NotificationFilter;
    attachToDatasetIds?: string[];
  }
>;
