import type { ErrorInfo, ReactElement, ReactNode } from 'react';
import React, { Component } from 'react';
import { ErrorScreen } from 'aim-components';
import Intercom from '../external/Intercom';
import GeneralError from './GeneralError';
import { logger } from 'aim-utils';

interface State {
  hasError: boolean;
  window?: Window;
}

interface Props {
  children: ReactNode;
  fallback?: ReactElement;
}

// TODO: This needs to be rewritten as a functional component
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidMount(): void {
    this.setState({ ...this.state, window: window });
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error(error, info);

    const errorMessage = ['Uncaught error in ErrorBoundary', error.name, error.message, window.location.href]
      .filter((x) => !!x)
      .join(' | ');

    logger.error({
      message: errorMessage,
      context: {
        href: window.location.href,
        errorInfo: info,
      },
      error,
    });
  }
  render(): ReactNode {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return React.cloneElement(this.props.fallback, {
          standalone: true,
          refreshComponent: () => this.setState({ hasError: false }),
          ...this.props.fallback.props,
        });
      }
      return (
        <>
          <Intercom hash='' userSession={null} />
          <ErrorScreen title="There's been a glitch" logo='/images/gilion-white-registered-trademark.png'>
            {this.state.window && <GeneralError errorSource='ErrorBoundary' />}
          </ErrorScreen>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
