import type { LastTupleItem } from './types';

export const uniqueArray = <T>(array: T[]) => {
  return [...new Set(array)];
};

export const getLastTupleItem = <TTuple extends readonly unknown[]>(tuple: TTuple) => {
  return tuple[tuple.length - 1] as LastTupleItem<TTuple>;
};

/**
 * 🛡️ **Type guard for checking if an item is in an array**'
 *
 * Useful for narrowing down a wider item type, to a specific array item type.
 *
 * @example
 * ```ts
 * const items = ['a', 'b', 'c'] as const;
 * const item: string;
 *
 * if (isArrayItem(item, items)) {
 *  // item is narrowed down to 'a' | 'b' | 'c'
 * }
 * ```
 */
export const isArrayItem = <TArray extends ReadonlyArray<unknown>>(
  item: unknown,
  array: TArray,
): item is TArray[number] => {
  return array.includes(item);
};

// * Possible performance improvement: support binary search if the array is sorted
export const findClosestIndex = (values: number[], target: number) => {
  if (values.length === 0) return -1;

  return values.reduce((closestIndex, currentValue, currentIndex) => {
    return Math.abs(currentValue - target) < Math.abs(values[closestIndex] - target) ? currentIndex : closestIndex;
  }, 0);
};
