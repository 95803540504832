import React from 'react';
import { SettingsContainer } from './SettingsContainer';
import NotificationPreference from '../notifications/NotificationPreference';
import useSWRImmutable from 'swr/immutable';
import { ApiRoutes } from 'pages/apiRoutes';
import type { NotificationSetting } from '@/api/notifications/settings/index.page';
import { fetcher } from '@/lib/request/request';
import { useNotificationToggle } from './hooks';

export const NotificationSettings = () => {
  const { data: notificationSettings } = useSWRImmutable(
    ApiRoutes.NotificationSettings,
    fetcher<NotificationSetting[]>,
  );

  const { notificationToggle, onToggle } = useNotificationToggle(notificationSettings);

  return (
    <>
      {notificationToggle.length > 0 && (
        <SettingsContainer>
          <NotificationPreference onToggle={onToggle} notification={notificationToggle[0]} />
        </SettingsContainer>
      )}
    </>
  );
};
