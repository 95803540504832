import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import { Footer } from './Footer/Footer';
import type { ModalFooterButton, PrimaryButtonProps } from './Footer/Footer';
import { Header } from './Header/Header';
import { Modal } from './Modal/Modal';
import type { TypographyColor } from '../Typography/Typography';

export interface HeaderFooterModalProps {
  children?: ReactNode;
  open: boolean;
  title?: string;
  subtitle?: string;
  subtitleColor?: TypographyColor;
  primaryButton?: PrimaryButtonProps;
  secondaryButton?: ModalFooterButton;
  bordered?: boolean;
  backButton?: () => void;
  onClose?: () => void;
}

export const HeaderFooterModal = ({
  children,
  open,
  title,
  subtitle,
  subtitleColor = 'primary',
  primaryButton,
  secondaryButton,
  bordered,
  backButton,
  onClose,
}: HeaderFooterModalProps): ReactElement => {
  const displayFooter = primaryButton || secondaryButton;

  return (
    <Modal onClose={onClose} open={open} bordered={bordered}>
      <div>
        <Header
          onClose={onClose}
          title={title}
          backButton={backButton}
          subtitle={subtitle}
          subtitleColor={subtitleColor}
        />
        {children}
      </div>
      {displayFooter && <Footer primary={primaryButton} secondary={secondaryButton} />}
    </Modal>
  );
};
