import React, { useState } from 'react';

import styles from './NotificationPreference.module.css';
import { Toggle, Typography } from 'aim-components';
import { NotificationGroups, getNotificationGroup, getNotificationSetting } from './utils';
import { useMobile } from 'aim-utils';
import { useTranslation } from 'next-i18next';
import { BetaBadge } from '../common/BetaBadge/BetaBadge';

interface NotificationSettings {
  isEnabled: boolean;
  reference: string;
  id: string;
}
interface NotificationPreferenceProps {
  notification: NotificationSettings;
  onToggle: (isEnabled: boolean, reference: string) => void;
}
const NotificationPreference = ({ notification, onToggle }: NotificationPreferenceProps) => {
  const { mobileView } = useMobile();
  const { t } = useTranslation('notifications');
  const [notificationToggle, setNotificationToggle] = useState(notification.isEnabled ?? false);

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>
          <Typography variant='text1' className={styles.title} color={mobileView ? 'secondary' : undefined}>
            {t(getNotificationGroup(NotificationGroups.ALL).title)}
          </Typography>
          {!mobileView && <BetaBadge type='neutral' />}
        </div>
        {!mobileView && getNotificationGroup(NotificationGroups.ALL).info && (
          <Typography variant='text2' color='secondary'>
            {getNotificationGroup(NotificationGroups.ALL).info}
          </Typography>
        )}
      </div>
      <div className={styles.notificationContainer}>
        {notification && (
          <div className={styles.notification}>
            <Toggle
              toggledOn={notificationToggle}
              onToggle={(toggledOn) => {
                setNotificationToggle(toggledOn);
                onToggle(toggledOn, notification.reference);
              }}
            />
            {getNotificationSetting(notification.id).title ||
              (getNotificationSetting(notification.id).info && (
                <div className={styles.text}>
                  <Typography variant='text1' className={styles.title}>
                    {getNotificationSetting(notification.id).title}
                  </Typography>
                  <Typography variant='text2' color='secondary'>
                    {getNotificationSetting(notification.id).info}
                  </Typography>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationPreference;
