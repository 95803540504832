import React, { useContext } from 'react';
import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { AnnotationContext } from '../AnnotationContext';
import { MentionsInput } from '../../Input/MentionsInput/MentionsInput';
import { useMentions } from '../../Input/MentionsInput/hooks';
import styles from './AnnotationInput.module.css';
import { Spinner } from '../../Loading';

interface AnnotationInputProps {
  onChange?: (value: string, mentionedIds: Array<string>) => void;
  onSend: (value: string, mentionedIds: Array<string>) => void;
  placeholder?: string;
  isLoading?: boolean;
}

export const AnnotationInput = ({ onSend, onChange, placeholder, isLoading }: AnnotationInputProps) => {
  const annotationContextData = useContext(AnnotationContext);
  const { value, handleMentionsInputChange, handleSend } = useMentions(onChange, onSend);

  return (
    <div className={styles.sendAnnotationContainer}>
      <MentionsInput
        mentionsData={annotationContextData.mentionsUserData}
        placeholder={placeholder}
        autofocus
        value={value}
        handleMentionsInputChange={handleMentionsInputChange}
        handleEnter={handleSend}
      />
      {isLoading ? (
        <Spinner size='small' />
      ) : (
        <Button
          onClick={() => handleSend()}
          color='white'
          disabled={value === ''}
          startIcon={<Icon icon='paper-plane' />}
        />
      )}
    </div>
  );
};
