import type { InsightCategory } from 'aim-components';
import { Typography } from 'aim-components';
import styles from './NotificationCard.module.css';
import { NotificationFlags } from './NotificationFlags';
import type { Notification } from '@/api/notifications/index.page';
import { useTranslation } from 'next-i18next';
import type { notificationMap } from '../../utils';
import { formatDate } from '../../utils';
import { useMemo } from 'react';

interface ExtendedNotification extends Notification {
  groupId: InsightCategory | undefined;
  notificationEvent: (typeof notificationMap)[keyof typeof notificationMap];
}

interface NotificationTitleProps {
  notification: ExtendedNotification;
  isRead: boolean;
  collapsed: boolean;
}

export const NotificationTitle = ({ notification, isRead, collapsed }: NotificationTitleProps) => {
  const { t } = useTranslation('notifications', { keyPrefix: 'sidePanel' });
  const { t: tGroup } = useTranslation('common', { keyPrefix: 'navigation' });

  const formattedDate = formatDate(notification.date);
  const dateLabel = formattedDate === 'today' || formattedDate === 'yesterday' ? t(formattedDate) : formattedDate;
  const title = `${t(`notifications.title_${notification.notificationEvent}`)} ${t(
    `notifications.${notification.direction}`,
  )}`;

  const notificationInfo = useMemo(() => {
    if (notification.groupId) {
      return `${dateLabel} • ${tGroup(notification.groupId)}`;
    }

    return dateLabel;
  }, [dateLabel, notification.groupId, tGroup]);

  return (
    <div>
      <Typography variant='label3' color={isRead && collapsed ? 'secondary' : undefined}>
        {title}
      </Typography>
      <div className={styles.subtitleContainer}>
        <Typography variant='text2' color='secondary' className={styles.countryCodes}>
          {notificationInfo}
        </Typography>
        <NotificationFlags countryCodes={notification.countryCodes} />
      </div>
    </div>
  );
};
