import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';
import { MentionsInput as ReactMentionsInput, Mention as ReactMentionsMention } from 'react-mentions';
import { useEmojiMentions, useUserMentions } from './hooks';
import './MentionsInput.css';
import type { MentionData } from './types';
import { sortAlphabetically } from 'aim-utils';

interface MentionsInputProps {
  mentionsData: Array<MentionData>;
  singleLine?: boolean;
  placeholder?: string;
  autofocus?: boolean;
  showKeyHint?: boolean;
  value?: string;
  handleMentionsInputChange: ComponentProps<typeof ReactMentionsInput>['onChange'];
  handleEnter: (event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => void;
}

const neverMatchingRegex = /($a)/; // Used to return emoji itself, without any markup

export const MentionsInput = ({
  mentionsData,
  singleLine = false,
  placeholder = 'Mention a user using @',
  autofocus,
  value,
  handleMentionsInputChange,
  handleEnter,
}: MentionsInputProps) => {
  const [renderMentionSuggestion, transformDisplayedMention] = useUserMentions();
  const [renderEmojiSuggestion, getEmojiSuggestions] = useEmojiMentions();

  const sortedMentionsData = useMemo(() => {
    return mentionsData.sort((a, b) => sortAlphabetically(a.display, b.display));
  }, [mentionsData]);

  return (
    <>
      <ReactMentionsInput
        value={value}
        onChange={handleMentionsInputChange}
        className='mentions-input'
        placeholder={placeholder}
        forceSuggestionsAboveCursor
        allowSpaceInQuery
        singleLine={singleLine}
        autoFocus={autofocus}
        onKeyDown={handleEnter}
      >
        <ReactMentionsMention
          trigger='@'
          data={sortedMentionsData}
          markup='@{{__display__}}{{mention:__id__}}'
          className='mentions-input__mention'
          displayTransform={transformDisplayedMention}
          renderSuggestion={renderMentionSuggestion}
        />
        <ReactMentionsMention
          trigger=':'
          data={getEmojiSuggestions}
          markup='__id__'
          className='mentions-input__mention'
          renderSuggestion={renderEmojiSuggestion}
          regex={neverMatchingRegex}
        />
      </ReactMentionsInput>
    </>
  );
};
