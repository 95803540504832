import { ColorSpectrum } from 'aim-utils';
import type { ChartArea } from 'chart.js';

export const isPanning = (panning: number) => Math.abs(panning) > 1;
export const isScaling = (scale: number) => Math.abs(scale - 1) > 0.1;
export const getLimitedMax = (max: number, newMax: number) => Math.min(max, Math.round(newMax));
export const getLimitedMin = (newMin: number) => Math.max(0, Math.round(newMin));
export const computeXPos = (e: TouchEvent) => {
  return (e.touches[0].pageX + e.touches[1].pageX) / 2;
};

export const computeScaleFactor = (
  prevDistanceToTouchPos: number | undefined,
  prevScaleFactor: number,
  e: TouchEvent,
) => {
  const xPos = computeXPos(e);
  return Math.min(
    Math.max(
      prevDistanceToTouchPos
        ? prevScaleFactor +
            (Math.abs(xPos - e.touches[0].pageX) + Math.abs(xPos - e.touches[1].pageX) - prevDistanceToTouchPos) * 0.005
        : 1,
      0.5,
    ),
    3,
  );
};

export const pill = {
  height: 16,
  borderRadius: 8,
  verticalPadding: 2,
  horizontalPadding: 8,
} as const;

export const drawTooltip = (
  ctx: CanvasRenderingContext2D,
  x: number,
  { right, bottom, left }: ChartArea,
  title: string,
) => {
  ctx.lineWidth = 1;
  ctx.strokeStyle = ColorSpectrum['cool-grey'][700];

  ctx.save();
  ctx.beginPath();
  ctx.moveTo(x, bottom);
  ctx.lineTo(x, pill.height);
  ctx.stroke();

  const { width: textWidth } = ctx.measureText(title);

  const rectangleData = {
    x: x - textWidth / 2 - pill.horizontalPadding,
    y: pill.verticalPadding,
    width: textWidth + pill.horizontalPadding * 2,
    height: pill.height + pill.verticalPadding * 2,
    radius: pill.borderRadius,
  };

  const minAllowedRectangleX = left;
  const maxAllowedRectangleX = right - rectangleData.width;

  rectangleData.x = Math.max(rectangleData.x, minAllowedRectangleX);
  rectangleData.x = Math.min(rectangleData.x, maxAllowedRectangleX);

  ctx.roundRect(rectangleData.x, rectangleData.y, rectangleData.width, rectangleData.height, rectangleData.radius);

  ctx.fillStyle = ColorSpectrum['cool-grey'][700];
  ctx.fill();

  ctx.fillStyle = 'white';
  ctx.fillText(title, rectangleData.x + pill.horizontalPadding, pill.height);
  ctx.stroke();
  ctx.restore();
};
