import type { ReactNode } from 'react';
import { useCallback, useContext } from 'react';
import { Button } from '../../Button';
import { Typography } from '../../Typography';
import styles from './ChartHeaderMobile.module.css';
import { LineColors } from 'aim-utils';
import { InsightContext } from '../InsightContext';
import { ForecastChartContext } from '../ForecastChartContext';

export interface ChartHeaderMobileProps {
  title?: ReactNode;
  customTitle?: string;
  fullscreen?: boolean;
  setFullscreen: (open: boolean) => void;
  showInfo?: () => void;
  copyChartLink?: () => void;
  disableFullscreen?: boolean;
  hideFullScreen?: boolean;
  badge?: ReactNode;
  legend?: ReactNode;
}

const ChartHeaderTitleMobile = ({ title, customTitle }: { title?: ReactNode; customTitle?: string }) => {
  if (customTitle && customTitle !== title) {
    return (
      <div className={styles.multiRowTitle}>
        {typeof title === 'string' && (
          <Typography variant='text2' color='secondary'>
            {title}
          </Typography>
        )}
        <Typography variant='label2'>{customTitle}</Typography>
      </div>
    );
  }

  if (typeof title === 'string') {
    return (
      <Typography variant='label2' className={styles.title}>
        {title}
      </Typography>
    );
  }

  return title;
};

export const ChartHeaderMobile = ({
  title,
  customTitle,
  fullscreen,
  setFullscreen,
  showInfo,
  copyChartLink,
  disableFullscreen,
  hideFullScreen,
  badge,
  legend,
}: ChartHeaderMobileProps) => {
  const setFullscreenState = useCallback((open: boolean) => () => setFullscreen(open), [setFullscreen]);

  const insightContext = useContext(InsightContext);
  const forecastChartContext = useContext(ForecastChartContext);

  if (fullscreen) {
    return (
      <header className={styles.headerFullscreen}>
        <Button type='tertiary' color='white' endIcon='nav-back' onClick={setFullscreenState(false)} size='large' />
        <ChartHeaderTitleMobile title={title} customTitle={customTitle} />
        {copyChartLink && <Button type='tertiary' color='white' endIcon='link' onClick={copyChartLink} size='large' />}
      </header>
    );
  }

  return (
    <header className={styles.header}>
      <div className={styles.headerWithButtons}>
        <ChartHeaderTitleMobile title={title} customTitle={customTitle} />
        <div className={styles.drawerButtons}>
          {showInfo && <Button type='tertiary' color='white' endIcon='info-circle' onClick={showInfo} />}
          {insightContext?.missingChartDataIndicator && (
            <Button
              type='tertiary'
              color='white'
              endIcon='cloud-exclamation'
              onClick={() =>
                insightContext?.missingChartDataIndicator?.onClick('', forecastChartContext.nonReliableForecast)
              }
              className={styles.expandButton}
              disabled={disableFullscreen}
              iconFillColor={LineColors.gold}
            />
          )}
        </div>
      </div>

      <div className={styles.headerWithRightContent}>
        {badge}
        {!hideFullScreen && (
          <Button
            type='tertiary'
            color='white'
            endIcon='expand'
            onClick={setFullscreenState(true)}
            className={styles.expandButton}
            disabled={disableFullscreen}
          />
        )}
      </div>
      {legend && <div className={styles.headerWithLegend}>{legend}</div>}
    </header>
  );
};
