import { format, max, min } from 'date-fns';
import type { DatePickerProps } from '../DatePicker';

export const formatDatePickerDate = (date: Date | undefined) => {
  return date ? format(date, 'MMM d, yyyy') : undefined;
};

export const getDatePickerButtonLabel = (props: DatePickerProps) => {
  const { mode } = props;

  if (mode === 'single') {
    const { selected } = props;
    return formatDatePickerDate(selected);
  }

  if (mode === 'multiple') {
    const { selected } = props;
    if (!selected || selected.length === 0) return;
    const numberOfDates = selected.length;

    if (numberOfDates === 1) return formatDatePickerDate(selected[0]);

    const minDate = min(selected);
    const maxDate = max(selected);

    return `${formatDatePickerDate(minDate)} - ${formatDatePickerDate(maxDate)} (${numberOfDates})`;
  }

  if (mode === 'range') {
    const { selected } = props;
    const from = formatDatePickerDate(selected?.from);
    const to = formatDatePickerDate(selected?.to);

    if (!from && !to) return;

    return `${from ?? 'N/A'} - ${to ?? 'N/A'}`;
  }
};
