import type { MouseEvent, ReactElement } from 'react';
import { useCallback } from 'react';
import React from 'react';
import cn from 'classnames';
import { Badge } from '../Badge';
import { Button } from '../Button';
import { Card } from '../Card';
import { Checkbox } from '../Input';
import { Spinner } from '../Loading';
import './ConnectCard.css';
import type { ConnectorStatus } from '../Connectors/types';

export type ConnectorCardType = 'button' | 'checkbox';

export interface ConnectCardProps {
  fullName: string;
  shortName?: string;
  imgUrl?: string;
  status?: ConnectorStatus;
  type?: ConnectorCardType;
  isLoading?: boolean;
  onClick?: () => void;
  transparent?: boolean;
  disabled?: boolean;
}

interface ActionContentProps {
  connectorStatus?: ConnectorStatus;
  connectorCardType: ConnectorCardType;
  clickAction?: () => void;
}

function ActionContent({
  connectorStatus,
  connectorCardType,
  clickAction = () => {
    return;
  },
}: ActionContentProps): ReactElement {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement> | undefined) => {
      e?.stopPropagation();
      clickAction();
    },
    [clickAction],
  );

  if (connectorCardType === 'checkbox' && (connectorStatus === 'added' || connectorStatus === 'unadded'))
    return <Checkbox initialValue={connectorStatus === 'added'} onChange={() => handleClick(undefined)} />;
  switch (connectorStatus) {
    case 'unadded':
      return (
        <Button onClick={handleClick} size='small'>
          Set up
        </Button>
      );
    case 'unconfigured':
      return <Badge type='neutral'>Not connected</Badge>;
    case 'added':
      return <Badge type='success'>Added</Badge>;
    case 'connected':
      return <Badge type='success'>Connected</Badge>;
    case 'unsupported':
    default:
      return <></>;
  }
}

export function ConnectCard({
  fullName,
  shortName,
  imgUrl,
  status,
  isLoading = false,
  onClick,
  type = 'button',
  transparent,
  disabled = false,
}: ConnectCardProps): ReactElement {
  return (
    <Card
      disabled={disabled || status === 'unsupported'}
      transparent={transparent}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
      className='ConnectCard'
    >
      <div className='ConnectCard-content'>
        <div className='ConnectCard-logoText'>
          {imgUrl && <img src={imgUrl} className={cn(status === 'unsupported' && 'unsupported')} />}
          <div className='ConnectCard-cardNameFull'>{fullName}</div>
          <div className='ConnectCard-cardNameShort'>{shortName || fullName}</div>
        </div>
        <div className={cn('ConnectCard-actionContent', isLoading && 'loading')}>
          {isLoading ? (
            <Spinner />
          ) : (
            !disabled && <ActionContent connectorStatus={status} clickAction={onClick} connectorCardType={type} />
          )}
        </div>
      </div>
    </Card>
  );
}
