import { AnnotationContext } from 'aim-components';
import type { ComponentRef } from 'react';
import { useState, type ReactNode, useRef, useEffect, useContext } from 'react';
import styles from './NavigationLayout.module.css';
import { NavigationMenu } from './navigation-menu/NavigationMenu';
import { useRouter } from 'next/router';
import { NavigationHeader } from './navigation-header/NavigationHeader';
import { useAnnotations } from 'pages/[slug]/insights/hooks';
import { useIntercomContext, useMobile } from 'aim-utils';
import { Welcome } from '@/components/connectors/onboarding/Welcome';
import { NotificationsSidePanel } from '@/components/notifications/notificationsSidePanel/NotificationsSidePanel';
import { setUserSetting } from '@/lib/settings';
import { WELCOME_SCREEN_HAS_BEEN_SEEN_CACHE, useWelcomeScreen } from './hooks';
import AimFooter from '../AimFooter';
import { ApiRoutes } from 'pages/apiRoutes';
import { fetcher } from '@/lib/request/request';
import type { Notification } from '@/api/notifications/index.page';
import useSWR from 'swr';
import { UserContext } from '@/components/authentication/UserContext';
import { getUserPermissions } from '@/lib/permissions/role';
import { useNavigationContext } from '@/components/layouts/navigation/navigation-menu/NavigationContext';
import { AnnotationSidePanel } from '@/components/Annotations/AnnotationSidePanel/AnnotationSidePanel';

export const MAX_CONTENT_WIDTH = 1400;

export const NavigationLayout = ({ children, includeFooter }: { children: ReactNode; includeFooter?: boolean }) => {
  const { navigationMenuExpanded, setNavigationMenuExpanded, expandNavigationMenu, showFooter } =
    useNavigationContext();
  const [notificationSidePanelOpen, setNotificationSidePanelOpen] = useState(false);

  const { updateIntercomBlocker } = useIntercomContext();

  const contentRef = useRef<ComponentRef<'section'>>(null);
  const router = useRouter();
  const { mobileView } = useMobile();

  const user = useContext(UserContext);
  const { hasDeckViewerRole } = getUserPermissions(user.role);

  const annotationData = useAnnotations(!mobileView);

  useEffect(() => {
    updateIntercomBlocker('notification-side-panel', { hideIntercom: notificationSidePanelOpen });
  }, [notificationSidePanelOpen, updateIntercomBlocker]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [router.pathname]);

  const { data: notifications, mutate: refetchData } = useSWR<Notification[]>(ApiRoutes.Notifications, fetcher);
  const hasUnreadNotifications =
    Array.isArray(notifications) && notifications?.some((notification) => !notification.read);

  const { showWelcomeScreen, isLoading } = useWelcomeScreen();

  if (isLoading) return null;

  if (showWelcomeScreen) {
    return (
      <Welcome
        onAnimationEnd={async () => {
          localStorage.setItem(WELCOME_SCREEN_HAS_BEEN_SEEN_CACHE, 'true');
          await setUserSetting('enter-gilion', { hasBeenSeen: true });
          await setUserSetting('welcome-screen', { hasBeenSeen: true });
        }}
      />
    );
  }

  return (
    <>
      <AnnotationContext.Provider value={annotationData}>
        <div
          className={styles.navigationLayout}
          data-is-admin={annotationData.isAdmin}
          style={{ ['--content-max-width' as string]: `${MAX_CONTENT_WIDTH}px` }}
        >
          <NavigationMenu expanded={navigationMenuExpanded} setExpanded={setNavigationMenuExpanded} />

          <main className={styles.main}>
            <NavigationHeader
              deckViewer={hasDeckViewerRole}
              expandMobileMenu={expandNavigationMenu}
              expandNotificationsPanel={() => setNotificationSidePanelOpen(true)}
              unreadNotifications={hasUnreadNotifications}
            />

            <section className={styles.contentOuter} ref={contentRef}>
              <div className={styles.content}>
                {children}
                {!mobileView && <AnnotationSidePanel isOpened={annotationData.displayAnnotationSidePanel} />}
              </div>
              {showFooter && includeFooter && (
                <div className={styles.aimFooterWrapper}>
                  <AimFooter />
                </div>
              )}
              {!hasDeckViewerRole && (
                <NotificationsSidePanel
                  isOpened={notificationSidePanelOpen}
                  notifications={notifications}
                  refetchData={refetchData}
                  closeSidePanel={() => setNotificationSidePanelOpen(false)}
                />
              )}
            </section>
          </main>
        </div>
      </AnnotationContext.Provider>
    </>
  );
};
