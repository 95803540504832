import type { ITextLabelOptions } from 'scichart';
import { TextLabelProvider } from 'scichart';
import { formatDate, type TimeGranularity } from 'aim-utils';
import { USE_MONTHLY_TICKS_INSTEAD_OF_DAILY_ABOVE_VISIBLE_RANGE_DIFF } from './ModuloInclusionTickProvider';

type DynamicDateLabelProviderOptions = ITextLabelOptions & {
  timeGranularity: TimeGranularity;
};

export class DynamicDateLabelProvider extends TextLabelProvider {
  private timeGranularity: TimeGranularity;

  constructor(options: DynamicDateLabelProviderOptions) {
    super(options);

    if (!options) throw new Error('Options are required for DynamicDateLabelProvider.');

    this.timeGranularity = options.timeGranularity;
    this.textVariesForSameTick = true;
    this.useCache = false; // * Disable caching due to dynamic nature of the labels

    this.formatLabelProperty = this.formatDataValue;
    this.formatCursorLabelProperty = this.formatDataValue;
  }

  private formatDataValue(unixTimestamp: number) {
    const { visibleRange } = this.parentAxis;
    const date = new Date(unixTimestamp * 1000);

    if (
      this.timeGranularity === 'days' &&
      visibleRange.diff > USE_MONTHLY_TICKS_INSTEAD_OF_DAILY_ABOVE_VISIBLE_RANGE_DIFF
    ) {
      return formatDate(date, 'months');
    }

    return formatDate(date, this.timeGranularity);
  }

  setTimeGranularity(timeGranularity: TimeGranularity) {
    this.timeGranularity = timeGranularity;
  }

  getTimeGranularity() {
    return this.timeGranularity;
  }
}
