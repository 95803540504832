import React, { useMemo } from 'react';
import Head from 'next/head';
import { isDefined } from 'aim-utils';
import Script from 'next/script';

interface CommonHeadProps {
  // ? Viewport meta tag reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Viewport_meta_tag
  viewport: Partial<{
    width: number | 'device-width';
    initialScale: number;
    maximumScale: number;
    userScalable: 'yes' | 'no';
    interactiveWidget: 'resizes-visual' | 'resizes-content' | 'overlays-content';
  }>;
}

export const DEFAULT_VIEWPORT_META_TAG_SETTINGS = {
  width: 'device-width',
  initialScale: 1,
  maximumScale: 1,
  userScalable: 'no',
  interactiveWidget: 'resizes-visual',
} as const satisfies CommonHeadProps['viewport'];

const getViewportMetaTagContent = (viewport: CommonHeadProps['viewport'] = DEFAULT_VIEWPORT_META_TAG_SETTINGS) => {
  const viewportSettings = {
    width: viewport.width,
    'initial-scale': viewport.initialScale,
    'maximum-scale': viewport.maximumScale,
    'user-scalable': viewport.userScalable,
    'interactive-widget': viewport.interactiveWidget,
  };

  return Object.entries(viewportSettings)
    .filter(([, value]) => isDefined(value))
    .map(([key, value]) => `${key}=${value}`)
    .join(', ');
};

const CommonHead = ({ viewport }: Partial<CommonHeadProps>) => {
  const viewportMetaTagContent = useMemo(() => getViewportMetaTagContent(viewport), [viewport]);

  return (
    <>
      <Head>
        <title>Gilion</title>
        <link rel='icon' href='/images/favicon-32x32.png' />
        <link rel='apple-touch-icon' href='/images/apple-touch-icon.png' />
        <link rel='manifest' href='/site.webmanifest' crossOrigin='use-credentials' />
        <meta name='theme-color' content='#0033FF' />
        <meta name='viewport' content={viewportMetaTagContent} />
      </Head>
      <Script src='https://rum-static.pingdom.net/pa-65ddb7daeff32500120007dc.js' strategy='afterInteractive' />
    </>
  );
};

export default CommonHead;
