/* eslint-disable camelcase */
import { serverLogger } from '@/lib/logger';
import type { NextApiRequest, NextApiResponse } from 'next';
import { z } from 'zod';
import { LtvOverCacDataset } from './insights/ltv-cac/utils';
import { RecurringVsNonRecurringRevenueDataset } from './insights/recurring-vs-non-recurring-revenue/types';
import { UserGrowthDataset } from './insights/user-growth/utils';
import { RetentionTrendsDataset } from './insights/retention-trends/types';
import { UserActivityRetentionDataset } from './insights/user-activity-retention-trends/types';
import { AnnualisedRevenueDataset } from './insights/annualised-revenue/types';
import { NewCustomerVsMarketingDataset } from './insights/new-customers-vs-marketing/types';

const sendInvalidQueryResponse = (req: NextApiRequest, res: NextApiResponse, zodError: z.ZodError<unknown>): void => {
  const message = 'Invalid request query!';
  logInvalidRequest(req, message, zodError);

  res.status(400).send({ error: { message } });
};

const sendInvalidBodyResponse = (req: NextApiRequest, res: NextApiResponse, zodError: z.ZodError<unknown>): void => {
  const message = 'Invalid request body!';
  logInvalidRequest(req, message, zodError);

  res.status(400).send({ error: { message } });
};

const logInvalidRequest = (req: NextApiRequest, message: string, zodError: z.ZodError<unknown>) => {
  const { url, method, query, body, headers } = req;
  const projectCode = headers['x-aim-project-code'];

  serverLogger.warn(`${message} ${method} ${url} | ${projectCode}`, {
    url,
    method,
    // ! Disables request query and body logging in production, to avoid potential PII data being logged. If we use proper redaction (of email addresses etc.) or make this type of logging opt-in per end-point or similar, we can re-enable this, if we believe it would be useful.
    query: process.env.NEXT_PUBLIC_ENV !== 'production' ? query : undefined,
    body: process.env.NEXT_PUBLIC_ENV !== 'production' ? body : undefined,
    issues: zodError.issues,
    projectCode,
  });
};

export const CommonValidator = {
  countryCode: z.union([z.string().length(2), z.literal('ALL'), z.literal('UNKNOWN')]),
  acquisitionGranularity: z.enum(['quarters', 'months']),
} as const;

export const SchemaDescription = {
  country:
    "The country code for the country to display data for. It is using the ISO country code for the country, e.g. 'SE' for Sweden. There are two special values: 'ALL' to display data for all countries, 'UNKNOWN' for unknown countries.",
  datasets: 'Datasets to be displayed in the chart. Uses the same labels as displayed in the user interface.',
  scenarios: {
    '25pcs_worse_retention': '25% lower 2-year retention',
    '25pct_improved_retention': '25% improved 2-year retention',
    base_case: 'Gilion Base Case',
    cac_up_10pct: 'CAC up 10%',
    double_spend_10pct_higher_cac_25pct_improved_retention:
      'Double ad spend, 10% higher CAC, 25% improved 2-year retention',
    double_spend_cac_up_10pct: 'Double ad spend and CAC up 10%',
    no_new_intake: 'Only existing customers',
  },
  annualisedRevenueDatasets: {
    [AnnualisedRevenueDataset.Total]: 'Total',
    [AnnualisedRevenueDataset.Retained]: 'Retained',
    [AnnualisedRevenueDataset.New]: 'New',
    [AnnualisedRevenueDataset.Reactivated]: 'Reactivated',
    [AnnualisedRevenueDataset.Expanded]: 'Expanded',
    [AnnualisedRevenueDataset.Shrinkage]: 'Shrinkage',
    [AnnualisedRevenueDataset.Churn]: 'Churn',
  },
  newCustomerVsMarketingDatasets: {
    [NewCustomerVsMarketingDataset.NewCustomers]: 'New customers',
    [NewCustomerVsMarketingDataset.OpexMarketingDirect]: 'Direct marketing cost',
    [NewCustomerVsMarketingDataset.OpexMarketingOther]: 'Other marketing cost',
  },
  ltvOverCacDatasets: {
    [LtvOverCacDataset.CacDirect]: 'Direct marketing cost',
    [LtvOverCacDataset.CacOther]: 'Other marketing cost',
    [LtvOverCacDataset.Ltv36m]: 'LTV 36m',
    [LtvOverCacDataset.Ltv24m]: 'LTV 24m',
    [LtvOverCacDataset.Ltv18m]: 'LTV 18m',
    [LtvOverCacDataset.Ltv12m]: 'LTV 12m',
    [LtvOverCacDataset.Ltv6m]: 'LTV 6m',
    [LtvOverCacDataset.Ltv3m]: 'LTV 3m',
    [LtvOverCacDataset.Ltv1m]: 'LTV 1m',
  },
  recurringVsNonRecurringRevenueDataset: {
    [RecurringVsNonRecurringRevenueDataset.RecurringRevenue]: 'Monthly recurring revenue',
    [RecurringVsNonRecurringRevenueDataset.NonRecurringRevenue]: 'Monthly non-recurring revenue',
  },
  userGrowthDatasets: {
    [UserGrowthDataset.ActiveUsers]: 'Daily (DAU)',
    [UserGrowthDataset.ActiveUsers7dAvg]: 'Daily, 7 day average',
    [UserGrowthDataset.ActiveUsersLast7d]: 'Last 7 days (WAU)',
    [UserGrowthDataset.ActiveUsersLast28d]: 'Last 28 days',
    [UserGrowthDataset.ActiveUsersLast30d]: 'Last 30 days (MAU)',
    [UserGrowthDataset.ActiveUsersLast90d]: 'Last 90 days',
  },
  retentionTrendsDataset: {
    [RetentionTrendsDataset['1m']]: '1m',
    [RetentionTrendsDataset['3m']]: '3m',
    [RetentionTrendsDataset['6m']]: '6m',
    [RetentionTrendsDataset['12m']]: '12m',
    [RetentionTrendsDataset['18m']]: '18m',
    [RetentionTrendsDataset['24m']]: '24m',
    [RetentionTrendsDataset['36m']]: '36m',
  },
  userActivityRetentionDatasets: {
    [UserActivityRetentionDataset.SecondDayRetention]: 'Day 2',
    [UserActivityRetentionDataset.SecondWeekRetention]: 'Second week',
    [UserActivityRetentionDataset.SecondMonthRetention]: 'Second month',
    [UserActivityRetentionDataset.Day10Retention]: 'Day 10',
    [UserActivityRetentionDataset.Day30Retention]: 'Day 30',
    [UserActivityRetentionDataset.FirstWeekRetention]: 'First week',
    [UserActivityRetentionDataset.FirstMonthRetention]: '1 month',
    [UserActivityRetentionDataset.ThirdMonthRetention]: '3 months',
    [UserActivityRetentionDataset.SixthMonthRetention]: '6 months',
    [UserActivityRetentionDataset.TwelfthMonthRetention]: '12 months',
    [UserActivityRetentionDataset.EighteenthMonthRetention]: '18 months',
    [UserActivityRetentionDataset.TwentyForthMonthRetention]: '24 months',
    [UserActivityRetentionDataset.ThirtySixthMonthRetention]: '36 months',
  },
  timeGranularity: {
    years: 'Yearly time granularity. Each data point represents a year.',
    quarters: 'Quarterly time granularity. Each data point represents a quarter.',
    months: 'Monthly time granularity. Each data point represents a month.',
    weeks: 'Weeks. Each data point represents a week.',
    days: 'Days. Each data point represents a day.',
  },
  windowSize: {
    '1d': 'Window size of 1 day',
    '7d': 'Window size of 7 days (1 week)',
    '30d': 'Window size of 30 days (1 month)',
    '60d': 'Window size of 60 days (2 months)',
    '90d': 'Window size of 90 days (3 months)',
    '365d': 'Window size of 365 days (12 months / 1 year)',
  },
  sliderStart:
    "ISO date string representing the default start of the visible data range (which can be adjusted by the user using a slider). Example: '2021-01-01'",
  sliderEnd:
    "ISO date string representing the default end of the visible data range (which can be adjusted by the user using a slider). Example: '2021-12-31'",
} as const;

type SchemaValidationResult<TOutputData = unknown, TError = unknown> =
  | {
      success: true;
      data: TOutputData;
    }
  | {
      success: false;
      error: TError;
    };

/**
 * A schema validation function that accepts a `schema` and `data` to validate against the schema.
 *
 * This function wraps the [`safeParse`](https://zod.dev/?id=safeparse) function from Zod.
 * Having a wrapper makes it easier to swap out the validation library if needed, while keeping a consistent function API.
 *
 * @returns An object with `success: true` and the schema-conforming data if the validation was successful, or an object with `success: false` and validation errors if the validation failed.
 * */
export const validateSchema = <TOutputData>(schema: z.ZodType<TOutputData>, data: unknown) => {
  return schema.safeParse(data) satisfies SchemaValidationResult<TOutputData>;
};

/**
 * Utility function for validating request query parameters (`req.query`) against a schema.
 *
 * @param schema Validation schema for `req.query`
 */
export const validateRequestQuery = <TOutputData>(schema: z.ZodType<TOutputData>, request: NextApiRequest) => {
  const parsedQuery = validateSchema(schema, request.query);

  if (!parsedQuery.success) {
    return {
      parsedQuery: {
        ...parsedQuery,
        sendInvalidQueryResponse: (res: NextApiResponse) => sendInvalidQueryResponse(request, res, parsedQuery.error),
      },
    };
  }

  return { parsedQuery };
};

/**
 * Utility function for validating a request body (`req.body`) against a schema.
 *
 * @param schema Validation schema for `req.body`
 */
export const validateRequestBody = <TOutputData>(schema: z.ZodType<TOutputData>, request: NextApiRequest) => {
  const parsedBody = validateSchema(schema, request.body);

  if (!parsedBody.success) {
    return {
      parsedBody: {
        ...parsedBody,
        sendInvalidBodyResponse: (res: NextApiResponse) => sendInvalidBodyResponse(request, res, parsedBody.error),
      },
    };
  }

  return { parsedBody };
};

const userReferenceSchema = z.string().uuid();
export const validateUserReferenceOrThrow = (userReference?: string) => {
  userReferenceSchema.parse(userReference);
};
