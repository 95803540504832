import { createContext, useContext } from 'react';

interface IntercomContextProps {
  updateIntercomBlocker: (blockerKey: string, { hideIntercom }: { hideIntercom: boolean }) => void;
}

export const IntercomContext = createContext<IntercomContextProps | null>(null);

export const useIntercomContext = () => {
  const context = useContext(IntercomContext);

  if (!context) {
    throw new Error('useIntercomContext must be used within a IntercomContextProvider');
  }

  return context;
};
