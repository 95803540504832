import { AnimatedStack } from './AnimatedStack';
import { useEffect, useMemo } from 'react';
import { isLarge } from '../utils';
import { animated, useTrail } from '@react-spring/web';
import type { ChartAnimationProps } from '../MissingDataChartAnimation';
import { ColorSpectrum } from 'aim-utils';

export const StackedChartAnimation = ({ size, svgHeight, svgWidth }: ChartAnimationProps) => {
  const stacks = useMemo(
    () =>
      isLarge(size)
        ? [
            {
              from: [0, 1, 1, 1, 14, 18, 18, 20, 0],
              to: [0, 1, 1, 15, 47, 55, 60, 55, 0],
              fill: ColorSpectrum.azure[400],
            },
            {
              from: [0, 1, 1, 11, 13, 15, 15, 2, 0],
              to: [0, 4, 20, 38, 40, 49, 47, 4, 0],
              fill: ColorSpectrum.azure[500],
            },
            {
              from: [0, 5, 6, 8, 9, 9, 2, 1, 0],
              to: [0, 15, 24, 24, 34, 36, 3, 1, 0],
              fill: ColorSpectrum.azure[600],
            },
          ]
        : [
            {
              from: [0, 1, 1, 1, 14, 18, 18, 20, 0],
              to: [0, 1, 1, 1, 20, 28, 30, 32, 0],
              fill: ColorSpectrum.azure[400],
            },
            {
              from: [0, 1, 1, 11, 13, 15, 15, 2, 0],
              to: [0, 1, 1, 16, 18, 20, 20, 4, 0],
              fill: ColorSpectrum.azure[500],
            },
            {
              from: [0, 5, 6, 8, 9, 9, 2, 1, 0],
              to: [0, 6, 11, 13, 14, 12, 5, 1, 0],
              fill: ColorSpectrum.azure[600],
            },
          ],
    [size],
  );

  const Animated = animated(AnimatedStack);

  const [trails, api] = useTrail(
    stacks.length,
    () => {
      return {
        from: { startTrigger: 0 },
        to: { startTrigger: 1 },
        config: {
          duration: isLarge(size) ? 300 : 1000,
          mass: 5,
          tension: isLarge(size) ? 200 : 500,
          friction: isLarge(size) ? 1000 : 700,
        },
      };
    },
    [],
  );

  useEffect(() => {
    const interval = setInterval(
      () => api.start({ from: { startTrigger: 0 }, to: { startTrigger: 1 } }),
      isLarge(size) ? 3000 : 6000,
    );

    return () => clearInterval(interval);
  }, [api, size]);

  return (
    <svg width={svgWidth} height={svgHeight}>
      <g transform={`translate(0,${svgHeight})`}>
        {trails.reverse().map(({ startTrigger }, i) => (
          <g key={i}>
            <Animated
              width={svgWidth}
              fromData={stacks[i].from}
              toData={stacks[i].to}
              fill={stacks[i].fill}
              duration={isLarge(size) ? 1000 : 1500}
              startTrigger={startTrigger}
            />
          </g>
        ))}
      </g>
    </svg>
  );
};
