import type { FinancialData } from '@/api/business-data/index.page';
import { AllMetrics } from '@/api/business-data/index.page';
import type { Connector, ConnectorDefinitionCategory, ConnectorStatus, InsightCategory } from 'aim-components';
import { sortAlphabetically, isDefined } from 'aim-utils';

export type KeyFinancialDataSourceCategory = 'opex' | 'cash-position';
export type DataSourceMap = {
  connectorCategories: ReadonlyArray<Exclude<ConnectorDefinitionCategory, 'custom_database'>>;
  keyFinancialCategories: ReadonlyArray<KeyFinancialDataSourceCategory>;
};

/** Mapping between insight categories and required data sources */
const insightCategoryDataSourceMap = {
  growth: {
    connectorCategories: ['revenue'],
    keyFinancialCategories: [],
  },
  marketingEfficiency: {
    connectorCategories: ['revenue', 'marketing'],
    keyFinancialCategories: [],
  },
  monetisation: {
    connectorCategories: ['revenue'],
    keyFinancialCategories: [],
  },
  retention: {
    connectorCategories: ['revenue'],
    keyFinancialCategories: [],
  },
  financialStrength: {
    connectorCategories: ['revenue'],
    keyFinancialCategories: ['opex', 'cash-position'],
  },
  activity: {
    connectorCategories: ['engagement'],
    keyFinancialCategories: [],
  },
  scores: {
    connectorCategories: ['revenue', 'marketing'],
    keyFinancialCategories: [],
  },
  benchmarking: {
    connectorCategories: ['revenue'],
    keyFinancialCategories: [],
  },
  dataValidation: {
    connectorCategories: ['revenue'],
    keyFinancialCategories: [],
  },
} as const satisfies Record<InsightCategory, DataSourceMap>;

export const getRelatedDataSourceCategoriesByInsightCategory = <TCategory extends InsightCategory>(
  insightCategory: TCategory | null,
) => {
  if (!insightCategory)
    return {
      connectorCategories: [],
      keyFinancialCategories: [],
    } as DataSourceMap;

  return insightCategoryDataSourceMap[insightCategory];
};

export const filterUnconfiguredConnectors = (connectors: Connector[]) => {
  return connectors.filter(
    (connector) => connector.status === 'unconfigured' || connector.status === 'authenticationError',
  );
};

export const filterAccountingConnectors = (connectors: Connector[]) => {
  const accountingConnectors = new Set(['Fortnox']);
  return connectors.filter((connector) => accountingConnectors.has(connector.name));
};

export const hasSomeConfiguredConnector = (connectors: Connector[]) => {
  return connectors.some(
    (connector) => connector.status !== 'unconfigured' && connector.status !== 'authenticationError',
  );
};

export const sortConnectorsByNumberedName = (a: Connector, b: Connector) =>
  sortAlphabetically(a.numberedName ?? a.name, b.numberedName ?? b.name);

export const hasDefinedKeyFinancialDataCellValue = (
  financialData: FinancialData,
  keyFinancial: KeyFinancialDataSourceCategory,
) => {
  if (keyFinancial === 'opex') {
    return (
      financialData[AllMetrics.ResearchAndDevelopmentExpenses].cells.some(({ value }) => isDefined(value)) ||
      financialData[AllMetrics.SalesAndMarketingExpenses].cells.some(({ value }) => isDefined(value)) ||
      financialData[AllMetrics.GeneralAdminOtherOperatingExpenses].cells.some(({ value }) => isDefined(value))
    );
  }
  return financialData[AllMetrics.CashAndEquivalents].cells.some(({ value }) => isDefined(value));
};

const CONNECTED_CONNECTOR_STATUSES: Set<ConnectorStatus> = new Set(['connected', 'syncing', 'upToDate']);

/**
 * Checks if a connector has a `status` that indicates that is has been connected.
 *
 * See {@link CONNECTED_CONNECTOR_STATUSES}
 */
export const isConnectedConnector = (connector: Pick<Connector, 'status'>) => {
  return connector.status && CONNECTED_CONNECTOR_STATUSES.has(connector.status);
};

export const isSyncingConnector = (connector: Pick<Connector, 'status'>) => {
  return connector.status === 'syncing';
};
