import type { ReactElement } from 'react';
import React, { memo } from 'react';
import cn from 'classnames';
import { Icon } from '../../Icon';
import { Spinner } from '../../Loading';
import { Typography } from '../../Typography';
import './xAxisFilter.css';

interface XAxisFilterProps {
  axisTitle?: string;
  isLoading?: boolean;
  leftAction?: () => void;
  rightAction?: () => void;
}

const XAxisFilter = ({ axisTitle, isLoading, leftAction, rightAction }: XAxisFilterProps): ReactElement => {
  return (
    <div className='xAxisFilter'>
      <span
        role='button'
        aria-label='X axis back button'
        className={cn('xAxisButton', !leftAction && 'visibility-hidden')}
        onClick={leftAction && (() => leftAction())}
      >
        <Icon icon='nav-back' />
      </span>
      {isLoading ? <Spinner /> : <Typography variant='labelAxis'>{axisTitle}</Typography>}
      <span
        role='button'
        aria-label='X axis forward button'
        className={cn('xAxisButton', !rightAction && 'visibility-hidden')}
        onClick={rightAction && (() => rightAction())}
      >
        <Icon icon='nav-forward' />
      </span>
    </div>
  );
};

export default memo(XAxisFilter);
