import * as Arrow from 'apache-arrow';
import { Table } from 'apache-arrow';
import type { AxiosRequestConfig, AxiosRequestHeaders, ResponseType } from 'axios';
import axios, { AxiosError } from 'axios';
import type { ApiRoutes, ExternalRoutes } from '../../pages/apiRoutes';
import { serverLogger } from '../logger';
import type { Readable } from 'stream';
import { logger } from 'aim-utils';

export type Method = 'GET' | 'DELETE' | 'HEAD' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH' | 'CONNECT' | 'TRACE';

interface Request {
  method?: Method;
  url: ApiRoutes | ExternalRoutes | string;
  headers?: AxiosRequestHeaders;
  data?: { [key: string]: string | number | string[] | Record<string, unknown>[] } | Record<string, unknown> | FormData;
  responseType?: ResponseType;
}

const ignoredStatuses = [404];

const logClientError = (error: AxiosError | Error): void => {
  if (error instanceof AxiosError && error.response && !ignoredStatuses.includes(error.response.status)) {
    logger.error({
      message: `Client request ${error.config?.url} failed with status ${error.response.status} | Message: ${error.message}`,
      error,
    });
  }
};

const logServerError = (error: AxiosError): void => {
  if (error.response && ignoredStatuses.includes(error.response.status)) {
    return;
  }
  if (error.response) {
    const { url, method, data: requestData, params: requestParams } = error.config ?? {};
    const { data: responseData, status, statusText } = error.response ?? {};

    serverLogger.error(`${url} failed with status ${status}`, {
      request: {
        url,
        method,
        requestData: process.env.NEXT_PUBLIC_ENV !== 'production' ? requestData : undefined,
        requestParams: process.env.NEXT_PUBLIC_ENV !== 'production' ? requestParams : undefined,
      },
      response: { responseData, status, statusText },
    });
  } else {
    serverLogger.error(`Could not reach ${error.config?.url}`);
  }
};

let tenantToProjectCache: Record<string, string> = {};
// SERVER SIDE USE ONLY
export const tenantToProject = async (tenant: string): Promise<string> => {
  if (tenantToProjectCache[tenant]) {
    return tenantToProjectCache[tenant];
  }
  const response: { tenants: Array<{ slug: string; project_code: string }> } = await serverRequest({
    url: `${process.env.NEXT_PUBLIC_TENANT_MANAGEMENT_URL}/tenants`,
  });
  tenantToProjectCache = response.tenants.reduce<Record<string, string>>((acc, tenant) => {
    acc[tenant.slug] = tenant.project_code;
    return acc;
  }, {});
  return tenantToProjectCache[tenant];
};

async function clientRequest<T>({ method = 'GET', url, headers, data, responseType = 'json' }: Request): Promise<T> {
  try {
    const response = await axios({
      method,
      url,
      headers,
      data,
      responseType,
    });
    return response.data;
  } catch (error) {
    if (error instanceof Error || error instanceof AxiosError) logClientError(error);
    return Promise.reject(error);
  }
}

export async function clientRequestNoBearerToken<T>({ method = 'GET', url, headers, data }: Request): Promise<T> {
  const response = await axios({
    method,
    url,
    headers,
    data,
  });
  return response.data;
}

// We want to have separate logging for server-side requests
// These are caught by kubernetes and displayed in Datadog

const axiosServerSideInstance = axios.create();
axiosServerSideInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    logServerError(error);
    return Promise.reject(error);
  },
);

export async function serverRequest<T>({ method = 'GET', url, headers, data }: AxiosRequestConfig): Promise<T> {
  const response = await axiosServerSideInstance({
    method,
    url,
    headers,
    data,
  });
  return response.data;
}

export async function openEventStream({ method = 'POST', url, headers, data }: AxiosRequestConfig) {
  const response = await axiosServerSideInstance<Readable>({
    responseType: 'stream',
    method,
    url,
    headers,
    data,
  });

  return response;
}

export const arrowRequest = async (url: string): Promise<Table> => {
  try {
    const response = await axiosServerSideInstance(url, { responseType: 'arraybuffer' });
    return Arrow.tableFromIPC(response.data);
  } catch (error: unknown) {
    if (error instanceof AxiosError && (error?.response?.status === 404 || error?.response?.status === 503)) {
      return Promise.resolve(new Table());
    }
    serverLogger.error('Failed to parse arrow response!', { url, error });
    return Promise.reject(error);
  }
};

export function fetcher<T>(url: string): Promise<T> {
  return clientRequest<T>({ url });
}

export default clientRequest;

export const arrowRequestIsEmpty = (table: Table) => {
  return table.numRows === 0;
};
