import { fetcher } from '@/lib/request/request';
import { setUserSetting, useUserSetting } from '@/lib/settings';
import { EMPTY_ARRAY_STATIC_REFERENCE } from 'aim-components';
import { useRouter } from 'next/router';
import { ApiRoutes } from 'pages/apiRoutes';
import { useEffect, useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

/**
 * The number of most recently visited tenants to keep track of in the history.
 */
const TENANT_HISTORY_LIMIT = 10;

/**
 * Hook used to keep track of a user's **recently visited tenants**.
 * A maximum of `TENANT_HISTORY_LIMIT` tenants are kept in the history.
 *
 * The hook listens to changes in the `slug` query parameter, and updates the history accordingly.
 * In this way, both tenant switches made through the URL and the UI are tracked.
 *
 * The history is stored in the user's settings, and is sorted by most recently visited tenant (e.g. `recentTenants[0]` is the most recently visited tenant).
 */
export const useTenantHistory = ({ enabled }: { enabled: boolean }) => {
  const { data: allTenants = EMPTY_ARRAY_STATIC_REFERENCE, isValidating: isLoadingTenants } = useSWRImmutable(
    enabled ? ApiRoutes.GetTenants : null,
    fetcher<string[]>,
  );

  const { data: recentTenantsData, isValidating: isLoadingHistory } = useUserSetting(enabled ? 'recent-tenants' : null);

  const router = useRouter();
  const { slug } = router.query;

  const isLoadingTenantHistory = isLoadingTenants || isLoadingHistory;
  const isValidSlug = typeof slug === 'string' && allTenants.includes(slug);
  const enableHistoryTracking = enabled && isValidSlug && !isLoadingTenantHistory;

  const recentTenants = useMemo(() => {
    const tenantHistory = recentTenantsData?.tenants ?? EMPTY_ARRAY_STATIC_REFERENCE;

    // * Only keep tenants that are still in the list of available tenants, e.g. if a tenant has been removed, or the user has lost access to it.
    return tenantHistory.filter((tenant) => allTenants.includes(tenant));
  }, [allTenants, recentTenantsData?.tenants]);

  useEffect(() => {
    if (!enableHistoryTracking) return;

    // * The current tenant is already the most recently visited tenant – no need to update the history.
    if (recentTenants.at(0) === slug) return;

    const newTenantHistory = [slug, ...recentTenants.filter((tenant) => tenant !== slug)];
    const trimmedTenantHistory = newTenantHistory.slice(0, TENANT_HISTORY_LIMIT);

    (async () => {
      await setUserSetting('recent-tenants', { tenants: trimmedTenantHistory });
    })();
  }, [enableHistoryTracking, recentTenants, slug]);

  return { recentTenants, isLoadingTenantHistory } as const;
};
