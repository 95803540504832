import type { SeriesInfo } from 'scichart';

/** Calculates a tooltip hash for a single hovered data series */
export const getTooltipHash = (seriesInfo: SeriesInfo) => {
  return `${seriesInfo.renderableSeries.id}-${seriesInfo.xValue}-${seriesInfo.yValue}`;
};

export const getTooltipHashFromParts = (id: string, xValue: number, yValue: number) => {
  return `${id}-${xValue}-${yValue}`;
};
