import React from 'react';

export const SCORE_CONFIG = {
  viewBoxWidth: 1024,
  viewBoxHeight: 512,
  outerArcRadiuses: {
    small: 512,
    large: 512,
  },
  innerArcRadiuses: {
    small: 280,
    large: 300,
  },
} as const;

export type ArcSize = 'small' | 'large';

interface GradientProps {
  startColor: string;
  stopColor: string;
  id: string;
  orientation: 'horizontal' | 'vertical';
  startColorOffset?: number;
  stopColorOffset?: number;
}

export const Gradient = ({
  startColor,
  stopColor,
  id,
  orientation,
  startColorOffset,
  stopColorOffset,
}: GradientProps) => {
  return (
    <defs>
      <linearGradient
        id={id}
        x1='0%'
        y1='0%'
        x2={`${orientation === 'horizontal' ? '100%' : '0%'}`}
        y2={`${orientation === 'horizontal' ? '0%' : '100%'}`}
      >
        <stop offset={`${startColorOffset ?? 0}%`} stopColor={startColor} />
        <stop offset={`${stopColorOffset ?? 100}%`} stopColor={stopColor} />
      </linearGradient>
    </defs>
  );
};

export const isSafari = () => {
  const agent = window?.navigator.userAgent;
  return agent.includes('Safari') && !agent.includes('Chrome');
};

export const randomValueBetween = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};
