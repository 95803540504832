import { TenantSelector } from '@/components/layouts/navigation/navigation-menu/tenant-selector/TenantSelector';
import { TenantSelectorMobile } from '@/components/layouts/navigation/navigation-menu/tenant-selector/TenantSelector.mobile';
import { Routes } from '@/lib/routes';
import { HoverTooltip, NavigationMenu as Nav, Typography } from 'aim-components';
import { useCustomEventDispatcher } from 'aim-components/src/utils/useCustomEvent';
import { useIntercomContext, useMobile } from 'aim-utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type Dispatch, type SetStateAction, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigationMenuItems } from '../hooks';
import { NavigationMenuUserProfile } from './user-profile/NavigationMenuUserProfile';
import { SkeletonMenuContent } from './skeleton-menu-content/SkeletonMenuContent';
import styles from './NavigationMenu.module.css';
import { NavigationMenuUserProfileMobile } from '@/components/layouts/navigation/navigation-menu/user-profile/NavigationMenuUserProfile.mobile';
import { useRenderNavigationMenuContent } from '@/components/layouts/navigation/navigation-menu/hooks';
import { useTranslation } from 'next-i18next';
import { useNavigationHeaderCallToActions } from '@/components/subscription-plans/navigation-header-call-to-actions/hooks';

const skeletonSections = [{ items: 7 }, { items: 2 }, { items: 3 }, { items: 3 }, { items: 2 }];

export interface NavigationMenuProps {
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

export const NavigationMenu = ({ expanded, setExpanded }: NavigationMenuProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { mobileView } = useMobile();
  const { shouldShowCallToAction } = useNavigationHeaderCallToActions();

  const dispatchNavigationTransitionEvent = useCustomEventDispatcher('navigationMenuTransitionChanged');
  const { updateIntercomBlocker } = useIntercomContext();

  const [activePath, setActivePath] = useState(router.pathname);

  useEffect(() => {
    // ? We keep track of the active path here, instead of using router.pathname, as that only updates after the route change is completed.
    // ? This enables us to instantly update the active state in the navigation menu upon clicking a link.
    const handleRouteChangeStart = (url: string) => setActivePath(url);
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => router.events.off('routeChangeStart', handleRouteChangeStart);
  }, [router.events]);

  useLayoutEffect(() => {
    if (mobileView) return;
    dispatchNavigationTransitionEvent({ done: false });
  }, [dispatchNavigationTransitionEvent, expanded, mobileView]);

  // TODO: We might want to handle this in onClick instead, to avoid waiting for router to change. Using this as a proof of concept for now.
  useEffect(() => {
    if (!mobileView) return;
    setExpanded(false);
  }, [mobileView, router.pathname, setExpanded]);

  useEffect(() => {
    updateIntercomBlocker('mobile-navigation-menu', { hideIntercom: mobileView && expanded });
  }, [mobileView, expanded, updateIntercomBlocker]);

  const { items, isLoading } = useNavigationMenuItems();

  const { renderNavigationMenuPart } = useRenderNavigationMenuContent({
    activePath,
    enableTooltip: !expanded && !mobileView,
  });

  return (
    <Nav
      expanded={expanded}
      onToggle={() => setExpanded((prev) => !prev)}
      onToggleTransitionEnd={() => dispatchNavigationTransitionEvent({ done: true })}
    >
      <Nav.Header>
        {expanded && <>{mobileView ? <TenantSelectorMobile /> : <TenantSelector />}</>}
        <HoverTooltip
          message={expanded ? undefined : 'Expand'}
          position='right'
          renderTrigger={({ setReference, referenceProps }) => (
            <Nav.Toggle ref={setReference} icon={mobileView ? 'close' : 'sidebar'} {...referenceProps} />
          )}
        />
      </Nav.Header>

      <Nav.Scrollable>
        <>{isLoading ? <SkeletonMenuContent sections={skeletonSections} /> : items.map(renderNavigationMenuPart)}</>
      </Nav.Scrollable>

      <Nav.Footer>
        {shouldShowCallToAction && expanded && (
          <Link href={'/' + router.query.slug + Routes.SubscriptionPlans}>
            <Typography variant='text1' color='secondary' truncate className={styles.upgradePlan}>
              {t('subscriptionPlan.upgradePlan')}
            </Typography>
          </Link>
        )}

        {mobileView ? (
          <NavigationMenuUserProfileMobile expanded={expanded} />
        ) : (
          <NavigationMenuUserProfile expanded={expanded} />
        )}
      </Nav.Footer>
    </Nav>
  );
};
