import type { Type } from 'apache-arrow';
import type { BaseDataPoint } from '../utils';
import { createTimeGranularityTuple } from '@/components/insights/charts/common/types';

export const RETENTION_TRENDS_TIME_GRANULARITIES = createTimeGranularityTuple('quarters', 'months');

export enum RetentionTrendsMetric {
  Retention = 'metric-1',
  CustomersWithRepeatPurchase = 'metric-2',
}

export const RetentionTrendsDataset = {
  '1m': 'dataset-1',
  '3m': 'dataset-2',
  '6m': 'dataset-3',
  '12m': 'dataset-4',
  '18m': 'dataset-5',
  '24m': 'dataset-6',
  '36m': 'dataset-7',
} as const;

export const RetentionTrendsDatasets = Object.values(RetentionTrendsDataset);
export type RetentionTrendsDatasetType = (typeof RetentionTrendsDatasets)[number];

export const RetentionTrendsDatasetToTimeFilterMap = {
  [RetentionTrendsDataset['1m']]: '1m',
  [RetentionTrendsDataset['3m']]: '3m',
  [RetentionTrendsDataset['6m']]: '6m',
  [RetentionTrendsDataset['12m']]: '12m',
  [RetentionTrendsDataset['18m']]: '18m',
  [RetentionTrendsDataset['24m']]: '24m',
  [RetentionTrendsDataset['36m']]: '36m',
} as const;

export interface RetentionTrendsArrowRow {
  date_month?: Type.Utf8;
  date_quarter?: Type.Utf8;
  active_customer_retention_1m: Type.Float;
  active_customer_retention_3m: Type.Float;
  active_customer_retention_6m: Type.Float;
  active_customer_retention_12m: Type.Float;
  active_customer_retention_18m: Type.Float;
  active_customer_retention_24m: Type.Float;
  active_customer_retention_36m: Type.Float;
  share_customers_with_repeat_purchase_1m: Type.Float;
  share_customers_with_repeat_purchase_3m: Type.Float;
  share_customers_with_repeat_purchase_6m: Type.Float;
  share_customers_with_repeat_purchase_12m: Type.Float;
  share_customers_with_repeat_purchase_18m: Type.Float;
  share_customers_with_repeat_purchase_24m: Type.Float;
  share_customers_with_repeat_purchase_36m: Type.Float;
  transposed_metrics_1m_is_forecast: Type.Bool;
  transposed_metrics_3m_is_forecast: Type.Bool;
  transposed_metrics_6m_is_forecast: Type.Bool;
  transposed_metrics_12m_is_forecast: Type.Bool;
  transposed_metrics_18m_is_forecast: Type.Bool;
  transposed_metrics_24m_is_forecast: Type.Bool;
  transposed_metrics_36m_is_forecast: Type.Bool;
}

export interface RetentionTrendsApiResponse {
  meta: { displayUnit: string; availableFilters: Record<string, boolean>; xMin: string; xMax: string };
  metrics: Record<RetentionTrendsMetric, Record<RetentionTrendsDatasetType, BaseDataPoint[]>>;
}
