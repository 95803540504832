import type { ComponentProps, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { forwardRef, useCallback } from 'react';
import { Icon, type IconName } from '../Icon';
import { Typography } from '../Typography';
import styles from './ContextMenu.module.css';
import cn from 'classnames';
import { useFloatingTree } from '@floating-ui/react';
import { TextInput } from '../Input';
import { Spinner } from '../Loading';
import { ColorSpectrum } from 'aim-utils';
import { useBasicListNavigation } from './hooks';

export const ContextMenu = ({ children, className, ...props }: { children: ReactNode } & ComponentProps<'div'>) => {
  return (
    <div className={cn(styles.contextMenu, className)} {...props}>
      {children}
    </div>
  );
};

const ContextMenuTitle = ({ children }: { children: ReactNode }) => {
  return (
    <Typography variant='text2' className={styles.title} color='secondary'>
      {children}
    </Typography>
  );
};

const ContextMenuList = ({ children, className, ...props }: { children: ReactNode } & ComponentProps<'ul'>) => {
  return (
    <ul className={cn(styles.contextMenuList, className)} {...props}>
      {children}
    </ul>
  );
};

type ContextMenuListItemProps = {
  icon?: IconName;
  endIcon?: IconName;
  destructive?: boolean;
  label: string;
  highlight?: boolean;
  onClick?: (e: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>) => void;
} & ComponentProps<'li'>;

const ContextMenuListItem = forwardRef<HTMLLIElement, ContextMenuListItemProps>(
  ({ icon, endIcon, label, destructive, highlight = false, onClick, ...props }, ref) => {
    const tree = useFloatingTree();

    const handleOnClick = useCallback(
      (e: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>) => {
        if (onClick) {
          onClick(e);
          tree && tree.events.emit('click');
        }
      },
      [onClick, tree],
    );

    const { onKeyDown } = useBasicListNavigation({ onSelect: onClick });

    return (
      <li
        onClick={handleOnClick}
        className={cn(styles.contextMenuListItem, {
          [styles.clickable]: onClick !== undefined,
          [styles.highlight]: highlight,
        })}
        onKeyDown={onKeyDown}
        ref={ref}
        tabIndex={0}
        {...props}
      >
        <div className={cn(styles.itemLabelContainer, destructive && styles.destructiveItem)}>
          {icon && (
            <Icon
              icon={icon}
              className={styles.itemIcon}
              fillColor={destructive ? ColorSpectrum['vivid-red'][300] : undefined}
            />
          )}
          <span className={cn(styles.itemLabel, destructive && styles.destructiveItem)}>{label}</span>
          {endIcon && (
            <Icon
              icon={endIcon}
              className={styles.itemIcon}
              fillColor={destructive ? ColorSpectrum['vivid-red'][300] : undefined}
            />
          )}
        </div>
      </li>
    );
  },
);

ContextMenuListItem.displayName = 'ContextMenuListItem';

const ContextMenuSearch = (props: ComponentProps<typeof TextInput>) => {
  return (
    <div className={styles.contextMenuSearch}>
      <TextInput condensed icon={<Icon icon='search' />} showValidationErrors={false} {...props} />
    </div>
  );
};

const ContextMenuDivider = () => {
  return <div className={styles.contextMenuDivider} />;
};

const ContextMenuSpinner = () => {
  return (
    <div className={styles.contextMenuSpinner}>
      <Spinner />
    </div>
  );
};

ContextMenu.Title = ContextMenuTitle;
ContextMenu.List = ContextMenuList;
ContextMenu.ListItem = ContextMenuListItem;
ContextMenu.Divider = ContextMenuDivider;
ContextMenu.Search = ContextMenuSearch;
ContextMenu.Spinner = ContextMenuSpinner;
