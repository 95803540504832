import { useMemo } from 'react';
import { ConnectorListItem } from './ConnectorListItem';
import styles from './ConnectorList.module.css';
import cn from 'classnames';
import type { Connector } from '../types';
import { Checkbox } from '../../Input';

export const ConnectorSelectList = ({
  connectors,
  markedConnectors,
  connectorsAsCards,
  onCheckboxChange,
}: {
  connectors: Connector[];
  markedConnectors: Connector[];
  connectorsAsCards?: boolean;
  onCheckboxChange: (connector: Connector) => void;
}) => {
  const markedConnectorIds = useMemo(
    () => new Set(markedConnectors.map((connector) => connector.definitionId)),
    [markedConnectors],
  );

  if (connectors.length > 0) {
    return (
      <div className={cn(styles.list, connectorsAsCards && styles.connectorsAsCards)}>
        {connectors.map((item, index) => {
          return (
            <ConnectorListItem
              key={item.definitionId + index}
              name={item.name}
              logoSrc={item.logo}
              connectorsAsCards={connectorsAsCards}
              onClick={() => onCheckboxChange(item)}
              checkBox={
                <Checkbox
                  initialValue={markedConnectorIds.has(item.definitionId)}
                  onChange={() => onCheckboxChange(item)}
                />
              }
            />
          );
        })}
      </div>
    );
  }
  return null;
};
