import type { ChartMobile, InsightCategory } from 'aim-components';
import type { ChartActions } from 'aim-components/src/components/Chart/ChartContainer/types';
import type { ComponentProps } from 'react';

export interface Option<TValue extends string = string> {
  label: string;
  value: TValue;
  color?: string;
  tab?: string;
}

const TIME_GRANULARITIES = ['years', 'quarters', 'months', 'weeks', 'days'] as const;
export type TimeGranularityTuple = typeof TIME_GRANULARITIES;
export type TimeGranularity = TimeGranularityTuple[number];

export enum CohortGranularity {
  months = 'months',
  quarters = 'quarters',
}

type OrderedSubTuples<Tuple extends readonly unknown[]> = Tuple extends readonly [infer Head, ...infer Tail]
  ? [Head, ...OrderedSubTuples<Tail>] | OrderedSubTuples<Tail> | [Head]
  : never;

/**
 * Union type of time granularity tuple combinations, in **increasing order of granularity**.
 *
 * @example
 * ['years', 'quarters', 'months', 'weeks', 'days'] // ✅ Valid order – part of the union type.
 * ['quarters', 'weeks'] // ✅ Valid order – part of the union type.
 * ['days', 'weeks'] // ❌ Invalid order - NOT a part of the union type.
 */
type OrderedTimeGranularityTuple = Readonly<OrderedSubTuples<TimeGranularityTuple>>;

/**
 * Small utility function to create an ordered tuple of time granularities, in increasing order of granularity.
 * Provides type-safety and auto-completions for better DX, and ensures that the order of granularities is correct.
 *
 * @example createTimeGranularityTuple('years', 'months', 'days') // ['years', 'months', 'days']
 */
export const createTimeGranularityTuple = <TTuple extends OrderedTimeGranularityTuple>(
  ...orderedTimeGranularityTuple: TTuple
) => {
  return orderedTimeGranularityTuple;
};

export type AcquisitionGranularity = 'quarters' | 'months';

export type DefaultGranularity = 'quarters' | 'months' | 'days';

export interface TooltipDataRowProps {
  label?: string;
  color?: string;
  value?: string | number;
  unit?: string;
  type?: 'bar' | 'line' | 'scatter';
  percentage?: string;
  isForecast?: boolean;
  size?: 'small' | 'regular';
}

type ChartKeyMap = Record<Capitalize<string>, string>;

export const GrowthChartKey = {
  NetRevenueForecast: '1',
  CohortBuildup: '3',
  AnnualisedRevenue: '4',
  Concentration: '6',
  MetricsValidation: '7',
  GrowthRate: '37',
  RecurringVsNonRecurringRevenue: '38',
} as const satisfies ChartKeyMap;

export const MonetisationChartKey = {
  LtvOverCac: '17',
  AveragePurchase: '19',
  LtvRetentionCohorts: '20',
} as const satisfies ChartKeyMap;

export const RetentionChartKey = {
  LtvRetentionCohorts: '13',
  RetentionTrends: '15',
  CohortBuildup: '16',
  ActiveCustomerRetention: '35',

  RetentionTable: '39',
  CustomerGrowthRate: '53',
} as const satisfies ChartKeyMap;

export const MarketingChartKey = {
  MarketingCustomers: '8',
  LtvOverCac: '10',
  LtvCacEngagement: '11',
  AnnualisedRevenue: '12',
  NewCustomersVsMarketing: '5',
} as const satisfies ChartKeyMap;

export const FinancialStrengthChartKey = {
  CashForecasting: '21',
} as const satisfies ChartKeyMap;

export const ForecastingChartKey = {
  Forecasting: 'ForeCasting::Forecasting',
  NetRevenue: '40',
  CashForecasting: '41',
  ActiveCustomers: '42',
  SalesNet: '43',
  Retention: '44',
  NewCustomers: '45',
  AnnualisedRecurringRevenue: '46',
} as const satisfies ChartKeyMap;

export const ActivityChartKey = {
  UserActivity: '23',
  EngagementRate: '25',
  UserGrowth: '24',
  UserActivityRetentionTrends: '28',
  UserRetentionCohorts: '27',
  ActiveUsersCohortBuildup: '26',
} as const satisfies ChartKeyMap;

export const ScoreKey = {
  Ark: '29',
  Growth: '30',
  MarketingEfficiency: '31',
  Monetisation: '32',
  Retention: '33',
  FinancialStrength: '34',
} as const satisfies ChartKeyMap;

export const BenchmarkingKey = {
  NetSalesRate: '47',
  ARRRate: '48',
  RetentionRate: '49',
  NetSalesForecast: '50',
  ARRForecast: '51',
  RetentionForecast: '52',
} as const satisfies ChartKeyMap;

export type ForecastingChartKeyValue = (typeof ForecastingChartKey)[keyof typeof ForecastingChartKey];
export type ScoreKeyValue = (typeof ScoreKey)[keyof typeof ScoreKey];
export type BenchmarkingKeyValue = (typeof BenchmarkingKey)[keyof typeof BenchmarkingKey];

export type ChartKey =
  | (typeof GrowthChartKey)[keyof typeof GrowthChartKey]
  | (typeof MonetisationChartKey)[keyof typeof MonetisationChartKey]
  | (typeof RetentionChartKey)[keyof typeof RetentionChartKey]
  | (typeof MarketingChartKey)[keyof typeof MarketingChartKey]
  | (typeof FinancialStrengthChartKey)[keyof typeof FinancialStrengthChartKey]
  | (typeof ActivityChartKey)[keyof typeof ActivityChartKey]
  | ForecastingChartKeyValue
  | ScoreKeyValue
  | BenchmarkingKeyValue;

export const chartKeyInsightCategoryMap = new Map<ChartKey, InsightCategory>();

const mapping = [
  [GrowthChartKey, 'growth'],
  [MonetisationChartKey, 'monetisation'],
  [RetentionChartKey, 'retention'],
  [MarketingChartKey, 'marketingEfficiency'],
  [FinancialStrengthChartKey, 'financialStrength'],
  [ActivityChartKey, 'activity'],
  [ScoreKey, 'scores'],
  [BenchmarkingKey, 'benchmarking'],
] as const;

mapping.forEach(([chartKeyCategory, insightCategory]) => {
  Object.values(chartKeyCategory).forEach((chartKey) => {
    chartKeyInsightCategoryMap.set(chartKey, insightCategory);
  });
});

chartKeyInsightCategoryMap.set(ForecastingChartKey.CashForecasting, 'growth');
chartKeyInsightCategoryMap.set(ForecastingChartKey.NetRevenue, 'financialStrength');

export enum FilterKeys {
  TimeGranularity = 'TimeGranularity',
  Country = 'Country',
  BreakDown = 'BreakDown',
  Metrics = 'Metrics',
  XAxisToggle = 'XAxisToggle',
  Timespan = 'Timespan',
  Percentage = 'Percentage',
}

export type TimeGranularityOption = Option & {
  label: string;
  value: TimeGranularity;
};

export type FormatMobileTooltipTitleData = Parameters<
  NonNullable<ComponentProps<typeof ChartMobile>['formatTooltipTitle']>
>[0];

export interface InsightProps<T> {
  initialState?: Partial<T>;
  customActions?: ChartActions;
  sidebarInitiallyOpened?: boolean;
  onStateChange?: (state: T, name: string) => void;
}
