import { useContext, type ReactElement, type ReactNode } from 'react';
import { TextInput } from '../../Input';
import { Typography } from '../../Typography';
import { Icon } from '../../Icon';
import { MAX_LENGTH_CHART_NAME, isCustomTitleValid } from './util';
import './ChartHeader.css';
import { LineColors } from 'aim-utils';
import { InsightContext } from '../InsightContext';
import { HoverTooltip } from '../../HoverTooltip';
import { ForecastChartContext } from '../ForecastChartContext';

export const ChartHeaderTitle = ({
  title,
  customHeaderTitle,
  setCustomHeaderTitle,
  onInfoClick,
  badge,
  legendTop,
}: {
  title?: string;
  customHeaderTitle?: string;
  setCustomHeaderTitle?: (title: string) => void;
  onInfoClick?: () => void;
  badge?: ReactNode;
  legendTop?: ReactNode;
}): ReactElement => {
  const insightContext = useContext(InsightContext);
  const forecastChartContext = useContext(ForecastChartContext);
  if (onInfoClick) {
    return (
      <div className='Chart-header-title-with-info'>
        <Typography variant='label2'>{customHeaderTitle ?? title}</Typography>
        <div className='Chart-actions'>
          <HoverTooltip message='Info' position='right'>
            <Icon icon='info-circle' role='button' onClick={onInfoClick} aria-label='Show chart information drawer' />
          </HoverTooltip>
          {insightContext?.missingChartDataIndicator?.display === true && (
            <HoverTooltip
              message={"Some of your data sources haven't been connected. Click to connect."}
              position='right'
            >
              <Icon
                icon='cloud-exclamation'
                role='button'
                size='medium'
                fillColor={LineColors.gold}
                onClick={() =>
                  insightContext?.missingChartDataIndicator?.onClick(title, forecastChartContext.nonReliableForecast)
                }
              />
            </HoverTooltip>
          )}
        </div>
      </div>
    );
  } else if (setCustomHeaderTitle) {
    return (
      <div className={'Chart-header-title'}>
        {customHeaderTitle !== title && (
          <>
            <Typography variant='text2' color='secondary'>
              {title}
            </Typography>
          </>
        )}
        <TextInput
          value={customHeaderTitle ?? title}
          variant='label2'
          onChange={(e) => {
            setCustomHeaderTitle(e.target.value);
          }}
          onBlur={() => {
            //default to original title if the user clears the input field and hits save
            if (!customHeaderTitle || customHeaderTitle.length === 0) {
              setCustomHeaderTitle(title ?? '');
            }
          }}
          isValid={isCustomTitleValid(customHeaderTitle)}
          validationError={`Maximum ${MAX_LENGTH_CHART_NAME} characters reached`}
          showValidationErrors
        />
      </div>
    );
  }

  if (badge || legendTop) {
    return (
      <div className={'Chart-header-with-legend'}>
        <div className={'Chart-header-with-legend-inner'}>
          <Typography variant='label2' truncate>
            {customHeaderTitle ?? title}
          </Typography>
          {badge}
        </div>
        {legendTop}
      </div>
    );
  }

  return (
    <div className={'Chart-header-title'}>
      {customHeaderTitle && customHeaderTitle !== title && (
        <Typography variant='text2' color='secondary'>
          {title}
        </Typography>
      )}
      <div className={'Chart-header-title-container'}>
        <Typography variant='label2'>{customHeaderTitle ?? title}</Typography>
      </div>
    </div>
  );
};
