import styles from './ChartOverlayContent.module.css';
import cn from 'classnames';
import { useMemo, type CSSProperties, type ReactNode } from 'react';
import { Typography } from '../../Typography';

export const ChartOverlayContent = ({ children }: { children: ReactNode }) => {
  return <div className={styles.chartOverlayContent}>{children}</div>;
};

const Label = ({
  children,
  color,
  className,
}: {
  children: ReactNode;
  color?: CSSProperties['color'];
  className?: string;
}) => {
  return (
    <div
      className={cn(styles.label, className)}
      style={{
        ['--override-text-color' as string]: color,
      }}
    >
      <Typography variant='label3'>{children}</Typography>
    </div>
  );
};

const ChartFilterSummary = ({ labelParts, padding = true }: { labelParts: string[]; padding?: boolean }) => {
  const content = useMemo(() => labelParts.join(' | '), [labelParts]);
  return (
    <Typography variant='subtitle' className={cn(padding && styles.chartFilterSummary)}>
      {content}
    </Typography>
  );
};

const BigText = ({
  children,
  outline = false,
  color,
}: {
  children: ReactNode;
  outline?: boolean;
  color?: CSSProperties['color'];
}) => {
  return (
    <p
      className={cn(styles.bigText, outline && styles.outline)}
      style={{
        ['--override-text-color' as string]: color,
      }}
    >
      {children}
    </p>
  );
};

ChartOverlayContent.Label = Label;
ChartOverlayContent.BigText = BigText;
ChartOverlayContent.ChartFilterSummary = ChartFilterSummary;
