import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useRef, useContext } from 'react';
import type { ChartDataset, SeriesMetadataMap } from '../types';
import type { SciChartSurface, CustomAnnotation } from 'scichart';
import {
  EHorizontalAnchorPoint,
  EVerticalAnchorPoint,
  EllipsePointMarker,
  FastColumnRenderableSeries,
  FastMountainRenderableSeries,
  NumberRange,
  SciChartOverview,
  SplineMountainRenderableSeries,
  Thickness,
  XyDataSeries,
  XyScatterRenderableSeries,
  generateGuid,
} from 'scichart';
import { getUnixTimestampInSeconds } from '../utils/data-utils';
import { OVERVIEW_DARK_THEME } from '../themes/theme-dark';
import { ColorSpectrum, dateToString } from 'aim-utils';
import { unSelectedArea, createAccumulatedYValues, adornerSvgStringTemplate } from './utils';
import { isXyDataSeries } from '../utils/type-guards';
import type { XAxisConfig } from '../utils/axes/x-axis.utils';
import type { YAxisConfig } from '../utils/axes/y-axis.utils';
import { updateYAxisVisibleRange } from '../utils/axes/y-axis.utils';
import { createXyDataSeries, defaultStrokeThickness, traverseRenderableSeries } from '../utils/dataseries-utils';
import { createDataLabelAnnotation, getDataLabelAnnotationSvgString } from '../utils/annotation-utils';
import { filterDataPointsInRange } from '../utils/data-utils';
import { HOVERED_POINT_MARKER_STYLE } from '../hooks/hover.hooks';
import { SliderContext } from 'aim-components';

export const useSlider = ({
  surfaceRef,
  isInitialized,
  xAxis,
  yAxes,
  datasets,
  selectedDataset,
}: {
  datasets: ChartDataset[];
  selectedDataset: string[];
  surfaceRef: MutableRefObject<SciChartSurface | null>;
  isInitialized: boolean;
  xAxis: XAxisConfig;
  yAxes: YAxisConfig[];
}) => {
  const overviewId = useRef(`overview-id-${generateGuid()}`);
  const overviewRef = useRef<SciChartOverview | null>(null);
  const hasCreatedOverview = useRef<boolean>(false);
  const { slider, fullRange } = xAxis;

  const sliderContext = useContext(SliderContext);

  const surface = surfaceRef.current;

  useEffect(
    () => () => {
      overviewRef.current?.delete();
    },
    [],
  );

  // Add deafult value if no range exist
  useEffect(() => {
    if (!surface || !surface.id) return;
    if (!sliderContext.visibleRangeRef.current.min && !sliderContext.visibleRangeRef.current.max && isInitialized) {
      sliderContext.visibleRangeRef.current = {
        min: slider?.defaultMin
          ? getUnixTimestampInSeconds(slider.defaultMin)
          : getUnixTimestampInSeconds(fullRange.min),
        max: slider?.defaultMax
          ? getUnixTimestampInSeconds(slider.defaultMax)
          : getUnixTimestampInSeconds(fullRange.max),
      };
    }
  }, [isInitialized, slider, sliderContext.visibleRangeRef, fullRange.min, fullRange.max, surface]);

  // Update visible range on surface change
  useEffect(() => {
    if (!surface || !surface.id) return;
    if (isInitialized && slider) {
      surface.xAxes.get(0).visibleRangeChanged.subscribe((data) => {
        // Don't trigger on first render
        if (
          sliderContext.visibleRangeRef.current.min !== data?.visibleRange.min ||
          sliderContext.visibleRangeRef.current.max !== data?.visibleRange.max
        ) {
          slider.onSliderChange &&
            data &&
            slider.onSliderChange({
              min: { label: dateToString(new Date(data.visibleRange.min * 1000)) },
              max: { label: dateToString(new Date(data.visibleRange.max * 1000)) },
              changedByUser: true,
            });

          // * Adjusts the Y axis when the overview slider moves
          updateYAxisVisibleRange({ surface, yAxesConfig: yAxes });
        }

        sliderContext.visibleRangeRef.current = { min: data?.visibleRange.min, max: data?.visibleRange.max };
      });
    }
  }, [isInitialized, slider, sliderContext.visibleRangeRef, surface, yAxes]);

  // Set new range base on slider context
  useEffect(() => {
    if (!surface || !surface.id) return;
    if (!isInitialized || !sliderContext) return;
    const { min, max } = sliderContext.visibleRangeRef.current;
    if (surface.xAxes.get(0).visibleRange) {
      const { min: currentMin, max: currentMax } = surface.xAxes.get(0).visibleRange;
      surface.xAxes.get(0).visibleRange = new NumberRange(min ?? currentMin, max ?? currentMax);
    }
  }, [isInitialized, sliderContext, surface, slider]);

  const overviewStackedDataSeries = useRef<FastMountainRenderableSeries | undefined>();
  const generateOutlineSeriesForStacks = useCallback(
    async (datasets: ChartDataset[], selected: string[]) => {
      if (!surface || !surface.id || !sliderContext || !slider || !isInitialized) return; // TODO move to helpfunction that can be recuserr

      if (!datasets || datasets.length === 0 || !selected || selected.length === 0) {
        overviewStackedDataSeries.current?.dataSeries.clear();
        return;
      }

      const xValues = datasets[0].data.map((value) => getUnixTimestampInSeconds(value.x));
      const filteredDatasets = datasets.filter((dataset) => selected.includes(dataset.label));

      const accumulatedYValues = createAccumulatedYValues(filteredDatasets, xValues);

      // * If the data series already exists, we update it with the new data. Otherwise, we create a new instance.
      if (overviewStackedDataSeries.current && isXyDataSeries(overviewStackedDataSeries.current.dataSeries)) {
        overviewStackedDataSeries.current.dataSeries.clear();
        overviewStackedDataSeries.current.dataSeries.appendRange(xValues, accumulatedYValues);
        return;
      }

      if (hasCreatedOverview.current) {
        return;
      }
      hasCreatedOverview.current = true;

      const ctx = surface.webAssemblyContext2D;

      const min = fullRange.min;
      const max = fullRange.max;

      const overview = await SciChartOverview.create(surface, overviewId.current, {
        transformRenderableSeries: () => {
          const dataSeries = new XyDataSeries(ctx, {
            xValues,
            yValues: accumulatedYValues,
          });
          const outlineDataSeries = new FastMountainRenderableSeries(ctx, {
            dataSeries,
            fill: ColorSpectrum['cool-grey'][400],
            stroke: ColorSpectrum['cool-grey'][400],
          });

          overviewStackedDataSeries.current = outlineDataSeries;
          return outlineDataSeries;
        },
        overviewXAxisOptions: {
          zoomExtentsRange: new NumberRange(
            min ? getUnixTimestampInSeconds(new Date(min)) : undefined,
            max ? getUnixTimestampInSeconds(new Date(max)) : undefined,
          ),
        },
        padding: Thickness.fromString('0 0 0 0'),
        theme: OVERVIEW_DARK_THEME,
      });

      overview.parentSciChartSurface.xAxes.get(0).visibleRangeChanged.subscribe((data) => {
        sliderContext.visibleRangeRef.current = { min: data?.visibleRange.min, max: data?.visibleRange.max };
      });
      overview.rangeSelectionModifier.unselectedsvgString = unSelectedArea;
      overview.rangeSelectionModifier.rangeSelectionAnnotation.adornerSvgStringTemplate = adornerSvgStringTemplate;
      overviewRef.current = overview;
    },
    [surface, sliderContext, slider, isInitialized, fullRange.min, fullRange.max],
  );

  const generateOutlineSeries = useCallback(
    async (datasets: ChartDataset[], selectedDatasets: string[]) => {
      if (!surface || !surface.id || !sliderContext || !slider || !isInitialized) return;

      if (!datasets || datasets.length === 0 || !selectedDatasets || selectedDatasets.length === 0) {
        return;
      }

      const ctx = surface.webAssemblyContext2D;
      const min = fullRange.min;
      const max = fullRange.max;

      const createLinesFromDatasets = () => {
        datasets
          .filter((dataset) => selectedDatasets.includes(dataset.label) && dataset.yAxisId !== 'y1')
          .forEach((dataset) => {
            const dataSeries = createXyDataSeries(ctx, dataset);

            if (dataset.type === 'bar') {
              const series = new FastColumnRenderableSeries(ctx, {
                fill: ColorSpectrum['cool-grey'][400],
                stroke: ColorSpectrum['cool-grey'][400],
                dataSeries,
              });
              overviewRef.current && overviewRef.current.overviewSciChartSurface.renderableSeries.add(series);
            } else {
              const series = new SplineMountainRenderableSeries(ctx, {
                fill: ColorSpectrum['cool-grey'][400],
                stroke: ColorSpectrum['cool-grey'][400],
                dataSeries,
              });
              overviewRef.current && overviewRef.current.overviewSciChartSurface.renderableSeries.add(series);
            }
          });
      };

      // * If the data series already exists, we update it with the new data. Otherwise, we create a new instance.
      if (overviewRef.current) {
        overviewRef.current.overviewSciChartSurface.renderableSeries.clear(true);
        createLinesFromDatasets();
        return;
      }

      if (hasCreatedOverview.current) {
        // create a minifunction to reuse
        return;
      }
      hasCreatedOverview.current = true;

      const overview = await SciChartOverview.create(surface, overviewId.current, {
        transformRenderableSeries: (series) => {
          const dataset = datasets.find(({ label }) => label === series.getDataSeriesName());

          const dataSeries = createXyDataSeries(ctx, dataset);

          if (dataset?.type === 'bar') {
            return new FastColumnRenderableSeries(ctx, {
              fill: ColorSpectrum['cool-grey'][400],
              stroke: ColorSpectrum['cool-grey'][400],
              dataSeries,
            });
          }
          return new SplineMountainRenderableSeries(ctx, {
            fill: ColorSpectrum['cool-grey'][400],
            stroke: ColorSpectrum['cool-grey'][400],
            dataSeries,
          });
        },
        overviewXAxisOptions: {
          zoomExtentsRange: new NumberRange(
            min ? getUnixTimestampInSeconds(new Date(min)) : undefined,
            max ? getUnixTimestampInSeconds(new Date(max)) : undefined,
          ),
        },
        padding: Thickness.fromString('0 8 0 8'),
        theme: OVERVIEW_DARK_THEME,
      });

      overview.rangeSelectionModifier.unselectedsvgString = unSelectedArea;
      overview.rangeSelectionModifier.rangeSelectionAnnotation.adornerSvgStringTemplate = adornerSvgStringTemplate;
      overviewRef.current = overview;
    },
    [surface, sliderContext, slider, isInitialized, fullRange.min, fullRange.max],
  );

  useEffect(() => {
    const createOverview = () => {
      if (!surface || !isInitialized) return;

      if (surface.renderableSeries.asArray().map((series) => series.getDataSeriesName()))
        if (datasets.some((dataset) => dataset.type === 'stackedLine')) {
          generateOutlineSeriesForStacks(datasets, selectedDataset);
        } else {
          generateOutlineSeries(datasets, selectedDataset);
        }
    };
    surface?.renderableSeries.collectionChanged.subscribe(createOverview);

    return () => surface?.renderableSeries.collectionChanged.unsubscribe(createOverview);
  }, [datasets, generateOutlineSeries, generateOutlineSeriesForStacks, isInitialized, selectedDataset, surface]);

  return { overviewId, generateOutlineSeriesForStacks, generateOutlineSeries };
};

export const useDataLabels = ({
  surfaceRef,
  isInitialized,
  datasets,
  seriesMetadataRef,
}: {
  datasets: ChartDataset[];
  surfaceRef: MutableRefObject<SciChartSurface | null>;
  isInitialized: boolean;
  seriesMetadataRef: React.MutableRefObject<SeriesMetadataMap>;
}) => {
  const sliderEndAnnotationsRef = useRef<CustomAnnotation[]>([]);
  const sliderContext = useContext(SliderContext);
  const surface = surfaceRef.current;

  const sliderEndPointSeriesRef = useRef<Array<{ pointSeriesId: string; dataSeriesId: string }>>([]);

  useEffect(() => {
    if (!surface || !surface.id || datasets.length === 0 || !isInitialized) return;

    sliderEndPointSeriesRef.current = [];
    seriesMetadataRef.current.forEach(({ dataset, excludeFromTooltip }, id) => {
      if (excludeFromTooltip || !dataset.dataLabelsAtSliderMax) return;
      const ctx = surface.webAssemblyContext2D;
      const pointDataSeries = new XyDataSeries(ctx, { xValues: [], yValues: [] });
      const points = new XyScatterRenderableSeries(ctx, {
        stroke: dataset.color,
        strokeThickness: dataset.strokeThickness ?? defaultStrokeThickness,
        dataSeries: pointDataSeries,
        yAxisId: dataset.yAxisId,
        pointMarker: new EllipsePointMarker(ctx, { ...HOVERED_POINT_MARKER_STYLE, fill: dataset.color }),
      });
      surface.renderableSeries.add(points);
      sliderEndPointSeriesRef.current.push({ pointSeriesId: points.id, dataSeriesId: id });
    });
  }, [datasets, isInitialized, seriesMetadataRef, surface]);

  const updateDataPointsForAnnotations = useCallback(() => {
    if (!surface || !surface.id || datasets.length === 0 || !isInitialized) return;

    // Traverse all series, find the point series and add a point to slider max if dataLabelsAtSliderMax is true
    sliderEndPointSeriesRef.current.forEach((item) => {
      traverseRenderableSeries(surface.renderableSeries.asArray(), (series) => {
        if (series.id === item.pointSeriesId && isXyDataSeries(series.dataSeries)) {
          series.dataSeries.clear();

          const { dataset } = seriesMetadataRef.current.get(item.dataSeriesId) ?? {};
          if (!dataset) return;

          const visibleRange = surface.xAxes.get(0).visibleRange;

          const dataPointsInRange = dataset.data.filter(filterDataPointsInRange(visibleRange));
          const dataPoint = dataPointsInRange.at(-1);
          if (!dataPoint || dataPoint?.y === null) return;

          series.dataSeries.append(getUnixTimestampInSeconds(dataPoint.x), dataPoint?.y);
        }
      });
    });
  }, [datasets.length, isInitialized, seriesMetadataRef, surface]);

  const updateAnnotations = useCallback(() => {
    if (!surface || !surface.id || datasets.length === 0 || !isInitialized) return;
    const visibleRange = surface.xAxes.get(0).visibleRange;
    const canvas = surface.domCanvas2D?.getContext('2d');

    if (!canvas) return;
    const numberOfPreviousAnnotations = sliderEndAnnotationsRef.current.length;
    const datasetsWithAnnotationsAtSliderMax = datasets.filter((dataset) => dataset.dataLabelsAtSliderMax);
    const datasetsToUpdate = datasetsWithAnnotationsAtSliderMax.slice(0, numberOfPreviousAnnotations);
    const datasetsToCreate = datasetsWithAnnotationsAtSliderMax.slice(numberOfPreviousAnnotations);
    const annotationsToRemove = Math.max(0, numberOfPreviousAnnotations - datasetsWithAnnotationsAtSliderMax.length);

    for (const [i, dataset] of datasetsToUpdate.entries()) {
      const dataPointsInRange = dataset.data.filter(filterDataPointsInRange(visibleRange));
      const dataPoint = dataPointsInRange.at(-1);

      const yAxes = surface.yAxes.asArray();

      if (!dataPoint || !yAxes || yAxes.length === 0 || dataPoint.y === null) continue;

      const annotation = sliderEndAnnotationsRef.current[i];

      const yAxis = yAxes.find((axis) => axis.id === dataset.yAxisId) ?? yAxes[0];
      const label = yAxis.labelProvider ? yAxis.labelProvider.formatLabel(dataPoint.y) : '';
      const svgString = getDataLabelAnnotationSvgString({ label, canvas });

      annotation.x1 = getUnixTimestampInSeconds(dataPoint.x);
      annotation.y1 = dataPoint.y ?? Number.NaN;
      annotation.svgString = svgString;
    }
    for (const dataset of datasetsToCreate) {
      const dataPointsInRange = dataset.data.filter(filterDataPointsInRange(visibleRange));
      const dataPoint = dataPointsInRange.at(-1);
      if (!dataPoint) continue;

      const yAxes = surface.yAxes.asArray();

      const yAxis = yAxes.find((axis) => axis.id === dataset.yAxisId) ?? yAxes[0];
      const label = dataPoint.y !== null && yAxis.labelProvider ? yAxis.labelProvider.formatLabel(dataPoint.y) : '';

      const sliderEndAnnotation = createDataLabelAnnotation({
        annotationOptions: {
          x1: getUnixTimestampInSeconds(dataPoint.x),
          y1: dataPoint.y ?? Number.NaN,
          horizontalAnchorPoint: EHorizontalAnchorPoint.Right,
          verticalAnchorPoint: EVerticalAnchorPoint.Center,
          yCoordShift: 0,
          xCoordShift: -8,
          yAxisId: dataset.yAxisId,
          label,
        },
        canvas,
      });

      sliderEndAnnotationsRef.current.push(sliderEndAnnotation);
      surface.annotations.add(sliderEndAnnotation);
    }

    for (let i = 0; i < annotationsToRemove; i++) {
      const annotation = sliderEndAnnotationsRef.current.pop();
      if (annotation) {
        surface.annotations.remove(annotation, true);
      }
    }

    sliderContext.visibleRangeRef.current = { min: visibleRange.min, max: visibleRange.max };
  }, [datasets, isInitialized, sliderContext.visibleRangeRef, surface]);

  useEffect(() => {
    updateAnnotations();
    updateDataPointsForAnnotations();
  }, [updateAnnotations, updateDataPointsForAnnotations]);

  useEffect(() => {
    if (!surface || !surface.id) return;
    if (!isInitialized) return;

    // ? We might need to unsubscribe these listeners at appropriate times, to ensure we don't leak memory and don't have multiple listeners for the same event
    surface.annotations.collectionChanged.subscribe(() => {
      if (surface.annotations.size() === 0) {
        sliderEndAnnotationsRef.current = [];
      }
    });

    surface.renderableSeries.collectionChanged.subscribe(() => {
      updateAnnotations();
      updateDataPointsForAnnotations();
    });

    surface.xAxes.get(0).visibleRangeChanged.subscribe(() => {
      updateAnnotations();
      updateDataPointsForAnnotations();
    });
  }, [
    datasets,
    isInitialized,
    sliderContext.visibleRangeRef,
    surface,
    updateAnnotations,
    updateDataPointsForAnnotations,
  ]);
};
