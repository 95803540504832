import type { ReactNode } from 'react';
import React from 'react';
import './FixedFooterNavigation.css';

export interface FixedFooterNavigationProps {
  continueButton: ReactNode;
  previousButton?: ReactNode;
}

export const FixedFooterNavigation = ({ continueButton, previousButton }: FixedFooterNavigationProps) => {
  return (
    <div className='FixedFooterNavigation'>
      {continueButton && continueButton}
      {previousButton && previousButton}
    </div>
  );
};
