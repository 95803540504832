import type { ReactNode } from 'react';
import React, { useEffect, useMemo } from 'react';
import { NoiseArc } from '../NoiseArc';
import { ScoreGrade } from '../ScoreGrade';
import { ScorePath } from '../ScorePath';
import '../Score.css';
import './ScoreInsufficientData.css';
import type { ArcSize } from '../utils';
import { SCORE_CONFIG } from '../utils';
import { animated, easings, useSpring } from '@react-spring/web';
import { ScoreBlur } from '../ScoreBlur';
import { isLarge } from '../../missing-data-animations/utils';
import { ScoreSlicer } from '../ScoreSlicer';
import { CountDownCover } from './CountDownCover';

const { viewBoxWidth, viewBoxHeight, outerArcRadiuses, innerArcRadiuses } = SCORE_CONFIG;

interface ScoreMissingDataProps {
  arcSize?: ArcSize;
  footer?: ReactNode;
  contentInsideArc?: ReactNode;
  animationSize?: 'small' | 'large';
  dataCountdown?: {
    dataReadyDate: number;
    title: string;
    infoText: string;
    fallbackText: string;
  };
}

export const ScoreInsufficientData = ({
  contentInsideArc,
  arcSize = 'small',
  footer,
  animationSize = 'small',
  dataCountdown,
}: ScoreMissingDataProps) => {
  const clipPathId = Math.random(); // ! Used to generate a unique id for the clipPath
  const clipPath = `url(#score-arc-solid-${clipPathId})`;

  const outerArcRadius = outerArcRadiuses[arcSize];
  const innerArcRadius = innerArcRadiuses[arcSize];

  const score = isLarge(animationSize) ? 3.6 : 0.5;

  const animationArcDuration = 1500;
  const animationStartDelay = 1000;

  const animationConfig = useMemo(
    () => ({
      config: {
        duration: animationArcDuration,
        easing: easings.easeOutSine,
      },
      delay: animationStartDelay,
    }),
    [animationArcDuration],
  );

  const [spring, api] = useSpring(() => ({
    from: { angleTo: -Math.PI / 2, score: 0, transform: `rotate(-${5 * 180} 0 0)` },
  }));

  useEffect(() => {
    const interval = setInterval(
      () =>
        api.start({
          to: [
            {
              angleTo: -Math.PI / 2 + (score / 5) * Math.PI,
              score,
              transform: `rotate(-${(5 - score / 5) * 180} 0 0)`,
            },
            { angleTo: -Math.PI / 2, score: 0, transform: `rotate(-${5 * 180} 0 0)` },
          ],
          ...animationConfig,
        }),
      isLarge(animationSize) ? 5000 : 9000,
    );

    return () => clearInterval(interval);
  }, [animationConfig, animationSize, api, score]);

  const AnimatedSlicer = animated(ScoreSlicer);

  return (
    <>
      <div className='aim-score insufficient-score-data' data-has-countdown={dataCountdown !== undefined}>
        <ScoreBlur angleTo={spring.angleTo} score={score} arcSize={arcSize} />

        <svg className='aim-score_overlay' viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
          <ScorePath
            endAngle={Math.PI / 2}
            score={clipPathId}
            outerRadius={outerArcRadius}
            innerRadius={innerArcRadius - 3}
          />

          <NoiseArc clipPath={clipPath} />

          <ScoreGrade
            svgHeight={viewBoxHeight}
            svgWidth={viewBoxWidth}
            outerRadius={innerArcRadius}
            innerRadius={innerArcRadius - 4}
            displayTicks
          />

          <AnimatedSlicer
            arcRadius={outerArcRadius}
            svgWidth={viewBoxWidth}
            svgHeight={viewBoxHeight}
            rotation={spring.transform}
            animatedScore={spring.score}
          />
        </svg>
        {contentInsideArc && <div className='insufficient-data-inside-arc'>{contentInsideArc}</div>}
      </div>
      {footer && <div className='insufficient-data-footer'>{footer}</div>}
      {dataCountdown && <CountDownCover {...dataCountdown} />}
    </>
  );
};
