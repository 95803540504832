import { useMemo, useEffect } from 'react';
import { EMPTY_ARRAY_STATIC_REFERENCE } from '../../../utils';
import type { Option } from '../../Input/Select/Select';
import type { ChartDataset } from '../types';
import { getInitialDatasetOptions } from './utils';

export function useDatasetsOptions<TChartDataset extends ChartDataset>({
  datasets,
  selected,
  dispatch,
  sortDatasetOptions,
  resetPreselectedOptionsOnDatasetChanges = false,
}: {
  datasets: TChartDataset[];
  selected?: string[];
  dispatch?: (value: {
    type: 'SET_DATASETS' | 'SET_DATASETS_IF_EMPTY' | 'INTERSECT_DATASETS';
    datasets: Array<TChartDataset['datasetId']>;
  }) => void;
  sortDatasetOptions?: (a: Option, b: Option) => number;
  resetPreselectedOptionsOnDatasetChanges?: boolean;
}) {
  const datasetsToUse = datasets?.length > 0 ? datasets : EMPTY_ARRAY_STATIC_REFERENCE;
  const selectedDatasetIds = useMemo(
    () => selected ?? datasets.map(({ datasetId }) => datasetId),
    [datasets, selected],
  );

  const { datasetsOptions, preselectedDatasets } = useMemo(() => {
    const { allOptions, preselectedOptions } = getInitialDatasetOptions<TChartDataset['datasetId']>(
      datasetsToUse,
      false,
      sortDatasetOptions,
    );
    const preselectedDatasets = preselectedOptions.map(({ value }) => value);

    return { datasetsOptions: allOptions, preselectedDatasets };
  }, [datasetsToUse, sortDatasetOptions]);

  const selectedDatasets = useMemo(() => {
    const UniqueSelectedDatasetIds = new Set(selectedDatasetIds);
    return datasets.filter((dataset) => UniqueSelectedDatasetIds.has(dataset.datasetId));
  }, [datasets, selectedDatasetIds]);

  const selectedDatasetsOptions = useMemo(() => {
    return datasetsOptions.filter(({ value }) => selectedDatasetIds.includes(value));
  }, [datasetsOptions, selectedDatasetIds]);

  useEffect(() => {
    if (resetPreselectedOptionsOnDatasetChanges) {
      dispatch && dispatch({ type: 'SET_DATASETS', datasets: preselectedDatasets });
    } else {
      // * Applies the preselected datasets if there are no selected datasets since before.
      dispatch && dispatch({ type: 'SET_DATASETS_IF_EMPTY', datasets: preselectedDatasets });
    }
  }, [preselectedDatasets, dispatch, resetPreselectedOptionsOnDatasetChanges]);

  useEffect(() => {
    // * Fallback to pre-selection if current selection is invalid
    if (selectedDatasetIds.length > 0 && selectedDatasetsOptions.length === 0 && preselectedDatasets.length > 0) {
      dispatch && dispatch({ type: 'SET_DATASETS', datasets: preselectedDatasets });
    }
  }, [dispatch, preselectedDatasets, selectedDatasetIds.length, selectedDatasetsOptions.length]);

  useEffect(() => {
    // * Removes the selected datasets that are no longer present in the list of available dataset options.
    if (datasetsOptions.length === 0) return;
    dispatch && dispatch({ type: 'INTERSECT_DATASETS', datasets: datasetsOptions.map(({ value }) => value) });
  }, [datasetsOptions, dispatch]);

  return { datasetsOptions, selectedDatasets, selectedDatasetsOptions };
}

export function useCreateDatasetOptions<TChartDataset extends ChartDataset>(datasets: TChartDataset[]) {
  return useMemo(() => {
    return getInitialDatasetOptions<TChartDataset['datasetId']>(datasets, false).allOptions;
  }, [datasets]);
}
