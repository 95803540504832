import React from 'react';
import styles from './Carousel.module.css';
import { Icon } from '../Icon';
import { Button } from '../Button';

export const DisabledCarouselFooter = ({ numberOfBullets }: { numberOfBullets: number }) => {
  return (
    <div className={styles.navigation}>
      <Button type='tertiary' color='white' endIcon={<Icon icon='nav-back' />} disabled />
      <div className={styles.bullets}>
        {Array.from(Array(numberOfBullets)).map((_, i) => (
          <div key={i} role='button' className={styles.bullet} />
        ))}
      </div>
      <Button type='tertiary' color='white' endIcon={<Icon icon='nav-forward' />} disabled />
    </div>
  );
};
