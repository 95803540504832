export enum PasswordValidationErrors {
  NotValid = 'notValid',
  Valid = '',
  NotMatched = 'notMatched',
  WrongPassword = 'wrongPassword',
}

export interface InviteUserValidationErrors {
  name?: boolean;
  email?: boolean;
}

export const validateName = (
  name: string,
  setValidationErrors: (arg0: InviteUserValidationErrors) => void,
  validationErrors: InviteUserValidationErrors,
): void => {
  setValidationErrors({
    name: name.length == 0,
    email: validationErrors.email,
  });
};

export const validateEmail = (
  email: string,
  setValidationErrors: (arg0: InviteUserValidationErrors) => void,
  validationErrors: InviteUserValidationErrors,
): void => {
  const emailIsValid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email.toLowerCase(),
    );

  setValidationErrors({
    name: validationErrors.name,
    email: !emailIsValid,
  });
};

export const validatePassword = (password?: string): PasswordValidationErrors => {
  if (!password || password.length < 8 || password.length > 72) return PasswordValidationErrors.NotValid;
  const containsNumberRegEx = /[0-9]/;
  if (!containsNumberRegEx.test(password)) return PasswordValidationErrors.NotValid;
  const containsLowerAndUpperCaseLetter = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/;
  if (!containsLowerAndUpperCaseLetter.test(password)) return PasswordValidationErrors.NotValid;
  return PasswordValidationErrors.Valid;
};

export const validateRepeatedPassword = (
  newPassword: string,
  repeatedNewPassword: string,
): PasswordValidationErrors => {
  return newPassword != '' && newPassword === repeatedNewPassword
    ? PasswordValidationErrors.Valid
    : PasswordValidationErrors.NotMatched;
};
