import { getCurrentFullstorySessionUrl } from '@/lib/fullstory';
import { toast, useKeyDown } from 'aim-components';
import { format } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

export const useApplicationKeybindings = () => {
  const { t } = useTranslation('common', { keyPrefix: 'keybindings' });

  const keybindingMap = useMemo(() => {
    return {
      'toggle-command-palette': {
        name: t('toggle-command-palette'),
        keys: ['⌘ CMD', '⇧ Shift', 'P'],
        keyCode: 'KeyP',
        modifierKeys: ['metaKey', 'shiftKey'],
      },
      'toggle-navigation-menu': {
        name: t('toggle-navigation-menu'),
        keys: ['⌘ CMD', 'B'],
        keyCode: 'KeyB',
        modifierKeys: ['metaKey'],
      },
    } as const;
  }, [t]);

  return { keybindingMap } as const;
};

type KeybindingCommand = keyof ReturnType<typeof useApplicationKeybindings>['keybindingMap'];

export const useKeybindingCommand = <TKeybindingCommand extends KeybindingCommand>(
  keybindingCommand: TKeybindingCommand,
  onKeyDown: (ev: KeyboardEvent) => void,
) => {
  const { keybindingMap } = useApplicationKeybindings();
  const keybinding = keybindingMap[keybindingCommand];

  useKeyDown({
    keyCode: keybinding.keyCode,
    modifierKeys: keybinding.modifierKeys,
    onKeyDown,
    targetDocumentBody: false,
  });

  return keybinding;
};

/**
 * This hook generates a debug message containing information about the current session.
 *
 * The message is formatted using Markdown, and can be copied to e.g. Slack for debugging purposes.
 */
export const useSessionDebugInformationMessage = ({ projectCode }: { projectCode: string | undefined }) => {
  const { t } = useTranslation('common', { keyPrefix: 'commandPalette' });
  const { route } = useRouter();

  const copyDebugMessageToClipboard = useCallback(async () => {
    const fullstoryUrl = getCurrentFullstorySessionUrl({ format: 'url.now' });
    const dateString = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    const rows = [
      `> 🏢 *Project code:* \`${projectCode}\``,
      `> 🧭 *Route:* \`${route}\``,
      `> 📅 *${dateString}* (_${new Date().toISOString()}_)`,
      fullstoryUrl ? `> 🎥 *[Watch Fullstory session](${fullstoryUrl})*` : '',
    ];

    const debugInformationMessage = rows.filter((row) => row.length > 0).join('\n');

    try {
      await navigator.clipboard.writeText(debugInformationMessage);
      toast({ message: t('sessionDebug.copySuccess'), type: 'success' });
    } catch {
      toast({ message: t('sessionDebug.copyError'), type: 'critical' });
    }
  }, [projectCode, route, t]);

  return { copyDebugMessageToClipboard } as const;
};

export const useCopyStringToClipboard = () => {
  const { t } = useTranslation('common', { keyPrefix: 'commandPalette' });

  const copyStringToClipboard = useCallback(
    (text: string) => async () => {
      try {
        await navigator.clipboard.writeText(text);
        toast({ message: t('copyAction.copySuccess', { text }), type: 'success' });
      } catch {
        toast({ message: t('copyAction.copyError', { text }), type: 'critical' });
      }
    },
    [t],
  );

  return { copyStringToClipboard };
};
