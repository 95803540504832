import type { Notification } from '@/api/notifications/index.page';

export type ChartStateWithOptionalNotification<TChartState> =
  | Partial<TChartState>
  | Partial<TChartState & { notification: Notification }>;

export const generateChartLink = <TChartState>(chartState: TChartState, pathName: string) => {
  const base64Encoded = Buffer.from(JSON.stringify(chartState), 'utf8').toString('base64');
  const withUrlSafeCharactersAndNoPadding = base64Encoded.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');
  return `${pathName}?chart-link=${withUrlSafeCharactersAndNoPadding}`;
};

export const generateNotificationChartLink = ({
  notification,
  pathName,
}: {
  notification: Notification;
  pathName: string;
}) => {
  const { insightFilter, date, direction, message, eventId } = notification;

  const chartLinkPayload = {
    ...insightFilter,
    notification: { eventId, date, direction, message },
  };

  return generateChartLink(chartLinkPayload, pathName);
};
