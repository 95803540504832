import type { ReactElement } from 'react';
import React from 'react';
import cn from 'classnames';
import { Typography } from '../../Typography/Typography';
import './MultiSelectTabs.css';

export interface MultiSelectTabsProps<TabName extends string = string> {
  tabNames: TabName[] | readonly TabName[];
  selectedTabName: TabName;
  onTabChange: (tabName: TabName) => void;
}

export const MultiSelectTabs = <TabName extends string = string>({
  tabNames,
  selectedTabName,
  onTabChange,
}: MultiSelectTabsProps<TabName>): ReactElement => (
  <div className='MultiSelectTabs'>
    {tabNames.map((tabName) => {
      const isActiveTab = tabName === selectedTabName;
      return (
        <div
          role='tab'
          key={tabName}
          className={cn('tab', isActiveTab && 'active')}
          onClick={() => onTabChange(tabName)}
        >
          <Typography variant='label3'>{tabName}</Typography>
        </div>
      );
    })}
  </div>
);
