import { UserContext } from '@/components/authentication/UserContext';
import { Avatar, Typography, Icon } from 'aim-components';
import type { ComponentProps } from 'react';
import { forwardRef, useContext } from 'react';
import styles from './ProfileMenuTrigger.module.css';

type ProfileMenuTriggerProps = {
  expanded: boolean;
  icon: 'dropdown-up' | 'dropdown-down';
} & ComponentProps<'button'>;

export const ProfileMenuTrigger = forwardRef<HTMLButtonElement, ProfileMenuTriggerProps>(
  ({ expanded, icon, ...props }, ref) => {
    const user = useContext(UserContext);

    return (
      <button ref={ref} className={styles.userProfile} data-expanded={expanded} {...props}>
        <Avatar size='small' name={user.displayName} arkUserId={user.arkUserId} />
        {expanded && (
          <>
            <Typography variant='text1' truncate>
              {user.displayName}
            </Typography>
            <Icon icon={icon} size='xs' />
          </>
        )}
      </button>
    );
  },
);

ProfileMenuTrigger.displayName = 'ProfileMenuTrigger';
