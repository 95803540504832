import React from 'react';
import './ScoreInsufficientData.css';
import { getTimeDistanceToToday } from './utils';
import { Typography } from '../../Typography';
import { useMobile } from 'aim-utils';
interface CountDownCoverProps {
  dataReadyDate: number;
  title: string;
  infoText: string;
  fallbackText: string;
}

export const CountDownCover = ({ dataReadyDate, title, infoText, fallbackText }: CountDownCoverProps) => {
  const [months, days, hours] = getTimeDistanceToToday(dataReadyDate);
  const { mobileView } = useMobile();

  if (new Date(dataReadyDate) < new Date()) {
    return (
      <div className='score-insufficient-data-count-down-wrapper'>
        <Typography variant={'label2'}>{fallbackText}</Typography>
      </div>
    );
  }

  return (
    <div className='score-insufficient-data-count-down-wrapper'>
      <div className='score-insufficient-data-count-down'>
        <Typography variant='label2' color='secondary'>
          {title}
        </Typography>

        <div className='score-insufficient-data-count-down-time-content'>
          <div className='count-down-number'>
            <Typography variant={mobileView ? 'title1' : 'hero'} className='sansSerif'>
              {months}
            </Typography>
            <Typography variant='text1' color='secondary'>
              months
            </Typography>
          </div>
          <div className='count-down-number'>
            <Typography variant={mobileView ? 'title1' : 'hero'} className='sansSerif'>
              {days}
            </Typography>
            <Typography variant='text1' color='secondary'>
              days
            </Typography>
          </div>
          <div className='count-down-number'>
            <Typography variant={mobileView ? 'title1' : 'hero'} className='sansSerif ark-blue'>
              {hours}
            </Typography>
            <Typography variant='text1' className='ark-blue'>
              hours
            </Typography>
          </div>
        </div>
        <Typography variant={mobileView ? 'text2' : 'text1'} color='secondary' className='count-down-info-text'>
          {infoText}
        </Typography>
      </div>
    </div>
  );
};
