import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import './RadioGroup.css';
import cn from 'classnames';
import { Typography } from '../../Typography';

export interface RadioButtonOption<TValue extends string = string> {
  value: TValue;
  label: string;
  disabled?: boolean;
}

export interface RadioGroupProps<TValue extends string = string> {
  title: ReactNode;
  options: Array<RadioButtonOption<TValue>>;
  value?: RadioButtonOption<TValue> | TValue;
  disabled?: boolean;
  condensed?: boolean;
  onChange?: (value: RadioButtonOption<TValue>) => void;
  rowDirection?: boolean;
}

export const RadioGroup = <TValue extends string = string>({
  title,
  options = [],
  value,
  disabled,
  condensed,
  onChange,
  rowDirection,
}: RadioGroupProps<TValue>): ReactElement => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedOption = options.find((option) => option.value === e.target.value);
    if (selectedOption && onChange) {
      onChange(selectedOption);
    }
  };

  return (
    <form className={cn('RadioGroup', rowDirection && 'row')}>
      {typeof title === 'string' ? (
        <Typography variant={rowDirection ? 'text1' : 'labelAxis'}>{title}</Typography>
      ) : (
        title
      )}

      {options.map((option) => (
        <label key={option.value} className={cn((disabled || option.disabled) && 'disabled', condensed && 'condensed')}>
          <input
            disabled={disabled || option.disabled}
            type='radio'
            value={option.value}
            checked={typeof value === 'string' ? value === option.value : value?.value === option.value}
            onChange={handleChange}
          />
          <Typography variant={condensed ? 'text2' : 'text1'}>{option.label}</Typography>
        </label>
      ))}
    </form>
  );
};
