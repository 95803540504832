import { Button } from 'aim-components';
import { useTranslation } from 'next-i18next';
import { ProfileRow } from '../profile-settings/ProfileRow';
import { useJoinDealChannel } from '../hooks';

export const JoinDealChannel = () => {
  const { t } = useTranslation('settings', { keyPrefix: 'joinDealChannel' });

  const { joinDealChannel } = useJoinDealChannel();

  return (
    <ProfileRow title={t('joinDealChannel')}>
      <Button onClick={joinDealChannel} type='secondary' color='white'>
        {t('joinChannel')}
      </Button>
    </ProfileRow>
  );
};
