import type { AxisBase2D, ICustomAnnotationOptions } from 'scichart';
import { CustomAnnotation, ECoordinateMode, EVerticalAnchorPoint, EHorizontalAnchorPoint } from 'scichart';
import type { ChartDataset } from '../types';
import { getUnixTimestampInSeconds } from './data-utils';

type DataLabelAnnotationOptions = ICustomAnnotationOptions & {
  label: string;
};

export const getDatasetDataLabelAnnotationsOptions = ({
  dataset,
  yAxes,
}: {
  dataset: ChartDataset;
  yAxes: AxisBase2D[];
}) => {
  const annotationsOptions: DataLabelAnnotationOptions[] = [];

  const yAxis = yAxes.find((axis) => axis.id === dataset.yAxisId) ?? yAxes[0];

  for (const dataLabel of dataset.dataLabels ?? []) {
    if (typeof dataLabel === 'number') {
      const dataPoint = dataset.data[dataLabel];

      if (!dataPoint) continue;

      const y = dataPoint.y;

      if (y === null) continue;

      annotationsOptions.push({
        x1: getUnixTimestampInSeconds(dataPoint.x),
        y1: y,
        yAxisId: dataset.yAxisId,
        label: yAxis.labelProvider?.formatLabel(y),
      });
    } else {
      const dataPoint = dataset.data[dataLabel.index];

      if (!dataPoint) continue;
      if (dataPoint.y === null) continue;
      if (!dataLabel.label) continue;

      annotationsOptions.push({
        x1: getUnixTimestampInSeconds(dataPoint.x),
        y1: dataPoint.y,
        yAxisId: dataset.yAxisId,
        label: dataLabel.label,
      });
    }
  }

  return annotationsOptions;
};

export const createDataLabelAnnotation = ({
  annotationOptions,
  canvas,
}: {
  annotationOptions: DataLabelAnnotationOptions;
  canvas: CanvasRenderingContext2D;
}) => {
  const { label, ...options } = annotationOptions;
  const svgString = getDataLabelAnnotationSvgString({ label, canvas });

  return new CustomAnnotation({
    xCoordinateMode: ECoordinateMode.DataValue,
    yCoordinateMode: ECoordinateMode.DataValue,
    verticalAnchorPoint: EVerticalAnchorPoint.Top,
    horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
    yCoordShift: 8,
    svgString,
    ...options,
  });
};

export const getDataLabelAnnotationSvgString = ({
  label,
  canvas,
}: {
  label: string;
  canvas: CanvasRenderingContext2D;
}) => {
  const textMeasurement = canvas.measureText(label);
  const horizontalPadding = 12;

  canvas.font = '12px ArketypSans';

  return (
    `<svg xmlns="http://www.w3.org/2000/svg" width="${(textMeasurement?.width ?? 0) + horizontalPadding}" height="${16}">` +
    '<rect x="0" y="0" width="100%" height="100%" fill="black" rx="4" opacity="0.75" />' +
    `<text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="white" font-size="12px" font-family="ArketypSans">${label}</text>` +
    '</svg>'
  );
};
