import { useState, useEffect, useCallback, useMemo } from 'react';
import { AccordionContext } from './AccordionContext';
import { ACCORDION_ICON_POSITION, ACCORDION_TYPE, type SingleAccordionProps } from './types';

/** An accordion component that allows a **single** accordion item to be expanded at a time. */
export const SingleAccordion = <TAccordionId extends string = string>({
  expandedId,
  iconPosition,
  children,
  onChange,
}: SingleAccordionProps<TAccordionId>) => {
  const [expandedItemId, setExpandedItemId] = useState<TAccordionId | undefined>(expandedId);

  useEffect(() => setExpandedItemId(expandedItemId), [expandedItemId]);

  useEffect(() => {
    if (onChange) onChange(expandedItemId);
  }, [expandedItemId, onChange]);

  const toggleId = useCallback((id: TAccordionId) => {
    setExpandedItemId((previouslyExpandedId) => {
      return previouslyExpandedId === id ? undefined : id;
    });
  }, []) as (id: string) => void;

  const contextValue = useMemo(() => {
    return {
      type: ACCORDION_TYPE.SINGLE,
      expandedId: expandedItemId ?? null,
      toggleId,
      iconPosition: iconPosition ?? ACCORDION_ICON_POSITION.RIGHT,
    };
  }, [expandedItemId, toggleId, iconPosition]);

  return <AccordionContext.Provider value={contextValue}>{children}</AccordionContext.Provider>;
};
