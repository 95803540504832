export const emojis = [
  {
    id: '🙂',
    display: 'smile',
  },
  {
    id: '👋',
    display: 'wave',
  },
  {
    id: '👍',
    display: 'thumbs-up',
  },
  {
    id: '🎉',
    display: 'party-popper',
  },
  {
    id: '🤔',
    display: 'thinking-face',
  },
  {
    id: '📣',
    display: 'megaphone',
  },
  {
    id: '❓',
    display: 'question-mark',
  },
  {
    id: '⚠️',
    display: 'warning',
  },
  {
    id: '📈',
    display: 'chart-up',
  },
  {
    id: '📉',
    display: 'chart-down',
  },
  {
    id: '📊',
    display: 'bar-chart',
  },
] as const satisfies ReadonlyArray<{ id: string; display: string }>;
