import type { ReactElement } from 'react';
import React from 'react';
import { Toaster as ReactToaster, toast as reactToast } from 'react-hot-toast';
import type { ToasterProps } from './Toaster';
import Toaster, { TOASTER_DURATION } from './Toaster';

export interface ToasterContainerProps {
  topOffset?: string;
  rightOffset?: string;
}

const ToasterContainer = ({ topOffset = '24px', rightOffset = '24px' }: ToasterContainerProps): ReactElement => {
  return <ReactToaster position='top-right' containerStyle={{ top: topOffset, right: rightOffset }} />;
};

export interface ToastInput {
  icon?: ToasterProps['icon'];
  message: ToasterProps['text'];
  type?: ToasterProps['type'];
  dismissible?: boolean;
  button?: ToasterProps['button'];
  noMaxWidth?: ToasterProps['noMaxWidth'];
}

const toast = ({ icon, message, type, dismissible = false, button, noMaxWidth = false }: ToastInput): string =>
  reactToast.custom(
    (t) => {
      const removeToaster = (): void => reactToast.remove(t.id);
      return (
        <Toaster
          icon={icon}
          text={message}
          type={type}
          handleDismiss={dismissible ? removeToaster : undefined}
          button={button}
          noMaxWidth={noMaxWidth}
        />
      );
    },
    { duration: dismissible ? Infinity : TOASTER_DURATION },
  );

export { toast, ToasterContainer };
