import type { FastColumnRenderableSeries, SeriesInfo, XyScatterRenderableSeries } from 'scichart';
import {
  ECoordinateMode,
  EHorizontalAnchorPoint,
  ELabelPlacement,
  EVerticalAnchorPoint,
  TextAnnotation,
  Thickness,
  VerticalLineAnnotation,
  type IRenderableSeries,
  type SciChartSurface,
} from 'scichart';
import { getPointerEventPosition } from './events';
import type { ChartDataset, SeriesMetadataMap } from '../types';
import type { MutableRefObject } from 'react';
import {
  defaultStrokeThickness,
  traverseRenderableSeries,
  hoveredStrokeThickness,
  barGradient,
} from './dataseries-utils';
import { isBarRenderableSeries, isStackedMountainRenderableSeries, isXyDataSeries } from './type-guards';
import { type HoverPointMarker } from '../hooks/hover.hooks';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';

export const renderHoverState = (
  surfaceRef: React.MutableRefObject<SciChartSurface | null>,
  pointerEvent: PointerEvent,
  seriesMetadataRef: React.MutableRefObject<SeriesMetadataMap>,
  hoveredSeriesId: MutableRefObject<Set<string> | null>,
) => {
  if (!surfaceRef.current) return;

  const { x, y } = getPointerEventPosition(pointerEvent);
  const surface = surfaceRef.current;

  const series = surface.renderableSeries.asArray();

  let annotationPoint: { x: number; y: number } | undefined = undefined;
  for (let i = 0; i < surface.annotations.size(); i++) {
    const an = surface.annotations.get(i);
    if (an.checkIsClickedOnAnnotation(x, y)) {
      annotationPoint = { x: an.x1, y: an.y1 };
      break;
    }
  }

  traverseRenderableSeries(series, (serie) =>
    handleMouseOver(serie, hoveredSeriesId, seriesMetadataRef, annotationPoint, x, y),
  );
};

const handleMouseOver = (
  serie: IRenderableSeries,
  hoveredSeriesId: MutableRefObject<Set<string> | null>,
  seriesMetadataRef: React.MutableRefObject<SeriesMetadataMap>,
  annotationPoint: { x: number; y: number } | undefined,
  x: number,
  y: number,
) => {
  if (!serie.isVisible) return;
  const dataset = seriesMetadataRef.current.get(serie.id)?.dataset;
  if (!dataset) return;
  const hitTestInfo = serie.hitTestProvider.hitTest(x, y, 100);

  let seriesIshoveredByDataLabel = false;
  if (annotationPoint) {
    const yAxisCoordCalc = serie.yAxis.getCurrentCoordinateCalculator();
    const xAxisCoordCalc = serie.xAxis.getCurrentCoordinateCalculator();

    const y = yAxisCoordCalc.getCoordinate(annotationPoint.y);
    const x = xAxisCoordCalc.getCoordinate(annotationPoint.x);

    const hitTestInfo = serie.hitTestProvider.hitTest(x, y);
    seriesIshoveredByDataLabel = hitTestInfo.isHit;
  }

  hoveredSeriesId.current ??= new Set();

  if (hitTestInfo.isHit || seriesIshoveredByDataLabel) {
    handleHit(serie, dataset);
    hoveredSeriesId.current.add(serie.id);
  } else {
    handleMiss(serie, dataset);
    hoveredSeriesId.current.delete(serie.id);
  }
};

const handleHit = (serie: IRenderableSeries, dataset: ChartDataset) => {
  if (isStackedMountainRenderableSeries(serie) && dataset.type === 'stackedLine') {
    serie.fill = dataset.hoverColor ?? dataset.color;
  }

  if (isBarRenderableSeries(serie)) {
    addHoverStateToBarDataseries(serie, dataset);
  } else addHoverStateToLineDataseries(serie);
};

export const handleMiss = (serie: IRenderableSeries, dataset: ChartDataset) => {
  if (isStackedMountainRenderableSeries(serie)) {
    serie.fill = dataset.color;
  }
  if (isBarRenderableSeries(serie)) {
    removeHoverStateToBarDataseries(serie, dataset);
  } else removeHoverStateToLineDataseries(serie, dataset);
};

const addHoverStateToLineDataseries = (serie: IRenderableSeries) => {
  serie.strokeThickness = hoveredStrokeThickness;
};
const removeHoverStateToLineDataseries = (serie: IRenderableSeries, dataset: ChartDataset) => {
  serie.strokeThickness = dataset.strokeThickness ?? defaultStrokeThickness;
};

// find out if gradient is rendered or not
const isColumnRenderedWithoutGradient = (serie: FastColumnRenderableSeries) => {
  const coordinateCalculator = serie.xAxis.getCurrentCoordinateCalculator();
  return serie.getDataPointWidth(coordinateCalculator, serie.dataPointWidth) === 1;
};

//Todo once scichart has added some more functionality
const addHoverStateToBarDataseries = (serie: FastColumnRenderableSeries, _dataset: ChartDataset) => {
  if (isColumnRenderedWithoutGradient(serie)) {
    //serie.opacity = 1;
  } else {
    //serie.fill = dataset.color;
    // serie.fillLinearGradient = undefined as unknown as GradientParams; // Needed to fix the typescript error
  }
};
const removeHoverStateToBarDataseries = (serie: FastColumnRenderableSeries, dataset: ChartDataset) => {
  if (isColumnRenderedWithoutGradient(serie)) {
    serie.opacity = 0.8;
  } else {
    serie.fill = dataset.color;
    serie.fillLinearGradient = barGradient(dataset.color);
  }
};

export const updateHoverPointMarkerRenderableSeries = ({
  series,
  marker,
}: {
  series: XyScatterRenderableSeries;
  marker: HoverPointMarker;
}) => {
  if (isXyDataSeries(series.dataSeries)) {
    series.dataSeries.updateXy(0, marker.x, marker.y);
  }

  if (series.pointMarker) {
    series.pointMarker.fill = marker.color;
  }

  series.yAxisId = marker.yAxis;
};

const datasetHoverAnnotationStyle = {
  textColor: 'white',
  background: '#44454b',
} as const;

export const createDatasetHoverVerticalLineAnnotation = ({ xValue, yAxisId }: { xValue: number; yAxisId: string }) => {
  return new VerticalLineAnnotation({
    labelPlacement: ELabelPlacement.Axis,
    stroke: datasetHoverAnnotationStyle.background,
    x1: xValue,
    y1: 20,
    yCoordinateMode: ECoordinateMode.Pixel,
    yAxisId,
  });
};

export const createDatasetHoverTextAnnotation = ({ xValue, yAxisId }: { xValue: number; yAxisId: string }) => {
  // TODO: Future improvement, make the formatting configurable. Probably want to support different types of date formats, and maybe fully custom formats (inversion of control).
  const date = new Date(xValue * 1000);
  const formattedText = format(date, 'LLL yyyy', { locale: enUS });

  return new TextAnnotation({
    text: formattedText,
    textColor: datasetHoverAnnotationStyle.textColor,
    x1: xValue,
    yCoordinateMode: ECoordinateMode.Pixel,
    y1: 0,
    fontSize: 12,
    horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
    verticalAnchorPoint: EVerticalAnchorPoint.Top,
    background: datasetHoverAnnotationStyle.background,
    padding: Thickness.fromString('3 6 3 6'),
    yAxisId,
  });
};

export const areEqualHoverPointMarkers = (
  a: HoverPointMarker | null | undefined,
  b: HoverPointMarker | null | undefined,
  ignoreIsActive = false,
) => {
  return (
    a?.x === b?.x &&
    a?.y === b?.y &&
    a?.color === b?.color &&
    a?.yAxis === b?.yAxis &&
    ((!ignoreIsActive && a?.isActive === b?.isActive) || ignoreIsActive)
  );
};

export const areEqualSeriesInfo = (a: SeriesInfo | null | undefined, b: SeriesInfo | null | undefined) => {
  return a?.seriesName === b?.seriesName;
};
