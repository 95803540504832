import type { ReactElement } from 'react';
import React from 'react';

export function BarChartSvg(): ReactElement {
  return (
    <svg
      width='calc(100% - 48px)'
      height='90%'
      viewBox='0 0 1136 721'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <g opacity='1'>
        <path
          d='M1 5.09104C1 2.83162 2.58042 1 4.52996 1H96.309C98.2585 1 99.8389 2.83162 99.8389 5.09104V15.3186C99.8389 17.5781 98.2585 19.4097 96.309 19.4097H4.52996C2.58042 19.4097 1 17.5781 1 15.3186V5.09104Z'
          fill='#103776'
        />
        <path
          d='M1 35.7738C1 33.5144 2.58042 31.6828 4.52996 31.6828H283.397C285.346 31.6828 286.927 33.5144 286.927 35.7738V46.0014C286.927 48.2608 285.346 50.0925 283.397 50.0925H4.52996C2.58042 50.0925 1 48.2608 1 46.0014V35.7738Z'
          fill='#103776'
        />
        <path
          d='M1036.16 5.09104C1036.16 2.83162 1037.74 1 1039.69 1H1131.47C1133.42 1 1135 2.83162 1135 5.09104V15.3186C1135 17.5781 1133.42 19.4097 1131.47 19.4097H1039.69C1037.74 19.4097 1036.16 17.5781 1036.16 15.3186V5.09104Z'
          fill='#103776'
        />
        <path
          d='M4.52996 720C2.58042 720 1 718.168 1 715.909V483.743C1 481.483 2.58042 479.651 4.52996 479.651H29.2397C31.1892 479.651 32.7696 481.483 32.7696 483.743V715.909C32.7696 718.168 31.1892 720 29.2397 720H4.52996Z'
          fill='#103776'
        />
        <path
          d='M62.5421 720C60.5925 720 59.0121 718.168 59.0121 715.909V385.558C59.0121 383.298 60.5925 381.467 62.5421 381.467H87.2518C89.2014 381.467 90.7818 383.298 90.7818 385.558V715.909C90.7818 718.168 89.2014 720 87.2518 720H62.5421Z'
          fill='#103776'
        />
        <path
          d='M120.554 720C118.605 720 117.024 718.168 117.024 715.909V353.852C117.024 351.593 118.605 349.761 120.554 349.761H145.264C147.213 349.761 148.794 351.593 148.794 353.852V715.909C148.794 718.168 147.213 720 145.264 720H120.554Z'
          fill='#103776'
        />
        <path
          d='M178.566 720C176.617 720 175.036 718.168 175.036 715.909V420.331C175.036 418.072 176.617 416.24 178.566 416.24H203.276C205.226 416.24 206.806 418.072 206.806 420.331V715.909C206.806 718.168 205.226 720 203.276 720H178.566Z'
          fill='#103776'
        />
        <path
          d='M236.578 720C234.629 720 233.048 718.168 233.048 715.909V314.987C233.048 312.728 234.629 310.896 236.578 310.896H261.288C263.238 310.896 264.818 312.728 264.818 314.987V715.909C264.818 718.168 263.238 720 261.288 720H236.578Z'
          fill='#103776'
        />
        <path
          d='M294.591 720C292.641 720 291.061 718.168 291.061 715.909V346.693C291.061 344.433 292.641 342.602 294.591 342.602H319.3C321.25 342.602 322.83 344.433 322.83 346.693V715.909C322.83 718.168 321.25 720 319.3 720H294.591Z'
          fill='#103776'
        />
        <path
          d='M352.603 720C350.653 720 349.073 718.168 349.073 715.909V335.442C349.073 333.183 350.653 331.351 352.603 331.351H377.312C379.262 331.351 380.842 333.183 380.842 335.442V715.909C380.842 718.168 379.262 720 377.312 720H352.603Z'
          fill='#103776'
        />
        <path
          d='M410.615 720C408.665 720 407.085 718.168 407.085 715.909V332.374C407.085 330.115 408.665 328.283 410.615 328.283H435.325C437.274 328.283 438.855 330.115 438.855 332.374V715.909C438.855 718.168 437.274 720 435.325 720H410.615Z'
          fill='#103776'
        />
        <path
          d='M468.627 720C466.677 720 465.097 718.168 465.097 715.909V325.215C465.097 322.955 466.677 321.124 468.627 321.124H493.337C495.286 321.124 496.867 322.955 496.867 325.215V715.909C496.867 718.168 495.286 720 493.337 720H468.627Z'
          fill='#103776'
        />
        <path
          d='M526.639 720C524.69 720 523.109 718.168 523.109 715.909V292.486C523.109 290.227 524.69 288.395 526.639 288.395H551.349C553.298 288.395 554.879 290.227 554.879 292.486V715.909C554.879 718.168 553.298 720 551.349 720H526.639Z'
          fill='#103776'
        />
        <path
          d='M584.651 720C582.702 720 581.121 718.168 581.121 715.909V382.489C581.121 380.23 582.702 378.398 584.651 378.398H609.361C611.31 378.398 612.891 380.23 612.891 382.489V715.909C612.891 718.168 611.31 720 609.361 720H584.651Z'
          fill='#103776'
        />
        <path
          d='M642.663 720C640.714 720 639.133 718.168 639.133 715.909V392.717C639.133 390.457 640.714 388.626 642.663 388.626H667.373C669.323 388.626 670.903 390.457 670.903 392.717V715.909C670.903 718.168 669.323 720 667.373 720H642.663Z'
          fill='#103776'
        />
        <path
          d='M700.675 720C698.726 720 697.145 718.168 697.145 715.909V353.852C697.145 351.593 698.726 349.761 700.675 349.761H725.385C727.335 349.761 728.915 351.593 728.915 353.852V715.909C728.915 718.168 727.335 720 725.385 720H700.675Z'
          fill='#103776'
        />
        <path
          d='M758.687 720C756.738 720 755.157 718.168 755.157 715.909V238.28C755.157 236.021 756.738 234.189 758.687 234.189H783.397C785.347 234.189 786.927 236.021 786.927 238.28V715.909C786.927 718.168 785.347 720 783.397 720H758.687Z'
          fill='#103776'
        />
        <path
          d='M816.7 720C814.75 720 813.17 718.168 813.17 715.909V300.669C813.17 298.409 814.75 296.578 816.7 296.578H841.409C843.359 296.578 844.939 298.409 844.939 300.669V715.909C844.939 718.168 843.359 720 841.409 720H816.7Z'
          fill='#103776'
        />
        <path
          d='M874.712 720C872.762 720 871.182 718.168 871.182 715.909V340.556C871.182 338.297 872.762 336.465 874.712 336.465H899.421C901.371 336.465 902.951 338.297 902.951 340.556V715.909C902.951 718.168 901.371 720 899.421 720H874.712Z'
          fill='#103776'
        />
        <path
          d='M932.724 720C930.774 720 929.194 718.168 929.194 715.909V358.966C929.194 356.706 930.774 354.875 932.724 354.875H957.434C959.383 354.875 960.964 356.706 960.964 358.966V715.909C960.964 718.168 959.383 720 957.434 720H932.724Z'
          fill='#103776'
        />
        <path
          d='M990.736 720C988.786 720 987.206 718.168 987.206 715.909V300.669C987.206 298.409 988.786 296.578 990.736 296.578H1015.45C1017.4 296.578 1018.98 298.409 1018.98 300.669V715.909C1018.98 718.168 1017.4 720 1015.45 720H990.736Z'
          fill='#103776'
        />
        <path
          d='M1048.75 720C1046.8 720 1045.22 718.168 1045.22 715.909V275.1C1045.22 272.84 1046.8 271.009 1048.75 271.009H1073.46C1075.41 271.009 1076.99 272.84 1076.99 275.1V715.909C1076.99 718.168 1075.41 720 1073.46 720H1048.75Z'
          fill='#103776'
        />
        <path
          d='M1106.76 720C1104.81 720 1103.23 718.168 1103.23 715.909V223.962C1103.23 221.702 1104.81 219.871 1106.76 219.871H1131.47C1133.42 219.871 1135 221.702 1135 223.962V715.909C1135 718.168 1133.42 720 1131.47 720H1106.76Z'
          fill='#103776'
        />
        <path
          d='M1 5.09104C1 2.83162 2.58042 1 4.52996 1H96.309C98.2585 1 99.8389 2.83162 99.8389 5.09104V15.3186C99.8389 17.5781 98.2585 19.4097 96.309 19.4097H4.52996C2.58042 19.4097 1 17.5781 1 15.3186V5.09104Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M1 35.7738C1 33.5144 2.58042 31.6828 4.52996 31.6828H283.397C285.346 31.6828 286.927 33.5144 286.927 35.7738V46.0014C286.927 48.2608 285.346 50.0925 283.397 50.0925H4.52996C2.58042 50.0925 1 48.2608 1 46.0014V35.7738Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M1036.16 5.09104C1036.16 2.83162 1037.74 1 1039.69 1H1131.47C1133.42 1 1135 2.83162 1135 5.09104V15.3186C1135 17.5781 1133.42 19.4097 1131.47 19.4097H1039.69C1037.74 19.4097 1036.16 17.5781 1036.16 15.3186V5.09104Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M4.52996 720C2.58042 720 1 718.168 1 715.909V483.743C1 481.483 2.58042 479.651 4.52996 479.651H29.2397C31.1892 479.651 32.7696 481.483 32.7696 483.743V715.909C32.7696 718.168 31.1892 720 29.2397 720H4.52996Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M62.5421 720C60.5925 720 59.0121 718.168 59.0121 715.909V385.558C59.0121 383.298 60.5925 381.467 62.5421 381.467H87.2518C89.2014 381.467 90.7818 383.298 90.7818 385.558V715.909C90.7818 718.168 89.2014 720 87.2518 720H62.5421Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M120.554 720C118.605 720 117.024 718.168 117.024 715.909V353.852C117.024 351.593 118.605 349.761 120.554 349.761H145.264C147.213 349.761 148.794 351.593 148.794 353.852V715.909C148.794 718.168 147.213 720 145.264 720H120.554Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M178.566 720C176.617 720 175.036 718.168 175.036 715.909V420.331C175.036 418.072 176.617 416.24 178.566 416.24H203.276C205.226 416.24 206.806 418.072 206.806 420.331V715.909C206.806 718.168 205.226 720 203.276 720H178.566Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M236.578 720C234.629 720 233.048 718.168 233.048 715.909V314.987C233.048 312.728 234.629 310.896 236.578 310.896H261.288C263.238 310.896 264.818 312.728 264.818 314.987V715.909C264.818 718.168 263.238 720 261.288 720H236.578Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M294.591 720C292.641 720 291.061 718.168 291.061 715.909V346.693C291.061 344.433 292.641 342.602 294.591 342.602H319.3C321.25 342.602 322.83 344.433 322.83 346.693V715.909C322.83 718.168 321.25 720 319.3 720H294.591Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M352.603 720C350.653 720 349.073 718.168 349.073 715.909V335.442C349.073 333.183 350.653 331.351 352.603 331.351H377.312C379.262 331.351 380.842 333.183 380.842 335.442V715.909C380.842 718.168 379.262 720 377.312 720H352.603Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M410.615 720C408.665 720 407.085 718.168 407.085 715.909V332.374C407.085 330.115 408.665 328.283 410.615 328.283H435.325C437.274 328.283 438.855 330.115 438.855 332.374V715.909C438.855 718.168 437.274 720 435.325 720H410.615Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M468.627 720C466.677 720 465.097 718.168 465.097 715.909V325.215C465.097 322.955 466.677 321.124 468.627 321.124H493.337C495.286 321.124 496.867 322.955 496.867 325.215V715.909C496.867 718.168 495.286 720 493.337 720H468.627Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M526.639 720C524.69 720 523.109 718.168 523.109 715.909V292.486C523.109 290.227 524.69 288.395 526.639 288.395H551.349C553.298 288.395 554.879 290.227 554.879 292.486V715.909C554.879 718.168 553.298 720 551.349 720H526.639Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M584.651 720C582.702 720 581.121 718.168 581.121 715.909V382.489C581.121 380.23 582.702 378.398 584.651 378.398H609.361C611.31 378.398 612.891 380.23 612.891 382.489V715.909C612.891 718.168 611.31 720 609.361 720H584.651Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M642.663 720C640.714 720 639.133 718.168 639.133 715.909V392.717C639.133 390.457 640.714 388.626 642.663 388.626H667.373C669.323 388.626 670.903 390.457 670.903 392.717V715.909C670.903 718.168 669.323 720 667.373 720H642.663Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M700.675 720C698.726 720 697.145 718.168 697.145 715.909V353.852C697.145 351.593 698.726 349.761 700.675 349.761H725.385C727.335 349.761 728.915 351.593 728.915 353.852V715.909C728.915 718.168 727.335 720 725.385 720H700.675Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M758.687 720C756.738 720 755.157 718.168 755.157 715.909V238.28C755.157 236.021 756.738 234.189 758.687 234.189H783.397C785.347 234.189 786.927 236.021 786.927 238.28V715.909C786.927 718.168 785.347 720 783.397 720H758.687Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M816.7 720C814.75 720 813.17 718.168 813.17 715.909V300.669C813.17 298.409 814.75 296.578 816.7 296.578H841.409C843.359 296.578 844.939 298.409 844.939 300.669V715.909C844.939 718.168 843.359 720 841.409 720H816.7Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M874.712 720C872.762 720 871.182 718.168 871.182 715.909V340.556C871.182 338.297 872.762 336.465 874.712 336.465H899.421C901.371 336.465 902.951 338.297 902.951 340.556V715.909C902.951 718.168 901.371 720 899.421 720H874.712Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M932.724 720C930.774 720 929.194 718.168 929.194 715.909V358.966C929.194 356.706 930.774 354.875 932.724 354.875H957.434C959.383 354.875 960.964 356.706 960.964 358.966V715.909C960.964 718.168 959.383 720 957.434 720H932.724Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M990.736 720C988.786 720 987.206 718.168 987.206 715.909V300.669C987.206 298.409 988.786 296.578 990.736 296.578H1015.45C1017.4 296.578 1018.98 298.409 1018.98 300.669V715.909C1018.98 718.168 1017.4 720 1015.45 720H990.736Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M1048.75 720C1046.8 720 1045.22 718.168 1045.22 715.909V275.1C1045.22 272.84 1046.8 271.009 1048.75 271.009H1073.46C1075.41 271.009 1076.99 272.84 1076.99 275.1V715.909C1076.99 718.168 1075.41 720 1073.46 720H1048.75Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M1106.76 720C1104.81 720 1103.23 718.168 1103.23 715.909V223.962C1103.23 221.702 1104.81 219.871 1106.76 219.871H1131.47C1133.42 219.871 1135 221.702 1135 223.962V715.909C1135 718.168 1133.42 720 1131.47 720H1106.76Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
}
