import { FullStory, isInitialized } from '@fullstory/browser';
import type { Annotation, AnnotationReply, Connector, InsightCategory } from 'aim-components';
import type { NextRouter } from 'next/router';

export const FSPageViewEvent = (router: NextRouter) => {
  sendEvent('PageView', {
    url: `https://aim.arkkapital.com/${router.asPath}`,
  });
};

export const FSFullScreen = (insightsCategory: InsightCategory) => {
  sendEvent('OpenInsightFullscreen', {
    insightsCategory,
  });
};

export const FSConnectEvent = (ctaPlacement: 'insightsView' | 'onboardingPopUp' | 'tab') => {
  sendEvent('ConnectData', {
    ctaPlacement,
  });
};

export const FSInviteEvent = (ctaPlacement: 'onboardingPopUp' | 'settings') => {
  sendEvent('InviteUser', {
    ctaPlacement,
  });
};

export const FSRequestLoan = (type: 'firstLoan' | 'newLoan') => {
  sendEvent('RequestLoan', {
    type,
  });
};

export const FSConfigureConnector = (connector: Connector, stage: number) => {
  sendEvent('ConfigureConnector', {
    connector,
    stage,
  });
};

export const FSConnectConnector = (connector: Connector, group: 'A' | 'B') => {
  sendEvent('ConnectorConnected', {
    connector,
    group,
  });
};

export const FSChangeConnectorDateRange = (
  connector: Connector,
  includeFrom: Date | undefined,
  excludeFrom: Date | undefined,
) => {
  sendEvent('ChangeConnectorDateRange', {
    connector,
    includeFrom,
    excludeFrom,
  });
};

export const FSAddConnector = (connector: Connector) => {
  sendEvent('AddConnector', {
    connector,
  });
};

export const FSCreateAnnotation = (annotation: Annotation) => {
  sendEvent('CreateAnnotation', {
    annotation,
  });
};

export const FSCreateAnnotationReply = (annotation: AnnotationReply) => {
  sendEvent('CreateAnnotationReply', {
    annotation,
  });
};

export const FSShowSubscriptionPlans = ({ eventSource }: { eventSource: string }) => {
  sendEvent('ShowSubscriptionPlans', {
    eventSource,
  });
};

export const FSExpandSubscriptionPlanFeatureAccordion = ({ feature }: { feature: string }) => {
  sendEvent('ExpandSubscriptionPlanFeatureAccordion', {
    feature,
  });
};

export const FSDismissUpgradePlanCallToAction = ({ eventSource }: { eventSource: string }) => {
  sendEvent('DismissUpgradePlanCallToAction', {
    eventSource,
  });
};

export const FSOpenStripeCustomerPortal = () => {
  sendEvent('OpenStripeCustomerPortal');
};

export const FSInitiateStripeCheckout = ({ tier }: { tier: string }) => {
  sendEvent('InitiateStripeCheckout', {
    tier,
  });
};

export const FSCloseStripeCheckout = () => {
  sendEvent('CloseStripeCheckout');
};

export const FSOpenContactSalesModal = () => {
  sendEvent('OpenContactSalesModal');
};

export const FSCloseContactSalesModal = () => {
  sendEvent('CloseContactSalesModal');
};

export const FSClickContactSalesModalMailToLink = () => {
  sendEvent('ClickContactSalesModalMailToLink');
};

export const FSStartProductTour = (tourID: string) => {
  sendEvent('StartProductTour', {
    tourID: tourID,
  });
};

export const FSFinalizeProductTour = (tourID: string) => {
  sendEvent('FinalizeProductTour', {
    tourID: tourID,
  });
};

export const FSOpenWizard = (wizardID: string) => {
  sendEvent('OpenWizard', {
    wizardID: wizardID,
  });
};

export const FSCloseWizard = (wizardID: string) => {
  sendEvent('CloseWizard', {
    wizardID: wizardID,
  });
};

export const FSFindDataValidation = (interactionTyp: 'troubleshoot' | 'validate', from: 'banner' | 'progressBar') => {
  sendEvent('InteractWithProgressBar', { interactionTyp, from });
};

export const FSCreateSpace = ({ templateReference }: { templateReference: string }) => {
  sendEvent('CreateSpace', { templateReference });
};

export const FSRemoveSpace = ({ spaceReference }: { spaceReference: string }) => {
  sendEvent('RemoveSpace', { spaceReference });
};

export const FSAddInsightsToSpace = ({
  spaceReference,
  insightIds,
}: {
  spaceReference: string;
  insightIds: string[];
}) => {
  sendEvent('AddInsightsToSpace', { spaceReference, insightIds });
};

export const FSBootIntercom = ({
  withOptions,
  arkUserId,
  tenant,
  hashLength,
}: {
  withOptions: boolean;
  arkUserId?: string | null;
  tenant?: string | null;
  hashLength?: number;
}) => {
  sendEvent('BootIntercom', {
    withOptions,
    arkUserId,
    tenant,
    hashLength,
  });
};

export const FSErrorPage = (url: string, errorSource: string) => {
  sendEvent('ErrorPage', {
    url,
    errorSource,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendEvent = (eventName: string, eventProperties: Record<string, any> = {}) => {
  if (isInitialized()) {
    FullStory('trackEvent', {
      name: eventName,
      properties: {
        ...eventProperties,
        companyName: sessionStorage.getItem('aim_tenant'),
      },
    });
  }
};

export const getCurrentFullstorySessionUrl = (options?: Parameters<typeof FullStory<'getSession'>>[1]) => {
  if (isInitialized()) {
    return FullStory('getSession', options);
  }
};
