import type { HTMLAttributes } from 'react';
import React from 'react';
import { Icon } from 'aim-components/src/components/Icon';
import cn from 'classnames';
import styles from './DeleteIcon.module.css';

interface DeleteIconProps extends HTMLAttributes<HTMLDivElement> {
  onArchive?: () => void;
}

export const DeleteIcon = ({ onArchive, ...props }: DeleteIconProps) => {
  return (
    <>
      {onArchive && (
        <div
          className={cn(styles.trashIcon, props.className)}
          onClick={(e) => {
            onArchive();
            e.stopPropagation();
            e.isDefaultPrevented();
          }}
        >
          <Icon icon='delete-trash-can' />
        </div>
      )}
    </>
  );
};
