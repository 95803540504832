import React, { useCallback, useState } from 'react';
import { TextInput } from '../Input';
import { Icon } from '../Icon';
import { ConnectorSearchList } from '..';
import ConnectorAdd from './ConnectorList/ConnectorAdd';
import type { Connector, ConnectorDefinitionCategory } from './types';
import styles from './ConnectorInputSearch.module.css';
import classNames from 'classnames';

interface ConnectorInputSearchProps {
  connectors: Connector[];
  category: ConnectorDefinitionCategory;
  closeModal: () => void;
  handleAddConnector?: (searchString: string) => void;
  onCheckboxChange: (connector: Connector) => void;
}

const ConnectorInputSearch = ({
  connectors,
  category,
  closeModal,
  handleAddConnector,
  onCheckboxChange,
}: ConnectorInputSearchProps) => {
  const [searchString, setSearchString] = useState('');

  const onAddConnector = useCallback(() => {
    handleAddConnector && handleAddConnector(searchString);
    closeModal();
    setSearchString('');
  }, [closeModal, handleAddConnector, searchString]);
  return (
    <>
      <TextInput
        onChange={(e) => setSearchString(e.target.value)}
        placeholder='Search and select your data source(s)'
        value={searchString}
        icon={<Icon icon='search' />}
        autoFocus
        label={`Select your ${category === 'revenue' ? 'revenue' : 'other'} system(s)`}
      />
      <div className={classNames({ [styles.searchContainer]: searchString.length > 0 })}>
        {searchString.length > 0 && <ConnectorAdd searchString={searchString} handleAddConnector={onAddConnector} />}
        <ConnectorSearchList
          onCheckboxChange={(connector) => {
            setSearchString('');
            onCheckboxChange(connector);
          }}
          connectors={connectors}
          searchString={searchString}
        />
      </div>
    </>
  );
};

export default ConnectorInputSearch;
