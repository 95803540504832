import type { Chart, ChartArea, Plugin } from 'chart.js';
import { scaleLinear } from 'd3';
import type { MutableRefObject } from 'react';
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import {
  computeScaleFactor,
  computeXPos,
  drawTooltip,
  getLimitedMax,
  getLimitedMin,
  isPanning,
  isScaling,
} from './utils.mobile';
import { isValidDate, parseDate } from '../../utils/date-utils';
import type { ChartMobileProps } from './types';
import { isChartOptionsWithCustomOptions } from './types';

export const useGestures = (
  numberOfLabels: number,
  chartContainerRef: MutableRefObject<HTMLDivElement>,
  xMin: number,
  xMax: number,
  setXMin: React.Dispatch<React.SetStateAction<number>>,
  setXMax: React.Dispatch<React.SetStateAction<number>>,
  defaultMin: number,
  defaultMax: number,
  chartArea?: ChartArea,
) => {
  const xAxisScale = useMemo(
    () =>
      scaleLinear()
        .domain([xMin, xMax])
        .range([0, chartArea?.width ?? 0]),
    [chartArea?.width, xMax, xMin],
  );

  const xAxisScaleMin = useRef(defaultMin);
  const xAxisScaleMax = useRef(defaultMax);

  useEffect(() => {
    xAxisScaleMin.current = defaultMin;
    xAxisScaleMax.current = defaultMax;
  }, [defaultMax, defaultMin]);

  const prevTouchXPos = useRef<number>();
  const prevScaleFactor = useRef<number>(1);
  const prevDistanceToTouchPos = useRef<number>();

  useLayoutEffect(() => {
    const target = chartContainerRef.current;
    if (!target) return;
    const disablePinchZoom = (e: Event) => {
      xAxisScaleMin.current = xMin;
      xAxisScaleMax.current = xMax;
      prevTouchXPos.current = undefined;
      prevScaleFactor.current = 1;
      prevDistanceToTouchPos.current = undefined;
      e.preventDefault();
    };
    target.addEventListener('touchend', disablePinchZoom, { passive: false });
    return () => {
      target.removeEventListener('touchend', disablePinchZoom);
    };
  }, [chartContainerRef, xMax, xMin]);

  useLayoutEffect(() => {
    const target = chartContainerRef.current;
    if (!target) return;
    const handleTouchMove = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        const xPos = computeXPos(e);
        !prevTouchXPos.current && (prevTouchXPos.current = xPos);
        const panning = Math.floor(xAxisScale.invert(xPos) - xAxisScale.invert(prevTouchXPos.current ?? xPos));

        if (isPanning(panning)) {
          prevTouchXPos.current = xPos;
          const direction = Math.sign(panning);
          if (!(xMin === 0 && direction === 1) && !(xMax === numberOfLabels && direction === -1)) {
            xAxisScaleMin.current = getLimitedMin(xMin - panning);
            xAxisScaleMax.current = getLimitedMax(numberOfLabels, xMax - panning);
            setXMin(xAxisScaleMin.current);
            setXMax(xAxisScaleMax.current);
          }
        } else {
          const scaleFactor = computeScaleFactor(prevDistanceToTouchPos.current, prevScaleFactor.current, e);
          prevScaleFactor.current = scaleFactor;
          prevDistanceToTouchPos.current = Math.abs(xPos - e.touches[0].pageX) + Math.abs(xPos - e.touches[1].pageX);
          if (isScaling(scaleFactor)) {
            const touchIndex = Math.floor(xAxisScale.invert(xPos));
            const scaledMin = getLimitedMin(touchIndex - (touchIndex - xAxisScaleMin.current) / scaleFactor);
            const scaledMax = getLimitedMax(
              numberOfLabels,
              touchIndex + (xAxisScaleMax.current - touchIndex) / scaleFactor,
            );

            if (scaledMin !== scaledMax) {
              setXMin(scaledMin);
              setXMax(scaledMax);
            }
          }
        }
      }
      e.preventDefault();
    };
    target.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      target.removeEventListener('touchmove', handleTouchMove);
    };
  }, [chartContainerRef, numberOfLabels, setXMax, setXMin, xAxisScale, xMax, xMin]);

  return { isPerformingGesture: !!prevTouchXPos.current };
};

export const useTooltipPlugin = (handleHoverDataPoint?: (xValue: number | null) => void) => {
  const corsair = useRef<{
    draw: boolean; // * Whether or not to draw the tooltip (e.g. if the pointer is out of bounds)
    pointerX: number | null; // * The X pixel position of the pointer
    closestX: number | null; // * The X pixel position of the closest data point to the pointer
  }>({ draw: false, pointerX: null, closestX: null });

  return {
    id: 'corsair',
    afterEvent: (chart, evt) => {
      if (!isChartOptionsWithCustomOptions(chart.options) || !handleHoverDataPoint) return;
      if (chart.options.customOptions?.displayMobileTooltip !== true) return;

      const {
        chartArea: { top, bottom, left, right },
      } = chart;
      const { x, y } = evt.event;
      if (x && y) {
        if (x < left || x > right || y < top || y > bottom) {
          corsair.current = { draw: false, pointerX: null, closestX: null };
          chart.draw();
          handleHoverDataPoint && handleHoverDataPoint(null);
          return;
        }

        if (corsair.current.pointerX !== x) {
          const datasetMetas = chart.getSortedVisibleDatasetMetas();

          const metaInfo = {
            minDataPointX: Number.MAX_SAFE_INTEGER,
            maxDataPointX: Number.MIN_SAFE_INTEGER,
            closestX: x,
            closestDiff: Number.MAX_SAFE_INTEGER,
          };

          // ? Find the closest data point to the cursor (based on x value)
          datasetMetas.forEach((datasetMeta) => {
            datasetMeta.data.forEach((dataPoint) => {
              if (dataPoint.x < metaInfo.minDataPointX) metaInfo.minDataPointX = dataPoint.x;
              if (dataPoint.x > metaInfo.maxDataPointX) metaInfo.maxDataPointX = dataPoint.x;

              const diff = Math.abs(dataPoint.x - x);
              if (diff < metaInfo.closestDiff) {
                metaInfo.closestDiff = diff;
                metaInfo.closestX = dataPoint.x;
              }
            });
          });

          // ? Don't show tooltip if outside the data range
          if (x < metaInfo.minDataPointX || x > metaInfo.maxDataPointX) {
            corsair.current = { draw: false, pointerX: null, closestX: null };
            handleHoverDataPoint && handleHoverDataPoint(null);
            chart.draw();
            return;
          }

          // ? Snap the hover point to the closest data point (based on x value)
          const xValue = chart.scales.x.getValueForPixel(metaInfo.closestX);
          handleHoverDataPoint && handleHoverDataPoint(xValue ?? null);
          corsair.current = { draw: true, pointerX: x, closestX: metaInfo.closestX };
        }
      }

      chart.draw();
    },
    afterDatasetsDraw: (chart: Chart & { formatTooltipTitle?: ChartMobileProps['formatTooltipTitle'] }) => {
      const { closestX, draw } = corsair.current;
      if (!draw || closestX === null) return;

      if (!isChartOptionsWithCustomOptions(chart.options) || !handleHoverDataPoint) return;
      if (chart.options.customOptions?.displayMobileTooltip !== true) return;

      const { ctx, chartArea } = chart;

      // Custom tooltip title formatter
      if (typeof chart.formatTooltipTitle === 'function') {
        const title = chart.formatTooltipTitle(chart.tooltip);
        drawTooltip(ctx, closestX, chartArea, title);
        return;
      }

      // Default tooltip title formatter, for time series
      const defaultTooltipTitle = chart.tooltip?.title?.[0];
      if (!defaultTooltipTitle || !isValidDate(new Date(defaultTooltipTitle))) return;
      const { timeGranularity = 'months' } = chart.options.customOptions;

      const title = parseDate(chart.tooltip?.title?.[0] ?? '', timeGranularity);
      drawTooltip(ctx, closestX, chart.chartArea, title);
    },
  } satisfies Plugin;
};
