import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';
import './Icon.css';

import * as SvgIcon from './SvgIcons';

export const ICONS = {
  'annotation-solid': SvgIcon.AnnotationBubbleSolid,
  'arrow-left-to-line': SvgIcon.ArrowLeftToLine,
  'arrow-left': SvgIcon.ArrowLeft,
  'arrow-right': SvgIcon.ArrowRight,
  'arrow-down': SvgIcon.ArrowDownRegular,
  'arrow-down-to-line': SvgIcon.ArrowDownToLine,
  'arrow-up': SvgIcon.ArrowUpRegular,
  'arrow-up-arrow-down': SvgIcon.ArrowDownArrowUpRegular,
  'arrow-up-from-square': SvgIcon.ArrowUpRightFromSquare,
  badge: SvgIcon.Badge,
  'bell-solid': SvgIcon.BellSolid,
  'chart-column': SvgIcon.ChartColumn,
  'chart-gantt': SvgIcon.ChartGantt,
  'chart-scatter-bubble': SvgIcon.ChartScatterBubble,
  'chart-scatter': SvgIcon.ChartScatter,
  'chart-waterfall': SvgIcon.ChartWaterfall,
  'chart-solid': SvgIcon.ChartLineSolid,
  'chart-up-down-solid': SvgIcon.ChartLineUpDownSolid,
  'chart-up-down': SvgIcon.ChartLineUpDownRegular,
  check: SvgIcon.Check,
  'cloud-exclamation': SvgIcon.CloudExclamation,
  code: SvgIcon.Code,
  'comments-dollar-solid': SvgIcon.CommentsDollarSolid,
  'comments-dollar': SvgIcon.CommentsDollar,
  'comments-solid': SvgIcon.CommentsSolid,
  crown: SvgIcon.Crown,
  'data-source': SvgIcon.Database,
  'delete-trash-can': SvgIcon.Trash,
  'delete-x': SvgIcon.Xmark,
  'diagram-cells': SvgIcon.DiagramCellsLight,
  'dropdown-down': SvgIcon.ChevronDown,
  'dropdown-up': SvgIcon.ChevronUp,
  'external-link': SvgIcon.ArrowUpRightFromSquare,
  'face-sad-sweat': SvgIcon.FaceSadSweat,
  'failure-circle': SvgIcon.CircleXmark,
  'favorite-solid': SvgIcon.StarSolid,
  'file-invoice': SvgIcon.FileInvoiceLight,
  'file-regular': SvgIcon.FileRegular,
  'file-spreadsheet': SvgIcon.FileSpreadsheet,
  'flask-gear-solid': SvgIcon.FlaskGearSolid,
  'folders-sharp-solid': SvgIcon.FoldersSharpSolid,
  'folders-sharp': SvgIcon.FoldersSharp,
  'gauge-simple-high': SvgIcon.GaugeSimpleHigh,
  'grip-lines': SvgIcon.GripLines,
  'half-stroke-circle': SvgIcon.HalfStrokeCircle,
  handshake: SvgIcon.Handshake,
  'handshake-solid': SvgIcon.HandshakeSolid,
  'info-circle': SvgIcon.CircleInfo,
  'invite-user': SvgIcon.UserPlus,
  leaf: SvgIcon.Leaf,
  'log-out': SvgIcon.ArrowRightFromBracket,
  'menu-bars': SvgIcon.Bars,
  'menu-horizontal': SvgIcon.Ellipsis,
  'menu-vertical': SvgIcon.EllipsisVertical,
  'money-check-dollar-pen': SvgIcon.MoneyCheckDollarPenLight,
  'monitor-waveform-solid': SvgIcon.MonitorWaveformSolid,
  'monitor-waveform': SvgIcon.MonitorWaveform,
  'nav-back-chevron': SvgIcon.ChevronLeft,
  'nav-back': SvgIcon.ArrowLeft,
  'nav-forward-chevron': SvgIcon.ChevronRight,
  'nav-forward': SvgIcon.ArrowRight,
  'objects-column': SvgIcon.ObjectsColumn,
  'paper-plane': SvgIcon.PaperPlaneTop,
  'piggy-bank-solid': SvgIcon.PiggyBankSolid,
  'piggy-bank': SvgIcon.PiggyBank,
  'play-solid': SvgIcon.PlaySharpSolid,
  'plug-solid': SvgIcon.PlugSolid,
  'presentation-screen': SvgIcon.PresentationScreen,
  'question-mark': SvgIcon.Question,
  'regular-circle': SvgIcon.CircleRegular,
  'rocket-launch-solid': SvgIcon.RocketLaunchSolid,
  'rocket-launch': SvgIcon.RocketLaunch,
  'shield-check': SvgIcon.ShieldCheck,
  sidebar: SvgIcon.Sidebar,
  sparkles: SvgIcon.Sparkles,
  'solid-failure-circle': SvgIcon.SolidFailureCircle,
  'solid-success-circle': SvgIcon.SolidSuccessCircle,
  'sort-down': SvgIcon.ArrowDownLong,
  'sort-up': SvgIcon.ArrowUpLong,
  'success-circle': SvgIcon.CircleCheck,
  table: SvgIcon.Table,
  'thumbs-down': SvgIcon.ThumbsDown,
  'thumbs-up': SvgIcon.ThumbsUp,
  'user-gear': SvgIcon.UserGear,
  'user-hair-mullet-solid': SvgIcon.UserHairMulletSolid,
  'user-hair-mullet': SvgIcon.UserHairMullet,
  'wand-magic-sparkles-solid': SvgIcon.WandMagicSparklesSolid,
  'wand-magic-sparkles': SvgIcon.WandMagicSparkles,
  'warning-circle': SvgIcon.CircleExclamation,
  'warning-triangle': SvgIcon.TriangleExclamation,
  'wave-pulse': SvgIcon.WavePulse,
  'sort-up-solid': SvgIcon.SortUpSolid,
  'sort-down-solid': SvgIcon.SortDownSolid,
  add: SvgIcon.Plus,
  annotation: SvgIcon.AnnotationBubble,
  bell: SvgIcon.Bell,
  building: SvgIcon.Building,
  calendar: SvgIcon.Calendar,
  chart: SvgIcon.ChartLine,
  clock: SvgIcon.ClockRegular,
  close: SvgIcon.Xmark,
  comments: SvgIcon.Comments,
  copy: SvgIcon.CopyLight,
  download: SvgIcon.Download,
  expand: SvgIcon.Expand,
  facebook: SvgIcon.Facebook,
  favorite: SvgIcon.Star,
  filter: SvgIcon.BarsFilter,
  flag: SvgIcon.FlagSwallowtail,
  google: SvgIcon.Google,
  'google-color': SvgIcon.GoogleColor,
  grid: SvgIcon.Grid2,
  hide: SvgIcon.EyeSlash,
  home: SvgIcon.House,
  instagram: SvgIcon.Instagram,
  link: SvgIcon.Link,
  linkedin: SvgIcon.Linkedin,
  loading: SvgIcon.SpinnerThird,
  lock: SvgIcon.LockKeyhole,
  maximize: SvgIcon.Maximize,
  microsoft: SvgIcon.Microsoft,
  'microsoft-color': SvgIcon.MicrosoftColor,
  pause: SvgIcon.Pause,
  pen: SvgIcon.Pen,
  placeholder: SvgIcon.Leaf,
  play: SvgIcon.Play,
  plug: SvgIcon.PlugRegular,
  projector: SvgIcon.Projector,
  remove: SvgIcon.Minus,
  revenue: SvgIcon.DollarSign,
  search: SvgIcon.MagnifyingGlass,
  settings: SvgIcon.Gear,
  share: SvgIcon.Share,
  show: SvgIcon.Eye,
  shrink: SvgIcon.Compress,
  'sidebar-flip': SvgIcon.SidebarFlip,
  sort: SvgIcon.Sort,
  stop: SvgIcon.Stop,
  success: SvgIcon.Check,
  sync: SvgIcon.Rotate,
  upload: SvgIcon.Upload,
  user: SvgIcon.User,
  users: SvgIcon.Users,
  'badge-check': SvgIcon.BadgeCheckRegular,
} as const satisfies Record<Lowercase<string>, React.FC>;

export type IconName = keyof typeof ICONS;
type IconSize = 'small' | 'medium' | 'large' | 'xs' | 'xl';
type IconAnimation = 'spin';

export interface IconProps extends React.HTMLAttributes<HTMLElement> {
  icon: IconName;
  size?: IconSize;
  animation?: IconAnimation;
  fillColor?: string;
}

export const Icon = ({
  icon = 'placeholder',
  size = 'small',
  animation,
  className,
  fillColor,
  ...rest
}: IconProps): ReactElement => {
  const SvgIcon = ICONS[icon];
  return (
    <i className={cn('Icon', className)} {...rest} data-size={size} data-animation={animation}>
      <SvgIcon fill={fillColor} />
    </i>
  );
};

const IconStack = ({ children }: { children: ReactNode }) => {
  return <div className='IconStack'>{children}</div>;
};

Icon.Stack = IconStack;
