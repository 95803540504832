import type { MutableRefObject, ReactElement } from 'react';
import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';
import './../Slider.css';
import cn from 'classnames';
import { max, min, scaleLinear } from 'd3';
import { useResizeObserver } from 'aim-utils';

export interface SliderProps {
  rangeMin?: number;
  rangeMax: number;
  initialMinValue: number;
  initialMaxValue: number;
  getSliderRange: (range: number[]) => void;
  highlightedTicks?: number[];
}

type DraggablePart = 'thumb-left' | 'thumb-right' | 'slider-track';

export const Slider = ({
  rangeMin = 0,
  rangeMax,
  initialMinValue,
  initialMaxValue,
  getSliderRange,
  highlightedTicks = [],
}: SliderProps): ReactElement => {
  const padding = 5;
  const ref = useRef<SVGSVGElement>() as MutableRefObject<SVGSVGElement>;
  const [containerRef, { width }] = useResizeObserver<HTMLDivElement>();
  const scale = scaleLinear()
    .domain([rangeMin, rangeMax])
    .range([0, width - 4 * padding]);
  const ticks = scale.ticks(rangeMax);

  const [minVal, setMinVal] = useState<number>(initialMinValue);
  const [maxVal, setMaxVal] = useState<number>(initialMaxValue);

  const minValRef = useRef(initialMinValue);
  const maxValRef = useRef(initialMaxValue);

  const [partBeingDragged, setPartBeingDragged] = useState<DraggablePart | undefined>(undefined);

  useEffect(() => {
    setMinVal(initialMinValue);
    setMaxVal(initialMaxValue);
    minValRef.current = initialMinValue;
    maxValRef.current = initialMaxValue;
  }, [initialMinValue, initialMaxValue]);

  const sliderDragRef = useRef<number>(0);

  const getClosestTick = useCallback((goal: number) => Math.round(scale.invert(goal)), [scale]);

  function onDragSliderStart(e: React.PointerEvent<SVGRectElement>) {
    if (ref.current === null) return;
    setPartBeingDragged('slider-track');
    const position = e.clientX - ref.current.getBoundingClientRect().left - padding;
    const clampedPosition = max<number>([0, min([position, scale.range()[1]]) ?? 0]) ?? 0;
    sliderDragRef.current = getClosestTick(clampedPosition);
  }

  const handleSliderTrackDragged = useCallback(
    (e: PointerEvent) => {
      if (ref.current === null) return;
      e instanceof MouseEvent && e.preventDefault();

      const position = e.clientX - ref.current.getBoundingClientRect().left - padding;
      const clampedPosition = max<number>([0, min([position, scale.range()[1]]) ?? 0]) ?? 0;
      const newPosition = getClosestTick(clampedPosition);

      minValRef.current += newPosition - sliderDragRef.current;
      maxValRef.current += newPosition - sliderDragRef.current;

      // Make sure we don't go outside of range
      if (minValRef.current < rangeMin) {
        maxValRef.current -= minValRef.current - rangeMin;
        minValRef.current -= minValRef.current - rangeMin;
      }
      if (maxValRef.current > rangeMax) {
        minValRef.current -= maxValRef.current - rangeMax;
        maxValRef.current -= maxValRef.current - rangeMax;
      }
      sliderDragRef.current = newPosition;

      setMinVal(minValRef.current);
      setMaxVal(maxValRef.current);
    },
    [getClosestTick, rangeMax, rangeMin, scale],
  );

  const handleThumbDragged = useCallback(
    (e: PointerEvent, thumbSide: 'left' | 'right') => {
      if (ref.current === null) return;
      const position = e.clientX - ref.current.getBoundingClientRect().left - padding;
      const clampedPosition = max<number>([0, min([position, scale.range()[1]]) ?? 0]) ?? 0;
      thumbSide === 'left'
        ? setMinVal(min([getClosestTick(clampedPosition), maxVal - 1]) ?? 0)
        : setMaxVal(max([getClosestTick(clampedPosition), minVal + 1]) ?? 0);
    },
    [getClosestTick, maxVal, minVal, scale],
  );

  useEffect(() => {
    const draggedPartHandlers = {
      'thumb-left': (e: PointerEvent) => handleThumbDragged(e, 'left'),
      'thumb-right': (e: PointerEvent) => handleThumbDragged(e, 'right'),
      'slider-track': (e: PointerEvent) => handleSliderTrackDragged(e),
    } as const satisfies Record<DraggablePart, (e: PointerEvent) => void>;

    const handlePointerUp = () => setPartBeingDragged(undefined);

    if (partBeingDragged !== undefined) {
      document.addEventListener('pointermove', draggedPartHandlers[partBeingDragged]);
      document.addEventListener('pointerup', handlePointerUp);
    }

    return () => {
      Object.keys(draggedPartHandlers).forEach((part) => {
        document.removeEventListener('pointermove', draggedPartHandlers[part as DraggablePart]);
      });
      document.removeEventListener('pointerup', handlePointerUp);
    };
  }, [partBeingDragged, handleThumbDragged, handleSliderTrackDragged]);

  useLayoutEffect(() => {
    getSliderRange([minVal, maxVal]);
  }, [getSliderRange, maxVal, minVal]);

  return (
    <div className='sliderContainer' ref={containerRef}>
      {maxVal > 0 && scale(maxVal) > scale(minVal) && (
        <svg width={width + 2 * padding} height={padding * 4} ref={ref}>
          <g transform={`translate(${padding * 2},${padding * 2})`}>
            {ticks.slice(0, -1).map((tick, i) => (
              <g transform={`translate(${scale(tick) + 1},0)`} key={scale(tick)}>
                <rect
                  width={max([width / ticks.length - 2, 1])}
                  height={1}
                  className={cn(highlightedTicks.includes(i) ? 'annotationAccentColorPrimary' : 'sliderTrackD3')}
                />
              </g>
            ))}
            <rect
              y={0}
              x={scale(minVal)}
              width={scale(maxVal) - scale(minVal)}
              height={3}
              className='sliderRangeD3'
              onPointerDown={onDragSliderStart}
            />
            <g transform={`translate(${scale(minVal)}, 0)`}>
              <circle r={padding - 0.5} className='thumbD3' onPointerDown={() => setPartBeingDragged('thumb-left')} />
            </g>
            <g transform={`translate(${scale(maxVal)}, 0)`}>
              <circle r={padding - 0.5} className='thumbD3' onPointerDown={() => setPartBeingDragged('thumb-right')} />
            </g>
          </g>
        </svg>
      )}
    </div>
  );
};
