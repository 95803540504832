import React, { useRef, useMemo } from 'react';
import { to, animated, useSpring } from '@react-spring/web';
import { curveCatmullRom, line as d3Line, area as d3Area, interpolate } from 'd3';
import { interpolatePath } from 'd3-interpolate-path';
import type { D3ChartData, D3ChartDataset } from '../types';
import { ColorSpectrum, addOpacityToHexColor } from 'aim-utils';
import { useSvgDefinitionId } from './hooks';

interface AnimatedBubbleProps {
  dataset: D3ChartDataset;
  xScale: d3.ScalePoint<string>;
  yScale: d3.ScaleLinear<number, number, never>;
  xMin?: string;
  xMax?: string;
}

const BEFORE_FORECAST_COLOR = ColorSpectrum['cool-grey'][300];

const scaleXWithBounds = (
  dataPoint: D3ChartData,
  xScale: d3.ScalePoint<string>,
  xMin?: string,
  xMax?: string,
  padding = 10, // ? The padding is used to make the data points that are out of bounds located a bit outside of the chart, to make sure glow is not visible
) => {
  const [rangeMin, rangeMax] = xScale.range();
  const scaledX = xScale(dataPoint.x) as number;

  if (xMin && dataPoint.x < xMin) return rangeMin - padding;
  if (xMax && dataPoint.x > xMax) return rangeMax + padding;
  return scaledX;
};

const AnimatedLine = ({ dataset, xScale, yScale, xMin, xMax }: AnimatedBubbleProps) => {
  const filteredData = dataset.data.filter((point) => point.x);

  const [, xRangeMax] = xScale.range();
  const [yRangeMin] = yScale.range();

  const forecastOffsetStart = useMemo(() => {
    if (!dataset.forecastFrom) return 0;

    const forecastStartDate = dataset.data[dataset.forecastFrom].x;

    if (xMin && forecastStartDate <= xMin) return 0; // Forecast starts before current xMin, so everything in viewport is forecasted
    if (xMax && forecastStartDate >= xMax) return xScale(xMax) ?? 0; // Forecast starts after current xMax, so there is no forecast in the viewport

    const scaledForecastStart = xScale(forecastStartDate);

    if (scaledForecastStart) return scaledForecastStart;

    return 0;
  }, [dataset.data, dataset.forecastFrom, xMax, xMin, xScale]);

  const line = d3Line<D3ChartData>()
    .curve(curveCatmullRom.alpha(0.7))
    .x((d) => scaleXWithBounds(d, xScale, xMin, xMax))
    .y((d) => yScale(d.y));

  const area = d3Area<D3ChartData>()
    .curve(curveCatmullRom.alpha(0.7))
    .x((d) => scaleXWithBounds(d, xScale, xMin, xMax))
    .y0((d) => yScale(d.y))
    .y1(yRangeMin);

  const ref = useRef(dataset);
  const refYScale = useRef(yScale);

  const lineRef = useRef(line(filteredData) as string);
  const areaRef = useRef(area(filteredData) as string);
  const forecastStartingPointRef = useRef(forecastOffsetStart);

  const { areaInterpolator, lineInterpolator, numberInterpolator } = useMemo(() => {
    return {
      areaInterpolator: interpolatePath(areaRef.current, area(filteredData) as string),
      lineInterpolator: interpolatePath(lineRef.current, line(filteredData) as string),
      numberInterpolator: interpolate(forecastStartingPointRef.current, forecastOffsetStart),
    };
  }, [area, filteredData, forecastOffsetStart, line]);

  const { t } = useSpring({
    config: {
      duration: 500,
    },
    reset: ref.current !== dataset || refYScale.current !== yScale,
    from: { t: 0 },
    to: { t: 1 },
    onChange: () => {
      lineRef.current = lineInterpolator(ref.current !== dataset ? 1 : 0);
      areaRef.current = areaInterpolator(ref.current !== dataset ? 1 : 0);
    },
    onRest: () => {
      lineRef.current = lineInterpolator(1);
      areaRef.current = areaInterpolator(1);
      forecastStartingPointRef.current = forecastOffsetStart;
    },
  });

  const [gradientId, gradientUrl] = useSvgDefinitionId();
  const [forecastId, forecastUrl] = useSvgDefinitionId();
  const [clipPathActual, clipPathActualUrl] = useSvgDefinitionId();
  const [clipPathForecast, clipPathForecastUrl] = useSvgDefinitionId();
  const [glowId, glowUrl] = useSvgDefinitionId();

  return (
    <>
      <defs>
        <linearGradient id={gradientId} x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' stopColor={addOpacityToHexColor(BEFORE_FORECAST_COLOR, 0.5)} />
          <stop offset='80%' stopColor={addOpacityToHexColor(BEFORE_FORECAST_COLOR, 0.2)} />
          <stop offset='100%' stopColor={addOpacityToHexColor(BEFORE_FORECAST_COLOR, 0)} />
        </linearGradient>

        <linearGradient id={forecastId} x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' stopColor={addOpacityToHexColor(dataset.fillColor ?? dataset.color, 0.5)} />
          <stop offset='100%' stopColor={addOpacityToHexColor(dataset.fillColor ?? dataset.color, 0)} />
        </linearGradient>

        <filter id={glowId}>
          <feGaussianBlur stdDeviation={4} result='blur-big' />
          <feGaussianBlur stdDeviation={2} result='blur-small' />

          <feComponentTransfer in='blur-big' result='blur-big-faded'>
            <feFuncA type='linear' slope={0.5} />
          </feComponentTransfer>

          <feComponentTransfer in='blur-small' result='blur-small-faded'>
            <feFuncA type='linear' slope={0.8} />
          </feComponentTransfer>

          <feMerge>
            <feMergeNode in='blur-big-faded' />
            <feMergeNode in='blur-small-faded' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>

      <animated.clipPath id={clipPathForecast}>
        <animated.rect x={to(t, numberInterpolator)} width={xRangeMax} height={yRangeMin} />
      </animated.clipPath>

      <animated.clipPath id={clipPathActual}>
        <animated.rect width={to(t, numberInterpolator)} height={yRangeMin} />
      </animated.clipPath>

      {dataset.area && <animated.path d={to(t, areaInterpolator)} fill={gradientUrl} clipPath={clipPathActualUrl} />}
      {dataset.forecastFrom === undefined && dataset.area && (
        <animated.path d={to(t, areaInterpolator)} fill={forecastUrl} clipPath={clipPathForecastUrl} />
      )}

      <animated.path
        d={to(t, (t) => `M0,0${lineInterpolator(t)}`)} // Added M0,0 in order to draw straight lines
        fill='none'
        filter={dataset.glow ? glowUrl : undefined}
        stroke={dataset.color}
        strokeWidth={dataset?.borderWidth}
        pointerEvents='none'
        strokeDasharray={dataset.borderDash}
      />
      <animated.path
        d={to(t, (t) => `M0,0${lineInterpolator(t)}`)} // Added M0,0 in order to draw straight lines
        fill='none'
        stroke={addOpacityToHexColor(BEFORE_FORECAST_COLOR, 0.8)}
        strokeWidth={2}
        pointerEvents='none'
        clipPath={clipPathActualUrl}
      />
    </>
  );
};
export default AnimatedLine;
