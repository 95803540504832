import type { ReactNode } from 'react';
import { Typography } from '../../Typography';
import './Legend.css';
import cn from 'classnames';
import type { ChartDataset } from '../types';
import { LegendIcon } from './LegendIcon';

export const Legend = ({
  children,
  presentation = 'row',
  truncate,
}: {
  children: ReactNode;
  presentation?: 'column' | 'row' | 'grid';
  truncate?: boolean;
}) => {
  return (
    <div className={cn('Legend', truncate && 'OverflowHidden')} data-legend-presentation={presentation}>
      {children}
    </div>
  );
};

interface LegendItemProps {
  color?: string | string[];
  onClick?: () => void;
  children: ReactNode;
  icon?: ReactNode;
  truncate?: boolean;
}

const LegendItem = ({ color, onClick, children, icon, truncate }: LegendItemProps) => {
  const colors = Array.isArray(color) ? color : [color];
  const definedColors = colors.filter((color) => color !== undefined);

  return (
    <div
      className={cn('LegendItem', { clickable: onClick !== undefined }, truncate && 'OverflowHidden')}
      onClick={onClick}
    >
      {icon
        ? icon
        : definedColors.map((color) => (
            <span key={color} className='legend-color-dot' style={{ backgroundColor: color }} />
          ))}

      <Typography variant='text2' className='legend-label' color='secondary' truncate={truncate}>
        {children}
      </Typography>
    </div>
  );
};

export const getLegendIconFromDataset = (dataset: ChartDataset) => {
  if (dataset.isDotted) {
    return <LegendIcon.DottedLine color={dataset.forecastColor ?? dataset.color} />;
  }
  if (dataset.type === 'line' || dataset.type === 'band' || dataset.type === 'mountain') {
    return <LegendIcon.Line color={dataset.forecastColor ?? dataset.color} />;
  }
  if (dataset.type === 'bar') {
    return <LegendIcon.Square color={dataset.forecastColor ?? dataset.color} />;
  }

  return undefined;
};

Legend.Item = LegendItem;
Legend.getLegendIconFromDataset = getLegendIconFromDataset;
