import type { ReactNode } from 'react';
import React from 'react';
import './LoadingShimmer.css';

interface LoadingShimmerProps {
  children: ReactNode;
}

export const LoadingShimmer = ({ children: svgLayer }: LoadingShimmerProps) => {
  return (
    <div className='loading-shimmer'>
      {svgLayer /* Layer below (used for shimmer effect) */}
      {svgLayer /* Layer above (used for masking) */}
    </div>
  );
};
