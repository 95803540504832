import React from 'react';

const AnnotationBubbleIcon = () => {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg' role='button'>
      <circle cx='19' cy='19' r='19' fillOpacity='0' />
      <path d='M24.2471 24.7301C20.6501 27.2958 10.6462 24.6049 10.6462 24.6049C10.6462 24.6049 11.3588 14.2699 14.9558 11.7042C18.5528 9.13851 23.5487 9.97453 26.1144 13.5715C28.6801 17.1685 27.8441 22.1644 24.2471 24.7301Z' />
      <path
        d='M10.6462 24.6049L9.64855 24.5361L9.59195 25.3568L10.3864 25.5705L10.6462 24.6049ZM10.6462 24.6049C10.3864 25.5705 10.3866 25.5706 10.3869 25.5707L10.3875 25.5708L10.3895 25.5714L10.3962 25.5732L10.4203 25.5795C10.4411 25.585 10.4712 25.5929 10.5102 25.6029C10.588 25.6229 10.7012 25.6516 10.8457 25.6869C11.1345 25.7575 11.5489 25.8548 12.0567 25.9632C13.0706 26.1796 14.465 26.4419 15.9793 26.6226C17.4884 26.8026 19.1466 26.9053 20.6794 26.7901C22.1865 26.6768 23.7061 26.3443 24.8278 25.5442C28.8744 22.6578 29.8149 17.0374 26.9285 12.9908C24.0421 8.9442 18.4217 8.00368 14.3751 10.8901C13.2534 11.6902 12.4443 13.0188 11.8465 14.4068C11.2385 15.8186 10.7958 17.4199 10.4747 18.9054C10.1525 20.396 9.94655 21.7998 9.82108 22.8289C9.75825 23.3444 9.71534 23.7679 9.68805 24.064C9.6744 24.2121 9.66464 24.3284 9.65824 24.4085C9.65504 24.4486 9.65267 24.4796 9.65108 24.5011L9.64926 24.526L9.64877 24.5329L9.64863 24.535L9.64858 24.5356C9.64856 24.5359 9.64855 24.5361 10.6462 24.6049Z'
        strokeWidth='2'
      />
    </svg>
  );
};
export default AnnotationBubbleIcon;
