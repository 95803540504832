import type { UserSettingKey } from '@/lib/settings';
import { setUserSetting } from '@/lib/settings';
import { differenceInSeconds } from 'date-fns';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useRef } from 'react';

type TourId<T extends UserSettingKey = UserSettingKey> = T extends `${string}-tour` ? T : never;

const useProductTourContextProvider = () => {
  const startedTourIds = useRef<Set<TourId>>(new Set());

  // * Keeping track of these allows us to skip unnecessary requests to the backend (there's no need to update a tour that has already been finished)
  const finishedTourIds = useRef<Set<TourId>>(new Set());

  const lastTourStartTime = useRef<Date>(new Date());
  const lastTourFinishTime = useRef<Date>(new Date());

  const markTourAsStarted = useCallback((id: TourId) => {
    if (!startedTourIds.current.has(id)) {
      lastTourStartTime.current = new Date();
      startedTourIds.current.add(id);
    }
  }, []);

  const markTourAsFinished = useCallback(async (id: TourId) => {
    if (!finishedTourIds.current.has(id)) {
      lastTourFinishTime.current = new Date();
      finishedTourIds.current.add(id);

      await setUserSetting(id, { isFinished: true });
    }
  }, []);

  const hasTourBeenStarted = useCallback((id: TourId) => startedTourIds.current.has(id), []);

  const getTimeSinceLastTourUpdate = useCallback(() => {
    const now = new Date();
    const secondsSinceLastStart = differenceInSeconds(now, lastTourStartTime.current);
    const secondsSinceLastFinish = differenceInSeconds(now, lastTourFinishTime.current);
    const secondsSinceLastUpdate = Math.min(secondsSinceLastStart, secondsSinceLastFinish);

    return { secondsSinceLastStart, secondsSinceLastFinish, secondsSinceLastUpdate };
  }, []);

  return { markTourAsStarted, markTourAsFinished, getTimeSinceLastTourUpdate, hasTourBeenStarted } as const;
};

const ProductTourContext = createContext<ReturnType<typeof useProductTourContextProvider> | null>(null);

export const useProductTourContext = () => {
  const context = useContext(ProductTourContext);
  if (!context) throw new Error('useProductTourContext must be used within a ProductTourContextProvider');
  return context;
};

export const ProductTourContextProvider = ({ children }: { children: ReactNode }) => {
  const value = useProductTourContextProvider();

  return <ProductTourContext.Provider value={value}>{children}</ProductTourContext.Provider>;
};
