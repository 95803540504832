import { BREAKPOINT, useMediaQuery } from './useMediaQuery';

const mobileMediaQueries = [
  `(max-width: ${BREAKPOINT.Mobile}px)`,
  // ? Uses (hover: none) to only apply landscape media query to mobile / touch devices
  `(orientation: landscape) and (hover: none) and (max-height: ${BREAKPOINT.Mobile}px)`,
] as const satisfies ReadonlyArray<string>;

export function useMobile() {
  const isMobileViewBasedOnMediaQuery = useMediaQuery(mobileMediaQueries.join(', '));

  return {
    mobileView: isMobileViewBasedOnMediaQuery,
  };
}
