import React from 'react';
import type { BubbleChartAnnotation } from '../types';
import AnnotationCircle from './AnnotationCircle';
import { getXCatheter, getYCatheter } from './utils';

interface AnnotationProps {
  bubbleChartConfig: BubbleChartAnnotation[];
  circleRadius: number;
  marginLeft: number;
  marginTop: number;
  mobileView?: boolean;
}

const Annotation = ({ bubbleChartConfig, circleRadius, marginLeft, marginTop }: AnnotationProps) => {
  const annotations = bubbleChartConfig.map((annotation) => ({
    note: {
      title: annotation.annotationTitle,
      subTitle: annotation.annotationLabel,
      wrap: annotation.wrap ?? 200,
      align: annotation.align ?? 'end',
      wrapSplitter: annotation.wrapSplitter ?? undefined,
    },
    dx: getXCatheter(annotation.angle, circleRadius + (annotation?.dRadius ?? 0)),
    dy: -getYCatheter(annotation.angle, circleRadius + (annotation?.dRadius ?? 0)),
    x: circleRadius + marginLeft,
    y: circleRadius + marginTop,
    subject: {
      radius: circleRadius * annotation.circleRadius,
    },
    color: annotation.color,
    className: annotation.hideSubject ? 'noCircle' : '',
    angle: annotation.angle,
  }));

  return (
    <>
      {annotations.map((a) => {
        const { note, subject, y, dx, x, dy, color, className, angle } = a;
        return (
          <g key={`${note.title}${dx}`}>
            <AnnotationCircle
              x={x}
              y={y}
              dx={dx}
              dy={dy}
              note={note}
              radius={subject.radius}
              className={className}
              color={color}
              angle={angle}
            />
          </g>
        );
      })}
    </>
  );
};
export default Annotation;
