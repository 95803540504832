import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import { Icon } from '../../Icon';
import { TextInput as Input } from '../TextInput';
import type { TextInputProps } from '../TextInput/TextInput';
import styles from './Upload.module.css';
import cn from 'classnames';

interface UploadProps {
  label?: TextInputProps['label'];
  placeholder?: TextInputProps['placeholder'];
  onDelete: () => void;
  onUpload: (files: File) => void;
  acceptedFileTypes?: TextInputProps['acceptedFileTypes'];
  isLoading?: boolean;
  initialValue?: string;
  validationError?: string | undefined;
  disabled?: boolean;
  hint?: string;
}

export const Upload = ({
  label,
  onDelete,
  onUpload,
  acceptedFileTypes,
  placeholder,
  isLoading,
  initialValue = '',
  validationError,
  disabled,
  hint,
}: UploadProps) => {
  const [fileName, setFileName] = useState(initialValue);
  const [prevInitialValue, setPrevInitialValue] = useState(initialValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files && files[0]) {
      setFileName(files[0].name);
      onUpload(files[0]);
    }
  };

  const handleDelete = () => {
    onDelete();
  };

  if (initialValue !== prevInitialValue) {
    setFileName(initialValue);
    setPrevInitialValue(fileName);
  }

  const checkIfDocumentIsValid = () => {
    const isDocumentValid = validationError === '';
    const noDocumentToValidate = validationError === undefined;

    if (isDocumentValid) return true;
    if (noDocumentToValidate) return undefined;
    return false;
  };

  const icon =
    fileName && !isLoading ? (
      <Icon
        icon='delete-trash-can'
        className={styles.delete}
        onClick={handleDelete}
        role='button'
        title='delete-document'
      />
    ) : (
      <label htmlFor={label} className={cn(styles.upload, isLoading ? styles.spin : '')}>
        <Icon icon={isLoading ? 'loading' : 'upload'} animation={isLoading ? 'spin' : undefined} />
      </label>
    );

  // The browser is not allowed to change the value of a file input programmatically
  // https://stackoverflow.com/questions/1696877/how-to-set-a-value-to-a-file-input-in-html
  if (initialValue && fileName) {
    return <Input type='text' label={label} value={fileName} icon={icon} disabled />;
  }

  return (
    <Input
      type='file'
      label={label}
      onChange={handleChange}
      value={fileName}
      icon={icon}
      hint={hint}
      disabled={disabled || fileName !== ''}
      acceptedFileTypes={acceptedFileTypes}
      placeholder={placeholder}
      name={label}
      isValid={checkIfDocumentIsValid()}
      validationError={validationError}
    />
  );
};
