import { ConnectorListItem } from './ConnectorListItem';
import type { Connector } from '../types';

export const ConnectorSearchList = ({
  connectors,
  searchString,
  onCheckboxChange,
}: {
  connectors: Connector[] | null | undefined;
  searchString: string;
  onCheckboxChange: (connector: Connector) => void;
}) => {
  const filteredConnectors = connectors?.filter((d) => d.name.toLowerCase().includes(searchString.toLowerCase())) ?? [];
  if (filteredConnectors.length === 0 || searchString.length === 0) return null;

  return (
    <>
      {filteredConnectors.map((item) => {
        return (
          <ConnectorListItem
            key={item.definitionId}
            name={item.name}
            logoSrc={item.logo}
            onClick={() => onCheckboxChange(item)}
          />
        );
      })}
    </>
  );
};
