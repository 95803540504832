import React from 'react';
import { getXCatheter, getYCatheter } from './utils';
import { Typography } from '../../Typography';

interface note {
  title?: string;
  titleColor?: string;
  subTitle?: string;
  subTitleColor?: string;
  wrap?: number;
  align?: string;
  wrapSplitter?: RegExp;
}

interface AnnotationCircleProps {
  x: number;
  y: number;
  dx: number;
  dy: number;
  note: note;
  radius: number;
  className?: string;
  color?: string;
  angle: number;
}

const AnnotationCircle = ({ x, y, dx, dy, note, radius, className, color, angle }: AnnotationCircleProps) => {
  const splitTitle = note.wrapSplitter ? note.title?.split(note.wrapSplitter) : [note.title];
  const textPadding = 5;
  const lineHeight = 16;
  const lineHeightSubtitle = 45;
  const connectorLineWidth = 30;

  const pathStartPos = [getXCatheter(angle, radius), -getYCatheter(angle, radius)];
  const breakPointPos =
    angle > 90 ? [radius * Math.cos(Math.PI - Math.asin(dy / radius)), dy] : [dx - connectorLineWidth, dy];

  const connector =
    angle > 90
      ? `M ${breakPointPos[0]} ${breakPointPos[1]} L ${dx} ${dy}`
      : `M ${pathStartPos[0]} ${pathStartPos[1]} L ${breakPointPos[0]} ${breakPointPos[1]} L ${dx} ${dy}`;

  const height = lineHeight * (splitTitle?.length ?? 0) + (note.subTitle ? 1 : 0) * lineHeightSubtitle;
  const flipX = angle > 90 && angle < 270;

  return (
    <>
      <g transform={`translate(${x},${y})`} className='bubbleChartAnnotation'>
        {radius > 0 && ( // Radius will be negative on first render
          <circle r={radius} fill='none' stroke={color} strokeWidth='1' className={`annotationCircle ${className}`} />
        )}
        <foreignObject
          width={250}
          height={200}
          transform={`translate(${dx - (flipX ? 250 : -textPadding)}, ${dy - height})`}
        >
          <div style={{ float: flipX ? 'right' : 'left' }}>
            {splitTitle?.map((span, i) => {
              return (
                <Typography variant='text2' key={`${span}${i}`}>
                  {span}
                </Typography>
              );
            })}
            <Typography variant='hero' className='sansSerif' color={color ? 'primaryBlue' : undefined}>
              {note.subTitle}
            </Typography>
          </div>
        </foreignObject>

        <path d={connector} fill='none' stroke={color} strokeWidth={1} />
      </g>
    </>
  );
};

export default AnnotationCircle;
