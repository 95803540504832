import { serverRequest } from '@/lib/request/request';
import type { ApiNotification, Notification } from './index.page';
import { chartKeyInsightCategoryMap } from '@/components/insights/charts/common/types';
import { insightsMap } from '@/components/notifications/utils';
import { NotificationFilterMap } from './notificationFilterMap';

type Method = 'GET' | 'POST' | 'PUT';

export const notifierRequest = <T>(
  route: string,
  method: Method = 'GET',
  body?: Record<string, unknown>,
): Promise<T> => {
  const notifierUrl = process.env.NEXT_PUBLIC_NOTIFIER_URL;
  const url: string = notifierUrl + route;
  return serverRequest<T>({
    method,
    url,
    data: body,
  });
};

export const mapNotificationResponse = (notification: ApiNotification) => {
  // TODO: Change to UNIX after backend updated
  const date = Number.isNaN(+notification.date)
    ? notification.date // Handle legacy string format
    : +notification.date; // Handle UNIX timestamp format

  return {
    eventId: notification.event_id,
    date: new Date(date).toISOString(),
    direction: notification.direction,
    countryCodes: notification.country_codes,
    read: notification.read,
    reference: notification.reference,
    insightFilter: mapNotificationInsightFilter(notification),
    message: notification.message,
  };
};

/* eslint-disable camelcase */
export const mapNotificationInsightFilter = (notification: ApiNotification) => {
  const notificationFilter = NotificationFilterMap[notification.event_id].chartState;
  const country = notification?.country_codes?.[0] ?? null;
  const safeDatasets =
    'datasets' in notificationFilter && notificationFilter.datasets && notificationFilter.datasets?.length > 0
      ? notificationFilter.datasets
      : [];

  return {
    ...notificationFilter,
    country,
    datasets: safeDatasets,
  };
};
/* eslint-enable camelcase */

export const getNotificationChartPath = (slug: string, notification: Notification) => {
  const groupId = chartKeyInsightCategoryMap.get(notification.insightFilter.chartKey);
  if (!slug || !groupId) return null;
  return `/${slug}${insightsMap[groupId].route}`;
};
