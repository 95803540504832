import type { MutableRefObject, ReactElement } from 'react';
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import './../Slider.css';
import { scaleLinear } from 'd3';
import { ColorSpectrum, LineColors, useResizeObserver } from 'aim-utils';

export interface SliderProps {
  value: number;
  setValue: (value: number) => void;
  rangeMin?: number;
  rangeMax: number;
  defaultValue: number;
  color?: string;
  strokeColor?: string;
  ticks?: string[];
  disabled?: boolean;
}

export const ColoredSingleSlider = ({
  value,
  setValue,
  rangeMin = 0,
  rangeMax,
  defaultValue,
  color = LineColors.azure,
  strokeColor = LineColors.azure,
  ticks,
  disabled = false,
}: SliderProps): ReactElement => {
  const thumbRadius = 7;
  const sliderHeight = 7;
  const paddingX = 3;
  const pearlGray = ColorSpectrum['cool-grey'][500];
  const fillColor = disabled ? ColorSpectrum['cool-grey'][300] : color;
  const cursor = disabled ? 'auto' : 'pointer';
  const ref = useRef<SVGSVGElement>() as MutableRefObject<SVGSVGElement>;
  const [chartContainerRef, { width: svgWidth }] = useResizeObserver<HTMLDivElement>();

  const scale = scaleLinear()
    .domain([rangeMin, rangeMax])
    .range([0, svgWidth - 4 * thumbRadius - 2 * paddingX]);
  const ticksArray = useMemo(() => [...Array(ticks?.length).keys()], [ticks?.length]);

  const [isDragged, setIsDragged] = useState<boolean>(false);

  const getClosestTick = useCallback(
    (goal: number) => {
      return ticksArray.reduce((prev, curr) =>
        Math.abs(scale(curr) - goal) < Math.abs(scale(prev) - goal) ? curr : prev,
      );
    },
    [scale, ticksArray],
  );

  const handleThumbDragged = useCallback(
    (e: PointerEvent) => {
      if (ref.current === null) return;
      const position = e.clientX - ref.current.getBoundingClientRect().left - thumbRadius;
      const clampedPosition = Math.max(0, Math.min(position, scale.range()[1]));
      setValue(getClosestTick(clampedPosition));
    },
    [getClosestTick, scale, setValue],
  );

  useEffect(() => {
    const draggedPartHandlers = (e: PointerEvent) => handleThumbDragged(e);

    const handlePointerUp = () => setIsDragged(false);

    if (isDragged !== false) {
      document.addEventListener('pointermove', draggedPartHandlers);
      document.addEventListener('pointerup', handlePointerUp);
    }

    return () => {
      document.removeEventListener('pointermove', draggedPartHandlers);
      document.removeEventListener('pointerup', handlePointerUp);
    };
  }, [isDragged, handleThumbDragged]);

  useEffect(() => {
    setValue(value);
  }, [setValue, value]);

  return (
    <div className='sliderContainer' ref={chartContainerRef}>
      {
        <svg width={svgWidth + 2 * thumbRadius} height={thumbRadius * 4 + (ticks ? 50 : 0)} ref={ref}>
          <g transform={`translate(${thumbRadius * 2 + paddingX},${thumbRadius * 2})`}>
            {ticks &&
              ticks.map((tick, i) => (
                <g transform={`translate(${scale(i) + 1},20)`} key={scale(i)}>
                  <rect width={1} height={10} fill={pearlGray} />
                  <g transform={'translate(0, 30)'} style={{ userSelect: 'none' }}>
                    <text
                      fill={i === value ? fillColor : pearlGray}
                      textAnchor='middle'
                      fontWeight={400}
                      fontSize={10}
                      letterSpacing={1}
                    >
                      {tick}
                    </text>
                  </g>
                  <rect
                    x={-10}
                    y={-30}
                    width={20}
                    height={60}
                    fill='transparent'
                    style={{ cursor: cursor }}
                    onClick={() => !disabled && setValue(i)}
                  />
                </g>
              ))}
            <rect
              width={Math.max(scale(rangeMax), 0)}
              height={sliderHeight}
              fill={pearlGray}
              rx={sliderHeight / 2}
              pointerEvents='none'
            />
            <rect
              x={value < defaultValue ? scale(value) : scale(defaultValue)}
              width={Math.abs(scale(defaultValue) - scale(value))}
              height={sliderHeight}
              fill={fillColor}
            />
            <g transform={`translate(${scale(value) + thumbRadius / 4}, ${sliderHeight / 2})`}>
              <circle
                r={thumbRadius}
                onPointerDown={() => !disabled && setIsDragged(true)}
                fill={fillColor}
                stroke={disabled ? pearlGray : strokeColor}
                strokeWidth={2}
                style={{ cursor: cursor }}
              />
            </g>
          </g>
        </svg>
      }
    </div>
  );
};
