import type { NumberRange, TSciChart } from 'scichart';
import { DpiHelper, NumericTickProvider } from 'scichart';
import type { Interval } from 'date-fns';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
} from 'date-fns';
import { secondsInMonth } from 'date-fns/constants';

import type { TimeGranularity } from 'aim-utils';
import { DynamicDateLabelProvider } from './DynamicDateLabelProvider';
import { isAxisBase2D } from '../utils/type-guards';

/** The width of a label "slot", in pixels */
const LABEL_SLOT_WIDTH_PX = 120;

/** The threshold for the visible range difference, for which **monthly** tick formatting is used instead of **daily** formatting. */
export const USE_MONTHLY_TICKS_INSTEAD_OF_DAILY_ABOVE_VISIBLE_RANGE_DIFF = secondsInMonth * 2;

const timeGranularityIntervalMap = {
  years: eachYearOfInterval,
  quarters: eachQuarterOfInterval,
  months: eachMonthOfInterval,
  weeks: eachWeekOfInterval,
  days: eachDayOfInterval,
} as const satisfies Record<TimeGranularity, (args: Interval<Date>) => Date[]>;

export class ModuloInclusionTickProvider extends NumericTickProvider {
  private lastModuloInclusionCalculation: {
    hash: string;
    includeEveryNthTick: number;
  } | null = null;

  constructor(wasmContext: TSciChart) {
    super(wasmContext);
  }

  getMinorTicks(_minorDelta: number, _majorDelta: number, _visibleRange: NumberRange) {
    return [];
  }

  /**
   * Calculates a stable _"modulo inclusion factor"_ (e.g. `4`, if every 4th label should be preserved).
   *
   * @param numberOfLabelsInRange is the "unstable" number, which might alternate between e.g. 12 -> 13 -> 12 etc. when moving the range slider.
   *
   * This is to prevent different factors when just **moving** the range slider around (i.e. where the diff is unchanged), which could cause flickering.
   * */
  private getStableModuloInclusionFactor(
    visibleRange: NumberRange,
    numberOfLabelsInRange: number,
    timeGranularity: TimeGranularity,
  ) {
    const rangeDifference = visibleRange.diff;
    const axisWidth = this.parentAxis.getAxisSize();

    const hash = `${rangeDifference}-${axisWidth}-${timeGranularity}`;

    if (this.lastModuloInclusionCalculation?.hash === hash) {
      return this.lastModuloInclusionCalculation.includeEveryNthTick;
    }

    const maxNumberOfLabels = Math.floor(axisWidth / (LABEL_SLOT_WIDTH_PX * DpiHelper.PIXEL_RATIO));
    const includeEveryNthTick = Math.ceil(numberOfLabelsInRange / maxNumberOfLabels);

    this.lastModuloInclusionCalculation = { hash, includeEveryNthTick };

    return includeEveryNthTick;
  }

  getMajorTicks(_minorDelta: number, _majorDelta: number, visibleRange: NumberRange) {
    if (!isAxisBase2D(this.parentAxis)) {
      throw new Error('ModuloInclusionTickProvider requires an AxisBase2D parentAxis');
    }

    if (!(this.parentAxis.labelProvider instanceof DynamicDateLabelProvider)) {
      throw new Error('ModuloInclusionTickProvider requires a DynamicDateLabelProvider labelProvider');
    }

    const timeGranularity = this.parentAxis.labelProvider.getTimeGranularity();
    const { min, max } = this.parentAxis.visibleRangeLimit;

    // * Which interval generator to use, based on the time granularity.
    const intervalFunction = (() => {
      if (
        timeGranularity === 'days' &&
        visibleRange.diff > USE_MONTHLY_TICKS_INSTEAD_OF_DAILY_ABOVE_VISIBLE_RANGE_DIFF
      ) {
        return timeGranularityIntervalMap['months'];
      }

      return timeGranularityIntervalMap[timeGranularity];
    })();

    // * Generates an array of unix timestamps within a range, on a certain interval (e.g. every day, every month, etc.).
    const allTickTimestamps = intervalFunction({
      start: new Date(min * 1000),
      end: new Date(max * 1000),
    }).map((date) => date.getTime() / 1000);

    const tickTimestampsInRange = allTickTimestamps.filter((tick) => {
      return tick >= visibleRange.min && tick <= visibleRange.max;
    });

    const includeEveryNthTick = this.getStableModuloInclusionFactor(
      visibleRange,
      tickTimestampsInRange.length,
      timeGranularity,
    );

    // * We perform the modulo inclusion on **all** ticks, not just the ones in the visible range, to keep the ticks stable when moving the range slider.
    const fittedTicks = allTickTimestamps.filter((tick, i) => {
      if (tick < visibleRange.min || tick > visibleRange.max) return false;
      return i % includeEveryNthTick === 0;
    });

    // * If there's no "interval date" in the visible range, we return the unix timestamp for the visible range minimum as a fallback.
    if (fittedTicks.length === 0) return [visibleRange.min];

    return fittedTicks;
  }
}
