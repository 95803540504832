import type { Context } from 'react';
import type { IncomingHttpHeaders } from 'http';
import type { Role } from '@/lib/permissions/role';
import { logger } from 'aim-utils';
// This import is really not valid, but NextJS breaks due to other imports in the barrel file if we don't import it like this
import { base64DecodeUrlSafe } from 'aim-utils/src/base64';
import type { GetServerSidePropsContext } from 'next';
import crypto from 'crypto';
import type { Ownership } from '@/api/tenant/index.page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InferContextType<C extends Context<any>> = C extends Context<infer T> ? T : never;

export const capitalizePersonName = (name: string): string => {
  const reservedNameParts = new Set(['von', 'van', 'de', 'af']);

  return name
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .map((part) => {
      if (reservedNameParts.has(part)) return part;
      return part.charAt(0).toUpperCase() + part.slice(1);
    })
    .join(' ');
};

export const deslugifyTenantName = (tenantSlug = '') => {
  const topLevelDomains = [
    '.com',
    '.net',
    '.org',
    '.co.uk',
    '.uk',
    '.us',
    '.se',
    '.no',
    '.fi',
    '.io',
    '.de',
    '.fr',
    '.es',
    '.dk',
  ] as const;

  const partsToUppercase = new Set(['ab', 'llc']);

  const topLevelDomain = topLevelDomains.find((tld) => tenantSlug.endsWith(tld.replaceAll('.', '-')));

  const slugWithoutTopLevelDomain = topLevelDomain ? tenantSlug.slice(0, -topLevelDomain.length) : tenantSlug;

  return slugWithoutTopLevelDomain
    .split('-')
    .map((part) => {
      if (partsToUppercase.has(part)) return part.toUpperCase();
      return part.charAt(0).toUpperCase() + part.slice(1);
    })
    .join(' ')
    .trim();
};

export type UserSession =
  | {
      loggedIn: true;
      arkUserId: string;
      email: string;
      displayName: string;
      role: Role;
      created: string;
      projectCode: string;
      tenant: string;
      ownership: Ownership;
    }
  | { loggedIn: false; tenant: null; arkUserId: null };

export const getUserSession = (
  context: GetServerSidePropsContext,
): {
  userSession: UserSession;
  intercomHash: string;
} => {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const secret = process.env.INTERCOM_VERIFICATION_SECRET;
  let userSession: UserSession = { loggedIn: false, tenant: null, arkUserId: null };
  let intercomHash = '';

  if (context.req?.headers) {
    const { headers } = context.req;
    if (headers['x-aim-user-id']) {
      // The middleware has set us a logged in
      userSession = {
        loggedIn: true,
        arkUserId: headers['x-aim-user-id'] as string,
        email: headers['x-aim-user-email'] as string,
        displayName: base64DecodeUrlSafe((headers['x-aim-user-display-name'] as string) ?? ''),
        role: headers['x-aim-user-role'] as Role,
        created: headers['x-aim-user-created'] as string,
        tenant: headers['x-aim-tenant'] as string,
        projectCode: headers['x-aim-project-code'] as string,
        ownership: headers['x-aim-tenant-ownership'] as Ownership,
      };

      if (secret) {
        const hmac = crypto.createHmac('sha256', secret);
        intercomHash = hmac.update(headers['x-aim-user-email'] as string).digest('hex');
      }
    }
  }

  const cookies = [];
  const ati = context.req?.headers.cookie?.match(/(?<=_ati=)(.*?)(?=;|$)/)?.[0];
  if (crypto && crypto.randomUUID) {
    const randomUUID = crypto.randomUUID();
    if (!ati) {
      cookies.push(`_ati=${randomUUID}; Path=/; HttpOnly`);
    } else if (!ati.match(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/)) {
      cookies.push(`_ati=${randomUUID}; Path=/; HttpOnly`);
    }
  }

  if (cookies.length > 0) {
    context.res?.setHeader('Set-Cookie', cookies);
  }

  return { userSession, intercomHash };
};

export const getAimHeaders = (headers: IncomingHttpHeaders) => {
  return {
    arkUserId: headers['x-aim-user-id'] as string,
    email: headers['x-aim-user-email'] as string,
    displayName: base64DecodeUrlSafe((headers['x-aim-user-display-name'] as string) ?? ''),
    role: headers['x-aim-user-role'] as Role,
    created: headers['x-aim-user-created'] as string,
    projectCode: headers['x-aim-project-code'] as string,
    tenant: headers['x-aim-tenant'] as string,
  };
};

export const logNextImageError = ({ label, url }: { label: string; url?: string }) => {
  const errorMessage = `onError of <Image /> component triggered | Label: ${label ?? 'N/A'} | URL: ${url ?? 'N/A'}`;
  console.error(errorMessage);
  logger.error({ message: errorMessage, error: new Error(errorMessage) });
};
