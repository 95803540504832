import { differenceInDays } from 'date-fns';
import type { xAxisFormat } from '../types';
import { EMPTY_LABEL, hideContinuouslyDuplicatedLabels, replaceMissingWithEmptyLabel } from './axis-utils';
import { getDateChanges } from './date-changes';
import { formatters } from './formatters';

export const xAxisDateFormatter = (
  labels: string[],
  selectedTimeGranularity: xAxisFormat,
  hideLabels = false,
  width: number,
  xMin?: string,
): Array<string | string[]> => {
  if (!labels) return [];
  if (hideLabels) return labels.map(() => getEmptyLabel(selectedTimeGranularity));
  const formattedLabels = formatLabels(labels, selectedTimeGranularity, width, xMin);
  return formattedLabels.filter((label) => !!label && label !== 'undefined');
};

export const formatLabels = (
  labels: string[],
  selectedTimeGranularity: xAxisFormat,
  width: number,
  xMin?: string,
): Array<string | string[]> => {
  const mappedLabelsToTimezone = labels.map((label) => {
    const newDate = new Date(label);
    return new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
  });

  const duration = differenceInDays(mappedLabelsToTimezone.at(-1) ?? new Date(), mappedLabelsToTimezone[0]);
  const dateChanges = getDateChanges(mappedLabelsToTimezone);

  const formatter =
    selectedTimeGranularity === 'days' && duration > 60 ? formatters['months'] : formatters[selectedTimeGranularity];

  const formattedLabels = formatter({ labels, dateChanges, width, xMin });

  const withoutDuplicates = hideContinuouslyDuplicatedLabels(formattedLabels);
  return replaceMissingWithEmptyLabel(withoutDuplicates, getEmptyLabel(selectedTimeGranularity));
};

const getEmptyLabel = (xAxisFormat: xAxisFormat) => {
  return xAxisFormat === 'monthsRelative' || xAxisFormat === 'quartersRelative' ? [''] : EMPTY_LABEL;
};
