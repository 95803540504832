import React, { useState } from 'react';
import { Button } from 'aim-components';
import { useTranslation } from 'next-i18next';
import ConfirmationModal from '../ConfirmationModal';
import { useRefreshTenantCache } from '../hooks';
import { ProfileRow } from '../profile-settings/ProfileRow';

const RefreshDataCache = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation('settings', { keyPrefix: 'profileComponent' });
  const refreshCache = useRefreshTenantCache();

  return (
    <>
      <ConfirmationModal
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        onConfirm={refreshCache}
        destructive={false}
      />
      <ProfileRow title={t('refreshTitle')} subTitle={t('refreshSubTitle')}>
        <Button onClick={() => setShowConfirmationModal(true)} type='secondary' color='white'>
          {t('refresh')}
        </Button>
      </ProfileRow>
    </>
  );
};

export default RefreshDataCache;
