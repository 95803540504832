import type { ReactNode } from 'react';
import styles from './ConnectorListItem.module.css';
import cn from 'classnames';

export const ConnectorListItem = ({
  logoSrc,
  name,
  checkBox,
  onClick,
  connectorsAsCards,
}: {
  logoSrc?: string;
  name: string;
  checkBox?: ReactNode;
  onClick?: () => void;
  connectorsAsCards?: boolean;
}) => {
  return (
    <div className={cn(styles.content, connectorsAsCards && styles.connectorsAsCards)} onClick={onClick}>
      <div className={styles.logoText}>
        {logoSrc && <img src={logoSrc} />}
        <div className={styles.name}>{name}</div>
      </div>
      {checkBox}
    </div>
  );
};
