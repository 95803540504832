import { SurfaceColors } from 'aim-utils';

export const scoreCountUpColors = [
  SurfaceColors['vivid-red'],
  SurfaceColors.flamingo,
  SurfaceColors.magma,
  SurfaceColors.gold,
  SurfaceColors.lime,
] as const;

export const getScoreCountUpColors = (endCount: number) => {
  const [fromColor, ...toColors] = scoreCountUpColors.slice(0, Math.ceil(endCount) || 1);
  return { fromColor, toColors } as const;
};

const radialGradientImages = ['red', 'pink', 'coral', 'gold', 'lime'] as const;

export const getRadialGradientImageByScore = (endCount: number) => {
  return radialGradientImages[Math.ceil(endCount) - 1];
};

export const formatDelta = (delta: number) => {
  const deltaAbs = Math.abs(delta).toFixed(1);
  const deltaCloseToZero = Math.abs(delta) - 0.05 < 0;
  if (deltaCloseToZero) return `± ${deltaAbs}`;
  if (delta > 0) return `+ ${deltaAbs}`;
  if (delta < 0) return `- ${deltaAbs}`;
};
