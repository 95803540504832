import type { ReactElement } from 'react';
import { useState } from 'react';
import React from 'react';
import styles from './Menu.module.css';
import { Avatar } from '../Avatar';
import type { AvatarProps } from '../Avatar/Avatar';
import { Typography } from '../Typography';
import { MenuItem } from './MenuItem';

interface MenuUserProps extends Omit<AvatarProps, 'size'> {
  email?: string;
}

export interface MenuProps {
  children?: ReactElement | ReactElement[];
  handleSignOut?: () => void;
  onItemClick?: () => void;
  user?: MenuUserProps;
}

export const Menu = ({ children, handleSignOut, user, onItemClick }: MenuProps): ReactElement => {
  const [subItemsToShow, setSubItemsToShow] = useState<number>();
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <Avatar {...user} />
        <div className={styles.userInfo}>
          <Typography variant='text2'>{user?.name ?? ''}</Typography>
          <Typography variant='subtitle'>{user?.email ?? ''}</Typography>
        </div>
      </div>
      <hr />
      <div>
        {React.Children.map(
          children,
          (child, i) =>
            child &&
            React.cloneElement(child, {
              onClick: child.props.subItems
                ? () => {
                    subItemsToShow === i ? setSubItemsToShow(undefined) : setSubItemsToShow(i);
                  }
                : onItemClick,
              subItemsExpanded: subItemsToShow === i,
              onClose: onItemClick,
            }),
        )}
      </div>
      <div className={styles.footer}>
        {handleSignOut && <MenuItem label='Log out' iconName='log-out' onClick={handleSignOut} />}
      </div>
    </div>
  );
};
