import { pack as d3Pack, hierarchy } from 'd3';
import type { D3ChartDataset } from '../types';
export const pack = (data: D3ChartDataset['data'], size: number): d3.HierarchyCircularNode<unknown> =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  d3Pack().size([size, size]).padding(1)(hierarchy<unknown>({ children: data }).sum((d: any) => d.y)); // this computes how big each bubble should be as well as the placing of it

export const getBubbleSize = (percentage: number, outerCircleRadius: number): number =>
  Math.sqrt(percentage) * outerCircleRadius * 2;

const degreesToRadians = (degrees: number): number => {
  return degrees * (Math.PI / 180);
};

export const getXCatheter = (angle: number, hypotenuse: number): number =>
  Math.cos(degreesToRadians(angle)) * hypotenuse;

export const getYCatheter = (angle: number, hypotenuse: number): number =>
  Math.sin(degreesToRadians(angle)) * hypotenuse;

export const circleMovesToFar = (a: number, b: number) => {
  return Math.abs(a - b) > 40;
};
