import type { ReactNode } from 'react';
import { Typography } from '../Typography';
import { Spinner } from '../Loading';
import { WizardFooter } from './WizardFooter';
import { WizardStepContent } from './WizardStepContent';
import { Badge } from '../Badge';
import './WizardStep.css';

export interface WizardStepContent {
  title: string;
  nextStep?: string;
  contentBlocks: Array<string | string[]>;
}

interface WizardStepProps {
  step: WizardStepContent;
  media: ReactNode;
}

export const WizardStep = ({ step, media }: WizardStepProps) => {
  return (
    <section className='Wizard-step'>
      <Badge>NEW RELEASE</Badge>
      <section className='Content-container'>
        <section className='Content'>
          <Typography variant='title1' className='title'>
            {step.title}
          </Typography>
          <section className='Content-body'>
            <WizardStepContent contentBlocks={step.contentBlocks} />
          </section>
        </section>
        <section className='Media-container'>
          <div className='Spinner-container'>
            <Spinner size='big' />
          </div>
          {media}
        </section>
      </section>
      <WizardFooter nextStepText={step.nextStep} />
    </section>
  );
};
