import React from 'react';
import { animated, easings, useSpring } from '@react-spring/web';
import { Arc } from '@visx/shape';
import { addOpacityToHexColor } from 'aim-utils';
import { Gradient } from '../utils';

interface DeltaArrowProps {
  score: number;
  delta: number;
  innerArcRadius: number;
  svgWidth: number;
  svgHeight: number;
  delay: number;
}

export const DeltaArrow = ({ score, innerArcRadius, delta, svgWidth, svgHeight, delay }: DeltaArrowProps) => {
  const arrowColor = '#ffffff';
  const startColor = delta > 0 ? addOpacityToHexColor(arrowColor, 0) : arrowColor;
  const endColor = delta > 0 ? arrowColor : addOpacityToHexColor(arrowColor, 0);
  const ArcAnimated = animated(Arc);
  const arrowArcRadius = innerArcRadius - 20;

  const [animation] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 400,
      easing: easings.easeOutSine,
    },
    delay: delay,
  }));

  const gradientId = `scoreArrow${delta > 0 ? 'start' : 'end'}`;

  return (
    <>
      <Gradient startColor={startColor} stopColor={endColor} id={gradientId} orientation='horizontal' />
      <ArcAnimated
        startAngle={-Math.PI / 2 + ((score - delta) / 5) * Math.PI}
        endAngle={-Math.PI / 2 + ((score - 0.1 * Math.sign(delta)) / 5) * Math.PI}
        height='100%'
        outerRadius={arrowArcRadius + 3}
        innerRadius={arrowArcRadius}
        fill={`url(#${gradientId})`}
        transform={`translate(${svgHeight},${svgWidth / 2})`}
        opacity={animation.opacity}
      />
      <g
        transform={`translate(${svgHeight},${svgWidth / 2})rotate(-${
          (5 - score / 5) * 180
        } 0 0)translate(${arrowArcRadius},0)`}
      >
        <animated.path
          d='M8.17027 0.28418L0.79984 13.0501L15.5407 13.0501L8.17027 0.28418Z'
          fill={'#ffffff'}
          transform={`rotate(${Math.sign(delta) === 1 ? 180 : 0} 0 0)translate(-8 7)`}
          opacity={animation.opacity}
        />
      </g>
    </>
  );
};
