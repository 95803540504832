import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' {...props}>
    <path
      fill='#fff'
      d='M20.125 12.688c0 4.437-3.031 7.562-7.5 7.562a7.709 7.709 0 0 1-7.75-7.75 7.729 7.729 0 0 1 7.75-7.75c2.063 0 3.844.781 5.188 2.031l-2.125 2.032c-2.75-2.657-7.876-.657-7.876 3.687 0 2.719 2.157 4.906 4.813 4.906 3.063 0 4.219-2.187 4.375-3.343h-4.375v-2.657H20c.063.406.125.781.125 1.281Z'
    />
  </svg>
);
export default SvgGoogle;
