import { useEffect, useRef } from 'react';
import type { SciChartSurface } from 'scichart';
import { StackedMountainCollection, NumberRange } from 'scichart';
import type { ChartDataset, SeriesMetadataMap } from './types';
import { getUnixTimestampInSeconds } from './utils/data-utils';
import {
  createSeries,
  createStackedMountainRenderableSeries,
  createStackedXyDataSeries,
} from './utils/dataseries-utils';
import { isStackedMountainCollection } from './utils/type-guards';
import { ModuloInclusionTickProvider } from './providers/ModuloInclusionTickProvider';
import type { XAxisConfig } from './utils/axes/x-axis.utils';
import type { YAxisConfig } from './utils/axes/y-axis.utils';
import { updateYAxisVisibleRange } from './utils/axes/y-axis.utils';
import { createDataLabelAnnotation, getDatasetDataLabelAnnotationsOptions } from './utils/annotation-utils';

export const useDataSeries = ({
  surfaceRef,
  datasets,
  selectedDataset,
  isInitialized,
  xAxis,
  yAxes,
}: {
  surfaceRef: React.MutableRefObject<SciChartSurface | null>;
  datasets: ChartDataset[];
  selectedDataset: string[];
  isInitialized: boolean;
  xAxis: XAxisConfig;
  yAxes: YAxisConfig[];
}) => {
  const seriesMetadataRef = useRef<SeriesMetadataMap>(new Map());
  const { fullRange } = xAxis;

  useEffect(() => {
    const surface = surfaceRef.current;
    if (!surface || !surface.renderableSeries) return;
    if (!isInitialized) return;
    if (datasets.length === 0) return;

    const ctx = surface.webAssemblyContext2D;

    surface.renderableSeries.clear(true);
    surface.annotations.clear(true);

    seriesMetadataRef.current.clear();

    const dataXValues = datasets[0].data.map((d) => getUnixTimestampInSeconds(d.x));

    // Handle stacked datasets
    const stackedSeries = datasets
      .filter((dataset) => dataset.type === 'stackedLine')
      .map((dataset) => {
        const dataSeries = createStackedXyDataSeries(ctx, dataXValues, dataset);
        return createStackedMountainRenderableSeries(ctx, dataSeries, dataset, seriesMetadataRef);
      });
    if (stackedSeries.length > 0) {
      const collection = new StackedMountainCollection(ctx);
      collection.add(...stackedSeries);
      surface.renderableSeries.add(collection);
    }

    // Handle other types of datasets
    datasets.forEach((dataset) => {
      if (!(dataset.type === 'stackedLine')) {
        const series = createSeries(dataset, ctx, seriesMetadataRef);

        const annotationsOptions = getDatasetDataLabelAnnotationsOptions({ dataset, yAxes: surface.yAxes.asArray() });

        const canvas = surface.domCanvas2D?.getContext('2d');

        for (const annotationOptions of annotationsOptions) {
          if (!canvas) continue;

          const dataLabelAnnotation = createDataLabelAnnotation({ annotationOptions, canvas });
          surface.annotations.add(dataLabelAnnotation);
        }

        // if series is an array
        if (Array.isArray(series)) {
          series.forEach((s) => surface.renderableSeries.add(s));
        } else surface.renderableSeries.add(series);
      }
    });

    const collectionChangedHandler = () => {
      // ! A timeout is used to get the visible range **after** the series have been updated. Might exist a more robust/elegant way to do this.
      setTimeout(() => {
        // * Adjusts the Y axis when data series are added or removed.
        updateYAxisVisibleRange({ surface, yAxesConfig: yAxes });
      }, 500);
    };

    surface.renderableSeries.collectionChanged.subscribe(collectionChangedHandler);

    return () => {
      surface.renderableSeries.collectionChanged.unsubscribe(collectionChangedHandler);
    };
  }, [surfaceRef, isInitialized, datasets, yAxes]);

  useEffect(() => {
    const surface = surfaceRef.current;
    if (!isInitialized || !surface) return;

    const xAxis = surface.xAxes.get(0);

    xAxis.visibleRangeLimit = new NumberRange(
      getUnixTimestampInSeconds(fullRange.min),
      getUnixTimestampInSeconds(fullRange.max),
    );

    if (!(xAxis.tickProvider instanceof ModuloInclusionTickProvider)) {
      xAxis.tickProvider = new ModuloInclusionTickProvider(surface.webAssemblyContext2D);
    }
  }, [surfaceRef, isInitialized, fullRange.min, fullRange.max]);

  useEffect(() => {
    const surface = surfaceRef.current;
    if (!surface) return;
    if (!isInitialized) return;

    const suspender = surface.suspendUpdates();
    const series = surface.renderableSeries.asArray();

    for (const s of series) {
      if (isStackedMountainCollection(s)) {
        for (const stacked of s['items']) {
          stacked.isVisible = selectedDataset.includes(stacked.dataSeries.dataSeriesName);
        }
      } else {
        s.isVisible = selectedDataset.includes(s.dataSeries.dataSeriesName);
      }
    }

    suspender.resume();

    // * Adjusts the Y axis when selected data series change (toggling visibility)
    requestAnimationFrame(() => updateYAxisVisibleRange({ surface, yAxesConfig: yAxes }));
  }, [selectedDataset, surfaceRef, isInitialized, datasets, yAxes]);

  return { seriesMetadataRef };
};
