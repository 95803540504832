import type { Dispatch, MouseEvent, RefObject, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import React, { useCallback, useState, useMemo } from 'react';
import { AnnotationContext } from './AnnotationContext';
import AnnotationMarker from './AnnotationMarker';
import AnnotationHoverLine from './CreateAnnotation/AnnotationHoverLine';
import { CreateAnnotationCard } from './CreateAnnotation/CreateAnnotationCard';
import { useAnnotationEventDispatchers, useAnnotationEventListeners, useOutsideClick } from './hooks';
import { createAnnotationPositions } from './utils';
import './Annotations.css';
import { dateToString } from '../../utils';
import type { Chart } from 'chart.js';
import { ChartKeyContext } from 'aim-utils';

interface AnnotationWrapperProps {
  chartArea: { width: number; height: number; top: number; left: number };
  startDate: string;
  endDate: string;
  newAnnotationXPos: number | undefined;
  setNewAnnotationXPos: Dispatch<SetStateAction<number | undefined>>;
  chartRef: RefObject<Chart>;
}

const AnnotationsWrapper = ({
  chartArea,
  startDate,
  endDate,
  newAnnotationXPos,
  setNewAnnotationXPos,
  chartRef,
}: AnnotationWrapperProps) => {
  const { openedAnnotationId, setOpenedAnnotationId } = useContext(AnnotationContext);
  const chartKey = useContext(ChartKeyContext);

  const { hoveredAnnotationId } = useAnnotationEventListeners();
  const { dispatchHoveredAnnotationId } = useAnnotationEventDispatchers();

  const { width = 0, height = 0, left = 0, top = 0 } = chartArea;
  const chartHasTimeline = startDate?.toString().includes('-') || isNaN(parseInt(startDate));

  const {
    isCreatingAnnotation,
    setIsCreatingAnnotation,
    annotations,
    createAnnotation,
    displayAnnotationSidePanel,
    enableFeature,
    setDisplayAnnotationSidePanel,
  } = useContext(AnnotationContext);
  const [hovered, setHovered] = useState(false);
  const [mouseXPos, setMouseXPos] = useState<number>();

  useEffect(() => {
    // ? Handle updates from outside, e.g. when the user clicks on the chart itself, without the annotation mode being active
    if (displayAnnotationSidePanel === false) {
      setMouseXPos(newAnnotationXPos);

      if (newAnnotationXPos === undefined) {
        setIsCreatingAnnotation(false);
      } else {
        setOpenedAnnotationId(null);
        setIsCreatingAnnotation(true);
      }
    }
  }, [
    displayAnnotationSidePanel,
    setIsCreatingAnnotation,
    setDisplayAnnotationSidePanel,
    setNewAnnotationXPos,
    newAnnotationXPos,
    setOpenedAnnotationId,
  ]);

  const filteredAnnotations = useMemo(
    () =>
      annotations
        .filter((annotation) => annotation.insightId === chartKey)
        .filter((annotation) => {
          const date = new Date(annotation.startDate);
          return date <= new Date(endDate) && date >= new Date(startDate);
        }),
    [annotations, chartKey, endDate, startDate],
  );

  const { annotationMarkerSize, annotationPaddingBottom, mappedAnnotations, timeScale } = useMemo(
    () => createAnnotationPositions(startDate, endDate, width, filteredAnnotations),
    [startDate, endDate, width, filteredAnnotations],
  );

  const onMouseMove = useCallback(
    (e: MouseEvent<SVGRectElement>) => {
      if (!chartRef?.current) return;
      const closestElement = chartRef.current.getElementsAtEventForMode(
        e as unknown as Event,
        'nearest',
        { intersect: false, axis: 'x' },
        true,
      );
      const nearestX = closestElement[0]?.element.x ?? 0;
      !isCreatingAnnotation && setMouseXPos(nearestX - left);
    },
    [isCreatingAnnotation, chartRef, left],
  );

  const onMouseOver = useCallback(() => {
    setHovered(true);
  }, []);

  const onMouseOut = useCallback(() => {
    setHovered(false);
  }, []);

  const onMouseClick = useCallback(() => {
    if (openedAnnotationId === null) {
      setNewAnnotationXPos(isCreatingAnnotation ? undefined : mouseXPos);
      setIsCreatingAnnotation(!isCreatingAnnotation);
    } else {
      setOpenedAnnotationId(null);
      setNewAnnotationXPos(undefined);
      setIsCreatingAnnotation(false);
    }
  }, [
    setOpenedAnnotationId,
    isCreatingAnnotation,
    mouseXPos,
    openedAnnotationId,
    setIsCreatingAnnotation,
    setNewAnnotationXPos,
  ]);

  const handleClickOutside = useCallback(() => {
    setNewAnnotationXPos(undefined);
    setHovered(false);
    setIsCreatingAnnotation(false);
  }, [setIsCreatingAnnotation, setNewAnnotationXPos]);

  const ref = useOutsideClick(handleClickOutside);

  const addNewAnnotations = async (message: string, mentionedUserIds: string[]) => {
    const newAnnotation = {
      insightId: chartKey,
      annotationFilters: [],
      content: message,
      mentionedUserIds: mentionedUserIds,
      startDate: dateToString(timeScale.invert(newAnnotationXPos ?? 0)),
    };
    const createdAnnotation = await createAnnotation(newAnnotation);
    createdAnnotation && setOpenedAnnotationId(createdAnnotation.id);
    setDisplayAnnotationSidePanel(true);
    setNewAnnotationXPos(undefined);
    setIsCreatingAnnotation(false);
  };

  const displayCreateAnnotationHoverLine =
    isCreatingAnnotation || (hovered && !openedAnnotationId && displayAnnotationSidePanel);

  const annotationMarkerHeight = height + annotationPaddingBottom ?? 0;

  return (
    <>
      {enableFeature && chartHasTimeline && (
        <>
          <svg
            className='annotationsSvgWrapper'
            width={width + annotationMarkerSize.width}
            height={height + annotationPaddingBottom * 2}
            transform={`translate(${left - annotationMarkerSize.width / 2},${top})`}
            pointerEvents='none'
            ref={ref}
          >
            <g>
              {(displayAnnotationSidePanel || isCreatingAnnotation) && (
                <rect
                  width={width ? width : 0}
                  height={height ? height + annotationPaddingBottom * 2 : 0}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  onMouseMove={onMouseMove}
                  onClick={onMouseClick}
                  opacity='0'
                  pointerEvents='all'
                  data-testid='annotations-wrapper'
                />
              )}

              <g transform={`translate(0,${annotationMarkerHeight})`}>
                {mappedAnnotations
                  .filter((annotation) => !isNaN(annotation.leftOffset ?? 0))
                  .sort((annotation) => (annotation.id === hoveredAnnotationId ? 1 : -1))
                  .map((annotation) => (
                    <AnnotationMarker
                      key={annotation.id}
                      annotation={annotation}
                      height={annotationMarkerHeight}
                      width={width}
                      annotationMarkerSize={annotationMarkerSize}
                      openAnnotationId={openedAnnotationId}
                      onHover={dispatchHoveredAnnotationId}
                      setDisplayAnnotationSidePanel={setDisplayAnnotationSidePanel}
                      setOpenedAnnotationId={setOpenedAnnotationId}
                    />
                  ))}
              </g>
              {displayCreateAnnotationHoverLine && (
                <AnnotationHoverLine height={height} hoverXPos={(mouseXPos ?? 0) + annotationMarkerSize.width / 2} />
              )}
            </g>
          </svg>
          {newAnnotationXPos !== undefined && isCreatingAnnotation && (
            <CreateAnnotationCard width={width} xPos={newAnnotationXPos} onSend={addNewAnnotations} />
          )}
        </>
      )}
    </>
  );
};
export default AnnotationsWrapper;
