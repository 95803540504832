import React from 'react';
import { getRootCssVariable } from '../../../utils/utils';

interface AnimatedBubbleProps {
  fill: string;
  stroke?: string;
  r: number;
  text?: string;
  className?: string;
  width?: number;
  height?: number;
}

const D3BubbleChartLegend = ({ fill, stroke, r, text, className, width, height }: AnimatedBubbleProps) => {
  return (
    <g>
      {text && (
        <foreignObject
          width={width ?? 100}
          height={height ?? 100}
          transform={`translate(${r * 2}, ${-(height ?? r) / 5})`}
        >
          <div
            style={{
              fontSize: getRootCssVariable('--aim-font-size-text2'),
              textAnchor: 'middle',
              fill: 'currentColor',
              opacity: '0.6',
            }}
          >
            {text}
          </div>
        </foreignObject>
      )}

      <circle r={r} fill={fill} data-testid='circle' stroke={stroke} strokeWidth={1} className={className} />
    </g>
  );
};

export default D3BubbleChartLegend;
