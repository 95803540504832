import type { ComponentProps, ReactElement, ReactNode } from 'react';
import { Icon, type IconName } from '../Icon';
import styles from './Chip.module.css';
import { Typography } from '../Typography';

type ChipProps = {
  selected?: boolean;
  icon?: IconName | ReactElement;
  children: ReactNode;
} & ComponentProps<'button'>;

export const Chip = ({ selected, icon, onClick, children, ...props }: ChipProps) => {
  const clickable = typeof onClick === 'function' && !props.disabled;

  return (
    <button
      className={styles.chip}
      aria-selected={selected === true}
      {...props}
      data-clickable={clickable}
      onClick={clickable ? onClick : undefined}
    >
      {typeof children === 'string' ? (
        <Typography variant='text1' className={styles.label} truncate>
          {children}
        </Typography>
      ) : (
        children
      )}

      {icon && <div className={styles.icon}>{typeof icon === 'string' ? <Icon icon={icon} /> : icon}</div>}
    </button>
  );
};
