import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './AnnotationSidePanel.module.css';
import type { Annotation } from 'aim-components';
import { AnnotationContext, AnnotationInput, Badge, Icon, SidePanel, Spinner, Typography } from 'aim-components';
import { AnnotationSidePanelListItem } from '../AnnotationSidePanelItems/AnnotationSidePanelListItem';
import { AnnotationSidePanelThreadItem } from '../AnnotationSidePanelItems/AnnotationSidePanelThreadItem';
import { useTranslation } from 'next-i18next';

interface AnnotationSidePanelProps {
  isOpened: boolean;
}

export const AnnotationSidePanel = ({ isOpened }: AnnotationSidePanelProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'annotations' });
  const {
    annotations,
    isLoadingAnnotations,
    createAnnotationReply,
    archiveAnnotation,
    setOpenedAnnotationId,
    openedAnnotationId,
    setDisplayAnnotationSidePanel,
    isAdmin,
  } = useContext(AnnotationContext);
  const lastReplyRef = useRef<HTMLDivElement>(null);
  const replyRef = useRef<{ message: string; mentionedIds: string[] } | null>(null);

  const [isSendingReply, setIsSendingReply] = useState(false);
  const isAwaitingScrollToBottom = useRef(false);

  const openedAnnotation = annotations.find(({ id }) => id === openedAnnotationId);
  const replies = openedAnnotation?.replies ?? [];

  const annotationsToShow = openedAnnotation ? [openedAnnotation, ...replies] : annotations;

  const sendReply = useCallback(async () => {
    if (replyRef.current && openedAnnotationId !== null) {
      setIsSendingReply(true);
      isAwaitingScrollToBottom.current = true;

      const { message, mentionedIds } = replyRef.current;

      await createAnnotationReply({
        annotationId: openedAnnotationId,
        content: message,
        mentionedUserIds: mentionedIds,
      });

      setIsSendingReply(false);
      replyRef.current = null;
    }
  }, [createAnnotationReply, openedAnnotationId]);

  useEffect(() => {
    if (isAwaitingScrollToBottom.current && lastReplyRef.current) {
      lastReplyRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
      isAwaitingScrollToBottom.current = false;
    }
  }, [annotations]);

  const onChange = useCallback((message: string, mentionedIds: Array<string>) => {
    replyRef.current = { message, mentionedIds };
  }, []);

  const onClose = useCallback(() => {
    setOpenedAnnotationId(null);
    setDisplayAnnotationSidePanel(false);
  }, [setDisplayAnnotationSidePanel, setOpenedAnnotationId]);

  return (
    <>
      <SidePanel
        onClose={onClose}
        isOpened={isOpened}
        header={
          openedAnnotationId === null ? (
            <SidePanel.Header
              onClose={onClose}
              title={
                <SidePanel.Title
                  icon='annotation'
                  title='Annotations'
                  badge={isAdmin && <Badge type='neutral'> {t('admin')}</Badge>}
                />
              }
            />
          ) : (
            <SidePanel.Header
              onClose={onClose}
              title={
                <div className={styles.annotationSidePanelBackButton} onClick={() => setOpenedAnnotationId(null)}>
                  <Icon icon='nav-back-chevron' />
                  <Typography variant='text1' color='secondary'>
                    {t('back')}
                  </Typography>
                </div>
              }
            />
          )
        }
      >
        <div className={styles.annotationSidePanelContentContainer}>
          <div className={styles.annotationSidePanelScrollableContent} data-is-thread={openedAnnotationId !== null}>
            {annotationsToShow.length === 0 && (
              <>
                {isLoadingAnnotations ? (
                  <div className={styles.spinnerContainer}>
                    <Spinner />
                  </div>
                ) : (
                  <div className={styles.noAnnotationsPlaceholder}>
                    <Typography variant='text2'>{t('noAnnotationsPlaceholder')}</Typography>
                  </div>
                )}
              </>
            )}
            {(annotationsToShow as Annotation[]).map(
              ({ id, arkUserId, author, updatedAt, contentParts, replies: annotationReplies }, index) => (
                <div key={id} ref={index === annotationsToShow.length - 1 ? lastReplyRef : null}>
                  {!openedAnnotationId ? (
                    <AnnotationSidePanelListItem
                      author={author}
                      arkUserId={arkUserId}
                      date={new Date(updatedAt)}
                      contentParts={contentParts}
                      numberOfReplies={id === openedAnnotationId ? 0 : (annotationReplies?.length ?? 0)}
                      onClick={() => {
                        setOpenedAnnotationId(id);
                      }}
                      actions={{
                        onArchive: () => archiveAnnotation({ id, replyId: undefined }),
                      }}
                    />
                  ) : (
                    <AnnotationSidePanelThreadItem
                      author={author}
                      arkUserId={arkUserId}
                      date={new Date(updatedAt)}
                      contentParts={contentParts}
                      numberOfReplies={id === openedAnnotationId ? 0 : (annotationReplies?.length ?? 0)}
                      actions={{
                        onArchive: () =>
                          archiveAnnotation({
                            id: openedAnnotationId,
                            replyId: id === openedAnnotationId ? undefined : id,
                          }),
                      }}
                    />
                  )}
                  {id === openedAnnotationId && (
                    <div className={styles.numberOfRepliesDivider}>
                      <span>
                        {replies.length} {replies.length === 1 ? 'reply' : 'replies'}
                      </span>
                    </div>
                  )}
                </div>
              ),
            )}
          </div>

          {openedAnnotationId !== null && (
            <div className={cn(styles.annotationSidePanelFooter, { opened: true })}>
              <AnnotationInput onSend={sendReply} onChange={onChange} placeholder='Reply' isLoading={isSendingReply} />
            </div>
          )}
        </div>
      </SidePanel>
    </>
  );
};
