import type { DateString, TimeGranularity } from './types';

import {
  differenceInDays,
  differenceInMinutes,
  differenceInYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  format,
  formatISO,
  isValid,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';

import { enUS } from 'date-fns/locale';

export const formatters = {
  standard: new Intl.DateTimeFormat('en-IN'),
  dateTime: new Intl.DateTimeFormat('en-IN', { timeStyle: 'short', dateStyle: 'short' }),
};

// Dublicated functionality from aim-components // TODO: Remove it from aim-componets
export const dateToString = (date: Date) => {
  const validatedDate = isValid(date) ? date : 0;
  return formatISO(validatedDate, { representation: 'date' }) as DateString;
};

export const getDateLabels = (
  granularity: TimeGranularity,
  min: string | Date | number,
  max: Date | string | number,
) => {
  const labels = getDateInterval(min, max, granularity).map((label) => label.toISOString());
  return granularity === 'days' && labels.at(-1) !== max ? labels.concat([new Date(max).toISOString()]) : labels;
};

export const numberOfDaysBetweenDates = (endDate: Date, startDate: Date): number => {
  return differenceInDays(endDate, startDate);
};

export const isValidDate = (date: Date): boolean => {
  return isValid(date);
};

export const getDateInterval = (
  startDate: Date | string | number,
  endDate: Date | string | number,
  timeGranularity: TimeGranularity,
): Date[] => {
  const start = startDate instanceof Date ? startDate : new Date(startDate);
  const end = endDate instanceof Date ? endDate : new Date(endDate);

  const adjustedStart = new Date(start.getTime() + start.getTimezoneOffset() * 60000);
  const adjustedEnd = new Date(end.getTime() + end.getTimezoneOffset() * 60000);

  if (!isValidDate(start) || !isValidDate(end) || numberOfDaysBetweenDates(start, end) === 0) return [];

  const timeGranularityIntervalFunctionMap = {
    years: eachYearOfInterval,
    quarters: eachQuarterOfInterval,
    months: eachMonthOfInterval,
    weeks: eachWeekOfInterval,
    days: eachDayOfInterval,
  } as const satisfies Record<TimeGranularity, unknown>;

  const timeGranularityMatureDateFunctionMap = {
    years: subYears,
    quarters: subQuarters,
    months: subMonths,
    weeks: subWeeks,
    days: subDays,
  } as const satisfies Record<TimeGranularity, unknown>;

  if (timeGranularity === 'days') {
    const maxNumberOfTics = 300;
    const stepSize = Math.max(
      Math.round(numberOfDaysBetweenDates(adjustedEnd, adjustedStart) / maxNumberOfTics / 10) * 10,
      1,
    ); // Round to nears ten but use 1 instead of 0
    return eachDayOfInterval(
      {
        start: adjustedStart,
        end: subDays(adjustedEnd, 0),
      },
      { step: stepSize },
    ).map((date) => new Date(date.getTime() - date.getTimezoneOffset() * 60000));
  }

  if (timeGranularity === 'weeks') {
    return eachWeekOfInterval(
      {
        start: adjustedStart,
        end: subWeeks(adjustedEnd, 0),
      },
      { weekStartsOn: 1 },
    ).map((date) => new Date(date.getTime() - date.getTimezoneOffset() * 60000));
  }

  return timeGranularityIntervalFunctionMap[timeGranularity]({
    start: adjustedStart,
    end: timeGranularityMatureDateFunctionMap[timeGranularity](adjustedEnd, 0),
  }).map((date) => new Date(date.getTime() - date.getTimezoneOffset() * 60000));
};

export const formatRelativeDate = (date1: Date, date2: Date) => {
  if (!isValid(date1)) return '';

  if (differenceInMinutes(date2, date1) < 2) {
    return 'Just now';
  }

  if (differenceInMinutes(date2, date1) < 60) {
    return `${differenceInMinutes(date2, date1)} min`;
  }

  if (differenceInDays(date2, date1) === 0) {
    return format(date1, 'h:mm a');
  }

  if (differenceInDays(date2, date1) === 1) {
    return 'Yesterday';
  }

  if (differenceInYears(date2, date1) === 0) {
    return format(date1, 'MMM d');
  }

  return format(date1, 'd MMM yyyy');
};

const timeGranularityDateFormatMap = {
  years: 'yyyy',
  quarters: 'qqq yyyy',
  months: 'LLL yyyy',
  weeks: 'dd LLL yyyy',
  days: 'dd LLL yyyy',
} as const satisfies Record<TimeGranularity, string>;

export const formatDate = (date: Date | number, timeGranularity: TimeGranularity) => {
  const dateToFormat = date instanceof Date ? date : new Date(date);
  const formatTokens = timeGranularityDateFormatMap[timeGranularity];
  return format(dateToFormat, formatTokens, { locale: enUS });
};
