import { Button, ContextMenu, HoverTooltip, Icon, ModalOverlay, Spinner, Typography } from 'aim-components';
import type { KeyboardEvent } from 'react';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import styles from './CommandPalette.module.css';
import { ClearUserSettings } from '@/components/settings/AdminComponents/ClearUserSettings';
import { JoinDealChannel } from '@/components/settings/AdminComponents/JoinDealChannel';
import RefreshDataCache from '@/components/settings/AdminComponents/RefreshDataCache';
import { useNavigationContext } from '@/components/layouts/navigation/navigation-menu/NavigationContext';
import {
  useSwitchTenant,
  useTenantSearch,
  useTenants,
} from '@/components/layouts/navigation/navigation-menu/tenant-selector/hooks';
import { TenantListItem } from '@/components/layouts/navigation/navigation-menu/tenant-selector/TenantListItem';
import { TenantSearchNoMatches } from '@/components/layouts/navigation/navigation-menu/tenant-selector/TenantSearch';
import { TenantContext } from '@/components/tenant/context';
import { UserContext } from '@/components/authentication/UserContext';
import {
  useApplicationKeybindings,
  useCopyStringToClipboard,
  useKeybindingCommand,
  useSessionDebugInformationMessage,
} from '@/components/common/command-palette/hooks';
import { Trans, useTranslation } from 'next-i18next';
import { MemoryDebuggerToggle } from '@/components/settings/AdminComponents/MemoryDebuggerToggle';

const LoadingRow = ({ label, loading }: { label: string; loading: boolean }) => {
  return (
    <li>
      {loading ? <Spinner /> : <Icon icon='success-circle' className={styles.successIcon} />}
      <Typography variant='text2' color='secondary'>
        {label}
      </Typography>
    </li>
  );
};

export const CommandPalette = () => {
  const { t } = useTranslation('common', { keyPrefix: 'commandPalette' });
  const [isOpen, setIsOpen] = useState(false);
  const { toggleNavigationMenu } = useNavigationContext();
  const { selectableTenants, isLoading: isLoadingListOfTenants } = useTenants();
  const { switchTenant } = useSwitchTenant();
  const { projectCode } = useContext(TenantContext);
  const { tenant } = useContext(UserContext);

  const { keybindingMap } = useApplicationKeybindings();
  const keybindings = useMemo(() => Object.values(keybindingMap), [keybindingMap]);

  const { searchString, onSearchChange, filteredTenants, noMatchingResults, clearSearch } = useTenantSearch({
    tenants: selectableTenants,
  });

  const { copyDebugMessageToClipboard } = useSessionDebugInformationMessage({ projectCode });

  const toggleCommandPalette = useCallback(() => {
    setIsOpen((prev) => !prev);
    clearSearch();
  }, [clearSearch]);

  useKeybindingCommand('toggle-command-palette', toggleCommandPalette);
  useKeybindingCommand('toggle-navigation-menu', toggleNavigationMenu);

  const closeCommandPalette = useCallback(() => setIsOpen(false), []);

  const listItemRef = useRef<Array<HTMLLIElement | null>>([]);

  const handleSearchKeyDown = useCallback(
    async (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === 'Escape') {
        closeCommandPalette();
      }

      if (e.key === 'Enter' && filteredTenants.length === 1) {
        closeCommandPalette();
        await switchTenant(filteredTenants[0]);
      }

      if (['ArrowDown', 'ArrowRight', 'Enter'].includes(e.key)) {
        const [firstTenantRow] = listItemRef.current;
        if (firstTenantRow) {
          e.preventDefault();
          firstTenantRow.focus();
        }
      }
    },
    [closeCommandPalette, filteredTenants, switchTenant],
  );

  const { copyStringToClipboard } = useCopyStringToClipboard();

  if (isLoadingListOfTenants || !projectCode || !tenant) {
    return (
      <ModalOverlay open={isOpen} onClose={closeCommandPalette}>
        <div className={styles.loadingContainer}>
          <div className={styles.emoji}>🦄</div>
          <Typography variant='text2'>{t('loadingMessage')}</Typography>

          <ul className={styles.loadingList}>
            <LoadingRow label={t('loadingList.listOfTenants')} loading={isLoadingListOfTenants} />
            <LoadingRow label={t('loadingList.projectCode')} loading={!projectCode} />
            <LoadingRow label={t('loadingList.tenantInformation')} loading={!tenant} />
          </ul>
        </div>
      </ModalOverlay>
    );
  }

  return (
    <ModalOverlay open={isOpen} onClose={closeCommandPalette}>
      <div className={styles.commandPalette}>
        <section className={styles.grid}>
          <section className={styles.mainSection}>
            <div className={styles.header}>
              <Typography variant='label2' className={styles.title}>
                {t('title')}
              </Typography>

              <div className={styles.tenantInfo}>
                <HoverTooltip message={t('copyAction.copyToClipboard', { text: tenant })} position='top'>
                  <Typography variant='text1' color='secondary' onClick={copyStringToClipboard(tenant)}>
                    {tenant}
                  </Typography>
                </HoverTooltip>
                {projectCode && (
                  <HoverTooltip message={t('copyAction.copyToClipboard', { text: projectCode })} position='top'>
                    <Typography variant='text1' color='secondary' onClick={copyStringToClipboard(projectCode)}>
                      {projectCode}
                    </Typography>
                  </HoverTooltip>
                )}
              </div>
            </div>

            <div className={styles.debugAction}>
              <HoverTooltip message={t('sessionDebug.tooltip')} position='bottom'>
                <Button onClick={copyDebugMessageToClipboard}>{t('sessionDebug.copyAction')}</Button>
              </HoverTooltip>
            </div>

            <section className={styles.shortCuts}>
              <Typography variant='label3'>{t('keyboardShortcuts')}</Typography>
              {keybindings.map(({ name, keys }) => {
                return (
                  <div className={styles.shortCutItem} key={name}>
                    <Typography variant='text2' color='secondary'>
                      {name}
                    </Typography>
                    <Typography variant='text2' className={styles.shortcutKeys}>
                      {keys.map((key) => {
                        return <kbd key={key}>{key}</kbd>;
                      })}
                    </Typography>
                  </div>
                );
              })}
            </section>

            <section className={styles.actions}>
              <Typography variant='label3'>{t('adminActions')}</Typography>
              <RefreshDataCache />
              <ClearUserSettings />
              <JoinDealChannel />

              {process.env.NODE_ENV === 'development' && <MemoryDebuggerToggle />}
            </section>
          </section>

          {selectableTenants.length > 1 && (
            <section className={styles.tenantSection}>
              <ContextMenu.Search
                value={searchString}
                onChange={onSearchChange}
                onKeyDown={handleSearchKeyDown}
                placeholder={t('searchForTenant')}
                autoFocus
              />
              <ContextMenu.List className={styles.tenantList}>
                {filteredTenants.map((tenant, index) => (
                  <TenantListItem
                    key={tenant}
                    tenant={tenant}
                    highlight={filteredTenants.length === 1}
                    onClick={closeCommandPalette}
                    ref={(ref) => {
                      listItemRef.current[index] = ref;
                    }}
                  />
                ))}
                {noMatchingResults && <TenantSearchNoMatches />}
              </ContextMenu.List>
              {filteredTenants.length === 1 && (
                <Typography variant='text2' className={styles.tenantSearchKeyHint}>
                  <Trans t={t} i18nKey='pressEnterToSwitchTenant'>
                    <kbd />
                  </Trans>
                </Typography>
              )}
            </section>
          )}
        </section>
      </div>
    </ModalOverlay>
  );
};
