import { Routes } from '@/lib/routes';

export const isActiveRoute = ({ route, currentPathname }: { route: Routes; currentPathname: string }) => {
  switch (route) {
    case Routes.Home: {
      // * The home route is special, as it's included in all other routes
      return currentPathname === Routes.Home;
    }
    default: {
      return currentPathname.includes(route);
    }
  }
};
