import type { BubbleDataPoint, Chart as ChartJS, Point } from 'chart.js';
import { type ChartTypeRegistry, type PointPrefixedHoverOptions, type PointPrefixedOptions } from 'chart.js';
import type { UnionToIntersection } from 'chart.js/dist/types/utils';
import type { Options as DataLabelOptions } from 'chartjs-plugin-datalabels/types/options';
import { formatValue } from '../../utils';
import type { ChartDataset } from './types';
import { chartColorData, computeStack, createGradient, createGradientBarColor } from './utils';
import { getBackgroundColor, getDataLength, getScatterPointColors } from './utils';
import { LineColors } from 'aim-utils';
export const CHART_TYPES = ['line', 'bar', 'scatter'] as const;
export type ChartType = (typeof CHART_TYPES)[number];

export type DatasetConfig<DatasetChartType extends ChartType = ChartType> = Partial<
  Readonly<ChartTypeRegistry[DatasetChartType]['datasetOptions'] & { datalabels: DataLabelOptions }>
>;

/**
 * 🔘 Point configuration for **line** and **scatter** charts.
 * @see https://www.chartjs.org/docs/latest/configuration/elements.html#point-configuration
 */
const getPointData = (
  dataset: ChartDataset,
  isHovered = true,
): Readonly<Partial<PointPrefixedOptions & PointPrefixedHoverOptions>> => ({
  pointBackgroundColor: dataset.forecastColor ?? dataset.color,
  //Typescript don't think pointRadius can be an array but it can
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore

  pointRadius: getPointRadius(dataset),
  pointHoverRadius: isHovered ? 4 : 2,
  pointHoverBackgroundColor: dataset.forecastColor ?? dataset.color,
  pointHoverBorderColor: chartColorData.pointBackground,
});

const nullOrUndefined = (value: unknown) => {
  return value === null || value === undefined;
};

const getPointRadius = (dataset: ChartDataset) => {
  const pointArray = dataset.data.map((point, i) => {
    const isAPoint = point.y && nullOrUndefined(dataset.data[i - 1]?.y) && nullOrUndefined(dataset.data[i + 1]?.y);
    const isADataLabel = dataset.dataLabels?.find((dataLabel) => {
      const index = typeof dataLabel === 'number' ? dataLabel : dataLabel.index;
      return index === i;
    });
    return isAPoint || isADataLabel ? 3 : 0;
  });

  return pointArray;
};

/**
 * 🙈 Configuration used to **hide points on hover**.
 */
export const hideHoverPoint = {
  pointHoverBackgroundColor: 'transparent',
  pointHoverBorderColor: 'transparent',
  pointHoverRadius: 0,
} as const satisfies Partial<PointPrefixedHoverOptions>;

/**
 * ⬛️ Configuration for data that is grayed out, such as **reference lines**.
 */
export const greyedOutData = {
  ...hideHoverPoint,
  borderColor: chartColorData.referenceLine,
  order: 1000, // placed in the back (similar to z-index 0)
  borderWidth: 1,
  fill: false,
} as const satisfies DatasetConfig;

/**
 * 💬 Data label configuration, for `chartjs-plugin-datalabels`.
 * @see https://chartjs-plugin-datalabels.netlify.app/guide/options.html
 */
const baseDataLabels = {
  borderColor: chartColorData.lineDatalabelText,
  backgroundColor: chartColorData.lineDatalabelBackground,
  anchor: 'start',
  clamp: true,
  offset: 10,
  padding: { top: 2, bottom: 2, left: 8, right: 8 },
  borderRadius: 4,
  color: chartColorData.lineDatalabelText,
  opacity: 0.9,
} as const satisfies DataLabelOptions;

const getDataLabelDisplayValueAtDataIndex = (dataset: ChartDataset, dataIndex: number) => {
  if (!dataset.dataLabels) return false;

  const dataLabelIndices = dataset.dataLabels.map((dataLabel) =>
    typeof dataLabel === 'number' ? dataLabel : dataLabel.index,
  );

  if (!dataLabelIndices.includes(dataIndex)) return false;

  const maxDataLabelIndex = Math.max(...dataLabelIndices);
  const isLastDataLabel = maxDataLabelIndex === dataIndex;
  const isLastDataPoint = dataIndex === dataset.data.length - 1;

  if (isLastDataLabel && isLastDataPoint) return true;

  return 'auto';
};

/**
 * 👑 Base dataset configuration, shared by all chart types.
 */
export const getCommonChartDatasetConfig = (
  dataset: ChartDataset,
  isDatasetHovered: boolean,
  chartRef?: ChartJS | null,
  unit?: string,
): UnionToIntersection<DatasetConfig> => ({
  borderColor: dataset.color,
  borderWidth: 0,
  pointStyle: 'circle',
  order: getDataLength(dataset.data), //shortest line in the front (similar to z-index)
  backgroundColor: getBackgroundColor(isDatasetHovered, dataset, chartRef),
  xAxisID: dataset.xAxisId ?? 'x',
  yAxisID: dataset.yAxisId ?? 'y',
  datalabels: {
    ...baseDataLabels,
    display: (context) => getDataLabelDisplayValueAtDataIndex(dataset, context.dataIndex),
    formatter: (value, ctx) => {
      const datasetUnit = dataset.datasetUnit !== undefined ? dataset.datasetUnit : unit;

      if (dataset.dataLabels) {
        const dataLabel = dataset.dataLabels.find((dataLabel) => {
          const index = typeof dataLabel === 'number' ? dataLabel : dataLabel.index;
          return index === ctx.dataIndex;
        });

        if (!dataLabel) return null;

        if (typeof dataLabel === 'number') {
          if (dataset.isStacked) {
            const stacks: Array<Record<any, number>> = Object.values((ctx.chart as any)['_stacks']['x.y.line']);
            if (!stacks) return null;
            const stack = stacks.find((stack) => stack._top === ctx.datasetIndex);
            if (!stack) return null;

            const stackSum = Object.values(stack)
              .slice(0, -3)
              .reduce((acc, curr) => acc + curr, 0);

            return formatValue(stackSum, datasetUnit);
          }

          return formatValue(value.y, datasetUnit);
        }

        return dataLabel.label ?? formatValue(value.y, datasetUnit);
      }

      return formatValue(value.y, datasetUnit);
    },
  },
});

/**
 * 🔵 Dataset configuration for **scatter** charts.
 * The scatter chart uses the same dataset properties as the _line_ chart.
 * @see https://www.chartjs.org/docs/latest/charts/line.html#dataset-properties
 */
export const getScatterChartDatasetConfig = (dataset: ChartDataset): DatasetConfig<'scatter'> => ({
  ...getPointData(dataset),
  pointBackgroundColor: getScatterPointColors(dataset),
  pointRadius: 4,
});

/**
 * 📊 Dataset configuration for **bar** charts.
 * @see https://www.chartjs.org/docs/latest/charts/bar.html#dataset-properties
 */

export const getBarChartDatasetConfig = (
  dataset: ChartDataset,
  unit?: string,
  isStacked?: boolean,
  chartRef?: ChartJS | null,
): DatasetConfig<'bar'> => {
  const shadedFill = (() => {
    if (!dataset.shadedFill || !chartRef || chartRef.scales.y === undefined) return;

    const yScale = chartRef.scales[dataset.yAxisId ?? 'y'];
    const max = yScale?.max;

    return dataset.data.map(({ y }) => {
      const top = (1 - (y ?? 0) / max) * chartRef.chartArea.height;
      return createGradientBarColor(
        !Number.isNaN(top) && max !== 0 ? top : 0,
        chartRef.chartArea.height,
        chartRef?.ctx,
        dataset.color,
      );
    }) as any;
  })();

  const dataWithinBounds = (() => {
    if (!chartRef || !chartRef.scales.x) return dataset.data;

    const { min, max } = chartRef.scales.x;
    const dataWithinBounds = dataset.data.filter((data) => {
      if (data !== null && typeof data === 'object' && 'x' in data) {
        const x = typeof data.x === 'number' ? data.x : new Date(data.x).getTime();
        return x >= min && x <= max;
      }
      return true;
    });

    return dataWithinBounds;
  })();

  return {
    stack: 'bar',
    xAxisID: dataset.fill === false ? 'x1' : 'x',
    backgroundColor() {
      if (dataset.fill === false) return '#00000000';

      if (dataset.shadedFill && shadedFill) {
        return shadedFill;
      }

      return dataset.color;
    },
    hoverBackgroundColor: dataset.shadedFill ? dataset.color : undefined,
    datalabels: {
      display: (ctx) => {
        if (dataset.type !== 'bar') return false;

        const displayValue = getDataLabelDisplayValueAtDataIndex(dataset, ctx.dataIndex);
        if (displayValue !== false) return displayValue;

        if (isStacked) return false;
        if (ctx.chart.scales.x.type !== 'time') return false;

        // * If the chart is too narrow, don't display data labels. Prevents overlapping and cluttering.
        const MIN_CHART_WIDTH_TO_DISPLAY_BAR_CHART_DATA_LABELS = 650;

        // * If there are too many bars, don't display data labels. Prevents overlapping and cluttering.
        const MAX_NUMBER_OF_BARS_TO_DISPLAY_DATA_LABELS = 15;

        if (ctx.chart.width < MIN_CHART_WIDTH_TO_DISPLAY_BAR_CHART_DATA_LABELS) return false;

        return dataWithinBounds.length < MAX_NUMBER_OF_BARS_TO_DISPLAY_DATA_LABELS;
      },
      ...baseDataLabels,
      anchor: 'end',
      align: 'end',
      color: chartColorData.lineDatalabelText,
      offset: 4,
      formatter: (value: { y: number; x: number }, ctx) => {
        if (dataset.isStacked) {
          return computeStack(ctx, dataset.datasetUnit ?? unit);
        }

        if (dataset.dataLabels) {
          const dataLabel = dataset.dataLabels.find((dataLabel) => {
            const index = typeof dataLabel === 'number' ? dataLabel : dataLabel.index;
            return index === ctx.dataIndex;
          });

          if (!dataLabel) return null;

          if (typeof dataLabel === 'number') {
            const label = formatValue(value.y, dataset.datasetUnit ?? unit);

            if (ctx.chart.scales.x.type !== 'time') return label;

            const { max } = ctx.chart.scales.x;
            const data = ctx.chart.data.datasets[ctx.datasetIndex].data;

            const firstIndexOutsideBounds = data.findIndex((point) => {
              if (point !== null && typeof point === 'object' && 'x' in point) {
                const x = typeof point.x === 'number' ? point.x : new Date(point.x).getTime();
                return x > max;
              }

              return false;
            });

            if (firstIndexOutsideBounds !== -1 && ctx.dataIndex > firstIndexOutsideBounds) return '';
            if (ctx.dataIndex < firstIndexOutsideBounds) return label;

            // * If the label is the first label outside of the X axis max bound, pad it with trailing spaces to align to move it to the left, so the label is not cut off.
            // * chartjs-plugin-datalabels seems to have pretty limited options for aligning labels, so this is a workaround.
            const x = typeof value.x === 'number' ? value.x : new Date(value.x).getTime();
            const textWidth = ctx.chart.ctx.measureText(label).width;
            const chartWidth = ctx.chart.width;
            const xPositionBar = ctx.chart.scales.x.getPixelForValue(x);

            // * By how much distance the label is outside of the chart bounds
            const distanceOutsideBounds = Math.max(0, xPositionBar + textWidth - chartWidth);

            // * Calculate the width of a single whitespace character, to know how many of them to add to the end of the label.
            const whiteSpaceWidth = ctx.chart.ctx.measureText(' ').width;
            const numberOfWhiteSpacesToCompensate = Math.round(distanceOutsideBounds / whiteSpaceWidth);

            return label + ' '.repeat(numberOfWhiteSpacesToCompensate);
          }

          return dataLabel.label ?? formatValue(value.y, dataset.datasetUnit ?? unit);
        }
      },
    },
  };
};

/**
 * 📈 Dataset configuration for **line** charts.
 * @see https://www.chartjs.org/docs/latest/charts/line.html#dataset-properties
 */
export const getLineChartDatasetConfig = (dataset: ChartDataset, isDatasetHovered: boolean): DatasetConfig<'line'> => ({
  ...getPointData(dataset, isDatasetHovered),
  borderWidth: isDatasetHovered && !dataset.isReferenceLine ? 2.5 : 1.5,
  borderDash: (dataset.borderDash ?? dataset.isDotted) ? [0, 6] : [0, 0],
  borderCapStyle: 'round',
  cubicInterpolationMode: 'monotone',
});

/**
 * 🎨 Returns configuration for **filling areas** in a line (area) chart.
 */
export const getFillData = ({
  type,
  fill,
  shadedFill,
  chartRef,
  fillColor,
  color,
  isDatasetHovered,
  highlightNegativeValues,
  highlightOnHover,
  yAxisId,
  yMax,
}: ChartDataset & {
  chartRef: ChartJS<
    keyof ChartTypeRegistry,
    (number | Point | [number, number] | BubbleDataPoint | null)[],
    unknown
  > | null;
  isDatasetHovered: boolean | undefined;
  yMax: number | undefined;
}): DatasetConfig<'line'> | undefined => {
  if (type !== 'line' || !chartRef) return;

  const isHoveredForecastedDataset = isDatasetHovered === true && highlightOnHover;
  const fillTarget =
    (shadedFill && typeof fill === 'number') || fill === true || (isDatasetHovered && highlightOnHover)
      ? { value: 0 }
      : (fill ?? false);

  const yScale = chartRef.scales[yAxisId ?? 'y'];
  const dataExtent = yScale ? [yScale.min, yScale.max] : undefined;

  if (highlightNegativeValues) {
    return {
      fill: {
        target: { value: 0 },
        above: '#00000000',
        below: createGradient(
          chartRef.ctx,
          LineColors['vivid-red'],
          'vertical',
          true,
          dataExtent,
          chartRef.chartArea.height,
        ),
      },
    };
  }

  return {
    fill:
      (typeof fill === 'number' || isHoveredForecastedDataset) && chartRef.ctx
        ? {
            target: fillTarget,
            above: createGradient(
              chartRef.ctx,
              fillColor ?? color ?? LineColors['neon-blue'],
              'vertical',
              false,
              dataExtent,
              chartRef.chartArea.height,
              yMax,
            ),
            below: createGradient(
              chartRef.ctx,
              LineColors['vivid-red'],
              'vertical',
              true,
              dataExtent,
              chartRef.chartArea.height,
            ),
          }
        : fillTarget,
  };
};

/**
 * @returns 🔧 The additional configuration for a **dataset**, based on the `type` of the provided dataset.
 */
export const getChartTypeDatasetConfig = <Dataset extends ChartDataset>(
  dataset: Dataset,
  unit?: string,
  isStacked?: boolean,
  isDatasetHovered?: boolean,
  chartRef?: ChartJS | null,
): DatasetConfig<ChartType> => {
  switch (dataset.type) {
    case 'line':
      return getLineChartDatasetConfig(dataset, isDatasetHovered ?? false);
    case 'bar':
      return getBarChartDatasetConfig(dataset, unit, isStacked, chartRef);
    case 'scatter':
      return getScatterChartDatasetConfig(dataset);
    default:
      console.error(`Unknown chart type: ${dataset.type}`);
      return {};
  }
};
