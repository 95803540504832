import { NotificationBadge } from 'aim-components';
import styles from './NotificationUnreadMark.module.css';

export const NotificationUnreadMark = ({ isRead }: { isRead: boolean }) => {
  return (
    <div className={styles.notificationUnreadMark} data-visible={!isRead}>
      <NotificationBadge color='blue' />
    </div>
  );
};
