import type { ReactElement } from 'react';
import React from 'react';
import { getHslColorHueFromArkUserId } from '../../utils/utils';

import './Avatar.css';

export interface AvatarProps {
  name?: string;
  imageUrl?: string;
  size?: 'default' | 'small';
  arkUserId?: string;
}

const AVATAR_HSL_COLORS = {
  background: {
    saturation: 100,
    lightness: 80,
  },
  text: {
    saturation: 100,
    lightness: 10,
  },
};

export function Avatar({ name, imageUrl, size = 'default', arkUserId }: AvatarProps): ReactElement {
  const initials =
    name &&
    name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 2);

  const hue = getHslColorHueFromArkUserId(arkUserId) ?? 0;
  const { background, text } = AVATAR_HSL_COLORS;

  return (
    <>
      {imageUrl ? (
        <div className={'Avatar-iconContainer'} data-size={size}>
          <img src={imageUrl} alt='' className='Avatar-icon' />
        </div>
      ) : (
        <div
          className='Avatar-initials'
          data-size={size}
          style={{
            backgroundColor: `hsl(${hue}, ${background.saturation}%, ${background.lightness}%)`,
            color: `hsl(${hue}, ${text.saturation}%, ${text.lightness}%)`,
          }}
        >
          {initials}
        </div>
      )}
    </>
  );
}
