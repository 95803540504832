import type { TSciChart, NumberRange } from 'scichart';
import { NumericTickProvider } from 'scichart';

const DEFAULT_MIN_DELTA = 1;

type CustomNumericTickProviderOptions = {
  wasmContext: TSciChart;
  minDelta?: number;
};

export class YAxisTickProvider extends NumericTickProvider {
  private minDelta: number | undefined;

  constructor({ wasmContext, minDelta }: CustomNumericTickProviderOptions) {
    super(wasmContext);
    this.minDelta = minDelta ?? DEFAULT_MIN_DELTA;
  }

  public setMinDelta(minDelta: number | undefined = DEFAULT_MIN_DELTA) {
    this.minDelta = minDelta;
  }

  protected calculateTicks(visibleRange: NumberRange, originalDelta: number, originalMajorDelta: number): number[] {
    const delta = Math.max(this.minDelta ?? 0, originalDelta);
    const majorDelta = Math.max(this.minDelta ?? 0, originalMajorDelta);

    const ticks = super.calculateTicks(visibleRange, delta, majorDelta);

    return ticks;
  }
}
