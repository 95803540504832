import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAnnotationBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 18 18'
    fill='none'
    fillOpacity={0}
    width='1em'
    height='1em'
    {...props}
  >
    <path d='M9 17H1V9a8 8 0 1 1 8 8Z' stroke='currentcolor' strokeWidth={2} />
  </svg>
);
export default SvgAnnotationBubble;
