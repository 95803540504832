import React, { useEffect, useRef, useState } from 'react';
import type { MutableRefObject } from 'react';
import type { SpringValue } from '@react-spring/web';
import { randomValueBetween } from './utils';
import './Score.css';

interface SparklingArkProps {
  width: number;
  height: number;
  innerArcRadius: number;
  outerArcRadius: number;
  score: SpringValue<number>;
}

export const SparklingArk = ({ width, height, innerArcRadius, outerArcRadius, score }: SparklingArkProps) => {
  const canvas = useRef<HTMLCanvasElement>() as MutableRefObject<HTMLCanvasElement>;
  const ctx = canvas.current?.getContext('2d');
  const scoreAngle = ((5 - score.get()) / 5) * Math.PI;

  interface Particle {
    x: number;
    y: number;
    speed: number;
    dirX: number;
    dirY: number;
    radius: number;
  }

  const numberOfParticles = 1000;
  const initParticles = useRef(
    Array.from(Array(numberOfParticles).keys()).map(() => {
      const radius = innerArcRadius;
      const angle = Math.PI;
      return {
        x: Math.cos(angle) * radius,
        y: -Math.sin(angle) * radius,
        dirX: Math.random() > 0.5 ? 1 : -1,
        dirY: Math.random() > 0.5 ? 1 : -1,
        speed: Math.random() * 1.5,
        radius: 0,
      };
    }),
  );
  const [particles, setParticles] = useState<Particle[]>(initParticles.current);

  useEffect(() => {
    const draw = () => {
      if (ctx) {
        ctx.clearRect(0, 0, width, height);
        ctx.beginPath();
        particles?.forEach((particle) => {
          const x = particle.x + width / 2;
          const y = particle.y + height;

          ctx.moveTo(x, y);
          ctx.arc(x, y, particle.radius, 0, Math.PI * 2);
        });
        ctx.fill();
      }
    };
    if (ctx) {
      ctx.fillStyle = '#ffffff';
      draw();
    }
  }, [height, ctx, particles, width]);

  useEffect(() => {
    const maxNumberOfParticles = (score.get() / 5) * numberOfParticles;
    const interval = setInterval(
      () => {
        setParticles((particles) =>
          particles.map((particle, i) => {
            let x = particle.x + particle.dirX * particle.speed,
              y = particle.y + particle.dirY * particle.speed;
            const radius = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

            if (
              (score.isAnimating && i > (numberOfParticles * score.get()) / 5) ||
              y > 0 ||
              radius > outerArcRadius ||
              radius < innerArcRadius ||
              Math.cos(scoreAngle) < x / radius ||
              Math.sin(scoreAngle) < y / radius
            ) {
              const radius = Math.random() * (outerArcRadius - innerArcRadius) + innerArcRadius;
              const angle = randomValueBetween(scoreAngle, Math.PI);
              x = Math.cos(angle) * radius;
              y = -Math.sin(angle) * radius;
            }

            return { ...particle, x, y, radius: i < maxNumberOfParticles ? Math.random() + 0.6 : 0 };
          }),
        );
      },
      score.isAnimating ? 50 : 80,
    );
    return () => clearInterval(interval);
  }, [height, innerArcRadius, outerArcRadius, width, score, scoreAngle]);

  return (
    <foreignObject height='100%' width='100%'>
      <canvas id='sparklingCanvas' style={{ opacity: '0.5' }} ref={canvas} width={width} height={height} />
    </foreignObject>
  );
};
