import { useSubscriptionPlansContext } from '@/components/subscription-plans/SubscriptionPlanContext';
import { Button } from 'aim-components';
import { useTranslation } from 'next-i18next';
import type { ComponentProps } from 'react';
import { useNavigationHeaderCallToActions } from '@/components/subscription-plans/navigation-header-call-to-actions/hooks';

export const UpgradeSubscriptionPlanNavigationHeaderButton = (props: ComponentProps<typeof Button>) => {
  const { t } = useTranslation('common', { keyPrefix: 'subscriptionPlan' });
  const { showSubscriptionPlans } = useSubscriptionPlansContext();
  const { shouldShowCallToAction } = useNavigationHeaderCallToActions();

  if (!shouldShowCallToAction) return null;

  return (
    <Button size='small' onClick={showSubscriptionPlans({ eventSource: 'navigation-header' })} {...props}>
      {t('upgradePlan')}
    </Button>
  );
};
