import type { ComponentProps, ReactElement } from 'react';
import React from 'react';
import cn from 'classnames';

import './Modal.css';
import { ModalOverlay } from './ModalOverlay';

export interface ModalProps extends ComponentProps<typeof ModalOverlay> {
  bordered?: boolean;
  padding?: boolean;
  noBottom?: boolean;
  className?: string;
}

export const Modal = ({
  children,
  onClose,
  open,
  bordered,
  padding = true,
  noBottom = false,
  className,
}: ModalProps): ReactElement => {
  return (
    <ModalOverlay onClose={onClose} open={open}>
      <div
        className={cn(
          'Modal-content',
          bordered && 'bordered',
          !padding && 'no-padding',
          noBottom && 'no-bottom',
          className,
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className='Modal-body'>{children}</div>
      </div>
    </ModalOverlay>
  );
};
