import type { MouseEvent } from 'react';
import cn from 'classnames';
import { HoverTooltip } from '../HoverTooltip';
import { Icon } from '../Icon';
import './MissingDataButton.css';

export interface MissingDataButtonProps {
  onClick: (e: MouseEvent) => void;
  tooltipMessage?: string;
  color?: string;
  transparentBackground?: boolean;
}

export const MissingDataButton = ({
  onClick,
  tooltipMessage,
  color,
  transparentBackground = false,
}: MissingDataButtonProps) => {
  return (
    <div className='missing-data-button-container'>
      <HoverTooltip message={tooltipMessage} position='left'>
        <div onClick={onClick} className={cn('missing-data-button', { transparentBackground })} role='button'>
          <Icon icon='cloud-exclamation' size='large' fillColor={color} />
        </div>
      </HoverTooltip>
    </div>
  );
};
