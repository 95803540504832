import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import { Icon } from '../Icon';
import './Footer.css';
import { Typography } from '../Typography';

export interface FooterProps {
  items: FooterItem[];
}

interface FooterItem {
  label: string;
  url?: string;
}

type DocumentLinkProps = FooterItem;

const DocumentLink = ({ label, url }: DocumentLinkProps): ReactElement => {
  return (
    <a className='Footer-item-document' href={url} target='_blank' rel='noreferrer'>
      <FooterItemLabel>{label}</FooterItemLabel>
      <Icon icon='external-link' />
    </a>
  );
};

const FooterItemLabel = ({ children }: { children: ReactNode }) => {
  return (
    <Typography variant='text2' className='Footer-item-label'>
      {children}
    </Typography>
  );
};

const VerticalDivider = (): ReactElement => <small className='Footer-item-divider'>|</small>;

export const Footer = ({ items }: FooterProps): ReactElement => {
  return (
    <footer className='Footer'>
      <div className='Footer-items'>
        {items.map((item, i) => (
          <div key={`${item.label}-${i}`} className='Footer-item'>
            {item.url ? (
              <DocumentLink label={item.label} url={item.url} />
            ) : (
              <FooterItemLabel>{item.label}</FooterItemLabel>
            )}
            {i !== items.length - 1 && <VerticalDivider />}
          </div>
        ))}
      </div>
    </footer>
  );
};
