import { useEffect } from 'react';

const MODIFIER_KEYS = ['shiftKey', 'altKey', 'ctrlKey', 'metaKey'] as const;
type ModifierKey = (typeof MODIFIER_KEYS)[number];

const validateModifierKeys = (
  ev: KeyboardEvent,
  modifierKeys: ReadonlyArray<ModifierKey> | 'ignore-all' | 'allow-none',
): boolean => {
  if (modifierKeys === 'ignore-all') return true;
  if (modifierKeys === 'allow-none') return !MODIFIER_KEYS.some((key) => ev[key]);
  return modifierKeys.every((key) => ev[key]);
};

export default function useKeyDown({
  keyCode,
  onKeyDown,
  modifierKeys = 'allow-none',
  targetDocumentBody = true,
}: {
  keyCode: string;
  onKeyDown: null | ((ev: KeyboardEvent) => void);
  modifierKeys?: ReadonlyArray<ModifierKey> | 'ignore-all' | 'allow-none';
  targetDocumentBody?: boolean;
}): void {
  useEffect(() => {
    const keyDownListener = (ev: KeyboardEvent): void => {
      if (onKeyDown && ev.code === keyCode && validateModifierKeys(ev, modifierKeys)) {
        if (targetDocumentBody && ev.target === document.body) {
          onKeyDown(ev);
        }

        if (!targetDocumentBody) {
          onKeyDown(ev);
        }
      }
    };

    if (onKeyDown) {
      document.addEventListener('keydown', keyDownListener);
    }

    return () => document.removeEventListener('keydown', keyDownListener);
  }, [keyCode, modifierKeys, onKeyDown, targetDocumentBody]);
}
