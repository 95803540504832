import type { CSSProperties, ReactElement } from 'react';
import React from 'react';
import cn from 'classnames';
import { Icon } from '../Icon';
import { Checkbox } from '../Input';
import './TableHeader.css';

interface HeaderColumnProps {
  label?: string;
  toggleSortOrder?: () => void;
  sortOrder?: 'asc' | 'desc';
  sorted: boolean;
  width?: CSSProperties['width'];
}

export const Column = ({ label, sortOrder, toggleSortOrder, sorted, width }: HeaderColumnProps): ReactElement => {
  return (
    <th
      onClick={toggleSortOrder}
      role={toggleSortOrder && 'button'}
      className={cn(toggleSortOrder && 'sortable')}
      style={{ width }}
    >
      <div>
        {label && <p>{label}</p>}
        {toggleSortOrder && <Icon icon='dropdown-up' className={cn('sort-icon', sortOrder, sorted && 'sorted')} />}
      </div>
    </th>
  );
};

export interface TableColumn<Id extends string> {
  id: Id;
  label?: string;
  sortable?: boolean;
  width?: string;
}

interface TableHeaderProps<Ids extends string> {
  items: TableColumn<Ids>[];
  toggleSortOrder?: (columnId: Ids) => void;
  sortOrder?: 'asc' | 'desc';
  toggleAllCheckboxes?: (checked: boolean) => void;
  initialValue?: boolean;
  sortedColumn?: Ids;
}

export const TableHeader = <T extends string>({
  items,
  toggleSortOrder,
  sortOrder,
  toggleAllCheckboxes,
  initialValue,
  sortedColumn,
}: TableHeaderProps<T>): ReactElement => {
  return (
    <tr className='TableHeadRow'>
      {toggleAllCheckboxes && (
        <th className='CheckboxColumn'>
          <div>
            <Checkbox onChange={(checked) => toggleAllCheckboxes(checked)} initialValue={initialValue} />
          </div>
        </th>
      )}
      {items.map((i) => (
        <Column
          key={i.id}
          label={i.label}
          toggleSortOrder={i.sortable && toggleSortOrder ? () => toggleSortOrder(i.id) : undefined}
          sortOrder={sortedColumn === i.id ? sortOrder : undefined}
          sorted={sortedColumn === i.id}
          width={i.width}
        />
      ))}
    </tr>
  );
};
