/* eslint-disable no-console */
import type { SciChartSurface } from 'scichart';
import { ESeriesType, FastMountainRenderableSeries, SciChartOverview, XyDataSeries } from 'scichart';
import { SciChartReact } from 'scichart-react';
import React from 'react';

const xValues = [1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003];
const yValues1 = [10, 13, 7, 16, 4, 6, 20, 14, 16, 10, 24, 11];
const yValues2 = [12, 17, 21, 15, 19, 18, 13, 21, 22, 20, 5, 10];
const yValues3 = [7, 30, 27, 24, 21, 15, 17, 26, 22, 28, 21, 22];
const yValues4 = [16, 10, 9, 8, 22, 14, 12, 27, 25, 23, 17, 17];
const yValues5 = [7, 24, 21, 11, 19, 17, 14, 27, 26, 22, 28, 16];

// * Used to create an outline for the overview
const summedYValues = [yValues1, yValues2, yValues3, yValues4, yValues5].reduce((acc, yValues) => {
  yValues.forEach((yValue, i) => {
    acc[i] = (acc[i] || 0) + yValue;
  });

  return acc;
}, []);

// Minimal example trying to use a `StackedMountainCollection` together with `SciChartOverview` – which does not seem to be supported.
export const OverviewTest = () => {
  return (
    <>
      <SciChartReact<SciChartSurface>
        style={{ width: 800, height: 400 }}
        config={{
          series: [
            // Group StackedMountain into one StackedMountainCollection and pass into the series object
            {
              type: ESeriesType.StackedMountainCollection,
              series: [
                {
                  type: ESeriesType.StackedMountainSeries,
                  // @ts-expect-error TODO: Will fix before merge
                  options: { stackedGroupId: 'StackedGroupId', fill: '#882B91', stroke: '#E4F5FC' },
                  xyData: { xValues, yValues: yValues1 },
                },
                {
                  type: ESeriesType.StackedMountainSeries,
                  // @ts-expect-error TODO: Will fix before merge
                  options: { stackedGroupId: 'StackedGroupId', fill: '#EC0F6C', stroke: '#E4F5FC' },
                  xyData: { xValues, yValues: yValues2 },
                },
                {
                  type: ESeriesType.StackedMountainSeries,
                  // @ts-expect-error TODO: Will fix before merge
                  options: { stackedGroupId: 'StackedGroupId', fill: '#F48420', stroke: '#E4F5FC' },
                  xyData: { xValues, yValues: yValues3 },
                },
                {
                  type: ESeriesType.StackedMountainSeries,
                  // @ts-expect-error TODO: Will fix before merge
                  options: { stackedGroupId: 'StackedGroupId', fill: '#50C7E0', stroke: '#E4F5FC' },
                  xyData: { xValues, yValues: yValues4 },
                },
                {
                  type: ESeriesType.StackedMountainSeries,
                  // @ts-expect-error TODO: Will fix before merge
                  options: { stackedGroupId: 'StackedGroupId', fill: '#30BC9A', stroke: '#E4F5FC' },
                  xyData: { xValues, yValues: yValues5 },
                },
              ],
            },
          ],
        }}
        onInit={(initResult) => {
          const { sciChartSurface } = initResult;
          const wasmContext = sciChartSurface.webAssemblyContext2D;

          SciChartOverview.create(sciChartSurface, 'overview-id', {
            transformRenderableSeries: () => {
              // * SciChart Overview does not seem to support StackedMountainCollection, but using the transformRenderableSeries we can return another series to display. In this case, the outline of the total stack.
              const dataSeries = new XyDataSeries(wasmContext, { xValues, yValues: summedYValues });
              return new FastMountainRenderableSeries(wasmContext, {
                dataSeries,
                fill: 'rgba(255, 255, 255, 0.2)',
                stroke: 'rgba(255, 255, 255, 0.3)',
              });
            },
          });
        }}
      />

      <div id='overview-id' style={{ width: 800, height: 100 }}></div>
    </>
  );
};
