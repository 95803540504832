import React from 'react';
import { to, animated, useSpring } from '@react-spring/web';
import { line as d3Line } from 'd3';
import { interpolatePath } from 'd3-interpolate-path';

interface AnimatedTickProps {
  annotationMarkerSize: { width: number; height: number };
  active: boolean;
}

const AnimatedTick = ({ annotationMarkerSize, active }: AnimatedTickProps) => {
  const line = d3Line()
    .x((d) => d[0])
    .y((d) => d[1]);

  const triangle = line([
    [annotationMarkerSize.width / 2, -annotationMarkerSize.height * 2],
    [0, 0],
    [annotationMarkerSize.width, 0],
  ]);
  const rectangle = line([
    [annotationMarkerSize.width, -annotationMarkerSize.height],
    [0, -annotationMarkerSize.height],
    [0, 0],
    [annotationMarkerSize.width, 0],
  ]);

  const pathInterpolator = React.useMemo(
    () => interpolatePath(rectangle as string, triangle as string),
    [rectangle, triangle],
  );

  const { t } = useSpring({
    config: {
      duration: 200,
    },
    from: { t: 0 },
    t: active ? 1 : 0,
  });

  return <animated.path d={to(t, pathInterpolator)} className='annotationAccentColorPrimary' />;
};
export default AnimatedTick;
