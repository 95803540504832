import React, { useEffect, useMemo } from 'react';
import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';
import CountUp from 'react-countup';
import './ScoreCountUp.css';
import { formatDelta, getScoreCountUpColors } from './utils';
import { Typography } from '../../Typography';
import { HoverTooltip } from '../../HoverTooltip';
import { formatValue } from '../../../utils';

interface ScoreCountUpProps {
  score?: number;
  delta?: number;
  scale: number;
  durationMs?: number;
  delay?: number;
  displayScoreDelta?: boolean;
}

export const ScoreCountUp = ({
  score = 0,
  delta = 0,
  scale = 0.5,
  durationMs = 1000,
  delay = 0,
  displayScoreDelta = true,
}: ScoreCountUpProps) => {
  const { fromColor, toColors } = useMemo(() => getScoreCountUpColors(score), [score]);

  const animationConfig = useMemo(
    () => ({
      config: { duration: durationMs / (toColors.length || 1) },
      delay,
    }),
    [delay, durationMs, toColors.length],
  );

  const [colorAnimationStyle, api] = useSpring(() => ({
    from: { color: fromColor },
    ...animationConfig,
  }));

  useEffect(() => {
    api.start({
      to: toColors.map((color) => ({ color })),
      ...animationConfig,
    });
  }, [animationConfig, api, fromColor, toColors, score]);

  const displayDeltaArrow = Math.abs(delta) - 0.05 >= 0;

  const tooltipMessage = displayDeltaArrow
    ? `${formatValue(Math.abs(delta), '', 1)} ${delta > 0 ? 'increase' : 'decrease'} since last quarter`
    : 'Stable since last quarter';

  return (
    <animated.div
      style={{ ...colorAnimationStyle, transform: `scale(${scale})`, transformOrigin: 'bottom' }}
      className='ScoreCountUp'
    >
      {displayScoreDelta && (
        <HoverTooltip message={tooltipMessage} position='top'>
          <div className={cn('score-delta-container', { negativeDelta: delta < 0 })}>
            <Typography variant='text2'>{formatDelta(delta)}</Typography>
            {displayDeltaArrow && (
              <svg width='16' height='14' viewBox='0 0 16 14' xmlns='http://www.w3.org/2000/svg'>
                <path d='M8.17027 0.28418L0.79984 13.0501L15.5407 13.0501L8.17027 0.28418Z' />
              </svg>
            )}
          </div>
        </HoverTooltip>
      )}

      <CountUp
        delay={delay / 1000}
        preserveValue
        end={score}
        duration={durationMs / 1000}
        decimals={1}
        className='score-count-up-text'
      />
    </animated.div>
  );
};
