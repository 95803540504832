import type { ReactNode } from 'react';
import React from 'react';
import styles from './FixedFooterButton.module.css';

interface FixedFooterButtonProps {
  button: ReactNode;
}

export const FixedFooterButton = ({ button }: FixedFooterButtonProps) => {
  return <div className={styles.fixedFooterButton}>{button}</div>;
};
