import React from 'react';
import { Badge, Typography } from 'aim-components';
import styles from './BetaBadge.module.css';

export const BetaBadge = ({ type = 'warning' }: { type?: 'warning' | 'neutral' }) => {
  return (
    <div className={styles.betaBadge}>
      <Badge type={type}>
        <Typography variant='text2' color={type === 'warning' ? 'dark' : 'secondary'}>
          BETA
        </Typography>
      </Badge>
    </div>
  );
};
