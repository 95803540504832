import type { HTMLAttributes } from 'react';
import React, { useContext } from 'react';
import styles from './AnnotationSidePanelItem.module.css';
import { DeleteIcon } from '../AnnotationSidePanel/DeleteIcon';
import type { Annotation } from 'aim-components';
import { AnnotationCard, Avatar } from 'aim-components';
import { UserContext } from '@/components/authentication/UserContext';

interface AnnotationSidePanelListItemProps extends HTMLAttributes<HTMLDivElement> {
  author: string;
  arkUserId: string;
  date: Date;
  contentParts: Annotation['contentParts'];
  numberOfReplies?: number;
  onClick?: () => void;
  actions?: { onArchive?: () => void };
}

export const AnnotationSidePanelListItem = ({
  author,
  arkUserId,
  date,
  numberOfReplies,
  contentParts,
  onClick,
  actions,
  ...props
}: AnnotationSidePanelListItemProps) => {
  const user = useContext(UserContext);

  return (
    <div className={styles.annotationSidePanelListItem} role='button' onClick={onClick} {...props}>
      <Avatar name={author} size='small' arkUserId={arkUserId} />
      <div className={styles.annotationSidePanelListItemContent}>
        <AnnotationCard.Header author={author} date={date} numberOfReplies={numberOfReplies} isThread={true} />
        <AnnotationCard.Content contentParts={contentParts} truncate />
      </div>
      {user.arkUserId === arkUserId && (
        <DeleteIcon className={styles.floatingTrashIcon} onArchive={actions?.onArchive} />
      )}
    </div>
  );
};
