import type { ILabel2DOptions } from 'scichart';
import { NumericLabelProvider } from 'scichart';

export type YAxisLabelProviderFormat =
  | {
      type: 'currency';
      options: Intl.NumberFormatOptions & Required<Pick<Intl.NumberFormatOptions, 'currency'>>;
    }
  | {
      type: 'number';
      options: Intl.NumberFormatOptions;
    }
  | {
      type: 'percentage';
      options: Intl.NumberFormatOptions;
    };

type YAxisLabelProviderOptions = ILabel2DOptions & {
  format?: YAxisLabelProviderFormat;
};

export class YAxisLabelProvider extends NumericLabelProvider {
  constructor(options?: YAxisLabelProviderOptions) {
    super(options);
    this.updateFormat(options?.format);
  }

  private getFormatter(format: YAxisLabelProviderFormat | undefined): (dataValue: number) => string {
    switch (format?.type) {
      case 'currency': {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
          currencyDisplay: 'narrowSymbol',
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
          notation: 'compact',
          style: 'currency',
          ...format.options,
        });

        return (dataValue) => currencyFormatter.format(dataValue);
      }
      case 'percentage': {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
          currencyDisplay: 'narrowSymbol',
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
          notation: 'compact',
          style: 'percent',
          ...format.options,
        });

        return (dataValue) => currencyFormatter.format(dataValue / 100);
      }
      case 'number':
      default: {
        const numberFormatter = new Intl.NumberFormat('en-US', {
          notation: 'compact',
          compactDisplay: 'short',
          ...format?.options,
        });

        return (dataValue) => numberFormatter.format(dataValue);
      }
    }
  }

  public updateFormat(format: YAxisLabelProviderFormat | undefined) {
    this.formatLabelProperty = this.getFormatter(format);

    // * We clear the cache as the format has changed (the same input might now produce a different output).
    this.clearCache();
  }
}
