import type { FormEvent, ReactElement, ChangeEvent } from 'react';
import React from 'react';

// This component supports both providing individual onChange functions to the input children, _and_ to have a common onChange for all of them
// Currently, this is only supported for regular text inputs (i.e. not Select or Checkbox).
// To support this, Select and Checkbox needs to be altered to accept a regular onChange prop that takes a change event for an html input

export interface FormProps {
  children: ReactElement | ReactElement[];
  onSubmit: (e: FormEvent) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Form = ({ children, onSubmit, onChange }: FormProps): ReactElement => {
  const childrenToRender = onChange
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, { onChange: (e: ChangeEvent<HTMLInputElement>) => onChange(e) }),
      )
    : children;
  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    onSubmit(e);
  };
  return (
    <form onSubmit={handleSubmit} data-testid='form'>
      {childrenToRender}
    </form>
  );
};
