import { lazy, type ComponentProps, Suspense } from 'react';
import type { Step } from 'react-joyride';
import { ProductTourTooltip } from './ProductTourTooltip';

// * Lazy load react-joyride to avoid including it in the main bundle (the tours are shown conditionally, and the dependency is quite large)
const LazyJoyride = lazy(() => import('react-joyride'));

export type ProductTourStep = Step;

export const ProductTour = (props: ComponentProps<typeof LazyJoyride>) => {
  return (
    <Suspense fallback={null}>
      <LazyJoyride
        tooltipComponent={ProductTourTooltip}
        continuous
        showProgress
        styles={{
          options: {
            arrowColor: '#1a1a1a',
            overlayColor: 'rgba(0, 0, 0, 0.7)',
          },
          spotlight: {
            borderRadius: '100vw', // * 100vw gives rounded corners that work for different element widths
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          },
        }}
        {...props}
      />
    </Suspense>
  );
};
