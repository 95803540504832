import type { ReactElement } from 'react';
import React from 'react';
import cn from 'classnames';
import { valueFormatter } from '../../utils/utils';

export interface FormattedValueProps {
  value?: string | number | null;
  unit?: string;
  className?: string;
  decimals?: number;
}

export const FormattedValue = ({ value, unit, className, decimals = 1 }: FormattedValueProps): ReactElement => {
  return (
    <span className={cn('Formatted-value', className)} data-testid='formatted-value'>
      {valueFormatter(value as number, unit, decimals)}
    </span>
  );
};
