import type { ReactNode } from 'react';
import React from 'react';
import styles from './MobileFullModal.module.css';
import { Button } from '../Button';
import { Icon } from '../Icon';

interface MobileFullScreenModalProps {
  onBack?: () => void;
  title?: ReactNode;
  open: boolean;
  children: ReactNode;
  underlinedTitle?: boolean;
}

export const MobileFullScreenModal = ({
  onBack,
  title,
  open,
  children,
  underlinedTitle,
}: MobileFullScreenModalProps) => {
  return (
    <>
      {open && (
        <div className={styles.overlay}>
          <div className={styles.modalWrapper}>
            {(title || onBack) && (
              <div className={styles.header}>
                {onBack && (
                  <Button
                    type='tertiary'
                    color='white'
                    onClick={onBack}
                    startIcon={<Icon icon='arrow-left' title='Back' size='medium' />}
                  />
                )}

                {title}
              </div>
            )}

            {underlinedTitle && <div className={styles.divider} />}
            <div className={styles.container}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};
