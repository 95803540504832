import styles from './ProgressAccordion.module.css';
import type { ProgressAccordionProps } from './types';
import { ProgressAccordionStep } from './ProgressAccordionStep';
import cn from 'classnames';

export const ProgressAccordion = ({ children, className }: ProgressAccordionProps) => {
  return <div className={cn(styles.main, className)}>{children}</div>;
};

ProgressAccordion.Step = ProgressAccordionStep;
