import React from 'react';
import { animated, easings, useSpring } from '@react-spring/web';
import { min } from 'd3';

interface DeltaLabelProps {
  score: number;
  delta: number;
  innerArcRadius: number;
  outerArcRadius: number;
  svgWidth: number;
  svgHeight: number;
  delay: number;
  deltaLabelCurrent: string;
  deltaLabelPrev: string;
}

export const DeltaLabel = ({
  score,
  innerArcRadius,
  outerArcRadius,
  delta,
  svgWidth,
  svgHeight,
  delay,
  deltaLabelCurrent,
  deltaLabelPrev,
}: DeltaLabelProps) => {
  const paddingForCurrent = 0.07;
  const arrowColor = '#ffffff';
  const deltaLabelColor = Math.sign(delta) === 1 ? '#000000' : arrowColor;
  const arcWidth = outerArcRadius - innerArcRadius;
  const labelSize = min([deltaLabelPrev.length * 16, arcWidth]) ?? 0;
  const lineLength = (arcWidth - labelSize) / 2;

  const rotateLabel = Math.sign(score - delta - 2.5);

  const rotateCurrentLabel = Math.sign(score + paddingForCurrent - 2.5);

  const [animation] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 400,
      easing: easings.easeOutSine,
    },
    delay: delay,
  }));

  return (
    <>
      <g transform={`translate(${svgHeight},${svgWidth / 2})`}>
        <animated.path
          d={`M ${outerArcRadius - lineLength} 0 H ${outerArcRadius} `}
          transform={`rotate(-${(5 - (score - delta) / 5) * 180} 0 0)`}
          strokeLinecap='round'
          stroke={deltaLabelColor}
          strokeWidth={1}
          opacity={animation.opacity}
        />
        <animated.path
          d={`M ${innerArcRadius + 4} 0 H ${innerArcRadius + lineLength}`}
          transform={`rotate(-${(5 - (score - delta) / 5) * 180} 0 0)`}
          strokeLinecap='round'
          stroke={deltaLabelColor}
          strokeWidth={1}
          opacity={animation.opacity}
        />
        <animated.text
          x='0'
          y='5'
          textAnchor={'middle'}
          fill={deltaLabelColor}
          transform={`rotate(-${(5 - (score - delta) / 5) * 180} 0 0)translate(${
            innerArcRadius + arcWidth / 2
          }, -0)scale(${rotateLabel},${rotateLabel})`}
          opacity={animation.opacity}
          className='aim-score-deltaText'
        >
          {deltaLabelPrev}
        </animated.text>

        <animated.path
          d={`M ${outerArcRadius - lineLength} 0 H ${outerArcRadius} `}
          transform={`rotate(-${(5 - (score + paddingForCurrent) / 5) * 180} 0 0)`}
          strokeLinecap='round'
          stroke={arrowColor}
          strokeWidth={1}
          opacity={animation.opacity}
        />
        <animated.path
          d={`M ${innerArcRadius + 4} 0 H ${innerArcRadius + lineLength}`}
          transform={`rotate(-${(5 - (score + paddingForCurrent) / 5) * 180} 0 0)`}
          strokeLinecap='round'
          stroke={arrowColor}
          strokeWidth={1}
          opacity={animation.opacity}
        />

        <animated.text
          x='0'
          y='5'
          textAnchor={'middle'}
          fill={arrowColor}
          transform={`rotate(-${(5 - (score + paddingForCurrent) / 5) * 180} 0 0)translate(${
            innerArcRadius + arcWidth / 2
          }, -0)scale(${rotateCurrentLabel},${rotateCurrentLabel})`}
          opacity={animation.opacity}
          className='aim-score-deltaText'
        >
          {deltaLabelCurrent}
        </animated.text>
      </g>
    </>
  );
};
