import type { LinearScale, ScriptableTooltipContext, TooltipItem } from 'chart.js';
import { getIndexClosestPointInArray } from '../utils';
import type { ChartDataset } from '../types';

/**
 * @returns the hovered dataset index for stacked charts. The mouse pointer has to be inside the stack area (i.e. hovering outside/above a stack will return undefined).
 */
export const getHoveredStack = (
  chart: ScriptableTooltipContext<'bar' | 'line' | 'scatter'>,
  allDatasets: ChartDataset[],
): string | undefined => {
  if ('scales' in chart) {
    const caretValue = (chart.scales as { y: LinearScale }).y.getValueForPixel(chart.tooltip.caretY) ?? 0;

    const datasetStacks = Object.entries(
      chart.tooltip.dataPoints.filter((dataPoint) => 'isStacked' in dataPoint.dataset && dataPoint.dataset.isStacked)[0]
        ?.parsed?._stacks?.y ?? { 0: 0 },
    ).filter(([key]) => !isNaN(parseInt(key)));

    let closestPositiveStackIndex = undefined;
    let closestNegativeStackIndex = undefined;

    let closestPositiveDistance = Number.POSITIVE_INFINITY;
    let closestNegativeDistance = Number.POSITIVE_INFINITY;

    let positiveStackTop = 0;
    let negativeStackBottom = 0;

    datasetStacks.forEach(([key, stackHeight]) => {
      // Handles the case where the mouse is above 0 on the Y axis
      if (caretValue >= 0 && stackHeight >= 0) {
        positiveStackTop += stackHeight;

        if (caretValue < positiveStackTop) {
          const distance = positiveStackTop - caretValue;

          if (distance < closestPositiveDistance) {
            closestPositiveDistance = distance;
            closestPositiveStackIndex = parseInt(key);
          }
        }
      }

      // Handles the case where the mouse is below 0 on the Y axis
      if (caretValue < 0 && stackHeight < 0) {
        negativeStackBottom += stackHeight;

        if (caretValue > negativeStackBottom) {
          const distance = caretValue - negativeStackBottom;

          if (distance < closestNegativeDistance) {
            closestNegativeDistance = distance;
            closestNegativeStackIndex = parseInt(key);
          }
        }
      }
    });

    const index =
      closestNegativeDistance < closestPositiveDistance ? closestNegativeStackIndex : closestPositiveStackIndex;

    return index !== undefined ? allDatasets[index].label : undefined;
  }
};

/**
 * @returns the closest dataset
 */
export const getHoveredDataset = (
  context: ScriptableTooltipContext<'bar' | 'line' | 'scatter'>,
  dataPoints: TooltipItem<'bar' | 'line' | 'scatter'>[],
): string | undefined => {
  const filteredDatasets = dataPoints.filter((dataset) => dataset.dataset.borderColor !== '#303030');
  const closestDataset =
    filteredDatasets[
      getIndexClosestPointInArray(
        context.tooltip.caretY,
        filteredDatasets.filter((item) => item.element.y !== null).map((item) => item.element.y),
      )
    ];

  return closestDataset?.dataset?.label;
};
