import React from 'react';
import type { SpringValue } from '@react-spring/web';
import './Score.css';
import { useElementDimensions } from '../../utils/useElementDimensions';
import { ScorePath } from './ScorePath';
import type { ArcSize } from './utils';
import { isSafari, SCORE_CONFIG } from './utils';

const { viewBoxWidth, viewBoxHeight, outerArcRadiuses, innerArcRadiuses } = SCORE_CONFIG;

interface ScoreBlurProps {
  score?: number;
  arcSize?: ArcSize;
  angleTo: SpringValue<number>;
}

export const ScoreBlur = ({ score = 0, arcSize = 'small', angleTo }: ScoreBlurProps) => {
  const outerArcRadius = outerArcRadiuses[arcSize];
  const innerArcRadius = innerArcRadiuses[arcSize];

  const [, responsiveWidth] = useElementDimensions();

  const foreignObjectTopPadding = isSafari() ? (viewBoxWidth - responsiveWidth) / 2 : 0;
  const clipPath = `url(#score-arc-solid-${score})`;

  return (
    <>
      {/* First blur layer */}
      <svg viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight} `}>
        <ScorePath endAngle={angleTo} score={score} outerRadius={outerArcRadius} innerRadius={innerArcRadius} />
        <foreignObject height='100%' width='100%' opacity={0.75} clipPath={clipPath}>
          <div className='aim-score-rainbow-gradient' style={{ paddingTop: `${foreignObjectTopPadding}px` }} />
        </foreignObject>
      </svg>
      <div className='aim-score_overlay aim-score_blur-background' />

      {/* Second blur layer */}
      <svg className='aim-score_overlay' viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
        {/* We have to repeat these ScorePaths (clip paths) for Safari */}
        <ScorePath endAngle={angleTo} score={score} outerRadius={outerArcRadius} innerRadius={innerArcRadius} />

        <foreignObject height='100%' width='100%' opacity={0.1} clipPath={clipPath}>
          <div className='aim-score-rainbow-gradient' style={{ paddingTop: `${foreignObjectTopPadding}px` }} />
        </foreignObject>
      </svg>
    </>
  );
};
