import { animated, useSpring } from '@react-spring/web';
import { useEffect } from 'react';

interface AnimatedBarProps {
  width: number;
  fromHeight: number;
  toHeight: number;
  fill: string;
  duration: number;
  startTrigger: number;
}
export const AnimatedBar = ({ width, fromHeight, toHeight, fill, duration, startTrigger }: AnimatedBarProps) => {
  const [spring, api] = useSpring(
    () => ({
      from: { height: fromHeight },
      config: {
        duration: duration,
      },
    }),
    [],
  );

  useEffect(() => {
    if (startTrigger === 1) {
      api.start({ from: { height: fromHeight }, to: [{ height: toHeight }, { height: fromHeight }] });
    }
  }, [api, startTrigger, fromHeight, toHeight]);

  return (
    <g transform={`translate(${width},0)`}>
      <animated.rect {...spring} fill={fill} width={width} transform='rotate(180)' />
    </g>
  );
};
