import { Button } from '../Button';
import type { ButtonProps } from '../Button/Button';
import { Typography } from '../Typography';
import styles from './ProductTourTooltip.module.css';
import type { TooltipRenderProps } from 'react-joyride';

export const ProductTourTooltip = ({
  step,
  index: currentStepIndex,
  size: numberOfSteps,
  closeProps,
  primaryProps,
  isLastStep,
}: TooltipRenderProps) => {
  const { title, content, showProgress } = step;

  return (
    <div className={styles.tooltip}>
      <div className={styles.header}>
        {title && (
          <Typography variant='text1' className={styles.title}>
            {title}
          </Typography>
        )}
        <Button
          type='tertiary'
          color='white'
          endIcon='close'
          onClick={closeProps.onClick as ButtonProps['onClick']}
          className={styles.closeButton}
          aria-label='Close tour'
        />
      </div>
      <div className={styles.body}>{<Typography variant='text2'>{content}</Typography>}</div>
      <div className={styles.footer}>
        {showProgress && (
          <Typography variant='text1' color='secondary'>
            {currentStepIndex + 1} of {numberOfSteps}
          </Typography>
        )}
        <Button
          onClick={primaryProps.onClick as ButtonProps['onClick']}
          type='primary'
          aria-label={isLastStep ? 'Finish tour' : 'Next tour step'}
        >
          {isLastStep ? 'Done' : 'Next'}
        </Button>
      </div>
    </div>
  );
};
