import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type {
  Annotation,
  AnnotationContext,
  AnnotationReply,
  ConnectorDefinitionCategory,
  InsightCategory,
} from 'aim-components';
import { EMPTY_ARRAY_STATIC_REFERENCE, toast, useKeyDown } from 'aim-components';
import { useTranslation } from 'next-i18next';
import useSWR, { mutate } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { generateMentionsUserData, parseAnnotations } from './annotation-utils';
import type { CreateAnnotationRequestData } from '@/api/insights/annotations/create-annotation/index.page';
import type { CreateAnnotationReplyRequestData } from '@/api/insights/annotations/create-annotation-reply/index.page';
import type { AnnotationsResponse } from '@/api/insights/annotations/get-annotations/index.page';
import type { CountriesResponse } from '@/api/insights/countries/index.page';
import type { InvitedUser } from '@/components/settings/types';
import { FSCreateAnnotation, FSCreateAnnotationReply } from '@/lib/fullstory';
import request, { fetcher } from '@/lib/request/request';
import type { InferContextType } from '@/lib/utils';
import { ApiRoutes } from 'pages/apiRoutes';
import type { InsightMetadata } from '@/api/insights/metadata/index.page';
import type { ScoreName } from '@/api/scores/types';
import { Routes } from '@/lib/routes';
import { useUserPermissions } from '@/lib/permissions/hooks';
import { useAnnotationEventDispatchers } from 'aim-components/src/components/Annotation/hooks';
import { removeUrlSearchParameterFromUrl } from '@/components/insights/charts/common/chart-link-utils';
import type { ChartKey } from '@/components/insights/charts/common/types';
import { chartKeyInsightCategoryMap } from '@/components/insights/charts/common/types';
import { getRelatedDataSourceCategoriesByInsightCategory } from '@/components/unconfigured-data/utils';
import { getChartReadyState } from '@/components/insights/charts/common/utils';
import { useIntercomContext } from 'aim-utils';
import { UserContext } from '@/components/authentication/UserContext';

export const insightCategoryScoreNameMap = {
  growth: 'growth',
  marketingEfficiency: 'marketing_efficiency',
  monetisation: 'monetisation',
  retention: 'retention',
  financialStrength: 'financial_strength',
  activity: null,
  scores: 'aim',
  benchmarking: null,
  dataValidation: null,
} as const satisfies Record<InsightCategory, ScoreName | null>;

export const useCountries = () => {
  const [{ someInsightsAvailable }] = useMetadata();
  const { data, isValidating } = useSWRImmutable(
    someInsightsAvailable ? ApiRoutes.Countries : null,
    fetcher<CountriesResponse>,
  );
  return {
    countriesWithRevenue: data?.countriesWithRevenue ?? [],
    countriesWithActivity: data?.countriesWithActivity ?? [],
    isValidating,
  };
};

export const useMetadata = () => {
  const { data, isLoading } = useSWRImmutable(ApiRoutes.InsightsMetadata, fetcher<InsightMetadata>);
  const someInsightsAvailable = Object.values(data?.insightsAvailable ?? {}).some(
    (categoryHasInsights) => categoryHasInsights === true,
  );

  if (data) {
    return [{ ...data, someInsightsAvailable }, isLoading] as const;
  }

  return [
    {
      xAxisStartDate: new Date('2017-01-01').toISOString(),
      someInsightsAvailable: false,
      defaultGranularity: 'months',
      insightsAvailable: null,
      currencyCode: null,
      forecastingParameterMultiplierMap: null,
      hasReliableForecast: false,
      insightConfig: null,
    } satisfies {
      [Key in keyof InsightMetadata]: InsightMetadata[Key] | null;
    } & { someInsightsAvailable: boolean },
    isLoading,
  ] as const;
};

export const useDisplayInsight = () => {
  const [{ insightConfig }] = useMetadata();
  return useCallback((chartKey: ChartKey) => insightConfig?.[chartKey]?.show !== false, [insightConfig]);
};

export const useAnnotations = (enableFeature: boolean) => {
  const { t } = useTranslation('common', { keyPrefix: 'annotations' });
  const { data: annotationsResponse = EMPTY_ARRAY_STATIC_REFERENCE, isLoading: isLoadingAnnotations } =
    useSWR<AnnotationsResponse>(enableFeature ? ApiRoutes.GetAnnotations : null, fetcher);
  const { data: users = EMPTY_ARRAY_STATIC_REFERENCE } = useSWRImmutable<InvitedUser[]>(
    enableFeature ? ApiRoutes.GetAllInvitedUsers : null,
    fetcher,
  );

  const user = useContext(UserContext);
  const { hasAdminPrivileges } = useUserPermissions();

  const { canEditAnnotations } = useUserPermissions();
  const [displayAnnotationSidePanel, setDisplayAnnotationSidePanel] = useState<boolean>(false);
  const [isCreatingAnnotation, setIsCreatingAnnotation] = useState<boolean>(false);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const { dispatchHoveredAnnotationId } = useAnnotationEventDispatchers();
  const [openedAnnotationId, setOpenedAnnotationId] = useState<Annotation['id'] | null>(null);

  useKeyDown({
    keyCode: 'KeyC',
    onKeyDown: () => enableFeature && setDisplayAnnotationSidePanel((currentlyDisplayed) => !currentlyDisplayed),
  });

  useKeyDown({
    keyCode: 'Escape',
    onKeyDown: () => {
      if (!enableFeature) return;

      if (isCreatingAnnotation) {
        setIsCreatingAnnotation(false);
      } else {
        setDisplayAnnotationSidePanel(false);
      }
    },
    targetDocumentBody: false,
  });

  useEffect(() => {
    if (Array.isArray(annotationsResponse)) {
      setAnnotations(parseAnnotations(annotationsResponse, users));
    }
  }, [annotationsResponse, users]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const linkedAnnotationId = searchParams.get('annotation_reference');
    if (!linkedAnnotationId) return;

    const annotation = annotations.find((annotation) => annotation.id === linkedAnnotationId);
    if (!annotation) return;

    removeUrlSearchParameterFromUrl(window.location.href, 'annotation_reference');
    setDisplayAnnotationSidePanel(true);

    setOpenedAnnotationId(annotation.id);

    const linkedReplyId = searchParams.get('reply_reference');
    if (!linkedReplyId) return;

    removeUrlSearchParameterFromUrl(window.location.href, 'reply_reference');
    dispatchHoveredAnnotationId(linkedReplyId);
  }, [annotations, dispatchHoveredAnnotationId]);

  const createAnnotation = useCallback(
    async (newAnnotation: CreateAnnotationRequestData) => {
      if (!canEditAnnotations) return undefined;
      return request<AnnotationsResponse[0]>({
        url: ApiRoutes.CreateAnnotation,
        method: 'POST',
        data: newAnnotation,
      })
        .then((response) => {
          const parsedAnnotation: Annotation = parseAnnotations([response], users)[0];
          setAnnotations((annotations) => [...annotations, parsedAnnotation]);
          FSCreateAnnotation(parsedAnnotation);
          return parsedAnnotation;
        })
        .catch(() => {
          toast({ message: t('createAnnotationError'), type: 'critical' });
          return undefined;
        });
    },
    [t, users, canEditAnnotations],
  );

  const createAnnotationReply = useCallback(
    async (newReply: CreateAnnotationReplyRequestData) => {
      if (!canEditAnnotations) return undefined;
      await request<AnnotationReply>({
        url: ApiRoutes.CreateAnnotationReply,
        method: 'POST',
        data: newReply,
      })
        .then((response) => {
          mutate(ApiRoutes.GetAnnotations);
          FSCreateAnnotationReply(response);
        })
        .catch(() => toast({ message: t('createAnnotationReplyError'), type: 'critical' }));
    },
    [t, canEditAnnotations],
  );

  const archiveAnnotation = useCallback(
    async ({ id, replyId }: { id: Annotation['id']; replyId?: AnnotationReply['id'] }) => {
      if (!canEditAnnotations) return undefined;
      if (replyId) {
        setAnnotations(
          annotations.map((annotation) => ({
            ...annotation,
            replies:
              annotation.id === id ? annotation.replies?.filter((reply) => reply.id !== replyId) : annotation.replies,
          })),
        );
        await request({
          url: ApiRoutes.UpdateAnnotationReply,
          method: 'POST',
          data: { id, replyId, status: 'archived' },
        }).catch(() => toast({ message: t('updateAnnotationReplyError'), type: 'critical' }));
      } else {
        setAnnotations(annotations.filter((annotation) => annotation.id !== id));
        await request({
          url: ApiRoutes.UpdateAnnotation,
          method: 'POST',
          data: { id, status: 'archived' },
        }).catch(() => toast({ message: t('updateAnnotationError'), type: 'critical' }));
      }
    },
    [annotations, t, canEditAnnotations],
  );

  const mentionsUserData = useMemo(() => generateMentionsUserData(users), [users]);
  const { updateIntercomBlocker } = useIntercomContext();

  useEffect(() => {
    updateIntercomBlocker('annotation-side-panel', { hideIntercom: displayAnnotationSidePanel });
  }, [updateIntercomBlocker, displayAnnotationSidePanel]);

  useEffect(() => {
    if (!enableFeature) setDisplayAnnotationSidePanel(false);
  }, [enableFeature]);

  return {
    annotations,
    isLoadingAnnotations,
    createAnnotation,
    createAnnotationReply,
    displayAnnotationSidePanel,
    setDisplayAnnotationSidePanel,
    mentionsUserData,
    archiveAnnotation,
    enableFeature,
    isCreatingAnnotation,
    setIsCreatingAnnotation,
    openedAnnotationId,
    setOpenedAnnotationId,
    userName: user.displayName,
    isAdmin: hasAdminPrivileges,
    userId: user.arkUserId,
  } satisfies InferContextType<typeof AnnotationContext>;
};

export interface InsightNavigationItem {
  label: string;
  route: Routes;
  category: InsightCategory;
}

export const useInsightNavigationItems = () => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' });

  const navigationItems = useMemo(
    () =>
      [
        { label: t('growth'), route: Routes.InsightsGrowth, category: 'growth' },
        { label: t('marketingEfficiency'), route: Routes.InsightsMarketing, category: 'marketingEfficiency' },
        { label: t('retention'), route: Routes.InsightsRetention, category: 'retention' },
        { label: t('monetisation'), route: Routes.InsightsMonetisation, category: 'monetisation' },
        { label: t('financialStrength'), route: Routes.InsightsFinancialStrength, category: 'financialStrength' },
        { label: t('activity'), route: Routes.InsightsActivity, category: 'activity' },
        { label: t('scores'), route: Routes.InsightsScores, category: 'scores' },
      ] as const satisfies readonly InsightNavigationItem[],
    [t],
  );

  return navigationItems;
};

export const useChartReadyState = (
  chartKey: ChartKey,
  overrideConnectorCategories?: Exclude<ConnectorDefinitionCategory, 'custom_database'>[],
) => {
  const [{ insightsAvailable }, isLoadingMetadata] = useMetadata();

  const insightCategory = chartKeyInsightCategoryMap.get(chartKey);
  const { connectorCategories } = getRelatedDataSourceCategoriesByInsightCategory(insightCategory ?? null);

  const hasNeededData = insightsAvailable
    ? connectorCategories.every((category) => insightsAvailable[category]) ||
      overrideConnectorCategories?.some((category) => insightsAvailable[category])
    : false;

  const getChartState = ({
    hasError,
    isLoading,
  }: {
    hasError: () => boolean | undefined;
    isLoading: () => boolean | undefined;
  }) => {
    return getChartReadyState({
      hasError,
      hasNoData: () => !hasNeededData && !isLoadingMetadata,
      isLoading: () => isLoadingMetadata || isLoading(),
    });
  };

  return { hasNeededData, getChartState };
};

export const useChartReadyStateForValidationCharts = (
  connectorCategories: Exclude<ConnectorDefinitionCategory, 'custom_database'>[],
) => {
  const [{ insightsAvailable }, isLoadingMetadata] = useMetadata();

  const hasNeededData = insightsAvailable
    ? connectorCategories.every((category) => insightsAvailable[category])
    : false;

  const getChartState = ({
    hasError,
    isLoading,
  }: {
    hasError: () => boolean | undefined;
    isLoading: () => boolean | undefined;
  }) => {
    return getChartReadyState({
      hasError,
      hasNoData: () => !hasNeededData && !isLoadingMetadata,
      isLoading: () => isLoadingMetadata || isLoading(),
    });
  };

  return { hasNeededData, getChartState };
};
