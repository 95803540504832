export const USER_ROLES = ['OWNER', 'ADMIN', 'FINANCIAL_CONTROLLER', 'DECK_VIEWER'] as const;
export type Role = (typeof USER_ROLES)[number];

export const getUserPermissions = (role?: Role) => ({
  canEditKeyFinancialsSettings: role === 'ADMIN',
  canViewCovenants: role && ['ADMIN', 'FINANCIAL_CONTROLLER'].includes(role),
  canDownloadLoanDocuments: role === 'ADMIN',
  canReviewLoanApplication: role === 'ADMIN',
  canEditAnnotations: role && ['OWNER', 'ADMIN', 'FINANCIAL_CONTROLLER'].includes(role),
  canEditLoanApplication: role && ['OWNER', 'ADMIN', 'FINANCIAL_CONTROLLER'].includes(role),
  hasAdminPrivileges: role === 'ADMIN',
  canEditCovenants: role === 'FINANCIAL_CONTROLLER',
  hasDeckViewerRole: role === 'DECK_VIEWER',
  canEditSubscriptionPlan: role && ['OWNER', 'ADMIN', 'FINANCIAL_CONTROLLER'].includes(role),
  loanEngineFeatureEnabled: role === 'ADMIN',
  canDeleteInvestorNetworkRequests: role === 'ADMIN',
  hasSpacesEnabled: role === 'ADMIN',
});

export const isGilionEmployee = (email?: string) => email && email.match(/@gilion.com$/);

export const manageableRoles = {
  OWNER: ['OWNER'],
  ADMIN: USER_ROLES.filter((item) => item !== 'FINANCIAL_CONTROLLER' && item !== 'DECK_VIEWER'),
  FINANCIAL_CONTROLLER: ['OWNER'],
  DECK_VIEWER: ['DECK_VIEWER'],
} as const satisfies Record<Role, ReadonlyArray<Role>>;
