import { addDays, addMonths, differenceInDays, differenceInHours, differenceInMonths } from 'date-fns';

export const getTimeDistanceToToday = (date: number) => {
  const today = new Date();
  const dataReady = new Date(date);
  const monthsUntilDataReady = differenceInMonths(dataReady, today);
  const daysUntilDataReady = differenceInDays(dataReady, addMonths(today, monthsUntilDataReady));
  const hoursUntilDataReady = differenceInHours(
    dataReady,
    addDays(addMonths(today, monthsUntilDataReady), daysUntilDataReady),
  );
  return [monthsUntilDataReady, daysUntilDataReady, hoursUntilDataReady];
};
