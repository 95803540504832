import { useState, useEffect, useCallback, useMemo } from 'react';
import { AccordionContext } from './AccordionContext';
import type { MultipleAccordionProps } from './types';
import { ACCORDION_TYPE, ACCORDION_ICON_POSITION } from './types';

/** An accordion component that allows **multiple** accordion items to be expanded at the same time. */
export const MultipleAccordion = <TAccordionId extends string = string>({
  expandedIds,
  iconPosition,
  children,
  onChange,
}: MultipleAccordionProps<TAccordionId>) => {
  const [expandedItemIds, setExpandedItemIds] = useState<TAccordionId[]>(expandedIds ?? []);

  useEffect(() => {
    if (expandedIds) setExpandedItemIds(expandedIds);
  }, [expandedIds]);

  useEffect(() => {
    if (onChange) onChange(expandedItemIds);
  }, [expandedItemIds, onChange]);

  const toggleId = useCallback((id: TAccordionId) => {
    setExpandedItemIds((prevIds) => {
      return prevIds.includes(id) ? prevIds.filter((prevId) => prevId !== id) : [...prevIds, id];
    });
  }, []) as (id: string) => void;

  const contextValue = useMemo(() => {
    return {
      type: ACCORDION_TYPE.MULTIPLE,
      expandedIds: expandedItemIds,
      toggleId,
      iconPosition: iconPosition ?? ACCORDION_ICON_POSITION.RIGHT,
    };
  }, [expandedItemIds, toggleId, iconPosition]);

  return <AccordionContext.Provider value={contextValue}>{children}</AccordionContext.Provider>;
};
