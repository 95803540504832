import type { HTMLAttributes, ReactNode } from 'react';
import React from 'react';
import type { Annotation } from '../types';
import './AnnotationCard.css';
import { Avatar } from '../../Avatar';
import { Typography } from '../../Typography';
import { formatRelativeDate } from 'aim-utils';

interface AnnotationCardProps extends HTMLAttributes<HTMLDivElement> {
  author: string;
  arkUserId: string;
  date: Date;
  contentParts: Annotation['contentParts'];
}

export const AnnotationCard = ({ author, arkUserId, date, contentParts, ...props }: AnnotationCardProps) => {
  return (
    <div className='annotation-card' {...props}>
      <AnnotationHeader
        author={author}
        date={date}
        avatar={<Avatar name={author} size='small' arkUserId={arkUserId} />}
      />
      <AnnotationContent contentParts={contentParts} />
    </div>
  );
};

interface AnnotationHeaderProps {
  author: string;
  avatar?: ReactNode;
  date: Date;
  numberOfReplies?: number;
  isThread?: boolean;
}

const AnnotationHeader = ({ author, avatar, date, numberOfReplies = 0, isThread }: AnnotationHeaderProps) => {
  return (
    <div className='annotation-header'>
      <div className='annotation-header-name' data-is-thread={isThread}>
        {avatar && <div className='annotation-card-avatar'>{avatar}</div>}
        <div className='annotation-header-text'>
          <Typography variant='label3'>{author}</Typography>
          <span className='annotation-header-subtitle'>
            {numberOfReplies > 0 && (
              <Typography className='annotation-header-number-of-replies' variant='text2'>
                {numberOfReplies} {numberOfReplies > 1 ? 'replies' : 'reply'}
              </Typography>
            )}
            <Typography variant='text2' color='secondary'>
              {formatRelativeDate(date, new Date())}
            </Typography>
          </span>
        </div>
      </div>
    </div>
  );
};

interface AnnotationContentProps {
  contentParts: Annotation['contentParts'];
  truncate?: boolean;
}

const AnnotationContent = ({ contentParts, truncate }: AnnotationContentProps) => {
  return (
    <div className='annotation-content'>
      <Typography variant='text1' truncate={truncate} as='span'>
        {contentParts.map((part, index) => {
          if (typeof part === 'string') {
            return (
              <Typography key={index} variant='text1' as='span'>
                {part}
              </Typography>
            );
          }
          return (
            <Typography key={index} variant='text1' color='primaryBlue' as='span'>
              @{part.fullName}
            </Typography>
          );
        })}
      </Typography>
    </div>
  );
};

AnnotationCard.Header = AnnotationHeader;
AnnotationCard.Content = AnnotationContent;
