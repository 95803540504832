import { differenceInDays, differenceInMonths, differenceInQuarters } from 'date-fns';
import { toLocalDate } from '../../../utils';
import type { xAxisFormat } from '../types';
import type { Label } from './axis-utils';
import {
  fitByModuloExclusion,
  shouldIncludeYearInFirstLabel,
  fitByLabelPriorityLevels,
  hasOverlappingLabels,
} from './axis-utils';
import { getDateChanges, type DateChanges } from './date-changes';
import { isDefined } from 'aim-utils';

interface FormatterParameters {
  labels: string[];
  dateChanges: DateChanges;
  width: number;
  xMin?: string;
}

const yearlyAxisFormatter = ({ labels, dateChanges, width }: FormatterParameters): Label[] => {
  const newLabels = labels.map((_, index) => {
    const { year } = dateChanges[index] ?? {};
    return year?.toString();
  });

  return fitByModuloExclusion(newLabels, width);
};

const quarterlyAxisFormatter = ({ labels, dateChanges, width }: FormatterParameters): Label[] => {
  const includeFirstYear = shouldIncludeYearInFirstLabel(labels);

  const newLabels = labels.map((_, index) => {
    const { year, quarter } = dateChanges[index] ?? {};
    if (index === 0 && !includeFirstYear) return quarter;
    if (year && quarter) return [quarter, year];
    return quarter;
  });

  const QUARTERLY_PRIORITY_LEVELS = [
    ['Q1', 'Q3'], // Half year quarters
    ['Q1'],
  ];

  return fitByLabelPriorityLevels(newLabels, QUARTERLY_PRIORITY_LEVELS, width);
};

const monthlyAxisFormatter = ({ labels, dateChanges, width }: FormatterParameters): Label[] => {
  const includeFirstYear = shouldIncludeYearInFirstLabel(labels);

  const newLabels = labels.map((_, index) => {
    const { year, month } = dateChanges[index] ?? {};
    if (index === 0 && !includeFirstYear) return month;
    if (year && month) return [month, year];
    return month;
  });

  const MONTHLY_PRIORITY_LEVELS = [
    ['Jan', 'Apr', 'Jul', 'Oct'], // Quarterly months
    ['Jan', 'Jul'], // Half year months
    ['Jan'],
  ];

  return fitByLabelPriorityLevels(newLabels, MONTHLY_PRIORITY_LEVELS, width);
};

const weeklyAxisFormatter = ({ labels, dateChanges, width }: FormatterParameters): Label[] => {
  const newLabels = labels.map((_, index) => {
    const { isoYear, isoWeek } = dateChanges[index] ?? {};
    if (isoYear && isoWeek) return [isoWeek, isoYear];
    return isoWeek?.toString();
  });

  const WEEKLY_PRIORITY_LEVELS = [
    ['13', '26', '39'], // Quarterly weeks
  ];

  return fitByLabelPriorityLevels(newLabels, WEEKLY_PRIORITY_LEVELS, width);
};

const dailyAxisFormatter = ({ labels, width }: FormatterParameters): Label[] => {
  if (width === 0) return [];

  const labelDates = labels.map((label) => toLocalDate(new Date(label)));

  // * Fits labels by keeping every nth date, using modulo. I.e. starts out with every date, then every second, third, etc.
  for (let modulo = 1; modulo <= labelDates.length; modulo++) {
    // * "Excludes" certain dates by setting them to undefined, based on the modulo.
    const datesWithExcludedSlots = labelDates.map((label, i) => {
      return i % modulo === 0 ? label : undefined;
    });

    // * Calculates date changes for the preserved dates, i.e. those that are not excluded/undefined.
    const preservedDates = datesWithExcludedSlots.filter(isDefined);
    const dateChanges = getDateChanges(preservedDates);

    let dateChangeIndex = 0;

    const formattedLabels = datesWithExcludedSlots.map((date) => {
      if (!date) return;

      const { year, month, day } = dateChanges[dateChangeIndex] ?? {};
      dateChangeIndex++;

      if (year && month && day) return [day, `${month} ${year}`];
      if (month && day) return [day, month];
      return day;
    });

    if (hasOverlappingLabels(formattedLabels, width)) continue;

    return formattedLabels;
  }

  return [];
};

const relativeDailyFormatter = ({ labels, width, xMin }: FormatterParameters): Label[] => {
  const startDate = toLocalDate(new Date(xMin ?? 0));
  const newLabels = labels.map((label) => differenceInDays(toLocalDate(new Date(label)), startDate).toString());
  return fitByModuloExclusion(newLabels, width);
};

const relativeMonthlyFormatter = ({ labels, width, xMin }: FormatterParameters): Label[] => {
  const startDate = toLocalDate(new Date(xMin ?? 0));
  const newLabels = labels.map((label) => differenceInMonths(toLocalDate(new Date(label)), startDate).toString());
  return fitByModuloExclusion(newLabels, width);
};

const relativeQuarterlyFormatter = ({ labels, width, xMin }: FormatterParameters): Label[] => {
  const startDate = toLocalDate(new Date(xMin ?? 0));
  const newLabels = labels.map((label) => differenceInQuarters(toLocalDate(new Date(label)), startDate).toString());
  return fitByModuloExclusion(newLabels, width);
};

export const formatters: Record<xAxisFormat, (parameters: FormatterParameters) => Label[]> = {
  years: yearlyAxisFormatter,
  quarters: quarterlyAxisFormatter,
  months: monthlyAxisFormatter,
  weeks: weeklyAxisFormatter,
  days: dailyAxisFormatter,
  daysRelative: relativeDailyFormatter,
  monthsRelative: relativeMonthlyFormatter,
  quartersRelative: relativeQuarterlyFormatter,
};
