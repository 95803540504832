import type { IndexOfTupleItem } from 'aim-utils';

export const SubscriptionTier = {
  Free: 'tier-0',
  Plus: 'tier-1',
  Ultimate: 'tier-2',
} as const satisfies Record<string, `tier-${number}`>;

export const SUBSCRIPTION_PLAN_TIERS = [
  SubscriptionTier.Free,
  SubscriptionTier.Plus,
  SubscriptionTier.Ultimate,
] as const;

type TiersTuple = typeof SUBSCRIPTION_PLAN_TIERS;
export type SubscriptionPlanTier = TiersTuple[number];

export type PreviousSubscriptionPlanTier<TTier extends SubscriptionPlanTier> = [never, ...TiersTuple][IndexOfTupleItem<
  TiersTuple,
  TTier
>];
