import type { ComponentProps, ReactNode } from 'react';
import React, { forwardRef } from 'react';
import styles from './Expand.module.css';

interface ExpandProps extends ComponentProps<'div'> {
  expanded?: boolean;
  duration?: number;
  children: ReactNode;
}

export const Expand = forwardRef<HTMLDivElement, ExpandProps>(
  ({ expanded, duration = 250, children, ...props }, ref) => {
    return (
      <div
        className={styles.autoHeightExpandContainer}
        aria-expanded={expanded}
        style={{
          transitionDuration: `${duration}ms`,
        }}
        {...props}
        ref={ref}
      >
        <div className={styles.expandedContent}>{children}</div>
      </div>
    );
  },
);

Expand.displayName = 'Expand';
