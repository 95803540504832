import type { ReactElement, ReactNode, ChangeEvent, MouseEvent } from 'react';
import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';

import './Checkbox.css';
import { Icon } from '../../Icon';

export interface CheckboxProps {
  initialValue?: boolean;
  onChange?: (checked: boolean) => void;
  name?: string;
  children?: ReactNode;
  disabled?: boolean;
}

interface CheckboxIconProps {
  checked: boolean;
}

const CheckboxIcon = ({ checked }: CheckboxIconProps): ReactElement | null => {
  if (checked) return <Icon icon='success' className='Checkbox-checkmark' />;
  return null;
};

export const Checkbox = ({ initialValue, onChange, name, children, disabled }: CheckboxProps): ReactElement => {
  const [checked, setChecked] = useState(Boolean(initialValue));
  const ref = useRef<null | HTMLSpanElement>(null);

  const updateFocusStyle = (focusing: boolean): void => {
    const focusClassName = ' focus';
    if (ref.current) {
      if (focusing) ref.current.className = ref.current.className + focusClassName;
      else ref.current.className = ref.current.className.replace(focusClassName, '');
    }
  };

  useEffect(() => {
    setChecked(Boolean(initialValue));
  }, [initialValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(e.target.checked);
    setChecked(e.target.checked);
  };

  const onLabelClick = (e: MouseEvent<HTMLLabelElement>): void => {
    e.stopPropagation();
  };

  return (
    <label
      className='Checkbox-label'
      onFocus={() => updateFocusStyle(true)}
      onBlur={() => updateFocusStyle(false)}
      onClick={onLabelClick}
      htmlFor={name}
    >
      <span className={cn('Checkbox-hover', disabled && 'disabled')}>
        <input
          type='checkbox'
          role='checkbox'
          name={name}
          checked={checked}
          onChange={(e) => !disabled && handleChange(e)}
          disabled={disabled}
          id={name}
        />
        <span
          className={cn('Checkbox-input', checked && 'checked', disabled && 'disabled')}
          ref={ref}
          data-testid='checkbox-span'
        >
          <CheckboxIcon checked={checked} />
        </span>
      </span>
      {children && <div className={cn('Checkbox-labelText', disabled && 'disabled')}>{children}</div>}
    </label>
  );
};
