import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import DOMPurify from 'dompurify';
import styles from './Description.module.css';

export interface DescriptionProps {
  html?: string;
  children?: ReactNode;
}

export const Description = ({ html, children }: DescriptionProps): ReactElement => {
  if (!html) return <div className={styles.description}>{children}</div>;
  const sanitizedHtml = DOMPurify.sanitize(html);
  return (
    <div className={styles.description} data-testid='description' dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  );
};
