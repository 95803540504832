import { useContext, useMemo } from 'react';
import type {
  ChartArea,
  ChartOptions,
  ChartType,
  Point,
  ScaleOptions,
  TooltipItem,
  TooltipPositionerFunction,
  TooltipXAlignment,
  Chart as ChartJS,
  ChartTypeRegistry,
  BubbleDataPoint,
} from 'chart.js';
import { Tooltip } from 'chart.js';
import { sv } from 'date-fns/locale';
import type { Annotation } from '../Annotation';
import type { Option } from '../Input/Select/Select';
import type { ChartDataset, ChartOptionsWithCustomOptions, ChartXAxis, ChartYAxis, TooltipDataPoint } from './types';
import { xAxisDateFormatter } from './x-axis-date-formatter/x-axis-date-formatter';
import 'chartjs-adapter-date-fns';
import { dateToString, toLocalDate } from '../../utils';
import { currencyFormatter, formatValue, valueFormatter } from '../../utils/utils';
import type { Context } from 'chartjs-plugin-datalabels';
import { ChartKeyContext, LineColors, addOpacityToHexColor, getColorByYearAndMonth, isDefined } from 'aim-utils';

type ColorTheme = 'dark' | 'light';
interface ChartColorData {
  axisText: string;
  grid: string;
  dataLabel?: string;
  gradientBlue: string;
  fillGreen: string;
  fillRed: string;
  referenceLine: string;
  lineDatalabelText: string;
  lineDatalabelBackground: string;
  pointBackground: string;
}

// TODO: Switch to use context or localStorage?
const COLOR_THEME: ColorTheme = 'dark';

enum Color {
  AimGrey500 = '#737373',
  AimGrey200 = '#DADADA',
  AimTextSecondary = 'rgba(255, 255, 255, 0.66)',
  DarkChartGrid = 'rgba(218, 218, 218, 0.2)',
  LightChartGrid = '#DBE9FF',
  GradientBlue = 'rgba(0,97,255,0.15)',
  FillGreen = 'rgba(51,227,121,0.6)',
  FillRed = 'rgba(252,44,68,0.6)',
  DarkChartReferenceLine = '#303030',
  LightChartReferenceLine = '#E3E3E3',
  White = '#FFFFFF',
  AimElevation01Dp = '#202020',
}

const chartColorThemeMap: Record<ColorTheme, ChartColorData> = {
  dark: {
    axisText: Color.AimGrey200,
    grid: Color.DarkChartGrid,
    dataLabel: Color.AimTextSecondary,
    gradientBlue: Color.GradientBlue,
    fillGreen: Color.FillGreen,
    fillRed: Color.FillRed,
    referenceLine: Color.DarkChartReferenceLine,
    lineDatalabelText: Color.White,
    lineDatalabelBackground: Color.AimElevation01Dp,
    pointBackground: Color.White,
  },
  light: {
    axisText: Color.AimGrey500,
    grid: Color.LightChartGrid,
    dataLabel: undefined,
    gradientBlue: Color.GradientBlue,
    fillGreen: Color.FillGreen,
    fillRed: Color.FillRed,
    referenceLine: Color.LightChartReferenceLine,
    lineDatalabelText: Color.AimGrey500,
    lineDatalabelBackground: Color.White,
    pointBackground: Color.White,
  },
};

export const chartColorData: ChartColorData = chartColorThemeMap[COLOR_THEME];

declare module 'chart.js' {
  interface Chart {
    /**
     * Gilion specific extension of ChartJS, for attaching custom data to charts.
     *
     * ! **Use with caution / when no other reasonable alternative is available.** ⛔️
     */
    customData?: Partial<{
      hoveredDataLabel?: { datasetIndex: number; dataIndex: number };
    }>;
  }

  interface TooltipPositionerMap {
    /** Custom positioning setup */
    aimCustomPositioner: TooltipPositionerFunction<ChartType>;
  }
}

Tooltip.positioners.aimCustomPositioner = function (elements, eventPosition) {
  const { x, y } = eventPosition;
  const chart = this.chart;
  const mouseOffset = 70;

  const overXThreshold = x >= chart.width / 2;

  let xAdjust = mouseOffset;
  let xAlign: TooltipXAlignment = 'left';
  if (overXThreshold) {
    xAdjust = -(mouseOffset * 2); // Double mouse offset since we add one again on return
    xAlign = 'right';
  }

  return { x: x + xAdjust, y, xAlign };
};
// End of custom positioning setup

export function getChartOptions(
  xAxisInput: ChartXAxis,
  yAxisInput: ChartYAxis,
  y1AxisInput: ChartYAxis,
  customOptions?: ChartOptionsWithCustomOptions['customOptions'],
  overrideOptions?: ChartOptions,
): ChartOptionsWithCustomOptions {
  const xAxis = {
    title: '',
    unit: '',
    linear: undefined,
    offset: undefined,
    fontSize: undefined,
    display: true,
    min: undefined,
    max: undefined,
    xMin: undefined,
    ...xAxisInput,
  };

  const yAxis = {
    title: '',
    unit: '',
    stacked: undefined,
    fontSize: undefined,
    display: true,
    highlightNegativeArea: false,
    ...yAxisInput,
  };

  const y1Axis = {
    display: false,
    highlightNegativeArea: false,
    ...y1AxisInput,
  };

  // TODO: Not the most elegant, using as hot-fix
  const interactionMode =
    customOptions?.displayMobileTooltip === true
      ? 'nearest'
      : customOptions?.displayMobileTooltip === false
        ? null
        : undefined;

  const xScaleOptions = {
    min: xAxis.min,
    max: xAxis.max,
    grid: { display: false },
    display: xAxis.display,
    ticks: {
      color: chartColorData.axisText,
      display: xAxis.ticks?.display ?? true,
      ...(xAxis.unit && {
        callback(tickValue) {
          return currencyFormatter(xAxis.unit, +tickValue, 0);
        },
      }),
      font: {
        size: xAxis.fontSize,
      },
      maxRotation: xAxis.ticks?.maxRotation ?? 0,
      autoSkip: xAxis.ticks?.autoSkip ?? false,
      padding: 8,
      ...(xAxis.linear !== true && {
        source: 'labels',
      }),
    },
    afterTickToLabelConversion(axis) {
      if (xAxis.ticks?.formatter?.type) {
        const ticks = axis.ticks;
        const tickLabels = ticks.map((tick) => tick.label) as string[]; // TODO: Remove as string[]
        const { type, hideLabels } = xAxis.ticks.formatter;
        const newLabels = xAxisDateFormatter(tickLabels, type, hideLabels, axis.width, xAxis.xMin);
        axis.ticks = ticks.map((tick, index) => ({ ...tick, label: newLabels[index] }));
      }
    },
    stacked: true,
    ...(xAxis.offset && { offset: true }),
    ...(xAxis.linear === true ? { type: 'linear' } : { type: 'time' }),
    ...(xAxis.linear !== true && {
      adapters: {
        date: {
          locale: sv,
        },
      },
      time: {
        tooltipFormat: 'yyyy-MM-dd',
        displayFormats: {
          year: 'yyyy-MM-dd',
          month: 'yyyy-MM-dd',
          day: 'yyyy-MM-dd',
          week: 'yyyy-MM-dd',
          quarter: 'yyyy-MM-dd',
          hour: 'yyyy-MM-dd',
        },
        parser: function (date: string) {
          const newDate = new Date(date);
          return toLocalDate(newDate);
        },
      },
    }),
  } as ScaleOptions;

  return {
    customOptions,
    interaction: {
      // Need to do a ts ignore here because typescript does not like us to have mode === null but it is the only way to disable the tooltip
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mode: interactionMode,
      axis: 'x',
      intersect: false,
    },
    maintainAspectRatio: false,
    scales: {
      x: { ...xScaleOptions, afterFit: (axis) => xAxis.fixedHeight && (axis.height = xAxis.fixedHeight) },
      x1: { ...xScaleOptions, display: false },
      x2: { ...xScaleOptions, display: false },
      y: getYAxisOptions(yAxis, 'left'),
      y1: getYAxisOptions(y1Axis, 'right'),
    },
    layout: {
      padding: {
        top: 25,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: () => {},
        position: 'aimCustomPositioner',
      },
      legend: { display: false },
      annotation: {
        animations: {
          numbers: {
            properties: [],
            type: 'number',
          },
        },
        annotations: {
          highlightBelowZeroStroke: {
            type: 'line',
            display: (ctx) =>
              (yAxis?.highlightNegativeArea === true && ctx.chart.scales.y.min < 0) ||
              (y1Axis?.highlightNegativeArea === true && ctx.chart.scales.y1.min < 0),
            yMax: 0,
            yMin: 0,
            yScaleID: yAxis?.highlightNegativeArea ? 'y' : 'y1',
            borderColor: addOpacityToHexColor(LineColors['vivid-red'], 0.6),
            borderWidth: 1,
            drawTime: 'beforeDatasetsDraw',
          },
        },
      },
    },
    transitions: {
      resize: {
        animation: {
          duration: 500,
        },
      },
    },
    ...overrideOptions,
  };
}

export const mapDataPoints = (d: TooltipItem<'bar' | 'line' | 'scatter'>): TooltipDataPoint => {
  const xValue = d.raw ? (d.raw as { x: number }).x : d.parsed.x;
  const yValue = d.raw ? (d.raw as { y: number }).y : d.parsed.y;
  const date = d.raw ? (d.raw as { date: string }).date : undefined; // complains about typing if written like date = d.raw && ...

  return {
    type: d.dataset.type,
    label: d.dataset.label,
    color: d.dataset.borderColor?.toString(),
    value: yValue,
    point: date ? { x: xValue, y: yValue, date } : { x: xValue, y: yValue },
    datasetUnit: (d.dataset as unknown as ChartDataset).datasetUnit,
    dataIndex: d.dataIndex,
    datasetIndex: d.datasetIndex,
    forecastColor: (d.dataset as { forecastColor?: string }).forecastColor,
  };
};

export const getYAxisTitle = (
  singleDatasetOption: boolean | undefined,
  filteredData: Option[],
  title?: string,
): string => {
  if (title && title !== '') return title;
  if (singleDatasetOption && filteredData?.length > 0) return filteredData[0]?.label ?? '';
  return '';
};

const getZeroLine = (dataExtent?: number[]): number => {
  if (!dataExtent) return 0;

  const zeroLine =
    dataExtent[0] < 0 ? Math.abs(dataExtent[0]) / (Math.abs(dataExtent[0]) + Math.abs(dataExtent[1])) : 0;

  return zeroLine !== undefined && !Number.isNaN(zeroLine) ? zeroLine : 0;
};

export const getYMax = (
  chartRef: ChartJS<
    keyof ChartTypeRegistry,
    (number | Point | [number, number] | BubbleDataPoint | null)[],
    unknown
  > | null,
  dataset: ChartDataset,
) => {
  const xScale = chartRef?.scales.x;
  const xExtent = xScale ? [xScale.min, xScale.max] : undefined;

  const yPoints = dataset.data
    .filter(
      (point) => !xExtent || (new Date(point.x) < new Date(xExtent[1]) && new Date(point.x) > new Date(xExtent[0])),
    )
    .filter((point) => point.y !== null)
    .map((point) => point.y as number);

  return yPoints.length !== 0 ? Math.max(...yPoints) : undefined;
};

export function createGradient(
  ctx: CanvasRenderingContext2D,
  color: string,
  direction: 'horizontal' | 'vertical',
  reversed: boolean,
  dataExtent?: number[],
  chartHeight?: number,
  yMax?: number,
): CanvasGradient {
  const zeroLine = getZeroLine(dataExtent);

  let relativeY = 1;
  if (dataExtent && yMax && yMax < dataExtent[1]) {
    relativeY = yMax / dataExtent[1];
  }

  const gradient =
    direction === 'vertical'
      ? ctx.createLinearGradient(0, 0, 0, chartHeight ?? ctx.canvas.clientHeight)
      : ctx.createLinearGradient(0, 0, ctx.canvas.clientWidth, 0);

  if (reversed) {
    gradient.addColorStop(1 - zeroLine, addOpacityToHexColor(color, 0.05));
    gradient.addColorStop(1, addOpacityToHexColor(color, 0.5));
  } else {
    gradient.addColorStop(1 - Math.max(Math.min(relativeY + zeroLine, 1), 0), addOpacityToHexColor(color, 0.7));
    gradient.addColorStop(1 - zeroLine, addOpacityToHexColor(color, 0.05));
  }

  return gradient;
}

export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export function getScatterPointColor(date: string | undefined | null): string {
  if (!date) {
    return LineColors['cool-grey'];
  }
  const yearIndex = new Date().getUTCFullYear() - new Date(date).getUTCFullYear();
  const monthIndex = 11 - new Date(date).getUTCMonth();

  return getColorByYearAndMonth(yearIndex, monthIndex);
}

export function getDataLength(data: ChartDataset['data']): number {
  if (Array.isArray(data)) {
    return data.length;
  }
  return Object.keys(data).length;
}

export function getNumberOfBars(list: { x: string | number; y: number }[] | Record<string, unknown>[]): number {
  const bars: string[] = [];
  list.forEach((item) => {
    const label = item.x as string;
    !bars.includes(label) && bars.push(label);
  });
  return bars.length;
}

export const getBackgroundColor = (
  isDatasetHovered: boolean,
  d: ChartDataset,
  chartRef?: ChartJS | null,
): string | CanvasGradient => {
  if (isDatasetHovered && d.hoverColor) return d.hoverColor;
  if (d.shadedFill && chartRef?.ctx) {
    const yScale = chartRef.scales.y;
    const dataExtent = yScale ? [yScale.min, yScale.max] : undefined;

    return createGradient(
      chartRef?.ctx,
      d.fillColor ?? d.color,
      d.shadedFill.direction ?? 'vertical',
      d.shadedFill.reversed ?? false,
      dataExtent,
      chartRef.chartArea.height,
    );
  }
  return d.fillColor ?? d.color;
};

export function getScatterPointColors(dataset: ChartDataset): string[] {
  return dataset.data.map((item) => getScatterPointColor(item.date));
}

export function getChartType(
  datasets: ChartDataset[],
): [isScatterPlot: boolean, isLineChart: boolean, isBarChart: boolean] {
  const isScatterPlot = datasets.some((d) => d.type === 'scatter');
  const isAreaChart = datasets.every((d) => d.type === 'line' && d.fill && !d.shadedFill);
  const isBarChart = datasets.some((d) => d.type === 'bar');

  return [isScatterPlot, isAreaChart, isBarChart];
}

export function getYAxisUnit(
  singleDatasetOption: boolean | undefined,
  datasets: ChartDataset[],
  selectedOptions: Option[],
  yAxis?: ChartYAxis,
): string {
  if (singleDatasetOption && datasets && selectedOptions?.length > 0) {
    const filteredDataset = datasets.find((d) => d.label === selectedOptions[0]?.label);
    return filteredDataset?.datasetUnit ?? '';
  }

  return yAxis?.unit ?? '';
}

export const getUniqueMonths = (arr: string[]): string[] => {
  const map: Record<string, boolean> = {};
  const res: string[] = [];
  for (let i = 0; i < arr.length; i++) {
    const date = new Date(arr[i]);
    const key = `${date.getUTCFullYear()}${date.getUTCMonth()}`;
    if (!map[key]) {
      map[key] = true;
      res.push(arr[i]);
    }
  }

  return new Date(arr[arr.length - 1]) > new Date(res[res.length - 1]) ? res.concat(arr[arr.length - 1]) : res;
};

export const sameMonth = (date1: string, date2: string): boolean => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  return (
    `${firstDate.getUTCFullYear()}${firstDate.getUTCMonth()}` ===
    `${secondDate.getUTCFullYear()}${secondDate.getUTCMonth()}`
  );
};

export const useGetHighlightedTicks = (filteredLabels: string[], annotations: Annotation[]) => {
  const chartKey = useContext(ChartKeyContext);
  return useMemo(() => {
    if (!(filteredLabels?.length > 0 && annotations?.length > 0)) {
      return [];
    }
    const highLightedSliderTicks: number[] = [];

    if (filteredLabels.length > 0) {
      annotations
        .filter((annotation) => annotation.insightId === chartKey)
        .forEach((annotation) => {
          const closestLabelToAnnotation = floorGetClosestTick(new Date(annotation.startDate), filteredLabels);
          const index = filteredLabels.findIndex((label) => label === closestLabelToAnnotation);

          if (index !== -1) {
            highLightedSliderTicks.push(index);
          }
        });
    }
    return highLightedSliderTicks;
  }, [annotations, chartKey, filteredLabels]);
};

export const getClosestTick = (goal: Date, array: Array<string | number>) => {
  if (!goal || !array || array.length === 0) {
    return 0;
  }

  return array.reduce((prev, curr) =>
    Math.abs(+new Date(curr) - +goal) < Math.abs(+new Date(prev) - +goal) ? curr : prev,
  );
};

export const getClosestTickIndex = (goalDateString: string, labels: Array<string | number>) => {
  const goalDate = new Date(goalDateString);
  const closestTick = getClosestTick(goalDate, labels);

  return labels.findIndex((label) => label === closestTick);
};

export const getClosestPointInArray = (goal: number, array: Array<string | number>) => {
  if (!goal || !array || array.length === 0) {
    return 0;
  }

  return array.reduce((prev, curr) => (Math.abs(+curr - +goal) < Math.abs(+prev - +goal) ? curr : prev));
};

export const getIndexClosestPointInArray = (goal: number, array: Array<string | number>) => {
  if (!goal || !array || array.length === 0) {
    return 0;
  }

  const closestPoint = array.reduce((prev, curr) => (Math.abs(+curr - +goal) < Math.abs(+prev - +goal) ? curr : prev));
  return array.findIndex((point) => point === closestPoint);
};

export const floorGetClosestTick = (goal: Date, array: string[]) =>
  array.reduce((prev, curr) => {
    if (+goal - +new Date(curr) < 0) {
      return prev;
    }
    return +goal - +new Date(curr) < +goal - +new Date(prev) ? curr : prev;
  });

export const areEqualChartAreas = (a: ChartArea | undefined, b: ChartArea | undefined): boolean => {
  return (
    a?.width === b?.width &&
    a?.height === b?.height &&
    a?.top === b?.top &&
    a?.right === b?.right &&
    a?.bottom === b?.bottom &&
    a?.left === b?.left
  );
};

const getDataPointsWithinBounds = (dataPoints: ChartDataset['data'], xMin?: string, xMax?: string) => {
  return dataPoints.filter(({ x }) => {
    const minBoundValid = xMin === undefined || x >= xMin;
    const maxBoundValid = xMax === undefined || x <= xMax;
    return minBoundValid && maxBoundValid;
  });
};

export const getMaxYValueInDatasets = (datasets: Pick<ChartDataset, 'data'>[], xMin?: string, xMax?: string) => {
  const maxYValuePerDataset = datasets.map(({ data }) => {
    const dataPointsWithinBounds = getDataPointsWithinBounds(data, xMin, xMax);
    const yValuesWithinBounds = dataPointsWithinBounds.map(({ y }) => y);
    return Math.max(...yValuesWithinBounds.filter(isDefined));
  });

  return Math.max(...maxYValuePerDataset);
};

export const getMinYValueInDatasets = (datasets: Pick<ChartDataset, 'data'>[], xMin?: string, xMax?: string) => {
  const minYValuePerDataset = datasets.map(({ data }) => {
    const dataPointsWithinBounds = getDataPointsWithinBounds(data, xMin, xMax);
    const yValuesWithinBounds = dataPointsWithinBounds.map(({ y }) => y);
    return Math.min(...yValuesWithinBounds.filter(isDefined));
  });

  return Math.min(...minYValuePerDataset);
};

export const extendDatasetWithLabels = (
  dataset: ChartDataset,
  datasetIndex: number,
  xMaxDateString: string | undefined,
  isStacked: boolean,
  maxIndex: number,
) => {
  let dataLabels = dataset.dataLabels ?? [];

  if (dataset.dataLabelsAtSliderMax && !isStacked && xMaxDateString) {
    const index = dataset.data.findIndex(
      (data) => dateToString(new Date(data.x)) === dateToString(new Date(xMaxDateString)),
    );
    if (index !== -1) {
      dataLabels = dataLabels.concat(index);
    } else {
      const lastDataPoint = dateToString(new Date(dataset.data.at(-1)?.x ?? xMaxDateString));
      const maxDataPoint = dateToString(new Date(xMaxDateString));
      if (dataset.data.at(-1) && lastDataPoint && maxDataPoint && lastDataPoint < maxDataPoint) {
        dataLabels = dataLabels.concat(dataset.data.length - 1);
      }
    }
  }

  if (dataset.dataLabelsAtSliderMax && isStacked && xMaxDateString) {
    if (datasetIndex === maxIndex) {
      dataLabels = dataLabels.concat(
        dataset.data.findIndex((data) => dateToString(new Date(data.x)) === dateToString(new Date(xMaxDateString))),
      );
    }
  }

  return {
    ...dataset,
    dataLabels,
  };
};

export const computeStack = (ctx: Context, unit: string | undefined) => {
  const datasetArray: Point[] = [];

  const { x } = ctx.dataset.data[ctx.dataIndex] as Point;

  ctx.chart.data.datasets
    .filter((dataset) => dataset.type === 'bar')
    .forEach((dataset) => {
      const point = (dataset.data as Point[]).find((point) => point.x === x);
      if (point != undefined) {
        datasetArray.push(point);
      }
    });
  const sum = datasetArray.reduce((total, dataPoint) => (dataPoint.y > 0 ? total + dataPoint.y : total), 0);
  return formatValue(sum, unit);
};

export const createGradientBarColor = (top: number, bottom: number, ctx: CanvasRenderingContext2D, color: string) => {
  const gradient = ctx.createLinearGradient(0, top, 0, bottom);
  gradient.addColorStop(0, addOpacityToHexColor(color, 0.7));
  gradient.addColorStop(1, addOpacityToHexColor(color, 0.2));
  return gradient;
};

/**
 * Ensures that slider start and end values are within the range of the slider, and that start is always less than end.
 */
export const getSafeSliderIndexRange = ({ start, end, maxIndex }: { start: number; end: number; maxIndex: number }) => {
  const safeStart = clamp(start, 0, maxIndex - 1);
  const safeEnd = clamp(end, safeStart + 1, maxIndex);

  return { safeStart, safeEnd };
};

const axisTitle = (title?: string, fontSize?: number, display = true, color?: string) =>
  ({
    text: title?.toLocaleUpperCase(),
    display,
    padding: 0,
    color: color ?? chartColorData.axisText,
    font: { size: fontSize ?? 10 },
  }) as const;

const getYAxisOptions = (yaxis: ChartYAxis, position: 'left' | 'right') => {
  return {
    type: 'linear' as const,
    position: position,
    min: yaxis.fixedMin,
    max: yaxis.fixedMax,
    suggestedMax: yaxis.suggestedMax,
    display: yaxis.display,
    grace: yaxis.noGrace !== true && yaxis.display ? '2%' : undefined,
    title: axisTitle(yaxis.title, yaxis.fontSize, true, yaxis.color),
    ticks: {
      color: yaxis.color ?? chartColorData.axisText,
      precision: 0,
      callback: (val: number, index: number, ticks: string | any[]) => {
        if (!yaxis.unit || yaxis.unit === ' ') {
          const numberFormatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            notation: 'compact',
            style: 'decimal',
          });
          return numberFormatter.format(val);
        }

        const formatter =
          yaxis.unit === '%' || yaxis.unit === 'x'
            ? new Intl.NumberFormat('en-US', {
                unit: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                notation: 'compact',
                style: 'unit',
              })
            : new Intl.NumberFormat('en-US', {
                currency: yaxis.unit,
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
                notation: 'compact',
                style: 'currency',
              });

        const value = yaxis.unit !== 'x' ? formatter.format(val) : valueFormatter(val, 'x');

        // Excludes any duplicated rounded tick values from the Y-axis
        if (index < ticks.length - 1) {
          const nextValue = formatter.format(ticks[index + 1].value as number);

          if (value === nextValue) {
            return null;
          }
        }

        return value;
      },
      padding: 8,
      font: {
        size: yaxis.fontSize,
      },
    },
    afterFit: (axis: { width: number }) => yaxis.fixedWidth && yaxis.display && (axis.width = yaxis.fixedWidth),
    grid: {
      color: yaxis.color ? addOpacityToHexColor(yaxis.color, 0.5) : chartColorData.grid,
      lineWidth: 0.5,
    },
    border: {
      display: false,
      dash: position === 'right' ? [1, 2000] : [3, 2],
      dashOffset: position === 'right' ? 1 : 0,
    }, // [1,2000] is used for only creating a small dash to the right
    beginAtZero: true,
    ...(yaxis.stacked && { stacked: true }),
  } as ScaleOptions;
};
