import React from 'react';
import type { SpringValue } from '@react-spring/web';
import { animated } from '@react-spring/web';
import { ClipPath } from '@visx/clip-path';
import { Arc } from '@visx/shape';

const ArcAnimated = animated(Arc);

interface ScorePathProps {
  endAngle: SpringValue<number> | number;
  score: number;
  outerRadius: number;
  innerRadius: number;
}

export const ScorePath = ({ endAngle, score, outerRadius, innerRadius }: ScorePathProps) => {
  return (
    <ClipPath id={`score-arc-solid-${score}`}>
      <ArcAnimated
        startAngle={-Math.PI / 2}
        endAngle={endAngle}
        height='100%'
        outerRadius={outerRadius}
        innerRadius={innerRadius}
        stroke='transparent'
        fill='url(#score-noise)'
        transform='translate(512, 512)'
      />
    </ClipPath>
  );
};
