import type { CountryCode } from 'aim-utils';
import { format, isToday, isYesterday } from 'date-fns';
import { Routes } from '@/lib/routes';
import type { InsightCategory } from 'aim-components';
import type { NotificationEventId } from '@/api/notifications/notificationFilterMap';

export enum NotificationGroups {
  'GROWTH',
  'ALL',
}

//TODO: add all ids from the backend here
export const notificationSettingReference = '14';

interface NotificationSetting {
  info: string;
  title: string;
}

// There is documentation on how to add a new notification event
// Read it here: https://docs.google.com/document/d/1pfZLtGdrESBISYmE7KH4xbaaSEy9yAYFA5BkxnyX2Pk/edit?usp=sharing
export const notificationMap = {
  '1': 'activeCustomers',
  '2': 'activeUsers',
  '4': 'marketingSpend',
  '5': 'customerIntake',
  '6': 'netSales',
  '7': 'recurringRevenue',
  '8': 'newCustomers',
  '9': 'gilionScore',
  '10': 'growthScore',
  '11': 'marketingEfficiencyScore',
  '12': 'montetisationScore',
  '13': 'retentionScore',
  '14': 'financialStrengthScore',
} as const satisfies Record<NotificationEventId, string>;

export const insightsMap = {
  growth: { route: Routes.InsightsGrowth },
  marketingEfficiency: { route: Routes.InsightsMarketing },
  retention: { route: Routes.InsightsRetention },
  monetisation: { route: Routes.InsightsMonetisation },
  financialStrength: { route: Routes.InsightsFinancialStrength },
  activity: { route: Routes.InsightsActivity },
  scores: { route: Routes.InsightsScores },
  benchmarking: { route: Routes.Benchmarking },
  dataValidation: { route: Routes.DataValidation },
} as const satisfies Record<InsightCategory, { route: Routes }>;

export const getNotificationSetting = (id: string): NotificationSetting => {
  switch (id) {
    default:
      return { info: '', title: '' };
  }
};

export const getNotificationGroup = <TGroup extends NotificationGroups>(notificationGroup: TGroup) => {
  const notificationGroupMap = {
    [NotificationGroups.ALL]: { info: '', title: 'allNotificationTitle' },
    [NotificationGroups.GROWTH]: { info: '', title: '' },
  } as const;

  return notificationGroupMap[notificationGroup];
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  if (!date || isNaN(date.getDate())) return '';

  if (isToday(date)) return 'today';
  if (isYesterday(date)) return 'yesterday';
  return format(date, 'd MMM yyyy');
};

export const getCountryChartFilter = (countryCodes: CountryCode[]) => {
  return countryCodes.length === 1 ? countryCodes[0] : 'ALL';
};
