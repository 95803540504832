import type { PreviousSubscriptionPlanTier, SubscriptionPlanTier } from '@/api/subscription-plans/types';
import { SUBSCRIPTION_PLAN_TIERS, SubscriptionTier } from '@/api/subscription-plans/types';
import type { SubscriptionProduct } from '@/api/user/subscription/products/index.page';
import type { SubscriptionPlan } from '@/api/user/subscription/subscriptions/utils';

export const findProductPriceByRecurringInterval = (
  prices: SubscriptionProduct['prices'],
  interval: SubscriptionProduct['prices'][number]['recurringInterval'],
) => {
  return prices.find((price) => price.recurringInterval === interval);
};

export const findSubscriptionProductByTier = (
  subscriptionProducts: SubscriptionProduct[],
  tier: SubscriptionPlanTier,
) => {
  return subscriptionProducts.find((plan) => plan.tier === tier);
};

export const getSubscriptionPlanTier = (subscriptionPlan: SubscriptionPlan | undefined) => {
  if (!subscriptionPlan || subscriptionPlan.items.length === 0) return SubscriptionTier.Free;

  return subscriptionPlan.items[0].product.tier;
};

export const getPreviousSubscriptionPlanTier = <TTier extends SubscriptionPlanTier>(tier: TTier) => {
  const currentIndex = SUBSCRIPTION_PLAN_TIERS.indexOf(tier);
  return SUBSCRIPTION_PLAN_TIERS[currentIndex - 1] as PreviousSubscriptionPlanTier<TTier>;
};

export const isSubscriptionPlanTierUpgrade = (newTier: SubscriptionPlanTier, currentTier: SubscriptionPlanTier) => {
  return SUBSCRIPTION_PLAN_TIERS.indexOf(newTier) > SUBSCRIPTION_PLAN_TIERS.indexOf(currentTier);
};

export const formatPrice = (price: number | undefined, currency: string | undefined) => {
  if (price === null || price === undefined || currency === undefined) return 'N/A';

  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
  }).format(price);
};

export const formatSubscriptionProductPrice = (productPriceData: SubscriptionProduct['prices'][number] | undefined) => {
  if (productPriceData === null || productPriceData === undefined) return 'N/A';

  const { unitAmount, currency, recurringInterval } = productPriceData;

  // * We want to display the price per month, so we divide by 12 if the recurring interval is yearly.
  const divisor = recurringInterval === 'year' ? 12 : 1;

  // * Stripe prices are in cents, so we divide by 100.
  return formatPrice(unitAmount / divisor / 100, currency);
};
