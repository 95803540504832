import type { TenantInfo } from '@/api/tenant/index.page';
import { createContext } from 'react';

export const TenantContext = createContext<TenantInfo & { loading?: boolean }>({
  countryCode: undefined,
  interestedInLoanSince: null,
  ownership: undefined,
  projectCode: undefined,
  createdAt: undefined,
  loading: true,
});

// Around 47 % will be in B and 53 % will be in A
export const getABGroup = (projectCode: string) => {
  const getNumber = (str: string) => {
    return str.split('').reduce((acc, char) => {
      return acc + char.charCodeAt(0);
    }, 0);
  };

  const isEven = (number: number) => {
    return number % 2 === 0;
  };
  return isEven(getNumber(projectCode)) ? 'B' : 'A';
};
