/* eslint-disable no-console */
import type { SciChartSurface, TSeriesDefinition } from 'scichart';
import { EChart2DModifierType, ESeriesType } from 'scichart';
import { SciChartReact } from 'scichart-react';
import React, { useRef } from 'react';

const generateXValues = (start: number, numberOfValues: number) => {
  const values: number[] = [];

  for (let i = 0; i < numberOfValues; i++) {
    values.push(start + i);
  }

  return values;
};

const generateYValues = (numberOfValues: number) => {
  const values: number[] = [];

  for (let i = 0; i < numberOfValues; i++) {
    values.push(Math.random() * 100);
  }

  return values;
};

const generateSeries = (numberOfSeries: number, numberOfValues: number) => {
  const series: TSeriesDefinition[] = [];

  for (let i = 0; i < numberOfSeries; i++) {
    series.push({
      type: ESeriesType.StackedMountainSeries,
      // @ts-expect-error TODO: Will fix before merge
      options: { stackedGroupId: 'StackedGroupId', fill: i % 2 ? '#882B91' : '#bbaadd' },
      xyData: { xValues: generateXValues(1992, numberOfValues), yValues: generateYValues(numberOfValues) },
    });
  }

  return series;
};

// Minimal example trying to use a `StackedMountainCollection` together with `SciChartOverview` – which does not seem to be supported.
export const RolloverPerformanceTest = () => {
  const seriesRef = useRef(generateSeries(50, 50));

  return (
    <>
      <div id='trash' style={{ display: 'none' }} />
      <SciChartReact<SciChartSurface>
        style={{
          width: 800,
          height: 400,
          position: 'relative',
          overflow: 'hidden',
          touchAction: 'none',
          fontFamily: 'serif',
        }}
        config={{
          series: [
            // Group StackedMountain into one StackedMountainCollection and pass into the series object
            {
              type: ESeriesType.StackedMountainCollection,
              series: seriesRef.current,
            },
          ],
          modifiers: [
            {
              type: EChart2DModifierType.Rollover,
              options: {
                showTooltip: false, // ! This does not really seem to hide the tooltips
                placementDivId: 'trash', // * Adding this "hack" seems to improve the performance quite significantly
              },
            },
          ],
        }}
      />
    </>
  );
};
