import { LineColors } from 'aim-utils';
import { useId } from 'react';
import './Legend.css';

const LEGEND_ICON_SIZE = 12;

const LineIcon = ({
  color,
  fillOpacity = 1,
  lineThickness = 2,
  size = LEGEND_ICON_SIZE,
}: {
  color?: string;
  fillOpacity?: number;
  lineThickness?: number;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='LegendIcon'
    >
      <line
        stroke={color ?? LineColors['cool-grey']}
        strokeWidth={lineThickness}
        x1='0'
        y1={size / 2}
        x2={size}
        y2={size / 2}
        fillOpacity={fillOpacity}
      ></line>
    </svg>
  );
};

const DashedLineIcon = ({
  color,
  fillOpacity = 1,
  lineThickness = 2,
  size = LEGEND_ICON_SIZE,
}: {
  color?: string;
  fillOpacity?: number;
  lineThickness?: number;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='LegendIcon'
    >
      <line
        stroke={color}
        strokeDasharray='3, 1'
        strokeWidth={lineThickness}
        x1='0'
        y1={size / 2}
        x2={size}
        y2={size / 2}
        fillOpacity={fillOpacity}
      ></line>
    </svg>
  );
};

const Dotted = ({ color, size = LEGEND_ICON_SIZE }: { color: string; size?: number }) => {
  const dotRadius = size / 10;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='LegendIcon'
    >
      <circle cx={dotRadius} cy={size / 2} r={dotRadius} fill={color} />
      <circle cx={size / 2} cy={size / 2} r={dotRadius} fill={color} />
      <circle cx={size - dotRadius} cy={size / 2} r={dotRadius} fill={color} />
    </svg>
  );
};

const Square = ({ color, size = LEGEND_ICON_SIZE }: { color?: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='LegendIcon'
    >
      <rect x='0' y='0' width={size} height={size} fill={color ?? LineColors['cool-grey']} />
    </svg>
  );
};

const Rect = ({
  color,
  size = LEGEND_ICON_SIZE,
  width = size,
  height = width / 2,
}: {
  color?: string;
  size?: number;
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='LegendIcon'
    >
      <rect x='0' y='0' width={width} height={height} fill={color ?? LineColors['cool-grey']} />
    </svg>
  );
};

const Triangle = ({ color, size = LEGEND_ICON_SIZE }: { color?: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='LegendIcon'
    >
      <polygon points={`0,${size / 2} ${size},0 ${size},${size}`} fill={color ?? LineColors['cool-grey']} />
    </svg>
  );
};

const Dot = ({ color, size = LEGEND_ICON_SIZE }: { color?: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='LegendIcon'
    >
      <circle cx={size / 2} cy={size / 2} r={size / 2} fill={color ?? LineColors['cool-grey']} />
    </svg>
  );
};

const ForecastLineIcon = ({
  lineThickness = 1.5,
  size = LEGEND_ICON_SIZE,
  fillOpacity = 1,
}: {
  fillOpacity?: number;
  lineThickness?: number;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={2 * size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      opacity={fillOpacity}
    >
      <rect x={0} y={size / 2 - lineThickness / 2 - 4} width={size} height={lineThickness} fill={LineColors.lime} />
      <rect x={0} y={size / 2 - lineThickness / 2} width={size} height={lineThickness} fill={LineColors.flamingo} />
      <rect x={0} y={size / 2 - lineThickness / 2 + 4} width={size} height={lineThickness} fill={LineColors.azure} />
    </svg>
  );
};

const LineShadedFillIcon = ({ color, size = LEGEND_ICON_SIZE }: { color: string; size?: number }) => {
  const gradientId = useId();

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='LegendIcon'
    >
      <defs>
        <linearGradient id={gradientId} x1={0} y1={0} x2={0} y2='100%'>
          <stop stopColor={color} stopOpacity={0.5} />
          <stop offset={1} stopColor={color} stopOpacity={0} />
        </linearGradient>
      </defs>
      <path d={`M0 0H${size}V${size}H0V0Z`} fill={`url(#${gradientId})`} />
      <rect x={0} y={0} width={size} height={2} fill={color} />
    </svg>
  );
};

const GradientRectIcon = ({ color, size = LEGEND_ICON_SIZE }: { color: string; size?: number }) => {
  const gradientId = useId();
  const width = 2 * size;

  return (
    <svg
      width={width}
      height={size}
      viewBox={`0 0 ${width} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='LegendIcon'
    >
      <defs>
        <linearGradient id={gradientId} x1={0} y1={0} x2={'100%'} y2={0}>
          <stop stopColor={color} stopOpacity={0.7} />
          <stop offset={1} stopColor={color} stopOpacity={0} />
        </linearGradient>
      </defs>
      <rect x='0' width={width} height={size} fill={`url(#${gradientId})`} />
    </svg>
  );
};

const GradientRectRoundIcon = ({ color, size = LEGEND_ICON_SIZE }: { color: string; size?: number }) => {
  const gradientId = useId();
  const width = 2 * size;

  return (
    <svg
      width={width}
      height={size}
      viewBox={`0 0 ${width} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='LegendIcon'
    >
      <defs>
        <linearGradient id={gradientId} x1={0} y1={0} x2={'100%'} y2={0}>
          <stop stopColor={color} stopOpacity={0.7} />
          <stop offset={1} stopColor={color} stopOpacity={0} />
        </linearGradient>
      </defs>
      <rect x='0' width={width} height={size} rx={6} fill={`url(#${gradientId})`} />
    </svg>
  );
};

export const LegendIcon = {
  Line: LineIcon,
  ForecastLines: ForecastLineIcon,
  LineShadedFill: LineShadedFillIcon,
  GradientRect: GradientRectIcon,
  GradientRectRound: GradientRectRoundIcon,
  Square,
  Dotted,
  DashedLine: DashedLineIcon,
  DottedLine: Dotted,
  Dot,
  Rect,
  Triangle,
};
