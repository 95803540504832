import { UserContext } from '@/components/authentication/UserContext';
import { getNewUrlAfterTenantSwitch } from '@/components/layouts/navigation/navigation-menu/tenant-selector/utils';
import { fetcher } from '@/lib/request/request';
import { setUserSetting } from '@/lib/settings';
import { deslugifyTenantName } from '@/lib/utils';
import { EMPTY_ARRAY_STATIC_REFERENCE } from 'aim-components';
import { useRouter } from 'next/router';
import { ApiRoutes } from 'pages/apiRoutes';
import type { ChangeEvent } from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

/**
 * The maximum number of *recently visited tenants* to show in the tenant selector.
 */
const MAX_NUMBER_OF_RECENT_TENANTS_TO_SHOW = 3;

/**
 * The minimum number of tenants needed to show the tenant search. 🔍
 *
 * This is the same as the maximum number of recent tenants to show, otherwise there would be tenants that could never be selected.
 */
export const MIN_NUMBER_OF_TENANTS_TO_SHOW_SEARCH = MAX_NUMBER_OF_RECENT_TENANTS_TO_SHOW;

export const useTenants = () => {
  const { recentTenants, tenant: userTenant } = useContext(UserContext);

  const { data: tenants = EMPTY_ARRAY_STATIC_REFERENCE, isLoading: isLoadingTenants } = useSWRImmutable(
    ApiRoutes.GetTenants,
    fetcher<string[]>,
  );

  const isLoading = isLoadingTenants || recentTenants.isLoading;

  const validRecentTenants = useMemo(() => {
    const recentTenantsWithoutCurrent = recentTenants.tenants.filter((tenant) => tenant !== userTenant);
    return recentTenantsWithoutCurrent.slice(0, MAX_NUMBER_OF_RECENT_TENANTS_TO_SHOW);
  }, [recentTenants.tenants, userTenant]);

  // ? All tenants except for the current tenant (e.g. those that the user can choose to switch to).
  const selectableTenants = useMemo(() => tenants.filter((tenant) => tenant !== userTenant), [tenants, userTenant]);

  const tenant = useMemo(() => deslugifyTenantName(userTenant ?? ''), [userTenant]);

  return { tenant, tenants, selectableTenants, recentTenants: validRecentTenants, isLoading } as const;
};

export const useTenantSearch = ({ tenants }: { tenants: string[] }) => {
  const [searchString, setSearchString] = useState('');
  const lowerCaseSearchString = searchString.toLowerCase();

  const filteredTenants = useMemo(() => {
    if (!lowerCaseSearchString) return tenants;

    return tenants.filter((tenant) => tenant.toLowerCase().includes(lowerCaseSearchString));
  }, [lowerCaseSearchString, tenants]);

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchString(e.target.value);
  }, []);

  const clearSearch = useCallback(() => setSearchString(''), []);

  const noMatchingResults = tenants.length > 0 && filteredTenants.length === 0;

  return { searchString, onSearchChange, clearSearch, filteredTenants, noMatchingResults } as const;
};

export const useSwitchTenant = () => {
  const router = useRouter();

  const switchTenant = useCallback(
    async (tenant: string) => {
      try {
        await setUserSetting('preferred-tenant', { id: tenant });

        const newUrl = getNewUrlAfterTenantSwitch({ slug: tenant, route: router.route });

        await router.push(newUrl);
        router.reload();
      } catch (error) {
        console.error(error);
      }
    },
    [router],
  );

  return { switchTenant } as const;
};
