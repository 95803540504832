import React from 'react';
import { NavigationMenu } from 'aim-components';

type SkeletonMenuContentProps = {
  sections: Array<{ items: number }>;
};

/**
 * Renders a skeleton loader for the menu content, with a number of sections and items per section 💀
 */
export const SkeletonMenuContent = ({ sections }: SkeletonMenuContentProps) => {
  return sections.map((section, i) => (
    <NavigationMenu.Section key={i}>
      <NavigationMenu.List>
        {Array.from({ length: section.items }).map((_, j) => (
          <NavigationMenu.Item.Skeleton key={j} animationDelayMs={j * -150} />
        ))}
      </NavigationMenu.List>
    </NavigationMenu.Section>
  ));
};
