import type { IncomingHttpHeaders } from 'http';
import type { ChartDataset } from 'aim-components';
import { toLocalDate, toUTCDate } from 'aim-components';
import {
  add,
  addQuarters,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  startOfQuarter,
  startOfWeek,
} from 'date-fns';
import type { AcquisitionGranularity, TimeGranularity } from '@/components/insights/charts/common/types';
import { roundToDecimals } from '@/components/insights/charts/common/utils';
import { arrowRequestIsEmpty, serverRequest } from '@/lib/request/request';
import { ExternalRoutes } from 'pages/apiRoutes';
import type { Table } from 'apache-arrow';

export const CaseNames = [
  '25pcs_worse_retention',
  '25pct_improved_retention',
  'base_case',
  'cac_up_10pct',
  'double_spend_10pct_higher_cac_25pct_improved_retention',
  'double_spend_cac_up_10pct',
  'no_new_intake',
] as const;

export type CaseName = (typeof CaseNames)[number];

// Todo remove when all decks as backfilled
export const CaseNamesFallBack = {
  '25% lower 2-year retention': '25pcs_worse_retention',
  '25% improved 2-year retention': '25pct_improved_retention',
  'Gilion Base Case': 'base_case',
  'CAC up 10%': 'cac_up_10pct',
  'Double ad spend, 10% higher CAC, 25% improved 2-year retention':
    'double_spend_10pct_higher_cac_25pct_improved_retention',
  'Double ad spend and CAC up 10%': 'double_spend_cac_up_10pct',
  'Only existing customers': 'no_new_intake',
} as const satisfies Record<string, CaseName>;

export interface BaseDataPoint {
  date: string;
  isForecast?: boolean;
  value: number | null;
}

export interface MetricsRequestBody {
  dimensions: string[];
  metrics: string[];
  filters?: { dimension: string; operation: 'gt' | 'lt' | 'eq' | 'in'; value: string | number | boolean }[];
}

export interface DataExtractorResponse<T, U> {
  meta: T;
  rows: U[];
}

export const timeGranularityDateDimensionMap = {
  days: 'date_day',
  weeks: 'date_week',
  months: 'date_month',
  quarters: 'date_quarter',
  years: 'date_year',
} as const;

export const acquisitionGranularityDateDimensionMap = {
  months: 'acquisition_date_month',
  quarters: 'acquisition_date_quarter',
} as const; // "satisfies" would be nice here in a Record type

export type DateDimension = (typeof timeGranularityDateDimensionMap)[keyof typeof timeGranularityDateDimensionMap];

export type MonthQuarterDimension = Extract<DateDimension, 'date_month' | 'date_quarter'>;

export type DayMonthQuarterDimension = Extract<DateDimension, 'date_day' | 'date_month' | 'date_quarter'>;

export type TimeSinceAcquisition = 'calendar_months_since_acquisition' | 'calendar_quarters_since_acquisition';

export type RetentionRate = 'monthly_recurring_revenue_retention' | 'quarterly_recurring_revenue_retention';

export type AcquisitionDimension =
  (typeof acquisitionGranularityDateDimensionMap)[keyof typeof acquisitionGranularityDateDimensionMap];

export const mapTimeGranularityToDateDimension = <T extends TimeGranularity>(timeGranularity: T) => {
  return timeGranularity ? timeGranularityDateDimensionMap[timeGranularity] : 'date_month';
};

export const mapAcquisitionGranularityToAcquisitionDimension = (
  acquisitionGranularity: AcquisitionGranularity,
): AcquisitionDimension => {
  return acquisitionGranularity
    ? acquisitionGranularityDateDimensionMap[acquisitionGranularity]
    : 'acquisition_date_month';
};

// Not used right now but kept if we ever want to make request directly to the extractor
export const dataExtractorRequest = <T>(
  incomingHeaders: IncomingHttpHeaders,
  body?: MetricsRequestBody,
  url: string = ExternalRoutes.Metric,
): Promise<T> => {
  const dataExtractorUrl = process.env.NEXT_PUBLIC_DATA_EXTRACTOR_URL + url;

  return serverRequest<T>({
    method: 'POST',
    url: dataExtractorUrl,
    data: { ...body }, //This allows us to have strict typing for the the wrapper request, but still allow the generic request function to accept just about any body
    headers: { authorization: incomingHeaders.authorization ?? '' },
  });
};

export const parseDateValueItem = (
  item: {
    date: string;
    value: number | null;
  },
  removeNull = false,
): { x: ChartDataset['data'][number]['x']; y: number | null } => ({
  x: item.date,
  //we can remove this roundToDecimals since we use formatValue when we display values
  y: item.value !== null ? roundToDecimals(item.value) : removeNull ? 0 : null,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = (list: any[], groupBykey: string, property: string): Record<string, string> => {
  // using any since the response rows declarations are different for each insight TODO fix this
  return list
    .filter((x) => x[property])
    .reduce((prev, curr) => {
      return {
        ...prev,
        [curr[groupBykey]]: curr[property],
      };
    }, {});
};

export const getPreviousDatePeriod = (
  dateDimension: TimeGranularity | DateDimension,
  date?: Date | string,
  numberOfPeriods = 1,
): string => {
  const currentDate = date ? new Date(date) : new Date();
  return toUTCDate(calculateNewDatePeriod(toLocalDate(currentDate), -numberOfPeriods, dateDimension)).toISOString();
};

export const getNextDatePeriod = (
  dateDimension: TimeGranularity | DateDimension,
  date?: Date | string,
  numberOfPeriods = 1,
): string => {
  const currentDate = date ? new Date(date) : new Date();
  return toUTCDate(calculateNewDatePeriod(toLocalDate(currentDate), numberOfPeriods, dateDimension)).toISOString();
};

const calculateNewDatePeriod = (
  currentDate: Date,
  periodsToAdd: number,
  dateDimension: TimeGranularity | DateDimension,
): Date => {
  switch (dateDimension) {
    case 'months':
    //fall through
    case 'date_month':
      return new Date(currentDate.getFullYear(), currentDate.getMonth() + periodsToAdd, 1);

    case 'date_quarter':
    //fall through
    case 'quarters':
      return addQuarters(startOfQuarter(currentDate), periodsToAdd);

    case 'date_week':
    //fall through
    case 'weeks':
      return add(startOfWeek(currentDate, { weekStartsOn: 1 }), { weeks: periodsToAdd });

    case 'date_day':
    //fall through
    case 'days':
      return new Date(currentDate.setDate(currentDate.getDate() + periodsToAdd));

    case 'date_year':
    //fall through
    case 'years':
      return new Date(currentDate.setFullYear(currentDate.getFullYear() + periodsToAdd, 0, 1));
    default:
      return new Date(currentDate);
  }
};

export const dateRange = (
  startDate: string,
  endDate: string,
  dateDimension: TimeGranularity | DateDimension,
): string[] => {
  try {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const range = ((): Date[] => {
      switch (dateDimension) {
        case 'date_day':
        //fall through
        case 'days':
          return eachDayOfInterval({
            start,
            end,
          });
        case 'date_week':
        //fall through
        case 'weeks':
          return eachWeekOfInterval(
            {
              start,
              end,
            },
            { weekStartsOn: 1 },
          );
        case 'date_month':
        //fall through
        case 'months':
          return eachMonthOfInterval({
            start,
            end,
          });
        case 'date_quarter':
        //fall through
        case 'quarters':
          return eachQuarterOfInterval({
            start,
            end,
          });
        case 'date_year':
        //fall through
        case 'years':
          return eachYearOfInterval({
            start,
            end,
          });
      }
    })();
    return range.map((date) => toUTCDate(date).toISOString());
  } catch (e) {
    return [];
  }
};

export const getUnit = (table: Table, field: string) => {
  return arrowRequestIsEmpty(table) ? '' : JSON.parse(table.schema.metadata.get(field) as string).display_unit;
};

export const getDimension = (table: Table, field: string) => {
  if (arrowRequestIsEmpty(table)) {
    return { xMin: undefined, xMax: undefined };
  }
  const { min: xMin, max: xMax } = JSON.parse(table.schema.metadata.get(field) as string);
  return { xMin, xMax };
};
