import React from 'react';
import { animated, useSpring } from '@react-spring/web';

interface DataHoverCircleProps {
  x: number;
  y: number;
  strokeColor?: string;
  fillColor?: string;
  radius?: number;
}

export const DataHoverCircle = ({
  x,
  y,
  strokeColor = 'grey',
  fillColor = 'white',
  radius = 4,
}: DataHoverCircleProps) => {
  const styles = useSpring({
    config: {
      duration: 250,
    },
    from: {
      r: 0,
    },
    to: {
      r: radius,
      opacity: 1,
    },
  });

  return <animated.circle {...styles} cx={x} cy={y} fill={fillColor} stroke={strokeColor} pointerEvents='none' />;
};
