import type { ComponentRef } from 'react';
import { useCallback, useEffect, useRef, type ComponentProps } from 'react';

type TextAreaProps = ComponentProps<'textarea'> & {
  autoHeight?: boolean;
};

export const TextArea = ({ autoHeight, ...props }: TextAreaProps) => {
  const ref = useRef<ComponentRef<'textarea'>>(null);

  const resizeOnInput = useCallback(() => {
    if (!autoHeight) return;
    if (!ref.current) return;

    ref.current.style.height = 'auto';
    ref.current.style.height = `${ref.current.scrollHeight + 1}px`;
  }, [autoHeight]);

  useEffect(() => resizeOnInput(), [resizeOnInput]);

  const rows = autoHeight ? 1 : props.rows;

  return <textarea ref={ref} onInput={autoHeight ? resizeOnInput : undefined} {...props} rows={rows} />;
};
