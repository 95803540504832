import type { ComponentProps } from 'react';
import { createContext, type ReactNode, useContext, useMemo, useCallback } from 'react';
import cn from 'classnames';
import styles from './Tabs.module.css';
import { useTabsNavigation } from './hooks';
import { Typography } from '../Typography';

const TabsContext = createContext<{
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
} | null>(null);

export const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) throw new Error('useTabsContext must be used within a Tabs component!');
  return context;
};

type TabsProps<TValue extends string = string> = {
  selectedTab: TValue;
  setSelectedTab: (tab: TValue) => void;
  children: ReactNode;
};

export const Tabs = <TValue extends string = string>({ selectedTab, setSelectedTab, children }: TabsProps<TValue>) => {
  const contextValue = useMemo(
    () => ({
      selectedTab: selectedTab as string,
      setSelectedTab: setSelectedTab as (tab: string) => void,
    }),
    [selectedTab, setSelectedTab],
  );

  return <TabsContext.Provider value={contextValue}>{children}</TabsContext.Provider>;
};

const TabsList = ({ children, className, ...props }: ComponentProps<'ul'>) => {
  return (
    <ul className={cn(styles.tabsList, className)} {...props}>
      {children}
    </ul>
  );
};

const Tab = <TValue extends string = string>({
  value,
  children,
  ...props
}: { value: TValue } & ComponentProps<'li'>) => {
  const { selectedTab, setSelectedTab } = useTabsContext();

  const selectTab = useCallback(() => setSelectedTab(value), [setSelectedTab, value]);

  const { handleKeyDown } = useTabsNavigation({ changeTab: selectTab });

  const isActive = selectedTab === value;

  return (
    <li
      className={styles.tab}
      onClick={() => setSelectedTab(value)}
      onKeyDown={handleKeyDown}
      data-state={isActive ? 'active' : 'inactive'}
      tabIndex={0}
      {...props}
    >
      <Typography variant='text1'>{children}</Typography>
    </li>
  );
};

const TabContent = <TValue extends string = string>({ value, children }: { value: TValue; children: ReactNode }) => {
  const { selectedTab } = useTabsContext();

  if (selectedTab !== value) return null;

  return children;
};

Tabs.TabsList = TabsList;
Tabs.Tab = Tab;
Tabs.TabContent = TabContent;
