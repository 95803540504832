import type { MutableRefObject, ReactNode } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { createJsxToStringRenderer } from '../utils/core';
import {
  CursorModifier,
  DpiHelper,
  adjustTooltipPosition,
  type SciChartSurface,
  type SeriesInfo,
  type TCursorTooltipSvgTemplate,
} from 'scichart';
import type { ChartDataset, SeriesMetadataMap } from '../types';
import { getTooltipHash, getTooltipHashFromParts } from '../utils/tooltip';
import { isStackedXySeriesInfo } from '../utils/type-guards';
import { useHoverPointMarker } from './hover.hooks';
import { areEqualSeriesInfo } from '../utils/hover-utils';
import type { HoveredDataLabelProps } from '../modifiers/MouseOverAnnotationModifier';
import type { TooltipDataPoint } from 'aim-components';

const { renderJsxToString } = createJsxToStringRenderer();

export type CharlieTooltipProps = {
  seriesInfos: Array<{ info: SeriesInfo; dataset?: ChartDataset }>;
  hoveredSeriesInfo: { info: SeriesInfo; dataset?: ChartDataset };
};

export type RenderTooltipProps = {
  dataPoints: Array<TooltipDataPoint>;
  hoveredDatasetLabel: string | undefined;
};

export type TooltipRenderer = (props: RenderTooltipProps) => ReactNode;

const mapSeriesInfoToHoverPointMarker = (info: SeriesInfo, isActive: boolean) => {
  return {
    x: info.xValue,
    y: isStackedXySeriesInfo(info) ? info.accumulatedValue : info.yValue,
    color: info.stroke,
    yAxis: info.renderableSeries.yAxisId,
    isActive,
  };
};

export const useTooltip = ({
  renderTooltip,
  surfaceRef,
  hoveredSeriesId,
  seriesMetadataRef,
  hoveredDataLabelRef,
}: {
  renderTooltip?: TooltipRenderer;
  surfaceRef: MutableRefObject<SciChartSurface | null>;
  hoveredSeriesId: MutableRefObject<Set<string> | null>;
  seriesMetadataRef: MutableRefObject<SeriesMetadataMap>;
  hoveredDataLabelRef: MutableRefObject<HoveredDataLabelProps | null>;
}) => {
  const { updateHoverPointMarker, removeHoverPointMarkers } = useHoverPointMarker({
    surfaceRef,
  });

  const tooltipCacheRef = useRef<{
    hash: string;
    svgString: string;
  } | null>(null);

  const renderTooltipRef = useRef<typeof renderTooltip>();

  if (renderTooltipRef.current !== renderTooltip) {
    renderTooltipRef.current = renderTooltip;
  }

  const convertJsxToString = useCallback(({ hash, tooltip }: { hash: string; tooltip: ReactNode }) => {
    // * For performance reasons, we only "re-render" the tooltip if the hash changes.
    if (tooltipCacheRef.current?.hash === hash) {
      return tooltipCacheRef.current.svgString;
    }

    const svgString = renderJsxToString(tooltip);

    tooltipCacheRef.current = { hash, svgString };

    return svgString;
  }, []);

  const tooltipSvgTemplate = useRef(((allSeriesInfos, svgAnnotation) => {
    if (!renderTooltipRef.current) return '<svg></svg>';

    if (!surfaceRef.current) {
      removeHoverPointMarkers();
      return '<svg></svg>';
    }

    if (!hoveredDataLabelRef.current) {
      const seriesInfosToBeRendered = allSeriesInfos.filter((info) => {
        // * Filter out the hover data point marker series
        return seriesMetadataRef.current.get(info.renderableSeries.id) && !Number.isNaN(info.yValue);
      });

      const hoveredSeriesInfo = seriesInfosToBeRendered.find((info) => {
        return hoveredSeriesId.current?.has(info.renderableSeries.id);
      });

      if (!hoveredSeriesInfo) {
        removeHoverPointMarkers();
        return '<svg></svg>';
      }

      const chartHeight = surfaceRef.current.annotationOverlaySurfaceViewRect.height;

      const isStacked = seriesInfosToBeRendered.every((info) => isStackedXySeriesInfo(info));

      const allMarkers = (isStacked ? [hoveredSeriesInfo] : seriesInfosToBeRendered)
        .map((info) => {
          const isActive = areEqualSeriesInfo(hoveredSeriesInfo, info);
          return mapSeriesInfoToHoverPointMarker(info, isActive);
        })
        .filter((marker) => marker.x !== undefined && marker.y !== undefined);

      const hoveredMarker = mapSeriesInfoToHoverPointMarker(hoveredSeriesInfo, true);

      updateHoverPointMarker(allMarkers, hoveredMarker);

      adjustTooltipPosition(
        300, // TODO: We might be able to look at the actual size of the tooltip here, approximate it, or base it on a max-width.
        chartHeight / DpiHelper.PIXEL_RATIO / 2,
        svgAnnotation,
      );

      const tooltipHash = getTooltipHash(hoveredSeriesInfo);

      const seriesToBeDisplayedInTooltip = seriesInfosToBeRendered.filter((info) => {
        // * Filter out help series that renders points on the chart where the line is invisible
        return (
          seriesMetadataRef.current.get(info.renderableSeries.id) &&
          !seriesMetadataRef.current.get(info.renderableSeries.id)?.excludeFromTooltip
        );
      });

      const { dataPoints, hoveredDatasetLabel } = createTooltipDataForCharlieCharts({
        seriesInfos: seriesToBeDisplayedInTooltip.map((info) => ({
          info,
          dataset: seriesMetadataRef.current.get(info.renderableSeries.id)?.dataset,
        })),
        hoveredSeriesInfo: {
          info: hoveredSeriesInfo,
          dataset: seriesMetadataRef.current.get(hoveredSeriesInfo.renderableSeries.id)?.dataset,
        },
      });

      const svgString = convertJsxToString({
        hash: tooltipHash,
        tooltip: renderTooltipRef.current({ dataPoints, hoveredDatasetLabel }),
      });

      return svgString;
    }

    const hoveredDataLabel = hoveredDataLabelRef.current;
    const hoveredDataset = seriesMetadataRef.current.get(hoveredDataLabel.seriesId)?.dataset;

    const seriesHovered = surfaceRef.current?.renderableSeries.getById(hoveredDataLabel.seriesId);

    if (!seriesHovered || !hoveredDataset) return '<svg></svg>';

    const chartHeight = surfaceRef.current.annotationOverlaySurfaceViewRect.height;

    updateHoverPointMarker(
      [
        {
          x: hoveredDataLabel.annotationValue.x,
          y: hoveredDataLabel.annotationValue.y,
          color: hoveredDataset.color,
          yAxis: seriesHovered.yAxisId,
          isActive: true,
        },
      ],
      {
        x: hoveredDataLabel.annotationValue.x,
        y: hoveredDataLabel.annotationValue.y,
        color: hoveredDataset.color,
        yAxis: seriesHovered.yAxisId,
        isActive: true,
      },
    );

    adjustTooltipPosition(
      300, // TODO: We might be able to look at the actual size of the tooltip here, approximate it, or base it on a max-width.
      chartHeight / DpiHelper.PIXEL_RATIO / 2,
      svgAnnotation,
    );

    const tooltipHash = getTooltipHashFromParts(
      hoveredDataLabel.seriesId,
      hoveredDataLabel.annotationValue.x,
      hoveredDataLabel.annotationValue.y,
    );

    const svgString = convertJsxToString({
      hash: tooltipHash,
      tooltip: renderTooltipRef.current({
        dataPoints: [
          {
            type: hoveredDataset.type,
            label: hoveredDataset.label,
            color: hoveredDataset.color,
            value: hoveredDataLabel.annotationValue.y,
            datasetUnit: hoveredDataset.datasetUnit,
            point: { x: hoveredDataLabel.annotationValue.x * 1000, y: hoveredDataLabel.annotationValue.y },
          },
        ],
        hoveredDatasetLabel: hoveredDataset.label,
      }),
    });

    return svgString;
  }) satisfies TCursorTooltipSvgTemplate);

  const tooltipCursorModifier = useMemo(() => {
    return new CursorModifier({
      showTooltip: true,
      showXLine: false,
      showYLine: false,
      tooltipSvgTemplate: tooltipSvgTemplate.current,
    });
  }, []);

  return { tooltipCursorModifier };
};

export const createTooltipDataForCharlieCharts = (props: CharlieTooltipProps) => {
  const { seriesInfos, hoveredSeriesInfo } = props;

  const dataPoints = seriesInfos
    .filter((seriesInfo) => !isNaN(seriesInfo.info.yValue) && seriesInfo.dataset)
    .sort((a, b) => {
      // * Sort the tooltip rows according to the stack order (top to bottom)
      return a.info.yCoordinate - b.info.yCoordinate;
    })
    .map((seriesInfo) => {
      const { type, label, color, datasetUnit } = seriesInfo.dataset as ChartDataset;

      return {
        type,
        label: label,
        color: color,
        value: seriesInfo.info.yValue,
        datasetUnit: datasetUnit,
        point: { x: seriesInfo.info.xValue * 1000, y: seriesInfo.info.yValue },
      };
    });
  const hoveredDatasetLabel = hoveredSeriesInfo.dataset?.label;

  return {
    dataPoints,
    hoveredDatasetLabel,
  };
};
