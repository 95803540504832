import type { ReactNode } from 'react';
import React from 'react';
import styles from './TransparentCard.module.css';

export const TransparentCard = ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
