import React from 'react';

interface NoiseArcProps {
  clipPath: string;
}

export const NoiseArc = ({ clipPath }: NoiseArcProps) => {
  return (
    <>
      <defs>
        <filter
          id='noise-filter'
          filterUnits='objectBoundingBox'
          primitiveUnits='userSpaceOnUse'
          colorInterpolationFilters='linearRGB'
        >
          <feTurbulence type='fractalNoise' baseFrequency='0.056' numOctaves='10' seed='20' result='turbulence' />;
          <feSpecularLighting
            surfaceScale='18'
            specularConstant='1.6'
            specularExponent='20'
            lightingColor='#ffffff'
            in='turbulence'
            result='specularLighting'
          >
            <feDistantLight azimuth='8' elevation='40' />
          </feSpecularLighting>
        </filter>
      </defs>
      <rect
        opacity={0.3}
        width='1024'
        height='512'
        fill='#ffffff'
        filter='url(#noise-filter)'
        clipPath={clipPath}
      ></rect>
    </>
  );
};
