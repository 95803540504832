import type { ReactNode } from 'react';
import styles from './ProfileRow.module.css';
import { Typography } from 'aim-components';

interface ProfileRowProps {
  title: string;
  subTitle?: string;
  children: ReactNode;
}

export const ProfileRow = ({ title, subTitle, children }: ProfileRowProps) => (
  <label className={styles.profileRow}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant='label3'>{title}</Typography>
      <Typography variant='text2' color='secondary'>
        {subTitle}
      </Typography>
    </div>
    {children}
  </label>
);
