import type { ReactNode } from 'react';
import type React from 'react';
import type { Option } from '../Input/Select/Select';
import type { Chart, ChartOptions, TooltipItem, Plugin } from 'chart.js';

interface LabelFormatter {
  type: xAxisFormat;
  hideLabels?: boolean;
}

interface XAxisTicks {
  formatter?: LabelFormatter;
  maxRotation?: number;
  autoSkip?: boolean;
  display?: boolean;
}

interface XAxisActions {
  leftAction?: () => void;
  rightAction?: () => void;
}

interface ChartAxis {
  title?: string;
  unit?: string;
  linear?: boolean;
  isLoading?: boolean;
  display?: boolean;
  fontSize?: number;
}

export interface ChartXAxis extends ChartAxis {
  slider?: ChartSlider;
  linear?: boolean;
  fixedHeight?: number;
  offset?: boolean;
  xMax?: string;
  xMin?: string;
  min?: string;
  max?: string;
  granularity?: TimeGranularity;
  ticks?: XAxisTicks;
  actions?: XAxisActions;
}

export interface ChartYAxis extends ChartAxis {
  stacked?: boolean;
  fixedWidth?: number;
  noGrace?: boolean;
  fixedMin?: number;
  fixedMax?: number;
  suggestedMax?: number;
  capMaxTo?: number;
  capMinTo?: number;
  highlightNegativeArea?: boolean;
  color?: string;
}

export type DateString = `${number}-${number}-${number}`;

export interface ChartDataset<TDatasetId = string> {
  label: string;
  datasetId: TDatasetId;
  connectedWith?: string; // If the dataset should always be displayed with another dataset
  type: 'line' | 'bar' | 'scatter' | 'stackedLine' | 'mountain' | 'band';
  data: { x: string | number; y: number | null; date?: string | null }[];
  color: string;
  highlightOnHover?: boolean;

  /** An array of chart data labels.
   * Can either be provided as a `number` (corresponding to the data point index),
   * or as an object if a custom label should be shown instead of the default label (the Y value of the data point). */
  dataLabels?: Array<number | { index: number; label?: string }>;
  dataLabelsAtSliderMax?: boolean;
  preselected?: boolean;
  datasetUnit?: string;
  isDotted?: boolean;
  borderDash?: number[];
  isReferenceLine?: boolean;
  fill?: boolean | number | string;
  shadedFill?: { direction?: 'horizontal' | 'vertical'; reversed?: boolean };
  order?: number;
  borderWidth?: number | { top: number };
  fillColor?: string;
  forecastColor?: string;
  hoverColor?: string;
  filterable?: boolean;
  hoverable?: boolean;
  tab?: string;
  id?: string; // Used to distinguish between options that appears the same, but are used in different contexts (used to retrigger dataset filters).
  isStacked?: boolean;
  stack?: number;
  yPaddingPercentage?: number; // Adding padding in the y-axis for this dataset
  stepped?: boolean;
  xAxisId?: string;
  yAxisId?: 'y' | 'y1';
  highlightNegativeValues?: boolean;
}

export interface TooltipDataPoint {
  type?: ChartDataset['type'];
  label?: string;
  color?: string;
  forecastColor?: string;
  value?: number;
  datasetUnit?: string;
  point?: { x: number; y: number } | { x: number; y: number; date: string };
  dataIndex?: number;
  datasetIndex?: number;
}

export interface TooltipContentProps {
  title?: string;
  text?: string;
  children?: ReactNode;
  footer?: ReactNode;
  dataPoints: TooltipDataPoint[];
  unit?: string;
  units?: { x: string; y: string };
  hoveredDatasetLabel?: string;
}

export interface DatasetFilterSettings {
  filteredDataIsGreyedOut?: boolean;
  singleDataset?: boolean;
}

export interface ChartProps {
  title?: string;
  datasetFilterSettings?: DatasetFilterSettings;
  datasets: ChartDataset[];
  selectedDatasetsOptions: Option[];
  TooltipComponent?: React.FC<TooltipContentProps>;
  xAxis?: ChartXAxis;
  yAxis?: ChartYAxis;
  y1Axis?: ChartYAxis;
  disableAnnotations?: boolean;
  responsive?: boolean;
  expanded?: boolean;
  xmin?: number; // needs to be lower case to not throw errors in the DOM
  xmax?: number; // needs to be lower case to not throw errors in the DOM
  labels?: string[];
  forecastWarning?: ReactNode;
  circleLoadingCover?: boolean;
  overrideOptions?: ChartOptions;
  plugins?: Plugin[];
}

export interface ChartMobileProps extends ChartProps {
  handleHoverDataPoint?: (xValue: number | null) => void;
  handleTooltipChange?: (data: {
    dataPoints: Array<TooltipItem<'bar' | 'line' | 'scatter'>>;
    hoveredStack: number | undefined;
  }) => void;
  formatTooltipTitle?: (tooltip: Chart['tooltip']) => string;
  onClick?: () => void;
  badgeContent?: string;
}

export interface ChartSlider {
  defaultMin?: string;
  defaultMax?: string;
  fullRange?: { min?: number; max?: number };
}

export const TIME_GRANULARITIES = ['years', 'quarters', 'months', 'weeks', 'days'] as const;
export type TimeGranularity = (typeof TIME_GRANULARITIES)[number];

export const RELATIVE_X_AXIS_FORMATS = ['daysRelative', 'monthsRelative', 'quartersRelative'] as const;
export const X_AXIS_FORMATS = [...TIME_GRANULARITIES, ...RELATIVE_X_AXIS_FORMATS];

export type xAxisFormat = (typeof X_AXIS_FORMATS)[number];

// ? Maybe this can be done globally, with interface declaration merging
export type ChartOptionsWithCustomOptions = ChartOptions & {
  customOptions?: Partial<{
    displayMobileTooltip: boolean;
    timeGranularity: TimeGranularity;
  }>;
};

export const isChartOptionsWithCustomOptions = (
  options: ChartOptions | ChartOptionsWithCustomOptions,
): options is ChartOptionsWithCustomOptions => {
  return 'customOptions' in (options as ChartOptionsWithCustomOptions);
};
