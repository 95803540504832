import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMicrosoftColor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <rect width={32} height={32} fill='#F3F3F3' rx={4} />
    <path fill='#F35325' d='M5 5h10.476v10.476H5z' />
    <path fill='#81BC06' d='M16.524 5h10.477v10.476H16.524z' />
    <path fill='#05A6F0' d='M5 16.524h10.476V27H5z' />
    <path fill='#FFBA08' d='M16.524 16.524h10.477V27H16.524z' />
  </svg>
);
export default SvgMicrosoftColor;
