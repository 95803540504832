import type { ElementRef, MouseEvent } from 'react';
import { forwardRef, useCallback, useRef } from 'react';
import styles from './Accordion.module.css';
import cn from 'classnames';
import { Expand } from '../Animation';
import { Icon } from '../Icon';
import { useAccordionContext } from './AccordionContext';
import { SingleAccordion } from './SingleAccordion';
import type { AccordionItemProps, AccordionProps } from './types';
import { ACCORDION_TYPE } from './types';
import { MultipleAccordion } from './MultipleAccordion';

export const Accordion = <TAccordionId extends string = string>(
  props: Partial<AccordionProps<TAccordionId>> & Pick<AccordionProps<TAccordionId>, 'type' | 'children'>,
) => {
  if (props.type === ACCORDION_TYPE.SINGLE) {
    return <SingleAccordion {...props} />;
  }

  return <MultipleAccordion {...props} />;
};

const AccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(
  ({ children, title, subtitle, disabled = false, value, className, ...props }, ref) => {
    const accordionContext = useAccordionContext();

    const expandRef = useRef<ElementRef<typeof Expand>>(null);

    const isExpanded = (() => {
      if (accordionContext.type === ACCORDION_TYPE.SINGLE) {
        return accordionContext.expandedId === value;
      }

      return accordionContext.expandedIds.includes(value);
    })();

    const handleToggle = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        // * If the user clicks inside the expanded content, do not toggle the accordion.
        if (expandRef.current && expandRef.current.contains(e.target as Node)) return;

        accordionContext.toggleId(value);
      },
      [accordionContext, value],
    );

    return (
      <div
        className={cn(styles.accordion, className)}
        data-icon-position={accordionContext.iconPosition}
        aria-disabled={disabled}
        onClick={disabled ? undefined : handleToggle}
        ref={ref}
        {...props}
      >
        <div className={styles.base}>
          <div className={styles.titleContainer}>
            {title}
            {subtitle}
          </div>
          {!disabled && (
            <div className={styles.expandedIconContainer} data-accordion-toggle>
              <Icon icon='dropdown-down' className={cn(styles.icon, isExpanded && styles.expanded)} />
            </div>
          )}
        </div>
        <Expand expanded={isExpanded} ref={expandRef}>
          {children}
        </Expand>
      </div>
    );
  },
);

AccordionItem.displayName = 'AccordionItem';
Accordion.Item = AccordionItem;
