import { useState } from 'react';
import type { SortOrder } from './sorting';

export function useTableSort<SortableColumn>(
  defaultColumn: SortableColumn,
): [(columnId: string) => void, SortableColumn, SortOrder] {
  const [order, setOrder] = useState<SortOrder>('desc');
  const [sortedColumn, setSortedColumn] = useState<SortableColumn>(defaultColumn);

  const handleSort = (columnId: string): void => {
    if (columnId !== sortedColumn) {
      setOrder('desc');
    } else {
      setOrder(order === 'desc' ? 'asc' : 'desc');
    }
    setSortedColumn(columnId as SortableColumn);
  };

  return [handleSort, sortedColumn, order];
}
