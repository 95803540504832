import type { ReactElement } from 'react';
import { useMemo } from 'react';
import React from 'react';
import cn from 'classnames';
import { Icon } from '../../Icon';
import { Label } from '../Label';
import type { MultiSelectTabsProps } from './MultiSelectTabs';
import { MultiSelectTabs } from './MultiSelectTabs';
import { PillRow } from './PillRow';
import type { Option, SelectProps } from './Select';
import type { SelectOptionProps } from './SelectOption';
import { SelectOption } from './SelectOption';

import styles from './Select.module.css';
import multiStyles from './MultiSelect.module.css';
import { containsOption } from './utils';
import { isDefined, uniqueArray } from 'aim-utils';
import { useDismiss, useInteractions, FloatingPortal, useClick, FloatingOverlay } from '@floating-ui/react';
import { useSelect } from './hooks';

export interface PresetsProps {
  option: Option;
  includedOptions: string[];
  tab?: string;
}

export interface MultiSelectProps<TValue extends string = string>
  extends Omit<SelectProps<TValue>, 'selectedOption' | 'setSelectedOption'> {
  selectedOptions?: Option<TValue>[] | TValue[];
  setSelectedOptions: (options: Option<TValue>[]) => void;
  condensed?: boolean;
  tabConfig?: MultiSelectTabsProps;
  presets?: PresetsProps[];
  shouldDisplayPresets?: boolean;
  maximumNumberOfSelected?: number;
}

export const MultiSelect = <TValue extends string = string>({
  options,
  placeholder,
  hint,
  label,
  disabled,
  validationError,
  isValid,
  selectedOptions: _selectedOptions = [],
  setSelectedOptions,
  condensed,
  tabConfig,
  presets = [],
  maximumNumberOfSelected = Number.POSITIVE_INFINITY,
  placement,
}: MultiSelectProps<TValue>): ReactElement => {
  const { showOptions, refs, floatingStyles, context } = useSelect({ placement });

  const selectedTab: undefined | string = tabConfig?.selectedTabName;

  const selectedOptions = useMemo(
    () =>
      _selectedOptions
        .map((selectedOption) =>
          typeof selectedOption === 'string' ? options.find(({ value }) => value === selectedOption) : selectedOption,
        )
        .filter(isDefined),
    [_selectedOptions, options],
  );

  // Allows usage of the multi-select with and without tabs
  const optionsInTab = selectedTab ? options.filter(({ tab }) => tab === selectedTab) : options;
  const preSelectionsInTab: PresetsProps[] = selectedTab ? presets.filter(({ tab }) => tab === selectedTab) : presets;
  const selectedOptionsInTab = selectedTab ? selectedOptions.filter(({ tab }) => tab === selectedTab) : selectedOptions;
  const selectedOptionValues: Set<string> = new Set(selectedOptionsInTab.map(({ value }) => value));

  const tabNames = tabConfig?.tabNames ?? [];
  const hasTabs = tabNames.length > 0;

  const areAllOptionsSelected = selectedOptionsInTab.length === optionsInTab.length;

  const deselectOption = (value: Option['label'] | TValue, attribute: 'label' | 'value'): void => {
    return setSelectedOptions(selectedOptionsInTab.filter((option) => option[attribute] !== value));
  };

  const toggleOptionSelectState = (option: Option<TValue>): void => {
    if (selectedOptionValues.has(option.value)) {
      deselectOption(option.value, 'value');
    } else {
      setSelectedOptions([...selectedOptionsInTab, option]);
    }
  };

  const preSelectionOptions: SelectOptionProps[] = useMemo(
    () => [
      ...preSelectionsInTab.map((selection) => {
        const isSelected = selection.includedOptions.every((option) => containsOption(selectedOptions, option));
        return {
          option: selection.option,
          onSelect: () =>
            setSelectedOptions(
              isSelected
                ? selectedOptionsInTab.filter(
                    (selectedOption) => !selection.includedOptions.includes(selectedOption.value),
                  )
                : uniqueArray(
                    selectedOptionsInTab.concat(
                      optionsInTab.filter((option) => selection.includedOptions.includes(option.value)),
                    ),
                  ),
            ),
          isSelected: isSelected,
        };
      }),
    ],
    [optionsInTab, preSelectionsInTab, selectedOptions, selectedOptionsInTab, setSelectedOptions],
  );

  const selectOptionProps: SelectOptionProps<TValue>[] = [
    ...optionsInTab.map((option) => ({
      option,
      onSelect: toggleOptionSelectState,
      isSelected: selectedOptionValues.has(option.value),
    })),
  ];

  const getOptionKey = (option: Option): string => `${option.value}-${option.label}-${option.tab ?? 'no-tab'}`;

  const hasReachedMaximumNumberOfSelected = selectedOptionsInTab.length >= maximumNumberOfSelected;
  const dynamicLabel = hasReachedMaximumNumberOfSelected ? `${label} (Max ${maximumNumberOfSelected})` : label;

  const dismiss = useDismiss(context, { enabled: !disabled });
  const click = useClick(context, { enabled: !disabled });
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <>
      <Label label={dynamicLabel} disabled={disabled} htmlFor='multi-select-input' />
      <div
        className={cn(
          styles.container,
          hasTabs && multiStyles.selectWithTabs,
          disabled && styles.disabled,
          isValid === true && styles.valid,
          isValid === false && styles.invalid,
          condensed && styles.condensed,
        )}
        id='multi-select-input'
        tabIndex={showOptions ? -1 : 0}
        role='input'
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {selectedOptions.length > 0 ? (
          <PillRow pills={selectedOptions} onPillClose={(pill) => deselectOption(pill.label, 'label')} />
        ) : (
          <div className={multiStyles.placeholder}>{placeholder}</div>
        )}
        <Icon icon={`dropdown-${showOptions ? 'up' : 'down'}`} className={styles.dropdown} />
      </div>

      {showOptions && (
        <FloatingPortal>
          <FloatingOverlay>
            <div className={styles.options} ref={refs.setFloating} {...getFloatingProps()} style={floatingStyles}>
              {tabConfig && hasTabs && <MultiSelectTabs {...tabConfig} />}
              <div className={multiStyles.optionsList}>
                {optionsInTab.length <= maximumNumberOfSelected && (
                  <SelectOption
                    condensed={!hasTabs && condensed}
                    option={{ value: 'select-all', label: 'Select all' }}
                    onSelect={() => setSelectedOptions(areAllOptionsSelected ? [] : [...optionsInTab])}
                    isSelected={areAllOptionsSelected}
                  />
                )}
                <div className={multiStyles.presets}>
                  {preSelectionOptions.map((props) => (
                    <SelectOption key={getOptionKey(props.option)} {...props} condensed={!hasTabs && condensed} />
                  ))}
                </div>
                {selectOptionProps.map((props) => {
                  const optionIsDisabled = props.disabled || (hasReachedMaximumNumberOfSelected && !props.isSelected);

                  return (
                    <div key={getOptionKey(props.option)}>
                      <SelectOption {...props} disabled={optionIsDisabled} condensed={!hasTabs && condensed} />
                    </div>
                  );
                })}
              </div>
            </div>
          </FloatingOverlay>
        </FloatingPortal>
      )}
      {!(isValid === false && validationError) && hint && <p className={styles.hint}>{hint}</p>}
      {isValid === false && <p className={styles.error}>{validationError}</p>}
    </>
  );
};
