type MimeType = `${string}/${string}`;

export const downloadFile = ({
  data,
  mimeType,
  filename,
}: {
  data: BlobPart;
  mimeType: MimeType;
  filename: string;
}) => {
  const blob = new Blob([data], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;

  if (filename) {
    a.download = filename;
  }

  a.click();

  URL.revokeObjectURL(url);
};
