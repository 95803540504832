import type { ReactElement, ReactNode } from 'react';
import { Typography } from '../Typography';

import './ErrorScreen.css';
import { useMobile } from 'aim-utils';

export interface ErrorScreenProps {
  title: string;
  children: ReactNode;
  logo?: string;
}

const ErrorScreen = ({ title, children, logo }: ErrorScreenProps): ReactElement => {
  const { mobileView } = useMobile();
  return (
    <div className='Error-container'>
      <div className='logo' style={{ backgroundImage: `url(${logo})` }} />
      <div className='Error'>
        <div className='Error-text'>
          <Typography variant={mobileView ? 'title1' : 'title2'}>{title}</Typography>
          {children}
        </div>
      </div>
      <div />
    </div>
  );
};

export default ErrorScreen;
