import { useLocalStorage } from '@/lib/hooks';
import { Routes } from '@/lib/routes';
import { useUserSetting } from '@/lib/settings';
import { useTranslation } from 'next-i18next';
import { useDisplayInsight, useMetadata } from 'pages/[slug]/insights/hooks';
import { useCallback, useContext, useMemo } from 'react';
import { getLogoutFlow } from 'aim-ory';
import { TenantContext } from '@/components/tenant/context';
import { UserContext } from '@/components/authentication/UserContext';
import { getUserPermissions } from '@/lib/permissions/role';
import { BenchmarkingKey } from '@/components/insights/charts/common/types';
import type { NavigationMenuNode } from '@/components/layouts/navigation/navigation-menu/types';

export const WELCOME_SCREEN_HAS_BEEN_SEEN_CACHE = 'welcome-screen-has-been-seen-cache';
export const ENTER_GILION_HAS_BEEN_SEEN_CACHE = 'enter-gilion-has-been-seen-cache';

const useInsightsNavigationItems = () => {
  const { t } = useTranslation('common');

  return useMemo(() => {
    return [
      {
        type: 'section',
        label: t('navigation.insights'),
        items: [
          {
            type: 'nav-item',
            label: t('navigation.growth'),
            icon: 'chart',
            route: Routes.InsightsGrowth,
          },
          {
            type: 'nav-item',
            label: t('navigation.marketingEfficiency'),
            route: Routes.InsightsMarketing,
            icon: 'chart-scatter',
          },
          {
            type: 'nav-item',
            label: t('navigation.retention'),
            route: Routes.InsightsRetention,
            icon: 'chart-waterfall',
          },
          {
            type: 'nav-item',
            label: t('navigation.monetisation'),
            route: Routes.InsightsMonetisation,
            icon: 'chart-scatter-bubble',
          },
          {
            type: 'nav-item',
            label: t('navigation.financialStrength'),
            route: Routes.InsightsFinancialStrength,
            icon: 'chart-gantt',
          },
          {
            type: 'nav-item',
            label: t('navigation.activity'),
            route: Routes.InsightsActivity,
            icon: 'chart-column',
          },
          {
            type: 'nav-item',
            label: t('navigation.scores'),
            route: Routes.InsightsScores,
            icon: 'gauge-simple-high',
          },
        ],
      },
    ] as const satisfies readonly NavigationMenuNode[];
  }, [t]);
};

export const useNavigationMenuItems = () => {
  const { t } = useTranslation('common');
  const insightsNavigationItems = useInsightsNavigationItems();
  const benchmarkingNavigationItems = useBenchmarkingNavigationItems();
  const { ownership, loading: isLoadingTenantInfo } = useContext(TenantContext);
  const user = useContext(UserContext);
  const { hasDeckViewerRole } = getUserPermissions(user.role);
  const [{ insightsAvailable }, isLoadingMetadata] = useMetadata();

  const includeGrowthChat = ownership === 'PRIVATE';
  const includeInvestorNetwork = ownership === 'PRIVATE';
  const includeDataValidation = insightsAvailable?.revenue === true;

  const isLoading = isLoadingTenantInfo || isLoadingMetadata;

  const items = useMemo(() => {
    if (hasDeckViewerRole) {
      return [
        {
          type: 'nav-item',
          label: t('navigation.decks'),
          route: Routes.Decks,
          icon: 'presentation-screen',
        },
      ] as const satisfies readonly NavigationMenuNode[];
    }

    return [
      ...insightsNavigationItems,
      ...benchmarkingNavigationItems,
      {
        type: 'section',
        label: t('navigation.tools'),
        items: [
          {
            type: 'nav-item',
            label: t('navigation.forecasting'),
            route: Routes.Forecasting,
            icon: 'wand-magic-sparkles',
          },
          {
            type: 'nav-item',
            label: t('navigation.decks'),
            route: Routes.Decks,
            icon: 'presentation-screen',
          },
          ...(includeGrowthChat
            ? ([
                {
                  type: 'nav-item',
                  label: t('navigation.growthChat'),
                  route: Routes.GrowthChat,
                  icon: 'sparkles',
                },
              ] as const)
            : []),
        ],
      },
      {
        type: 'section',
        label: t('navigation.data'),
        items: [
          {
            type: 'nav-item',
            label: t('navigation.connectors'),
            route: Routes.Connectors,
            icon: 'plug',
          },
          ...(includeDataValidation
            ? ([
                {
                  type: 'nav-item',
                  label: t('navigation.dataValidation'),
                  route: Routes.DataValidation,
                  icon: 'badge-check',
                },
              ] as const)
            : []),
          {
            type: 'nav-item',
            label: t('navigation.keyFinancials'),
            route: Routes.KeyFinancials,
            icon: 'table',
          },
        ],
      },
      {
        type: 'section',
        label: t('navigation.funding'),
        items: [
          {
            type: 'nav-item',
            label: t('navigation.loans'),
            route: Routes.Loans,
            icon: 'piggy-bank',
          },
          ...(includeInvestorNetwork
            ? ([
                {
                  type: 'nav-item',
                  label: 'Investors',
                  route: Routes.InvestorNetwork,
                  icon: 'handshake',
                  beta: true,
                },
              ] as const)
            : []),
        ],
      },
    ] as const satisfies readonly NavigationMenuNode[];
  }, [
    t,
    hasDeckViewerRole,
    insightsNavigationItems,
    benchmarkingNavigationItems,
    includeGrowthChat,
    includeDataValidation,
    includeInvestorNetwork,
  ]);

  return { items, isLoading };
};

export const useBenchmarkingNavigationItems = () => {
  const { t } = useTranslation('common');
  const [{ someInsightsAvailable }] = useMetadata();
  const displayInsight = useDisplayInsight();

  return useMemo(() => {
    if (!someInsightsAvailable) return [];

    const showNetSalesRate = displayInsight(BenchmarkingKey.NetSalesRate);
    const showARRRate = displayInsight(BenchmarkingKey.ARRRate);
    const showRetentionRate = displayInsight(BenchmarkingKey.RetentionRate);

    if (!showNetSalesRate && !showARRRate && !showRetentionRate) return [];

    return [
      {
        type: 'section',
        label: t('navigation.benchmarking'),
        items: [
          {
            type: 'nav-item',
            label: t('navigation.netSales'),
            route: Routes.BenchmarkingNetSales,
            icon: 'chart-up-down',
          },
          ...(showARRRate
            ? ([
                {
                  type: 'nav-item',
                  label: t('navigation.arr'),
                  route: Routes.BenchmarkingARR,
                  icon: 'chart-up-down',
                },
              ] as const)
            : []),
          ...(showRetentionRate
            ? ([
                {
                  type: 'nav-item',
                  label: t('navigation.retention'),
                  route: Routes.BenchmarkingRetention,
                  icon: 'chart-up-down',
                },
              ] as const)
            : []),
        ],
      },
    ] as const satisfies readonly NavigationMenuNode[];
  }, [displayInsight, someInsightsAvailable, t]);
};

export const useLogout = () => {
  const performLogout = useCallback(async () => {
    const { data: flow } = await getLogoutFlow();
    // Use the received token to "update" the flow and thus perform the logout
    sessionStorage.removeItem('aim_tenant');
    window.location.href = flow.logout_url;
  }, []);

  return { performLogout };
};

export const useWelcomeScreen = () => {
  // We keep track of this in localStorage as well as in the user settings database, so we can use localStorage as a cache to prevent unnecessary requests to the backend.
  // In this way we don't have to wait for the user setting to load before we can determine whether to show the welcome screen or not, for a better user experience.
  const [welcomeScreenHasBeenSeenCache, setWelcomeScreenHasBeenSeenCache] = useLocalStorage<true | null>(
    WELCOME_SCREEN_HAS_BEEN_SEEN_CACHE,
    null,
  );

  const { data: welcomeScreenSettingData, isLoading } = useUserSetting(
    welcomeScreenHasBeenSeenCache ? null : 'welcome-screen',
  );

  if (welcomeScreenHasBeenSeenCache) {
    return { showWelcomeScreen: false, isLoading: false };
  }

  const hasBeenSeen = welcomeScreenSettingData?.hasBeenSeen === true;

  if (hasBeenSeen) {
    setWelcomeScreenHasBeenSeenCache(true);
  }

  const showWelcomeScreen = !isLoading && !hasBeenSeen;

  return { showWelcomeScreen, isLoading };
};

export const useEnterGilion = () => {
  // We keep track of this in localStorage as well as in the user settings database, so we can use localStorage as a cache to prevent unnecessary requests to the backend.
  // In this way we don't have to wait for the user setting to load before we can determine whether to show the welcome screen or not, for a better user experience.
  const [enterGilionHasBeenSeenCache, setEnterGilionHasBeenSeenCache] = useLocalStorage<true | null>(
    ENTER_GILION_HAS_BEEN_SEEN_CACHE,
    null,
  );

  const { data: enterGilionSettingData, isLoading } = useUserSetting(
    enterGilionHasBeenSeenCache ? null : 'enter-gilion',
  );

  if (enterGilionHasBeenSeenCache) {
    return { showEnterGilion: false, isLoading: false };
  }

  const hasBeenSeen = enterGilionSettingData?.hasBeenSeen === true;

  if (hasBeenSeen) {
    setEnterGilionHasBeenSeenCache(true);
  }

  const showEnterGilion = !isLoading && !hasBeenSeen;

  return { showEnterGilion, isLoading };
};
