import { type MutableRefObject } from 'react';
import type { IRenderableSeries } from 'scichart';
import type { IChartModifierBaseOptions } from 'scichart/Charting/ChartModifiers/ChartModifierBase2D';
import { ChartModifierBase2D } from 'scichart/Charting/ChartModifiers/ChartModifierBase2D';
import type { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { DpiHelper } from 'scichart/Charting/Visuals/TextureManager/DpiHelper';
import { traverseRenderableSeries } from '../utils/dataseries-utils';

export type HoveredDataLabelProps = {
  annotationValue: { x: number; y: number };
  seriesId: string;
};

export class MouseOverAnnotationModifier extends ChartModifierBase2D {
  public readonly type = 'MouseOverAnnotationModifier';
  public hoveredDataLabelRef: MutableRefObject<HoveredDataLabelProps | null>;

  constructor(
    hoveredDataLabelRef: MutableRefObject<HoveredDataLabelProps | null>,
    options?: IChartModifierBaseOptions,
  ) {
    super(options);
    this.hoveredDataLabelRef = hoveredDataLabelRef;
  }

  private handleMouseOver = (serie: IRenderableSeries, annotationXValue: number, annotationYvalue: number) => {
    if (!serie.isVisible) return null;

    const yAxisCoordCalc = serie.yAxis.getCurrentCoordinateCalculator();
    const xAxisCoordCalc = serie.xAxis.getCurrentCoordinateCalculator();

    const y = yAxisCoordCalc.getCoordinate(annotationYvalue);
    const x = xAxisCoordCalc.getCoordinate(annotationXValue);

    const hitTestInfo = serie.hitTestProvider.hitTest(x, y, 30);

    if (hitTestInfo.isHit) {
      return {
        annotationValue: { x: annotationXValue, y: annotationYvalue },
        seriesId: serie.id,
      };
    }

    return null;
  };

  public modifierMouseMove(args: ModifierMouseArgs): void {
    super.modifierMouseMove(args);

    const x = args.mousePoint.x / DpiHelper.PIXEL_RATIO;
    const y = args.mousePoint.y / DpiHelper.PIXEL_RATIO;

    let isHit: HoveredDataLabelProps | null = null;

    for (let i = 0; i < this.parentSurface.annotations.size(); i++) {
      const an = this.parentSurface.annotations.get(i);
      if (an.checkIsClickedOnAnnotation(x, y)) {
        const series = this.parentSurface.renderableSeries.asArray();

        traverseRenderableSeries(series, (serie) => {
          isHit = isHit ?? this.handleMouseOver(serie, an.x1, an.y1);
        });

        break;
      }
    }

    this.hoveredDataLabelRef.current = isHit;
  }
}
