import { SubscriptionTier } from '@/api/subscription-plans/types';
import { useSubscriptionPlansContext } from '@/components/subscription-plans/SubscriptionPlanContext';
import { getSubscriptionPlanTier } from '@/components/subscription-plans/utils';
import { FSDismissUpgradePlanCallToAction } from '@/lib/fullstory';
import { useLocalStorage } from '@/lib/hooks';
import { useTenantPermissions, useUserPermissions } from '@/lib/permissions/hooks';
import { Routes } from '@/lib/routes';
import { useMobile } from 'aim-utils';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useNavigationHeaderCallToActions = () => {
  const { mobileView } = useMobile();
  const router = useRouter();

  const { currentSubscriptionPlan } = useSubscriptionPlansContext();
  const { subscriptionsEnabled } = useTenantPermissions();
  const { canEditSubscriptionPlan } = useUserPermissions();

  const [hasDismissedCallToAction, setHasDismissedCallToAction] = useLocalStorage(
    'has-dismissed-navigation-header-call-to-action',
    false,
  );

  const currentSubscriptionTier = getSubscriptionPlanTier(currentSubscriptionPlan.data);

  const shouldShowCallToAction = (() => {
    if (!subscriptionsEnabled) return false;
    if (!canEditSubscriptionPlan) return false;
    if (currentSubscriptionPlan.isLoading) return false;
    if (currentSubscriptionTier !== SubscriptionTier.Free) return false;
    if (mobileView && hasDismissedCallToAction) return false;
    if (router.pathname.endsWith(Routes.SubscriptionPlans)) return false;

    return true;
  })();

  const dismissCallToAction = useCallback(
    ({ eventSource }: { eventSource: string }) => {
      return () => {
        FSDismissUpgradePlanCallToAction({ eventSource });
        setHasDismissedCallToAction(true);
      };
    },
    [setHasDismissedCallToAction],
  );

  return { shouldShowCallToAction, dismissCallToAction };
};
