import React from 'react';
import { Arc } from '@visx/shape';
import '../Score.css';
import { LoadingShimmer } from '../../Loading/Shimmer/LoadingShimmer';
import type { ArcSize } from '../utils';
import { SCORE_CONFIG } from '../utils';

const { viewBoxWidth, viewBoxHeight, outerArcRadiuses, innerArcRadiuses } = SCORE_CONFIG;

interface ScoreLoaderProps {
  arcSize?: ArcSize;
}

export const ScoreLoader = ({ arcSize = 'small' }: ScoreLoaderProps) => {
  const outerArcRadius = outerArcRadiuses[arcSize];
  const innerArcRadius = innerArcRadiuses[arcSize];

  return (
    <div
      className='aim-score'
      style={{ position: 'relative', zIndex: 1, height: '-webkit-fill-available', margin: 'auto' }}
    >
      <LoadingShimmer>
        <svg className='aim-score_overlay' viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
          <Arc
            startAngle={-Math.PI / 2}
            endAngle={Math.PI / 2}
            height='100%'
            outerRadius={outerArcRadius}
            innerRadius={innerArcRadius}
            transform='translate(512, 512)'
            strokeWidth={2}
          />
        </svg>
      </LoadingShimmer>
    </div>
  );
};
