/* eslint-disable camelcase */
import type { MonthQuarterDimension } from '../utils';
import type { LtvCacMetrics } from './[country_code].page';

export const splitByMetricAndCountry = (
  rows: LtvCacArrowRow[],
  countryCode: string,
  dateDimension: MonthQuarterDimension,
): LtvCacMetrics => {
  const metrics = {} as LtvCacMetrics;

  rows.forEach((row) => {
    Object.entries(ltvCacMetricsMap).forEach(([rawMetricName, metricName]) => {
      const metricItem = {
        date: row[dateDimension] as string,
        isForecast: row[forecastMap[rawMetricName as LtvMetrics]] ?? false,
        value: row[rawMetricName as LtvMetrics],
      };
      ((metrics[metricName] ??= {})[countryCode] ??= []).push(metricItem);
    });
  });
  return metrics;
};

export interface LtvCacArrowRow {
  cac_direct: number | null;
  cac_other: number | null;
  customer_net_ltv_36m: number | null;
  customer_net_ltv_24m: number | null;
  customer_net_ltv_18m: number | null;
  customer_net_ltv_12m: number | null;
  customer_net_ltv_6m: number | null;
  customer_net_ltv_3m: number | null;
  customer_net_ltv_1m: number | null;
  date_month?: string;
  date_quarter?: string;
  transposed_metrics_36m_is_forecast: boolean | null;
  transposed_metrics_24m_is_forecast: boolean | null;
  transposed_metrics_18m_is_forecast: boolean | null;
  transposed_metrics_12m_is_forecast: boolean | null;
  transposed_metrics_6m_is_forecast: boolean | null;
  transposed_metrics_3m_is_forecast: boolean | null;
  transposed_metrics_1m_is_forecast: boolean | null;
}

export const LtvOverCacDataset = {
  CacDirect: 'dataset-1',
  CacOther: 'dataset-2',
  Ltv36m: 'dataset-3',
  Ltv24m: 'dataset-4',
  Ltv18m: 'dataset-5',
  Ltv12m: 'dataset-6',
  Ltv6m: 'dataset-7',
  Ltv3m: 'dataset-8',
  Ltv1m: 'dataset-9',
} as const;

export const LtvOverCacDatasets = Object.values(LtvOverCacDataset);
export type LtvOverCacDatasetType = (typeof LtvOverCacDatasets)[number];

export const ltvCacMetricsMap: Record<LtvMetrics | 'cac_direct' | 'cac_other', LtvOverCacDatasetType> = {
  cac_direct: LtvOverCacDataset.CacDirect,
  cac_other: LtvOverCacDataset.CacOther,
  customer_net_ltv_36m: LtvOverCacDataset.Ltv36m,
  customer_net_ltv_24m: LtvOverCacDataset.Ltv24m,
  customer_net_ltv_18m: LtvOverCacDataset.Ltv18m,
  customer_net_ltv_12m: LtvOverCacDataset.Ltv12m,
  customer_net_ltv_6m: LtvOverCacDataset.Ltv6m,
  customer_net_ltv_3m: LtvOverCacDataset.Ltv3m,
  customer_net_ltv_1m: LtvOverCacDataset.Ltv1m,
};

type LtvMetrics =
  | 'customer_net_ltv_36m'
  | 'customer_net_ltv_24m'
  | 'customer_net_ltv_18m'
  | 'customer_net_ltv_12m'
  | 'customer_net_ltv_6m'
  | 'customer_net_ltv_3m'
  | 'customer_net_ltv_1m';

type LtvForecasts =
  | 'transposed_metrics_36m_is_forecast'
  | 'transposed_metrics_24m_is_forecast'
  | 'transposed_metrics_18m_is_forecast'
  | 'transposed_metrics_12m_is_forecast'
  | 'transposed_metrics_6m_is_forecast'
  | 'transposed_metrics_3m_is_forecast'
  | 'transposed_metrics_1m_is_forecast';

export const forecastMap: Record<LtvMetrics, LtvForecasts> = {
  customer_net_ltv_36m: 'transposed_metrics_36m_is_forecast',
  customer_net_ltv_24m: 'transposed_metrics_24m_is_forecast',
  customer_net_ltv_18m: 'transposed_metrics_18m_is_forecast',
  customer_net_ltv_12m: 'transposed_metrics_12m_is_forecast',
  customer_net_ltv_6m: 'transposed_metrics_6m_is_forecast',
  customer_net_ltv_3m: 'transposed_metrics_3m_is_forecast',
  customer_net_ltv_1m: 'transposed_metrics_1m_is_forecast',
};
