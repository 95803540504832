import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import type {
  IPointMarkerOptions,
  IRenderableSeries,
  SciChartSurface,
  VerticalLineAnnotation,
  TextAnnotation,
} from 'scichart';
import { EllipsePointMarker, XyDataSeries, XyScatterRenderableSeries } from 'scichart';
import {
  areEqualHoverPointMarkers,
  createDatasetHoverTextAnnotation,
  createDatasetHoverVerticalLineAnnotation,
  handleMiss,
  updateHoverPointMarkerRenderableSeries,
} from '../utils/hover-utils';
import { getPointerEventPosition } from '../utils/events';
import type { OnDatasetHover, SeriesMetadataMap } from '../types';
import { traverseRenderableSeries } from '../utils/dataseries-utils';

export type HoverPointMarker = {
  x: number;
  y: number;
  color: string;
  yAxis: string;
  isActive: boolean;
};

export const POINT_MARKER_STYLE = {
  width: 4,
  height: 4,
  stroke: 'white',
  strokeThickness: 1,
} satisfies IPointMarkerOptions;

export const HOVERED_POINT_RADIUS = 8;

export const HOVERED_POINT_MARKER_STYLE = {
  ...POINT_MARKER_STYLE,
  width: HOVERED_POINT_RADIUS,
  height: HOVERED_POINT_RADIUS,
} satisfies IPointMarkerOptions;

export const useHoverPointMarker = ({ surfaceRef }: { surfaceRef: MutableRefObject<SciChartSurface | null> }) => {
  const hoverPointMarkerRenderableSeriesRef = useRef<XyScatterRenderableSeries[]>([]);
  const markerRef = useRef<HoverPointMarker[]>([]);
  const hoveredMarkerRef = useRef<HoverPointMarker>();

  const removeHoverPointMarkers = useCallback(() => {
    const surface = surfaceRef.current;
    if (!surface) return;

    hoverPointMarkerRenderableSeriesRef.current.forEach((series) => {
      surface.renderableSeries.remove(series, true);
    });

    hoverPointMarkerRenderableSeriesRef.current = [];
    markerRef.current = [];
  }, [surfaceRef]);

  const updateHoverPointMarker = useCallback(
    (markers: HoverPointMarker[], hoveredMarker: HoverPointMarker) => {
      // * If the markers haven't changed, we don't need to do anything.
      if (
        markers.length === markerRef.current.length &&
        markers.every((marker, i) => areEqualHoverPointMarkers(marker, markerRef.current[i]))
      ) {
        return;
      }

      markerRef.current = markers;

      if (!surfaceRef.current) return;

      const isAllMarkersTheSame = markers.every((marker, i) =>
        areEqualHoverPointMarkers(marker, markerRef.current[i], true),
      );

      // If we are hovering a new series we need to recreate all points
      const numberOfPreviousMarkers =
        isAllMarkersTheSame && !areEqualHoverPointMarkers(hoveredMarker, hoveredMarkerRef.current)
          ? 0
          : hoverPointMarkerRenderableSeriesRef.current.length;
      const markersToUpdate = markers.slice(0, numberOfPreviousMarkers);
      const markersToCreate = markers.slice(numberOfPreviousMarkers);
      const markersToRemove = hoverPointMarkerRenderableSeriesRef.current.slice(
        numberOfPreviousMarkers,
        hoverPointMarkerRenderableSeriesRef.current.length,
      );

      // * Update existing markers (so we don't have to allocate new memory as a part of creating new series)
      if (markersToUpdate.length > 0) {
        for (const [i, marker] of markersToUpdate.entries()) {
          const pointMarkerSeries = hoverPointMarkerRenderableSeriesRef.current[i];

          updateHoverPointMarkerRenderableSeries({
            series: pointMarkerSeries,
            marker,
          });
        }
      }

      hoveredMarkerRef.current = hoveredMarker;

      // * Add new markers (by creating brand new series)
      const context = surfaceRef.current.webAssemblyContext2D;
      for (const marker of markersToCreate) {
        // * We only create a new series if there's no existing one.
        const pointMarkerSeries = new XyScatterRenderableSeries(context, {
          pointMarker: new EllipsePointMarker(context, {
            ...(marker.isActive ? HOVERED_POINT_MARKER_STYLE : POINT_MARKER_STYLE),
            fill: marker.color,
          }),
          dataSeries: new XyDataSeries(context, { xValues: [marker.x], yValues: [marker.y], isSorted: true }),
          yAxisId: marker.yAxis,
        });

        surfaceRef.current.renderableSeries.add(pointMarkerSeries);
        hoverPointMarkerRenderableSeriesRef.current.push(pointMarkerSeries);
      }

      // * Remove excessive markers (if there are fewer markers than before, we can remove the series that are no longer needed)
      for (const marker of markersToRemove) {
        surfaceRef.current.renderableSeries.remove(marker, true);
      }
    },
    [surfaceRef],
  );

  return {
    updateHoverPointMarker,
    removeHoverPointMarkers,
  };
};

export const useDatasetMobileHover = ({
  surfaceRef,
  seriesMetadataRef,
  enabled,
}: {
  surfaceRef: MutableRefObject<SciChartSurface | null>;
  seriesMetadataRef: MutableRefObject<SeriesMetadataMap>;
  enabled: boolean;
}) => {
  const verticalLineAnnotationRef = useRef<VerticalLineAnnotation | null>(null);
  const textAnnotationRef = useRef<TextAnnotation | null>(null);

  const enabledRef = useRef(enabled);

  useEffect(() => {
    enabledRef.current = enabled;
  }, [enabled]);

  const updateVerticalHoverLine = useCallback(
    ({ xValue }: { xValue: number }) => {
      const surface = surfaceRef.current;
      if (!surface) return;

      if (verticalLineAnnotationRef.current) {
        verticalLineAnnotationRef.current.x1 = xValue;
        return;
      }

      const yAxisId = surface.yAxes.asArray()[0].id;

      const verticalLine = createDatasetHoverVerticalLineAnnotation({ xValue, yAxisId });
      surface.annotations.add(verticalLine);
      verticalLineAnnotationRef.current = verticalLine;
    },
    [surfaceRef],
  );

  const removeVerticalHoverLine = useCallback(() => {
    const surface = surfaceRef.current;
    if (!surface) return;

    if (verticalLineAnnotationRef.current) {
      surface.annotations.remove(verticalLineAnnotationRef.current, true);
      verticalLineAnnotationRef.current = null;
    }
  }, [surfaceRef]);

  const updateTextAnnotation = useCallback(
    ({ xValue }: { xValue: number }) => {
      const surface = surfaceRef.current;
      if (!surface) return;

      if (textAnnotationRef.current) {
        textAnnotationRef.current.x1 = xValue;
        textAnnotationRef.current.text = new Date(xValue * 1000).toISOString().slice(0, 10);
        return;
      }
      const yAxisId = surface.yAxes.asArray()[0].id;

      const textAnnotation = createDatasetHoverTextAnnotation({ xValue, yAxisId });
      surface.annotations.add(textAnnotation);
      textAnnotationRef.current = textAnnotation;
    },
    [surfaceRef],
  );

  const removeTextAnnotation = useCallback(() => {
    const surface = surfaceRef.current;
    if (!surface) return;

    if (textAnnotationRef.current) {
      surface.annotations.remove(textAnnotationRef.current);
      textAnnotationRef.current = null;
    }
  }, [surfaceRef]);

  const handleDatasetHoverAnnotation = useCallback(
    (e: PointerEvent | undefined) => {
      if (!surfaceRef.current) return;

      let isHittingSomeSeries = false;
      if (enabledRef.current === false || !e) {
        removeVerticalHoverLine();
        removeTextAnnotation();
        return;
      }

      const { x, y } = getPointerEventPosition(e);
      const series = surfaceRef.current.renderableSeries.asArray();

      const handleMouseOver = (serie: IRenderableSeries, x: number, y: number) => {
        if (!serie.isVisible) return;

        const dataset = seriesMetadataRef.current.get(serie.id)?.dataset;
        if (!dataset) return;

        const hitTestInfo = serie.hitTestProvider.hitTest(x, y);
        if (!hitTestInfo.isHit) return;

        isHittingSomeSeries = true;

        const xValue = hitTestInfo.xValue;

        updateVerticalHoverLine({ xValue });
        updateTextAnnotation({ xValue });
      };

      if (!isHittingSomeSeries) {
        removeVerticalHoverLine();
        removeTextAnnotation();
      }

      traverseRenderableSeries(series, (serie) => handleMouseOver(serie, x, y));
    },
    [
      removeTextAnnotation,
      removeVerticalHoverLine,
      seriesMetadataRef,
      surfaceRef,
      updateTextAnnotation,
      updateVerticalHoverLine,
    ],
  );

  useEffect(() => {
    if (!enabled) {
      removeVerticalHoverLine();
      removeTextAnnotation();
      const series = surfaceRef.current?.renderableSeries.asArray() ?? [];
      traverseRenderableSeries(series, (serie) => {
        const dataset = seriesMetadataRef.current.get(serie.id)?.dataset;
        if (!dataset) return;
        handleMiss(serie, dataset);
      });
    }
  }, [enabled, removeTextAnnotation, removeVerticalHoverLine, seriesMetadataRef, surfaceRef]);

  return { handleDatasetHoverAnnotation };
};

/** Hook responsible for **emitting** data about the hovered dataset, through the `onDatasetHover` prop.
 * This makes it possible for the parent component to utilize this information. */
export const useOnDatasetHover = ({
  surfaceRef,
  seriesMetadataRef,
  onDatasetHover: _onDatasetHover,
}: {
  surfaceRef: MutableRefObject<SciChartSurface | null>;
  seriesMetadataRef: MutableRefObject<SeriesMetadataMap>;
  onDatasetHover: OnDatasetHover | undefined;
}) => {
  const onDatasetHoverRef = useRef(_onDatasetHover);

  if (_onDatasetHover !== onDatasetHoverRef.current) {
    onDatasetHoverRef.current = _onDatasetHover;
  }

  /** Emits hover information through `onDatasetHover`. */
  const handleOnDatasetHover = (pointerEvent: PointerEvent) => {
    if (!surfaceRef.current) return;

    const { x, y } = getPointerEventPosition(pointerEvent);
    const series = surfaceRef.current.renderableSeries.asArray();

    let isHittingSomeSeries = false;

    const handleMouseOver = (serie: IRenderableSeries, x: number, y: number) => {
      if (!onDatasetHoverRef.current) return;
      if (!serie.isVisible) return;

      const dataset = seriesMetadataRef.current.get(serie.id)?.dataset;
      if (!dataset) return;

      const hitTestInfo = serie.hitTestProvider.hitTest(x, y);
      if (!hitTestInfo.isHit) return;

      isHittingSomeSeries = true;

      const dataPointIndex = dataset.data.findIndex(
        (dataPoint) => new Date(dataPoint.x).getTime() === hitTestInfo.xValue * 1000,
      );

      if (dataPointIndex === -1) return;

      onDatasetHoverRef.current({
        xValue: hitTestInfo.xValue,
        yValue: hitTestInfo.yValue,
        dataset,
        dataPoint: dataset.data[dataPointIndex],
        dataPointIndex,
      });
    };

    if (!isHittingSomeSeries) {
      onDatasetHoverRef.current?.(null);
    }

    traverseRenderableSeries(series, (serie) => handleMouseOver(serie, x, y));
  };

  return { handleOnDatasetHover };
};
