import React from 'react';
import { Arc } from '@visx/shape';
import { max } from 'd3';
import { scoreCountUpColors } from './ScoreCountUp/utils';

interface ScoreGradeProps {
  svgWidth: number;
  svgHeight: number;
  outerRadius: number;
  innerRadius: number;
  displayTicks?: boolean;
}

export const ScoreGrade = ({
  svgWidth,
  svgHeight,
  outerRadius,
  innerRadius,
  displayTicks = false,
}: ScoreGradeProps) => {
  const arcs = scoreCountUpColors.map((color, i) => ({
    startAngle: -Math.PI / 2 + (i / 5) * Math.PI,
    endAngle: -Math.PI / 2 + ((i + 1) / 5) * Math.PI,
    color: color,
  }));
  return (
    <g transform={`translate(${svgWidth / 2}, ${svgHeight})`}>
      {arcs.map((arc, i) => (
        <g key={arc.color}>
          <Arc
            startAngle={arc.startAngle}
            endAngle={arc.endAngle}
            height='100%'
            outerRadius={outerRadius}
            innerRadius={innerRadius}
            fill={arc.color}
          />
          {displayTicks && (
            <text
              textAnchor={i > 1 ? 'start' : 'end'}
              className='aim-score-ticks'
              transform={`translate(${Math.sin(arc.endAngle) * (innerRadius - 20)},-${max([
                Math.cos(arc.endAngle) * (innerRadius - 20),
                0,
              ])} )`}
            >
              {i + 1}
            </text>
          )}
        </g>
      ))}
      {displayTicks && (
        <text textAnchor='start' className='aim-score-ticks' transform={`translate(-${innerRadius - 5},0)`}>
          0
        </text>
      )}
    </g>
  );
};
