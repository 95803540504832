import type { ComponentProps, ReactNode } from 'react';
import React from 'react';
import styles from './DefaultPageWrapper.module.css';
import cn from 'classnames';

type DefaultPageWrapperProps = ComponentProps<'div'> & {
  children: ReactNode;
  heading?: ReactNode;
};

export const DefaultPageWrapper = ({ heading, children, className, ...props }: DefaultPageWrapperProps) => {
  return (
    <div className={cn(styles.page, className)} {...props}>
      <div className={styles.container}>
        {heading && <div className={styles.heading}>{heading}</div>}
        {children}
      </div>
    </div>
  );
};
