import React, { useState } from 'react';
import styles from './NotificationsSidePanel.module.css';
import { NotificationCard } from './notificationsCard/NotificationCard';
import { Button, Icon, MobileTakeover, Typography } from 'aim-components';
import { useTranslation } from 'next-i18next';
import type { Notification } from '@/api/notifications/index.page';
import type { NotificationsSidePanelProps } from './NotificationsSidePanel';
import { NotificationSettings } from '@/components/settings/NotificationsSettings';

export const NotificationsCenterMobile = ({
  isOpened,
  notifications,
  closeSidePanel,
  changeReadStatus,
}: NotificationsSidePanelProps & {
  changeReadStatus: (updatedNotification: Notification, markAsRead: boolean) => Promise<boolean | undefined>;
}) => {
  const { t } = useTranslation('notifications', { keyPrefix: 'sidePanel' });
  const [shownNotificationPreference, setShowNotificationPreferences] = useState(false);

  return (
    <>
      <MobileTakeover
        open={isOpened}
        onClose={closeSidePanel}
        title={
          <div className={styles.mobileNotificationCenterTitle}>
            <Icon icon='bell' />
            <Typography variant='label1'>{t('title')}</Typography>
          </div>
        }
      >
        <div className={styles.mobileTakeover}>
          <div className={styles.contentContainer}>
            {Array.isArray(notifications) && notifications.length > 0 ? (
              <div className={styles.scrollableContent}>
                {notifications?.map((notification) => {
                  return (
                    <NotificationCard
                      changeReadStatus={changeReadStatus}
                      key={notification.reference}
                      notification={notification}
                    />
                  );
                })}
              </div>
            ) : (
              <div className={styles.noNotifications}>
                <Typography variant='label3' color='secondary'>
                  {t('noNotifications')}
                </Typography>
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <Button
              startIcon='settings'
              type='tertiary'
              color='white'
              onClick={() => {
                setShowNotificationPreferences(true);
              }}
            >
              {t('notificationPreferences')}
            </Button>
          </div>
        </div>
      </MobileTakeover>
      <MobileTakeover
        open={shownNotificationPreference}
        title={
          <div className={styles.notificationPreferencesTitle}>
            <Icon
              icon='arrow-left'
              className='ModalHeader-close'
              role='button'
              size='large'
              onClick={() => setShowNotificationPreferences(false)}
            />

            <Typography className={styles.notificationPreferencesText} variant='label1'>
              {t('notificationPreferences')}
            </Typography>
          </div>
        }
      >
        <div className={styles.mobileTakeover}>
          <NotificationSettings />
        </div>
      </MobileTakeover>
    </>
  );
};
