export interface SortableRow {
  label: string;
}

export type SortOrder = 'asc' | 'desc';

type TableRow<T extends string> = Record<T, unknown & SortableRow>;

const svCollator = new Intl.Collator('sv', { numeric: true });

export const sortRows = <TColumns extends string>(
  a: Partial<TableRow<TColumns>>,
  b: Partial<TableRow<TColumns>>,
  sortColumn: TColumns,
  sortOrder: SortOrder,
): number => {
  if (!a[sortColumn] || !b[sortColumn]) return 0;
  const labelA = a[sortColumn]?.label;
  const labelB = b[sortColumn]?.label;
  if (!labelA || !labelB) return 0;
  if (sortOrder === 'desc') {
    return svCollator.compare(labelA, labelB);
  }
  if (sortOrder === 'asc') {
    return svCollator.compare(labelB, labelA);
  }
  return 0;
};
