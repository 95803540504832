import { ContextMenu, Popover, TextInput, Typography, MobileFullModal } from 'aim-components';
import { TenantListItem } from './TenantListItem';
import styles from './TenantSearch.module.css';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useTenantSearch } from './hooks';

// * Improvement idea: add keyboard navigation to the search results, see https://floating-ui.com/docs/useListNavigation
export const TenantSearch = ({ tenants }: { tenants: string[] }) => {
  const { t } = useTranslation('common', { keyPrefix: 'tenantContextMenu' });
  const { searchString, onSearchChange, filteredTenants, noMatchingResults } = useTenantSearch({ tenants });
  const [showTenantSearch, setShowTenantSearch] = useState(false);

  // * The user is actively searching for a tenant, so we don't want to dismiss the popover when the user hovers outside of it, as the list shrinks in height as the results are filtered.
  const disableHoverOutsideDismiss = searchString.length > 0;

  return (
    <Popover
      open={showTenantSearch}
      onOpenChange={setShowTenantSearch}
      disableHover={disableHoverOutsideDismiss}
      renderPopoverTrigger={(triggerProps) => (
        <div {...triggerProps}>
          <OpenTenantSearchListItem />
        </div>
      )}
    >
      <ContextMenu className={styles.tenantSearchContextMenu}>
        <ContextMenu.Search
          value={searchString}
          onChange={onSearchChange}
          placeholder={t('searchPlaceholder')}
          autoFocus
        />
        <ContextMenu.List>
          {filteredTenants.map((tenant) => (
            <TenantListItem key={tenant} tenant={tenant} />
          ))}
          {noMatchingResults && <TenantSearchNoMatches />}
        </ContextMenu.List>
      </ContextMenu>
    </Popover>
  );
};

export const TenantSearchMobile = ({
  open,
  onClose,
  tenants,
}: {
  open: boolean;
  onClose: () => void;
  tenants: string[];
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'tenantContextMenu' });
  const { searchString, onSearchChange, clearSearch, filteredTenants, noMatchingResults } = useTenantSearch({
    tenants,
  });

  useEffect(() => clearSearch(), [open, clearSearch]);

  return (
    <MobileFullModal open={open} onBack={onClose} title='Search'>
      <div className={styles.mobileSearch}>
        <div className={styles.mobileSearchBarContainer}>
          <TextInput
            value={searchString}
            onChange={onSearchChange}
            placeholder={t('searchPlaceholder')}
            autoFocus
            showValidationErrors={false}
          />
        </div>
        <div className={styles.mobileSearchResultsList}>
          {filteredTenants.map((tenant) => (
            <TenantListItem key={tenant} tenant={tenant} onClick={onClose} />
          ))}
        </div>
        {noMatchingResults && <TenantSearchNoMatches />}
      </div>
    </MobileFullModal>
  );
};

export const OpenTenantSearchListItem = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation('common', { keyPrefix: 'tenantContextMenu' });

  return (
    <ContextMenu.ListItem label={t('searchForTenant')} icon='search' endIcon='nav-forward-chevron' onClick={onClick} />
  );
};

export const TenantSearchNoMatches = () => {
  const { t } = useTranslation('common', { keyPrefix: 'tenantContextMenu' });

  return (
    <div className={styles.noSearchMatches}>
      <Typography variant='text2' color='secondary'>
        {t('noSearchMatches')}
      </Typography>
    </div>
  );
};
