import React from 'react';
import { Icon } from '../../Icon';
import styles from './ConnectorAdd.module.css';

interface ConnectorAddProps {
  searchString: string;
  handleAddConnector?: (searchString: string) => void;
}

const ConnectorAdd = ({ searchString, handleAddConnector }: ConnectorAddProps) => {
  return (
    <div className={styles.container} onClick={() => handleAddConnector && handleAddConnector(searchString)}>
      <div className={styles.label}>{`Add "${searchString}"`}</div>
      <Icon icon={'add'} />
    </div>
  );
};

export default ConnectorAdd;
