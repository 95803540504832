import { ApiRoutes } from '../pages/apiRoutes';
import clientRequest from '@/lib/request/request';
import type { JSONValue } from 'aim-utils';
import { mutate } from 'swr';
import useSWRImmutable from 'swr/immutable';

type UserSettingValueMap = {
  'score-wizard': {
    isFinished: boolean;
  };
  'decks-tour': {
    isFinished: boolean;
  };
  'forecasting-tour': {
    isFinished: boolean;
  };
  'growth-chat-wizard': {
    isFinished: boolean;
  };
  'preferred-tenant': {
    id: string;
  };
  'recent-tenants': {
    tenants: string[];
  };
  'welcome-screen': {
    hasBeenSeen: boolean;
  };
  'enter-gilion': {
    hasBeenSeen: boolean;
  };
  'validation-banner': {
    display: boolean;
  };
};

export type UserSettingKey = Lowercase<keyof UserSettingValueMap>;

type UserSettingValue = Record<string, JSONValue>;

type UserSettingFetchResponse<T extends UserSettingValueMap[UserSettingKey]> =
  | {
      found: true;
      value: T;
    }
  | { found: false; value: undefined };

interface UserSettingMutateResponse {
  success: boolean;
  error?: string;
}

export const userSettingUrl = (key: UserSettingKey): string => {
  return ApiRoutes.UserSettings.replace('[setting-key]', key);
};

export const fetchUserSetting = async <TSettingValue extends UserSettingValueMap[UserSettingKey] & UserSettingValue>(
  url: string,
): Promise<TSettingValue | undefined> => {
  const result = await clientRequest<UserSettingFetchResponse<TSettingValue>>({ method: 'GET', url });
  return result.found ? result.value : undefined;
};

export const setUserSetting = async <TSetting extends UserSettingKey>(
  setting: TSetting,
  value: UserSettingValueMap[TSetting],
) => {
  const url = ApiRoutes.UserSettings.replace('[setting-key]', setting);
  const result = await clientRequest<UserSettingMutateResponse>({ method: 'PUT', url, data: { value } });
  await mutate(url);
  return result;
};

export const deleteUserSetting = async (setting: UserSettingKey) => {
  const url = ApiRoutes.UserSettings.replace('[setting-key]', setting);
  const result = await clientRequest<UserSettingMutateResponse>({ method: 'DELETE', url });
  await mutate(url);
  return result;
};

export const deleteAllUserSettings = async () => {
  const url = ApiRoutes.UserSettings.replace('[setting-key]', '');
  const result = await clientRequest<UserSettingMutateResponse>({ method: 'DELETE', url });
  await mutate(url);
  return result;
};

export const useUserSetting = <TSetting extends UserSettingKey>(setting: TSetting | null) => {
  return useSWRImmutable<UserSettingValueMap[TSetting] | undefined>(
    setting ? userSettingUrl(setting) : null,
    fetchUserSetting,
  );
};
