import type { CreateCustomerPortalSessionResponse } from '@/api/subscription-plans/customer-portal/index.page';
import { SubscriptionTier, type SubscriptionPlanTier } from '@/api/subscription-plans/types';
import type { SubscriptionProductPriceCurrency } from '@/api/user/subscription/products/index.page';
import type { RefreshSubscriptionBody } from '@/api/user/subscription/subscription/refresh/index.page';
import type { SubscriptionPlan } from '@/api/user/subscription/subscriptions/utils';
import { useSubscriptionPlansContext } from '@/components/subscription-plans/SubscriptionPlanContext';
import { formatPrice } from '@/components/subscription-plans/utils';
import { FSOpenStripeCustomerPortal } from '@/lib/fullstory';
import clientRequest from '@/lib/request/request';
import { toast } from 'aim-components';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ApiRoutes } from 'pages/apiRoutes';
import { useCallback, useMemo } from 'react';
import { mutate } from 'swr';

/**
 * @returns The localized name for the given subscription plan tier, from i18n.
 */
export const useSubscriptionPlanName = (tier: SubscriptionPlanTier) => {
  const { t } = useTranslation('common', { keyPrefix: 'subscriptionPlan' });
  return t(`${tier}.name`);
};

/**
 * @returns The localized description for the given subscription plan tier, from i18n.
 */
export const useSubscriptionPlanDescription = (tier: SubscriptionPlanTier) => {
  const { t } = useTranslation('common', { keyPrefix: 'subscriptionPlan' });
  return t(`${tier}.description`);
};

// * Mapping for dynamic values in i18n feature strings. This has nothing to do with the actual cost of the subscription plans.
const tierDataCostsPerMonth = {
  [SubscriptionTier.Free]: {
    EUR: 50,
    USD: 50,
    SEK: 500,
    GBP: 50,
  },
  [SubscriptionTier.Plus]: {
    EUR: 100,
    USD: 100,
    SEK: 1000,
    GBP: 100,
  },
  [SubscriptionTier.Ultimate]: {
    EUR: 200,
    USD: 200,
    SEK: 2000,
    GBP: 200,
  },
} as const satisfies Record<SubscriptionPlanTier, Record<SubscriptionProductPriceCurrency, number>>;

/**
 * @returns A list of localized features for the given subscription plan tier, from i18n.
 */
export const useSubscriptionPlanFeatures = (tier: SubscriptionPlanTier) => {
  const { t } = useTranslation('common', { keyPrefix: 'subscriptionPlan' });
  const { currency } = useSubscriptionPlansContext();

  const costPerMonth = useMemo(() => {
    if (!currency) return 'N/A';
    const constAmount = tierDataCostsPerMonth[tier][currency];
    return formatPrice(constAmount, currency);
  }, [tier, currency]);

  const { features = [] } = t(tier, {
    returnObjects: true,
    dataCostsUpTo: costPerMonth,
  });

  return { features } as const;
};

/**
 * @returns Localized data for the given subscription plan tier, from i18n.
 */
export const useSubscriptionPlanData = (tier: SubscriptionPlanTier) => {
  const name = useSubscriptionPlanName(tier);
  const description = useSubscriptionPlanDescription(tier);
  const { features } = useSubscriptionPlanFeatures(tier);

  return { tier, name, description, features } as const;
};

/**
 * Opens the Stripe customer portal, allowing the user to manage their subscription.
 */
export const useStripeCustomerPortal = () => {
  const { t } = useTranslation('common', { keyPrefix: 'subscriptionPlan' });
  const router = useRouter();

  const openStripeCustomerPortal = useCallback(async () => {
    FSOpenStripeCustomerPortal();

    try {
      const response = await clientRequest<CreateCustomerPortalSessionResponse>({
        url: ApiRoutes.SubscriptionPlansCustomerPortal,
        method: 'POST',
      });

      await router.replace(response.url);
    } catch (error) {
      console.error(error);
      toast({ message: t('request.createCustomerPortalSession.error'), type: 'critical' });
    }
  }, [router, t]);

  return { openStripeCustomerPortal } as const;
};

/**
 * ♻️ Hook used to refresh a subscription plan, ensures that the subscription plan is up-to-date with the data in `aim-tenant-management`.
 * When `aim-tenant-management` indicates that it's up to date, we mutate the SWR call for the active subscription plans, to keep the data in sync.
 */
export const useRefreshSubscription = () => {
  const { t } = useTranslation('common', { keyPrefix: 'subscriptionPlan' });

  const refreshSubscription = useCallback(
    async (stripeSubscriptionReference: string) => {
      try {
        const response = await clientRequest<SubscriptionPlan>({
          url: ApiRoutes.RefreshSubscription,
          method: 'POST',
          data: { stripeSubscriptionReference } satisfies RefreshSubscriptionBody,
        });

        await mutate(ApiRoutes.Subscriptions);

        return response;
      } catch (error) {
        console.error(error);
        toast({ message: t('request.refreshSubscription.error'), type: 'critical' });
      }
    },
    [t],
  );

  return { refreshSubscription } as const;
};
