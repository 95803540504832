import React from 'react';
import { Icon, type IconName } from '../../Icon';
import styles from './SteppedProgressBar.module.css';

interface SteppedProgressBarSection {
  filled: boolean;
  icon?: IconName;
}

interface SteppedProgressBarProps {
  sections: SteppedProgressBarSection[];
}

export const SteppedProgressBar = ({ sections }: SteppedProgressBarProps) => {
  if (sections.length === 0) return null;

  return (
    <div className={styles.progressBar}>
      {sections.map((section, i) => (
        <React.Fragment key={i}>
          <div data-filled={section.filled} className={styles.progressBarSection} />
          {section.icon && (
            <div className={styles.progressBarIcon} data-filled={section.filled}>
              <Icon icon={section.icon} />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
