import { useCallback, type Dispatch, type SetStateAction } from 'react';
import { BottomSheet, ContextMenu } from 'aim-components';
import { useTenantPermissions, useUserPermissions } from '@/lib/permissions/hooks';
import type { SettingsTab } from 'pages/[slug]/settings/utils';
import { getPermittedSettings } from 'pages/[slug]/settings/utils';
import { useLogout } from '../hooks';
import { useTranslation } from 'next-i18next';
import { useSettingsNavigation } from '@/components/settings/hooks';
import { useNavigationContext } from '@/components/layouts/navigation/navigation-menu/NavigationContext';

export const SettingsMenuMobile = ({
  showProfileMenu,
  setShowProfileMenu,
}: {
  showProfileMenu: boolean;
  setShowProfileMenu: Dispatch<SetStateAction<boolean>>;
}) => {
  const { performLogout } = useLogout();
  const { t } = useTranslation('settings');

  const { hasAdminPrivileges, hasDeckViewerRole } = useUserPermissions();
  const { subscriptionsEnabled } = useTenantPermissions();
  const { goToSettingsTab } = useSettingsNavigation();
  const { collapseNavigationMenu } = useNavigationContext();

  const permittedSettings = getPermittedSettings({
    hasAdminPrivileges,
    hasDeckViewerRole,
    hasSubscriptionsEnabled: subscriptionsEnabled,
  });

  const navigateToSettingsTab = useCallback(
    (tab: SettingsTab) => {
      return () => {
        collapseNavigationMenu();
        setShowProfileMenu(false);
        goToSettingsTab(tab);
      };
    },
    [collapseNavigationMenu, goToSettingsTab, setShowProfileMenu],
  );

  return (
    <>
      <BottomSheet open={showProfileMenu} onClose={() => setShowProfileMenu(false)}>
        <>
          <ContextMenu.Title>Settings</ContextMenu.Title>

          {permittedSettings.includes('account') && (
            <ContextMenu.ListItem label={t('account')} icon='user' onClick={navigateToSettingsTab('account')} />
          )}
          {permittedSettings.includes('subscription') && (
            <ContextMenu.ListItem
              label={t('subscription')}
              icon='crown'
              onClick={navigateToSettingsTab('subscription')}
            />
          )}
          {permittedSettings.includes('user-management') && (
            <ContextMenu.ListItem
              label={t('user-management')}
              icon='user-gear'
              onClick={navigateToSettingsTab('user-management')}
            />
          )}
          {permittedSettings.includes('notifications') && (
            <ContextMenu.ListItem
              label={t('notifications')}
              icon='bell'
              onClick={navigateToSettingsTab('notifications')}
            />
          )}
          {permittedSettings.includes('admin-only') && (
            <ContextMenu.ListItem label={t('admin-only')} icon='lock' onClick={navigateToSettingsTab('admin-only')} />
          )}

          <ContextMenu.Title>Account</ContextMenu.Title>
          <ContextMenu.ListItem
            label='Log out'
            icon='log-out'
            onClick={() => {
              setShowProfileMenu(false);
              performLogout();
            }}
          />
        </>
      </BottomSheet>
    </>
  );
};
