import React, { useCallback, useState } from 'react';
import { HeaderFooterModal } from 'aim-components';
import { useTranslation } from 'next-i18next';

interface ConfirmationModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onConfirm: () => void;
  destructive: boolean;
}

const ConfirmationModal = ({ showModal, setShowModal, onConfirm, destructive }: ConfirmationModalProps) => {
  const { t } = useTranslation('settings', { keyPrefix: 'confirmationModal' });
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = useCallback(async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    setShowModal(false);
  }, [onConfirm, setShowModal]);

  return (
    <HeaderFooterModal
      onClose={() => setShowModal(false)}
      open={showModal}
      title={t('areYouSure')}
      primaryButton={{
        loading: isLoading,
        title: t('imSure'),
        color: destructive ? 'destructive' : undefined,
        onClick: () => {
          handleConfirm();
        },
      }}
      secondaryButton={{
        title: t('iveChangedMyMind'),
        onClick: () => setShowModal(false),
        type: 'tertiary',
        color: 'blue',
      }}
    >
      <div style={{ width: '400px' }}></div>
    </HeaderFooterModal>
  );
};

export default ConfirmationModal;
