import { Typography, Icon } from 'aim-components';
import type { ComponentProps } from 'react';
import { forwardRef } from 'react';
import cn from 'classnames';
import styles from './TenantSelectorTrigger.module.css';

type TenantSelectorTriggerProps = {
  tenant: string;
  icon: 'dropdown-up' | 'dropdown-down' | null;
} & ComponentProps<'button'>;

export const TenantSelectorTrigger = forwardRef<HTMLButtonElement, TenantSelectorTriggerProps>(
  ({ tenant, icon, className, ...props }, ref) => {
    return (
      <button ref={ref} className={cn(styles.tenantSelector, className)} {...props}>
        <Typography variant='text1' truncate>
          {tenant}
        </Typography>
        {icon && <Icon icon={icon} size='xs' />}
      </button>
    );
  },
);

TenantSelectorTrigger.displayName = 'TenantSelectorTrigger';
