import React from 'react';
import type { ChartTooltipProps } from 'aim-components';
import { Tooltip, TooltipContainer } from 'aim-components';

export const CharlieTooltip = (props: ChartTooltipProps) => {
  return (
    <svg>
      <foreignObject x={10} width='100%' height='100%'>
        <TooltipContainer.Standalone>
          <Tooltip {...props} />
        </TooltipContainer.Standalone>
      </foreignObject>
    </svg>
  );
};
