import { useCallback, useRef, useState, useEffect, startTransition, useLayoutEffect, useMemo } from 'react';
import type { ChartArea, ChartData, Point } from 'chart.js';
import type { Option } from '../Input/Select/Select';
import type { ChartType } from './chartDataConfig';
import {
  getCommonChartDatasetConfig,
  getChartTypeDatasetConfig,
  getFillData,
  greyedOutData,
  hideHoverPoint,
} from './chartDataConfig';
import type { ChartDataset, ChartSlider, ChartYAxis, TimeGranularity } from './types';
import {
  areEqualChartAreas,
  getMaxYValueInDatasets,
  getMinYValueInDatasets,
  extendDatasetWithLabels,
  getSafeSliderIndexRange,
  getClosestTickIndex,
  getYMax,
} from './utils';
import type { Options as DataLabelOptions } from 'chartjs-plugin-datalabels/types/options';
import { getDateInterval } from '../../utils/date-utils';
import type { Chart as ChartJS } from 'chart.js';
import { useMobile } from 'aim-utils';

export function useSelectedDatasets(datasets: ChartDataset[], selectedOptions: Option[] = [], removeData?: boolean) {
  const selectedDatasetLabels: string[] = useMemo(() => selectedOptions.map((item) => item.label), [selectedOptions]);

  const selectedDataset: ChartDataset[] = useMemo(
    () =>
      removeData
        ? datasets.filter(
            (item) =>
              (item.filterable === false && !item.connectedWith) ||
              selectedDatasetLabels.includes(item.label) ||
              (item.connectedWith && selectedDatasetLabels.includes(item.connectedWith)),
          )
        : datasets,
    [datasets, removeData, selectedDatasetLabels],
  );
  return { selectedDatasetLabels, selectedDataset };
}

export function useChartData(
  datasets: ChartDataset[],
  selectedOptions: Option[] = [],
  chartRef: ChartJS | null,
  removeData?: boolean,
  unit?: string,
  hoveredDatasetLabel?: string,
  xMinDateString?: string,
  xMaxDateString?: string,
  expanded?: boolean,
): ChartData<ChartType> {
  const [chartData, setChartData] = useState<ChartData<ChartType>>({ datasets: [] });
  const [chartDataWithDataLabels, setChartDataWithDataLabels] = useState<ChartData<ChartType>>({ datasets: [] });
  const { mobileView } = useMobile();

  const { selectedDataset, selectedDatasetLabels } = useSelectedDatasets(datasets, selectedOptions, removeData);

  const isStacked = useMemo(() => (datasets ? datasets.some((dataset) => dataset.isStacked) : false), [datasets]);

  const selectedTab = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (selectedOptions[0]?.tab) {
      selectedTab.current = selectedOptions[0].tab;
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (!datasets) {
      return;
    }

    const chartData = {
      datasets: (selectedDataset ?? [])
        .filter((item) => (selectedTab.current ? item.tab === selectedTab.current : true))
        .sort((a, b) => a.order ?? 0 - (b.order ?? 0))
        .map((dataset, index) => {
          const isDataGreyedOut =
            dataset.filterable !== false && removeData !== true && !selectedDatasetLabels.includes(dataset.label);

          const isDatasetHovered =
            hoveredDatasetLabel === dataset.label ||
            (dataset.connectedWith !== undefined && hoveredDatasetLabel === dataset.connectedWith);

          const extendedDataset = extendDatasetWithLabels(
            dataset,
            index,
            xMaxDateString,
            isStacked,
            selectedDataset.length - 1,
          );

          const shouldHideHoverPoint = (() => {
            if (dataset.hoverable === true) return false;
            if (dataset.hoverable === false) return true;
            if (dataset.isDotted) return true;
            if (dataset.hoverColor && !isDatasetHovered) return true;
            return false;
          })();

          const yMax = getYMax(chartRef, dataset);

          return {
            isStacked,
            ...getCommonChartDatasetConfig(extendedDataset, isDatasetHovered, chartRef, unit),
            ...getChartTypeDatasetConfig(
              extendedDataset,
              unit,
              isStacked,
              mobileView ? false : isDatasetHovered,
              chartRef,
            ),
            ...(shouldHideHoverPoint && hideHoverPoint),
            ...dataset,
            ...getFillData({
              ...dataset,
              highlightOnHover: dataset.highlightOnHover,
              chartRef,
              isDatasetHovered: isDatasetHovered,
              yMax,
            }), // Placed last in spread to override any "fill" property defined in dataset
            ...(isDataGreyedOut && greyedOutData),
          } as ChartDataset;
        }),
    } as ChartData<ChartType>;

    setChartData(chartData);
  }, [
    chartRef,
    chartRef?.scales?.y?.max,
    chartRef?.scales?.y1?.max,
    datasets,
    selectedDataset,
    selectedDatasetLabels,
    hoveredDatasetLabel,
    isStacked,
    removeData,
    selectedOptions,
    unit,
    xMinDateString,
    xMaxDateString,
    mobileView,
  ]);

  useLayoutEffect(() => {
    const minTime = new Date(xMinDateString ?? Number.NaN).getTime();
    const maxTime = new Date(xMaxDateString ?? Number.NaN).getTime();

    setChartDataWithDataLabels(() => ({
      ...chartData,
      datasets: chartData.datasets.map((dataset) => {
        const dynamicDataLabels: DataLabelOptions = {
          ...dataset.datalabels,
          ...(dataset.datalabels?.align === undefined && {
            align(ctx) {
              // ? Align data labels relative to horizontal position in chart. If closer to the left, align right, and vice versa.
              if (ctx.chart.scales.x.type === 'time') {
                const dataPoint = ctx.dataset.data[ctx.dataIndex] as Point;
                const pointInTime = new Date(dataPoint.x).getTime();
                const pointIsCloserToLeftSide = Math.abs(pointInTime - minTime) < Math.abs(pointInTime - maxTime);

                return pointIsCloserToLeftSide ? 'right' : 'left';
              }

              return 'center';
            },
            listeners: {
              enter: ({ chart, datasetIndex, dataIndex }, e) => {
                chart.customData = { ...chart.customData, hoveredDataLabel: { datasetIndex, dataIndex } };

                // ! setTimeout(..., 0) is used to apply the data label hover effect **after** normal chart hover effects have been processed (e.g. after the tooltip is triggered).
                setTimeout(() => {
                  const activeElements = [{ datasetIndex, index: dataIndex }];

                  // * Updates the active chart elements, to trigger hover effects for the data point belonging to the data label.
                  chart.setActiveElements(activeElements);

                  // * Updates the active tooltip elements, to show tooltip data for the data point belonging to the data label.
                  chart.tooltip?.setActiveElements(activeElements, { x: e.x ?? 0, y: e.y ?? 0 });

                  chart.update();
                }, 0);
              },
              leave: ({ chart }) => {
                delete chart.customData?.hoveredDataLabel;
              },
            },
          }),
          ...(dataset.datalabels?.clip === undefined && {
            clip(ctx) {
              // ? Clip data labels if they are outside of the slider range, to effectively only perform horizontal clipping.
              // ! `clip: true` clips both vertically and horizontally, which is not desired.
              if (ctx.chart.scales.x.type === 'time') {
                const dataPoint = ctx.dataset.data[ctx.dataIndex] as Point;
                const xPointTime = new Date(dataPoint.x ?? Number.NaN).getTime();

                if (minTime !== undefined && xPointTime < minTime) return true;
                if (maxTime !== undefined && xPointTime > maxTime) return true;
              }

              return false;
            },
          }),
          display: dataset.datalabels?.display,
        };

        return {
          ...dataset,
          datalabels: dynamicDataLabels,
        };
      }),
    }));
  }, [chartData, datasets, xMinDateString, xMaxDateString, unit, expanded]);

  return chartDataWithDataLabels;
}

interface SliderChangeEventData<
  SliderData = {
    label: string;
  },
> {
  min: SliderData;
  max: SliderData;
  changedByUser: boolean;
}

export type SliderChangeEvent = (sliderChangeEventData: SliderChangeEventData) => void;

export function useSlider(
  labels: string[],
  slider: ChartSlider,
  onSliderChange?: SliderChangeEvent,
): [number, number, (range: number[]) => void] {
  const [range, setRange] = useState({ start: 0, end: Math.max(labels.length - 1, 1) });

  // ? Keeps track of previous labels, for remapping the slider range when the labels change.
  const previousLabelsRef = useRef<string[]>([]);

  const { defaultMin, defaultMax } = slider;

  // ? Used to keep track of changes to the default range, to update the slider range accordingly.
  const initialRangeDateStringRef = useRef({
    start: slider.defaultMin,
    end: slider.defaultMax,
  });

  // ? Used to keep track of the initial range labels, to determine if the user has changed the slider range.
  const initialRangeLabelRef = useRef<{ startLabel: string; endLabel: string } | null>(null);
  const hasChangedSinceInitialization = useRef(false);

  const handleRange = useCallback((range: number[]): void => {
    setRange({
      start: range[0],
      end: range[1],
    });
  }, []);

  useEffect(() => {
    const DEBOUNCE_MS = 200;

    // ! Debounces emitting the changed slider values for performance reasons, as the slider can be updated very frequently while dragging.
    const debounceTimer = setTimeout(() => {
      const startLabel = labels[range.start];
      const endLabel = labels[range.end];

      if (onSliderChange && startLabel && endLabel) {
        hasChangedSinceInitialization.current = (() => {
          if (!initialRangeLabelRef.current) return false;

          const { startLabel: initialStartLabel, endLabel: initialEndLabel } = initialRangeLabelRef.current;
          return initialStartLabel !== startLabel || initialEndLabel !== endLabel;
        })();

        // don't update with undefined
        onSliderChange({
          min: { label: startLabel },
          max: { label: endLabel },
          changedByUser: hasChangedSinceInitialization.current,
        });
      }
    }, DEBOUNCE_MS);

    return () => clearTimeout(debounceTimer);
  }, [labels, onSliderChange, range.start, range.end]);

  useLayoutEffect(() => {
    if (labels.length === 0) return;

    const { start: previousDefaultMin, end: previousDefaultMax } = initialRangeDateStringRef.current;
    const defaultRangeChanged = previousDefaultMin !== defaultMin || previousDefaultMax !== defaultMax;
    const labelsHaveChanged = previousLabelsRef.current !== labels && previousLabelsRef.current.length > 0;

    // ? Updates slider values if default values have changed
    const updateIfDefaultMinMaxHasChanged = () => {
      return new Promise((resolve) => {
        if (
          previousLabelsRef.current.length === 0 ||
          (defaultRangeChanged && hasChangedSinceInitialization.current === false && !labelsHaveChanged)
        ) {
          const newStartIndex = defaultMin ? getClosestTickIndex(defaultMin, labels) : 0;
          const newEndIndex = defaultMax ? getClosestTickIndex(defaultMax, labels) : labels.length - 1;

          const { safeStart, safeEnd } = getSafeSliderIndexRange({
            start: newStartIndex,
            end: newEndIndex,
            maxIndex: labels.length - 1,
          });

          setRange({ start: safeStart, end: safeEnd });

          initialRangeLabelRef.current = { startLabel: labels[safeStart], endLabel: labels[safeEnd] };
          initialRangeDateStringRef.current = { start: defaultMin, end: defaultMax };
        }
        resolve(labels);
      });
    };

    // ? Remaps the slider range if the labels have changed (e.g. when switching time granularity).
    const updateStateIfLabelsHaveChanged = () => {
      return new Promise((resolve) => {
        if (labelsHaveChanged) {
          setRange((prevRange) => {
            const newStartIndex = getClosestTickIndex(
              hasChangedSinceInitialization?.current === false && defaultMin
                ? defaultMin
                : previousLabelsRef.current[prevRange.start],
              labels,
            );
            const newEndIndex = getClosestTickIndex(
              hasChangedSinceInitialization?.current === false && defaultMax
                ? defaultMax
                : previousLabelsRef.current[prevRange.end],
              labels,
            );

            const { safeStart, safeEnd } = getSafeSliderIndexRange({
              start: newStartIndex,
              end: newEndIndex,
              maxIndex: labels.length - 1,
            });

            return { start: safeStart, end: safeEnd };
          });
        }
        resolve(labels);
      });
    };

    updateIfDefaultMinMaxHasChanged().then(() => {
      updateStateIfLabelsHaveChanged().then(() => {
        previousLabelsRef.current = labels;
      });
    });
  }, [labels, defaultMin, defaultMax]);

  return [range.start, range.end, handleRange] as const;
}

export function useChartFullscreen(initiallyExpanded = false) {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  useEffect(() => {
    if (initiallyExpanded) {
      setIsExpanded(true);
    }
  }, [initiallyExpanded]);

  return [isExpanded, setIsExpanded] as const;
}

export function useChartArea() {
  const [chartArea, setChartArea] = useState<ChartArea | undefined>();

  const updateChartArea = useCallback((newChartArea: ChartArea) => {
    startTransition(() => {
      setChartArea((previousChartArea) => {
        if (!areEqualChartAreas(previousChartArea, newChartArea)) {
          return newChartArea;
        }
        return previousChartArea;
      });
    });
  }, []);

  return [chartArea, updateChartArea] as const;
}

export const useAdjustedYMax = (datasets: ChartDataset[], xMin: string, xMax: string) => {
  return useMemo(() => {
    const datasetWithYPaddingPercentage = datasets.find((dataset) => dataset.yPaddingPercentage);

    if (datasetWithYPaddingPercentage && datasetWithYPaddingPercentage.yPaddingPercentage) {
      const maxYValueWidthPercentage =
        getMaxYValueInDatasets([datasetWithYPaddingPercentage], xMin, xMax) *
        datasetWithYPaddingPercentage.yPaddingPercentage;

      const maxYValue = getMaxYValueInDatasets(datasets, xMin, xMax);
      return Math.max(maxYValueWidthPercentage, maxYValue);
    }
    return undefined;
  }, [datasets, xMax, xMin]);
};

export const useCapYValueForAxis = (
  datasets: ChartDataset[],
  xMin: string,
  xMax: string,
  capMaxValue: number | undefined,
  capMinValue: number | undefined,
) => {
  return useMemo(() => {
    const maxYValue = getMaxYValueInDatasets(datasets, xMin, xMax);
    const minYValue = getMinYValueInDatasets(datasets, xMin, xMax);

    const max = capMaxValue ? Math.min(capMaxValue, maxYValue) : undefined;
    const min = capMinValue ? Math.max(capMinValue, minYValue) : undefined;

    if (max !== undefined && min !== undefined && min >= max) {
      return { max, min: undefined };
    }

    return { max, min };
  }, [capMaxValue, capMinValue, datasets, xMax, xMin]);
};

export const useCapYValue = (
  datasets: ChartDataset[],
  xMin: string,
  xMax: string,
  yAxis: ChartYAxis | undefined,
  y1Axis: ChartYAxis | undefined,
) => {
  const capYValueYAxis = useCapYValueForAxis(
    datasets.filter((dataset) => dataset.yAxisId !== 'y1'),
    xMin,
    xMax,
    yAxis?.capMaxTo,
    yAxis?.capMinTo,
  );
  const capYValueY1Axis = useCapYValueForAxis(
    datasets.filter((dataset) => dataset.yAxisId === 'y1'),
    xMin,
    xMax,
    y1Axis?.capMaxTo,
    y1Axis?.capMinTo,
  );

  return {
    capYValueYAxis,
    capYValueY1Axis,
  };
};

// TODO: A similar method exists in /aim right now, might want to refactor somehow
export const useXAxisLabels = ({
  min,
  max,
  granularity,
  displayNonMaturePeriod,
}: {
  min?: string;
  max?: string;
  granularity: TimeGranularity;
  displayNonMaturePeriod?: boolean;
}) => {
  return useMemo(() => {
    if (!min || !max) return [];

    const labels = getDateInterval(min, max, granularity, displayNonMaturePeriod ?? false).map((label) =>
      label.toISOString(),
    );

    return granularity === 'days' && labels.at(-1) !== max ? labels.concat([max]) : labels;
  }, [displayNonMaturePeriod, granularity, max, min]);
};

export const useYAxisBoundsBasedOnData = (datasets: ChartDataset[], xMin?: string, xMax?: string) => {
  return useMemo(() => {
    const datasetsWithoutReferenceLines = datasets.filter((dataset) => !dataset.isReferenceLine);
    const min = getMinYValueInDatasets(datasetsWithoutReferenceLines, xMin, xMax);
    const max = getMaxYValueInDatasets(datasetsWithoutReferenceLines, xMin, xMax);

    return min === max ? { min: undefined, max: undefined } : { min, max };
  }, [datasets, xMin, xMax]);
};
