import type { Notification } from '@/api/notifications/index.page';
import { chartKeyInsightCategoryMap } from '@/components/insights/charts/common/types';
import { Expand, Typography } from 'aim-components';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { notificationMap } from '../../utils';
import { getNotificationChartPath } from '@/api/notifications/utils';
import styles from './NotificationCard.module.css';
import { NotificationUnreadMark } from './NotificationUnreadMark';
import { NotificationTitle } from './NotificationTitle';
import { generateNotificationChartLink } from 'pages/[slug]/notification-link/utils';
import { mutate } from 'swr';
import { ApiRoutes } from 'pages/apiRoutes';

interface NotificationCardProps {
  notification: Notification;
  changeReadStatus: (notification: Notification, markAsRead: boolean) => Promise<boolean | undefined>;
}

export const NotificationCard = ({ notification, changeReadStatus }: NotificationCardProps) => {
  const { t } = useTranslation('notifications', { keyPrefix: 'sidePanel' });
  const router = useRouter();
  const { slug } = router.query;
  const [collapsed, setCollapsed] = useState(true);

  const notificationEvent = notificationMap[notification.eventId];
  const groupId = chartKeyInsightCategoryMap.get(notification.insightFilter.chartKey);
  const pathName = getNotificationChartPath(slug as string, notification);

  const updateReadStatus = async () => {
    await changeReadStatus(notification, true);
    await mutate(ApiRoutes.Notifications);
  };

  const notificationChartLink = pathName && generateNotificationChartLink({ notification, pathName });

  return (
    notification.message && (
      <div
        className={cn(styles.notificationCard, notification.read && styles.readNotification)}
        onClick={() => {
          setCollapsed((collapsed) => !collapsed);
          if (!notification.read) updateReadStatus();
        }}
      >
        <NotificationUnreadMark isRead={notification.read} />
        <div className={styles.notificationCardContent}>
          <div className={styles.notificationCardText}>
            <NotificationTitle
              notification={{ ...notification, groupId, notificationEvent }}
              collapsed={collapsed}
              isRead={notification.read}
            />
            <Expand expanded={!collapsed} data-testid='notification-card-expandable-content'>
              <div className={styles.expandedContent}>
                <Typography variant='text1'>{notification.message}</Typography>
                {notificationChartLink && (
                  <Typography variant='text1'>
                    <a href={notificationChartLink} onClick={(e) => e.stopPropagation()}>
                      {t('linkText')}
                    </a>
                  </Typography>
                )}
              </div>
            </Expand>
          </div>
        </div>
      </div>
    )
  );
};
