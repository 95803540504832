import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';
import useDelayMount from '../../utils/useDelayMount';
import useKeyDown from '../../utils/useKeyDown';
import { Description } from '../Description/Description';
import { Icon } from '../Icon';
import './Drawer.css';

export interface DrawerProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  html?: string;
}

const ANIMATION_DURATION = 250;

export const Drawer = ({ open, onClose, children, html }: DrawerProps): ReactElement => {
  const isMounted = useDelayMount(open, ANIMATION_DURATION);
  useKeyDown({ keyCode: 'Escape', onKeyDown: isMounted && open ? onClose : null });

  return (
    <>
      {isMounted && (
        <div
          className={cn('Drawer-overlay', open ? 'open' : 'closed')}
          onClick={onClose}
          style={{
            ['--animation-duration' as string]: `${ANIMATION_DURATION}ms`,
          }}
        >
          <div className={cn('Drawer-content', open ? 'open' : 'closed')} onClick={(e) => e.stopPropagation()}>
            <Icon
              icon='close'
              className='Drawer-close'
              role='button'
              size='medium'
              onClick={() => onClose()}
              aria-label='Close chart information drawer'
            />
            <Description html={html}>{children}</Description>
          </div>
        </div>
      )}
    </>
  );
};
