import React from 'react';
import type { TooltipDataPoint } from '../../../Chart/types';
import { DataHoverCircle } from './DataHoverCircle';

interface DataHoverCirclesProps {
  dataPoints: TooltipDataPoint[];
}

export const DataHoverCircles = ({ dataPoints }: DataHoverCirclesProps) => {
  return (
    <>
      {dataPoints.map(({ point, color }, index) => {
        const x = point?.x ?? 0;
        const y = point?.y ?? 0;
        return <DataHoverCircle key={`${index} ${x} ${y}`} x={x} y={y} strokeColor={color} />;
      })}
    </>
  );
};
