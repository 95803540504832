import type { ComponentProps, ReactElement } from 'react';
import React from 'react';
import './NotificationBadge.css';
import cn from 'classnames';

export interface NotificationBadgeProps extends ComponentProps<'span'> {
  color: 'blue' | 'red';
  count?: number;
  overflowCount?: number;
}

export function NotificationBadge({
  color,
  count,
  overflowCount = 99,
  className,
}: NotificationBadgeProps): ReactElement {
  const notificationCount = count && (count > overflowCount ? `${overflowCount}+` : count);
  return <span className={cn('NotificationBadge', color, className)}>{notificationCount && notificationCount}</span>;
}
