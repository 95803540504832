import { useEffect, useRef } from 'react';

/** Returns an `AbortController` that can be used to clean up event listeners on component unmount. */
export const useAbortController = () => {
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    const abortController = abortControllerRef.current;

    return () => {
      abortController.abort();
    };
  }, []);

  return abortControllerRef.current;
};
