import { toast } from 'aim-components';
import type { ChartKey } from './types';
import { base64EncodeUrlSafe } from 'aim-utils';

const copyToClipboard = async (str: string): Promise<void> => {
  await navigator.clipboard.writeText(str);
};

type SingleOrArray<T> = T | T[];

export type SerializableChartStateData = Record<string, SingleOrArray<string | number | boolean | undefined | null>>;

export const generateChartLink = <TChartState>(
  chartState: TChartState,
  pathname: string = window.location.pathname,
): string => {
  const base64 = base64EncodeUrlSafe(JSON.stringify(chartState));
  const { origin } = window.location;
  return `${origin}${pathname}?chart-link=${base64}`;
};

export const copyChartLinkToClipboard = async (
  chartKey: ChartKey,
  chartState: SerializableChartStateData,
  successMessage: string,
  errorMessage: string,
): Promise<void> => {
  const link = generateChartLink({ ...chartState, chartKey });
  try {
    await copyToClipboard(link);
    toast({ message: successMessage, type: 'success' });
  } catch (e) {
    toast({ message: errorMessage, type: 'critical' });
  }
};

export const removeUrlSearchParameterFromUrl = (url: string, parameter: string): URL => {
  const urlObject = new URL(url);
  urlObject.searchParams.delete(parameter);
  window.history.replaceState(null, '', urlObject);
  return urlObject;
};

export const scrollChartIntoView = (chartKey: ChartKey) => {
  const chartElement = document.getElementById(chartKey);
  if (chartElement) {
    chartElement.scrollIntoView({ block: 'center' });
  }
};
