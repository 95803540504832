import React, { useMemo } from 'react';
import './Score.css';
import { formatDelta, getScoreCountUpColors } from './ScoreCountUp/utils';
import cn from 'classnames';
import { ChartOverlayContent } from '../Chart';
import { Icon } from '../Icon';

interface DeltaLabelMobileProps {
  score?: number;
  delta?: number;
  deltaLabel?: string;
}

export const DeltaLabelMobile = ({ score = 0, delta = 0, deltaLabel = '' }: DeltaLabelMobileProps) => {
  const displayDeltaArrow = Math.abs(delta) - 0.05 >= 0;
  const { toColors } = useMemo(() => getScoreCountUpColors(score), [score]);

  return (
    <ChartOverlayContent>
      <ChartOverlayContent.Label>{`Since ${deltaLabel}`} </ChartOverlayContent.Label>
      <ChartOverlayContent.BigText outline={true} color={toColors.at(-1)}>
        {formatDelta(delta)}
        {displayDeltaArrow && (
          <span className={cn('mobileDeltaIndicator', { negativeDelta: delta < 0, positiveDelta: delta > 0 })}>
            <Icon icon={'play-solid'} fillColor={toColors.at(-1)} />
          </span>
        )}
      </ChartOverlayContent.BigText>
    </ChartOverlayContent>
  );
};
