import styles from './PulsatingCircles.module.css';

interface PulsatingCirclesProps {
  diameter: number;
  animateLightToDark?: boolean;
}

export const PulsatingCircles = ({ diameter, animateLightToDark }: PulsatingCirclesProps) => {
  const viewBoxSize = 100;
  const midpoint = viewBoxSize / 2;

  return (
    <svg
      className={styles.pulsatingCircles}
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx={midpoint} cy={midpoint} r={50} fill={animateLightToDark ? '#CCD6FF' : '#020641'}>
        {animateLightToDark && createAnimation('#020641', '#CCD6FF')}
      </circle>
      <circle cx={midpoint} cy={midpoint} r={38} fill={animateLightToDark ? '#9FB2FE' : '#030C7D'}>
        {animateLightToDark && createAnimation('#030C7D', '#9FB2FE')}
      </circle>
      <circle cx={midpoint} cy={midpoint} r={29} fill={animateLightToDark ? '#718DFE' : '#0512C2'}>
        {animateLightToDark && createAnimation('#0512C2', '#718DFE')}
      </circle>
      <circle cx={midpoint} cy={midpoint} r={18} fill={animateLightToDark ? '#0026BD' : '#1021F9'}>
        {animateLightToDark && createAnimation('#1021F9', '#0026BD')}
      </circle>
    </svg>
  );
};

const createAnimation = (toColor: string, fromColor: string) => {
  return (
    <animate attributeType='XML' attributeName='fill' fill='freeze' from={fromColor} to={toColor} dur='2s' begin='1s' />
  );
};
