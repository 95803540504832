import { createContext, useContext } from 'react';

type AccordionContextType = (
  | {
      type: 'single';
      expandedId: string | null;
    }
  | {
      type: 'multiple';
      expandedIds: string[];
    }
) & {
  toggleId: (id: string) => void;
  iconPosition: 'left' | 'right';
};

export const AccordionContext = createContext<AccordionContextType | null>(null);

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error('<Accordion.Item> must be used within an <Accordion> component');
  }

  return context;
};
