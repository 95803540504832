import { numberOfDaysBetweenDates } from '../../../utils/date-utils';

/**
 * Used to determine how compact the X-axis labels are allowed to be displayed.
 * The smaller the value, the more compact the labels can be displayed.
 */
const CHARACTER_WIDTH = 16;

export type Label = string | string[] | undefined;

/**
 * Two empty lines are used to preserve the x-axis height while loading / hiding labels.
 */
export const EMPTY_LABEL = ['', ''];

/**
 * Attempts to fit the @param labels on the x-axis, by gradually reducing the number of allowed labels until they fit the @param width.
 * The allowed labels are passed in **descending** level of priority in @param labelPriorityLevels.
 *
 * If the labels still do not fit, the labels are excluded using @function fitByModuloExclusion as a fallback.
 */
export const fitByLabelPriorityLevels = (
  labels: Label[],
  labelPriorityLevels: Array<string[]>,
  width: number,
): Label[] => {
  if (fitsOnAxis(labels, width)) return removeOverlaps(labels, width);

  for (const level of labelPriorityLevels) {
    const newLabels = mapToLevel(labels, level);
    if (fitsOnAxis(newLabels, width)) {
      return removeOverlaps(newLabels, width);
    }
  }

  const lowestLabelPriorityLevel = labelPriorityLevels[labelPriorityLevels.length - 1];
  const labelsThatDidNotFitByLabelPriorityLevels = mapToLevel(labels, lowestLabelPriorityLevel);
  return fitByModuloExclusion(labelsThatDidNotFitByLabelPriorityLevels, width);
};

const mapToLevel = (labels: Label[], level: string[]): Label[] => {
  // ! Hot-fix for robustness, seems like labels is sometimes not an array, resulting in "labels.map is not a function"
  if (!Array.isArray(labels)) return [];

  return labels.map((label) => {
    if (!label) return;
    if (Array.isArray(label)) return label; // Keep labels including a year (a second row)
    return level.includes(label) ? label : undefined;
  });
};

/**
 * Excludes an increasing number of @param labels from the x-axis, until they fit the x-axis @param width.
 */
export const fitByModuloExclusion = (labels: Label[], width: number): Label[] => {
  const definedIndices = labels
    .map((label, index) => {
      if (!label) return;
      return index;
    })
    .filter((index) => index !== undefined);

  for (let moduloExclude = 1; moduloExclude <= definedIndices.length; moduloExclude++) {
    const validIndices = definedIndices.filter((_, index) => index % moduloExclude === 0);
    const newLabels = labels.map((label, index) => (validIndices.includes(index) ? label : undefined));

    if (moduloExclude === definedIndices.length || fitsOnAxis(newLabels, width)) {
      return newLabels;
    }
  }

  return labels;
};

/**
 * Checks if the array of @param labels fits the x-axis @param width, by comparing the total number of characters in the labels to the width.
 *
 * The width is calculated by multiplying the number of characters by the @constant CHARACTER_WIDTH, which is a rough estimate of the width of a character.
 */
const fitsOnAxis = (labels: Label[], width: number): boolean => {
  const totalNumberOfCharacters = labels.reduce((acc, label) => {
    if (!label) return acc;
    if (Array.isArray(label)) {
      return acc + Math.max(label[0].length, label[1].length);
    }
    return acc + label.length;
  }, 0);

  return width > totalNumberOfCharacters * CHARACTER_WIDTH;
};

const getMinimumIndexDistanceBetweenTicks = (labels: Label[], width: number): number => {
  const spaceForEachTick = width / labels.length;
  return Math.ceil((3 * CHARACTER_WIDTH) / spaceForEachTick);
};

export const hasOverlappingLabels = (labels: Label[], width: number): boolean => {
  const minimumIndexDistanceBetweenTicks = getMinimumIndexDistanceBetweenTicks(labels, width);

  let lastLabelIndex = 0;

  return labels.some((label, i) => {
    if (!label || i === 0) return false;

    const indexDistance = i - lastLabelIndex;

    if (indexDistance < minimumIndexDistanceBetweenTicks) return true;

    lastLabelIndex = i;
  });
};

const removeOverlaps = (labels: Label[], width: number) => {
  const minimumIndexDistanceBetweenTicks = getMinimumIndexDistanceBetweenTicks(labels, width);
  const newLabels = labels.slice();

  let lastLabelIndex = 0;

  newLabels.forEach((label, i) => {
    if (label && i !== 0) {
      if (i - lastLabelIndex < minimumIndexDistanceBetweenTicks) {
        if (typeof label === 'string') {
          newLabels[i] = undefined;
        } else {
          newLabels[lastLabelIndex] = undefined;
          lastLabelIndex = i;
        }
      } else {
        lastLabelIndex = i;
      }
    }
  });

  return newLabels;
};

/**
 * Hides continuously duplicated labels from the @param labels array.
 * @example ['Jan', 'Jan', 'Jan', 'Feb', 'Feb', 'Feb'] => ['Jan', undefined, undefined, 'Feb', undefined, undefined]
 */
export const hideContinuouslyDuplicatedLabels = (labels: Label[]): Label[] => {
  return labels.map((label, index) => {
    if (!label) return;
    const previousLabel = labels[index - 1];
    if (!previousLabel) return label;

    const previous = JSON.stringify(previousLabel);
    const current = JSON.stringify(label);

    return previous === current ? undefined : label;
  });
};

export const replaceMissingWithEmptyLabel = (labels: Label[], emptyLabel: string[]): Array<string | string[]> => {
  return labels.map((label) => (!label ? emptyLabel : label));
};

export const shouldIncludeYearInFirstLabel = (labels: string[]): boolean => {
  const firstDate = new Date(labels[0]);
  const nextYearLabel = labels.find((label) => {
    const date = new Date(label);
    return date.getUTCFullYear() !== firstDate.getUTCFullYear();
  });

  if (!nextYearLabel) return true;

  const nextYearDate = new Date(nextYearLabel ?? firstDate);
  return numberOfDaysBetweenDates(nextYearDate, firstDate) >= 365;
};
