import { createContext } from 'react';

/**
 * The `DeckChartContext` contains information about the chart that is currently being displayed **inside a deck**.
 *
 * If the chart is not currently being displayed inside a deck, the context is `null`.
 */
export const DeckChartContext = createContext<{
  title: string;
} | null>(null);
