import { createTimeGranularityTuple } from '@/components/insights/charts/common/types';

export interface UserActivityRetentionArrowRow {
  activation_date_month: string;
  days_since_first_activity: number;
  user_retention_rate_daily: number;
  user_retention_rate_last_7d: number;
  user_retention_rate_last_30d: number;
}

export const UserActivityRetentionDataset = {
  SecondDayRetention: 'dataset-1',
  Day10Retention: 'dataset-4',
  Day30Retention: 'dataset-5',
  FirstWeekRetention: 'dataset-6',
  SecondWeekRetention: 'dataset-2',
  FirstMonthRetention: 'dataset-7',
  SecondMonthRetention: 'dataset-3',
  ThirdMonthRetention: 'dataset-8',
  SixthMonthRetention: 'dataset-9',
  TwelfthMonthRetention: 'dataset-10',
  EighteenthMonthRetention: 'dataset-11',
  TwentyForthMonthRetention: 'dataset-12',
  ThirtySixthMonthRetention: 'dataset-13',
} as const;

export const UserActivityRetentionDatasets = Object.values(UserActivityRetentionDataset);

export type UserActivityRetentionDatasetType = (typeof UserActivityRetentionDatasets)[number];

export const USER_ACTIVITY_RETENTION_TRENDS_TIME_GRANULARITIES = createTimeGranularityTuple('months', 'weeks');

export const filters = {
  [UserActivityRetentionDataset.SecondDayRetention]: { days: 1, field: 'user_retention_rate_daily' },
  [UserActivityRetentionDataset.FirstWeekRetention]: { days: 7, field: 'user_retention_rate_last_7d' },
  [UserActivityRetentionDataset.SecondWeekRetention]: { days: 14, field: 'user_retention_rate_last_7d' },
  [UserActivityRetentionDataset.FirstMonthRetention]: { days: 30, field: 'user_retention_rate_last_30d' },
  [UserActivityRetentionDataset.SecondMonthRetention]: { days: 60, field: 'user_retention_rate_last_30d' },
  [UserActivityRetentionDataset.ThirdMonthRetention]: { days: 90, field: 'user_retention_rate_last_30d' },
  [UserActivityRetentionDataset.SixthMonthRetention]: { days: 180, field: 'user_retention_rate_last_30d' },
  [UserActivityRetentionDataset.TwelfthMonthRetention]: { days: 360, field: 'user_retention_rate_last_30d' },
  [UserActivityRetentionDataset.EighteenthMonthRetention]: { days: 540, field: 'user_retention_rate_last_30d' },
  [UserActivityRetentionDataset.TwentyForthMonthRetention]: { days: 720, field: 'user_retention_rate_last_30d' },
  [UserActivityRetentionDataset.ThirtySixthMonthRetention]: { days: 1080, field: 'user_retention_rate_last_30d' },
  [UserActivityRetentionDataset.Day10Retention]: { days: 10, field: 'user_retention_rate_daily' },
  [UserActivityRetentionDataset.Day30Retention]: { days: 30, field: 'user_retention_rate_daily' },
} as const satisfies Record<
  UserActivityRetentionDatasetType,
  { days: number; field: keyof UserActivityRetentionArrowRow }
>;
