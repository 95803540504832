import { type Role } from '@/lib/permissions/role';
import { createContext } from 'react';
export interface UserContextProps {
  displayName: string;
  email: string;
  role: Role;
  tenant: string;
  arkUserId: string;
  created: string;
  recentTenants: { tenants: string[]; isLoading: boolean };
}
export const UserContext = createContext<UserContextProps>({
  displayName: 'User',
  email: 'email',
  role: 'OWNER',
  tenant: '',
  arkUserId: '',
  created: '',
  recentTenants: { tenants: [], isLoading: false },
});
