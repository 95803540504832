import type { ComponentProps, ReactNode } from 'react';

export const ACCORDION_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
} as const;

export type AccordionType = (typeof ACCORDION_TYPE)[keyof typeof ACCORDION_TYPE];

export const ACCORDION_ICON_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
} as const;

type AccordionIconPosition = (typeof ACCORDION_ICON_POSITION)[keyof typeof ACCORDION_ICON_POSITION];

export type BaseAccordionProps = {
  iconPosition?: AccordionIconPosition;
  children: ReactNode;
};

export type SingleAccordionProps<TAccordionId extends string> = BaseAccordionProps & {
  type: typeof ACCORDION_TYPE.SINGLE;
  expandedId?: TAccordionId;
  onChange?: (expandedId: TAccordionId | undefined) => void;
};

export type MultipleAccordionProps<TAccordionId extends string> = BaseAccordionProps & {
  type: typeof ACCORDION_TYPE.MULTIPLE;
  expandedIds?: TAccordionId[];
  onChange?: (expandedIds: TAccordionId[]) => void;
};

export type AccordionProps<TAccordionId extends string> =
  | SingleAccordionProps<TAccordionId>
  | MultipleAccordionProps<TAccordionId>;

export interface AccordionItemProps extends Omit<ComponentProps<'div'>, 'title'> {
  children: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  disabled?: boolean;
  value: string;
}
