//TODO Should be placed in aim2
import type { ReactElement } from 'react';
import React from 'react';
import type { BadgeType } from '../Badge/Badge';
import { Icon } from '../Icon';
import { BadgeCell, Cell, NameAndEmailCell } from './Cell';
import { Row } from './Row';
import { Table } from './Table';
import './UserManagementTable.css';
import { useTableSort } from './hooks';
import type { SortableRow } from './sorting';
import { sortRows } from './sorting';
import { useMobile } from 'aim-utils';

export interface UserManagementRow {
  name: { fullName: string; email: string } & SortableRow;
  role?: { badgeType?: BadgeType } & SortableRow;
  action?: { onClick: () => void };
  id?: string;
}

export interface UserManagementTableProps {
  rows: UserManagementRow[];
}

type SortableColumns = 'name' | 'role';

const UserManagementRow = ({ name, role, action }: UserManagementRow): ReactElement => {
  const { mobileView } = useMobile();
  return (
    <Row className='UserManagementRow' onClick={action && action.onClick}>
      <NameAndEmailCell fullName={name.fullName} email={name.email} role={mobileView ? role?.label : undefined} />
      {role && !mobileView && <BadgeCell label={role.label} type={role.badgeType} />}
      {action && (
        <Cell>
          <Icon icon='nav-forward-chevron' />
        </Cell>
      )}
    </Row>
  );
};

export const UserManagementTable = ({ rows }: UserManagementTableProps): ReactElement => {
  const [, sortedColumn, order] = useTableSort<SortableColumns>('name');
  const sortedRows = rows.sort((a, b) => sortRows<SortableColumns>(a, b, sortedColumn, order));

  return (
    <div className='UserManagementTableContainer'>
      <Table>
        {sortedRows.map((r) => (
          <UserManagementRow
            {...r}
            key={r.id}
            name={{ fullName: r.name.fullName, email: r.name.email, label: r.name.fullName }}
          />
        ))}
      </Table>
    </div>
  );
};
