import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import { CategoryScale, Chart as ChartJS, LinearScale, LineElement, PointElement, TimeScale } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Section } from '../../Section';
import ChartHeader from '../ChartHeader/ChartHeader';
import { options, placeholderData } from './utils';
import './ChartOverlay.css';

ChartJS.register([CategoryScale, TimeScale, LinearScale, PointElement, LineElement]);

export interface OverlayedChartProps {
  title: string;
  overlayComponent?: ReactNode;
}

export const ChartOverlay = ({ title, overlayComponent }: OverlayedChartProps): ReactElement => {
  return (
    <Section condensed>
      <div className='OverlayedChart'>
        <ChartHeader title={title} />
        <div className='Overlay-Blur'>
          <Chart type='line' data={placeholderData} data-testid='chart' options={options} />
        </div>
        {overlayComponent}
      </div>
    </Section>
  );
};
