import { UserContext } from '@/components/authentication/UserContext';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import { getUserPermissions, manageableRoles } from './role';
import { TenantContext } from '@/components/tenant/context';
import { getTenantPermissions } from './tenant';

export const useRoleOptions = () => {
  const { t } = useTranslation('common', { keyPrefix: 'roles' });
  const user = useContext(UserContext);

  return (manageableRoles[user.role] ?? []).map((role) => ({ label: t(role), value: role }));
};

export const useUserPermissions = () => {
  const user = useContext(UserContext);
  return getUserPermissions(user.role);
};

export const useTenantPermissions = () => {
  const tenant = useContext(TenantContext);
  return getTenantPermissions(tenant.countryCode);
};
