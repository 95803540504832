import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMicrosoft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' {...props}>
    <path
      fill='#fff'
      d='M5 5.5h6.688v6.719H5V5.5Zm7.281 0H19v6.719h-6.719V5.5ZM5 12.813h6.688V19.5H5v-6.688Zm7.281 0H19V19.5h-6.719v-6.688Z'
    />
  </svg>
);
export default SvgMicrosoft;
