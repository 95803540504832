import type { Dispatch, SetStateAction } from 'react';
import { memo } from 'react';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { animated, useSpring } from '@react-spring/web';
import AnimatedTick from './AnimatedTick';
import { AnnotationCard } from './AnnotationCard/AnnotationCard';
import './Annotations.css';
import type { Annotation } from './types';

interface AnnotationMarkerProps {
  height: number;
  width: number;
  annotationMarkerSize: { width: number; height: number };
  annotation: Annotation;
  openAnnotationId: string | null;
  onHover: (annotationId: string | null) => void;
  setDisplayAnnotationSidePanel: Dispatch<SetStateAction<boolean>>;
  setOpenedAnnotationId: (id: string | null) => void;
}

const AnnotationMarker = ({
  height,
  width,
  annotationMarkerSize,
  annotation,
  openAnnotationId,
  onHover,
  setDisplayAnnotationSidePanel,
  setOpenedAnnotationId,
}: AnnotationMarkerProps) => {
  const pillarWidth = annotationMarkerSize.width / 5;
  const [hovered, setHovered] = useState(false);

  const ref = useRef(width);
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    if (ref.current !== height) {
      setAnimate(false);
      ref.current = height;
    } else {
      setAnimate(true);
    }
  }, [height, annotation.leftOffset]);

  const annotationIsOpen = hovered || annotation.id === openAnnotationId;

  const onMouseOver = useCallback(() => {
    onHover(annotation.id);
    setHovered(true);
  }, [annotation.id, onHover]);

  const onMouseOut = useCallback(() => {
    onHover(null);
    setHovered(false);
  }, [onHover]);

  const onClick = useCallback(() => {
    const clickedOnOpenedAnnotation = openAnnotationId === annotation.id;
    setDisplayAnnotationSidePanel(clickedOnOpenedAnnotation ? false : true);
    setOpenedAnnotationId(clickedOnOpenedAnnotation ? null : annotation.id);
  }, [annotation.id, openAnnotationId, setDisplayAnnotationSidePanel, setOpenedAnnotationId]);

  const animationPillar = useSpring({
    config: {
      duration: 200,
    },
    x: annotationMarkerSize.width / 2 - pillarWidth / 2,
    height: annotationIsOpen ? height : 0,
    y: annotationIsOpen ? -height : 0,
    width: pillarWidth,
    from: {
      height: 0,
    },
  });

  const animateOpacity = useSpring({
    config: {
      duration: 200,
    },
    to: {
      opacity: annotationIsOpen ? 1 : 0,
    },
    from: {
      opacity: 0,
    },
  });

  const animation = useSpring({
    config: {
      duration: 450,
    },
    immediate: !animate,
    transform: `translate(${annotation.leftOffset},0)`,
  });

  return (
    <>
      {annotationIsOpen && (
        <animated.foreignObject
          width={width}
          height={height}
          className='noPointerEvents'
          {...animateOpacity}
          transform={`translate(0,${-height})`}
        >
          <AnnotationCard
            author={annotation.author}
            arkUserId={annotation.arkUserId}
            date={new Date(annotation.updatedAt)}
            contentParts={annotation.contentParts}
            style={{
              opacity: 'inherit',
              transform:
                (annotation.leftOffset ?? 0) < width / 2
                  ? `translate(calc(${annotation.leftOffset}px + 20px), -50%)`
                  : `translate(calc(${annotation.leftOffset}px - 100% - 10px), -50%)`,
            }}
          />
        </animated.foreignObject>
      )}

      <animated.g {...animation}>
        <animated.rect {...animationPillar} className='annotationAccentColorSecondary' opacity='0.2' />
        <AnimatedTick active={annotationIsOpen} annotationMarkerSize={annotationMarkerSize} />
        <rect
          x='0'
          y={-annotationMarkerSize.height * 3}
          width={annotationMarkerSize.width}
          height={annotationMarkerSize.height * 8}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          opacity='0'
          className={'AnnotationMarker-target'}
          onClick={onClick}
        />
      </animated.g>
    </>
  );
};
export default memo(AnnotationMarker);
