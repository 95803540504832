import { useContext } from 'react';
import { NavigationMenuContext } from './NavigationMenuContext';

export const useNavigationMenuContext = () => {
  const context = useContext(NavigationMenuContext);

  if (!context) {
    throw new Error('NavigationMenu compound components cannot be rendered outside the NavigationMenu component');
  }

  return context;
};
