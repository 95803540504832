import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';
import type { Annotation } from './types';

export interface AnnotationContextProps {
  annotations: Annotation[];
  isLoadingAnnotations: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAnnotation: (newAnnotation: any) => Promise<Annotation | undefined>; // TODO: Should we create a type for this in aim-components?
  createAnnotationReply: (newReply: {
    annotationId: string;
    content: string;
    mentionedUserIds: string[];
  }) => Promise<void>;
  displayAnnotationSidePanel: boolean;
  setDisplayAnnotationSidePanel: Dispatch<SetStateAction<boolean>>;
  archiveAnnotation: ({ id, replyId }: { id: Annotation['id']; replyId?: Annotation['id'] }) => void;
  mentionsUserData: Array<{ id: string; display: string; email: string }>;
  enableFeature: boolean; // TODO: remove when enabled for all users
  isCreatingAnnotation: boolean;
  openedAnnotationId: Annotation['id'] | null;
  setOpenedAnnotationId: Dispatch<SetStateAction<Annotation['id'] | null>>;
  setIsCreatingAnnotation: (toggle: boolean) => void;
  userName: string | undefined;
  isAdmin: boolean;
  userId: string | undefined;
}

export const AnnotationContext = createContext<AnnotationContextProps>({
  annotations: [],
  isLoadingAnnotations: false,
  createAnnotation: () => Promise.resolve(undefined),
  createAnnotationReply: () => Promise.resolve(undefined),
  displayAnnotationSidePanel: false,
  setDisplayAnnotationSidePanel: () => undefined,
  mentionsUserData: [],
  archiveAnnotation: () => Promise.resolve(undefined),
  enableFeature: true,
  isCreatingAnnotation: false,
  openedAnnotationId: null,
  setOpenedAnnotationId: () => undefined,
  setIsCreatingAnnotation: () => undefined,
  userName: undefined,
  isAdmin: false,
  userId: undefined,
});
