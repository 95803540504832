import React, { useId } from 'react';
import type { SpringValue } from '@react-spring/web';
import { animated } from '@react-spring/web';
import { addOpacityToHexColor } from 'aim-utils';
import { scoreCountUpColors } from './ScoreCountUp/utils';
import { Gradient } from './utils';

interface ScoreSlicerProps {
  arcRadius: number;
  svgWidth: number;
  svgHeight: number;
  rotation: SpringValue<string>;
  animatedScore?: number;
}

export const ScoreSlicer = ({ arcRadius, svgWidth, svgHeight, rotation, animatedScore }: ScoreSlicerProps) => {
  const gradientId = useId();

  return (
    <>
      <Gradient
        startColor={addOpacityToHexColor(scoreCountUpColors[Math.ceil(Math.max(animatedScore ?? 1, 1)) - 1], 0)}
        stopColor={scoreCountUpColors[Math.ceil(Math.max(animatedScore ?? 1, 1)) - 1]}
        startColorOffset={30}
        id={gradientId}
        orientation='horizontal'
      />
      <g transform={`translate(${svgHeight},${svgWidth / 2})`}>
        <animated.path
          d={`M ${arcRadius} 0 V 0.01 H 0`}
          transform={rotation}
          strokeLinecap='round'
          stroke={`url(#${gradientId})`}
          strokeWidth={6}
        />
      </g>
    </>
  );
};
