import React from 'react';
import { getRootCssVariable } from '../../../../utils/utils';
import type { D3LineChartConfig, Margin } from '../../types';

export const ChartDescription = ({
  margin,
  width,
  svgHeight,
  chartLegendSize,
  lineChartConfig,
}: {
  margin: Margin;
  width: number;
  svgHeight: number;
  chartLegendSize: number;
  lineChartConfig: D3LineChartConfig;
}) => {
  return (
    <g transform={`translate(${margin.left},${svgHeight - chartLegendSize})`}>
      <foreignObject width={width} height={chartLegendSize}>
        <div
          style={{
            fontSize: getRootCssVariable('--aim-font-size-text2'),
            opacity: '0.6',
          }}
        >
          {lineChartConfig?.descriptionText}
        </div>
      </foreignObject>
    </g>
  );
};
