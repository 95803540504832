import { LocalStorageKey, useLocalStorage } from '@/lib/hooks';
import { MemoryDebugger } from 'charlie';
import { createContext, useContext, useMemo } from 'react';

const MemoryDebuggerContext = createContext<{
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
} | null>(null);

export const useMemoryDebuggerContext = () => {
  const context = useContext(MemoryDebuggerContext);

  if (!context) throw new Error('useMemoryDebugger must be used within a MemoryDebuggerContextProvider');

  return context;
};

export const MemoryDebuggerContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [enabled, setEnabled] = useLocalStorage(LocalStorageKey.MEMORY_DEBUGGER_ENABLED, false);
  const contextValue = useMemo(() => ({ enabled, setEnabled }), [enabled, setEnabled]);

  return (
    <MemoryDebuggerContext.Provider value={contextValue}>
      {enabled && <MemoryDebugger />}
      {children}
    </MemoryDebuggerContext.Provider>
  );
};
