import type { ReactElement } from 'react';
import React from 'react';
import './Chart.css';
import { Spinner } from '../Loading';

export const ChartCircleLoadingCover = (): ReactElement => {
  return (
    <div className='Chart-circle-loader-cover '>
      <Spinner size='small' />
    </div>
  );
};
