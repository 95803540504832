import type { ReactNode, ComponentRef, Dispatch, SetStateAction } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './Carousel.module.css';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { debounce, throttle } from 'aim-utils';

interface CarouselProps {
  slide: number;
  setSlide: Dispatch<SetStateAction<number>>;
  children: ReactNode;
  disabled?: boolean;
  onSlideChange?: (slide: number) => void;
  navigationHidden?: boolean;
}

export const Carousel = ({
  slide,
  setSlide,
  children,
  disabled,
  onSlideChange,
  navigationHidden = false,
}: CarouselProps) => {
  const numberOfSlides = React.Children.count(children);
  const bullets = useMemo(() => Array.from({ length: numberOfSlides }, (_, i) => i), [numberOfSlides]);
  const slidesRef = useRef<ComponentRef<'ul'>>(null);
  const lastScrolledToSlide = useRef<number>(-1);
  const [slideBeingScrolledTo, setSlideBeingScrolledTo] = useState<number>();

  const scrollToSlide = useCallback((newSlide: number) => {
    if (slidesRef.current) {
      slidesRef.current.scrollTo({
        left: slidesRef.current.clientWidth * newSlide,
        // ! Fixes an issue in Safari where the left offset is reset when toggling "navigationHidden"
        behavior: lastScrolledToSlide.current !== newSlide ? 'smooth' : 'instant',
      });
    }
  }, []);

  const slideForward = useCallback(() => {
    setSlide((slide) => {
      if (slide < numberOfSlides - 1) {
        return slide + 1;
      }
      return slide;
    });
  }, [numberOfSlides, setSlide]);

  const slideBackward = useCallback(() => {
    setSlide((slide) => {
      if (slide > 0) {
        return slide - 1;
      }
      return slide;
    });
  }, [setSlide]);

  useEffect(() => {
    if (!slidesRef.current || navigationHidden) return;

    const localRef = slidesRef.current;

    // ? This is used to update the active bullet and navigation buttons, before the scroll animation is finished (to make it feel more responsive)
    const handleScrollInProgress = throttle(() => {
      const { scrollLeft, clientWidth } = localRef;
      const newSlide = Math.round(scrollLeft / clientWidth);
      setSlideBeingScrolledTo(newSlide);
    }, 50);

    /**
     * ! The "scrollend" event does not work on Safari, so we use the "scroll" event instead and debounce it, to achieve the same effect.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollend_event#browser_compatibility
     */
    const handleScrollEnd = debounce(() => {
      const { scrollLeft, clientWidth } = localRef;
      const newSlide = Math.round(scrollLeft / clientWidth);
      lastScrolledToSlide.current = newSlide;
      setSlide(newSlide);
    }, 100);

    const combinedHandler = () => {
      handleScrollInProgress();
      handleScrollEnd();
    };

    localRef.addEventListener('scroll', combinedHandler, { passive: true });

    return () => {
      localRef.removeEventListener('scroll', combinedHandler);
    };
  }, [setSlide, navigationHidden]);

  useEffect(() => {
    if (onSlideChange) onSlideChange(slide);
  }, [slide, onSlideChange]);

  useEffect(() => scrollToSlide(slide), [scrollToSlide, slide, navigationHidden]);

  const activeSlideIndex = slideBeingScrolledTo ?? slide;

  return (
    <section className={styles.carousel} data-carousel-disabled={disabled}>
      <ul className={styles.slides} ref={slidesRef}>
        {React.Children.map(children, (child, i) => {
          if (navigationHidden && i !== slide) return null;
          return <li className={styles.slide}>{child}</li>;
        })}
      </ul>
      {numberOfSlides > 1 && !navigationHidden && (
        <div className={styles.navigation}>
          <Button
            type='tertiary'
            color='white'
            endIcon={<Icon icon='nav-back' />}
            onClick={slideBackward}
            disabled={disabled || activeSlideIndex === 0}
          />
          <div className={styles.bullets}>
            {bullets.map((bullet, i) => (
              <div
                key={bullet}
                role='button'
                className={styles.bullet}
                data-bullet-active={activeSlideIndex === i}
                onClick={disabled ? undefined : () => setSlide(bullet)}
              />
            ))}
          </div>
          <Button
            type='tertiary'
            color='white'
            endIcon={<Icon icon='nav-forward' />}
            onClick={slideForward}
            disabled={disabled || activeSlideIndex === numberOfSlides - 1}
          />
        </div>
      )}
    </section>
  );
};

interface CarouselChartSlideProps {
  children: ReactNode;
  onClick: () => void;
}

const CarouselChartSlide = ({ children, onClick }: CarouselChartSlideProps) => {
  return (
    <div className={styles.chartSlide} onClick={onClick}>
      {children}
    </div>
  );
};

Carousel.ChartSlide = CarouselChartSlide;
