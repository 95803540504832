export const clamp = ({ value, min, max }: { value: number; min: number; max: number }) => {
  return Math.min(Math.max(value, min), max);
};

/**
 * 
 * @example
  floatWithMaxFractions(1.234567, 2) // 1.23
  floatWithMaxFractions(1.234567, 3) // 1.235
  floatWithMaxFractions(1, 2) // 1
 */
export const floatWithMaxFractions = (value: number, digits: number) => {
  return parseFloat(value.toFixed(digits));
};
