import React, { useCallback, useMemo, useState } from 'react';
import styles from './NotificationsSidePanel.module.css';
import { NotificationCard } from './notificationsCard/NotificationCard';
import { Button, SidePanel, Typography } from 'aim-components';
import { useTranslation } from 'next-i18next';
import type { Notification } from '@/api/notifications/index.page';
import useSWR, { type KeyedMutator } from 'swr';
import { ApiRoutes } from 'pages/apiRoutes';
import clientRequest, { fetcher } from '@/lib/request/request';
import type { WrappedApiNotification } from '@/api/notifications/read-status/[key].page';
import { logger, useMobile } from 'aim-utils';
import { NotificationsCenterMobile } from './NotificationsCenter.mobile';
import { useSettingsNavigation } from '@/components/settings/hooks';

export interface NotificationsSidePanelProps {
  isOpened: boolean;
  notifications: Notification[] | undefined;
  refetchData: KeyedMutator<Notification[]>;
  closeSidePanel: () => void;
}

export const NotificationsSidePanel = ({ isOpened, closeSidePanel }: NotificationsSidePanelProps) => {
  const { t } = useTranslation('notifications', { keyPrefix: 'sidePanel' });
  const { mobileView } = useMobile();
  const { goToSettingsTab } = useSettingsNavigation();
  const [showMenu, setShowMenu] = useState(false);

  const { data: notifications, mutate: refetchData } = useSWR<Notification[]>(ApiRoutes.Notifications, fetcher);

  const allIsRead = useMemo(() => {
    return notifications?.every((n) => n.read);
  }, [notifications]);

  const updateReadStatus = async () => {
    notifications?.forEach(async (notification) => {
      await changeReadStatus(notification, !allIsRead);
    });
    refetchData();
    setShowMenu(false);
  };

  const changeReadStatus = useCallback(async (updatedNotification: Notification, markAsRead: boolean) => {
    if (updatedNotification.read === !markAsRead) {
      try {
        await clientRequest<WrappedApiNotification>({
          url: ApiRoutes.NotificationReadStatus.replace('[notification_id]', updatedNotification.reference),
          method: 'PUT',
          data: { read: markAsRead },
        });
      } catch (err) {
        logger.warn({ message: 'unable to mark notification as read' });
        return false;
      }
    }
  }, []);

  if (mobileView) {
    return (
      <NotificationsCenterMobile
        isOpened={isOpened}
        notifications={notifications}
        refetchData={refetchData}
        changeReadStatus={changeReadStatus}
        closeSidePanel={closeSidePanel}
      />
    );
  }

  return (
    <>
      <SidePanel
        isOpened={isOpened}
        onClose={closeSidePanel}
        header={
          <SidePanel.Header
            onClose={closeSidePanel}
            title={<SidePanel.Title icon='bell' title={t('title')} />}
            menu={
              <SidePanel.Menu
                open={showMenu}
                showMenu={setShowMenu}
                trigger={
                  <Button
                    type='tertiary'
                    color='white'
                    endIcon='menu-vertical'
                    onClick={(e) => {
                      setShowMenu((shown) => !shown);
                      e?.preventDefault();
                      e?.stopPropagation();
                    }}
                  />
                }
                items={[
                  {
                    title: allIsRead ? t('markAllAsUnread') : t('markAllAsRead'),
                    action: () => {
                      updateReadStatus();
                    },
                  },
                ]}
              />
            }
          />
        }
      >
        <div className={styles.contentContainer}>
          {Array.isArray(notifications) && notifications.length > 0 ? (
            <div className={styles.scrollableContent}>
              {notifications?.map((notification) => {
                return (
                  <NotificationCard
                    changeReadStatus={changeReadStatus}
                    key={notification.reference}
                    notification={notification}
                  />
                );
              })}
            </div>
          ) : (
            <div className={styles.noNotifications}>
              <Typography variant='label3' color='secondary'>
                {t('noNotifications')}
              </Typography>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <Button
            startIcon='settings'
            type='secondary'
            color='white'
            onClick={() => {
              goToSettingsTab('notifications');
              closeSidePanel();
            }}
          >
            {t('settings')}
          </Button>
        </div>
      </SidePanel>
    </>
  );
};
