import type { ComponentProps, ReactElement, ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';

import './Badge.css';

export const BADGE_TYPES = ['neutral', 'critical', 'warning', 'info', 'success', 'emphasized'] as const;
export type BadgeType = (typeof BADGE_TYPES)[number];

export interface BadgeProps {
  children: ReactNode;
  type?: BadgeType;
  color?: React.CSSProperties['color'];
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  className?: ComponentProps<'div'>['className'];
}

export const Badge = ({
  children,
  type = 'neutral',
  color,
  startIcon,
  endIcon,
  className,
}: BadgeProps): ReactElement => {
  return (
    <div
      className={cn('Badge', `Badge-${type}`, { 'badge-with-custom-color': color !== undefined }, className)}
      style={
        color && {
          ['--badge-color' as string]: color,
        }
      }
    >
      {startIcon}
      <div className={cn('Badge-Label', startIcon && 'start-padding', endIcon && 'end-padding')}>{children}</div>
      {endIcon}
    </div>
  );
};
