import React from 'react';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import './ScoreContainer.css';
import cn from 'classnames';
import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { Typography } from '../../Typography';

interface ScoreContainerProps extends ComponentPropsWithoutRef<'div'> {
  title: string;
  children: ReactNode;
  callToAction?: {
    text: string;
    onClick: () => void;
  };
}

export const ScoreContainer = ({ title, children, callToAction, className, ...rest }: ScoreContainerProps) => {
  return (
    <div
      className={cn('ScoreContainer', { clickable: callToAction?.onClick }, className)}
      onClick={callToAction?.onClick}
      {...rest}
    >
      <div className='score-container-header'>
        <Typography variant='labelCaps' className='score-container-title'>
          {title}
        </Typography>
      </div>
      <div className='score-container-content'>
        <div className='score-container-inner-content'>{children}</div>
      </div>
      {callToAction && (
        <div className='score-container-footer'>
          <Button onClick={callToAction.onClick} type='tertiary' color='blue' endIcon={<Icon icon='nav-forward' />}>
            {callToAction.text}
          </Button>
        </div>
      )}
    </div>
  );
};
