import { Button } from '../Button';
import { Icon } from '../Icon';
import { useWizard } from './hooks';
import './WizardFooter.css';

export const WizardFooter = ({ nextStepText }: { nextStepText: string | undefined }) => {
  const { currentStep, numberOfSteps, finishWizard, goToNextStep, goToPreviousStep } = useWizard();

  return (
    <footer className='Wizard-footer'>
      {currentStep > 0 && (
        <Button onClick={goToPreviousStep} type='tertiary' color='white' startIcon={<Icon icon='nav-back' />}>
          Back
        </Button>
      )}
      {currentStep < numberOfSteps - 1 && (
        <Button onClick={goToNextStep} type='secondary' color='blue' endIcon={<Icon icon='nav-forward' />}>
          {nextStepText ?? nextStepText}
        </Button>
      )}
      {currentStep === numberOfSteps - 1 && (
        <Button color='white' onClick={finishWizard} type='secondary' endIcon={<Icon icon='arrow-right' />}>
          Get started
        </Button>
      )}
    </footer>
  );
};
