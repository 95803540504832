import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';
import { CheckboxCell } from './Cell';

import './Row.css';

interface RowProps {
  children?: ReactNode;
  toggleRow?: () => void;
  toggled?: boolean;
  onClick?: () => void;
  className?: string;
}

export const Row = ({ children, toggleRow, toggled, onClick, className }: RowProps): ReactElement => {
  return (
    <tr
      className={cn('TableRow', Boolean(onClick) && 'clickable', className)}
      onClick={onClick}
      role={onClick && 'button'}
    >
      {toggleRow && <CheckboxCell toggleCell={toggleRow} toggled={Boolean(toggled)} />}
      {children}
    </tr>
  );
};
