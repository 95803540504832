import type { ReactElement } from 'react';
import React from 'react';
import cn from 'classnames';
import type { ButtonColor, ButtonType } from '../../Button/Button';
import { Button } from '../../Button/Button';

import './Footer.css';
import type { IconName } from '../../Icon';

export interface ModalFooterButton {
  title: string;
  onClick: () => void;
  type?: ButtonType;
  color?: ButtonColor;
  disabled?: boolean;
  loading?: boolean;
  startIcon?: IconName | ReactElement;
  endIcon?: IconName | ReactElement;
}

export interface PrimaryButtonProps extends ModalFooterButton {
  rightAdjusted?: boolean;
}

export interface FooterProps {
  primary?: PrimaryButtonProps;
  secondary?: ModalFooterButton;
}

export const Footer = ({ primary, secondary }: FooterProps): ReactElement => {
  return (
    <footer className='ModalFooter-container'>
      <div className={cn('ModalFooter', secondary && primary && 'ModalFooter-buttons')}>
        {secondary && (
          <Button
            onClick={secondary?.onClick}
            type={secondary.type}
            color={secondary.color}
            disabled={secondary.disabled}
            loading={secondary.loading}
            startIcon={secondary.startIcon}
            endIcon={secondary.endIcon}
          >
            {secondary?.title}
          </Button>
        )}
        {primary && (
          <Button
            onClick={primary?.onClick}
            color={primary.color}
            disabled={primary.disabled}
            type={primary.type}
            loading={primary.loading}
            startIcon={primary.startIcon}
            endIcon={primary.endIcon}
          >
            {primary?.title}
          </Button>
        )}
      </div>
    </footer>
  );
};
