import type { KeyboardEvent, MouseEvent } from 'react';
import { useCallback } from 'react';

/**
 * A basic hook for keyboard navigation of list items.
 *
 * TODO: We might want to generalize this using e.g. FloatingUI instead, in the future.
 */
export const useBasicListNavigation = ({
  onSelect,
}: {
  onSelect?: (e: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>) => void;
}) => {
  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLLIElement>) => {
      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft': {
          const previousSibling = e.currentTarget.previousSibling;
          if (previousSibling instanceof HTMLElement) {
            e.preventDefault();
            previousSibling.focus();
          }
          break;
        }
        case 'ArrowDown':
        case 'ArrowRight': {
          const nextSibling = e.currentTarget.nextSibling;
          if (nextSibling instanceof HTMLElement) {
            e.preventDefault();
            nextSibling.focus();
          }
          break;
        }
        case 'Enter': {
          onSelect && onSelect(e);
          break;
        }
        case 'Escape': {
          e.currentTarget.blur();
          break;
        }
      }
    },
    [onSelect],
  );

  return { onKeyDown };
};
