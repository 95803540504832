import styles from './ProgressAccordion.module.css';
import cn from 'classnames';
import type { ProgressAccordionStepProps } from './types';
import { Badge } from '../Badge';
import { Icon } from '../Icon';
import { useCallback } from 'react';
import { Expand } from '../Animation';
import { Typography } from '../Typography';
import React from 'react';

export const ProgressAccordionStep = ({
  id,
  active,
  complete,
  hideProgress,
  badge,
  title,
  titleSecondary,
  description,
  children,
  onToggle,
}: ProgressAccordionStepProps) => {
  const onToggleInternal = useCallback(() => {
    onToggle(id);
  }, [id, onToggle]);

  const numberOfDefinedChildren = React.Children.toArray(children).length;
  const disabled = numberOfDefinedChildren === 0;

  return (
    <div className={cn(styles.step, { [styles.active]: active })} aria-disabled={disabled}>
      <div className={cn(styles.progress, { [styles.hidden]: hideProgress })}>
        {!hideProgress && (
          <div className={cn(styles.state, { [styles.completed]: complete })}>
            <Icon icon='check' size='small' className={styles.stateIcon} />
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title} onClick={disabled ? undefined : onToggleInternal}>
            <Typography variant='label2'>{title}</Typography>
            {titleSecondary && (
              <>
                <div className={styles.titleSecondary}>–</div>
                <div className={styles.titleSecondary}>
                  <Typography className={styles.titleEllipsis} variant='text1' color='secondary' truncate>
                    {titleSecondary}
                  </Typography>
                </div>
              </>
            )}
            {badge && <Badge {...badge.props}>{badge.text}</Badge>}
            <div className={styles.navigationIconWrapper}>
              {!disabled && (
                <div className={styles.navigationIcon}>
                  <Icon icon='nav-back-chevron' className={styles.stepStateIcon} />
                </div>
              )}
            </div>
          </div>
          {description && (
            <Typography variant='text1' color='secondary' className={styles.description}>
              {description || ''}
            </Typography>
          )}
        </div>

        <Expand expanded={active && numberOfDefinedChildren > 0}>
          <div className={styles.expansionContent}>{children}</div>
        </Expand>
      </div>
    </div>
  );
};

ProgressAccordionStep.displayName = 'ProgressAccordionStep';
