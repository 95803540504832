import { useMobile } from 'aim-utils';
import type { CSSProperties, ReactNode } from 'react';
import React, { useRef } from 'react';
import type { TransitionStatus } from 'react-transition-group';
import { Transition } from 'react-transition-group';

interface SlideProps {
  expanded?: boolean;
  duration?: number;
  children: ReactNode;
  offset: number;
}

export const Slide = ({ expanded, duration = 500, children, offset }: SlideProps) => {
  const nodeRef = useRef(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const mobileView = useMobile();

  const defaultStyle = {
    transition: `margin-right ${duration}ms ease-out`,
    marginRight: `-${offset}px`,
  };

  const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
    entering: { marginRight: '0' },
    entered: { marginRight: '0' },
    exiting: { marginRight: `-${offset}px` },
    exited: { marginRight: `-${offset}px` },
  };

  return (
    <Transition nodeRef={nodeRef} in={expanded} timeout={duration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={
            mobileView
              ? {}
              : {
                  ...defaultStyle,
                  ...transitionStyles[state],
                }
          }
        >
          <div ref={innerRef}>{children}</div>
        </div>
      )}
    </Transition>
  );
};
