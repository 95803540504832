import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import type { TooltipModel } from 'chart.js';
import cn from 'classnames';

import './TooltipContainer.css';

export interface TooltipContainerProps {
  tooltipData?: TooltipModel<'line' | 'bar' | 'scatter'>;
  position: { left?: number; top?: number };
  visible: boolean;
  children: ReactNode;
}

export const TooltipContainer = ({
  tooltipData,
  position,
  visible,
  children,
}: TooltipContainerProps): ReactElement | null => {
  if (!tooltipData || Number.isNaN(position?.left) || Number.isNaN(position?.top)) {
    return null;
  }

  return (
    <div
      style={{
        left: position.left,
        top: position.top,
      }}
      className={cn('TooltipContainer', visible ? 'visible' : 'invisible')}
      id='chart-tooltip'
    >
      {children}
    </div>
  );
};

const TooltipContainerStandalone = ({ children }: { children: ReactNode }) => {
  return <div className={cn('TooltipContainer', 'TooltipContainerStandalone')}>{children}</div>;
};

/** Standalone version of `TooltipContainer`, without any positioning. Used for tooltips in SciChart charts. */
TooltipContainer.Standalone = TooltipContainerStandalone;
