import { useContext, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { MIN_NUMBER_OF_TENANTS_TO_SHOW_SEARCH, useTenants } from './hooks';
import { BottomSheet, ContextMenu } from 'aim-components';
import InviteUserModal from '@/components/settings/InviteUserModal/InviteUserModal';
import { TenantListItem } from './TenantListItem';
import { OpenTenantSearchListItem, TenantSearchMobile } from './TenantSearch';
import { UserContext } from '@/components/authentication/UserContext';
import { getUserPermissions } from '@/lib/permissions/role';
import { TenantSelectorTrigger } from '@/components/layouts/navigation/navigation-menu/tenant-selector/TenantSelectorTrigger';

export const TenantSelectorMobile = () => {
  const { t } = useTranslation('common', { keyPrefix: 'tenantContextMenu' });

  const { tenant, tenants, selectableTenants, recentTenants, isLoading } = useTenants();
  const [showTenantMenu, setShowTenantMenu] = useState(false);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [showSearchTenants, setShowSearchTenants] = useState(false);

  const numberOfSelectableTenants = selectableTenants.length;
  const numberOfRecentTenants = recentTenants.length;

  const user = useContext(UserContext);
  const hasDeckViewerRole = getUserPermissions(user.role).hasDeckViewerRole;
  const isAimUser = !hasDeckViewerRole;

  return (
    <>
      <TenantSelectorTrigger tenant={tenant} icon='dropdown-down' onClick={() => setShowTenantMenu(true)} />

      <InviteUserModal showModal={showInviteUserModal} setShowModal={setShowInviteUserModal} />

      <BottomSheet open={showTenantMenu} onClose={() => setShowTenantMenu(false)}>
        <div>
          {isAimUser && (
            <>
              <ContextMenu.Title>{tenant}</ContextMenu.Title>
              <ContextMenu.List>
                <ContextMenu.ListItem
                  label={t('inviteUsers')}
                  icon='invite-user'
                  onClick={() => {
                    setShowTenantMenu(false);
                    setShowInviteUserModal(true);
                  }}
                />
              </ContextMenu.List>
            </>
          )}
          {isLoading ? (
            <ContextMenu.Spinner />
          ) : (
            <>
              {numberOfSelectableTenants > 0 && (
                <>
                  {numberOfSelectableTenants > MIN_NUMBER_OF_TENANTS_TO_SHOW_SEARCH ? (
                    <>
                      {/* With many tenants to choose from: show recently visited tenants (if any), followed by the ability to search for tenants. */}
                      <ContextMenu.Title>
                        {t(numberOfRecentTenants > 0 ? 'switchTenantMostRecent' : 'switchTenant')}
                      </ContextMenu.Title>
                      <ContextMenu.List>
                        {recentTenants.map((tenant) => (
                          <TenantListItem key={tenant} tenant={tenant} onClick={() => setShowTenantMenu(false)} />
                        ))}

                        <OpenTenantSearchListItem
                          onClick={() => {
                            setShowTenantMenu(false);
                            setShowSearchTenants(true);
                          }}
                        />
                      </ContextMenu.List>
                    </>
                  ) : (
                    <>
                      {/* With few tenants to choose from: display them directly in the menu. We don't care about recently visited tenants or searching here. */}
                      <ContextMenu.Title>{t('switchTenant')}</ContextMenu.Title>
                      <ContextMenu.List>
                        {selectableTenants.map((tenant) => (
                          <TenantListItem key={tenant} tenant={tenant} onClick={() => setShowTenantMenu(false)} />
                        ))}
                      </ContextMenu.List>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </BottomSheet>

      <TenantSearchMobile open={showSearchTenants} onClose={() => setShowSearchTenants(false)} tenants={tenants} />
    </>
  );
};
