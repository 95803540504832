import React, { useCallback, useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';
import { formatValue } from '../../../utils';
import { getRootCssVariable } from '../../../utils/utils';
import type { D3BubbleChartData } from '../types';
import { circleMovesToFar } from './utils';
import { LineColors } from 'aim-utils';

interface AnimatedBubbleProps {
  cx: number;
  cy: number;
  r: number;
  fill: string;
  point: D3BubbleChartData;
  unit?: string;
  label: string;
  getTooltipPosition: (position: { x: number; y: number }, label: string, visible: boolean) => void;
}

const AnimatedBubble = ({ cx, cy, r, fill, getTooltipPosition, point, unit, label }: AnimatedBubbleProps) => {
  const displayLabel = point.y > 0.075 || r > 50;
  const [hovered, setHovered] = useState(false);
  const xRef = useRef(cx);
  const yRef = useRef(cy);
  const style = useSpring({
    config: {
      duration: 500,
    },
    r: Math.abs(r),
    opacity: 1,
    cx: cx,
    cy: cy,
    from: {
      r: 0,
      ...(circleMovesToFar(cx, xRef.current) && { cx: cx }),
      ...(circleMovesToFar(cy, yRef.current) && { cy: cy }),
    },
    reset: circleMovesToFar(cx, xRef.current) || circleMovesToFar(cy, yRef.current),
    onChange: () => {
      xRef.current = cx;
      yRef.current = cy;
    },
  });
  const labelStyle = useSpring({
    config: {
      duration: 500,
    },
    opacity: 1,
    x: cx,
    y: cy,
    from: {
      opacity: 0,
      ...(circleMovesToFar(cx, xRef.current) && { x: cx }),
    },
    reset: circleMovesToFar(cx, xRef.current) || circleMovesToFar(cy, yRef.current),
  });

  const onMouseOver = useCallback(() => {
    getTooltipPosition({ x: cx + r, y: cy + r }, label, true);
    setHovered(true);
  }, [cx, cy, getTooltipPosition, label, r]);

  const onMouseOut = useCallback(() => {
    getTooltipPosition({ x: cx + r, y: cy + r }, label, false);
    setHovered(false);
  }, [cx, cy, getTooltipPosition, label, r]);

  const lightText = fill === LineColors['vibrant-blue'] || fill === LineColors['vivid-red'];

  return (
    <>
      <animated.circle
        {...style}
        fill={fill}
        data-testid='circle'
        stroke={point?.stroke ?? ''}
        strokeWidth={1.5}
        className={cn(point?.blur && 'blur', hovered && 'hovered')}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
      {point?.label !== undefined && displayLabel && (
        <animated.text
          style={{
            fontSize: getRootCssVariable('--aim-font-size-text2'),
            textAnchor: 'middle',
            fill: lightText ? 'currentcolor' : '--aim-elevation-00dp',
            opacity: '0.6',
            pointerEvents: 'none',
          }}
          {...labelStyle}
        >
          <animated.tspan {...labelStyle}>
            <animated.tspan {...labelStyle}>{formatValue(point.y * 100, unit, 1)}</animated.tspan>
            <animated.tspan {...labelStyle} dy={'1.3em'}>
              {point.label}
            </animated.tspan>
          </animated.tspan>
        </animated.text>
      )}
    </>
  );
};

export default AnimatedBubble;
