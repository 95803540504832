import { Typography } from 'aim-components';
import styles from './NotificationCard.module.css';
import { getFlagEmoji } from '@/components/insights/charts/common/utils';
import type { CountryCode } from 'aim-utils';

export const NotificationFlags = ({ countryCodes }: { countryCodes: CountryCode[] }) => {
  const notificationFlags = countryCodes.map((countryCode, i) => {
    return { key: `${countryCode}-${i}`, flag: getFlagEmoji(countryCode) };
  });
  const notificationHasFlag = notificationFlags.find((flag) => flag.flag !== '');

  return (
    notificationHasFlag && (
      <>
        <Typography variant='text2' color='secondary' className={styles.countryCodes}>
          {' • '}
        </Typography>
        {notificationFlags.map(({ key, flag }) => {
          return (
            <Typography key={key} variant='text2' className={styles.countryCodes}>
              {flag}
            </Typography>
          );
        })}
      </>
    )
  );
};
