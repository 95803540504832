const numberFormatterNoUnit = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
});

export const currencyFormatter = (currency: string, value: number, fractions = 1) => {
  if (!currency) return numberFormatterNoUnit.format(value);
  return new Intl.NumberFormat('en-US', {
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: fractions,
    notation: 'compact',
    style: 'currency',
  }).format(value);
};

export const formatValue = (value: number | null | undefined, unit?: string, toFixed?: number): string => {
  if (value === null || value === undefined) return 'N/A';
  if (toFixed !== undefined) return `${value.toFixed(toFixed)} ${unit ?? ''}`.trim();
  try {
    return currencyFormatter(unit as string, value);
  } catch {
    return `${numberFormatterNoUnit.format(value)} ${unit ?? ''}`.trim();
  }
};

export const valueFormatter = (value: number | null | undefined, unit?: string, fractions?: number) => {
  if (value === null || value === undefined) return 'N/A';
  try {
    return currencyFormatter(unit as string, value, fractions);
  } catch (e) {
    if (unit === '%') {
      return `${numberFormatterNoUnit.format(value)}%`;
    }
    if (unit === 'x') {
      return `${fractions ? value.toFixed(fractions) : numberFormatterNoUnit.format(value)}x`;
    }
    return `${numberFormatterNoUnit.format(value)} ${unit ?? ''}`.trim();
  }
};

export const formatNumberInput = (value: string | number | undefined) => {
  const numberValue = value?.toString().replace(/[^0-9]+/g, '') ?? '';
  return numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

/**
 * Keep the same reference if the array is empty to avoid unnecessary re-renders, as [] !== [].
 */
export const EMPTY_ARRAY_STATIC_REFERENCE = [];

export const getRootCssVariable = (variable: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(variable);

/**
 * @example mapNumberFromOneRangeToAnother(25, 0, 100, 0, 1) => 0.25
 */
export const mapNumberFromOneRangeToAnother = (
  value: number,
  [fromMin, fromMax]: readonly [number, number],
  [toMin, toMax]: readonly [number, number],
) => {
  return ((value - fromMin) * (toMax - toMin)) / (fromMax - fromMin) + toMin;
};

/**
 * Takes an `arkUserId` (which is a `UUID` – a string of hexadecimal digits) and parses it to a HSL hue value `(0-360)`.
 * @returns a HSL hue value between @param minimumAllowedHue and @param maximumAllowedHue.
 */
export const getHslColorHueFromArkUserId = (
  arkUserId = '',
  minimumAllowedHue = 30, // 30 is used as the minimum to exclude muddy brown colors.
  maximumAllowedHue = 360,
): number => {
  const stringAsNumber = parseInt(arkUserId.slice(0, 8), 16);
  const hue = Number.isNaN(stringAsNumber) ? 0 : stringAsNumber % 360;
  const hueWithinAllowedRange = Math.floor(
    mapNumberFromOneRangeToAnother(hue, [0, 360], [minimumAllowedHue, maximumAllowedHue]),
  );

  return hueWithinAllowedRange;
};

export const clamp = ({ value, min, max }: { value: number; min: number; max: number }) => {
  return Math.min(Math.max(value, min), max);
};
