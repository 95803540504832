import React, { cloneElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import useKeyDown from '../../../utils/useKeyDown';
import { ChartHeader } from '../ChartHeader';
import { useChartFullscreen, useSlider } from '../hooks';
import ConditionalWrapper from '../../ConditionalWrapper';
import { Modal } from '../../Modal';
import type { ChartContainerProps } from './types';
import './ChartContainer.css';
import XAxisFilter from '../XAxisFilter/XAxisFilter';
import { AnnotationContext } from '../../Annotation';
import { useGetHighlightedTicks } from '../utils';
import type { ChartXAxis } from '../types';
import { Slider } from '../../Slider';
import { useCustomEventListener } from '../../../utils/useCustomEvent';
import { ChartContainerMobile } from './ChartContainer.mobile';
import { ForecastChartContext } from '../ForecastChartContext';
import { InsightContext } from '../InsightContext';
import { SliderContext } from '../SliderContext';

export const ChartContainer = ({
  info,
  loader,
  actions = {},
  sidebar,
  sidebarInitiallyOpened = false,
  children,
  legend,
  useAsPlaceholder = false,
  displayAnnotationsOnSlider = true,
  noBackgroundColor = false,
  nonReliableForecast,
  onSliderChange,
  onFullscreenChange, // TODO: Ideally we might want to move the "expanded" state to the parent, and pass it down as a prop
  badge,
  legendTop,
}: ChartContainerProps) => {
  const [expanded, setExpanded] = useChartFullscreen(actions.initiallyExpanded ?? false);
  const [responsive, setResponsive] = useState(true);

  useKeyDown({ keyCode: 'Escape', onKeyDown: expanded ? () => setExpanded(false) : null });

  // ? Used to disable chart animations while the main navigation menu is transitioning, for performance reasons.
  useCustomEventListener('navigationMenuTransitionChanged', ({ detail: { done } }) => setResponsive(done));

  const insightContext = useContext(InsightContext);

  useEffect(() => {
    onFullscreenChange && onFullscreenChange(expanded);
    expanded && insightContext?.FSEventOnExpandInsight();
  }, [expanded, insightContext, onFullscreenChange]);

  const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);

  const toggleSidebar = useCallback(() => {
    if (useAsPlaceholder) return;

    setResponsive(false);
    setSidebarOpened((opened) => !opened);
  }, [useAsPlaceholder]);

  const hasSidebar = !!sidebar;

  useEffect(() => {
    if (useAsPlaceholder || !hasSidebar) {
      setSidebarOpened(false);
    } else {
      setSidebarOpened((currentlyOpened) => currentlyOpened || sidebarInitiallyOpened);
    }
  }, [hasSidebar, sidebarInitiallyOpened, useAsPlaceholder]);

  const chartHeaderLegend = typeof legend === 'function' ? legend({ toggleSidebar }) : legend;

  const xAxis = useMemo(() => {
    let axis = {} as ChartXAxis;
    React.Children.forEach(children, (child: any) => (axis = child?.props?.xAxis ?? axis));
    return axis;
  }, [children]);

  const labels = useMemo(() => {
    let labels: Array<string> = [];
    React.Children.forEach(children, (child: any) => (labels = child?.props?.labels ?? labels));
    return labels;
  }, [children]);

  const { annotations } = useContext(AnnotationContext);
  const highlightedTicks = useGetHighlightedTicks(labels, annotations);

  const [xmin, xmax, handleRange] = useSlider(labels, xAxis?.slider ?? {}, onSliderChange);
  const visibleRangeRef = useRef<{ min?: number; max?: number }>({});

  return (
    <SliderContext.Provider value={{ visibleRangeRef }}>
      <ForecastChartContext.Provider value={{ nonReliableForecast }}>
        <div className='ChartContainer' data-chart-container-actions-disabled={useAsPlaceholder}>
          {loader ? (
            loader
          ) : (
            <ConditionalWrapper
              condition={expanded}
              wrapper={(children) => (
                <div className='Chart-expandedModal'>
                  <Modal onClose={() => setExpanded(false)} open={expanded} padding={false}>
                    {children}
                  </Modal>
                </div>
              )}
            >
              <div className={cn('Chart-inner-container', expanded && 'Chart-expandedContainer')}>
                <div className={cn('Chart-left-container', noBackgroundColor && 'no-background-color')}>
                  <ChartHeader
                    {...info}
                    setCustomHeaderTitle={actions.setCustomHeaderTitle}
                    onEdit={actions.onEdit}
                    copyChartLink={actions.copyChartLink}
                    onExpand={actions.enableExpanding !== false && !expanded ? () => setExpanded(!expanded) : undefined}
                    saveToDeck={actions.saveToDeck}
                    onInfoClick={expanded ? undefined : actions.onInfoClick}
                    onReport={expanded ? undefined : actions.onReport}
                    dataExport={actions.dataExport}
                    sidebar={
                      !actions.hideSidebar && sidebar && !actions.hideSideBarIcon
                        ? {
                            opened: sidebarOpened,
                            onToggle: useAsPlaceholder ? undefined : toggleSidebar,
                          }
                        : undefined
                    }
                    legend={!useAsPlaceholder && chartHeaderLegend}
                    badge={badge}
                    legendTop={legendTop}
                  />
                  <div className='ChartContainer-content'>
                    <>
                      {React.Children.map(
                        children,
                        (child: any) =>
                          child &&
                          cloneElement(child, {
                            title: info?.title,
                            responsive,
                            labels,
                            xmin,
                            xmax,
                          }),
                      )}
                      {(xAxis?.slider || xAxis?.title) && (
                        <div className='Chart-footer'>
                          {xAxis?.slider && (
                            <div className='Chart-sliderContainer'>
                              <Slider
                                initialMinValue={xmin}
                                initialMaxValue={xmax}
                                rangeMax={labels.length - 1}
                                getSliderRange={handleRange}
                                highlightedTicks={displayAnnotationsOnSlider ? highlightedTicks : []}
                              />
                            </div>
                          )}
                          <XAxisFilter
                            axisTitle={xAxis?.title}
                            isLoading={xAxis?.isLoading}
                            leftAction={xAxis?.actions?.leftAction}
                            rightAction={xAxis?.actions?.rightAction}
                          />
                        </div>
                      )}
                    </>
                  </div>
                </div>
                {sidebar &&
                  !actions.hideSidebar &&
                  React.cloneElement(sidebar, { opened: sidebarOpened, onTransitionEnd: () => setResponsive(true) })}
              </div>
            </ConditionalWrapper>
          )}
        </div>
      </ForecastChartContext.Provider>
    </SliderContext.Provider>
  );
};

ChartContainer.Mobile = ChartContainerMobile;
