import type { MutableRefObject } from 'react';
import { createContext } from 'react';

export interface SliderContextProps {
  visibleRangeRef: MutableRefObject<{
    min?: number;
    max?: number;
  }>;
}

export const SliderContext = createContext<SliderContextProps>({
  visibleRangeRef: { current: {} },
});
