import type { ReactNode } from 'react';
import React from 'react';
import styles from './Settings.module.css';

export const SettingsContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles.settingsWrapper}>
      <div className={styles.settingsContainer}>{children}</div>
    </div>
  );
};
