import type { ReactElement, ReactNode } from 'react';
import React, { memo, useContext } from 'react';
import cn from 'classnames';
import { HoverTooltip } from '../../HoverTooltip';
import { Icon } from '../../Icon';
import { Typography } from '../../Typography';
import type { ChartActions } from '../ChartContainer/types';
import './ChartHeader.css';
import { DeckChartContext, isDefined } from 'aim-utils';
import { ChartHeaderTitle } from './ChartHeaderTitle';

interface ChartHeaderProps extends ChartActions {
  title?: string;
  subtitle?: string | string[];
  expanded?: boolean;
  saveToDeck?: ReactNode;
  legend?: ReactNode;
  sidebar?: {
    opened: boolean;
    onToggle: undefined | (() => void);
  };
  onEdit?: () => void;
  onExpand?: (() => void) | null;
  setCustomHeaderTitle?: (title: string) => void;
  badge?: ReactNode;
  legendTop?: ReactNode;
}

function ChartHeader({
  title,
  subtitle,
  copyChartLink,
  saveToDeck,
  legend,
  sidebar,
  onEdit,
  onInfoClick,
  onReport,
  onExpand,
  dataExport,
  setCustomHeaderTitle,
  badge,
  legendTop,
}: ChartHeaderProps): ReactElement {
  const subtitles = Array.isArray(subtitle) ? subtitle : [subtitle];
  const subtitleParts = subtitles.filter(isDefined);
  const deckChartContext = useContext(DeckChartContext);

  return (
    <div className='Chart-header'>
      <div className='header-row no-wrap'>
        <ChartHeaderTitle
          title={title}
          customHeaderTitle={deckChartContext?.title}
          setCustomHeaderTitle={setCustomHeaderTitle}
          onInfoClick={onInfoClick}
          badge={badge}
          legendTop={legendTop}
        />
        <div className='Chart-actions'>
          {onEdit && (
            <HoverTooltip message='Edit card' position='left'>
              <Icon icon='pen' role='button' onClick={onEdit} aria-label='Launch chart edit modal' />
            </HoverTooltip>
          )}
          {saveToDeck && saveToDeck}
          {dataExport && dataExport}
          {copyChartLink && (
            <HoverTooltip message={copyChartLink.tooltipMessage} position='left'>
              <Icon icon='link' role='button' onClick={copyChartLink.onCopy} aria-label='Copy chart link' />
            </HoverTooltip>
          )}
          {onExpand && (
            <HoverTooltip message='Show chart in full screen.' position='left'>
              <Icon icon='maximize' role='button' onClick={onExpand} aria-label='Expand chart' />
            </HoverTooltip>
          )}
          {onReport && (
            <HoverTooltip message='Report chart.' position='left'>
              <Icon icon='flag' role='button' onClick={onReport} aria-label='Report chart' />
            </HoverTooltip>
          )}
          {sidebar && (
            <HoverTooltip
              message={sidebar.opened ? 'Collapse chart side panel.' : 'Open chart side panel.'}
              position='left'
            >
              <Icon
                icon='sidebar-flip'
                className={cn('sidebar-button', sidebar.opened && 'active')}
                role='button'
                onClick={sidebar.onToggle}
                aria-label='Show side panel'
              />
            </HoverTooltip>
          )}
        </div>
      </div>
      <div className='header-row'>
        <div onClick={() => sidebar?.onToggle && sidebar.onToggle()} style={{ display: 'flex' }}>
          {subtitleParts.map((subtitlePart) => (
            <Typography
              key={subtitlePart}
              variant='text2'
              className={cn('Chart-subtitle', { hoverable: !!sidebar?.onToggle })}
            >
              {subtitlePart}
            </Typography>
          ))}
        </div>
        {legend}
      </div>
    </div>
  );
}

export default memo(ChartHeader);
