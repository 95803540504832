import { useState, type ComponentProps } from 'react';
import { DayPicker } from 'react-day-picker';
import { Icon } from '../Icon';
import styles from './DatePicker.module.css';
import { Popover } from '../Popover';
import { DatePickerButton } from './date-picker-button/DatePickerButton';

export type DatePickerProps = ComponentProps<typeof DayPicker> & {
  /** If the date picker is rendered as a standalone component. Should be `false` if inside a popover. */
  standalone?: boolean;
};

export const DatePicker = ({ standalone = true, ...dayPickerProps }: DatePickerProps) => {
  return (
    <DayPicker
      {...dayPickerProps}
      data-standalone={standalone !== false}
      classNames={{
        /* eslint-disable camelcase */
        root: styles.datePicker,
        months: styles.months,
        month_caption: styles.caption,
        caption_label: styles.captionLabel,
        nav: styles.navigation,
        button_previous: styles.navigationButtonPrevious,
        button_next: styles.navigationButtonNext,
        weekdays: styles.headRow,
        weekday: styles.headCell,
        month_grid: styles.table,
        week: styles.row,
        day: styles.cell,
        day_button: styles.day,
        selected: styles.daySelected,
        outside: styles.dayOutside,
        disabled: styles.dayDisabled,
        /* eslint-enable camelcase */
      }}
      showOutsideDays={dayPickerProps.showOutsideDays !== false}
      components={{
        Chevron: (props) => {
          if (props.orientation === 'left') {
            return <Icon icon='nav-back-chevron' size='xs' />;
          }
          return <Icon icon='nav-forward-chevron' size='xs' />;
        },
      }}
    />
  );
};

type DatePickerPopoverProps = DatePickerProps & {
  placeholder?: string;
  placement?: ComponentProps<typeof Popover>['placement'];
};

export const DatePickerPopover = ({ placeholder, placement, ...datePickerProps }: DatePickerPopoverProps) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Popover
      open={showPopover}
      onOpenChange={setShowPopover}
      offset={{ mainAxis: 8 }}
      placement={placement}
      renderPopoverTrigger={(triggerProps) => (
        <div {...triggerProps}>
          <DatePickerButton
            {...datePickerProps}
            placeholder={placeholder}
            onClick={() => setShowPopover(!showPopover)}
          />
        </div>
      )}
    >
      <DatePicker {...datePickerProps} standalone={false} onDayClick={() => setShowPopover(!showPopover)} />
    </Popover>
  );
};
