import React, { memo } from 'react';
import '../Annotations.css';
import AnnotationBubbleIcon from './AnnotationBubbleIcon';

interface AnnotationHoverLineProps {
  height: number;
  hoverXPos?: number;
}

const AnnotationHoverLine = ({ height, hoverXPos }: AnnotationHoverLineProps) => {
  return (
    <g>
      {hoverXPos && (
        <g transform={`translate(${hoverXPos}, 0)`}>
          <rect
            x={0}
            y={0}
            width={1}
            height={height}
            className='annotationAccentColorPrimary noPointerEvents'
            opacity={0.3}
          />
          <g transform={`translate(5, ${height / 2})`}>
            <AnnotationBubbleIcon />
          </g>
        </g>
      )}
    </g>
  );
};
export default memo(AnnotationHoverLine);
