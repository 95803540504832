import { Footer } from 'aim-components';
import { getYear } from 'date-fns';
import { TenantContext } from '../tenant/context';
import { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { LEGAL_DOCS_ROOT, PRIVACY_NOTICE_LINK, TERMS_OF_SERVICE_LINK } from '@/lib/legalDocuments/legalDocuments';

const AimFooter = () => {
  const { t } = useTranslation('common', { keyPrefix: 'footer' });
  const { countryCode } = useContext(TenantContext);
  const currentYear = getYear(new Date());

  const footerItems = [
    { label: t('gilionCopyright', { currentYear }) },
    { label: t('privacyNotice'), url: PRIVACY_NOTICE_LINK },
    { label: t('termsOfService'), url: TERMS_OF_SERVICE_LINK },
    { label: t('trackingTechnologyNotice'), url: `${LEGAL_DOCS_ROOT}/en/tt_notice.pdf` },
  ];

  if (countryCode === 'US') {
    footerItems.push({ label: t('noticeAtCollection'), url: `${LEGAL_DOCS_ROOT}/us/collection.pdf` });
  }

  return <Footer items={footerItems} />;
};

export default AimFooter;
