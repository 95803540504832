import type { NumberRange } from 'scichart';

// * SciChart uses a UNIX timestamp in seconds, while the Date object uses a UNIX timestamp in milliseconds.
export const getUnixTimestampInSeconds = (date: Date | string | number | undefined) => {
  if (!date) return 0;
  const DIVISOR = 1000;
  if (date instanceof Date) return date.getTime() / DIVISOR;

  return new Date(date).getTime() / DIVISOR;
};

export const padArray = <T>({ array, length, fillWith }: { array: T[]; length: number; fillWith: T }) => {
  const valuesToPrepend = Math.max(0, length - array.length);
  const newValues = Array(valuesToPrepend).fill(fillWith);

  return [...newValues, ...array];
};

export const filterDataPointsInRange = (numberRange: NumberRange) => {
  return ({ x }: { x: number | string }) => {
    const unixValue = getUnixTimestampInSeconds(x);
    return unixValue >= numberRange.min && unixValue <= numberRange.max;
  };
};
