import type { ComponentProps, ReactElement } from 'react';
import React from 'react';
import type { ChartDataset } from '../types';
import { Legend } from './Legend';
import { LegendIcon } from './LegendIcon';
import { LineColors } from 'aim-utils';
import { EMPTY_ARRAY_STATIC_REFERENCE } from '../../../utils';

interface ChartLegendProps extends Omit<ComponentProps<typeof Legend>, 'children'> {
  datasets?: ChartDataset[];
  onClick?: () => void;
  hasForecastedDatasets?: boolean;
}

export const ChartLegend = ({
  datasets = EMPTY_ARRAY_STATIC_REFERENCE,
  onClick,
  hasForecastedDatasets,
  ...legendProps
}: ChartLegendProps): ReactElement => {
  const nonReferenceDatasets = datasets.filter((dataset) => !dataset.isReferenceLine);
  const referenceColor = datasets.find((dataset) => dataset.isReferenceLine)?.color;

  return (
    <Legend {...legendProps}>
      {nonReferenceDatasets.map((dataset) => {
        return (
          <Legend.Item
            key={dataset.label}
            color={dataset.color}
            onClick={onClick}
            icon={Legend.getLegendIconFromDataset(dataset)}
          >
            {dataset.label}
          </Legend.Item>
        );
      })}
      {referenceColor && (
        <Legend.Item key='globalReference' icon={<LegendIcon.DottedLine color={referenceColor} />}>
          Global reference
        </Legend.Item>
      )}
      {hasForecastedDatasets && (
        <>
          <Legend.Item icon={<LegendIcon.Line color={LineColors['cool-grey']} />}>Actual</Legend.Item>
          <Legend.Item icon={<LegendIcon.DashedLine color={LineColors['cool-grey']} />}>Forecast</Legend.Item>
        </>
      )}
    </Legend>
  );
};
