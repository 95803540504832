import React, { useCallback, useState } from 'react';
import type { MentionItem, SuggestionDataItem } from 'react-mentions';
import { isValidMessage } from '../../Annotation/utils';
import { Avatar } from '../../Avatar/Avatar';
import { emojis } from './emoji-suggestions';
import type { MentionData } from './types';
import { getUniqueMentionedIds, removeDisplayValueMentionsFromString } from './utils';

export const useMentions = (
  onChange?: (value: string, mentionedIds: Array<string>) => void,
  onSend?: (value: string, mentionedIds: Array<string>) => void,
) => {
  const [value, setValue] = useState('');
  const [mentions, setMentions] = useState<Array<string>>([]);

  const handleMentionsInputChange = useCallback(
    (_event: { target: { value: string } }, newValue: string, _newPlainTextValue: string, mentions: MentionItem[]) => {
      setValue(newValue);
      const uniqueMentionedIds = getUniqueMentionedIds(mentions);
      setMentions(uniqueMentionedIds);
      const stringWithoutDisplayValueMentions = removeDisplayValueMentionsFromString(newValue);
      onChange && onChange(stringWithoutDisplayValueMentions, uniqueMentionedIds);
    },
    [onChange],
  );

  const send = useCallback(() => {
    if (onSend && isValidMessage(value)) {
      onSend(removeDisplayValueMentionsFromString(value), mentions);
      setValue('');
    }
  }, [mentions, onSend, value]);

  const handleSend = useCallback(
    (event?: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
      if (!event) {
        send();
        return;
      }
      if (event.key === 'Escape' && document.activeElement instanceof HTMLElement) {
        return document.activeElement.blur();
      }

      event.stopPropagation(); // Prevent global key listeners from being triggered (i.e. on "C" key pressed, which toggles the annotation sidebar)

      if (event.key === 'Enter' && event.shiftKey === false) {
        event.preventDefault();
        send();
      }
    },
    [send],
  );

  return { value, handleMentionsInputChange, handleSend, setValue } as const;
};

export const useUserMentions = () => {
  const renderMentionSuggestion = useCallback((mention: SuggestionDataItem) => {
    return (
      <span className='mention-suggestion'>
        <Avatar size='small' name={mention.display ?? ''} arkUserId={`${mention.id}`} />
        <span className='suggestion-display-text'>{mention.display}</span>{' '}
        <span className='mention-hint'>({(mention as MentionData).email})</span>
      </span>
    );
  }, []);

  const transformDisplayedMention = useCallback((_id: string, display: string) => `@${display}`, []);

  return [renderMentionSuggestion, transformDisplayedMention] as const;
};

export const useEmojiMentions = () => {
  const renderEmojiSuggestion = useCallback(({ id, display }: SuggestionDataItem) => {
    return (
      <span>
        {id} {display}
      </span>
    );
  }, []);

  const getEmojiSuggestions = useCallback((query: string) => {
    return emojis.filter((emoji) => emoji.display.includes(query.toLowerCase()));
  }, []);

  return [renderEmojiSuggestion, getEmojiSuggestions] as const;
};
