import type { ComponentProps, ReactElement, ReactNode } from 'react';
import React, { forwardRef } from 'react';
import cn from 'classnames';
import './Button.css';
import type { IconName } from '../Icon';
import { Icon } from '../Icon';
import { useMobile } from 'aim-utils';

export type ButtonType = 'primary' | 'secondary' | 'tertiary';
export type ButtonColor = 'white' | 'blue' | 'destructive' | 'secondary' | 'dark';

export interface ButtonProps extends Omit<ComponentProps<'button'>, 'type'> {
  type?: ButtonType;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
  buttonType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  name?: string;
  value?: string;
  color?: ButtonColor;
  size?: 'large' | 'medium' | 'small';
  startIcon?: IconName | ReactElement;
  endIcon?: IconName | ReactElement;
  className?: ComponentProps<'button'>['className'];
  iconFillColor?: string;
}

const DEFAULT_SIZE_MOBILE = 'large';
const DEFAULT_SIZE_DESKTOP = 'medium';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'primary',
      onClick,
      children,
      name,
      value,
      buttonType = 'button',
      disabled = false,
      loading = false,
      color,
      size,
      startIcon,
      endIcon,
      className,
      iconFillColor,
      ...props
    },
    ref,
  ) => {
    const { mobileView } = useMobile();
    const settings: string[] = [];

    if (size) {
      settings.push(`Button-${size}`);
    } else {
      settings.push(`Button-${mobileView ? DEFAULT_SIZE_MOBILE : DEFAULT_SIZE_DESKTOP}`);
    }
    if (type) settings.push(`Button-${type}`);
    if (disabled) settings.push('disabled');
    if (loading) settings.push('loading');
    if (!children && (endIcon || startIcon)) settings.push('icon');
    if (!disabled && !loading) settings.push('hoverable');
    if (color) settings.push(color);

    const startIconElement =
      typeof startIcon === 'string' ? <Icon icon={startIcon} fillColor={iconFillColor} size={size} /> : startIcon;
    const endIconElement =
      typeof endIcon === 'string' ? <Icon icon={endIcon} fillColor={iconFillColor} size={size} /> : endIcon;

    return (
      <button
        ref={ref}
        type={buttonType}
        onClick={(e) => onClick && onClick(e)}
        className={cn('Button', 'ButtonLabel-default', settings.join(' '), className)}
        disabled={disabled || loading}
        name={name}
        value={value}
        {...props}
      >
        {startIconElement}
        {children && <div className='Button-text-content'>{children}</div>}
        {loading && <Icon icon='loading' animation='spin' />}
        {!loading && endIconElement}
      </button>
    );
  },
);

Button.displayName = 'Button';
