export enum Routes {
  Connectors = '/data/connectors',
  Data = '/data',
  DataValidation = '/data/validation',
  Decks = '/decks',
  Failure = '/registration/failure',
  FinalizeConnector = '/finalize',
  Forecasting = '/forecasting',
  GrowthChat = '/growth-chat',
  Home = '/',
  Insights = '/insights',
  InsightsActivity = '/insights/activity',
  InsightsFinancialStrength = '/insights/financial-strength',
  InsightsGrowth = '/insights/growth',
  InsightsMarketing = '/insights/marketing',
  InsightsMonetisation = '/insights/monetisation',
  InsightsRetention = '/insights/retention',
  InsightsScores = '/insights/scores',
  Benchmarking = '/benchmarking',
  BenchmarkingNetSales = '/benchmarking/net-sales',
  BenchmarkingARR = '/benchmarking/arr',
  BenchmarkingRetention = '/benchmarking/retention',
  KeyFinancials = '/data/key-financials',
  LoanApplication = '/[slug]/loans/application/[id]',
  LoanApplicationAssessmentStep = '/[slug]/loans/application/[id]/assessment',
  LoanApplicationCompanyStep = '/[slug]/loans/application/[id]/company',
  LoanApplicationDocumentStep = '/[slug]/loans/application/[id]/document',
  LoanApplicationLoanStep = '/[slug]/loans/application/[id]/loan',
  Loans = '/loans',
  InvestorNetwork = '/investors',
  Login = '/login',
  SubscriptionPlans = '/subscription-plans',
  RecoverPassword = '/login/recover',
  Registration = '/registration',
  Settings = '/settings',
  SignUp = '/signup',
  Unsupported = '/registration/unsupported',
  WaitList = '/registration/waitlist',
  VerifyEmail = '/verify-email',
  InvalidEmail = '/invalid-email',
  SetPassword = '/set-password',
  Spaces = '/spaces',
}
