import type { ComponentRef } from 'react';
import React, { useRef } from 'react';
import styles from './Welcome.module.css';
import Image from 'next/image';

interface WelcomeProps {
  onAnimationEnd: () => void;
}

export const Welcome = ({ onAnimationEnd }: WelcomeProps) => {
  const backdropRef = useRef<ComponentRef<'div'>>(null);

  return (
    <>
      <div
        className={styles.backdrop}
        ref={backdropRef}
        onAnimationEnd={(e) => {
          if (e.target === backdropRef.current) {
            onAnimationEnd();
          }
        }}
      />
      <div className={styles.container}>
        <Image
          src='/svg/gilion-white-registered-trademark.svg'
          width={163}
          height={92}
          alt='Gilion'
          className={styles.logo}
          priority
        />
      </div>
    </>
  );
};
