import type { ReactElement, MouseEvent, ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';

import './Pill.css';
import { Icon } from '../../Icon';

export interface PillProps {
  label: string;
  onClose?: () => void;
  icon?: ReactNode;
  notShowOnFocus?: boolean;
}

export const Pill = ({ label, onClose, icon, notShowOnFocus }: PillProps): ReactElement => {
  const onClosePill = (e: MouseEvent): void => {
    e.stopPropagation();
    onClose && onClose();
  };
  return (
    <span className={cn('Pill', notShowOnFocus && 'Not-show-on-focus')}>
      {icon && icon}
      <label className='Pill-label'>{label}</label>
      {onClose && (
        <Icon
          aria-label={`${label} close button`}
          style={{ fontSize: 10 }}
          icon='close'
          className='Pill-close'
          role='button'
          onClick={(e) => onClosePill(e)}
        />
      )}
    </span>
  );
};
