import React, { useContext } from 'react';
import { AnnotationContext } from '../AnnotationContext';
import cn from 'classnames';
import { Avatar } from '../../Avatar';
import styles from './NewAnnotationCard.module.css';
import { Typography } from '../../Typography';
import { Badge } from '../../Badge';

import { AnnotationInput } from '../AnnotationInput/AnnotationInput';

interface CreateAnnotationCardProps {
  xPos: number;
  width: number;
  onSend: (message: string, mentionedIds: Array<string>) => void;
}

/**
 * This selector is used to identify the create annotation card in the DOM, so that we can ignore clicks within it in the useOutsideClick hook.
 * There is likely a more elegant solution to this, perhaps by using refs or something similar, but it does not feel like a priority at the moment.
 */
export const CREATE_ANNOTATION_CARD_SELECTOR = 'create-annotation-card-selector';

export const CreateAnnotationCard = ({ xPos, width, onSend }: CreateAnnotationCardProps) => {
  const annotationContextData = useContext(AnnotationContext);

  return (
    <div
      className={cn('createAnnotation-card', CREATE_ANNOTATION_CARD_SELECTOR)}
      style={{
        transform:
          xPos < width / 2
            ? `translate(calc(${xPos}px  + 100px), -50%)`
            : `translate(calc(${xPos}px - 100% + 50px), -50%)`,
      }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Avatar name={annotationContextData.userName} arkUserId={annotationContextData.userId} size='small' />
          <Typography variant='text1'>{annotationContextData.userName}</Typography>
          {annotationContextData.isAdmin && <Badge type='neutral'>ADMIN</Badge>}
        </div>

        <AnnotationInput onSend={onSend} placeholder='Add a comment..' />
        {
          // TODO add this when backend is ready
          /*annotationContextData.isAdmin && (
          <Typography variant='text2' color='secondary'>
            Comment will only be visible for admins
          </Typography>
        )*/
        }
      </div>
    </div>
  );
};
