import type { MutableRefObject } from 'react';
import React, { useRef, useState, useCallback } from 'react';
import { useCustomEventDispatcher, useCustomEventListener } from '../../utils/useCustomEvent';
import { CREATE_ANNOTATION_CARD_SELECTOR } from './CreateAnnotation/CreateAnnotationCard';

export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<SVGSVGElement>() as MutableRefObject<SVGSVGElement>;

  React.useEffect(() => {
    const handleClick = (event: Event) => {
      const elementToIgnoreClicksWithin = document.querySelector(`.${CREATE_ANNOTATION_CARD_SELECTOR}`);
      const clickedElement = event?.target as HTMLElement;

      if (elementToIgnoreClicksWithin && elementToIgnoreClicksWithin.contains(clickedElement)) {
        return;
      }

      if (ref.current && clickedElement.tagName !== 'rect') {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [callback, ref]);

  return ref;
};

export const useAnnotationEventDispatchers = () => {
  const dispatchAnnotationHoveredEvent = useCustomEventDispatcher('annotationHovered');

  const dispatchHoveredAnnotationId = useCallback(
    (annotationId: string | null) => dispatchAnnotationHoveredEvent({ annotationId }),
    [dispatchAnnotationHoveredEvent],
  );

  return { dispatchHoveredAnnotationId };
};

export const useAnnotationEventListeners = () => {
  const [hoveredAnnotationId, setHoveredAnnotationId] = useState<string | null>(null);

  useCustomEventListener('annotationHovered', ({ detail: { annotationId } }) => {
    setHoveredAnnotationId(annotationId);
  });

  return { hoveredAnnotationId };
};
