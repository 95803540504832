import type { TSciChart, AxisBase2D } from 'scichart';
import { DateTimeNumericAxis, ELabelAlignment, EMultiLineAlignment, Thickness, NumberRange } from 'scichart';
import type { ChartSlider } from '../../hooks/axes.hooks';
import { DynamicDateLabelProvider } from '../../providers/DynamicDateLabelProvider';
import { getUnixTimestampInSeconds } from '../data-utils';
import type { ChartViewMode } from '../../types';
import type { DateString, TimeGranularity } from 'aim-utils';

export type XAxisConfig = {
  type: 'date-time';
} & {
  timeGranularity: TimeGranularity;
  slider?: ChartSlider;
  fullRange: { min?: DateString; max?: DateString };
};

export const createXAxis = ({
  wasmContext,
  config,
  mode,
}: {
  wasmContext: TSciChart;
  config: XAxisConfig;
  mode: ChartViewMode;
}) => {
  switch (config.type) {
    case 'date-time': {
      const axis = new DateTimeNumericAxis(wasmContext, {
        hideOverlappingLabels: false,
        labelProvider: new DynamicDateLabelProvider({
          timeGranularity: config.timeGranularity,
        }),
      });

      return updateXAxis({ axis, config, mode });
    }
    default: {
      throw new Error(`Unsupported X-axis type: ${config.type}`);
    }
  }
};

const updateXAxisVisibleRangeLimit = (axis: AxisBase2D, updatedAxis: XAxisConfig) => {
  const { min, max } = updatedAxis.fullRange ?? {};
  if (min === undefined && max === undefined) return;

  const rangeLimitMin = min ? getUnixTimestampInSeconds(new Date(min)) : undefined;
  const rangeLimitMax = max ? getUnixTimestampInSeconds(new Date(max)) : undefined;

  axis.visibleRangeLimit = new NumberRange(rangeLimitMin, rangeLimitMax);
};

export const updateXAxis = ({ axis, config, mode }: { axis: AxisBase2D; config: XAxisConfig; mode: ChartViewMode }) => {
  axis.drawMajorGridLines = false;
  axis.drawMinorGridLines = false;
  axis.drawMajorTickLines = false;
  axis.drawMinorTickLines = false;
  axis.drawMajorBands = false;
  axis.autoTicks = false;

  axis.labelStyle = {
    alignment: ELabelAlignment.Center,
    multilineAlignment: EMultiLineAlignment.Center,
    padding: Thickness.fromString('16 0 8 0'),
    fontSize: mode === 'desktop' ? 13 : 10,
    fontFamily: 'ArketypSans, sans-serif',
  };

  axis.autoRangeAnimation = {
    duration: 500,
    animateInitialRanging: true,
    animateSubsequentRanging: true,
  };

  if (axis.labelProvider instanceof DynamicDateLabelProvider) {
    axis.labelProvider.setTimeGranularity(config.timeGranularity);
  }

  updateXAxisVisibleRangeLimit(axis, config);

  return axis;
};
