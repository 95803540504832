import type { ComponentProps } from 'react';
import React, { Fragment } from 'react';
import { Typography } from '../Typography';
import './Breadcrumbs.css';
import cn from 'classnames';
import { Icon } from '../Icon';

export type BreadcrumbsProps = ComponentProps<'div'> & {
  breadcrumbs: string[];
  onNavigation: (value: number) => void;
};

export const Breadcrumbs = ({ breadcrumbs, onNavigation, className, ...props }: BreadcrumbsProps) => {
  const activeIndex = breadcrumbs.length - 1;

  return (
    <div className={cn('Breadcrumbs', className)} {...props}>
      {breadcrumbs.map((item, index) => {
        const activeCrumb = index === activeIndex;
        return (
          <Fragment key={item + index}>
            <div className='Breadcrumb-wrapper'>
              <span
                data-testid='Breadcrumb'
                className={cn('Breadcrumb', activeCrumb && 'Active')}
                onClick={activeCrumb ? undefined : () => onNavigation(index)}
              >
                <Typography variant='text2'>{item}</Typography>
              </span>
            </div>
            {!activeCrumb && <Icon icon='nav-forward-chevron' size='small' />}
          </Fragment>
        );
      })}
    </div>
  );
};
