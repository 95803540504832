import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGoogleColor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
    <rect width={32} height={32} fill='#F3F3F3' rx={4} />
    <path
      fill='#4285F4'
      d='M27.745 16.27c0-.79-.07-1.54-.19-2.27h-11.3v4.51h6.47c-.29 1.48-1.14 2.73-2.4 3.58v3h3.86c2.26-2.09 3.56-5.17 3.56-8.82'
    />
    <path
      fill='#34A853'
      d='M16.255 28c3.24 0 5.95-1.08 7.93-2.91l-3.86-3c-1.08.72-2.45 1.16-4.07 1.16-3.13 0-5.78-2.11-6.73-4.96h-3.98v3.09c1.97 3.92 6.02 6.62 10.71 6.62'
    />
    <path
      fill='#FBBC05'
      d='M9.525 18.29c-.25-.72-.38-1.49-.38-2.29s.14-1.57.38-2.29v-3.09h-3.98a11.86 11.86 0 0 0 0 10.76z'
    />
    <path
      fill='#EA4335'
      d='M16.255 8.75c1.77 0 3.35.61 4.6 1.8l3.42-3.42C22.205 5.19 19.495 4 16.255 4c-4.69 0-8.74 2.7-10.71 6.62l3.98 3.09c.95-2.85 3.6-4.96 6.73-4.96'
    />
  </svg>
);
export default SvgGoogleColor;
