import { type ReactElement, type ReactNode, useEffect, useMemo } from 'react';
import { FullStory, init, isInitialized } from '@fullstory/browser';
import { toast, ToasterContainer } from 'aim-components';
import type { AxiosError } from 'axios';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../next-i18next.config';
import '../public/fonts/style.css';
import '../styles/global.css';
import '../styles/colors.css';
import '../styles/vars.css';
import TagManager from 'react-gtm-module';
import { mutate, SWRConfig } from 'swr';
import Intercom from '../components/common/external/Intercom';
import AuthenticatedContent from '@/components/authentication/AuthenticatedContent';
import ErrorBoundary from '@/components/common/error/ErrorBoundary';
import CommonHead from '@/components/common/head/CommonHead';
import { initializeLogger } from '@/lib/logger';
import { applyColors, logger } from 'aim-utils';
import type { UserSession } from '@/lib/utils';
import { Routes } from '@/lib/routes';

export type NextPageWithLayout<Props = object> = NextPage<Props> & {
  getLayout?: (page: ReactElement) => ReactNode;
  includeNavigationMobile?: boolean;
  includeNavigationDesktop?: boolean;
  includeFooter?: boolean;
  requiresAuth?: boolean;
  requiresAdmin?: boolean;
};

type AppPropsWithLayout<T> = AppProps<T> & {
  Component: NextPageWithLayout;
  intercomHash?: string;
};

type ExpectedErrorType = {
  error: string;
  error_description: string;
};

if (process.env.NEXT_PUBLIC_LOGGER_ENABLED === 'true') {
  initializeLogger();
}

const isFullStoryTrackingEnabled = (userSession: UserSession) => {
  if (!userSession.loggedIn) return true;

  // * Handles the case when we're in the sign up flow, when there's no tenant but we want FullStory enabled.
  if (!userSession.tenant) return true;

  return userSession.ownership === 'PRIVATE';
};

const checkLocalStorageHasCurrentVersion = () => {
  const currentVersion = '1.0.0';
  const localStorageVersion = localStorage.getItem('storage_version');
  if (localStorageVersion !== currentVersion) {
    localStorage.clear();
    localStorage.setItem('storage_version', currentVersion);
  }
};

const App = ({ Component, pageProps }: AppPropsWithLayout<{ userSession: UserSession; intercomHash: string }>) => {
  const router = useRouter();
  const userSession = useMemo(() => pageProps.userSession ?? { loggedIn: false }, [pageProps.userSession]);

  // Adds all color css variables and checks local storage
  useEffect(() => {
    applyColors();
    checkLocalStorageHasCurrentVersion();
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator && userSession.loggedIn) {
      const channel = new BroadcastChannel('sw-messages');
      channel.onmessage = (event) => {
        const currentTenant = userSession.tenant;
        if (event.data.type === 'update' && event.data.tenant === currentTenant) {
          const url = new URL(event.data.url);
          mutate(`${url.pathname}${url.search}`, event.data.content, { revalidate: false });
        }
        // Logging the event data for debugging purposes
        if (event.data.tenant !== currentTenant) {
          logger.log({
            message: 'caching mismatch in service worker data update',
            context: {
              tenant: event.data.tenant,
              currentTenant,
              userEmail: userSession.email,
              url: event.data.url,
            },
          });
        }
      };

      navigator.serviceWorker.register('/service-worker.js');
      navigator.serviceWorker.ready.then((registration) => {
        registration?.active?.postMessage({ type: 'set-user', value: userSession?.arkUserId ?? '' });
      });

      return () => {
        channel.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession.loggedIn, userSession.tenant, userSession?.arkUserId]);

  useEffect(() => {
    const fullStoryTrackingEnabled = isFullStoryTrackingEnabled(userSession);

    if (fullStoryTrackingEnabled) {
      if (!isInitialized()) {
        init({ orgId: 'o-1487-eu1' });
        if (userSession.loggedIn) {
          FullStory('setIdentity', {
            consent: true,
            uid: userSession.email,
            properties: {
              arkUserId: userSession.arkUserId,
              email: userSession.email,
              name: userSession.displayName,
              role: userSession.role,
            },
          });
        }
      }
    }
  }, [userSession]);

  useEffect(() => {
    const lightThemRoutes = [
      Routes.Login,
      Routes.SignUp,
      Routes.RecoverPassword,
      Routes.VerifyEmail,
      Routes.Registration,
      Routes.InvalidEmail,
      Routes.SetPassword,
    ];
    document.documentElement.setAttribute(
      'data-theme',
      lightThemRoutes.some((route) => router.pathname.includes(route)) ? 'light' : 'dark',
    );
  }, [router.pathname]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-M9F4KZX',
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);
  const Content = () => {
    return (
      <SWRConfig
        value={{
          onError: toastOnError,
        }}
      >
        <CommonHead />
        <Intercom hash={pageProps.intercomHash ?? ''} userSession={pageProps.userSession} />
        <Component {...pageProps} />
      </SWRConfig>
    );
  };

  if (Component.requiresAuth) {
    return (
      <>
        <ToasterContainer topOffset='80px' />
        <ErrorBoundary>
          <AuthenticatedContent
            userSession={userSession}
            includeNavigationDesktop={Component.includeNavigationDesktop}
            includeNavigationMobile={Component.includeNavigationMobile}
            includeFooter={Component.includeFooter}
          >
            {getLayout(<Content />)}
          </AuthenticatedContent>
        </ErrorBoundary>
      </>
    );
  }

  if (router.isReady) {
    return (
      <>
        <ToasterContainer topOffset='80px' />
        <ErrorBoundary>{getLayout(<Content />)}</ErrorBoundary>
      </>
    );
  }

  return <CommonHead />;
};

function toastOnError(error: AxiosError<ExpectedErrorType>): void {
  const excludedStatuses = [401, 403, 404, 500];
  if (error.response?.status && !excludedStatuses.includes(error.response.status)) {
    toast({ message: `${error.message}`, type: 'critical' });
  }
}

const app: unknown = appWithTranslation(App, nextI18NextConfig);
export default app;
