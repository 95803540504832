import './Stepper.css';

export interface StepperProps {
  currentStep: number;
  numberOfSteps: number;
}

export const Stepper = ({ currentStep, numberOfSteps }: StepperProps) => {
  return (
    <ol className='stepper'>
      {[...Array(numberOfSteps)].map((_, index) => (
        <li key={index} data-active={index === currentStep} />
      ))}
    </ol>
  );
};
