import type { ReactNode } from 'react';
import React, { useEffect, useMemo } from 'react';
import { animated, easings, useSpring } from '@react-spring/web';
import './Score.css';
import { useElementDimensions } from '../../utils/useElementDimensions';
import { clamp } from '../../utils/utils';
import { DeltaIndicator } from './DeltaIndicator/DeltaIndicator';
import { ScoreCountUp } from './ScoreCountUp';
import { getRadialGradientImageByScore } from './ScoreCountUp/utils';
import { ScoreGrade } from './ScoreGrade';
import { ScorePath } from './ScorePath';
import { ScoreSlicer } from './ScoreSlicer';
import { SparklingArk } from './SparklingArc';
import type { ArcSize } from './utils';
import { isSafari, SCORE_CONFIG } from './utils';
import cn from 'classnames';
import { ScoreBlur } from './ScoreBlur';
import { useMobile } from 'aim-utils';
import { DeltaLabelMobile } from './DeltaLabel.mobile';

const { viewBoxWidth, viewBoxHeight, outerArcRadiuses, innerArcRadiuses } = SCORE_CONFIG;

/** The delta threshold for when delta indicators are shown. Used to avoid overlapping indicators for small deltas. */
export const SCORE_DELTA_INDICATOR_THRESHOLD = 0.15;

interface ScoreProps {
  saveToDeck?: ReactNode;
  score: number | null;
  delta?: number | null;
  rainbow?: boolean;
  deltaLabelCurrent?: string;
  deltaLabelPrev?: string;
  arcSize?: ArcSize;
  displayScoreDelta?: boolean;
}

export const Score = ({
  saveToDeck,
  score: inputScore,
  delta = 0,
  rainbow = false,
  deltaLabelPrev,
  deltaLabelCurrent,
  arcSize = 'small',
}: ScoreProps) => {
  const score = inputScore ?? 0;
  const animationArcDuration = 300 * score;
  const animationStartDelay = 300;

  const outerArcRadius = outerArcRadiuses[arcSize];
  const innerArcRadius = innerArcRadiuses[arcSize];

  const animationConfig = useMemo(
    () => ({
      config: {
        duration: animationArcDuration,
        easing: easings.easeOutSine,
      },
      delay: animationStartDelay,
    }),
    [animationArcDuration],
  );

  const [spring, api] = useSpring(() => ({
    from: { angleTo: -Math.PI / 2, score: 0, transform: `rotate(-${5 * 180} 0 0)` },
  }));

  useEffect(() => {
    api.start({
      to: { angleTo: -Math.PI / 2 + (score / 5) * Math.PI, score, transform: `rotate(-${(5 - score / 5) * 180} 0 0)` },
      ...animationConfig,
    });
  }, [animationArcDuration, animationConfig, api, score]);

  const [resizeElementRef, responsiveWidth] = useElementDimensions();

  const foreignObjectTopPadding = isSafari() ? (viewBoxWidth - responsiveWidth) / 2 : 0;
  const ratioAdoption = isSafari() ? responsiveWidth / viewBoxWidth : 1;

  // Used to make the sizing of the score count up responsive (@container queries would probably be a nice use case here!)
  const scoreCountUpScalingFactor = clamp({ value: responsiveWidth / 600, min: 0, max: 1 });

  const clipPath = `url(#score-arc-solid-${score})`;

  const AnimatedScoreSlicer = animated(ScoreSlicer);
  const { mobileView } = useMobile();

  return (
    <div style={{ width: '100%' }}>
      {saveToDeck && saveToDeck}

      {mobileView && delta !== null && <DeltaLabelMobile score={score} deltaLabel={deltaLabelPrev} delta={delta} />}
      <div
        className={cn('aim-score', { smallArc: arcSize === 'small', mobileScore: mobileView })}
        style={{
          ['--conic-gradient-end-percentage' as string]: `${score * 10}%`,
        }}
        ref={resizeElementRef}
      >
        {rainbow && <ScoreBlur angleTo={spring.angleTo} score={score} arcSize={arcSize} />}
        {/* Non-blurred arc (to make it solid) */}
        <div>
          <svg className='aim-score_overlay' viewBox={`0 ${0} ${viewBoxWidth} ${viewBoxHeight}`}>
            {/* We have to repeat these ScorePaths (clip paths) for Safari */}

            <ScorePath
              endAngle={spring.angleTo}
              score={score}
              outerRadius={outerArcRadius}
              innerRadius={innerArcRadius - 3}
            />

            {rainbow ? (
              <>
                <SparklingArk
                  width={viewBoxWidth * ratioAdoption}
                  height={viewBoxHeight * ratioAdoption}
                  innerArcRadius={innerArcRadius * ratioAdoption + 3}
                  outerArcRadius={outerArcRadius * ratioAdoption}
                  score={spring.score}
                />
              </>
            ) : (
              <foreignObject height='100%' width='100%' clipPath={clipPath}>
                <div
                  className={`aim-score-color-gradient aim-score-color-gradient-${getRadialGradientImageByScore(
                    score,
                  )}`}
                  style={{ paddingTop: `${foreignObjectTopPadding}px`, height: viewBoxHeight }}
                />
              </foreignObject>
            )}

            <ScoreGrade
              svgHeight={viewBoxHeight}
              svgWidth={viewBoxWidth}
              outerRadius={innerArcRadius}
              innerRadius={innerArcRadius - 6}
              displayTicks={rainbow}
            />

            {rainbow && score > 0 && (
              <AnimatedScoreSlicer
                arcRadius={outerArcRadius}
                svgHeight={viewBoxHeight}
                svgWidth={viewBoxWidth}
                rotation={spring.transform}
                animatedScore={spring.score}
              />
            )}
            {delta && Math.abs(delta) >= SCORE_DELTA_INDICATOR_THRESHOLD && (
              <DeltaIndicator
                score={score}
                delta={delta}
                deltaLabelPrev={deltaLabelPrev}
                deltaLabelCurrent={deltaLabelCurrent}
                innerArcRadius={innerArcRadius}
                outerArcRadius={outerArcRadius}
                svgHeight={viewBoxHeight}
                svgWidth={viewBoxWidth}
                delay={animationArcDuration + animationStartDelay}
                displayTicks={rainbow}
              />
            )}
          </svg>
        </div>
        <ScoreCountUp
          score={score}
          delta={delta ?? 0}
          scale={scoreCountUpScalingFactor}
          durationMs={animationArcDuration}
          delay={animationStartDelay}
          displayScoreDelta={!mobileView}
        />
      </div>
    </div>
  );
};
