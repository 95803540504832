import React from 'react';
interface LimitLineProps {
  width: number;
  limit: number;
  yScale: d3.ScaleLinear<number, number, never>;
}

const LimitLine = ({ width, limit, yScale }: LimitLineProps) => {
  return (
    <g transform={`translate(0, ${yScale(limit)})`}>
      <path d={`M 0 0 H ${width}`} fill='none' stroke='currentColor' strokeWidth='1' opacity='0.5' />
    </g>
  );
};
export default LimitLine;
