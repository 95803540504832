import type { ChartData } from 'chart.js';

export const placeholderData: ChartData<'line', unknown> = {
  datasets: [
    {
      data: [
        { x: 'label', y: 100 },
        { x: 'label2', y: 200 },
        { x: 'label3', y: 50 },
        { x: 'label4', y: 300 },
        { x: 'label5', y: 150 },
        { x: 'label6', y: 250 },
        { x: 'label7', y: 400 },
        { x: 'label8', y: 450 },
        { x: 'label9', y: 300 },
        { x: 'label10', y: 200 },
        { x: 'label11', y: 100 },
        { x: 'label12', y: 350 },
        { x: 'label13', y: 500 },
        { x: 'label14', y: 500 },
      ],
      cubicInterpolationMode: 'monotone',
      datalabels: { display: false },
    },
  ],
};

export const options = {
  scales: { x: { grid: { display: false } }, y: { grid: { display: false }, ticks: { padding: 24 } } },
};
