import { ContextMenu, NavigationMenu } from 'aim-components';
import { useCallback, useMemo } from 'react';
import { useLogout } from '../hooks';
import { useRouter } from 'next/router';
import { Routes } from '@/lib/routes';
import type { NavigationMenuNode } from '@/components/layouts/navigation/navigation-menu/types';
import { isActiveRoute } from '@/components/layouts/navigation/navigation-menu/utils';
import { BetaBadge } from '@/components/common/BetaBadge/BetaBadge';
import Link from 'next/link';

export const useProfileMenuItems = (closeProfileMenu: () => void) => {
  const { performLogout } = useLogout();
  const router = useRouter();
  const { slug } = router.query;
  const profileMenuItems = useMemo(() => {
    return (
      <>
        <ContextMenu.ListItem
          label='Settings'
          icon='settings'
          onClick={() => {
            closeProfileMenu();
            router.push(`/${slug}${Routes.Settings}`);
          }}
        />

        <ContextMenu.ListItem
          label='Log out'
          icon='log-out'
          onClick={() => {
            closeProfileMenu();
            performLogout();
          }}
        />
      </>
    );
  }, [closeProfileMenu, performLogout, router, slug]);

  return { profileMenuItems };
};

export const useRenderNavigationMenuContent = ({
  activePath,
  enableTooltip,
}: {
  activePath: string;
  enableTooltip: boolean;
}) => {
  const router = useRouter();

  const renderNavigationMenuPart = useCallback(
    (item: NavigationMenuNode, index: number) => {
      switch (item.type) {
        case 'nav-item': {
          const isActive = isActiveRoute({ route: item.route, currentPathname: activePath });

          const navigationItem = (
            <NavigationMenu.Item
              key={index}
              icon={item.icon}
              label={item.label}
              active={isActive}
              disabled={item.disabled}
              tooltip={enableTooltip ? item.label : undefined}
            >
              {item.beta && <BetaBadge type='neutral' />}
              {'menu' in item && item.menu && <NavigationMenu.Item.MenuButton displayMode='on-hover' />}
            </NavigationMenu.Item>
          );

          if (item.disabled) {
            // * We don't need (or want) to wrap a disabled item in an anchor tag, hence we return it directly.
            return navigationItem;
          }

          return <Link href={'/' + router.query.slug + item.route}>{navigationItem}</Link>;
        }
        case 'action-item': {
          return (
            <NavigationMenu.Item
              key={index}
              icon={item.icon}
              label={item.label}
              tooltip={enableTooltip ? item.label : undefined}
              onClick={item.onClick}
              disabled={item.disabled}
            >
              {item.beta && <BetaBadge type='neutral' />}
            </NavigationMenu.Item>
          );
        }
        case 'section': {
          return (
            <NavigationMenu.Section key={index} title={item.label}>
              <NavigationMenu.List>
                {item.items.map((childItem, childIndex) => renderNavigationMenuPart(childItem, childIndex))}
              </NavigationMenu.List>
            </NavigationMenu.Section>
          );
        }
      }
    },
    [activePath, enableTooltip, router.query.slug],
  );

  return { renderNavigationMenuPart };
};
