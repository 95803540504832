import type { ReactElement } from 'react';
import React from 'react';
import cn from 'classnames';

import './Label.css';
import { Typography } from '../Typography';
import type { TypographyColor } from '../Typography/Typography';

interface LabelProps {
  label?: string;
  disabled?: boolean;
  htmlFor?: string;
  color?: TypographyColor;
}

export const Label = ({ label, disabled, htmlFor, color }: LabelProps): ReactElement | null => {
  if (label) {
    return (
      <Typography variant='label3' color={color}>
        <label htmlFor={htmlFor} className={cn('Label', disabled && 'disabled')}>
          {label}
        </label>
      </Typography>
    );
  }
  return null;
};
