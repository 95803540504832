import type { SeriesInfo, IRenderableSeries, IDataSeries } from 'scichart';
import { FastColumnRenderableSeries } from 'scichart';
import { BaseDataSeries, XyyDataSeries } from 'scichart';
import { StackedXySeriesInfo, StackedMountainCollection, XyDataSeries } from 'scichart';
import { AxisBase2D, SmoothStackedMountainRenderableSeries } from 'scichart';

export const isStackedXySeriesInfo = (seriesInfo: SeriesInfo): seriesInfo is StackedXySeriesInfo => {
  return seriesInfo instanceof StackedXySeriesInfo;
};

export const isStackedMountainCollection = (series: IRenderableSeries): series is StackedMountainCollection => {
  return series instanceof StackedMountainCollection;
};
export const isStackedMountainRenderableSeries = (
  series: IRenderableSeries,
): series is SmoothStackedMountainRenderableSeries => {
  return series instanceof SmoothStackedMountainRenderableSeries;
};

export const isBarRenderableSeries = (series: IRenderableSeries): series is FastColumnRenderableSeries => {
  return series instanceof FastColumnRenderableSeries;
};

export const isBaseDataSeries = (dataSeries: IDataSeries): dataSeries is BaseDataSeries => {
  return dataSeries instanceof BaseDataSeries;
};

export const isXyDataSeries = (dataSeries: IDataSeries): dataSeries is XyDataSeries => {
  return dataSeries instanceof XyDataSeries;
};

export const isXyyDataSeries = (dataSeries: IDataSeries): dataSeries is XyDataSeries => {
  return dataSeries instanceof XyyDataSeries;
};

export const isAxisBase2D = (axis: unknown): axis is AxisBase2D => {
  return axis instanceof AxisBase2D;
};
