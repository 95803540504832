import { useCallback, useMemo } from 'react';
import { BottomSheet } from '../../../BottomSheet';
import { Typography } from '../../../Typography';
import { Checkbox } from '../../Checkbox';
import type { Option } from '../Select';
import styles from './MultiSelectBottomSheet.module.css';

interface MultiSelectBottomSheetProps<TValue extends string = string> {
  open: boolean;
  onClose: () => void;
  title?: string;
  options: Option<TValue>[];
  selectedOptions: Option<TValue>[];
  setSelectedOptions: (options: Option<TValue>[]) => void;
  maximumNumberOfSelected?: number;
}

// TODO: We might want to add a "Deselect all" option, like in MultiSelect.tsx?
export const MultiSelectBottomSheet = <TValue extends string = string>({
  open,
  onClose,
  title,
  options,
  selectedOptions,
  setSelectedOptions,
  maximumNumberOfSelected = Number.POSITIVE_INFINITY,
}: MultiSelectBottomSheetProps<TValue>) => {
  const selectedOptionsValues = useMemo(() => selectedOptions.map((option) => option.value), [selectedOptions]);

  const handleToggleOption = useCallback(
    (option: Option<TValue>) => {
      if (selectedOptionsValues.includes(option.value)) {
        const newSelection = selectedOptions.filter((selectedOption) => selectedOption.value !== option.value);
        setSelectedOptions(newSelection);
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    },
    [selectedOptions, selectedOptionsValues, setSelectedOptions],
  );

  const hasReachedMaximumNumberOfSelected =
    selectedOptionsValues.length >= maximumNumberOfSelected && selectedOptionsValues.length < options.length;
  const dynamicTitle = hasReachedMaximumNumberOfSelected ? `${title} (Max ${maximumNumberOfSelected})` : title;

  return (
    <BottomSheet open={open} onClose={onClose}>
      <BottomSheet.Content>
        {dynamicTitle && (
          <Typography variant='title2' className={styles.title}>
            {dynamicTitle}
          </Typography>
        )}
        <ul className={styles.checkboxList}>
          {options.map((option) => {
            const optionSelected = selectedOptionsValues.includes(option.value);

            return (
              <li key={option.value} className={styles.checkboxRow}>
                <Checkbox
                  disabled={hasReachedMaximumNumberOfSelected && !optionSelected}
                  initialValue={optionSelected}
                  onChange={() => handleToggleOption(option)}
                >
                  {option.label}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </BottomSheet.Content>
    </BottomSheet>
  );
};
