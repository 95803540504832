import { createTimeGranularityTuple } from '@/components/insights/charts/common/types';

export const CUSTOMER_GROWTH_RATE_TIME_GRANULARITIES = createTimeGranularityTuple('quarters', 'months', 'days');
export const CUSTOMER_GROWTH_RATE_WINDOW_SIZES = ['1d', '7d', '30d', '60d', '90d', '365d'] as const;

export type CustomerGrowthRateTimeGranularity = (typeof CUSTOMER_GROWTH_RATE_TIME_GRANULARITIES)[number];
export type WindowSize = (typeof CUSTOMER_GROWTH_RATE_WINDOW_SIZES)[number];

type YearOverYearRowData = {
  active_customer_growth_rate_year_over_year: bigint | null;
  new_customers_growth_rate_year_over_year: bigint | null;
  new_customers_growth_rate_year_over_year_7d: bigint | null;
  new_customers_growth_rate_year_over_year_30d: bigint | null;
  new_customers_growth_rate_year_over_year_60d: bigint | null;
  new_customers_growth_rate_year_over_year_90d: bigint | null;
  new_customers_growth_rate_year_over_year_365d: bigint | null;
};

type MonthOverMonthRowData = {
  active_customer_growth_rate_month_over_month: bigint | null;
  new_customers_growth_rate_month_over_month: bigint | null;
  new_customers_growth_rate_month_over_month_7d: bigint | null;
  new_customers_growth_rate_month_over_month_30d: bigint | null;
  new_customers_growth_rate_month_over_month_60d: bigint | null;
  new_customers_growth_rate_month_over_month_90d: bigint | null;
  new_customers_growth_rate_month_over_month_365d: bigint | null;
};

type QuarterOverQuarterRowData = {
  active_customer_growth_rate_quarter_over_quarter: bigint | null;
  new_customers_growth_rate_quarter_over_quarter: bigint | null;
};

type MetricRowData = {
  active_customers: bigint | null;
  new_customers: bigint | null;
  new_customers_7d: bigint | null;
  new_customers_30d: bigint | null;
  new_customers_60d: bigint | null;
  new_customers_90d: bigint | null;
  new_customers_365d: bigint | null;
};

export type CustomerGrowthRateArrowRow = YearOverYearRowData &
  (
    | ({ date_day: number } & MonthOverMonthRowData & MetricRowData)
    | ({ date_month: number } & MonthOverMonthRowData & MetricRowData)
    | ({ date_quarter: number } & QuarterOverQuarterRowData & MetricRowData)
  );

export const getMetricYoY = (
  windowSize: WindowSize,
  timeGranularity: CustomerGrowthRateTimeGranularity,
  metric: 'new_customers',
): keyof YearOverYearRowData => {
  if (windowSize === '1d' || timeGranularity !== 'days') {
    return `${metric}_growth_rate_year_over_year`;
  }
  return `${metric}_growth_rate_year_over_year_${windowSize}`;
};

export const getMetricMoM = (
  windowSize: WindowSize,
  timeGranularity: CustomerGrowthRateTimeGranularity,
  metric: 'new_customers',
): keyof MonthOverMonthRowData => {
  if (windowSize === '1d' || timeGranularity !== 'days') {
    return `${metric}_growth_rate_month_over_month`;
  }
  return `${metric}_growth_rate_month_over_month_${windowSize}`;
};

export const getMetricBase = (
  windowSize: WindowSize,
  timeGranularity: CustomerGrowthRateTimeGranularity,
  metric: 'new_customers',
): keyof MetricRowData => {
  if (windowSize === '1d' || timeGranularity !== 'days') {
    return metric;
  }

  return `${metric}_${windowSize}`;
};
