import { useState, useEffect } from 'react';
import type {
  TooltipModel,
  Chart as ChartJS,
  ChartTypeRegistry,
  Point,
  BubbleDataPoint,
  ScriptableTooltipContext,
} from 'chart.js';
import { getHoveredDataset, getHoveredStack } from './utils';
import { useThrottledState } from 'aim-utils';
import type { ChartDataset, ChartMobileProps, DateString } from '../types';
import { dateToString, toLocalDate } from '../../../utils';
import type { Option } from '../../Input/Select/Select';
import { useSelectedDatasets } from '../hooks';

interface TooltipPosition {
  left?: number;
  top?: number;
  offset?: number;
}

const TOOLTIP_FADE_TIME_IN_MS = 150;

export function useChartTooltip(
  datasets: ChartDataset[],
  chartRef: React.RefObject<
    ChartJS<keyof ChartTypeRegistry, (number | [number, number] | Point | BubbleDataPoint | null)[], unknown> & {
      formatTooltipTitle?: ChartMobileProps['formatTooltipTitle'];
    }
  >,
  selectedOptions: Option[] = [],
  removeData?: boolean,
  isCohortBuildupChart?: boolean,
  isExpanded?: boolean,
  handleTooltipChange?: ChartMobileProps['handleTooltipChange'],
) {
  const [visible, setVisible] = useState(false);
  const [data, setTooltipData] = useState<TooltipModel<'bar' | 'line' | 'scatter'>>();
  const [position, setPosition] = useState<TooltipPosition>({ top: undefined, left: undefined, offset: undefined });
  const [hoveredDatasetLabel, setHoveredDatasetLabel] = useThrottledState<string | undefined>(undefined, 150);

  const { selectedDataset, selectedDatasetLabels } = useSelectedDatasets(datasets, selectedOptions, removeData);

  useEffect(() => {
    let tooltipTimeout: NodeJS.Timeout;
    const abortController = new AbortController();

    if (chartRef.current?.canvas) {
      chartRef.current.canvas.addEventListener(
        'mouseout',
        () => {
          clearTimeout(tooltipTimeout);
          setVisible(false);
          setHoveredDatasetLabel(undefined);
        },
        { signal: abortController.signal },
      );

      if (chartRef.current.config.options) {
        chartRef.current.config.options.onHover = (event) => {
          clearTimeout(tooltipTimeout);

          if ('chart' in event) {
            const chart = { ...(event.chart as ScriptableTooltipContext<'bar' | 'line' | 'scatter'>) };

            if (chart.tooltip.title) {
              const dataPoints = chart.tooltip.dataPoints
                .filter((dataPoint) => (dataPoint.raw as { y: number | null }).y !== null)
                .filter(
                  (dataPoint) =>
                    dataPoint.dataset.borderColor !== '#303030' &&
                    (('hoverable' in dataPoint.dataset && dataPoint.dataset.hoverable !== false) ||
                      !('hoverable' in dataPoint.dataset)),
                );
              setVisible(false);

              if (isCohortBuildupChart) {
                tooltipTimeout = setTimeout(() => {
                  setVisible(true);
                }, TOOLTIP_FADE_TIME_IN_MS);

                const hoveredStack = getHoveredStack(chart, selectedDataset);
                setHoveredDatasetLabel(hoveredStack);
                setPosition(
                  hoveredStack !== undefined
                    ? { top: chart.tooltip.y, left: chart.tooltip.x || 0 }
                    : { top: undefined, left: undefined },
                );

                if (handleTooltipChange) {
                  handleTooltipChange({
                    dataPoints: dataPoints,
                    hoveredStack: selectedDataset.findIndex((dataset) => dataset.label === hoveredStack),
                  });
                }
                setTooltipData(chart.tooltip);
              } else {
                const potentialDataPoints = selectedDataset
                  .filter(
                    ({ label, filterable, hoverable }) =>
                      (selectedDatasetLabels.includes(label) || filterable === false) && hoverable !== false,
                  )
                  .filter((dataset) =>
                    dataset.data
                      .map((data) => dateToString(toLocalDate(new Date(data.x))))
                      .includes(chart.tooltip.title[0] as DateString),
                  )
                  .map((dataset) => ({
                    ...dataset.data.find(
                      (point) =>
                        dataPoints[0]?.raw &&
                        typeof dataPoints[0].raw === 'object' &&
                        'x' in dataPoints[0].raw &&
                        point.x === dataPoints[0]?.raw.x,
                    ),
                    yAxisId: dataset.yAxisId,
                  }))
                  .filter((point) => {
                    if (point.y === null || point.y === undefined) return false;

                    if (point.yAxisId === 'y1') {
                      // Excludes points that are above the Y1 axis max value
                      const y1Max = chartRef.current?.scales?.y1?.max;
                      if (y1Max === undefined) return true;
                      return point.y <= y1Max;
                    }

                    // Excludes points that are above the Y axis max value
                    const yMax = chartRef.current?.scales?.y?.max;
                    if (yMax === undefined) return true;
                    return point.y <= yMax;
                  });

                // sometimes the chart js tooltip does not send all dataPoints, this check is needed to always display a correct list of dataPoints in the tooltip
                if (
                  potentialDataPoints.length === dataPoints.length ||
                  chart.tooltip.title.length === 0 ||
                  selectedDataset.every((dataset) => dataset.type === 'bar') ||
                  chartRef.current?.customData?.hoveredDataLabel // * This handles the case when hovering a "data label", where we only show data for the single corresponding dataset.
                ) {
                  tooltipTimeout = setTimeout(() => {
                    setVisible(true);
                  }, TOOLTIP_FADE_TIME_IN_MS);

                  const hoveredDataSet = getHoveredDataset(chart, dataPoints);
                  setHoveredDatasetLabel(hoveredDataSet);
                  setPosition(
                    hoveredDataSet !== undefined
                      ? { top: chart.tooltip.y, left: chart.tooltip.x || 0 }
                      : { top: undefined, left: undefined },
                  );

                  if (handleTooltipChange) {
                    handleTooltipChange({
                      dataPoints: dataPoints,
                      hoveredStack: -1,
                    });
                  }
                  setTooltipData(chart.tooltip);
                }
              }
            }
          }
        };
      }
    }
    return () => {
      clearTimeout(tooltipTimeout);
      abortController.abort();
    };
  }, [
    chartRef,
    handleTooltipChange,
    isCohortBuildupChart,
    setHoveredDatasetLabel,
    selectedDataset,
    selectedDatasetLabels.length,
    selectedOptions,
    selectedDatasetLabels,
  ]);

  useEffect(() => {
    setTimeout(() => setPosition({ top: undefined, left: undefined }), TOOLTIP_FADE_TIME_IN_MS);
  }, [isExpanded]);

  return {
    tooltipVisible: visible,
    tooltipData: data,
    tooltipPosition: position,
    hoveredDatasetLabel,
  } as const;
}
