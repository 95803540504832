import type { PointerEvent, ReactNode, ComponentRef } from 'react';
import React, { useCallback, useRef } from 'react';

import './BottomSheet.css';
import { BottomSheetOverlay } from './BottomSheetOverlay';
import cn from 'classnames';
import useDelayMount from '../../utils/useDelayMount';
import { Description } from '../Description/Description';

export interface BottomSheetProps {
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
}

const ANIMATION_DURATION_MS = 250;

// ? The distance (screen height percentage as fraction) since last pointer move that the pointer needs to be moving at to close the bottom sheet
const FLICK_ACTION_THRESHOLD = 0.025;

export const BottomSheetContent = ({ children }: { children: ReactNode }) => {
  return <div className='BottomSheet-content'>{children}</div>;
};

export function BottomSheet({ open, children, onClose }: BottomSheetProps) {
  const isMounted = useDelayMount(open, ANIMATION_DURATION_MS);
  const bottomSheetRef = useRef<ComponentRef<'div'>>(null);
  const dragHandleRef = useRef<ComponentRef<'div'>>(null);

  const currentHeightRef = useRef<number | null>(null);
  const lastYPositionRef = useRef<number | null>(null);
  const isDraggingRef = useRef(false);
  const flickActionRef = useRef<'up' | 'down' | null>(null);

  const handlePointerDown = useCallback((e: PointerEvent) => {
    if (!isDraggingRef.current) {
      isDraggingRef.current = true;
      lastYPositionRef.current = e.clientY;
      currentHeightRef.current = null;
      flickActionRef.current = null;
    }
  }, []);

  const handlePointerMove = useCallback((e: PointerEvent) => {
    const isMouseDown = e.buttons === 1;
    if (!bottomSheetRef.current || !dragHandleRef.current || !isDraggingRef.current || !isMouseDown) return;

    const roundedPointerYPosition = Math.round(e.clientY);
    const distanceY = lastYPositionRef.current ? roundedPointerYPosition - lastYPositionRef.current : 0;
    if (distanceY === 0) return; // ? Only update if the pointer has moved at least 1px, for performance reasons

    const { clientHeight: bottomSheetHeight } = bottomSheetRef.current;

    const isFlicking = lastYPositionRef.current
      ? Math.abs(lastYPositionRef.current - roundedPointerYPosition) > screen.height * FLICK_ACTION_THRESHOLD
      : false;

    const isFlickingUp = distanceY < 0 && isFlicking;
    const isFlickingDown = distanceY > 0 && isFlicking;
    flickActionRef.current = isFlickingUp ? 'up' : isFlickingDown ? 'down' : null;

    const height = Math.max(0, bottomSheetHeight - distanceY);
    bottomSheetRef.current.style.height = `${Math.max(dragHandleRef.current.clientHeight, height)}px`;

    currentHeightRef.current = height;
    lastYPositionRef.current = roundedPointerYPosition;
  }, []);

  const handlePointerUp = useCallback(() => {
    isDraggingRef.current = false;

    if (flickActionRef.current === 'down' && onClose) return onClose();

    if (flickActionRef.current === 'up' && bottomSheetRef.current) {
      bottomSheetRef.current.style.height = `${window.innerHeight}px`;
      currentHeightRef.current = window.innerHeight;
    }

    if (
      onClose &&
      currentHeightRef.current &&
      dragHandleRef.current &&
      currentHeightRef.current <= dragHandleRef.current.clientHeight * 1.5 // ? Close if close to the bottom of the screen
    ) {
      onClose();
    }
  }, [onClose]);

  if (!isMounted) return null;

  return (
    <div
      className='BottomSheetWrapper'
      style={{
        ['--animation-duration-ms' as string]: `${ANIMATION_DURATION_MS}ms`,
      }}
    >
      <BottomSheetOverlay onClose={onClose} open={open} />
      <div className={cn('BottomSheet', open ? 'open' : 'closed')} ref={bottomSheetRef}>
        <div
          className='BottomSheet-top'
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
          ref={dragHandleRef}
        >
          <div className='BottomSheet-drag-handle' />
        </div>
        <ul className='BottomSheet-content'>{children}</ul>
      </div>
    </div>
  );
}

BottomSheet.Content = BottomSheetContent;
BottomSheet.Description = Description;
