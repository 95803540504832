import type { ReactElement } from 'react';
import React from 'react';
import cn from 'classnames';
import styles from './MenuItem.module.css';
import { Typography } from '../Typography';
import { Icon } from '../Icon';
import { Expand } from '../Animation';
import type { IconProps } from '../Icon/Icon';
import { useMediaQuery } from 'aim-utils';

export interface MenuItemProps {
  label: string;
  mobileOnly?: boolean;
  subItems?: ReactElement[];
  onClick?: () => void;
  onClose?: () => void;
  isSubItem?: boolean;
  iconName?: IconProps['icon'];
  subItemsExpanded?: boolean;
}

export const MenuItem = ({
  label,
  subItems = [],
  mobileOnly = false,
  onClick,
  onClose,
  isSubItem,
  iconName,
  subItemsExpanded,
}: MenuItemProps): ReactElement => {
  const isSmallScreen = useMediaQuery('(max-width: 1100px)');
  const isLogOutIcon = iconName === 'log-out';

  const getIconName = () => {
    if (subItems.length) return 'dropdown-down';
    if ((isSmallScreen || !iconName) && !isLogOutIcon) return 'nav-forward';
    return iconName;
  };

  return (
    <>
      <div
        className={cn(
          isSubItem ? styles.subItem : styles.item,
          mobileOnly && styles.hidden,
          isLogOutIcon && styles.logOut,
        )}
        onClick={onClick}
      >
        {!isSubItem && (
          <Icon icon={getIconName()} className={cn(styles.icon, subItemsExpanded && subItems.length && styles.up)} />
        )}
        <Typography variant={isSubItem ? 'text2' : 'subtitle'} className={styles.label}>
          {label}
        </Typography>
      </div>
      {subItems.length > 0 && isSmallScreen && (
        <Expand expanded={subItemsExpanded}>
          {React.Children.map(
            subItems,
            (subItem) =>
              subItem &&
              React.cloneElement(subItem, {
                onClick: () => {
                  onClick && onClick();
                  onClose && onClose();
                },
              }),
          )}
        </Expand>
      )}
    </>
  );
};
