import type { ReactElement, LegacyRef, MouseEvent, FocusEvent, ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import React, { useRef, useState, useCallback } from 'react';
import cn from 'classnames';
import { Icon } from '../../Icon';
import { Spinner } from '../../Loading';
import { Label } from '../Label';
import { SelectOption } from './SelectOption';
import styles from './Select.module.css';
import type { Placement } from '@floating-ui/react';
import { useDismiss, useInteractions, FloatingPortal, FloatingOverlay } from '@floating-ui/react';
import { useSelect } from './hooks';
import { useMobile } from 'aim-utils';

export interface Option<TValue extends string | number = string> {
  value: TValue;
  label: string;
  icon?: ReactNode;
  tab?: string;
  id?: string; // Used to distinguish between options that appears the same, but are used in different contexts (used to retrigger dataset filters).
  disabled?: boolean;
}

export interface SelectableOption<TValue extends string | number = string> extends Option<TValue> {
  newFilterFlag?: boolean;
}

export interface SelectProps<TValue extends string | number = string> {
  options: ReadonlyArray<SelectableOption<TValue>>;
  placeholder?: string;
  hint?: string;
  selectedOption?: SelectableOption<TValue> | TValue | null;
  setSelectedOption?: (option: Option<TValue> | undefined) => void;
  label?: string;
  disabled?: boolean;
  validationError?: string;
  isValid?: boolean;
  condensed?: boolean;
  searchable?: boolean;
  optionIsLoading?: boolean;
  placement?: Placement;
  classNames?: Record<'container', string>;
  noBorder?: boolean;
  iconDisplayMode?: 'always' | 'selected';
}

export const Select = <TValue extends string | number = string>({
  options,
  placeholder,
  hint,
  selectedOption: _selectedOption,
  setSelectedOption,
  label,
  disabled,
  validationError,
  isValid,
  condensed,
  searchable,
  optionIsLoading,
  placement,
  classNames,
  noBorder = false,
  iconDisplayMode = 'selected',
}: SelectProps<TValue>): ReactElement => {
  const { showOptions, setShowOptions, refs, floatingStyles, context } = useSelect({ placement });

  const selectedOption = useMemo(() => {
    return typeof _selectedOption === 'object' ? _selectedOption : options.find((o) => o.value === _selectedOption);
  }, [_selectedOption, options]);

  const [searchValue, setSearchValue] = useState(selectedOption?.label || '');
  const searchRef = useRef<HTMLInputElement>();
  const onSelect = useCallback(
    (option: Option<TValue>, e: MouseEvent) => {
      setSelectedOption && setSelectedOption(option);
      setSearchValue(option.label);
      e.stopPropagation();
      setShowOptions(false);
      e.preventDefault();
    },
    [setSelectedOption, setShowOptions],
  );

  useEffect(() => {
    setSearchValue(selectedOption?.label || '');
  }, [selectedOption]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if ((e.nativeEvent as unknown as { sourceCapabilities: InputDeviceInfo }).sourceCapabilities) {
        setSearchValue(selectedOption?.label || '');
      }
    },
    [selectedOption],
  );

  const handleOnClick = useCallback(() => {
    if (!disabled && !optionIsLoading) {
      searchable && setSearchValue('');
      searchRef.current?.focus();
      setShowOptions(!showOptions);
    }
  }, [disabled, optionIsLoading, searchable, setShowOptions, showOptions]);

  const filteredOptions = useMemo(() => {
    return searchable && showOptions && searchValue?.length > 0
      ? options.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()))
      : options;
  }, [options, searchValue, searchable, showOptions]);

  const dismiss = useDismiss(context, { enabled: !disabled });
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const someOptionHasNewFilterFlag = options.some((option) => option.newFilterFlag);
  const { mobileView } = useMobile();

  return (
    <div>
      <div className={styles.labelContainer}>
        <Label label={label} disabled={disabled} htmlFor='select-input' />
        {someOptionHasNewFilterFlag && !mobileView && (
          <Label label={'New filter'} disabled={disabled} color='primaryBlue' />
        )}
      </div>
      <div
        className={cn(
          styles.container,
          disabled && styles.disabled,
          isValid === true && styles.valid,
          isValid === false && styles.invalid,
          condensed && styles.condensed,
          classNames?.container,
          noBorder && styles.noBorder,
        )}
        id='select-input'
        tabIndex={0}
        role='input'
        aria-label={label}
        onClick={handleOnClick}
        onMouseDown={(e) => e.preventDefault()}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <input
          onChange={handleChange}
          autoComplete='off'
          onBlur={handleBlur}
          value={searchValue}
          ref={searchRef as LegacyRef<HTMLInputElement>}
          role='textbox'
          placeholder={placeholder}
          readOnly={!searchable}
        />
        {optionIsLoading ? (
          <Spinner />
        ) : (
          <Icon icon={`dropdown-${showOptions ? 'up' : 'down'}`} className={styles.dropdown} />
        )}
      </div>

      {showOptions && (
        <FloatingPortal>
          <FloatingOverlay>
            <ul
              className={styles.options}
              data-testid='select-options'
              ref={refs.setFloating}
              {...getFloatingProps()}
              style={floatingStyles}
            >
              {filteredOptions.length > 0 ? (
                filteredOptions.map((o) => (
                  <SelectOption
                    key={o.value}
                    option={o}
                    isSelected={o.value === selectedOption?.value}
                    onSelect={onSelect}
                    condensed={condensed}
                    disabled={o.disabled}
                    iconDisplayMode={iconDisplayMode}
                  />
                ))
              ) : (
                <SelectOption option={{ label: 'No option available', value: '' }} />
              )}
            </ul>
          </FloatingOverlay>
        </FloatingPortal>
      )}
      {!(isValid === false && validationError) && hint && <p className={styles.hint}>{hint}</p>}
      {isValid === false && <p className={styles.error}>{validationError}</p>}
    </div>
  );
};
