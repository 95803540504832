import { createTimeGranularityTuple } from '@/components/insights/charts/common/types';

export const GROWTH_TIME_GRANULARITIES = createTimeGranularityTuple('quarters', 'months', 'days');
export const GROWTH_RATE_WINDOW_SIZES = ['1d', '7d', '30d', '60d', '90d', '365d'] as const;

export type GrowthRateTimeGranularity = (typeof GROWTH_TIME_GRANULARITIES)[number];
export type WindowSize = (typeof GROWTH_RATE_WINDOW_SIZES)[number];

type YearOverYearRowData = {
  sales_net_growth_rate_year_over_year: bigint | null;
  sales_net_growth_rate_year_over_year_7d_avg: bigint | null;
  sales_net_growth_rate_year_over_year_30d_avg: bigint | null;
  sales_net_growth_rate_year_over_year_60d_avg: bigint | null;
  sales_net_growth_rate_year_over_year_90d_avg: bigint | null;
  sales_net_growth_rate_year_over_year_365d_avg: bigint | null;
  annualized_sales_net_growth_rate_year_over_year: bigint | null;
  annualized_sales_net_growth_rate_year_over_year_7d_avg: bigint | null;
  annualized_sales_net_growth_rate_year_over_year_30d_avg: bigint | null;
  annualized_sales_net_growth_rate_year_over_year_60d_avg: bigint | null;
  annualized_sales_net_growth_rate_year_over_year_90d_avg: bigint | null;
  annualized_sales_net_growth_rate_year_over_year_365d_avg: bigint | null;
  active_customer_growth_rate_year_over_year: bigint | null;
  annualized_subscription_sales_net_growth_rate_year_over_year: bigint | null;
};

type MonthOverMonthRowData = {
  sales_net_growth_rate_month_over_month: bigint | null;
  sales_net_growth_rate_month_over_month_7d_avg: bigint | null;
  sales_net_growth_rate_month_over_month_30d_avg: bigint | null;
  sales_net_growth_rate_month_over_month_60d_avg: bigint | null;
  sales_net_growth_rate_month_over_month_90d_avg: bigint | null;
  sales_net_growth_rate_month_over_month_365d_avg: bigint | null;
  annualized_sales_net_growth_rate_month_over_month: bigint | null;
  annualized_sales_net_growth_rate_month_over_month_7d_avg: bigint | null;
  annualized_sales_net_growth_rate_month_over_month_30d_avg: bigint | null;
  annualized_sales_net_growth_rate_month_over_month_60d_avg: bigint | null;
  annualized_sales_net_growth_rate_month_over_month_90d_avg: bigint | null;
  annualized_sales_net_growth_rate_month_over_month_365d_avg: bigint | null;
  active_customer_growth_rate_month_over_month: bigint | null;
  annualized_subscription_sales_net_growth_rate_month_over_month: bigint | null;
};

type QuarterOverQuarterRowData = {
  sales_net_growth_rate_quarter_over_quarter: bigint | null;
  active_customer_growth_rate_quarter_over_quarter: bigint | null;
  annualized_subscription_sales_net_growth_rate_quarter_over_quarter: bigint | null;
  annualized_sales_net_growth_rate_quarter_over_quarter: bigint | null;
};

type MetricRowData = {
  sales_net: bigint | null;
  sales_net_7d: bigint | null;
  sales_net_30d: bigint | null;
  sales_net_60d: bigint | null;
  sales_net_90d: bigint | null;
  sales_net_365d: bigint | null;
  annualized_sales_net: bigint | null;
  annualized_sales_net_7d: bigint | null;
  annualized_sales_net_30d: bigint | null;
  annualized_sales_net_60d: bigint | null;
  annualized_sales_net_90d: bigint | null;
  annualized_sales_net_365d: bigint | null;
  active_customers: bigint | null;
  annualized_subscription_sales_net_run_rate: bigint | null;
};

export type GrowthRateArrowRow = YearOverYearRowData &
  (
    | ({ date_day: number } & MonthOverMonthRowData & MetricRowData)
    | ({ date_month: number } & MonthOverMonthRowData & MetricRowData)
    | ({ date_quarter: number } & QuarterOverQuarterRowData & MetricRowData)
  );

export const getMetricYoY = (
  windowSize: WindowSize,
  timeGranularity: GrowthRateTimeGranularity,
  metric: 'sales_net' | 'annualized_sales_net',
): keyof YearOverYearRowData => {
  if (windowSize === '1d' || timeGranularity !== 'days') {
    return `${metric}_growth_rate_year_over_year`;
  }
  return `${metric}_growth_rate_year_over_year_${windowSize}_avg`;
};

export const getMetricMoM = (
  windowSize: WindowSize,
  timeGranularity: GrowthRateTimeGranularity,
  metric: 'sales_net' | 'annualized_sales_net',
): keyof MonthOverMonthRowData => {
  if (windowSize === '1d' || timeGranularity !== 'days') {
    return `${metric}_growth_rate_month_over_month`;
  }
  return `${metric}_growth_rate_month_over_month_${windowSize}_avg`;
};

export const getMetricBase = (
  windowSize: WindowSize,
  timeGranularity: GrowthRateTimeGranularity,
  metric: 'sales_net' | 'annualized_sales_net',
): keyof MetricRowData => {
  if (windowSize === '1d' || timeGranularity !== 'days') {
    return metric;
  }

  return `${metric}_${windowSize}`;
};
