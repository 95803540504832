import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './Toaster.module.css';
import type { IconName } from '../Icon';
import { Icon } from '../Icon';
import { Slide } from '../Animation/Slide';

const TOASTER_START = 50;
export const TOASTER_DURATION = 4000;

export type ToasterType = 'neutral' | 'critical' | 'warning' | 'info' | 'success';

export interface ToasterProps {
  icon?: boolean;
  type?: ToasterType;
  text: string;
  handleDismiss?: () => void;
  button?: { action: () => void; text: string };
  noMaxWidth?: boolean;
}

export function Toaster({
  icon = true,
  type = 'neutral',
  text,
  handleDismiss,
  button,
  noMaxWidth = false,
}: ToasterProps) {
  const [show, setShow] = useState(false);

  // toasts are not rendered until toast() is called, meaning it would be initiated with true as the in prop
  // this way, the entering animation would not be shown, which is why this hook is needed
  useEffect(() => {
    if (TOASTER_DURATION) {
      setTimeout(() => setShow(true), TOASTER_START);
      setTimeout(() => !handleDismiss && setShow(false), TOASTER_DURATION);
    }
  }, [handleDismiss]);

  return (
    <Slide expanded={show} offset={noMaxWidth ? 0 : 500}>
      <div className={cn(styles.toaster, styles[type], noMaxWidth && styles.noMaxWidth)}>
        {icon && <Icon icon={getIcon(type)} className={type === 'neutral' ? styles.neutralIcon : styles.icon} />}
        <div className={styles.toasterText}>{text}</div>
        {button?.text && button?.action && (
          <button className={styles.toasterButton} onClick={() => button.action()}>
            {button.text}
          </button>
        )}
        {handleDismiss && (
          <Icon icon='close' onClick={() => handleDismiss()} className={styles.dismiss} role='button' />
        )}
      </div>
    </Slide>
  );
}

function getIcon(type: ToasterType): IconName {
  switch (type) {
    case 'info':
      return 'info-circle';
    case 'critical':
      return 'failure-circle';
    case 'success':
      return 'success-circle';
    case 'warning':
      return 'warning-circle';
    case 'neutral':
      return 'bell';
    default:
      return 'placeholder';
  }
}

export default Toaster;
