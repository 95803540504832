/* eslint-disable camelcase */
import { useEffect } from 'react';
import { isGilionEmployee } from '@/lib/permissions/role';
import type { UserSession } from '@/lib/utils';
import { FSBootIntercom } from '@/lib/fullstory';

const APP_ID = 'rh0bx3cu';

export const load = (settings: Partial<typeof window.intercomSettings>) => {
  window.intercomSettings = {
    alignment: 'right',
    ...settings,
  };

  // * Script from https://developers.intercom.com/installing-intercom/web/installation/#how-to-install
  // prettier-ignore
  /* eslint-disable */
  // @ts-ignore
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==='function'){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  /* eslint-enable */
  /* eslint-disable camelcase */
};

export const boot = (options = {}) => {
  window && window.Intercom && window.Intercom('boot', { app_id: APP_ID, ...options });
};

export const show = () => {
  window && window.Intercom && window.Intercom('show');
};

export const showIntercomMessages = () => {
  window && window.Intercom && window.Intercom('showMessages');
};

/**
 * ! This returns the internal Intercom state from `localStorage`. It's an undocumented "feature" that should be used with caution.
 */
export const getInternalIntercomState = () => {
  if (!window || !window.Intercom) return null;

  try {
    const persistedIntercomState = localStorage.getItem('intercom.intercom-state');
    if (persistedIntercomState === null) return null;

    return JSON.parse(persistedIntercomState);
  } catch (error) {
    return null;
  }
};

export const showConversation = (conversationId: string) => {
  if (!window || !window.Intercom) return;

  // @ts-expect-error The types for Intercom are not fully up-to-date, see: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/intercom-web/index.d.ts
  window.Intercom('showConversation', conversationId);
};

export const onUnreadCountChange = (callback: () => void) => {
  if (!window || !window.Intercom) return;

  window.Intercom('onUnreadCountChange', callback);
};

export const IntercomSurvey = {
  Benchmarking: 39403913,
} as const;

type IntercomSurveyId = (typeof IntercomSurvey)[keyof typeof IntercomSurvey];

export const startSurvey = (surveyId: IntercomSurveyId) => {
  if (!window || !window.Intercom) throw new Error('Intercom is not available');

  window.Intercom('startSurvey', surveyId);
};

const Intercom = ({ hash, userSession }: { hash: string; userSession: UserSession | null }) => {
  useEffect(() => {
    try {
      if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_ENV === 'production') {
        load({ vertical_padding: 20 });

        if (userSession?.arkUserId && userSession?.tenant) {
          // * Do not register Gilion Employees company property in Intercom
          const logCompany = !isGilionEmployee(userSession.email);

          boot({
            name: userSession.displayName,
            email: userSession.email,
            company: logCompany ? userSession.tenant : null,
            created_at: userSession.created,
            tenant_url: window.location.origin + '/' + userSession.tenant,
            user_hash: hash,
          });

          FSBootIntercom({
            withOptions: true,
            arkUserId: userSession?.arkUserId,
            tenant: userSession?.tenant,
            hashLength: typeof hash === 'string' ? hash.length : undefined,
          });
        } else {
          boot();

          FSBootIntercom({
            withOptions: false,
            arkUserId: userSession?.arkUserId,
            tenant: userSession?.tenant,
            hashLength: typeof hash === 'string' ? hash.length : undefined,
          });
        }
      }
    } catch (e) {
      console.error('Error loading Intercom', e);
    }
  }, [hash, userSession]);

  return null;
};

export default Intercom;
