import { sortAlphabetically } from '../sorting';
import { COUNTRY_CODES } from './country-codes';

export type CountryCode = (typeof COUNTRY_CODES)[number];

export interface Country {
  code: CountryCode;
  name: string;
}

const countryName = new Intl.DisplayNames(['en'], { type: 'region' });

export const list: Country[] = COUNTRY_CODES.map((code) => ({ code, name: countryName.of(code) ?? '' })).sort((a, b) =>
  sortAlphabetically(a.name, b.name),
);

export const map: Record<CountryCode, string> = COUNTRY_CODES.reduce(
  (o, code) => ({ ...o, [code]: countryName.of(code) ?? '' }),
  {} as Record<CountryCode, string>,
);
