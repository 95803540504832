import React, { useEffect, useState } from 'react';
import styles from './MemoryDebugger.module.css';
import { MemoryUsageHelper } from 'scichart';
import { getAverage, getMemoryDebugData, getMemoryHistoryUpdater, logObjectRegistry } from './utils';

type MemoryDebuggerProps = {
  intervalMs?: number;
  historyCapacity?: number;
};

type MemoryUsageKind = 'undeleted' | 'uncollected' | 'collectedNotDeleted' | 'deletedNotCollected';
type MemoryUsageData = { values: number[]; peak?: number };

const POSITIONS = ['top-left', 'top-right', 'bottom-left', 'bottom-right'] as const;
type Position = (typeof POSITIONS)[number];

const POSITION_ICON_MAP = {
  'top-left': '↖',
  'top-right': '↗',
  'bottom-left': '↙',
  'bottom-right': '↘',
} as const satisfies Record<Position, string>;

export type MemoryUsageHistory = Record<MemoryUsageKind, MemoryUsageData>;

const initialMemoryUsageHistory = {
  undeleted: { values: [] },
  uncollected: { values: [] },
  collectedNotDeleted: { values: [] },
  deletedNotCollected: { values: [] },
} as const satisfies MemoryUsageHistory;

/**
 * Debugging tool to monitor memory usage of SciChart charts. Can be used to detect memory leaks.
 * Similar to `MemoryUsageHelper.objectRegistry.log()`, but with a visual representation.
 *
 * * **Undeleted:** Objects that were created but `.delete()` has not been called.
 * * **Uncollected:** Objects that have not been collected by the JavaScript garbage collector.
 * * **Collected not deleted:** Objects that were collected by the garbage collector but `.delete()` was not called.
 * * **Deleted not collected:** Objects that had `.delete()` called, but not collected by the garbage collector.
 */
export const MemoryDebugger = ({ intervalMs = 1_000, historyCapacity = 10 }: MemoryDebuggerProps) => {
  const [memoryUsageHistory, setMemoryUsageHistory] = useState<MemoryUsageHistory>(initialMemoryUsageHistory);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [positionOnScreen, setPositionOnScreen] = useState<Position>('bottom-right');

  useEffect(() => {
    const debugEnabledSinceBefore = MemoryUsageHelper.isMemoryUsageDebugEnabled;
    MemoryUsageHelper.isMemoryUsageDebugEnabled = true;

    const interval = setInterval(() => {
      const { undeletedIds, uncollectedIds, collectedNotDeletedIds, deletedNotCollectedIds } = getMemoryDebugData();

      const undeleted = undeletedIds.length;
      const uncollected = uncollectedIds.length;
      const collectedNotDeleted = collectedNotDeletedIds.length;
      const deletedNotCollected = deletedNotCollectedIds.length;

      setMemoryUsageHistory((prev) => {
        const appendHistory = getMemoryHistoryUpdater({ history: prev, capacity: historyCapacity });

        return {
          undeleted: appendHistory('undeleted', undeleted),
          uncollected: appendHistory('uncollected', uncollected),
          collectedNotDeleted: appendHistory('collectedNotDeleted', collectedNotDeleted),
          deletedNotCollected: appendHistory('deletedNotCollected', deletedNotCollected),
        };
      });

      setElapsedTime((prev) => prev + intervalMs);
    }, intervalMs);

    return () => {
      clearInterval(interval);
      MemoryUsageHelper.isMemoryUsageDebugEnabled = debugEnabledSinceBefore;
    };
  }, [historyCapacity, intervalMs]);

  const clearHistory = () => {
    setMemoryUsageHistory(initialMemoryUsageHistory);
    setElapsedTime(0);
  };

  const elapsedSeconds = elapsedTime / 1000;
  const intervalSeconds = intervalMs / 1000;

  return (
    <article className={styles.container} data-position={positionOnScreen}>
      <ul className={styles.list}>
        <li className={styles.header}>
          <span className={styles.label}>Object kind</span>
          <span className={styles.peak}>Peak ({elapsedSeconds}s)</span>
          <span className={styles.average}>
            Average ({Math.min(elapsedSeconds, historyCapacity * intervalSeconds)}s)
          </span>
          <span className={styles.latest}>Latest ({intervalSeconds}s)</span>
        </li>
        <MemoryDebuggerRow label='Undeleted' data={memoryUsageHistory.undeleted} />
        <MemoryDebuggerRow label='Uncollected' data={memoryUsageHistory.uncollected} />
        <MemoryDebuggerRow
          label='Collected not deleted'
          data={memoryUsageHistory.collectedNotDeleted}
          warningThreshold={0}
        />
        <MemoryDebuggerRow label='Deleted not collected' data={memoryUsageHistory.deletedNotCollected} />
      </ul>

      <div className={styles.buttons}>
        <div className={styles.positionButtons}>
          {POSITIONS.map((position) => (
            <button
              key={position}
              onClick={() => setPositionOnScreen(position)}
              disabled={position === positionOnScreen}
            >
              {POSITION_ICON_MAP[position]}
            </button>
          ))}
        </div>

        <button onClick={logObjectRegistry}>Log object registry</button>
        <button onClick={clearHistory}>Reset elapsed time</button>
      </div>
    </article>
  );
};

const MemoryDebuggerRow = ({
  label,
  data: { values, peak },
  warningThreshold,
}: {
  label: string;
  data: MemoryUsageData;
  warningThreshold?: number;
}) => {
  const peakValue = peak ?? 'N/A';
  const average = values.length > 0 ? Math.round(getAverage(values)) : 'N/A';
  const previousValue = values.at(-2) ?? 'N/A';
  const latest = values.at(-1) ?? 'N/A';
  const displayWarning = warningThreshold !== undefined && typeof latest === 'number' && latest > warningThreshold;

  return (
    <li data-warn={displayWarning}>
      <span className={styles.label}>{label}</span>
      <span className={styles.peak}>
        <span className={styles.value}>{peakValue}</span>
      </span>
      <span className={styles.average}>
        <TrendIndicator value={latest} referenceValue={average} />
        <span className={styles.value}>{average}</span>
      </span>
      <span className={styles.latest}>
        <TrendIndicator value={latest} referenceValue={previousValue} />
        <span className={styles.value}>{latest}</span>
      </span>
    </li>
  );
};

const TrendIndicator = ({ value, referenceValue }: { value: number | string; referenceValue: number | string }) => {
  if (typeof value === 'string' || typeof referenceValue === 'string') return null;

  const trend = (() => {
    if (value > referenceValue) return { name: 'up', icon: '↑' };
    if (value < referenceValue) return { name: 'down', icon: '↓' };
    return { name: 'unchanged', icon: '→' };
  })();

  return (
    <span className={styles.trendIndicator} data-trend={trend.name}>
      {trend.icon}
    </span>
  );
};
