import { useCallback, useId, useMemo, useRef } from 'react';
import { localPoint } from '@visx/event';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import type { ScaleLinear, ScalePoint } from 'd3';
import { scalePoint, scaleLinear, min } from 'd3';
import type { TooltipDataPoint } from '../../Chart/types';
import type { D3ChartDataset, D3LineChartConfig, Margin } from '../types';
import { getMaxYValueInDatasets } from '../../Chart/utils';
import { getDateInterval } from '../../../utils/date-utils';
import { uniqueArray } from 'aim-utils';

export const useScales = (
  width: number,
  height: number,
  datasets: D3ChartDataset[],
  config: D3LineChartConfig,
  labels?: string[],
): [d3.ScalePoint<string> | undefined, d3.ScaleLinear<number, number, never> | undefined] => {
  const xMin = config?.xAxis?.min;
  const xMax = config?.xAxis?.max;
  const labelsWithinBounds = useMemo(() => {
    if (xMin && xMax && labels) {
      return uniqueArray(
        getDateInterval(xMin ?? labels[0], xMax ?? labels.at(-1), config.xAxis?.granularity ?? 'months')
          .map((label) => label.toISOString())
          .concat(labels.filter((l) => l >= xMin && l <= xMax)),
      );
    }
    return labels;
  }, [config.xAxis?.granularity, labels, xMax, xMin]);

  const xScale = useMemo(() => {
    if (labelsWithinBounds) {
      return scalePoint()
        .domain(labelsWithinBounds.map((d) => d))
        .range([0, width]);
    }
  }, [labelsWithinBounds, width]);

  const yScale = useMemo(() => {
    if (datasets) {
      const filteredDatasets = datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.filter(({ x }) => (xMin ? x >= xMin : true)).filter(({ x }) => (xMax ? x <= xMax : true)),
      }));
      return scaleLinear()
        .domain([
          config?.yAxis?.yMin ?? (min(filteredDatasets, (d) => min(d.data, (d) => d.y)) as number),
          config?.yAxis?.yMax ?? getYMax(filteredDatasets),
        ])
        .range([height, 0]);
    }
  }, [datasets, config?.yAxis?.yMin, config?.yAxis?.yMax, height, xMin, xMax]);

  return [xScale, yScale];
};

export const getYMax = (datasets: D3ChartDataset[]) => {
  const datasetWithYPaddingPercentage = datasets.find((dataset) => dataset.yPaddingPercentage);
  if (datasetWithYPaddingPercentage && datasetWithYPaddingPercentage.yPaddingPercentage) {
    const maxYValueWidthPercentage =
      getMaxYValueInDatasets([datasetWithYPaddingPercentage]) * datasetWithYPaddingPercentage.yPaddingPercentage;
    const maxYValue = getMaxYValueInDatasets(datasets);
    return Math.max(maxYValueWidthPercentage, maxYValue);
  }
  return getMaxYValueInDatasets(datasets);
};

export const useLineChartTooltip = (
  datasets: D3ChartDataset[],
  labels: string[],
  margin: Margin,
  xScale: ScalePoint<string> | undefined,
  yScale: ScaleLinear<number, number> | undefined,
  enableTooltip: boolean,
) => {
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<{
    dataPoints: TooltipDataPoint[];
    dataIndex: number;
  }>();

  const { containerRef, containerBounds } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const lastDataIndex = useRef<number>(-1);

  const stepSize = containerBounds.width / (labels.length || 1);

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement>) => {
      const coords = localPoint(event) ?? { x: 0, y: 0 };
      const xCoordinate = coords.x - margin.left;

      const dataIndex = Math.floor(xCoordinate / stepSize);

      if (lastDataIndex.current === dataIndex) return; // Only update tooltip if a new data point is hovered

      const data = datasets[0]?.data?.[dataIndex];

      if (data === undefined || yScale === undefined || xScale === undefined) {
        return hideTooltip();
      }

      const scaledX = xScale(data.x) ?? 0;
      const scaledY = yScale(data.y);

      const dataPoints: TooltipDataPoint[] = datasets.map((dataset, datasetIndex) => {
        const data = dataset.data[dataIndex];

        return {
          label: data.x,
          value: data.y,
          dataIndex,
          datasetIndex,
          type: 'line',
          color: dataset.color,
          point: { x: scaledX, y: scaledY },
        };
      });

      showTooltip({
        tooltipLeft: scaledX,
        tooltipTop: coords.y,
        tooltipData: {
          dataIndex,
          dataPoints,
        },
      });

      lastDataIndex.current = dataIndex;
    },
    [datasets, hideTooltip, margin.left, showTooltip, stepSize, xScale, yScale],
  );

  return [
    containerRef,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    enableTooltip ? handlePointerMove : undefined,
    enableTooltip ? hideTooltip : undefined,
  ] as const;
};

export const useSvgDefinitionId = () => {
  const id = useId();
  const url = `url(#${id})`;

  return [id, url] as const;
};
