import type { IRenderableSeries } from 'scichart';
import { SciChartSurface, Thickness, EXyDirection, ZoomPanModifier } from 'scichart';
import { SciChartReact } from 'scichart-react';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { DARK_THEME } from './themes/theme-dark';
import chartStyles from './styles/chart.module.css';
import overviewStyles from './styles/overview.module.css';
import type { TooltipRenderer } from './hooks/tooltip.hooks';
import { useTooltip } from './hooks/tooltip.hooks';
import type { ChartDataset, ChartViewMode, OnDatasetHover } from './types';
import { useDataSeries } from './hooks';
import { renderHoverState } from './utils/hover-utils';
import { useXAxes, useYAxes } from './hooks/axes.hooks';
import { useDataLabels, useSlider } from './slider/slider-hooks';
import { useDatasetMobileHover, useOnDatasetHover } from './hooks/hover.hooks';
import { useAbortController } from './hooks/abort-controller.hooks';
import type { XAxisConfig } from './utils/axes/x-axis.utils';
import { createXAxis } from './utils/axes/x-axis.utils';
import type { YAxisConfig } from './utils/axes/y-axis.utils';
import { createYAxis } from './utils/axes/y-axis.utils';
import type { HoveredDataLabelProps } from './modifiers/MouseOverAnnotationModifier';
import { MouseOverAnnotationModifier } from './modifiers/MouseOverAnnotationModifier';

SciChartSurface.loadWasmFromCDN();

// * Runtime license key for SciChart. Bound to controlled domains, does not need to be treated as a secret.
SciChartSurface.setRuntimeLicenseKey(
  'dzPiXEV4Vzfwm9EjSJfZfoYi6PYZCVYlwN5c6gpNNfk7SyZoCRXBvY+S7GBVuUfHyvLDg975zNIwtLPaAEfcCpoakfcrUUyJgVicV1t8EuFzUrOZl1N/tlkJSrPzC7cCKw0ARtiDP5/ZViQjSSK1k93oiwfip8tP5L6wJtQCwQVghmJC4E4/Z27ZwXVAgNlFZLkN8Uf/Oe+AadeAf8O+MuIcX6xvb8LJN7JAq6WLWHmy7Lc1d1XgN/W/8xT+7NO0LQcGqfWG/+Q9luVM48XiWiLaotCVn5K/X1IHz0/Npz+ixt4n4bmrmUpPuhrWszMFe+lUSVFyIOQc5t8WMyIfwMcobraLjhK3PZ2hvPPjx8ThANZEc9Qto825OdEUPk5SizLmNM1Itj7885V3Rfzo4F/v+dQUWUoU3qblMZqeOTFFDPg6DefywNMiFfcT0gcWns9TuzOIyGhxQZIFzFSyqkwNRym+0lR0DQgQrVlXSbbX6rw/Ar382qk326B2jCmZndK+gz7xMz2Wg1nfpDhDG6KMbEegurp0KN6qlQ1O1gYVplzSKGqw7olyh3IkMBdZ5mAig2F47Rx+WgR3lU+TY+6Rdmel1nUGPjnGOskhpPi3b1uC5NtsNLEetm5DpB3Q1/aZnA5sDYUxOeJ2IdGgMKZvQ7gkt3T5N/EM/zvfGkV9bALV+5D0h8k2Zt/J0d1ZNPear8rhSDMiMmWYLqFk2YDlavxSGVi61UhEjPYq',
);

type CharlieChartProps = {
  mode: ChartViewMode; // ? We could perhaps "inject" this through a context. Maybe SliderContext can be made a bit more generic, something like CharlieChartContext or so?
  fullscreen?: boolean; // ? We could perhaps "inject" this through a context. Maybe SliderContext can be made a bit more generic, something like CharlieChartContext or so?
  datasets: ChartDataset[];
  selected: string[];
  renderTooltip?: TooltipRenderer;
  yAxes: Array<YAxisConfig>;
  xAxis: XAxisConfig;
  onDatasetHover?: OnDatasetHover;
};

export const CharlieChart = ({
  mode,
  fullscreen,
  datasets,
  selected,
  renderTooltip,
  yAxes,
  xAxis,
  onDatasetHover,
}: CharlieChartProps) => {
  const surfaceRef = useRef<SciChartSurface | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const abortController = useAbortController();
  const hoveredSeriesId = useRef<Set<IRenderableSeries['id']> | null>(null);
  const hoveredDataLabelRef = useRef<HoveredDataLabelProps | null>(null);

  useEffect(() => () => surfaceRef.current?.delete(), []);

  const { overviewId } = useSlider({
    datasets,
    selectedDataset: selected,
    surfaceRef,
    isInitialized,
    xAxis,
    yAxes,
  });

  const { seriesMetadataRef } = useDataSeries({
    surfaceRef,
    datasets,
    selectedDataset: selected,
    isInitialized,
    xAxis,
    yAxes,
  });

  useDataLabels({ surfaceRef, datasets, isInitialized, seriesMetadataRef });

  useXAxes({ axes: [xAxis], surfaceRef, isInitialized, mode });
  useYAxes({ axes: yAxes, surfaceRef, isInitialized, mode, datasets });

  const { tooltipCursorModifier } = useTooltip({
    renderTooltip,
    surfaceRef,
    hoveredSeriesId,
    seriesMetadataRef,
    hoveredDataLabelRef,
  });

  const { handleOnDatasetHover } = useOnDatasetHover({ surfaceRef, seriesMetadataRef, onDatasetHover });

  const { handleDatasetHoverAnnotation } = useDatasetMobileHover({
    surfaceRef,
    seriesMetadataRef,
    enabled: !!(mode === 'mobile' && fullscreen),
  });

  return (
    <>
      <SciChartReact<SciChartSurface>
        className={chartStyles.chart}
        style={{
          // * Pointer events are disabled on mobile while not in fullscreen mode, to allow scrolling on the page where the chart is displayed.
          pointerEvents: mode === 'mobile' && !fullscreen ? 'none' : 'auto',
        }}
        fallback={<></>}
        onInit={({ sciChartSurface }) => {
          surfaceRef.current = sciChartSurface;

          surfaceRef.current.domCanvas2D.addEventListener(
            'pointermove',
            (pointerEvent: PointerEvent) => {
              renderHoverState(surfaceRef, pointerEvent, seriesMetadataRef, hoveredSeriesId);
              handleOnDatasetHover(pointerEvent);
              handleDatasetHoverAnnotation(pointerEvent);
            },
            { signal: abortController.signal },
          );

          setIsInitialized(true);
        }}
        initChart={async (rootElement) => {
          const { sciChartSurface, wasmContext } = await SciChartSurface.create(rootElement, {
            theme: DARK_THEME,
            padding: Thickness.fromNumber(0),
          });

          const initialXAxis = createXAxis({ wasmContext, config: xAxis, mode });
          sciChartSurface.xAxes.add(initialXAxis);

          const initialYAxes = yAxes.map((config) => createYAxis({ wasmContext, config, mode }));
          sciChartSurface.yAxes.add(...initialYAxes);

          const zoomPanModifier = new ZoomPanModifier({
            xyDirection: EXyDirection.XDirection,
            horizontalGrowFactor: 0.002,
            enableZoom: true,
          });

          const mouseOverAnnotationModifier = new MouseOverAnnotationModifier(hoveredDataLabelRef);

          sciChartSurface.chartModifiers.add(tooltipCursorModifier, zoomPanModifier, mouseOverAnnotationModifier);

          return { sciChartSurface };
        }}
      />
      <div
        id={overviewId.current}
        className={overviewStyles.overviewChartContainer}
        style={{
          // * This is hidden via CSS, to avoid a race-condition when looking for this element in the DOM, based on the ID.
          display: xAxis.slider ? 'block' : 'none',
        }}
      />
    </>
  );
};
