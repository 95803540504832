import { ContextMenu } from 'aim-components';
import { forwardRef, useCallback } from 'react';
import { useSwitchTenant } from './hooks';

interface TenantListItemProps {
  tenant: string;
  onClick?: () => void;
  highlight?: boolean;
}

export const TenantListItem = forwardRef<HTMLLIElement, TenantListItemProps>(
  ({ tenant, onClick, highlight = false }, ref) => {
    const { switchTenant } = useSwitchTenant();

    const handleOnClick = useCallback(() => {
      onClick && onClick();
      switchTenant(tenant);
    }, [switchTenant, tenant, onClick]);

    return (
      <ContextMenu.ListItem
        label={tenant}
        icon='building'
        onClick={handleOnClick}
        title={tenant}
        highlight={highlight}
        ref={ref}
      />
    );
  },
);

TenantListItem.displayName = 'TenantListItem';
