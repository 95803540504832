import React, { useMemo } from 'react';
import './../d3Chart.css';
import cn from 'classnames';
import { sum } from 'd3';
import { Tooltip } from '../../Chart/Tooltip/Tooltip';
import type { D3BubbleChartConfig, D3BubbleChartData, D3ChartProps } from '../types';
import AnimatedBubble from './AnimatedBubble';
import Annotation from './Annotations';
import D3BubbleChartLegend from './Legend';
import { useTooltip } from './hooks';
import { getBubbleSize, pack } from './utils';
import { useMobile, useResizeObserver } from 'aim-utils';

const BubbleChart = ({ datasets, chartConfig }: D3ChartProps<D3BubbleChartData, D3BubbleChartConfig>) => {
  const legendBubbleRadius = 10;
  const legendPaddingLeft = 17;
  const [chartContainerRef, { width: svgWidth, height: svgHeight }] = useResizeObserver<HTMLDivElement>();
  const { mobileView } = useMobile();

  const margin = { top: 20, right: 10, bottom: 40, left: 120 };

  const smallestDirection = Math.min(svgWidth - 100, svgHeight);
  const circleRadius = (mobileView ? smallestDirection * 0.95 : svgHeight - margin.top - margin.bottom) / 2;

  if (mobileView) {
    margin.left = margin.right = svgWidth / 2 - circleRadius;
    margin.top = margin.top = svgHeight / 2 - circleRadius;
  }

  const { data, color, datasetUnit } = datasets[0] ?? {};

  const { bubbleSize, packedData } = useMemo(() => {
    if (data?.length > 0 && circleRadius && chartConfig) {
      const bubbleSize = getBubbleSize(
        sum(data, (d) => d.y),
        circleRadius,
      );
      return { bubbleSize, packedData: pack(data, bubbleSize).children };
    }
    return { bubbleSize: 0, packedData: null };
  }, [data, circleRadius, chartConfig]);

  const [tooltip, setTooltip] = useTooltip({ x: margin.left + circleRadius, y: margin.top + circleRadius });

  const updateTooltip = (
    value: number,
    position: { x: number; y: number },
    visible: boolean,
    label: string,
    color?: string,
  ) => {
    setTooltip({
      title: chartConfig?.tooltipTitle,
      label,
      value,
      position,
      visible,
      color,
    });
  };

  return (
    <div className='Chart-canvasContainer' ref={chartContainerRef}>
      {chartConfig.tooltipTitle && !mobileView && (
        <div
          className={cn('d3TooltipContainer', 'TooltipContainer', { d3TooltipVisible: tooltip.visible })}
          style={{ transform: `translate(${tooltip.position.x}px,${tooltip.position.y}px)` }}
        >
          <Tooltip
            title={tooltip.title}
            unit='%'
            dataPoints={[{ value: tooltip.value, label: tooltip.label, color: tooltip.color }]}
          />
        </div>
      )}

      <svg width={svgWidth} height={svgHeight}>
        <g transform={`translate(${margin.left + circleRadius},${margin.top + circleRadius})`}>
          {packedData?.map((point, i) => (
            <AnimatedBubble
              cx={point.x - bubbleSize / 2}
              cy={point.y - bubbleSize / 2}
              r={point.r}
              fill={data[i].fill ?? color}
              key={i}
              unit={datasetUnit}
              point={data[i]}
              label={data[i].tooltipLabel ?? ''}
              getTooltipPosition={(position: { x: number; y: number }, label: string, visible: boolean) => {
                updateTooltip(data[i].y * 100, position, visible, label, data[i].fill ?? datasets[0].color);
              }}
            />
          ))}
        </g>
        {chartConfig?.annotations && (
          <Annotation
            bubbleChartConfig={chartConfig.annotations}
            circleRadius={circleRadius}
            marginLeft={margin.left}
            marginTop={margin.top}
            mobileView={mobileView}
          />
        )}

        {!mobileView && (
          <g transform={`translate(0,-${margin.bottom / 2})`}>
            {chartConfig?.legend?.map((legend, i) => (
              <g
                transform={`translate(${legendBubbleRadius + legendPaddingLeft},${
                  margin.top + circleRadius * 2 - legendBubbleRadius * 3 * i
                })`}
                key={legend.text}
              >
                <D3BubbleChartLegend
                  fill={legend.fill}
                  r={legendBubbleRadius}
                  text={legend.text}
                  className={legend.blur ? 'blur' : ''}
                  stroke={legend.stroke}
                  width={legend.width}
                  height={legend.height}
                />
              </g>
            ))}
          </g>
        )}
        <defs>
          <filter id='blurFilter'>
            <feGaussianBlur in='SourceGraphic' stdDeviation='2' />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
export default BubbleChart;
