import lodashThrottle from 'lodash.throttle';
import { flushSync } from 'react-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throttle = <TFunction extends (...args: any) => any>(
  functionToThrottle: TFunction,
  throttleTimeMs: number,
) => lodashThrottle(functionToThrottle, throttleTimeMs) as unknown as TFunction;

export const debounce = <FunctionToDebounce extends (...args: Parameters<FunctionToDebounce>) => void>(
  functionToDebounce: FunctionToDebounce,
  debounceTimeMs: number,
) => {
  let timeout: NodeJS.Timeout;

  const debouncedFunction = (...args: Parameters<FunctionToDebounce>) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => functionToDebounce(...args), debounceTimeMs);
  };

  return debouncedFunction;
};

export const isDefined = <T>(value: T | undefined | null): value is T => value !== undefined && value !== null;

/**
 * Used to exhaust `switch` cases, to make sure all possible cases are handled.
 * If a case is missed, Typescript will display an error during compilation / in the IDE.
 */
export const exhaustiveSwitchCheck = (value: never): never => {
  throw new Error(`Unhandled switch case: ${value}`);
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Progressive enhancement wrapper around `document.startViewTransition` that checks if the method is supported before calling it.
 *
 * @see https://caniuse.com/mdn-api_document_startviewtransition
 */
export const startViewTransitionIfSupported = (callback: () => void) => {
  if (document && 'startViewTransition' in document && typeof document.startViewTransition === 'function') {
    document.startViewTransition(() => flushSync(callback));
  } else {
    callback();
  }
};
