import type { CountryCode } from 'aim-utils';

const COUNTRIES_WITH_SUBSCRIPTION_PLANS_ENABLED = new Set<CountryCode>([
  'AT', // 🇦🇹 Austria
  'BE', // 🇧🇪 Belgium
  'BG', // 🇧🇬 Bulgaria
  'HR', // 🇭🇷 Croatia
  'CZ', // 🇨🇿 Czech Republic
  'DK', // 🇩🇰 Denmark
  'EE', // 🇪🇪 Estonia
  'FI', // 🇫🇮 Finland
  'FR', // 🇫🇷 France
  'DE', // 🇩🇪 Germany
  'GR', // 🇬🇷 Greece
  'HU', // 🇭🇺 Hungary
  'IE', // 🇮🇪 Ireland
  'IT', // 🇮🇹 Italy
  'LV', // 🇱🇻 Latvia
  'LT', // 🇱🇹 Lithuania
  'LU', // 🇱🇺 Luxembourg
  'MT', // 🇲🇹 Malta
  'NL', // 🇳🇱 Netherlands
  'NO', // 🇳🇴 Norway
  'CY', // 🇨🇾 Cyprus
  'PL', // 🇵🇱 Poland
  'PT', // 🇵🇹 Portugal
  'RO', // 🇷🇴 Romania
  'SK', // 🇸🇰 Slovakia
  'SI', // 🇸🇮 Slovenia
  'ES', // 🇪🇸 Spain
  'SE', // 🇸🇪 Sweden
  'GB', // 🇬🇧 United Kingdom
  'US', // 🇺🇸 United States
]);

export const COUNTRIES_WITH_LOANS_ENABLED = new Set<CountryCode>([
  'SE', // 🇸🇪 Sweden
  'DK', // 🇩🇰 Denmark
  'DE', // 🇩🇪 Germany
  'FI', // 🇫🇮 Finland
]);

export const COUNTRIES_WITH_GIN_ENABLED = new Set<CountryCode>([
  'SE', // 🇸🇪 Sweden
  'DK', // 🇩🇰 Denmark
  'GB', // 🇬🇧 United Kingdom
]);

export const countryHasLoansEnabled = (country: CountryCode | undefined) =>
  country ? COUNTRIES_WITH_LOANS_ENABLED.has(country) : false;

export const getTenantPermissions = (country?: CountryCode) => ({
  loansEnabled: countryHasLoansEnabled(country),
  subscriptionsEnabled: country ? COUNTRIES_WITH_SUBSCRIPTION_PLANS_ENABLED.has(country) : false,
  investorNetworkEnabled: country ? COUNTRIES_WITH_GIN_ENABLED.has(country) : false,
});
