export enum RoutesRequiringAuth {
  // ENDPOINTS BELOW REQUIRES AUTHENTICATION BEARER TO CALL, a
  // WILL BE VALIDATED AT INGRESS SEE
  // https://github.com/arkkapital/aim-helm-charts/blob/main/aim-services/aim/templates/http-proxy.yaml
  Root = 'api',
  ConnectorDefinitions = '/api/connectors/definitions',
  SuggestedConnectors = '/api/connectors/suggest',
  SelectedConnectors = '/api/connectors/selected',
  ConnectorDetails = '/api/connectors',
  SelectConnector = '/api/connectors/select',
  DeleteConnector = '/api/connectors/delete',
  UploadConnectorData = '/api/connectors/upload-data',
  DisconnectConnector = '/api/connectors/disconnect',
  LimitDateRangeConnector = '/api/connectors/limit-date-range',
  LtvOverCac = '/api/insights/ltv-cac',
  LtvCacEngagement = '/api/insights/ltv-cac-engagement',
  RetentionTrends = '/api/insights/retention-trends',
  CashForecasting = '/api/insights/cash-forecasting',
  MetricsValidation = '/api/insights/metrics-validation',
  LtvRetentionCohorts = '/api/insights/ltv-retention-cohorts',
  RetentionCohortsTable = '/api/insights/cohort-retention-table',
  NetRevenueForecast = '/api/insights/net-revenue-forecast',
  MarketingCustomers = '/api/insights/marketing-customers',
  AveragePurchase = '/api/insights/average-purchase',
  CohortBuildup = '/api/insights/cohort-buildup',
  AnnualisedRevenue = '/api/insights/annualised-revenue',
  RecurringVsNonRecurringRevenue = '/api/insights/recurring-vs-non-recurring-revenue',
  NewCustomers = '/api/insights/new-customers-vs-marketing',
  CustomerConcentration = '/api/insights/customer-concentration',
  CountryConcentration = '/api/insights/country-concentration',
  EngagementRate = '/api/insights/engagement-rate',
  UserGrowth = '/api/insights/user-growth',
  UserActivity = '/api/insights/user-activity',
  UserActivityRetentionTrends = '/api/insights/user-activity-retention-trends',
  UserRetentionCohorts = '/api/insights/user-retention-cohorts',
  ActiveUsersCohortBuildup = '/api/insights/active-users-cohort-buildup',
  ActiveCustomerRetention = '/api/insights/active-customer-retention',
  GrowthRate = '/api/insights/growth-rate',
  CustomerGrowthRate = '/api/insights/customer-growth-rate',
  ForecastingAdSpend = '/api/forecasting/ad-spend',
  ForecastingAveragePurchase = '/api/forecasting/average-purchase',
  ForecastingCac = '/api/forecasting/cac',
  ForecastingCashPosition = '/api/forecasting/cash-position',
  ForecastingOpex = '/api/forecasting/opex',
  ForecastingRetention = '/api/forecasting/retention',
  ForecastingNetRevenue = '/api/forecasting/net-revenue',
  ForecastingNewCustomers = '/api/forecasting/new-customers',
  ForecastingCohortBuildup = '/api/forecasting/cohort-buildup',
  ForecastingCohortRetention = '/api/forecasting/cohort-retention',
  ForecastingAnnualisedRecurringRevenue = '/api/forecasting/annualised-recurring-revenue',
  InviteUser = '/api/settings/invite-user',
  ChangeUserRole = '/api/settings/change-role',
  DeleteUser = '/api/settings/delete-user',
  GetAllInvitedUsers = '/api/settings/get-users',
  GetAnnotations = '/api/insights/annotations/get-annotations',
  CreateAnnotation = '/api/insights/annotations/create-annotation',
  UpdateAnnotation = '/api/insights/annotations/update-annotation',
  UpdateAnnotationReply = '/api/insights/annotations/update-annotation-reply',
  CreateAnnotationReply = '/api/insights/annotations/create-annotation-reply',
  InsightsMetadata = '/api/insights/metadata',
  AllLoans = '/api/loans/all', // when we can fetch active loans from ATBD, we can merge this with Loans
  Countries = '/api/insights/countries',
  GetTenants = '/api/settings/get-tenants',
  JoinDealChannel = '/api/settings/join-deal-channel',
  TenantInfo = '/api/tenant',
  RefreshTenantCache = '/api/settings/refresh-tenant-cache',
  BusinessData = '/api/business-data',
  SyncATBD = '/api/business-data/sync',
  BusinessDataSettings = '/api/business-data/settings',
  ValidBusinessDataSettings = '/api/business-data/settings/valid',
  LoanApplication = '/api/loans/application',
  LoanApplicationActions = '/api/loans/application/[id]/actions',
  LoanApplicationDocuments = '/api/loans/application/[id]/document',
  LoanApplicationDocumentsGlen = '/api/loans/application/[id]/document/glen',
  LoanApplicationInvestmentCase = '/api/loans/application/[id]/investment_case',
  LoanApplicationOptions = '/api/loans/application/options',
  UpdateLoanStatus = '/api/loans/application/[id]/status',
  InvestmentCases = '/api/loans/investment_case',
  Scores = '/api/scores',
  UserSettings = '/api/user/settings/[setting-key]',
  TenantSettings = '/api/tenant/settings/[setting-key]',
  CovenantReportDetails = '/api/business-data/covenants/report/[id]',
  Covenants = '/api/business-data/covenants',
  UploadCovenantDocument = '/api/business-data/covenants/document',
  DownloadCovenantReportDocuments = '/api/business-data/covenants/document/[report_id]/[document_id]',
  GrowthChatThreads = '/api/growth-chat/threads',
  GrowthChatThreadMessages = '/api/growth-chat/threads/[thread_reference]/messages',
  GrowthChatThreadMessageVote = '/api/growth-chat/threads/[thread_reference]/messages/[message_reference]/vote',
  NotificationSettings = '/api/notifications/settings',
  Notifications = '/api/notifications',
  NotificationReadStatus = '/api/notifications/read-status/[notification_id]',
  Decks = '/api/decks',
  CreateDeck = '/api/decks/create-deck',
  RenameDeck = '/api/decks/rename-deck',
  DeleteDeck = '/api/decks/delete-deck',
  AddCardToDeck = '/api/decks/create-card',
  EditCard = '/api/decks/edit-card',
  DeleteCard = '/api/decks/delete-card',
  ShareDeck = '/api/decks/share',
  ShareDeckWithType = '/api/decks/type/share',
  RemoveShareDeck = '/api/decks/share/remove',
  RemoveShareDeckWithType = '/api/decks/type/share/remove',
  OrderDeckCards = '/api/decks/order-cards',
  RequestAccess = '/api/user/request-access',
  SubscriptionPlansCheckoutSessions = '/api/subscription-plans/checkout-sessions',
  SubscriptionPlansCustomerPortal = '/api/subscription-plans/customer-portal',
  Subscriptions = '/api/user/subscription/subscriptions',
  SubscriptionProducts = '/api/user/subscription/products',
  RefreshSubscription = '/api/user/subscription/subscription/refresh',
  BenchmarkingNetSalesRate = '/api/benchmarking/net-sales/rate',
  BenchmarkingARRRate = '/api/benchmarking/arr/rate',
  BenchmarkingRetentionRate = '/api/benchmarking/retention/rate',
  BenchmarkingNetSalesForecast = '/api/benchmarking/net-sales/forecast',
  BenchmarkingARRForecast = '/api/benchmarking/arr/forecast',
  BenchmarkingRetentionForecast = '/api/benchmarking/retention/forecast',
  ValidationEntries = '/api/data-validation/validation-entries',
  ValidationChartData = '/api/data-validation/chart-data',
  ConnectorDateRange = '/api/data-validation/connector-date-range',
  ValidationStarted = '/api/data-validation/validation-started',
  ReasonCategory = '/api/data-validation/reason-category',
  ReasonFreeText = '/api/data-validation/reason-free-text',
  ReValidate = '/api/data-validation/re-validate',
  IssueIdentifier = '/api/data-validation/issue-identifier',
  InvestorNetworkRequests = '/api/investor-network/requests',
  InvestorNetworkRequest = '/api/investor-network/requests/[reference]',
  InvestorNetworkRequestPitchDeckUpload = '/api/investor-network/requests/[reference]/uploads/pitch-deck',
  InvestorNetworkRequestSubmit = '/api/investor-network/requests/[reference]/submit',
  InvestorNetworkRequestQualificationDecision = '/api/investor-network/requests/[reference]/qualifications/[qualificationReference]/decision',
  InvestorNetworkOptions = '/api/investor-network/options',
  Spaces = '/api/spaces',
  Space = '/api/spaces/[reference]',
  SpaceInsights = '/api/spaces/[reference]/insights',
  SpaceTemplates = '/api/templates/spaces',
  InsightTemplates = '/api/templates/insights',
}

export const ApiRoutes = { ...RoutesRequiringAuth };
export type ApiRoutes = (typeof ApiRoutes)[keyof typeof ApiRoutes];

export enum ExternalRoutes {
  NotificationSettings = '/tenants/{project_code}/{ark_id}/notifications/settings',
  Notifications = '/tenants/{project_code}/{ark_id}/notifications',
  Notification = '/tenants/{project_code}/{ark_id}/notifications/{notificationReference}',
  Connectors = '/tenants/{project_code}/connectors',
  CreateConnector = '/tenants/{project_code}/connectors/{connector_id}',
  ConnectorDefinitions = '/tenants/{project_code}/connectors/definitions',
  SuggestedConnectors = '/connectors/suggest',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  GetConnector = '/tenants/{project_code}/connectors/{connector_id}',
  SelectConnectors = '/tenants/{project_code}/connectors/select',
  DeleteConnector = '/tenants/{project_code}/connectors/{connector_id}/tombstone',
  UploadConnectorData = '/tenants/{project_code}/connectors/{connector_id}/upload_data',
  DisconnectConnector = '/tenants/{project_code}/connectors/{connector_id}/disconnect_connector',
  FinalizeConnector = '/tenants/{project_code}/connectors/{connector_id}/finalize',
  LimitDateRangeConnector = '/tenants/{project_code}/connectors/limit-date-range',
  InviteUser = '/tenants/{project_code}/users/invite',
  ChangeUserRole = '/tenants/{project_code}/users/{ark_id}/role',
  DeleteUser = '/tenants/{project_code}/users/{ark_id}',
  GetAllInvitedUsers = '/tenants/{project_code}/users?max_role_level={max_role_level}',
  Metric = '/metric',
  InsightsMetadata = '/tenants/{project_code}/insights/metadata',
  GetAnnotations = '/tenants/{project_code}/users/{user_reference}/annotations',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CreateAnnotation = '/{project_code}/annotations',
  UpdateAnnotation = '/{project_code}/annotations/{annotation_reference}',
  UpdateAnnotationReply = '/{project_code}/annotations/{annotation_reference}/replies/{reply_reference}',
  CreateAnnotationReply = '/{project_code}/annotations/{annotation_reference}/replies',
  ResetPassword = '/users/reset-password',
  VerifyUserByActionCode = '/tenant/user/verify',
  GetTenants = '/users/{ark_id}/tenants',
  TenantInfo = '/tenants/{project_code}',
  JoinDealChannel = '/tenants/{project_code}/users/{ark_id}/slack/join',
  RefreshTenantCache = '/tenants/{project_code}/cache/refresh',
  SyncATBD = '/business_data/sync/{project_code}',
  BusinessData = '/business_data?tenant_project_code={project_code}',
  BusinessDataSettings = '/business_data/settings?tenant_project_code={project_code}',
  ValidBusinessDataSettings = '/business_data/settings/valid',
  InvestmentCases = '/investment_cases?project_codes={project_code}',
  LoanApplication = '/loans/project/{project_code}/application',
  Loan = '/loans/project/{project_code}/loan/{application_id}',
  LoanApplicationDocument = '/loans/project/{project_code}/application/{application_id}/document',
  Loans = '/loans/project/{project_code}',
  LoanApplicationOptions = '/loans/options/valid',
  LoanStatus = '/loans/project/{project_code}/application/{application_id}/status',
  LoanApplicationActions = '/investment_cases/{reference}/actions',
  Scores = '/insights/scores',
  CovenantReport = '/covenants/reports/{project_code}/{report_id}',
  CovenantMetrics = '/covenants/metrics/{project_code}',
  PostCovenants = '/covenants/reports',
  GetCovenantSettings = '/covenants?project_code={project_code}',
  GetCovenantDocuments = '/covenants/reports/{project_code}/document/{report_id}',
  UploadCovenantDocument = '/covenants/reports/{project_code}/document',
  DownloadCovenantDocument = '/covenants/reports/{project_code}/document/{report_id}/{document_id}',
  CreateDeck = '/tenants/{project_code}/users/{ark_id}/decks',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Decks = '/tenants/{project_code}/users/{ark_id}/decks',
  AddCardToDeck = '/tenants/{project_code}/users/{ark_id}/decks/{deck_reference}/cards',
  EditCard = '/tenants/{project_code}/users/{ark_id}/decks/{deck_reference}/cards/{card_reference}',
  DeleteDeck = '/tenants/{project_code}/users/{ark_id}/decks/{deck_reference}',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  DeleteCard = '/tenants/{project_code}/users/{ark_id}/decks/{deck_reference}/cards/{card_reference}',
  ShareDeck = '/tenants/{project_code}/users/{ark_id_sharing}/decks/{deck_reference}/share',
  ShareDeckWithType = '/tenants/{project_code}/users/{ark_id_sharing}/decks/{deck_reference}/type/share',
  RemoveShareDeck = '/tenants/{project_code}/users/{ark_id}/decks/{deck_reference}/share/remove',
  RemoveShareDeckWithType = '/tenants/{project_code}/users/{ark_id}/decks/{deck_reference}/type/share/remove',
  OrderDeckCards = '/tenants/{project_code}/users/{ark_id}/decks/{deck_reference}/order',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  RenameDeck = '/tenants/{project_code}/users/{ark_id}/decks/{deck_reference}',
  RequestAccess = '/tenants/{project_code}/employees',
  TenantBySearch = '/tenants/search',
  ListSubscriptions = '/tenants/{project_code}/users/{ark_id}/subscriptions',
  ListSubscriptionProducts = '/tenants/{project_code}/users/{ark_id}/products',
  RefreshSubscription = '/tenants/{project_code}/users/{ark_id}/subscription/refresh',
  ValidationEntries = '/tenants/{project_code}/validation-entries',
  ValidationStarted = '/tenants/{project_code}/validation-entries/{reference}/validation-started',
  ReasonCategory = '/tenants/{project_code}/validation-entries/{reference}/reason-category',
  ReasonFreeText = '/tenants/{project_code}/validation-entries/{reference}/reason-free-text',
  ReValidate = '/tenants/{project_code}/validation-entries/{reference}/re-validate',
  IssueIdentifier = '/tenants/{project_code}/validation-entries/{reference}/issue-identifier',
  InvestorNetworkRequests = '/tenants/{project_code}/gin-requests',
  InvestorNetworkRequest = '/tenants/{project_code}/gin-requests/{reference}',
  InvestorNetworkRequestPitchDeckUpload = '/tenants/{project_code}/gin-requests/{reference}/uploads/pitch-deck',
  InvestorNetworkRequestSubmit = '/tenants/{project_code}/gin-requests/{reference}/submit',
  InvestorNetworkRequestQualificationDecision = '/tenants/{project_code}/gin-requests/{reference}/qualifications/{qualification_reference}/decision',
  InvestorNetworkOptions = '/tenants/{project_code}/gin-requests/options',
  Spaces = '/tenants/{project_code}/spaces',
  Space = '/tenants/{project_code}/spaces/{reference}',
  SpaceInsights = '/tenants/{project_code}/spaces/{reference}/insights',
  SpacesTemplates = '/tenants/{project_code}/templates/spaces',
  InsightTemplates = '/tenants/{project_code}/templates/insights',
}

type RouteReplacements<Route extends string> = Route extends `${string}{${infer Replacement}}${infer Tail}`
  ? Replacement | RouteReplacements<Tail>
  : never;

export const replaceRouteParameters = <Route extends ExternalRoutes>(
  route: Route,
  replacements: Record<RouteReplacements<Route>, string>,
): string => {
  return Object.entries<string>(replacements).reduce<string>((acc, [parameter, parameterValue]) => {
    return acc.replace(`{${parameter}}`, parameterValue);
  }, route);
};

export const LOCAL_BACK_ENDS = false;
