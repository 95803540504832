import type { ChartDataset } from '../types';
import { padArray } from '../utils/data-utils';

export const createAccumulatedYValues = (filteredDatasets: ChartDataset[], xValues: number[]) => {
  const paddedYValues: number[][] = filteredDatasets.map((dataset) => {
    return padArray({ array: dataset.data.map((value) => value.y ?? 0), length: xValues.length, fillWith: 0 });
  });

  const summedYValues = paddedYValues.reduce((acc, curr) => {
    curr.forEach((yValue, i) => {
      acc[i] = (acc[i] || 0) + yValue;
    });

    return acc;
  }, []);

  return summedYValues;
};

const LINE_WIDTH = 2;
const HANDLE_WIDTH = 3;
const HANDLE_RADIUS = 1.5;

export const adornerSvgStringTemplate = (x1: number, y1: number, x2: number, y2: number) => {
  const HEIGHT = y2 - y1;
  const HANDLE_HEIGHT = HEIGHT / 3;

  return `<svg xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="gradient" x1="0" x2="0" y1="0%" y2="100%">
        <stop offset="0%" stop-color="#ffffff" stop-opacity="0" />
        <stop offset="25%" stop-color="#ffffff" stop-opacity="1" />
        <stop offset="75%" stop-color="#ffffff" stop-opacity="1" />
        <stop offset="100%" stop-color="#ffffff" stop-opacity="0" />
      </linearGradient>
    </defs>

    <rect x="${x1}" y="${y1}" width="${x2 - x1}" height="${HEIGHT}" fill="#ffffff" opacity="0.05" />

    <rect x="${x1 + LINE_WIDTH - HANDLE_RADIUS * 0.9}" y="${HEIGHT / 2 - HANDLE_HEIGHT / 2}" width="${HANDLE_WIDTH}" height="${HANDLE_HEIGHT}" fill="white" stroke="black" rx="${HANDLE_RADIUS}" />
    <rect x="${x1}" y="${y1}" width="${LINE_WIDTH}" height="100%" fill="url(#gradient)" />
    
    <rect x="${x2 - LINE_WIDTH - HANDLE_WIDTH + HANDLE_RADIUS * 0.9}" y="${HEIGHT / 2 - HANDLE_HEIGHT / 2}" width="${HANDLE_WIDTH}" height="${HANDLE_HEIGHT}" fill="white" stroke="black" rx="${HANDLE_RADIUS}" />
    <rect x="${x2 - LINE_WIDTH}" y="${y1}" width="${LINE_WIDTH}" height="100%" fill="url(#gradient)" />
    </svg>`;
};

export const unSelectedArea = `<svg width="50" height="50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" style="display: block; opacity: 1; fill: #141414;">
<rect width="100%" height="100%"/>
</svg>`;
