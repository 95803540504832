import type { ReactElement } from 'react';
import React from 'react';

export function AreaChartSvg(): ReactElement {
  return (
    <svg
      width='calc(100% - 48px)'
      height='90%'
      viewBox='0 0 2326 721'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <g opacity='1'>
        <path
          d='M228.191 610.659L1 720H2325V318.055L2220.81 346.546L2025.46 375.036L1880.75 435.097L1714.33 448.957L1553.71 582.168L1163 532.888L792.549 596.799L731.772 564.458L523.393 675.339L228.191 610.659Z'
          fill='#103776'
        />
        <path
          d='M1 5.09104C1 2.83162 4.23888 1 8.23424 1H196.325C200.32 1 203.559 2.83162 203.559 5.09104V15.3186C203.559 17.5781 200.32 19.4097 196.325 19.4097H8.23424C4.23888 19.4097 1 17.5781 1 15.3186V5.09104Z'
          fill='#103776'
        />
        <path
          d='M1 35.7738C1 33.5144 4.23888 31.6828 8.23424 31.6828H579.739C583.735 31.6828 586.974 33.5144 586.974 35.7738V46.0014C586.974 48.2608 583.735 50.0925 579.739 50.0925H8.23424C4.23888 50.0925 1 48.2608 1 46.0014V35.7738Z'
          fill='#103776'
        />
        <path
          d='M2122.44 5.09104C2122.44 2.83162 2125.68 1 2129.68 1H2317.77C2321.76 1 2325 2.83162 2325 5.09104V15.3186C2325 17.5781 2321.76 19.4097 2317.77 19.4097H2129.68C2125.68 19.4097 2122.44 17.5781 2122.44 15.3186V5.09104Z'
          fill='#103776'
        />
        <path
          d='M228.191 610.659L1 720H2325V318.055L2220.81 346.546L2025.46 375.036L1880.75 435.097L1714.33 448.957L1553.71 582.168L1163 532.888L792.549 596.799L731.772 564.458L523.393 675.339L228.191 610.659Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M1 5.09104C1 2.83162 4.23888 1 8.23424 1H196.325C200.32 1 203.559 2.83162 203.559 5.09104V15.3186C203.559 17.5781 200.32 19.4097 196.325 19.4097H8.23424C4.23888 19.4097 1 17.5781 1 15.3186V5.09104Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M1 35.7738C1 33.5144 4.23888 31.6828 8.23424 31.6828H579.739C583.735 31.6828 586.974 33.5144 586.974 35.7738V46.0014C586.974 48.2608 583.735 50.0925 579.739 50.0925H8.23424C4.23888 50.0925 1 48.2608 1 46.0014V35.7738Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
        <path
          d='M2122.44 5.09104C2122.44 2.83162 2125.68 1 2129.68 1H2317.77C2321.76 1 2325 2.83162 2325 5.09104V15.3186C2325 17.5781 2321.76 19.4097 2317.77 19.4097H2129.68C2125.68 19.4097 2122.44 17.5781 2122.44 15.3186V5.09104Z'
          stroke='#0062FF'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
}
