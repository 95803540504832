import type { ChangeEvent, ReactElement } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import React, { useState } from 'react';
import '../Slider.css';
import { useResizeObserver } from 'aim-utils';

export interface SliderProps {
  min?: number;
  max: number;
  initialValue: number;
  displayTicks?: boolean;
  labels?: string[];
  steps?: number;
  getSliderValue: (value: number) => void;
}

export const SingleSlider = ({
  min = 0,
  max,
  initialValue,
  displayTicks = false,
  labels,
  getSliderValue,
  steps = 1,
}: SliderProps): ReactElement => {
  const [value, setValue] = useState(initialValue);
  const [sliderContainerRef, { width: sliderContainerWidth }] = useResizeObserver<HTMLDivElement>();

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue, value]);

  const updateValue = useCallback(
    (value: number) => {
      setValue(value);
      getSliderValue(value);
    },
    [getSliderValue],
  );

  return (
    <div className='sliderContainer singleSlider'>
      <input
        type='range'
        min={min}
        max={max}
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => updateValue(+event.target.value)}
        step={steps}
        className='thumb thumbRight'
      />
      <div className='slider' ref={sliderContainerRef}>
        <div className='sliderTrack' />
        <ul className='tickList'>
          {[...Array(max - min + 1)].map((x, i) =>
            displayTicks ? (
              <li
                key={i}
                className='tick cursorPointer'
                data-before={labels ? labels[i] : null}
                onClick={() => updateValue(i)}
              />
            ) : (
              <li
                style={{ width: sliderContainerWidth / (max - min + 1) }}
                key={i}
                className='clickableTick'
                onClick={() => updateValue(i)}
              />
            ),
          )}
        </ul>
      </div>
    </div>
  );
};
