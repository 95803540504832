import { useState } from 'react';

export interface D3TooltipProps {
  position: { x: number; y: number };
  title?: string;
  value?: number;
  label?: string;
  visible: boolean;
  color?: string;
}

export const useTooltip = (origin: { x: number; y: number }): [D3TooltipProps, (props: D3TooltipProps) => void] => {
  const [tooltip, setTooltip] = useState<D3TooltipProps>({ position: origin, visible: false });

  const updateTooltip = ({ position, ...props }: D3TooltipProps) => {
    setTooltip({
      ...props,
      position: { x: origin.x + position.x, y: origin.y + position.y },
    });
  };

  return [tooltip, updateTooltip];
};
