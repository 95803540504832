import { useContext } from 'react';
import { WizardContext } from './WizardContext';

export const useWizard = () => {
  const wizardContext = useContext(WizardContext);

  if (!wizardContext) {
    throw new Error('useWizard must be used within a WizardContext');
  }

  return wizardContext;
};
