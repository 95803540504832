import type { NextRouter } from 'next/router';

export function deleteRouteParameter(router: NextRouter, parameter: string) {
  const { pathname, query } = router;
  delete router.query[parameter];
  router.replace({ pathname, query }, undefined, { shallow: true });
}

export function addRouteParameter(router: NextRouter, parameter: string, value: string) {
  const { pathname, query } = router;
  router.query[parameter] = value;
  router.replace({ pathname, query }, undefined, { shallow: true });
}
