import type { Dispatch, ReactNode, SetStateAction } from 'react';
import React from 'react';
import { Icon, type IconName } from '../Icon';
import './SidePanel.css';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { useOutsideClick } from '../../utils/useOutsideClick';
import { useMobile } from 'aim-utils';
import { BottomSheet } from '../BottomSheet';
import { Popover } from '../Popover';
import { ContextMenu } from '../ContextMenu';

export interface SidePanelProps {
  isOpened: boolean;
  children: ReactNode;
  header: ReactNode;
  onClose: () => void;
}

export const SidePanel = ({ isOpened, children, header, onClose }: SidePanelProps) => {
  const ref = useOutsideClick(onClose);
  return (
    <div className='side-panel' data-is-open={isOpened} ref={ref}>
      {header}
      {children}
    </div>
  );
};

interface HeaderProps {
  onClose: () => void;
  title: ReactNode;
  menu?: ReactNode;
}

const Header = ({ onClose, title, menu }: HeaderProps) => {
  return (
    <div className='side-panel-top'>
      {title}
      <div className='side-panel-menu-button-wrapper'>
        {menu && menu}
        <Button endIcon={'close'} onClick={() => onClose()} size='large' color='secondary' type='tertiary' />
      </div>
    </div>
  );
};

interface TitleProps {
  icon: IconName;
  title: string;
  badge?: ReactNode;
}

const Title = ({ icon, title, badge }: TitleProps) => {
  return (
    <div className='side-panel-title'>
      <Icon icon={icon} size='small' />
      <Typography variant='label2'>{title}</Typography>
      {badge && badge}
    </div>
  );
};

interface SidePanelMenu {
  open: boolean;
  showMenu: Dispatch<SetStateAction<boolean>>;
  trigger: ReactNode;
  items: Array<{ title: string; action: () => void }>;
}

export const SidePanelMenu = ({ open, showMenu, trigger, items }: SidePanelMenu) => {
  const { mobileView } = useMobile();

  if (mobileView) {
    return (
      <>
        <div>{trigger}</div>
        <BottomSheet open={open} onClose={() => showMenu(false)}>
          <BottomSheet.Content>
            {items.map((item) => (
              <div
                key={item.title}
                onClick={async (e) => {
                  await item.action();
                  e.preventDefault();
                  e.stopPropagation();
                }}
                style={{ paddingBottom: 'var(--size-xl)' }}
              >
                {item.title}
              </div>
            ))}
          </BottomSheet.Content>
        </BottomSheet>
      </>
    );
  }

  return (
    <Popover
      open={open}
      onOpenChange={showMenu}
      placement='left-start'
      renderPopoverTrigger={(triggerProps) => <div {...triggerProps}>{trigger}</div>}
    >
      <ContextMenu>
        <ContextMenu.List>
          {items.map((item) => (
            <ContextMenu.ListItem
              key={item.title}
              label={item.title}
              onClick={(e) => {
                item.action();
                e.stopPropagation();
              }}
            />
          ))}
        </ContextMenu.List>
      </ContextMenu>
    </Popover>
  );
};

SidePanel.Header = Header;
SidePanel.Title = Title;
SidePanel.Menu = SidePanelMenu;
