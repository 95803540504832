import { createTimeGranularityTuple } from '@/components/insights/charts/common/types';
import type { BaseDataPoint } from '../utils';

export const NewCustomerVsMarketingDataset = {
  NewCustomers: 'dataset-1',
  OpexMarketingDirect: 'dataset-2',
  OpexMarketingOther: 'dataset-3',
} as const;

export const NewCustomerVsMarketingDatasets = Object.values(NewCustomerVsMarketingDataset);

export type NewCustomerVsMarketingDatasetType = (typeof NewCustomerVsMarketingDatasets)[number];

export interface NewCustomersMetric {
  [NewCustomerVsMarketingDataset.NewCustomers]: BaseDataPoint[];
  [NewCustomerVsMarketingDataset.OpexMarketingDirect]: BaseDataPoint[];
  [NewCustomerVsMarketingDataset.OpexMarketingOther]: BaseDataPoint[];
}

export interface NewCustomersVsMarketingResponse {
  meta: { displayUnit: string; xMin: string; xMax: string };
  metrics: NewCustomersMetric;
}

export interface NewCustomersArrowRow {
  date_day?: string;
  date_month?: string;
  date_quarter?: string;
  new_customers: number | null;
  cost_opex_marketing: number | null;
  cost_opex_marketing_direct: number | null;
}

export const NEW_CUSTOMERS_TIME_GRANULARITIES = createTimeGranularityTuple('quarters', 'months', 'days');
