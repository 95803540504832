import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import type { FilterPresetKey } from '@/components/insights/charts/common/utils';

export enum LocalStorageKey {
  FINISHED_STORIES = 'finished-stories',
  DISMISSED_CONNECT_DATA_LOAN = 'dismissed-connect-data',
  MEMORY_DEBUGGER_ENABLED = 'memory-debugger-enabled',
}

export const useLocalStorage = <T,>(
  key: LocalStorageKey | FilterPresetKey,
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>] => {
  const [localStorageValue, updateLocalStorageValue] = useState<T>(() => {
    return localStorage.getItem(key) !== null ? JSON.parse(localStorage.getItem(key) as string) : initialValue;
  });

  const setLocalStorageValue = useCallback(
    (value: SetStateAction<T>): void => {
      const newValue = value instanceof Function ? value(localStorageValue) : value;
      localStorage.setItem(key, JSON.stringify(newValue));
      updateLocalStorageValue(newValue);
    },
    [key, localStorageValue],
  );

  return [localStorageValue, setLocalStorageValue];
};
