import { useState } from 'react';
import { SettingsMenuMobile } from '@/components/layouts/navigation/navigation-menu/SettingsMenu.mobile';
import { ProfileMenuTrigger } from '@/components/layouts/navigation/navigation-menu/user-profile/ProfileMenuTrigger';

type NavigationMenuUserProfileMobileProps = {
  expanded: boolean;
};

export const NavigationMenuUserProfileMobile = ({ expanded }: NavigationMenuUserProfileMobileProps) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  return (
    <>
      <ProfileMenuTrigger
        expanded={expanded}
        icon={showProfileMenu ? 'dropdown-up' : 'dropdown-down'}
        onClick={() => setShowProfileMenu((prev) => !prev)}
      />

      {showProfileMenu && (
        <SettingsMenuMobile showProfileMenu={showProfileMenu} setShowProfileMenu={setShowProfileMenu} />
      )}
    </>
  );
};
