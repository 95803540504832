/* eslint-disable camelcase */

import type { BaseDataPoint } from '../utils';

export type AnnualisedRecurringRevenueMetrics = keyof typeof annualisedRecurringRevenueDatasetMap;
export type MonhtlyRecurringRevenueMetrics = keyof typeof monthlyRecurringRevenueDatasetMap;
export type NumberOfRecurringCustomersMetrics = keyof typeof numberOfRecurringCustomersDatasetMap;

export enum AnnualisedRevenueMetric {
  AnnualisedRecurringRevenue = 'metric-1',
  MonthlyRecurringRevenue = 'metric-2',
  NumberOfRecurringCustomers = 'metric-3',
}

export const AnnualisedRevenueDataset = {
  Total: 'dataset-1',
  Retained: 'dataset-2',
  New: 'dataset-3',
  Reactivated: 'dataset-4',
  Expanded: 'dataset-5',
  Shrinkage: 'dataset-6',
  Churn: 'dataset-7',
} as const;

export const AnnualisedRevenueDatasets = Object.values(AnnualisedRevenueDataset);

export type AnnualisedRevenueDatasetType = (typeof AnnualisedRevenueDatasets)[number];

export const annualisedRecurringRevenueDatasetMap = {
  [AnnualisedRevenueDataset.Total]: 'annualized_subscription_sales_net_run_rate',
  [AnnualisedRevenueDataset.Retained]: 'annualized_subscription_sales_net_run_rate_retained',
  [AnnualisedRevenueDataset.New]: 'annualized_subscription_sales_net_run_rate_increase_from_new_customers',
  [AnnualisedRevenueDataset.Reactivated]:
    'annualized_subscription_sales_net_run_rate_increase_from_reactivated_customers',
  [AnnualisedRevenueDataset.Expanded]: 'annualized_subscription_sales_net_run_rate_increase_from_existing_customers',
  [AnnualisedRevenueDataset.Shrinkage]: 'annualized_subscription_sales_net_run_rate_decrease_from_existing_customers',
  [AnnualisedRevenueDataset.Churn]: 'annualized_subscription_sales_net_run_rate_decrease_from_churned_customers',
} as const;

export const monthlyRecurringRevenueDatasetMap = {
  [AnnualisedRevenueDataset.Total]: 'monthly_subscription_sales_net_run_rate',
  [AnnualisedRevenueDataset.Retained]: 'monthly_subscription_sales_net_run_rate_retained',
  [AnnualisedRevenueDataset.New]: 'monthly_subscription_sales_net_run_rate_increase_from_new_customers',
  [AnnualisedRevenueDataset.Reactivated]: 'monthly_subscription_sales_net_run_rate_increase_from_reactivated_customers',
  [AnnualisedRevenueDataset.Expanded]: 'monthly_subscription_sales_net_run_rate_increase_from_existing_customers',
  [AnnualisedRevenueDataset.Shrinkage]: 'monthly_subscription_sales_net_run_rate_decrease_from_existing_customers',
  [AnnualisedRevenueDataset.Churn]: 'monthly_subscription_sales_net_run_rate_decrease_from_churned_customers',
} as const;

export const numberOfRecurringCustomersDatasetMap = {
  [AnnualisedRevenueDataset.Total]: 'customers_with_subscription_sales_net_run_rate',
  [AnnualisedRevenueDataset.Retained]: 'customers_with_retained_annualized_subscription_sales_net_run_rate',
  [AnnualisedRevenueDataset.Expanded]: 'customers_with_increased_annualized_subscription_sales_net_run_rate',
  [AnnualisedRevenueDataset.Reactivated]: 'customers_with_reactivated_annualized_subscription_sales_net_run_rate',
  [AnnualisedRevenueDataset.New]: 'customers_with_new_annualized_subscription_sales_net_run_rate',
  [AnnualisedRevenueDataset.Churn]: 'customers_with_churned_annualized_subscription_sales_net_run_rate',
  [AnnualisedRevenueDataset.Shrinkage]: 'customers_with_decreased_annualized_subscription_sales_net_run_rate',
} as const;

export interface AnnualisedRevResponse {
  meta: { displayUnit: string; xMin?: string; xMax?: string };
  metrics: Record<AnnualisedRevenueMetric, Record<AnnualisedRevenueDatasetType, BaseDataPoint[]>>;
}

export interface AnnualisedRevenueArrowRow {
  annualized_subscription_sales_net_run_rate: number | null; // total
  annualized_subscription_sales_net_run_rate_retained: number | null; // retained
  annualized_subscription_sales_net_run_rate_increase_from_existing_customers: number | null;
  annualized_subscription_sales_net_run_rate_decrease_from_existing_customers: number | null;
  annualized_subscription_sales_net_run_rate_increase_from_reactivated_customers: number | null;
  annualized_subscription_sales_net_run_rate_decrease_from_churned_customers: number | null;
  annualized_subscription_sales_net_run_rate_increase_from_new_customers: number | null;

  monthly_subscription_sales_net_run_rate: number | null;
  monthly_subscription_sales_net_run_rate_retained: number | null;
  monthly_subscription_sales_net_run_rate_increase_from_existing_customers: number | null;
  monthly_subscription_sales_net_run_rate_decrease_from_existing_customers: number | null;
  monthly_subscription_sales_net_run_rate_increase_from_reactivated_customers: number | null;
  monthly_subscription_sales_net_run_rate_decrease_from_churned_customers: number | null;
  monthly_subscription_sales_net_run_rate_increase_from_new_customers: number | null;

  customers_with_subscription_sales_net_run_rate: number | null;
  customers_with_retained_annualized_subscription_sales_net_run_rate: number | null;
  customers_with_increased_annualized_subscription_sales_net_run_rate: number | null;
  customers_with_decreased_annualized_subscription_sales_net_run_rate: number | null;
  customers_with_reactivated_annualized_subscription_sales_net_run_rate: number | null;
  customers_with_churned_annualized_subscription_sales_net_run_rate: number | null;
  customers_with_new_annualized_subscription_sales_net_run_rate: number | null;

  date_quarter: string;
  date_month: string;
}
