/**
 * Returns a new URL after switching tenants. It takes the static parts of the route, until the first dynamic part, if any.
 *
 * @example
 * const result = getNewUrlAfterTenantSwitch({ slug: 'new-slug', route: '/[slug]/data/connectors/[id]' });
 * // 👆 /new-slug/data/connectors/
 */
export const getNewUrlAfterTenantSwitch = ({ slug, route }: { slug: string; route: string }) => {
  const results = route.match(/\/\[slug]\/([\w-/]+)/);

  if (!results) return `/${slug}`;

  const [_, baseRoute] = results;

  return `/${slug}/${baseRoute}`;
};
