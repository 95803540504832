export const getUniqueMentionedIds = (mentions: Array<{ id: string }>) => {
  const mentionedIds = mentions.map(({ id }) => id);
  const uniqueMentionedIds = [...new Set(mentionedIds)];
  return uniqueMentionedIds;
};

/**
 * Removes display values for mentions from the provided string, only leaving the mention IDs.
 *
 * @example `Hello @{{John Doe}}{{mention:123}} => Hello {{mention:123}}`
 */
export const removeDisplayValueMentionsFromString = (value: string) => {
  return value.replaceAll(/@{{[^}]+}}/g, '');
};
