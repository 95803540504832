import { Button, Typography } from 'aim-components';
import styles from './GeneralError.module.css';
import { show } from '@/components/common/external/Intercom';
import { useEffect } from 'react';
import { useMobile } from 'aim-utils';
import { FSErrorPage } from '@/lib/fullstory';

const GeneralError = ({ errorSource }: { errorSource: string }) => {
  const mobileView = useMobile();
  useEffect(() => {
    !mobileView && show();
  }, [mobileView]);

  useEffect(() => {
    FSErrorPage(window.location.href, errorSource);
  }, [errorSource]);

  return (
    <>
      <div>
        <Typography variant='text1'>
          Something went wrong. We are working to fix it, in the meantime you can try to refresh the page or go back
          home.
          <br />
          <br />
          If you need any help, reach out to us via the chat.
        </Typography>
      </div>
      <div className={styles.buttonWrapper}>
        <a href={window.location.origin}>
          <Button type='primary'>Go back home</Button>
        </a>
      </div>
    </>
  );
};

export default GeneralError;
