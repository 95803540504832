import { useCallback, useMemo } from 'react';
import type { Option } from '../../Input/Select/Select';
import { Button } from '../../Button';
import { SelectOption } from '../../Input/Select/SelectOption';
import selectStyles from '../../Input/Select/Select.module.css';
import styles from './MetricSelect.module.css';
import { useSelect } from '../../Input/Select/hooks';
import { FloatingPortal, useDismiss, useInteractions } from '@floating-ui/react';
import cn from 'classnames';

export interface MetricSelectMobileProps<TMetricValue extends string = string> {
  options: ReadonlyArray<Option<TMetricValue>>;
  selectedOption: Option<TMetricValue> | TMetricValue;
  setSelectedOption: (option: Option<TMetricValue>) => void;
}

export const MetricSelectMobile = <TMetricValue extends string = string>({
  options,
  selectedOption: _selectedMetric,
  setSelectedOption,
}: MetricSelectMobileProps<TMetricValue>) => {
  const { showOptions, setShowOptions, refs, floatingStyles, context } = useSelect({ placement: 'bottom' });

  const selectedOption = useMemo(
    () =>
      typeof _selectedMetric === 'string' ? options.find(({ value }) => value === _selectedMetric) : _selectedMetric,
    [_selectedMetric, options],
  );

  const onSelect = useCallback(
    (option: Option<TMetricValue>) => {
      setSelectedOption(option);
      setShowOptions(false);
    },
    [setSelectedOption, setShowOptions],
  );

  const maxLabelLength = useMemo(() => Math.max(...options.map(({ label }) => label.length)), [options]);

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  return (
    <>
      <div
        className={styles.metricSelectMobile}
        style={{
          minWidth: `min(${maxLabelLength}ch, 100%)`,
        }}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <Button
          endIcon={showOptions ? 'dropdown-up' : 'dropdown-down'}
          onClick={() => setShowOptions(!showOptions)}
          type='tertiary'
          color='white'
          className={styles.metricSelectButton}
        >
          <span className={styles.buttonLabel}>{selectedOption?.label}</span>
        </Button>
      </div>

      {showOptions && (
        <FloatingPortal>
          <ul
            className={cn(selectStyles.options, styles.metricSelectOptions)}
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={floatingStyles}
          >
            {options.map((option) => (
              <SelectOption
                key={option.value}
                option={option}
                isSelected={option.value === selectedOption?.value}
                onSelect={onSelect}
                condensed
                disabled={option.disabled}
              />
            ))}
          </ul>
        </FloatingPortal>
      )}
    </>
  );
};
