import { useCallback, useEffect } from 'react';

type EventData = {
  annotationHovered: { annotationId: string | null };
  annotationOpened: { annotationId: string | null };
  navigationMenuTransitionChanged: { done: boolean };
};

export const useCustomEventDispatcher = <EventName extends keyof EventData>(eventName: EventName) => {
  const dispatchCustomEvent = useCallback(
    (data: EventData[EventName]) => {
      document.dispatchEvent(new CustomEvent(eventName, { detail: data }));
    },
    [eventName],
  );

  return dispatchCustomEvent;
};

export const useCustomEventListener = <EventName extends keyof EventData>(
  eventName: EventName,
  handler: (event: CustomEvent<EventData[EventName]>) => void,
): void => {
  useEffect(() => {
    document.addEventListener(eventName, handler as EventListener);

    return () => {
      document.removeEventListener(eventName, handler as EventListener);
    };
  }, [eventName, handler]);
};
