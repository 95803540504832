import type { ReactElement } from 'react';
import { BarChartAnimation } from './bar-chart/BarChartAnimation';
import { LineChartAnimation } from './line-chart/LineChartAnimation';
import { StackedChartAnimation } from './stacked-chart/StackedChartAnimation';

export interface MissingDataChartAnimationProps {
  size: 'large' | 'small';
  type: 'bar' | 'line' | 'stack';
}
export interface ChartAnimationProps {
  size: 'large' | 'small';
  svgHeight: number;
  svgWidth: number;
}

export function MissingDataChartAnimation({ size, type }: MissingDataChartAnimationProps): ReactElement {
  const [svgHeight, svgWidth] = [65, 150];

  switch (type) {
    case 'bar':
      return <BarChartAnimation size={size} svgHeight={svgHeight} svgWidth={svgWidth} />;
    case 'line':
      return <LineChartAnimation size={size} svgHeight={svgHeight} svgWidth={svgWidth} />;
    case 'stack':
      return <StackedChartAnimation size={size} svgHeight={svgHeight} svgWidth={svgWidth} />;
    default:
      return <BarChartAnimation size={size} svgHeight={svgHeight} svgWidth={svgWidth} />;
  }
}
