import type { ReactNode } from 'react';
import styles from './ChartFooter.mobile.module.css';

interface ChartFooterMobileProps {
  children: ReactNode;
}

export const ChartFooterMobile = ({ children }: ChartFooterMobileProps) => {
  return <div className={styles.chartFooter}>{children}</div>;
};
