import type { ReactElement, ReactNode, MouseEvent, ComponentProps } from 'react';
import React from 'react';
import cn from 'classnames';

import './Card.css';

export interface CardProps extends ComponentProps<'div'> {
  children: ReactNode;
  disabled?: boolean;
  transparent?: boolean;
  onClick?: (e: MouseEvent) => void;
}

export function Card({ children, disabled, transparent, onClick, className, ...props }: CardProps): ReactElement {
  return (
    <div
      className={cn('Card', { disabled, transparent, clickable: !disabled && onClick !== undefined }, className)}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      <div className='Card-content'>{children}</div>
    </div>
  );
}
