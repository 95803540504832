import type { ReactElement } from 'react';
import React from 'react';
import { LoadingShimmer } from '../Shimmer/LoadingShimmer';
import { BarChartSvg, AreaChartSvg } from './ChartSvg';
import './ChartLoader.css';

type ChartLoaderProps = {
  type: 'area' | 'bar';
};

const chartSvgMap: Record<ChartLoaderProps['type'], ReactElement> = {
  area: <AreaChartSvg />,
  bar: <BarChartSvg />,
} as const;

export function ChartLoader({ type }: ChartLoaderProps): ReactElement {
  const svg: ReactElement = chartSvgMap[type];

  return (
    <div className='Loader' aria-label='Chart loader'>
      <LoadingShimmer>{svg}</LoadingShimmer>
    </div>
  );
}
