import { CommonValidator, validateRequestQuery } from '@/api/validators';
import { arrowRequest } from '@/lib/request/request';
import { getAimHeaders } from '@/lib/utils';
import type { NextApiRequest, NextApiResponse } from 'next';
import { z } from 'zod';
import type { BaseDataPoint } from '../utils';
import { getDimension, mapTimeGranularityToDateDimension } from '../utils';
import { handleApiError } from '@/lib/request/error';
import {
  CUSTOMER_GROWTH_RATE_WINDOW_SIZES,
  CUSTOMER_GROWTH_RATE_TIME_GRANULARITIES,
  type CustomerGrowthRateArrowRow,
  getMetricYoY,
  getMetricBase,
  getMetricMoM,
} from './utils';

const customerGrowthRateQuerySchema = z.object({
  countryCode: CommonValidator.countryCode,
  timeGranularity: z.enum(CUSTOMER_GROWTH_RATE_TIME_GRANULARITIES),
  windowSize: z.enum(CUSTOMER_GROWTH_RATE_WINDOW_SIZES),
});

export enum CustomerGrowthRateMetric {
  NewCustomers = 'metric-1',
  ActiveCustomers = 'metric-2',
}

export const CUSTOMER_GROWTH_RATE_GROWTH_METRIC = [
  'yearOverYear',
  'yearOverYearRef',
  'quarterOverQuarter',
  'quarterOverQuarterRef',
  'monthOverMonthRef',
  'monthOverMonth',
] as const;

export type CustomerGrowthRateGrowthMetric = (typeof CUSTOMER_GROWTH_RATE_GROWTH_METRIC)[number];

type GrowthRateMetrics = Record<CustomerGrowthRateGrowthMetric, BaseDataPoint[]> & {
  baseMetric: BaseDataPoint[];
};

export interface CustomerGrowthRateResponse {
  meta: { displayUnit: string; xMin: string; xMax: string };
  metrics: Record<CustomerGrowthRateMetric, GrowthRateMetrics>;
}

const getRowTimestamp = (row: CustomerGrowthRateArrowRow) => {
  if ('date_quarter' in row) return row.date_quarter;
  if ('date_month' in row) return row.date_month;
  return row.date_day;
};

const convertToPercentage = (value: bigint | null) => {
  if (value === null) return null;
  return (Number(value) - 1) * 100; // Ask Maria why -1
};

export default async function handler(req: NextApiRequest, res: NextApiResponse): Promise<void> {
  const { projectCode } = getAimHeaders(req.headers);

  const { parsedQuery } = validateRequestQuery(customerGrowthRateQuerySchema, req);
  if (!parsedQuery.success) return parsedQuery.sendInvalidQueryResponse(res);

  const { countryCode, timeGranularity, windowSize } = parsedQuery.data;

  const url = new URL(
    `${process.env.NEXT_PUBLIC_DATA_EXTRACTOR_URL}/tenants/${projectCode}/insights/customer-growth-rate`,
  );
  url.searchParams.set('country_code', countryCode);
  url.searchParams.set('granularity', timeGranularity);
  url.searchParams.set('window_size', windowSize);

  const urlReference = new URL(
    `${process.env.NEXT_PUBLIC_DATA_EXTRACTOR_URL}/tenants/${projectCode}/insights/customer-growth-rate`,
  );
  urlReference.searchParams.set('country_code', 'ALL');
  urlReference.searchParams.set('granularity', timeGranularity);
  urlReference.searchParams.set('window_size', windowSize);

  try {
    const table = await arrowRequest(url.toString());
    const tableArray = table.toArray();

    const tableReferenceData = await arrowRequest(urlReference.toString());
    const tableArrayReference = tableReferenceData.toArray();

    const response: CustomerGrowthRateResponse = {
      meta: {
        displayUnit: '',
        ...getDimension(table, mapTimeGranularityToDateDimension(timeGranularity)),
      },
      metrics: {
        [CustomerGrowthRateMetric.NewCustomers]: {
          yearOverYear: [],
          yearOverYearRef: [],
          monthOverMonth: [],
          monthOverMonthRef: [],
          quarterOverQuarter: [],
          quarterOverQuarterRef: [],
          baseMetric: [],
        },
        [CustomerGrowthRateMetric.ActiveCustomers]: {
          yearOverYear: [],
          yearOverYearRef: [],
          monthOverMonth: [],
          monthOverMonthRef: [],
          quarterOverQuarter: [],
          quarterOverQuarterRef: [],
          baseMetric: [],
        },
      },
    };

    tableArray.forEach((row: CustomerGrowthRateArrowRow) => {
      const date = new Date(getRowTimestamp(row)).toISOString();

      response.metrics[CustomerGrowthRateMetric.ActiveCustomers].yearOverYear.push({
        date,
        value: convertToPercentage(row.active_customer_growth_rate_year_over_year),
      });
      response.metrics[CustomerGrowthRateMetric.ActiveCustomers].baseMetric.push({
        date,
        value: Number(row.active_customers),
      });

      response.metrics[CustomerGrowthRateMetric.NewCustomers].yearOverYear.push({
        date,
        value: convertToPercentage(row[getMetricYoY(windowSize, timeGranularity, 'new_customers')]),
      });
      response.metrics[CustomerGrowthRateMetric.NewCustomers].baseMetric.push({
        date,
        value: Number(row[getMetricBase(windowSize, timeGranularity, 'new_customers')]),
      });

      if ('date_quarter' in row) {
        response.metrics[CustomerGrowthRateMetric.ActiveCustomers].quarterOverQuarter.push({
          date,
          value: convertToPercentage(row.active_customer_growth_rate_quarter_over_quarter),
        });

        response.metrics[CustomerGrowthRateMetric.NewCustomers].quarterOverQuarter.push({
          date,
          value: convertToPercentage(row.new_customers_growth_rate_quarter_over_quarter),
        });
      }

      if ('date_month' in row || 'date_day' in row) {
        response.metrics[CustomerGrowthRateMetric.ActiveCustomers].monthOverMonth.push({
          date,
          value: convertToPercentage(row.active_customer_growth_rate_month_over_month),
        });

        response.metrics[CustomerGrowthRateMetric.NewCustomers].monthOverMonth.push({
          date,
          value: convertToPercentage(row[getMetricMoM(windowSize, timeGranularity, 'new_customers')]),
        });
      }
    });

    tableArrayReference.forEach((row: CustomerGrowthRateArrowRow) => {
      const date = new Date(getRowTimestamp(row)).toISOString();

      response.metrics[CustomerGrowthRateMetric.ActiveCustomers].yearOverYearRef.push({
        date,
        value: convertToPercentage(row.active_customer_growth_rate_year_over_year),
      });

      response.metrics[CustomerGrowthRateMetric.NewCustomers].yearOverYearRef.push({
        date,
        value: convertToPercentage(row[getMetricYoY(windowSize, timeGranularity, 'new_customers')]),
      });

      if ('date_quarter' in row) {
        response.metrics[CustomerGrowthRateMetric.ActiveCustomers].quarterOverQuarterRef.push({
          date,
          value: convertToPercentage(row.active_customer_growth_rate_quarter_over_quarter),
        });

        response.metrics[CustomerGrowthRateMetric.NewCustomers].quarterOverQuarterRef.push({
          date,
          value: convertToPercentage(row.new_customers_growth_rate_quarter_over_quarter),
        });
      }

      if ('date_month' in row || 'date_day' in row) {
        response.metrics[CustomerGrowthRateMetric.ActiveCustomers].monthOverMonthRef.push({
          date,
          value: convertToPercentage(row.active_customer_growth_rate_month_over_month),
        });

        response.metrics[CustomerGrowthRateMetric.NewCustomers].monthOverMonthRef.push({
          date,
          value: convertToPercentage(row[getMetricMoM(windowSize, timeGranularity, 'new_customers')]),
        });
      }
    });

    res.status(200).json(response);
  } catch (error) {
    handleApiError(res, error);
  }
}
