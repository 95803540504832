import React, { useContext, useState } from 'react';
import { Alert, HeaderFooterModal, Select, TextInput, Typography } from 'aim-components';
import { useTranslation } from 'next-i18next';
import { mutate } from 'swr';
import { useInviteUser } from '../hooks';
import type { Option } from '../types';
import { validateEmail, validateName } from '../validations';
import { UserContext } from '@/components/authentication/UserContext';
import { ApiRoutes } from 'pages/apiRoutes';
import type { Role } from '@/lib/permissions/role';
import { useRoleOptions, useUserPermissions } from '@/lib/permissions/hooks';
import { isGilionEmployee } from '@/lib/permissions/role';
import styles from './InviteUserModal.module.css';

interface InviteUserModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const InviteUserModal = ({ showModal, setShowModal }: InviteUserModalProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'inviteUser' });
  const userContext = useContext(UserContext);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const roleOptions = useRoleOptions();
  const [role, setRole] = useState<Option | undefined>(roleOptions.find((role) => role.value === 'OWNER'));
  const sendInvite = useInviteUser();
  const [validationErrors, setValidationErrors] = useState<{ name?: boolean; email?: boolean }>({});
  const { hasAdminPrivileges } = useUserPermissions();

  const gilionUserNotice = isGilionEmployee(email) && !hasAdminPrivileges;

  const resetModal = (): void => {
    setFullName('');
    setEmail('');
    setValidationErrors({});
    setRole(role);
    setShowModal(false);
  };

  const title = hasAdminPrivileges ? t('adminHeader', { tenant: userContext.tenant }) : t('header');

  return (
    <HeaderFooterModal
      onClose={() => resetModal()}
      open={showModal}
      title={title}
      primaryButton={{
        title: t('sendInvite'),
        onClick: async () => {
          await sendInvite(fullName, email, role?.value as Role);
          mutate(ApiRoutes.GetAllInvitedUsers);
          resetModal();
        },
        disabled: validationErrors.name != false || validationErrors.email != false,
      }}
    >
      <div className={styles.container}>
        <TextInput
          onChange={(e) => {
            setFullName(e.target.value);
            validateName(e.target.value, (errors) => setValidationErrors(errors), validationErrors);
          }}
          value={fullName}
          isValid={validationErrors.name == true ? false : undefined}
          label={t('whatIsTheirName')}
        />
        <TextInput
          onChange={(e) => {
            setEmail(e.target.value);
            validateEmail(e.target.value, (errors) => setValidationErrors(errors), validationErrors);
          }}
          value={email}
          isValid={validationErrors.email ? false : undefined}
          label={t('andTheirEmail')}
        />
        {hasAdminPrivileges && (
          <>
            {gilionUserNotice && <Alert type='warning' text={t('gilionUserNotice')} />}
            <Select
              setSelectedOption={(role) => role && setRole(role)}
              selectedOption={role}
              options={roleOptions}
              label={t('whichPermissions')}
            />
            <br />
            <Typography variant='text2' color='secondary'>
              {t('roleInfo')}
            </Typography>
          </>
        )}
      </div>
    </HeaderFooterModal>
  );
};

export default InviteUserModal;
