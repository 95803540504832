import type { ReactNode } from 'react';
import React from 'react';
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import styles from './ModalOverlay.module.css';

type ModalOverlayProps = {
  onClose?: () => void;
  children: ReactNode;
  open: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const ModalOverlay = ({ children, onClose, open, ...rest }: ModalOverlayProps) => {
  const { refs, context } = useFloating({ open, onOpenChange: onClose });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([click, role, dismiss]);

  return (
    <FloatingPortal>
      {open && (
        <FloatingOverlay className={styles.backdrop} lockScroll {...rest}>
          <FloatingFocusManager context={context} modal>
            <div ref={refs.setFloating} {...getFloatingProps()} className={styles.contentContainer}>
              {children}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
};
