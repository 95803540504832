import React, { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import './../d3Chart.css';
import { useElementDimensions } from '../../../utils/useElementDimensions';
import type { D3ChartData, D3ChartProps, D3LineChartConfig } from '../types';
import AnimatedLine from './AnimatedLine';
import { YAxis, XAxis } from './components/Axis';
import Background from './components/Background';
import { ChartDescription } from './components/ChartDescription';
import { DataHoverCircles } from './components/DataHoverCircles';
import DataLabels from './components/DataLabels';
import LimitLine from './components/LimitLine';
import { useLineChartTooltip, useScales } from './hooks';
import { useMobile } from 'aim-utils';

const LineChart = ({
  datasets,
  chartConfig,
  TooltipComponent,
}: D3ChartProps<D3ChartData, D3LineChartConfig>): ReactElement => {
  const [chartContainerRef, svgWidth, svgHeight] = useElementDimensions();
  const chartLegendSize = chartConfig?.descriptionText ? 50 : 0;
  const { mobileView } = useMobile();

  const margin = chartConfig?.chartOnly
    ? { top: 20, right: 0, bottom: 0, left: 0 }
    : mobileView
      ? { top: 10, right: 55, bottom: 40, left: 40 }
      : { top: 30, right: 55, bottom: 40, left: 60 };

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const labels = datasets.flatMap((item) => item.data).map((item) => item?.x);

  const [xScale, yScale] = useScales(width, height, datasets, chartConfig, labels);

  const enableTooltip = TooltipComponent !== undefined;
  const [containerRef, tooltipData, tooltipLeft = 0, tooltipTop = 0, tooltipOpen, handlePointerMove, handlePointerOut] =
    useLineChartTooltip(datasets, labels, margin, xScale, yScale, enableTooltip);

  useEffect(() => {
    svgHeight && setHeight(svgHeight - margin.top - margin.bottom - chartLegendSize);
    svgWidth && setWidth(svgWidth - margin.left - margin.right);
  }, [chartLegendSize, margin.bottom, margin.left, margin.right, margin.top, svgHeight, svgWidth]);

  const shouldDisplayTooltip = TooltipComponent && tooltipOpen && tooltipData !== undefined;

  return (
    <div className='Chart-canvasContainer' ref={chartContainerRef}>
      <svg width={svgWidth} height={svgHeight}>
        <g transform={`translate(${margin.left},${margin.top})`}>
          {xScale && yScale && margin && (
            <>
              {!chartConfig.chartOnly && <YAxis width={width} yScale={yScale} unit={chartConfig.unit ?? ''} />}
              {!chartConfig.chartOnly && (
                <XAxis width={width} height={height} xScale={xScale} chartConfig={chartConfig} labels={labels} />
              )}
              {chartConfig?.limitLine && (
                <LimitLine width={width} limit={chartConfig.limitLine.value} yScale={yScale} />
              )}

              <g ref={containerRef} onPointerMove={handlePointerMove} onPointerOut={handlePointerOut}>
                <Background chartConfig={chartConfig} width={width} yScale={yScale} />
              </g>
              {datasets?.map((dataset) => (
                <g key={dataset.label}>
                  <AnimatedLine
                    dataset={dataset}
                    xScale={xScale}
                    yScale={yScale}
                    xMin={chartConfig.xAxis?.min}
                    xMax={chartConfig.xAxis?.max}
                  />
                  <DataLabels dataset={dataset} xScale={xScale} yScale={yScale} />
                </g>
              ))}
              {shouldDisplayTooltip && <DataHoverCircles dataPoints={tooltipData.dataPoints} />}
            </>
          )}
        </g>
        {chartConfig?.descriptionText && (
          <ChartDescription
            margin={margin}
            width={width}
            svgHeight={svgHeight}
            chartLegendSize={chartLegendSize}
            lineChartConfig={chartConfig}
          />
        )}
      </svg>

      {shouldDisplayTooltip && (
        <div
          className='TooltipContainer'
          key={tooltipData.dataIndex}
          style={{
            position: 'absolute',
            transform:
              tooltipLeft > width / 2
                ? `translate(calc(${tooltipLeft + margin.left}px - 100%), ${tooltipTop - svgHeight}px)`
                : `translate(${tooltipLeft + margin.left}px, ${tooltipTop - svgHeight}px)`,
          }}
        >
          <TooltipComponent dataPoints={tooltipData.dataPoints} />
        </div>
      )}
    </div>
  );
};
export default LineChart;
