import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import { formatValue } from '../../../../utils';
import { getRootCssVariable } from '../../../../utils/utils';
import type { D3ChartDataset } from '../../types';

interface DataLabelsProps {
  dataset: D3ChartDataset;
  xScale: d3.ScalePoint<string>;
  yScale: d3.ScaleLinear<number, number, never>;
}

const DataLabels = ({ dataset, xScale, yScale }: DataLabelsProps) => {
  return (
    <>
      {dataset.dataLabels?.map((point) => {
        const dataPoint = dataset.data[typeof point === 'number' ? point : point.index];
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const style = useSpring({
          config: {
            duration: 500,
          },
          transform: `translate(${xScale(dataPoint.x)}, ${yScale(dataPoint.y)})`,
        });
        return (
          <animated.g {...style} key={`${dataset.label}${point}`}>
            <text
              style={{
                fontSize: getRootCssVariable('--aim-font-size-text2'),
                textAnchor: 'start',
                transform: 'translate(4px, 3px)',
                fill: dataset.color,
              }}
            >
              {formatValue(dataPoint.y, dataset.datasetUnit, 1)}
            </text>
          </animated.g>
        );
      })}
    </>
  );
};
export default DataLabels;
