/**
 * URL-safe base64 encoding.
 *
 * The string is encoded using @function encodeURIComponent to support Unicode characters, such as emojis.
 * Unsafe URL characters (+, /, and =) are replaced with safe URL characters (-, _, and no padding).
 */
export const base64EncodeUrlSafe = (str: string): string => {
  const base64Encoded = btoa(encodeURIComponent(str));
  return base64Encoded.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');
};

/**
 * URL-safe base64 decoding.
 *
 * Padding characters (i.e. =) are not required for @function atob, so they are not re-added before decoding.
 */
export const base64DecodeUrlSafe = (str: string): string => {
  const withStandardBase64CharactersAndNoPadding = str.replaceAll('-', '+').replaceAll('_', '/');
  return decodeURIComponent(atob(withStandardBase64CharactersAndNoPadding));
};
