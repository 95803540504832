import { UpgradeSubscriptionPlanNavigationHeaderButton } from '@/components/subscription-plans/navigation-header-call-to-actions/UpgradeSubscriptionPlanHeaderButton';
import { Typography, Button } from 'aim-components';
import styles from './MobileHeaderUpgradePlanCallToAction.module.css';
import { useNavigationHeaderCallToActions } from './hooks';
import { useTranslation } from 'next-i18next';
import { SubscriptionTier } from '@/api/subscription-plans/types';
import { useSubscriptionPlanName } from '@/components/subscription-plans/hooks';

export const MobileHeaderUpgradePlanCallToAction = () => {
  const { t } = useTranslation('common', { keyPrefix: 'subscriptionPlan' });
  const { shouldShowCallToAction, dismissCallToAction } = useNavigationHeaderCallToActions();
  const planName = useSubscriptionPlanName(SubscriptionTier.Plus);

  if (!shouldShowCallToAction) return null;

  return (
    <section className={styles.container}>
      <Typography variant='text1'>{t('getMoreBenefitsOnPlan', { plan: planName })}</Typography>
      <UpgradeSubscriptionPlanNavigationHeaderButton />
      <Button
        startIcon='close'
        type='secondary'
        onClick={dismissCallToAction({ eventSource: 'mobile-navigation-header' })}
      />
    </section>
  );
};
