export const setIntercomVisibility = (visible: boolean) => {
  // eslint-disable-next-line camelcase
  window && window.Intercom && window.Intercom('update', { hide_default_launcher: !visible });
};

/**
 * Wrapper around Intercom's `trackEvent` method.
 *
 * @see https://developers.intercom.com/installing-intercom/web/methods/#intercomtrackevent
 */
export const trackIntercomEvent = (eventName: string, metadata?: Record<string, unknown>) => {
  if (!window || !window.Intercom) return;

  window.Intercom('trackEvent', eventName, metadata);
};
