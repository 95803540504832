import type { ReactElement } from 'react';
import React from 'react';

import './Header.css';
import { Icon } from '../../Icon';
import { Typography } from '../../Typography';
import type { TypographyColor } from '../../Typography/Typography';

export interface HeaderProps {
  subtitle?: string;
  subtitleColor?: TypographyColor;
  title?: string | ReactElement;
  onClose?: () => void;
  backButton?: () => void;
}

export const Header = ({ title, subtitle, subtitleColor, onClose, backButton }: HeaderProps): ReactElement => {
  return (
    <header className='ModalHeaderContainer'>
      <div className='ModalHeader'>
        {backButton && (
          <Icon
            icon='arrow-left'
            className='ModalHeader-close'
            role='button'
            size='large'
            onClick={() => backButton()}
          />
        )}
        {title && typeof title === 'string' ? (
          <Typography variant='label1' className='ModalHeader-title'>
            {title}
          </Typography>
        ) : (
          title
        )}
        {onClose && (
          <Icon icon='close' className='ModalHeader-close' role='button' size='large' onClick={() => onClose()} />
        )}
      </div>
      {subtitle && (
        <Typography className='Modal-subtitle' variant='text1' color={subtitleColor}>
          {subtitle}
        </Typography>
      )}
    </header>
  );
};
