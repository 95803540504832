import type { ReactNode } from 'react';
import { flushSync } from 'react-dom';
import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

/**
 * @returns a `renderJsxToString` function that is used to convert a ReactNode into a string.
 * This is useful e.g. for showing more complex components in SciChart tooltips, which only accepts an SVG string.
 */
export const createJsxToStringRenderer = () => {
  let div: HTMLDivElement | null;
  let root: Root | null;

  const renderJsxToString = (node: ReactNode) => {
    // * These elements are only created once and reused, for performance reasons.
    div ??= document.createElement('div');
    root ??= createRoot(div);

    // * flushSync is called so `div.innerHTML` gets the latest changes.
    flushSync(() => {
      if (root) root.render(node);
    });

    return div.innerHTML;
  };

  return { renderJsxToString };
};
