import type { ReactElement } from 'react';
import React from 'react';

import './ProgressBar.css';
import cn from 'classnames';

export interface ProgressBarProps {
  progress?: number;
  color?: string;
  fillBackground?: boolean;
}

export const ProgressBar = ({ progress = 0, color, fillBackground }: ProgressBarProps): ReactElement => {
  return (
    <div className={cn('ProgressBar', fillBackground && 'ProgressBar-filled-background')} title={progress.toString()}>
      <div
        data-testid='progressBar'
        className='ProgressBar-filled'
        style={{ width: `${progress}%`, backgroundColor: color }}
      />
    </div>
  );
};
