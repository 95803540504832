import type { ReactElement, ReactNode } from 'react';
import React from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactElement;
  children: ReactNode;
}

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps): ReactElement =>
  condition ? wrapper(children) : <>{children}</>;

export default ConditionalWrapper;
