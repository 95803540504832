import React, { useRef, useState } from 'react';
import type { ReactElement } from 'react';
import { Typography } from '../Typography';
import './HoverTooltip.css';
import type { Placement } from '@floating-ui/react';
import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cn from 'classnames';

type HoverTooltipProps = {
  message: string | undefined;
  position?: Placement;
  offsetPx?: number;
  /**
   * @deprecated Use `renderTrigger` instead, to avoid wrapping in unnecessary `<div>`.
   * This also avoids the tooltip causing styling changes to the trigger element.
   */
  children?: ReactElement;
  className?: string;
  renderTrigger?: ({
    setReference,
    referenceProps,
  }: {
    setReference: ReturnType<typeof useFloating>['refs']['setReference'];
    referenceProps: Record<string, unknown>;
  }) => ReactElement;
  disabled?: boolean;
};

const ARROW_SIZE = 5;
const MAX_Z_INDEX = 2147483647;

export const HoverTooltip = ({
  message,
  position,
  offsetPx = 6,
  children,
  disabled = false,
  renderTrigger,
  className,
}: HoverTooltipProps) => {
  const arrowRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: position,
    open,
    onOpenChange: setOpen,
    middleware: [offset(offsetPx), shift(), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false, enabled: !disabled });
  const focus = useFocus(context, { enabled: !disabled });
  const dismiss = useDismiss(context, { enabled: !disabled });
  const role = useRole(context, { role: 'tooltip', enabled: !disabled });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  return (
    <>
      {renderTrigger ? (
        renderTrigger({ setReference: refs.setReference, referenceProps: getReferenceProps() })
      ) : (
        <div ref={refs.setReference} {...getReferenceProps()} className={cn('HoverTooltipReferenceWrapper', className)}>
          {children}
        </div>
      )}

      {open && message && !disabled && (
        <FloatingPortal>
          <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: MAX_Z_INDEX }} {...getFloatingProps()}>
            <div className='HoverTooltip'>
              <FloatingArrow ref={arrowRef} context={context} height={ARROW_SIZE} width={ARROW_SIZE * 2} />
              <Typography variant='text2'>{message}</Typography>
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
