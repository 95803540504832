import { AxiosError } from 'axios';
import type { NextApiResponse } from 'next';
import { serverLogger } from '../logger';

/** A generic error message that does not leak any information about the error to the client, for security reasons. */
export const genericServerErrorResponse = 'Something went wrong while processing your request.';

export const handleApiError = (res: NextApiResponse, error: AxiosError | Error | unknown): void => {
  if (error instanceof AxiosError) {
    if (error.response) {
      res.status(error.response.status).send(genericServerErrorResponse);
    } else if (error.request) {
      res.status(503).send('External service unavailable.');
    }
  } else {
    // We only log all requests when axios catches an error. This is for exceptions we throw ourselves.
    serverLogger.error('Detected an error in handleApiError!', { error });
    res.status(500).send(genericServerErrorResponse);
  }
};
