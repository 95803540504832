import {
  getQuarter as getQuarterFns,
  getISOWeek,
  getISOWeekYear,
  differenceInDays,
  isValid,
  formatISO,
  eachYearOfInterval,
  eachQuarterOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachDayOfInterval,
  subQuarters,
  subYears,
  subMonths,
  subWeeks,
  subDays,
  startOfDay,
  sub,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import type { DateString, TimeGranularity } from '../components/Chart/types';

export const getQuarter = (date: Date): number => {
  return getQuarterFns(date);
};

export const getIsoWeek = (date: Date): number => {
  return getISOWeek(date);
};

export const getIsoWeekYear = (date: Date): number => {
  return getISOWeekYear(date);
};

export const numberOfDaysBetweenDates = (endDate: Date, startDate: Date): number => {
  return differenceInDays(endDate, startDate);
};

export const isValidDate = (date: Date): boolean => {
  return isValid(date);
};

export const dateToString = (date: Date) => {
  if (!isValid(date)) return undefined;
  return formatISO(date, { representation: 'date' }) as DateString;
};

export const getDateInterval = (
  startDate: Date | string,
  endDate: Date | string,
  timeGranularity: TimeGranularity,
  displayNonMaturePeriod = false,
): Date[] => {
  const start = startDate instanceof Date ? startDate : new Date(startDate);
  const end = endDate instanceof Date ? endDate : new Date(endDate);

  const adjustedStart = new Date(start.getTime() + start.getTimezoneOffset() * 60000);
  const adjustedEnd = new Date(end.getTime() + end.getTimezoneOffset() * 60000);

  if (!isValidDate(start) || !isValidDate(end) || numberOfDaysBetweenDates(start, end) === 0) return [];

  const timeGranularityIntervalFunctionMap = {
    years: eachYearOfInterval,
    quarters: eachQuarterOfInterval,
    months: eachMonthOfInterval,
    weeks: eachWeekOfInterval,
    days: eachDayOfInterval,
  } as const satisfies Record<TimeGranularity, unknown>;

  const timeGranularityMatureDateFunctionMap = {
    years: subYears,
    quarters: subQuarters,
    months: subMonths,
    weeks: subWeeks,
    days: subDays,
  } as const satisfies Record<TimeGranularity, unknown>;

  if (timeGranularity === 'days') {
    const maxNumberOfTics = 300;
    const stepSize = Math.max(
      Math.round(numberOfDaysBetweenDates(adjustedEnd, adjustedStart) / maxNumberOfTics / 10) * 10,
      1,
    ); // Round to nears ten but use 1 instead of 0
    return eachDayOfInterval(
      {
        start: adjustedStart,
        end: subDays(adjustedEnd, !displayNonMaturePeriod && end > subDays(new Date(), 1) ? 1 : 0),
      },
      { step: stepSize },
    ).map((date) => new Date(date.getTime() - date.getTimezoneOffset() * 60000));
  }

  if (timeGranularity === 'weeks') {
    return eachWeekOfInterval(
      {
        start: adjustedStart,
        end: subWeeks(adjustedEnd, !displayNonMaturePeriod && end > subWeeks(new Date(), 1) ? 1 : 0),
      },
      { weekStartsOn: 1 },
    ).map((date) => new Date(date.getTime() - date.getTimezoneOffset() * 60000));
  }

  return timeGranularityIntervalFunctionMap[timeGranularity]({
    start: adjustedStart,
    end: timeGranularityMatureDateFunctionMap[timeGranularity](
      adjustedEnd,
      !displayNonMaturePeriod && end > timeGranularityMatureDateFunctionMap[timeGranularity](new Date(), 1) ? 1 : 0,
    ),
  }).map((date) => new Date(date.getTime() - date.getTimezoneOffset() * 60000));
};

export const getLastMatureDate = (timeGranularity: TimeGranularity): Date => {
  const now = new Date();

  switch (timeGranularity) {
    case 'days':
      return startOfDay(sub(now, { days: 1 }));
    case 'weeks':
      return startOfWeek(sub(now, { weeks: 1 }));
    case 'months':
      return startOfMonth(sub(now, { months: 1 }));
    case 'quarters':
      return startOfQuarter(subQuarters(now, 1));
    case 'years':
      return startOfYear(sub(now, { years: 1 }));
    default:
      throw new Error(`Unsupported granularity: ${timeGranularity}`);
  }
};

export const toLocalDate = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const toUTCDate = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export const dateFormatters = {
  years: new Intl.DateTimeFormat('en', { year: 'numeric' }),
  months: new Intl.DateTimeFormat('en', { year: '2-digit', month: 'short' }),
  days: new Intl.DateTimeFormat('en', { year: '2-digit', month: 'short', day: '2-digit' }),
  shortYear: new Intl.DateTimeFormat('en', { year: '2-digit' }),
  default: new Intl.DateTimeFormat('en', { year: '2-digit', month: 'short' }),
};

export const parseDate = (date: string, timeGranularity: TimeGranularity = 'months'): string => {
  const newDate = new Date(date);
  const localDate = toLocalDate(newDate);

  if (isNaN(localDate.getTime())) {
    // ! Invalid date, perform no parsing.
    return date;
  }

  if (timeGranularity === 'quarters') {
    const quarter = getQuarter(localDate);
    const year = localDate.getFullYear();
    return `Q${quarter} ${year}`;
  }
  if (timeGranularity === 'weeks') {
    return dateFormatters['days'].format(localDate);
  }
  if (timeGranularity === 'years') {
    return `${localDate.getFullYear()}`;
  }

  return dateFormatters[timeGranularity]
    ? dateFormatters[timeGranularity].format(localDate)
    : dateFormatters['default'].format(localDate);
};
