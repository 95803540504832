import type { ReactElement, ChangeEvent } from 'react';
import React from 'react';
import cn from 'classnames';
import './Toggle.css';
import { Typography } from '../../Typography';

export interface ToggleProps {
  toggledOn?: boolean;
  onToggle: (toggledOn: boolean) => void;
  label?: string;
  disabled?: boolean;
  variant?: 'large' | 'small';
}

export const Toggle = ({
  toggledOn = false,
  onToggle,
  label,
  disabled,
  variant = 'large',
}: ToggleProps): ReactElement => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onToggle(e.target.checked);
  };

  return (
    <div className='toggle-container'>
      {label && (
        <Typography className='toggle-label' variant={variant === 'small' ? 'text2' : 'text1'}>
          {label}
        </Typography>
      )}
      <label className={cn('toggle-switch', variant)}>
        <input
          type='checkbox'
          role='checkbox'
          name={label}
          checked={toggledOn}
          onChange={(e) => !disabled && handleChange(e)}
          disabled={disabled}
        />
        <span className={cn('toggle-slider', variant, disabled && 'disabled')} data-testid='toggle-slider' />
      </label>
    </div>
  );
};
