import type { IThemeProvider } from 'scichart';
import { SciChartJSDarkv2Theme } from 'scichart';

const defaultDarkTheme = new SciChartJSDarkv2Theme();

export const DARK_THEME = {
  ...defaultDarkTheme,
  sciChartBackground: 'transparent',
  tickTextBrush: '#DADADA',
  loadingAnimationBackground: 'transparent',
  loadingAnimationForeground: 'rgba(255, 255, 255, 0.5)',
} satisfies IThemeProvider;

export const OVERVIEW_DARK_THEME = {
  ...DARK_THEME,
  loadingAnimationForeground: 'transparent',
} satisfies IThemeProvider;
