/* eslint-disable camelcase */

import type { BaseDataPoint } from '../utils';

export const RecurringVsNonRecurringRevenueDataset = {
  RecurringRevenue: 'dataset-1',
  NonRecurringRevenue: 'dataset-2',
} as const;

export const RECURRING_VS_NON_RECURRING_REVENUE_DATASETS = Object.values(RecurringVsNonRecurringRevenueDataset);
export type RecurringVsNonRecurringRevenueDatasetType = (typeof RECURRING_VS_NON_RECURRING_REVENUE_DATASETS)[number];

export enum RecurringVsNonRecurringRevenueStackingRule {
  'StackedBars' = 'stackingRule-1',
  '100PercentStackedBars' = 'stackingRule-2',
}

export interface RecurringVsNonRecurringRevenueResponse {
  meta: { displayUnit: string; xMin?: string; xMax?: string };
  metrics: Record<RecurringVsNonRecurringRevenueDatasetType, BaseDataPoint[]>;
}

export interface RecurringVsNonRecurringRevenueArrowRow {
  monthly_subscription_sales_net_run_rate: number | null;
  periodized_non_subscription_sales_net: number | null;
  date_month: string;
}
