import { ContextMenu, Popover } from 'aim-components';
import { useState } from 'react';
import { useProfileMenuItems } from '@/components/layouts/navigation/navigation-menu/hooks';
import { ProfileMenuTrigger } from '@/components/layouts/navigation/navigation-menu/user-profile/ProfileMenuTrigger';

type NavigationMenuUserProfileProps = {
  expanded: boolean;
};

export const NavigationMenuUserProfile = ({ expanded }: NavigationMenuUserProfileProps) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { profileMenuItems } = useProfileMenuItems(() => setShowProfileMenu(false));

  return (
    <Popover
      open={showProfileMenu}
      onOpenChange={setShowProfileMenu}
      placement='right-end'
      offset={{ mainAxis: 8 }}
      renderPopoverTrigger={(triggerProps) => (
        <ProfileMenuTrigger
          expanded={expanded}
          icon={showProfileMenu ? 'dropdown-up' : 'dropdown-down'}
          onClick={() => setShowProfileMenu((prev) => !prev)}
          {...triggerProps}
        />
      )}
    >
      <ContextMenu>
        <ContextMenu.List>{profileMenuItems}</ContextMenu.List>
      </ContextMenu>
    </Popover>
  );
};
