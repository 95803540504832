/* eslint-disable camelcase */
import type { UserGrowthResponse } from './index.page';

export const addToMetric = (
  metrics: UserGrowthResponse['metrics'],
  key: keyof UserGrowthResponse['metrics'],
  value: number,
  date: string,
) => {
  metrics[key].push({
    date: new Date(date).toISOString(),
    value: Number(value),
  });
};

export const UserGrowthDataset = {
  ActiveUsers: 'dataset-1',
  ActiveUsers7dAvg: 'dataset-2',
  ActiveUsersLast7d: 'dataset-3',
  ActiveUsersLast28d: 'dataset-4',
  ActiveUsersLast30d: 'dataset-5',
  ActiveUsersLast90d: 'dataset-6',
} as const;
export const UserGrowthDatasets = Object.values(UserGrowthDataset);
export type UserGrowthDatasetType = (typeof UserGrowthDatasets)[number];

export const arrowToDatasetMap = {
  [UserGrowthDataset.ActiveUsers]: 'active_users',
  [UserGrowthDataset.ActiveUsers7dAvg]: 'active_users_7d_avg',
  [UserGrowthDataset.ActiveUsersLast7d]: 'active_users_last_7d',
  [UserGrowthDataset.ActiveUsersLast28d]: 'active_users_last_28d',
  [UserGrowthDataset.ActiveUsersLast30d]: 'active_users_last_30d',
  [UserGrowthDataset.ActiveUsersLast90d]: 'active_users_last_90d',
} as const;
