import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import { ModalOverlay } from '../Modal';
import './TakeOver.css';

import { Header } from '../Modal/Header/Header';

export const MobileTakeover = ({
  children,
  onClose,
  open,
  title,
  backButton,
}: {
  children: ReactNode;
  onClose?: () => void;
  open: boolean;
  title: string | ReactElement;
  backButton?: () => void;
}): ReactElement => {
  return (
    <ModalOverlay onClose={onClose} open={open} className='takeover-wrapper'>
      <Header onClose={onClose} title={title} backButton={backButton} />
      <div className='takeover-container'>{children}</div>
    </ModalOverlay>
  );
};
