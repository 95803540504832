import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

type NavigationHeaderButton<TType extends string> = {
  type: TType;
  onClick?: () => void;
} | null;

const useNavigationContextProvider = () => {
  const [navigationMenuExpanded, setNavigationMenuExpanded] = useState(true);

  const [modalNavigationHeaderConfig, setModalNavigationHeaderConfig] = useState<{
    title?: string;
    startButton: NavigationHeaderButton<'menu' | 'back'>;
    endButton: NavigationHeaderButton<'close' | 'ellipsis'>;
  } | null>(null);

  const [showFooter, setShowFooter] = useState(true);

  const expandNavigationMenu = useCallback(() => setNavigationMenuExpanded(true), []);
  const collapseNavigationMenu = useCallback(() => setNavigationMenuExpanded(false), []);
  const toggleNavigationMenu = useCallback(() => setNavigationMenuExpanded((expanded) => !expanded), []);

  return {
    modalNavigationHeaderConfig,
    setModalNavigationHeaderConfig,
    navigationMenuExpanded,
    setNavigationMenuExpanded,
    expandNavigationMenu,
    collapseNavigationMenu,
    toggleNavigationMenu,
    showFooter,
    setShowFooter,
  } as const;
};

const NavigationContext = createContext<ReturnType<typeof useNavigationContextProvider> | null>(null);

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error('useNavigationContext must be used within a NavigationContextProvider');
  }

  return context;
};

export const NavigationContextProvider = ({ children }: { children: ReactNode }) => {
  const contextValue = useNavigationContextProvider();

  return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>;
};
