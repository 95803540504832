const isBrowser = typeof window !== 'undefined';

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en'],
  },
  reloadOnPrerender: process.env.NODE_ENV !== 'production',
  returnNull: false,
  partialBundledLanguages: isBrowser && true,
};
