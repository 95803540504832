import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import type { ChartDataset } from '..';
import { LegendIcon, type ChartTooltipProps, type TooltipDataPoint } from '..';
import { Typography } from '../../Typography';
import './Tooltip.css';
import { EMPTY_ARRAY_STATIC_REFERENCE, valueFormatter } from '../../../utils/utils';

export const Tooltip = ({
  title,
  text,
  dataPoints,
  children,
  footer,
  unit = '',
  hoveredDatasetLabel,
}: Omit<ChartTooltipProps, 'dataPoints' | 'title'> & {
  dataPoints?: TooltipDataPoint[];
  title?: ReactNode | string;
}) => {
  const { uniqueDataPoints } = (dataPoints ?? EMPTY_ARRAY_STATIC_REFERENCE).reduce(
    (acc, point) => {
      if (
        !acc.uniqueDataPoints.find(
          (item) => item.label?.replace(' (forecasted)', '') === point.label?.replace(' (forecasted)', ''),
        )
      ) {
        acc.uniqueDataPoints.push(point);
      }
      return acc;
    },
    { uniqueDataPoints: Array<TooltipDataPoint>() },
  );

  return (
    <div className='Tooltip'>
      <div className='Tooltip-title'>
        {typeof title === 'string' ? <Typography variant='text1'>{title}</Typography> : title}

        {text && (
          <div className='Tooltip-text'>
            <Typography variant='text1'>{text}</Typography>
          </div>
        )}
      </div>
      <div className='Tooltip-container'>
        {dataPoints &&
          uniqueDataPoints
            .sort((a, b) => (b.value ?? 0) - (a.value ?? 0))
            .map((item, i) => (
              <TooltipDataRow
                key={(item.label || '') + i}
                {...item}
                color={item.forecastColor ?? item.color}
                value={item.value}
                unit={item.datasetUnit ?? unit}
                hovered={hoveredDatasetLabel ? item.label === hoveredDatasetLabel : undefined}
                type={item.type}
              />
            ))}
        {children}
      </div>
      {footer}
    </div>
  );
};

export interface TooltipDataRowProps {
  label?: string;
  color?: string;
  icon?: ReactNode;
  value?: string | number;
  valueIsText?: boolean;
  unit?: string;
  type?: ChartDataset['type'];
  percentage?: string;
  decimals?: number;
  isForecast?: boolean;
  variant?: 'small' | 'regular';
  hovered?: boolean;
}

const TooltipDataRow = ({
  label,
  color,
  icon,
  value,
  valueIsText,
  unit,
  percentage,
  decimals,
  variant = 'regular',
  hovered = true,
  type,
}: TooltipDataRowProps): ReactElement => {
  if (value === undefined || value === null) return <></>;

  const lineTypes = ['line', 'mountain', 'stackedLine', 'band'];

  return (
    <div className='Tooltip-lineItem'>
      <div className='Tooltip-itemLabel'>
        {color && !icon && (
          <>
            {type && lineTypes.includes(type) && <LegendIcon.Line color={color} />}
            {type === 'bar' && <LegendIcon.Square color={color} />}
          </>
        )}
        {icon && icon}
        <Typography variant={variant === 'small' ? 'text2' : 'text1'} color={hovered ? 'primary' : 'secondary'}>
          {label}
        </Typography>
      </div>
      <span className='Tooltip-itemValue'>
        <Typography variant={variant === 'small' ? 'text2' : 'text1'} color={hovered ? 'primary' : 'secondary'}>
          {valueIsText === true ? value : valueFormatter(value as number, unit, decimals)}
        </Typography>

        {percentage && (
          <Typography variant={variant === 'small' ? 'text2' : 'text1'} color={hovered ? 'primary' : 'secondary'}>
            {` (${percentage})`}
          </Typography>
        )}
      </span>
    </div>
  );
};

const TooltipDivider = () => <div className='Tooltip-divider' />;

Tooltip.DataRow = TooltipDataRow;
Tooltip.Divider = TooltipDivider;
