import type { MutableRefObject, Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

export function useHandleClickOutside(
  ref: MutableRefObject<Element | undefined>,
  containerRef?: MutableRefObject<Element | undefined>,
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [show, setShow] = useState(false);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      const clickOutside =
        ref &&
        ref.current &&
        !ref.current?.contains(event.target as Node) &&
        (!containerRef || !containerRef.current?.contains(event.target as Node));
      if (show && clickOutside) setShow(false);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, containerRef, show]);
  return [show, setShow];
}
