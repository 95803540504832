import React from 'react';

import './Spinner.css';
import { Icon } from '../../Icon';

type SpinnerSize = 'small' | 'big';

export interface SpinnerProps {
  size?: SpinnerSize;
}

export const Spinner = ({ size = 'small' }: SpinnerProps) => {
  return (
    <Icon
      icon='loading'
      animation='spin'
      className='Spinner'
      style={{ fontSize: size === 'big' ? 128 : 16 }}
      title='loading'
    />
  );
};
