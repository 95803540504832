import type { AxisBase2D, IGenericAnimation, SciChartSurface, TEasingFn, TSciChart } from 'scichart';
import { EAutoRange, EAxisAlignment, NumberRange, NumericAxis } from 'scichart';
import type { YAxisLabelProviderFormat } from '../../providers/YAxisLabelProvider';
import { YAxisLabelProvider } from '../../providers/YAxisLabelProvider';
import type { ChartViewMode } from '../../types';
import { YAxisTickProvider } from '../../providers/YAxisTickProvider';

export type YAxisConfig = {
  type: 'numeric';
} & {
  title: string;
  alignment: 'left' | 'right';
  visible?: boolean;
  format?: YAxisLabelProviderFormat;
  fixedRange?: { min?: number; max?: number };
  capAt?: { min?: number; max?: number };
  minDelta?: number;
  id?: string;
  color?: string;
};

const PADDING_YMAX = 1.2;
const PADDING_YMIN = 1.1;

export const createYAxis = ({
  wasmContext,
  config,
  mode,
}: {
  wasmContext: TSciChart;
  config: YAxisConfig;
  mode: ChartViewMode;
}) => {
  switch (config.type) {
    case 'numeric': {
      const axis = new NumericAxis(wasmContext, {
        labelProvider: new YAxisLabelProvider({ format: config.format }),
      });

      axis.tickProvider = new YAxisTickProvider({ wasmContext, minDelta: config.minDelta });

      return updateYAxis({ axis, config, mode, hide: false });
    }
    default: {
      throw new Error(`Unsupported Y-axis type: ${config.type}`);
    }
  }
};

export const updateYAxis = ({
  axis,
  config,
  mode,
  hide,
}: {
  axis: AxisBase2D;
  config: YAxisConfig;
  mode: ChartViewMode;
  hide: boolean;
}) => {
  axis.isVisible = config.visible !== false && !hide;
  axis.axisTitle = config.title.toUpperCase();

  if (config.alignment === 'left') axis.axisAlignment = EAxisAlignment.Left;
  if (config.alignment === 'right') axis.axisAlignment = EAxisAlignment.Right;

  axis.axisTitleStyle = {
    fontSize: mode === 'desktop' ? 10 : 0, // Preferably we should not add a title at all for mobile
    color: config.color ?? 'rgba(255, 255, 255, 0.7)',
  };

  axis.majorGridLineStyle = {
    strokeDashArray: [3, 2],
    strokeThickness: 1,
    color: 'rgba(255, 255, 255, 0.1)',
  };

  axis.id = config.id ?? 'DefaultAxisId';

  axis.labelStyle = {
    fontSize: mode === 'desktop' ? 13 : 10,
    fontFamily: 'ArketypSans',
    color: config.color ?? 'rgba(255, 255, 255, 0.7)',
  };

  axis.drawMajorBands = false;
  axis.drawMajorGridLines = true;
  axis.drawMinorGridLines = false;

  axis.autoRange = EAutoRange.Never;

  if (axis.labelProvider instanceof YAxisLabelProvider) {
    axis.labelProvider.updateFormat(config.format);
  }

  if (axis.tickProvider instanceof YAxisTickProvider) {
    axis.tickProvider.setMinDelta(config.minDelta);
  }

  return axis;
};

const yAxisVisibleRangeAnimationMap = new Map<string, IGenericAnimation>();

export const updateYAxisVisibleRange = ({
  surface,
  yAxesConfig,
  durationMs = 500,
  easingFunction,
}: {
  surface: SciChartSurface;
  durationMs?: number;
  easingFunction?: TEasingFn;
  yAxesConfig: YAxisConfig[];
}) => {
  if (!surface) return;
  const yAxes = surface.yAxes;
  if (!yAxes.get(0).parentSurface) return;

  yAxes.asArray().forEach((yAxis) => {
    const id = `${surface.id}-${yAxis.id}`;

    const existingAnimation = yAxisVisibleRangeAnimationMap.get(id);

    // * If there's already an existing animation for adjusting this Y-axis, we cancel it and start a new one instead. To avoid stacking animations.
    if (existingAnimation) existingAnimation.cancel();

    const maximumRange = yAxis.getMaximumRange();

    const fixedMin = (yAxesConfig ?? []).find((config) => config.id === yAxis.id)?.fixedRange?.min;
    const fixedMax = (yAxesConfig ?? []).find((config) => config.id === yAxis.id)?.fixedRange?.max;

    const capAtMin = (yAxesConfig ?? []).find((config) => config.id === yAxis.id)?.capAt?.min;
    const capAtMax = (yAxesConfig ?? []).find((config) => config.id === yAxis.id)?.capAt?.max;

    const animation = yAxis.animateVisibleRange(
      new NumberRange(
        fixedMin ?? Math.max(maximumRange.min, capAtMin ?? maximumRange.min) * PADDING_YMAX,
        fixedMax ?? Math.min(maximumRange.max, capAtMax ?? maximumRange.max) * PADDING_YMIN,
      ),
      durationMs,
      easingFunction,
      () => yAxisVisibleRangeAnimationMap.delete(id),
    );

    yAxisVisibleRangeAnimationMap.set(id, animation);
  });
};
