export function addOpacityToHexColor(hexCode: string, opacity: number): string {
  if (typeof hexCode !== 'string') return hexCode;

  let fixedOpacity = opacity;
  if (fixedOpacity < 0) fixedOpacity = 0;
  else if (fixedOpacity > 100) fixedOpacity = 100;

  if (hexCode.length === 7) {
    return `${hexCode}${Math.round(fixedOpacity * 255)
      .toString(16)
      .toUpperCase()
      .padStart(2, '0')}`;
  }

  let a = '0x' + hexCode[7] + hexCode[8];
  a = (+a / 255).toFixed(2).toString();

  return `${hexCode.slice(0, 7)}${Math.floor(fixedOpacity * +a * 255)
    .toString(16)
    .toUpperCase()
    .padStart(2, '0')}`;
}

export type ColorName =
  | 'vibrant-blue'
  | 'azure'
  | 'magma'
  | 'neon-blue'
  | 'flamingo'
  | 'lime'
  | 'electric-purple'
  | 'gold'
  | 'vivid-red'
  | 'cool-grey'
  | 'warm-grey';

export type HexColor = `#${string}`;

type ColorVariant = 900 | 800 | 700 | 600 | 500 | 400 | 300 | 200 | 100;
type ColorVariants = Record<ColorVariant, HexColor>;

export const ColorSpectrum = {
  'vibrant-blue': {
    900: '#000a33',
    800: '#001361',
    700: '#001d8f',
    600: '#0026bd',
    500: '#2550fd',
    400: '#5375fd',
    300: '#718dfe',
    200: '#9fb2fe',
    100: '#ccd6ff',
  },
  azure: {
    900: '#001933',
    800: '#002F61',
    700: '#00458f',
    600: '#005bbd',
    500: '#0a81ff',
    400: '#52a5ff',
    300: '#70b5ff',
    200: '#9ecdff',
    100: '#cce5ff',
  },
  magma: {
    900: '#331000',
    800: '#611f00',
    700: '#8f2e00',
    600: '#bd3b00',
    500: '#ff5200',
    400: '#ff8952',
    300: '#ff9d70',
    200: '#ffbd9e',
    100: '#ffdccc',
  },
  'neon-blue': {
    900: '#002e33',
    800: '#005861',
    700: '#00828f',
    600: '#00acbd',
    500: '#01ddf3',
    400: '#52efff',
    300: '#70f2ff',
    200: '#9ef6ff',
    100: '#ccf1ff',
  },
  flamingo: {
    900: '#330028',
    800: '#61004C',
    700: '#8f006f',
    600: '#bd0093',
    500: '#f500bf',
    400: '#ff52d9',
    300: '#ff70e0',
    200: '#ff9eea',
    100: '#ffccf4',
  },
  lime: {
    900: '#0a3300',
    800: '#146100',
    700: '#1d8f00',
    600: '#27bd00',
    500: '#31f000',
    400: '#75ff52',
    300: '#a2ff8a',
    200: '#ceffc2',
    100: '#ebffe5',
  },
  'electric-purple': {
    900: '#250033',
    800: '#470061',
    700: '#69008f',
    600: '#8a00bd',
    500: '#bb00ff',
    400: '#d152ff',
    300: '#d970ff',
    200: '#e59eff',
    100: '#f1ccff',
  },
  gold: {
    900: '#342a00',
    800: '#544300',
    700: '#7b6200',
    600: '#a98700',
    500: '#d8ad00',
    400: '#ffd014',
    300: '#ffe371',
    200: '#ffeb9f',
    100: '#fff5cc',
  },
  'vivid-red': {
    900: '#330006',
    800: '#61000B',
    700: '#8f0010',
    600: '#bd0016',
    500: '#ff001d',
    400: '#ff5266',
    300: '#ff7081',
    200: '#ff9ea9',
    100: '#ffccd2',
  },
  'cool-grey': {
    900: '#18191b',
    800: '#2E2F33',
    700: '#44454b',
    600: '#63656e',
    500: '#797c86',
    400: '#a3a5ac',
    300: '#c1c3c7',
    200: '#dfdfe2',
    100: '#f2f2f3',
  },
  'warm-grey': {
    900: '#1d1a16',
    800: '#36312B',
    700: '#50493f',
    600: '#756a5c',
    500: '#8f8270',
    400: '#b3aa9e',
    300: '#ccc6be',
    200: '#e4e1dd',
    100: '#f4f2f1',
  },
} as const satisfies Record<ColorName, ColorVariants>;

export const LineColors = {
  'vibrant-blue': ColorSpectrum.azure[500],
  azure: ColorSpectrum.azure[200],
  magma: ColorSpectrum.magma[400],
  'neon-blue': ColorSpectrum['neon-blue'][400],
  flamingo: ColorSpectrum.flamingo[400],
  lime: ColorSpectrum.lime[400],
  'electric-purple': ColorSpectrum['electric-purple'][400],
  gold: ColorSpectrum.gold[300],
  'vivid-red': ColorSpectrum['vivid-red'][500],
  'cool-grey': ColorSpectrum['cool-grey'][400],
  'warm-grey': ColorSpectrum['warm-grey'][400],
} as const satisfies Record<ColorName, HexColor>;

export const VibrantBlue550 = '#2550fd';

export const SurfaceColors = {
  'vibrant-blue': ColorSpectrum['vibrant-blue'][500],
  azure: ColorSpectrum.azure[300],
  magma: ColorSpectrum.magma[400],
  'neon-blue': ColorSpectrum['neon-blue'][400],
  flamingo: ColorSpectrum.flamingo[400],
  lime: ColorSpectrum.lime[400],
  'electric-purple': ColorSpectrum['electric-purple'][400],
  gold: ColorSpectrum.gold[300],
  'vivid-red': ColorSpectrum['vivid-red'][500],
  'cool-grey': ColorSpectrum['cool-grey'][400],
  'warm-grey': ColorSpectrum['warm-grey'][400],
} as const satisfies Record<ColorName, HexColor>;

export enum SpecialLineColors {
  Trendline = '#E3E3E3',
  Reference = '#bcc9c6',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Unknown = '#E3E3E3',
  HistoricalData = 'rgba(255, 255, 255, 0.5)',
}

export const getColorSpectrumVariantByIndex = (index: number, variantIndex: number) => {
  const color = (Object.keys(ColorSpectrum)[index] ?? 'cool-grey') as ColorName;
  const variant = (Object.keys(ColorSpectrum[color])[variantIndex] ?? '100') as unknown as ColorVariant;
  return ColorSpectrum[color][variant];
};

export const FullColorSpectrum = {
  azure: [
    '#B3CBE5',
    '#9FBEDF',
    '#8CB1D9',
    '#79A4D2',
    '#6697CC',
    '#538AC6',
    '#407DBF',
    '#3971AC',
    '#336499',
    '#2D5886',
    '#264B73',
    '#203F60',
  ],
  magma: [
    '#E5C3B3',
    '#DFB49F',
    '#D9A48C',
    '#D29679',
    '#CC8766',
    '#C67853',
    '#BF6940',
    '#AC5E39',
    '#995433',
    '#86492D',
    '#733F26',
    '#603420',
  ],
  'neon-blue': [
    '#B3E1E5',
    '#9FD9DF',
    '#8CD2D9',
    '#79CAD2',
    '#66C3CC',
    '#53BBC6',
    '#40B4BF',
    '#39A2AC',
    '#339099',
    '#2D7E86',
    '#266C73',
    '#205A60',
  ],
  flamingo: [
    '#E5B3DA',
    '#DF9FD1',
    '#D98CC8',
    '#D279BF',
    '#CC66B6',
    '#C653AD',
    '#BF40A4',
    '#AC3993',
    '#993383',
    '#862D73',
    '#732662',
    '#602052',
  ],
  lime: [
    '#BDE5B3',
    '#ACDF9F',
    '#9CD98C',
    '#8BD279',
    '#7ACC66',
    '#6AC653',
    '#59BF40',
    '#50AC39',
    '#479933',
    '#3E862D',
    '#367326',
    '#2D6020',
  ],
  'electric-purple': [
    '#D8B3E5',
    '#CE9FDF',
    '#C48CD9',
    '#BB79D2',
    '#B166CC',
    '#A753C6',
    '#9D40BF',
    '#8E39AC',
    '#7E3399',
    '#6E2D86',
    '#5E2673',
    '#4F2060',
  ],
  gold: [
    '#E5DBB3',
    '#DFD29F',
    '#D9C98C',
    '#D2C179',
    '#CCB866',
    '#C6AF53',
    '#BFA640',
    '#AC9539',
    '#998533',
    '#86742D',
    '#736326',
    '#605320',
  ],
  'vivid-red': [
    '#E5B3B8',
    '#DF9FA7',
    '#D98C95',
    '#D27984',
    '#CC6672',
    '#C65360',
    '#BF404F',
    '#AC3947',
    '#99333F',
    '#862D37',
    '#73262F',
    '#602027',
  ],
} as const satisfies Record<Exclude<ColorName, 'warm-grey' | 'cool-grey' | 'vibrant-blue'>, readonly HexColor[]>;

export const applyColors = () => {
  Object.entries(ColorSpectrum).forEach(([colorName, spectrum]) => {
    Object.entries(spectrum).forEach(([spectrumValue, hexCode]) => {
      document.documentElement.style.setProperty(`--aim-${colorName}-${spectrumValue}`, hexCode);
    });
  });
};

const colorSpectrumValues = Object.values(FullColorSpectrum);

export const getColorByYearAndMonth = (colorIndex: number, shadeIndex: number): string => {
  try {
    const numberOfColors = colorSpectrumValues.length;
    const colorVariants = colorSpectrumValues[colorIndex % numberOfColors];
    const numberOfShades = colorVariants.length;
    return colorVariants[shadeIndex % numberOfShades];
  } catch (error) {
    return SpecialLineColors.Unknown;
  }
};
