import cn from 'classnames';
import styles from './TabNavigation.module.css';
import { Typography } from '../Typography';
import { Spinner } from '../Loading/Spinner';
import { Tabs, useTabsContext } from '../Tabs/Tabs';
import type { ComponentProps } from 'react';

interface TabNavigationProps<TTab extends string = string> {
  children: React.ReactNode;
  isLoading?: boolean;
  selectedTab: TTab;
  setSelectedTab(tab: TTab): void;
}

export const TabNavigation = <TTab extends string = string>({
  children,
  isLoading,
  selectedTab,
  setSelectedTab,
}: TabNavigationProps<TTab>) => {
  return (
    <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
      <div className={styles.container}>
        <div className={styles.navigationContainer}>
          <ul className={styles.list}>{children}</ul>
        </div>
        <div>{isLoading && <Spinner />}</div>
      </div>
    </Tabs>
  );
};

const TabNavigationItem = <TValue extends string = string>({
  value,
  children,
  ...props
}: { value: TValue } & ComponentProps<'li'>) => {
  const { selectedTab, setSelectedTab } = useTabsContext();
  const isActive = selectedTab === value;

  return (
    <li {...props} onClick={() => setSelectedTab(value)}>
      <Typography
        variant='text1'
        color={isActive ? 'primary' : 'secondary'}
        className={cn(styles.listItem, isActive && styles.active)}
      >
        {children}
      </Typography>
    </li>
  );
};

TabNavigation.Item = TabNavigationItem;
