import type { AnnotationsResponse } from '@/api/insights/annotations/get-annotations/index.page';
import type { InvitedUser } from '@/components/settings/types';

export const getDisplayNameFromUserId = (userId: string, users: InvitedUser[]): string => {
  const user = users.find((user) => user.id === userId);
  return user?.fullName ?? 'Removed user';
};

const parseMentionedUserIds = (contentParts: AnnotationsResponse[number]['contentParts'], users: InvitedUser[]) => {
  return contentParts.map((part) => {
    if (typeof part === 'string') return part;
    const { fullName = 'Removed user', email = 'Unknown Email' } =
      users.find((user) => user.id === part.mentionedUserId) ?? {};
    return { mentionedUserId: part.mentionedUserId, fullName, email };
  });
};

export const parseAnnotations = (annotations: AnnotationsResponse, users: InvitedUser[]) => {
  return annotations.map(({ replies, ...annotation }) => ({
    ...annotation,
    author: getDisplayNameFromUserId(annotation.arkUserId, users),
    contentParts: parseMentionedUserIds(annotation.contentParts, users),
    tags: [],
    replies: replies.map((reply) => ({
      ...reply,
      contentParts: parseMentionedUserIds(reply.contentParts, users),
      author: getDisplayNameFromUserId(reply.arkUserId, users),
    })),
  }));
};

export const generateMentionsUserData = (users: InvitedUser[]) => {
  return users
    .filter((user) => user.emailVerified)
    .map(({ id, fullName, email }) => ({ id, display: fullName, email }));
};
