import { useCallback, useEffect, useMemo } from 'react';
import { isLarge, line, scale } from '../utils';
import { animated, easings, to, useSpring } from '@react-spring/web';
import type { ChartAnimationProps } from '../MissingDataChartAnimation';
import { LineColors } from 'aim-utils';

export const LineChartAnimation = ({ size, svgHeight, svgWidth }: ChartAnimationProps) => {
  const noOfDataPoints = 100;
  const noOfDataPointsSmall = 50;

  const scaleX = scale(noOfDataPoints, svgWidth - 20);
  const scaleY = scale(noOfDataPoints, 18);

  const y = useCallback((x: number) => 15 * Math.cos(x) + 25 * (Math.sin(x / 8) + Math.sin(x / 14)) - 7 + 10, []);
  const data: [number, number][] = useMemo(
    () =>
      Array.from(Array(isLarge(size) ? noOfDataPoints : noOfDataPointsSmall)).map((_, i) => [scaleX(i), -y(scaleY(i))]),
    [scaleX, scaleY, size, y],
  );

  const pathSlicer = useCallback((t: number) => line(data.slice(0, Math.max(Math.floor(t), 20))), [data]);

  const [spring, api] = useSpring(
    () => ({
      config: {
        duration: isLarge(size) ? 2000 : 3000,
        easing: easings.easeInOutSine,
      },
      from: { t: 0 },
      to: [{ t: data.length }, { t: 0 }],
    }),
    [],
  );

  useEffect(() => {
    const interval = setInterval(
      () => api.start({ from: { t: 0 }, to: [{ t: data.length }, { t: 0 }] }),
      isLarge(size) ? 4000 : 9000,
    );

    return () => clearInterval(interval);
  }, [api, data.length, size]);

  return (
    <svg width={svgWidth} height={svgHeight}>
      <g transform={`translate(0,${svgHeight})`}>
        <animated.path d={to(spring.t, pathSlicer)} stroke={LineColors['vibrant-blue']} strokeWidth={3} fill={'none'} />
        <animated.rect
          y={-150}
          x={to(spring.t, (t) => scaleX(Math.max(t, 20)))}
          width={1}
          height={200}
          opacity={0.3}
          stroke={'white'}
          strokeWidth={1}
          strokeDasharray={2}
        />
      </g>
    </svg>
  );
};
