import type { MutableRefObject, ReactElement, MouseEvent } from 'react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import type { ChartOptions } from 'chart.js';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  TimeScale,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'react-chartjs-2';
import AnnotationsWrapper from '../Annotation/AnnotationsWrapper';
import { TooltipContainer } from './Tooltip/TooltipContainer';
import { useAdjustedYMax, useCapYValue, useChartArea, useChartData } from './hooks';
import type { ChartMobileProps, ChartProps } from './types';
import { getChartOptions, getChartType, getYAxisTitle, getYAxisUnit, mapDataPoints } from './utils';
import './Chart.css';
import { useChartTooltip } from './Tooltip/hooks';
import { EMPTY_ARRAY_STATIC_REFERENCE } from '../../utils';
import { min } from 'date-fns';
import { ChartCircleLoadingCover } from './ChartCircleLoader';
import { ForecastChartContext } from './ForecastChartContext';
import { AnnotationContext } from '../Annotation';

ChartJS.register([
  LineController,
  BarController,
  TimeScale,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  ChartDataLabels,
  ScatterController,
  Filler,
  ChartAnnotationPlugin,
]);

ChartJS.defaults.font = { family: 'ArketypSans', size: 13 };

export const ChartComponent = ({
  datasetFilterSettings = {},
  datasets = [],
  selectedDatasetsOptions,
  TooltipComponent,
  xAxis,
  yAxis,
  y1Axis,
  disableAnnotations = false,
  responsive = true,
  expanded = false,
  xmin = 0,
  xmax = 0,
  labels = EMPTY_ARRAY_STATIC_REFERENCE,
  forecastWarning,
  circleLoadingCover,
  overrideOptions,
  plugins,
}: ChartProps): ReactElement => {
  const forecastChartContext = useContext(ForecastChartContext);
  const [chartOptions, setChartOptions] = useState<ChartOptions>();
  const [isScatterPlot, isAreaChart, isBarChart] = getChartType(datasets);

  const chartRef = useRef<ChartJS & { formatTooltipTitle?: ChartMobileProps['formatTooltipTitle'] }>(null);

  const { singleDataset, filteredDataIsGreyedOut } = datasetFilterSettings ?? {};
  const yAxisUnit = getYAxisUnit(singleDataset, datasets, selectedDatasetsOptions, yAxis);
  const yAxisTitle = getYAxisTitle(singleDataset, selectedDatasetsOptions, yAxis?.title);

  const { tooltipData, tooltipVisible, tooltipPosition, hoveredDatasetLabel } = useChartTooltip(
    datasets,
    chartRef,
    selectedDatasetsOptions,
    !filteredDataIsGreyedOut,
    isAreaChart,
    expanded,
  );

  const chartData = useChartData(
    datasets,
    selectedDatasetsOptions,
    chartRef.current,
    !filteredDataIsGreyedOut,
    yAxisUnit,
    hoveredDatasetLabel,
    labels[xmin],
    labels[xmax],
  );

  const adjustedFixedYMax = useAdjustedYMax(datasets, labels[xmin], labels[xmax]);
  const { capYValueYAxis, capYValueY1Axis } = useCapYValue(datasets, labels[xmin], labels[xmax], yAxis, y1Axis);

  const chartContainerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const chartContainerExpandedRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const [chartArea, updateChartArea] = useChartArea();

  const [newAnnotationXPos, setNewAnnotationXPos] = useState<number | undefined>(undefined);

  useEffect(() => {
    setChartOptions({
      ...getChartOptions(
        {
          linear: isScatterPlot,
          offset: isBarChart,
          fontSize: expanded ? 13 : undefined,
          ...(xAxis?.slider && { min: labels[xmin] }), // <--- TODO: Perhaps extract labels[xmin] and labels[xmax] to a variable, to have a primitive string in the dependency array?
          ...(xAxis?.slider && { max: labels[xmax] }),
          ...xAxis,
        },
        {
          fixedMin: capYValueYAxis.min ?? yAxis?.fixedMin,
          unit: yAxisUnit,
          fontSize: expanded ? 13 : undefined,
          title: yAxisTitle,
          suggestedMax: adjustedFixedYMax,
          fixedMax: yAxis?.fixedMax ?? capYValueYAxis.max,
          ...yAxis,
        },
        {
          fixedMin: capYValueY1Axis.min ?? y1Axis?.fixedMin,
          fixedMax: capYValueY1Axis.max ?? y1Axis?.fixedMax,
          display: !!y1Axis && y1Axis.display !== false,
          fontSize: expanded ? 13 : undefined,
          ...y1Axis,
        },
        {},
        overrideOptions,
      ),
      responsive,
      onResize: ({ chartArea }) => {
        updateChartArea(chartArea);
      },
      animation: {
        onProgress() {
          updateChartArea(this.chartArea);
        },
      },
    });
  }, [
    expanded,
    isAreaChart,
    isBarChart,
    isScatterPlot,
    labels,
    responsive,
    chartRef,
    updateChartArea,
    xAxis,
    xmax,
    xmin,
    yAxis,
    yAxisTitle,
    yAxisUnit,
    y1Axis,
    overrideOptions,
    adjustedFixedYMax,
    capYValueYAxis.min,
    capYValueYAxis.max,
    capYValueY1Axis.min,
    capYValueY1Axis.max,
  ]);

  const handleChartClick = useCallback(
    (e: MouseEvent) => {
      if (!chartRef?.current) return;
      const closestElement = chartRef.current.getElementsAtEventForMode(
        e as unknown as Event,
        'nearest',
        { intersect: false, axis: 'x' },
        true,
      );
      const nearestX = closestElement[0]?.element.x ?? 0;
      setNewAnnotationXPos(nearestX - (chartArea?.left ?? 0));
    },
    [chartArea?.left, chartRef],
  );

  const xAxisHeight = chartRef.current?.scales.x?.height ?? 0;

  const annotationContext = useContext(AnnotationContext);
  const annotationsHidesTooltip =
    annotationContext.displayAnnotationSidePanel || annotationContext.isCreatingAnnotation;

  const forecastStartDates = datasets
    .filter(
      (dataset) =>
        dataset.highlightOnHover &&
        (dataset.filterable === false || selectedDatasetsOptions.find((option) => option.label === dataset.label)),
    )
    .map((dataset) => new Date(dataset.data[0]?.x ?? new Date(labels[xmin])));
  const forecastIsDisplayed =
    forecastChartContext?.nonReliableForecast && forecastStartDates
      ? new Date(labels[xmax]) > new Date(min(forecastStartDates))
      : false;

  return (
    <div className='Chart-content-wrapper'>
      {TooltipComponent &&
        tooltipPosition.left != undefined &&
        tooltipPosition.top != undefined &&
        tooltipData &&
        !annotationsHidesTooltip && (
          <TooltipContainer
            tooltipData={tooltipData}
            position={{
              left:
                (expanded ? chartContainerExpandedRef : chartContainerRef).current?.getBoundingClientRect().left +
                  tooltipPosition.left ?? 0,
              top:
                (expanded ? chartContainerExpandedRef : chartContainerRef).current?.getBoundingClientRect().top +
                  tooltipPosition.top ?? 0,
            }}
            visible={tooltipVisible}
          >
            <TooltipComponent
              title={tooltipData.title.toString()}
              dataPoints={tooltipData.dataPoints.map(mapDataPoints)}
              unit={yAxis && yAxis.unit}
              units={{ x: xAxis?.unit ?? '', y: yAxis?.unit ?? '' }}
              hoveredDatasetLabel={hoveredDatasetLabel}
            />
          </TooltipContainer>
        )}
      <div className='Chart-canvasContainer' ref={expanded ? chartContainerExpandedRef : chartContainerRef}>
        <Chart
          type='line'
          data={{ datasets: chartData.datasets, labels }}
          options={chartOptions}
          ref={chartRef}
          onClick={handleChartClick}
          data-testid='chart'
          plugins={plugins}
        />

        {chartArea && !disableAnnotations && (
          <AnnotationsWrapper
            chartArea={chartArea}
            startDate={labels[xmin]}
            endDate={labels[xmax]}
            newAnnotationXPos={newAnnotationXPos}
            setNewAnnotationXPos={setNewAnnotationXPos}
            chartRef={chartRef}
          />
        )}
        {forecastIsDisplayed && (
          <div className='missing-data-button-wrapper' style={{ bottom: xAxisHeight + 8 }}>
            {forecastWarning}
          </div>
        )}
        {circleLoadingCover && <ChartCircleLoadingCover />}
      </div>
    </div>
  );
};
