import { serverRequest } from '@/lib/request/request';
import { type ApiBusinessData, type DataCell, type DataPoint, type FinancialData } from './index.page';
import { getMonth, getYear, isBefore, isFuture, isSameMonth, isSameYear, startOfDay } from 'date-fns';
import type { ValidBusinessDataSettingsResponse } from './settings/valid/index.page';
import { toUTCDate } from 'aim-components';
import type { RawAxiosRequestHeaders } from 'axios';

type Method = 'GET' | 'POST' | 'PUT';

export const loanDataRequest = <T>(
  route: string,
  options?: {
    method?: Method;
    body?: Record<string, unknown>;
    arkUserId?: string;
  },
): Promise<T> => {
  const { method = 'GET', body, arkUserId } = options || {};
  const url: string = process.env.NEXT_PUBLIC_LOAN_MANAGEMENT_URL + route;
  const headers: Partial<RawAxiosRequestHeaders> = {
    'x-aimi-investor-reference': process.env.NEXT_PUBLIC_GILION_INVESTOR_REFERENCE,
    'x-gilion-client': 'AIM',
  };
  if (arkUserId) {
    headers['x-aimi-user-reference' as string] = arkUserId;
  }

  return serverRequest<T>({
    method,
    url,
    data: body,
    headers,
  });
};

export const businessDataRequest = <T>(
  route: string,
  method: Method = 'GET',
  body?: Record<string, unknown>,
): Promise<T> => {
  const businessDataUrl = process.env.NEXT_PUBLIC_BUSINESS_DATA_URL;
  const url: string = businessDataUrl + route;

  return serverRequest<T>({
    method,
    url,
    data: body,
  });
};

export const formatter = new Intl.DateTimeFormat('en', { month: 'short', year: 'numeric', day: 'numeric' });

const mutateGridCells = (
  hasForecasts: boolean,
  cells: DataCell[],
  date: string,
  value?: number | null,
  forecastValue?: number | null,
) => {
  const parsed = new Date(date);

  // we don't want to push forecast values for covenants since we don't support forecast there
  if (!hasForecasts) {
    cells.push({
      value: value ?? null,
      date,
      forecast: false,
      id: date + '-actual',
    });
  } else if (isFuture(parsed)) {
    cells.push({
      value: forecastValue ?? null,
      date,
      forecast: true,
      id: date + '-forecast',
      forecastName: 'base_case',
    });
  } else {
    cells.push(
      {
        value: value ?? null,
        date,
        forecast: false,
        id: date + '-actual',
      },
      {
        value: forecastValue ?? null,
        date,
        forecast: true,
        id: date + '-forecast',
        forecastName: 'base_case',
      },
    );
  }
};

export const createCovenantGridCells = (from: string, dataPoints: DataPoint[]) => {
  const formatting = new Intl.DateTimeFormat('sv-SE', { month: '2-digit', day: '2-digit', year: 'numeric' });

  const cells: DataCell[] = [];
  let iterator = 0;

  const startMonth = getMonth(new Date(from)) + 1;
  const startYear = getYear(new Date(from));

  const endMonth = getMonth(new Date()) + 1;
  const endYear = getYear(new Date());

  for (let year = startYear; year <= endYear; year++) {
    // Determine the start and end months for the current year
    const currentStartMonth = year === startYear ? startMonth : 1;
    const currentEndMonth = year === endYear ? endMonth : 12;

    // Loop through the months for the current year
    for (let month = currentStartMonth; month <= currentEndMonth; month++) {
      const currentDataPoint = dataPoints[iterator];
      const dataPointMonth = getMonth(currentDataPoint?.date) + 1;
      const dataPointYear = getYear(currentDataPoint?.date);

      if (currentDataPoint && dataPointMonth === month && dataPointYear === year) {
        // add value of data point to the correct cell
        // check if we have values for both actual and forecast value so we can push them into our data cell array
        const cellValue =
          dataPoints.find((item) => item.date === currentDataPoint.date && !item.is_forecast)?.value ?? '';

        const forecastCellValue =
          dataPoints.find((item) => item.date === currentDataPoint.date && item.is_forecast)?.value ?? '';

        const cellDate = formatting.format(new Date(dataPointYear, dataPointMonth - 1, 1));
        mutateGridCells(false, cells, cellDate, parseFloat(cellValue), parseFloat(forecastCellValue));

        //increase the iterator with the number of values we find in the data points
        iterator = iterator + dataPoints.filter((item) => item.date === currentDataPoint.date).length;
      } else {
        const cellDate = formatting.format(new Date(year, month - 1, 1));
        mutateGridCells(false, cells, cellDate, null, null);
      }
    }
  }

  return cells;
};

export const createKeyFinancialGridCells = (
  from: string,
  to: string,
  dataPoints: DataPoint[],
  isKeyFinancials = false,
) => {
  const cells: DataCell[] = [];
  let iterator = 0;

  const fromUTC = toUTCDate(startOfDay(new Date(from)));
  const toUTC = toUTCDate(startOfDay(new Date(to)));

  for (let d = fromUTC; d <= toUTC; d.setUTCMonth(d.getUTCMonth() + 1)) {
    const currentDataPoint = dataPoints[iterator];
    // if data point is before table start date
    if (currentDataPoint && isBefore(new Date(currentDataPoint.date), fromUTC)) {
      // set UTC back one month (i.e., re-run for loop for the same date again)
      d.setUTCMonth(d.getUTCMonth() - 1);
      // increase iterator to check the NEXT data point from the backend
      iterator++;
    } else if (
      currentDataPoint &&
      isSameMonth(new Date(currentDataPoint.date), d) &&
      isSameYear(new Date(currentDataPoint.date), d)
    ) {
      // add value of data point to the correct cell
      const dataPointDate = currentDataPoint.date;

      // check if we have values for both actual and forecast value so we can push them into our data cell array
      const cellValue =
        dataPoints.find((item) => item.date === currentDataPoint.date && !item.is_forecast)?.value ?? '';

      const forecastCellValue =
        dataPoints.find((item) => item.date === currentDataPoint.date && item.is_forecast)?.value ?? '';

      mutateGridCells(isKeyFinancials, cells, dataPointDate, parseFloat(cellValue), parseFloat(forecastCellValue));

      //increase the iterator with the number of values we find in the data points
      iterator = iterator + dataPoints.filter((item) => item.date === currentDataPoint.date).length;
    } else {
      // no value in the backend for a certain cell, just insert undefined, one forecasted and one actual
      const cellDate = d.toISOString();

      mutateGridCells(isKeyFinancials, cells, cellDate, null, null);
    }
  }

  return cells;
};

export const createEmptyTableCells = (
  metrics: string[],
  from: string,
  to: string,
  settings: ValidBusinessDataSettingsResponse,
): FinancialData => {
  const tableData: FinancialData = {};
  metrics.map((metric) => {
    tableData[metric] = {
      cells: createKeyFinancialGridCells(from, to, [], true),
      breakdownData: {},
      unit: settings.data.units_for_metrics[metric],
    };
  });

  return tableData;
};

export const fillTableCellsWithData = (
  financialData: ApiBusinessData[],
  from: string,
  to: string,
  allMetrics: string[],
  emptyTableCells: FinancialData,
): FinancialData => {
  const tableCellsWithData = emptyTableCells;
  financialData.map((metric) => {
    const cellsWithValues = createKeyFinancialGridCells(from, to, metric.data_points, true);
    const { metric: metricName, currency, unit, market } = metric;

    if (allMetrics.includes(metricName)) {
      if (metric.market === 'ALL') {
        tableCellsWithData[metricName] = { ...tableCellsWithData[metricName], unit, currency, cells: cellsWithValues };
      } else {
        tableCellsWithData[metricName].breakdownData[market] = { cells: cellsWithValues };
      }
    }
  });

  return tableCellsWithData;
};

export interface BusinessDataResponse<TData> {
  error: boolean;
  message: string;
  data: TData;
}
