import { useEffect, type MutableRefObject } from 'react';
import { type SciChartSurface } from 'scichart';
import type { XAxisConfig } from '../utils/axes/x-axis.utils';
import { updateXAxis } from '../utils/axes/x-axis.utils';
import type { YAxisConfig } from '../utils/axes/y-axis.utils';
import { updateYAxis } from '../utils/axes/y-axis.utils';
import type { ChartDataset, ChartViewMode } from '../types';

export interface ChartSlider {
  defaultMin?: string;
  defaultMax?: string;
  onSliderChange?: SliderChangeEvent;
}

export type SliderChangeEvent = (sliderChangeEventData: SliderChangeEventData) => void;

// This can be simplified, looks like this until we have changed aim-components
interface SliderChangeEventData<
  SliderData = {
    label: string;
  },
> {
  min: SliderData;
  max: SliderData;
  changedByUser: boolean;
}

type UseAxesProps<TAxisConfig extends YAxisConfig | XAxisConfig> = {
  axes: Array<TAxisConfig>;
  surfaceRef: MutableRefObject<SciChartSurface | null>;
  mode: ChartViewMode;
  isInitialized: boolean;
  datasets?: ChartDataset[];
};

export const useYAxes = ({ axes: yAxes, surfaceRef, mode, isInitialized, datasets }: UseAxesProps<YAxisConfig>) => {
  useEffect(() => {
    if (!isInitialized || !surfaceRef.current) return;
    const currentNumberOfYAxes = surfaceRef.current.yAxes.size();

    for (let i = 0; i < Math.min(currentNumberOfYAxes, yAxes.length); i++) {
      const axis = surfaceRef.current.yAxes.get(i);
      const config = yAxes[i];

      const yAxisisUsed = datasets ? datasets.some((dataset) => dataset.yAxisId === config.id) : true;

      updateYAxis({ axis, config, mode, hide: !yAxisisUsed });
    }
  }, [isInitialized, surfaceRef, yAxes, mode, datasets]);
};

export const useXAxes = ({ axes: xAxes, surfaceRef, mode, isInitialized }: UseAxesProps<XAxisConfig>) => {
  useEffect(() => {
    if (!isInitialized || !surfaceRef.current || !xAxes) return;

    const currentNumberOfXAxes = surfaceRef.current.xAxes.size();

    for (let i = 0; i < Math.min(currentNumberOfXAxes, xAxes.length); i++) {
      const axis = surfaceRef.current.xAxes.get(i);
      const config = xAxes[i];

      updateXAxis({ axis, config, mode });
    }
  }, [isInitialized, surfaceRef, xAxes, mode]);
};
