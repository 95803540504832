import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ModalOverlay } from 'aim-components';
import { useMemo, type ComponentProps } from 'react';
import styles from './CheckoutModal.module.css';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!) as ComponentProps<
  typeof EmbeddedCheckoutProvider
>['stripe'];

interface CheckoutModalProps extends Omit<ComponentProps<typeof ModalOverlay>, 'children'> {
  clientSecret: string | null;
}

export const CheckoutModal = ({ clientSecret, open, onClose }: CheckoutModalProps) => {
  const options = useMemo(() => ({ clientSecret }), [clientSecret]);

  return (
    <ModalOverlay open={open} onClose={onClose}>
      <div className={styles.checkoutWrapper}>
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </ModalOverlay>
  );
};
