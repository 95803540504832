interface WizardStepContentProps {
  contentBlocks: Array<string | string[]>;
}

/**
 * Utility component to render score wizard i18n data in a formatted way.
 * Strings are rendered as paragraphs, arrays of strings are rendered as lists.
 */
export const WizardStepContent = ({ contentBlocks }: WizardStepContentProps) => {
  return (
    <>
      {contentBlocks.map((content, index) => {
        if (Array.isArray(content)) {
          return (
            <ul key={index}>
              {content.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          );
        }

        return <p key={index}>{content}</p>;
      })}
    </>
  );
};
