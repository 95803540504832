import type { KeyboardEvent } from 'react';
import { useCallback } from 'react';

export const useTabsNavigation = ({ changeTab }: { changeTab: () => void }) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLLIElement>) => {
      if (e.key === 'Enter') {
        changeTab();
      }
    },
    [changeTab],
  );

  return { handleKeyDown };
};
