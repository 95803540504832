import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { Children, useState, useMemo } from 'react';
import { WizardContext } from './WizardContext';
import { useKeyDown } from '../../utils';
import './Wizard.css';

interface WizardProps {
  children: ReactNode;
  onFinish?: () => void;
}

export const Wizard = ({ children, onFinish }: WizardProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const numberOfSteps = Children.count(children);

  const goToNextStep = useCallback(() => {
    setCurrentStep((currentStep) => {
      return currentStep < numberOfSteps - 1 ? currentStep + 1 : currentStep;
    });
  }, [numberOfSteps]);

  const goToPreviousStep = useCallback(() => {
    setCurrentStep((currentStep) => {
      return currentStep > 0 ? currentStep - 1 : currentStep;
    });
  }, []);

  const finishWizard = useCallback(() => {
    if (onFinish && currentStep === numberOfSteps - 1) {
      onFinish();
    }
  }, [currentStep, numberOfSteps, onFinish]);

  useKeyDown({ keyCode: 'ArrowRight', onKeyDown: goToNextStep });
  useKeyDown({ keyCode: 'ArrowLeft', onKeyDown: goToPreviousStep });
  useKeyDown({ keyCode: 'Enter', onKeyDown: finishWizard });

  const stepContent = useMemo(() => Children.toArray(children)[currentStep], [children, currentStep]);

  const wizardContextValue = useMemo(() => {
    return {
      currentStep,
      numberOfSteps,
      goToNextStep,
      goToPreviousStep,
      finishWizard,
    };
  }, [currentStep, goToNextStep, goToPreviousStep, finishWizard, numberOfSteps]);

  return (
    <div className='Wizard'>
      <WizardContext.Provider value={wizardContextValue}>{stepContent}</WizardContext.Provider>
    </div>
  );
};
