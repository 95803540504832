import { useCallback, useEffect, useState } from 'react';
import { toast } from 'aim-components';
import { useTranslation } from 'next-i18next';
import useSWR, { mutate } from 'swr';
import type { BadgeType } from '../connectors/utils';
import type { InvitedUser } from './types';
import request, { fetcher } from '@/lib/request/request';
import { ApiRoutes } from 'pages/apiRoutes';
import type { Role } from '@/lib/permissions/role';
import { useUserPermissions } from '@/lib/permissions/hooks';
import { useMobile } from 'aim-utils';
import type { NotificationSetting } from '@/api/notifications/settings/index.page';
import { useRouter } from 'next/router';
import type { SettingsTab } from 'pages/[slug]/settings/utils';
import { Routes } from '@/lib/routes';

export function useInviteUser(): (name: string, email: string, role: Role) => Promise<void> {
  const { t } = useTranslation('common', { keyPrefix: 'inviteUser' });

  const sendInvite = useCallback(
    async (name: string, email: string, role: Role) => {
      await request<string>({
        url: ApiRoutes.InviteUser,
        method: 'POST',
        data: { name: name, email: email, role: role },
      })
        .then(() => {
          toast({ message: t('inviteSuccessfullySent'), type: 'success' });
          mutate(ApiRoutes.GetAllInvitedUsers);
        })
        .catch(() => {
          toast({ message: t('inviteNotSent'), type: 'critical', dismissible: true });
        });
    },
    [t],
  );

  return sendInvite;
}

export function useChangeUserRole() {
  const { t } = useTranslation('settings');

  const changeUserRole = useCallback(
    async (id: string, role: Role) => {
      await request<string>({
        url: ApiRoutes.ChangeUserRole,
        method: 'POST',
        data: { role, id },
      })
        .then(() => {
          toast({ message: t('roleSuccessfullyChanged'), type: 'success' });
        })
        .catch((error) => {
          toast({ message: t('couldNotChangeRole'), type: 'critical' });
          console.error(error);
        });
    },
    [t],
  );

  return changeUserRole;
}

export function useDeleteUser() {
  const { t } = useTranslation('settings', { keyPrefix: 'deleteUser' });

  const deleteUser = useCallback(
    async (id: string) => {
      await request<string>({
        url: `${ApiRoutes.DeleteUser}/?id=${id}`,
        method: 'DELETE',
      })
        .then(() => {
          toast({ message: t('userSuccessfullyDeleted'), type: 'success' });
          mutate(ApiRoutes.GetAllInvitedUsers);
        })
        .catch((error) => {
          toast({ message: t('userNotDeleted'), type: 'critical', dismissible: true });
          console.error(error);
        });
    },
    [t],
  );

  return deleteUser;
}

interface UserManagementTableRow {
  name: { fullName: string; email: string; label: string };
  role?: { label: string; badgeType: BadgeType };
  id: string;
  action?: {
    onClick: () => void;
  };
}

export function useUserManagementTable(
  showUserCard: (invitedUser: InvitedUser) => void,
): [activeUsers: UserManagementTableRow[], pendingUsers: UserManagementTableRow[], isLoading: boolean] {
  const { data } = useSWR(ApiRoutes.GetAllInvitedUsers, fetcher<InvitedUser[]>);
  const { t } = useTranslation('settings', { keyPrefix: 'userTable' });
  const { t: tCommon } = useTranslation('common', { keyPrefix: 'roles' });
  const { hasAdminPrivileges } = useUserPermissions();

  const [isLoading, setIsLoading] = useState(true);
  const [activeUsers, setActiveUsers] = useState<UserManagementTableRow[]>([]);
  const [pendingUsers, setPendingUsers] = useState<UserManagementTableRow[]>([]);
  const { mobileView } = useMobile();

  useEffect(() => {
    const mapUser = (user: InvitedUser) => {
      return {
        name: { fullName: user.fullName, email: user.email, label: user.fullName },
        id: user.id,
        action: !mobileView
          ? {
              onClick: () => showUserCard(user),
            }
          : undefined,
        role: hasAdminPrivileges ? { label: tCommon(user.role), badgeType: 'neutral' as BadgeType } : undefined,
      };
    };
    if (data) {
      setIsLoading(false);
      setPendingUsers(
        data
          .filter((user) => !user.emailVerified)
          .map((user) => {
            return mapUser(user);
          }),
      );

      setActiveUsers(
        data
          .filter((user) => user.emailVerified)
          .map((user) => {
            return mapUser(user);
          }),
      );
    }
  }, [data, hasAdminPrivileges, mobileView, showUserCard, t, tCommon]);

  return [activeUsers, pendingUsers, isLoading];
}

export function useRefreshTenantCache(): () => void {
  const { t } = useTranslation('settings', { keyPrefix: 'refreshTenantCache' });

  const refreshTenantCache = useCallback(async () => {
    await request<string>({
      url: ApiRoutes.RefreshTenantCache,
      method: 'POST',
      data: {},
    })
      .then(() => {
        toast({ message: t('successfullyRequestedRefresh'), type: 'success' });
      })
      .catch((error) => {
        toast({ message: t('couldNotRefresh'), type: 'critical' });
        console.error(error);
      });
  }, [t]);

  return refreshTenantCache;
}

export function useJoinDealChannel() {
  const { t } = useTranslation('settings', { keyPrefix: 'joinDealChannel' });

  const joinDealChannel = useCallback(async () => {
    await request<string>({
      url: ApiRoutes.JoinDealChannel,
      method: 'POST',
    })
      .then(() => {
        toast({ message: t('successJoinDealChannel'), type: 'success' });
      })
      .catch((error) => {
        toast({ message: t('couldNotJoin'), type: 'critical' });
        console.error(error);
      });
  }, [t]);

  return { joinDealChannel } as const;
}

export const useNotificationToggle = (settings: NotificationSetting[] | undefined) => {
  const [notificationToggle, setNotificationToggle] = useState<
    {
      reference: string;
      isEnabled: boolean;
      id: string;
    }[]
  >([]);

  useEffect(() => {
    if (settings) {
      setNotificationToggle(
        settings.map((notificationSetting) => {
          return {
            reference: notificationSetting.reference,
            isEnabled: notificationSetting.isEnabled,
            id: notificationSetting.eventId,
          };
        }),
      );
    }
  }, [settings]);

  const onToggle = useCallback(
    async (isEnabled: boolean, reference: string) => {
      await request<NotificationSetting[]>({
        url: ApiRoutes.NotificationSettings,
        method: 'PUT',
        data: {
          isEnabled,
          notificationSettingReferences: notificationToggle.map((notification) => notification.reference),
        },
      })
        .then(() => {
          setNotificationToggle((previousToggles) => {
            return previousToggles.map((toggle) =>
              toggle.reference === reference ? { ...toggle, isEnabled } : toggle,
            );
          });
        })
        .catch(() => toast({ message: 'something went wrong, please try again', type: 'critical' }));
    },
    [notificationToggle],
  );

  return { notificationToggle, onToggle };
};

export const useSettingsNavigation = () => {
  const router = useRouter();

  const goToSettingsTab = useCallback(
    async (tab: SettingsTab) => {
      await router.push(`/${router.query.slug}${Routes.Settings}?tab=${tab}`);
    },
    [router],
  );

  return { goToSettingsTab } as const;
};
