import type {
  CreateCheckoutSessionBody,
  CreateCheckoutSessionResponse,
} from '@/api/subscription-plans/checkout-sessions/index.page';
import { SubscriptionTier, type SubscriptionPlanTier } from '@/api/subscription-plans/types';
import type {
  SubscriptionProduct,
  SubscriptionProductPriceCurrency,
} from '@/api/user/subscription/products/index.page';
import type { SubscriptionPlan } from '@/api/user/subscription/subscriptions/utils';
import { CheckoutModal } from '@/components/subscription-plans/checkout-modal/CheckoutModal';
import { useRefreshSubscription, useStripeCustomerPortal } from '@/components/subscription-plans/hooks';
import {
  findProductPriceByRecurringInterval,
  findSubscriptionProductByTier,
} from '@/components/subscription-plans/utils';
import { FSCloseStripeCheckout, FSInitiateStripeCheckout, FSShowSubscriptionPlans } from '@/lib/fullstory';
import { useTenantPermissions } from '@/lib/permissions/hooks';
import clientRequest, { fetcher } from '@/lib/request/request';
import { Routes } from '@/lib/routes';
import { toast } from 'aim-components';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ApiRoutes } from 'pages/apiRoutes';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

const SubscriptionPlansContext = createContext<{
  subscriptionProducts: { data: SubscriptionProduct[]; isLoading: boolean; error: unknown };
  currentSubscriptionPlan: { data: SubscriptionPlan | undefined; isLoading: boolean; error: unknown };
  changeSubscriptionPlan: (plan: SubscriptionPlanTier) => Promise<void>;
  openStripeCustomerPortal: () => Promise<void>;
  showSubscriptionPlans: ({
    eventSource,
  }: {
    /** The `eventSource` is used for tracking purposes, to get more insights in Fullstory. */
    eventSource: string;
  }) => () => void;
  refreshSubscription: (
    stripeSubscriptionReference: SubscriptionPlan['stripeSubscriptionReference'],
  ) => Promise<SubscriptionPlan | undefined>;
  currency: SubscriptionProductPriceCurrency | undefined;
} | null>(null);

export const useSubscriptionPlansContext = () => {
  const context = useContext(SubscriptionPlansContext);

  if (!context) {
    throw new Error('useSubscriptionPlansContext must be used within SubscriptionPlanProvider');
  }

  return context;
};

export const SubscriptionPlansProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('common', { keyPrefix: 'subscriptionPlan' });
  const { subscriptionsEnabled } = useTenantPermissions();
  const [clientSecret, setClientSecret] = useState<CreateCheckoutSessionResponse['clientSecret']>(null);
  const [showCheckout, setShowCheckout] = useState(false);
  const router = useRouter();

  const { openStripeCustomerPortal } = useStripeCustomerPortal();
  const { refreshSubscription } = useRefreshSubscription();

  const showSubscriptionPlans = useCallback(
    ({ eventSource }: { eventSource: string }) => {
      return async () => {
        FSShowSubscriptionPlans({ eventSource });

        const slug = router.query.slug;
        await router.push(`/${slug}${Routes.SubscriptionPlans}`);
      };
    },
    [router],
  );

  const {
    data: userSubscriptionPlans,
    isLoading: isLoadingUserSubscriptionPlans,
    error: userSubscriptionPlansError,
  } = useSWRImmutable<SubscriptionPlan[]>(subscriptionsEnabled ? ApiRoutes.Subscriptions : null, fetcher);

  const {
    data: subscriptionProducts,
    isLoading: isLoadingSubscriptionProducts,
    error: subscriptionProductsError,
  } = useSWRImmutable<SubscriptionProduct[]>(subscriptionsEnabled ? ApiRoutes.SubscriptionProducts : null, fetcher);

  const changeSubscriptionPlan = useCallback(
    async (tier: SubscriptionPlanTier) => {
      if (tier === SubscriptionTier.Free) return;

      if (!subscriptionProducts) return;

      const plan = findSubscriptionProductByTier(subscriptionProducts, tier);
      if (!plan) return;

      // * Open the checkout with the monthly price, to enable the toggle between monthly and yearly billing.
      const monthlyPrice = findProductPriceByRecurringInterval(plan.prices, 'month');
      if (!monthlyPrice) return;

      FSInitiateStripeCheckout({ tier });

      try {
        const { clientSecret } = await clientRequest<CreateCheckoutSessionResponse>({
          url: ApiRoutes.SubscriptionPlansCheckoutSessions,
          method: 'POST',
          data: { priceId: monthlyPrice.stripeReference } satisfies CreateCheckoutSessionBody,
        });

        setClientSecret(clientSecret);
        setShowCheckout(true);
      } catch (error) {
        console.error(error);
        toast({ message: t('request.createCheckoutSession.error'), type: 'critical' });
      }
    },
    [subscriptionProducts, t],
  );

  /**
   * @returns The currency used for a Stripe subscription product. This is useful for consistent currency formatting.
   * For instance, the Free plan does not exist as a product in Stripe. So we extract the currency (e.g. "EUR") from one of the "real" products from Stripe instead, such as the Plus plan.
   */
  const currency = useMemo(() => {
    if (!Array.isArray(subscriptionProducts)) return;
    if (subscriptionProducts.length === 0) return;

    return subscriptionProducts[0]?.prices?.[0]?.currency;
  }, [subscriptionProducts]);

  const closeCheckout = useCallback(() => {
    FSCloseStripeCheckout();
    setShowCheckout(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      subscriptionProducts: {
        data: subscriptionProducts ?? [],
        isLoading: isLoadingSubscriptionProducts,
        error: subscriptionProductsError,
      },
      currentSubscriptionPlan: {
        data: userSubscriptionPlans?.[0],
        isLoading: isLoadingUserSubscriptionPlans,
        error: userSubscriptionPlansError,
      },
      changeSubscriptionPlan,
      openStripeCustomerPortal,
      showSubscriptionPlans,
      refreshSubscription,
      currency,
    }),
    [
      subscriptionProducts,
      isLoadingSubscriptionProducts,
      subscriptionProductsError,
      userSubscriptionPlans,
      isLoadingUserSubscriptionPlans,
      userSubscriptionPlansError,
      changeSubscriptionPlan,
      openStripeCustomerPortal,
      showSubscriptionPlans,
      refreshSubscription,
      currency,
    ],
  );

  return (
    <SubscriptionPlansContext.Provider value={contextValue}>
      <CheckoutModal clientSecret={clientSecret} open={showCheckout} onClose={closeCheckout} />
      {children}
    </SubscriptionPlansContext.Provider>
  );
};
