import type { Placement, UseFloatingData } from '@floating-ui/react';
import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/react';
import { useState } from 'react';

const DROPDOWN_LIST_GAP_PX = 8;
const DROPDOWN_LIST_MIN_HEIGHT_BEFORE_FLIP_PX = 120;
const DROPDOWN_LIST_MAX_HEIGHT_PX = 500;

type UseSelectProps = {
  placement?: Placement;
};

export const useSelect = ({
  placement = 'bottom-start',
}: UseSelectProps): {
  showOptions: boolean;
  setShowOptions: (showOptions: boolean) => void;
  refs: UseFloatingData['refs'];
  floatingStyles: UseFloatingData['floatingStyles'];
  context: UseFloatingData['context'];
} => {
  const [showOptions, setShowOptions] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: showOptions,
    onOpenChange: setShowOptions,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      size({
        apply({ availableHeight, elements }) {
          const referenceSize = elements.reference.getBoundingClientRect();
          const floatingSize = elements.floating.getBoundingClientRect();

          const rows = elements.floating.querySelectorAll('li');
          const numberOfRows = rows.length;
          const rowHeight = rows[0]?.getBoundingClientRect().height ?? 0;

          const minHeight = Math.min(
            numberOfRows * rowHeight, // * Handles the case when there are few options in the list, e.g. when searching
            DROPDOWN_LIST_MIN_HEIGHT_BEFORE_FLIP_PX,
            floatingSize.height,
          );

          const maxHeight = Math.min(DROPDOWN_LIST_MAX_HEIGHT_PX, availableHeight - DROPDOWN_LIST_GAP_PX * 2);

          Object.assign(elements.floating.style, {
            width: `${referenceSize.width}px`,
            minHeight: `${minHeight}px`,
            maxHeight: `${maxHeight}px`,
          });
        },
      }),
      // * The gap between the dropdown list and the select input
      offset({ mainAxis: DROPDOWN_LIST_GAP_PX }),
      // * Switches the placement of the dropdown list to be displayed above or below the select input, depending on the available space
      flip({ crossAxis: false, padding: DROPDOWN_LIST_GAP_PX }),
    ],
  });

  return {
    showOptions,
    setShowOptions,
    refs,
    floatingStyles,
    context,
  } as const;
};
