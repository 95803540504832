import type { Dispatch, SetStateAction } from 'react';
import type { ChartDataset } from 'aim-components';
import { dateToString, formatValue, toLocalDate, toUTCDate } from 'aim-components';
import { getISOWeek, getQuarter, max, min, subDays } from 'date-fns';
import type { ChartKey, CohortGranularity, FilterKeys, Option, TimeGranularity, TimeGranularityOption } from './types';
import type { BaseDataPoint, CaseName } from '@/api/insights/utils';
import { sum } from 'd3';
import type { HexColor } from 'aim-utils';
import { LineColors, isDefined } from 'aim-utils';
import type { ChartStateWithOptionalNotification } from 'pages/[slug]/notification-link/utils';
import type { z } from 'zod';
import type { ChartNotificationData } from '@/components/insights/charts/common/hooks';

export const roundToDecimals = (val: number | string): number => {
  if (isNaN(val as number)) return val as number;
  return Math.round((val as number) * 100) / 100;
};

export const getPercentage = (numerator: number | undefined, denominator: number | undefined): string => {
  if (!numerator || !denominator) return '0%';
  return ((numerator / denominator) * 100).toFixed(1).toString() + '%';
};

export const SCATTER_COLORS = {
  GRAY_PRIMARY: '#c9c9c9',
};

/* eslint-disable camelcase */
export const caseColorMap: Record<CaseName, HexColor> = {
  '25pcs_worse_retention': LineColors['neon-blue'],
  '25pct_improved_retention': LineColors.azure,
  base_case: LineColors['vibrant-blue'],
  cac_up_10pct: LineColors['electric-purple'],
  double_spend_10pct_higher_cac_25pct_improved_retention: LineColors.gold,
  double_spend_cac_up_10pct: LineColors.flamingo,
  no_new_intake: LineColors.magma,
};

export const regionColorMap: Record<Region, HexColor> = {
  Europe: LineColors['neon-blue'],
  Oceania: LineColors.magma,
  Americas: LineColors.azure,
  Asia: LineColors.flamingo,
  Africa: LineColors.gold,
};

export const REGIONS = ['Europe', 'Oceania', 'Americas', 'Asia', 'Africa'] as const;
export type Region = (typeof REGIONS)[number];

export const defaultCountryOption = { label: 'All countries', value: 'ALL' } as const satisfies Option;

export const parseCountryCode = (countryCode: string): string => {
  if (countryCode === '') return 'Unspecified';
  if (countryCode === 'ALL') return 'All countries';
  if (countryCode === 'OTHER') return 'Other';
  try {
    const countryNames = new Intl.DisplayNames(['en'], { type: 'region' });
    return countryNames.of(countryCode) ?? 'Unspecified';
  } catch {
    return 'Unspecified';
  }
};
export const parseTimespanOption = (timespan: string): string => {
  if (timespan === '') return 'Unspecified';
  if (timespan === 'ALL') return 'All time';
  if (timespan === '90Days') return 'Last 90 days';
  if (timespan === 'thisYear') return 'This Year';
  return timespan;
};

export function getFlagEmoji(countryCode: string): string {
  try {
    if (countryCode === 'ALL') return '';
    if (countryCode === 'UNKNOWN') return '🇺🇳';
    const countryNames = new Intl.DisplayNames(['en'], { type: 'region' });
    countryNames.of(countryCode);

    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map((char) => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
  } catch {
    return '';
  }
}

export const parseUnit = (unit: string): string => {
  if (!unit) return '';
  try {
    const currency = new Intl.NumberFormat('en', { style: 'currency', currency: unit })
      .formatToParts()
      .find((item) => item.type === 'currency');
    return currency ? currency.value : unit;
  } catch {
    return unit;
  }
};

export const dateFormatters = {
  years: new Intl.DateTimeFormat('en', { year: 'numeric' }),
  months: new Intl.DateTimeFormat('en', { year: '2-digit', month: 'short' }),
  days: new Intl.DateTimeFormat('en', { year: '2-digit', month: 'short', day: '2-digit' }),
  shortYear: new Intl.DateTimeFormat('en', { year: '2-digit' }),
  default: new Intl.DateTimeFormat('en', { year: '2-digit', month: 'short' }),
};

export const parseDate = (date: string | number, timeGranularity: TimeGranularity = 'months'): string => {
  const newDate = new Date(date);
  const localDate = toLocalDate(newDate);

  if (isNaN(localDate.getTime())) {
    // ! Invalid date, perform no parsing.
    return date.toString();
  }

  if (timeGranularity === 'quarters') {
    const quarter = getQuarter(localDate);
    const year = localDate.getFullYear();
    return `Q${quarter} ${year}`;
  }
  if (timeGranularity === 'weeks') {
    const week = getISOWeek(localDate);
    return `W. ${week}, ${dateFormatters.shortYear.format(localDate)}`;
  }
  if (timeGranularity === 'years') {
    return `${localDate.getFullYear()}`;
  }

  return dateFormatters[timeGranularity]
    ? dateFormatters[timeGranularity].format(localDate)
    : dateFormatters['default'].format(localDate);
};

export const EarlierDatasetId = 'EarlierDatasetId';
export const createDatasetIdForCohorts = (
  date: string | number,
  timeGranularity: TimeGranularity = 'months',
  breakdown: boolean,
): string => {
  if (date === EarlierDatasetId) {
    if (!breakdown) return 'Earlier years';
    if (timeGranularity === 'months') return 'Earlier months';
    return 'Earlier quarters';
  }

  const newDate = new Date(date);
  const localDate = toLocalDate(newDate);

  if (isNaN(localDate.getTime())) {
    // ! Invalid date, perform no parsing.
    return date.toString();
  }

  if (timeGranularity === 'quarters') {
    const quarter = getQuarter(localDate);
    const year = localDate.getFullYear();
    return `Q${quarter} ${year}`;
  }
  if (timeGranularity === 'weeks') {
    const week = getISOWeek(localDate);
    return `W. ${week}, ${dateFormatters.shortYear.format(localDate)}`;
  }
  if (timeGranularity === 'years') {
    return `${localDate.getFullYear()}`;
  }

  return dateFormatters[timeGranularity]
    ? dateFormatters[timeGranularity].format(localDate)
    : dateFormatters['default'].format(localDate);
};

export const sortCountryOptions = (
  a: { value: string; label: string },
  b: { value: string; label: string },
): number => {
  if (b.value === 'ALL' || parseCountryCode(a.value) > parseCountryCode(b.value)) {
    return 1;
  }
  if (a.value === 'ALL' || parseCountryCode(a.value) < parseCountryCode(b.value)) {
    return -1;
  }
  return 0;
};

export const createCountryOption = (item: string): { label: string; value: string } => {
  return { label: `${getFlagEmoji(item)} ${parseCountryCode(item)}`, value: item };
};

export const sortTimespanOptions = (
  a: { value: string; label: string },
  b: { value: string; label: string },
): number => {
  if (b.value === 'ALL') {
    return 1;
  }
  if (a.value === 'ALL') {
    return -1;
  }
  return 0;
};

export const createTimespanOption = (item: string): { label: string; value: string } => ({
  label: parseTimespanOption(item),
  value: item,
});

export const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
] as const;
export type ShortMonth = (typeof SHORT_MONTHS)[number];

export const LONG_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const;

export const parseShortDate = (dateString: string): string | undefined => {
  const monthMappings = {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
  } as const satisfies Record<ShortMonth, string>;

  const monthKey = Object.keys(monthMappings).find((key) => dateString.includes(key)) as ShortMonth;

  if (monthKey) return dateString.replace(monthKey, `${monthMappings[monthKey]}`);
};

export const capitalizeString = (stringToCapitalize: string): string => {
  if (!stringToCapitalize) return '';
  return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
};

export const getLocalStorageChartStateKey = (chartKey: ChartKey, tenant: string) => {
  return `${tenant}:chart-state:${chartKey}`;
};

export type FilterPresetKey = string;
export const getFilterPresetKey = (chartKey: ChartKey, filterKey: FilterKeys, tenant: string): FilterPresetKey => {
  return `filter:${tenant}:${chartKey}:${filterKey}`;
};

const getLocalStorageChartFilterKeyPrefix = (chartKey: ChartKey, tenant: string): string => {
  return `filter:${tenant}:${chartKey}:`;
};

export const resetLocalStorageChartFilters = (chartKey: ChartKey, tenant: string): void => {
  // * Removes chart state object (reducer state)
  localStorage.removeItem(getLocalStorageChartStateKey(chartKey, tenant));

  // * Removes all individual chart filter states (legacy)
  const prefix = getLocalStorageChartFilterKeyPrefix(chartKey, tenant);
  Object.keys(localStorage)
    .filter((key) => key.startsWith(prefix))
    .forEach((key) => localStorage.removeItem(key));
};

type SubtitlePart =
  | {
      label: string | Option | undefined;
    }
  | {
      labels: string[] | Option[];
      suffix: string;
    };

const getLabel = (label: string | Option | undefined): string => {
  if (typeof label === 'string') return label ?? '';
  return label?.label ?? '';
};

// Used to remove flags etc.
const removeUnicodeCharacters = (str: string): string => {
  return str
    .split('')
    .filter((char) => char.charCodeAt(0) <= 255)
    .join('');
};

export const generateSubtitle = (...parts: SubtitlePart[]): string[] => {
  return parts
    .map((part) => {
      if ('label' in part) return getLabel(part.label);

      const { labels, suffix } = part;
      if (labels.length === 0) return '';
      if (labels.length === 1) return getLabel(labels[0]);
      return `Showing: ${labels.length} ${suffix}`;
    })
    .filter((part) => part !== '')
    .map(removeUnicodeCharacters)
    .map((str) => str.trim());
};

export enum EarlierLabel {
  Years = 'Earlier years',
  Quarters = 'Earlier quarters',
  Months = 'Earlier months',
}

export const setOption = (
  allOptions: Option[],
  option: Option | Option['value'],
  setOption: Dispatch<SetStateAction<Option>>,
): void => {
  const optionValue = typeof option === 'string' ? option : option?.value;
  const optionToSet = allOptions?.find((o) => o?.value === optionValue);
  if (optionToSet) setOption(optionToSet);
};

export const setOptionsByValues = (allOptions: Option[], setOptions: Dispatch<SetStateAction<Option[]>>) => {
  return (values: Option['value'][]) => {
    const optionsToSet = allOptions?.filter((o) => values.includes(o?.value));
    setOptions(optionsToSet);
  };
};

export const getTimeRange = (xMin: string, xMax: string, xAxisStartDate?: string, useDataMax?: boolean) => {
  const today = new Date();

  return {
    xMin: min([new Date(xMin), new Date(xAxisStartDate ?? xMin)]),
    xMax: useDataMax
      ? new Date(xMax)
      : max([
          new Date(xMax),
          toUTCDate(subDays(new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()), 1)),
        ]),
  };
};

export const getTimeRangeFromDatasets = (datasets: ChartDataset[]) => {
  const xValues = datasets
    .flatMap((dataset) => dataset.data.map((data) => (data.y === null ? null : new Date(data.x))))
    .filter(isDefined);

  if (xValues.length === 0) return { xMin: undefined, xMax: undefined } as const;

  const xMin = min(xValues).toISOString();
  const xMax = max(xValues).toISOString();

  return { xMin, xMax } as const;
};

export const getChartReadyState = ({
  hasError,
  hasNoData,
  isLoading,
}: {
  hasError: () => boolean | undefined;
  hasNoData: () => boolean | undefined;
  isLoading: () => boolean | undefined;
}) => {
  const baseReadyState = {
    ERROR: false,
    NO_DATA: false,
    LOADING: false,
    READY: false,
  } as const;

  // ? IIFE to get named return value ("chartReadyState"), for consistent naming in charts.
  const chartReadyState = (() => {
    if (hasError()) return { ERROR: true } as const;
    if (hasNoData()) return { NO_DATA: true } as const;
    if (isLoading()) return { LOADING: true } as const;
    return { READY: true } as const;
  })();

  return { chartReadyState: { ...baseReadyState, ...chartReadyState } };
};

export const getLastDatasetValue = <TDataset extends ChartDataset>({
  datasets,
  datasetLabel,
}: {
  datasets: TDataset[];
  datasetLabel: TDataset['label'];
}) => {
  const dataset = datasets.find((dataset) => dataset.label === datasetLabel);
  if (!dataset) return null;
  return dataset.data[dataset.data.length - 1]?.y ?? null;
};

export const getLastFormattedDatasetValue = <TDataset extends ChartDataset>({
  datasets,
  datasetLabel,
  unit,
}: {
  datasets: TDataset[];
  datasetLabel: TDataset['label'];
  unit: string;
}) => {
  const lastValue = getLastDatasetValue({ datasets, datasetLabel });
  if (lastValue === null) return null;
  return formatValue(lastValue, unit);
};

export const TimeGranularityOptionsMap = {
  years: { label: 'Years', value: 'years' },
  quarters: { label: 'Quarters', value: 'quarters' },
  months: { label: 'Months', value: 'months' },
  weeks: { label: 'Weeks', value: 'weeks' },
  days: { label: 'Days', value: 'days' },
} as const satisfies Record<TimeGranularity, TimeGranularityOption>;

const FORMATTED_VALUE_FALLBACK = '-';
export const formatMobileTooltipValue = (value: number | undefined, unitSymbol: string) => {
  return value ? formatValue(value, unitSymbol) : FORMATTED_VALUE_FALLBACK;
};

export const formatMobileTooltipValueWithPercentage = (
  value: number | undefined,
  total: number | undefined,
  unitSymbol: string,
) => {
  const formattedValue = formatMobileTooltipValue(value, unitSymbol);

  if (total === undefined || value === total || formattedValue === FORMATTED_VALUE_FALLBACK) {
    return formattedValue;
  }

  const percentage = getPercentage(value, total);
  return `${formattedValue} (${percentage})`;
};

export const getMaxDataPoint = (chartDataset: BaseDataPoint[], xMaxDateString?: string) => {
  return filterData(chartDataset, xMaxDateString).reduce(
    (maxIndex, currentValue, currentIndex, array) =>
      currentValue.value && currentValue.value > (array[maxIndex].value ?? currentValue.value)
        ? currentIndex
        : maxIndex,
    0,
  );
};

export const filterData = (data: BaseDataPoint[], xMaxDateString?: string) => {
  if (!xMaxDateString) return data;
  return (data ?? []).filter(({ date }) => new Date(date) <= new Date(xMaxDateString));
};

export const getIndexOfLargestStack = (dataArrays: ChartDataset['data'][]) => {
  const indexOfPointWithLargestValue = dataArrays.reduce(
    (maxIndex, currentValue, currentIndex, array) => {
      return getLargestStack(currentValue, dataArrays.slice(0, currentIndex + 1)).sum >
        getLargestStack(array[maxIndex], dataArrays.slice(0, maxIndex + 1)).sum
        ? currentIndex
        : maxIndex;
    },

    0,
  );

  return {
    datasetIndex: indexOfPointWithLargestValue,
    dataIndex: getLargestStack(dataArrays[indexOfPointWithLargestValue], dataArrays).index,
  };
};

const getLargestStack = (data: ChartDataset['data'], dataArrays: ChartDataset['data'][]) => {
  if (!data) return { sum: 0, index: undefined };
  const index = data.reduce((maxIndex, currentValue, currentIndex, array) => {
    return getStackSum(currentValue, dataArrays) > getStackSum(array[maxIndex], dataArrays) ? currentIndex : maxIndex;
  }, 0);

  return { sum: getStackSum(data[index], dataArrays), index };
};

const getStackSum = (
  point: {
    x: string | number;
    y: number | null;
  },
  dataArrays: ChartDataset['data'][],
) => {
  return sum(
    dataArrays
      .flat()
      .filter(({ x, y }) => x === point?.x && +(y ?? 0) > 0)
      .map(({ y }) => y),
  );
};

export const getPreviousTimeGranularity = <T extends TimeGranularity>(
  timeGranularities: ReadonlyArray<T>,
  timeGranularity: T,
) => {
  const index = timeGranularities.findIndex((granularity) => granularity === timeGranularity);
  return timeGranularities[Math.max(0, index - 1)];
};

export const getNextTimeGranularity = <T extends TimeGranularity>(
  timeGranularities: ReadonlyArray<T>,
  timeGranularity: T,
) => {
  const index = timeGranularities.findIndex((granularity) => granularity === timeGranularity);
  return timeGranularities[Math.min(timeGranularities.length - 1, index + 1)];
};

export const isTimeGranularity = (value: string): value is TimeGranularity => {
  return value in TimeGranularityOptionsMap;
};

export const addNotificationDataLabelToDatasets = ({
  datasets,
  dataLabel,
}: {
  datasets: ChartDataset[];
  dataLabel: ChartNotificationData | undefined;
}) => {
  if (!datasets || !dataLabel) return;

  const dataLabelDate = new Date(dataLabel.date);

  return datasets.map((dataset) => {
    if (Array.isArray(dataLabel.attachToDatasetIds) && !dataLabel.attachToDatasetIds.includes(dataset.datasetId)) {
      return dataset;
    }

    const xDataPoints = dataset.data.map(({ x }) => x);
    const index = xDataPoints.findIndex((x) => dateToString(new Date(x)) === dateToString(dataLabelDate));

    if (index === -1) return dataset;

    return {
      ...dataset,
      dataLabelsAtSliderMax: false,
      dataLabels: [{ index, label: dataLabel.label }],
    };
  });
};

export const parseChartUrlStateWithOptionalNotification = <TChartState>(
  state: ChartStateWithOptionalNotification<TChartState>,
) => {
  if ('notification' in state && state.notification) {
    const { notification, ...chartState } = state;
    return { chartState, notification };
  }

  return { chartState: state, notification: null };
};

/**
 * Custom Zod schema validator that extracts only the valid (schema-compliant) fields from an object.
 * Invalid fields are **excluded** from the returned object.
 */
export const extractValidPartialObject = <TSchema extends z.SomeZodObject>(
  objectSchema: TSchema,
  objectData: unknown,
) => {
  if (typeof objectData !== 'object' || objectData === null) return {} as Partial<z.infer<TSchema>>;

  return Object.entries(objectData).reduce(
    (acc, [key, value]) => {
      if (!(key in objectSchema.shape)) return acc;

      const validationResult = objectSchema.shape[key].safeParse(value);

      if (validationResult.success) {
        return { ...acc, [key]: validationResult.data };
      }

      return acc;
    },
    {} as Partial<z.infer<TSchema>>,
  );
};

export const updateCohortGranularity = (
  datasets: Option<string>[],
  cohortTab: Record<CohortGranularity, string>,
  currentGranularity: CohortGranularity,
  dispatch: Dispatch<{ type: 'SET_COHORT_GRANULARITY'; cohortGranularity: CohortGranularity }>,
) => {
  if (datasets.length === 0) return;

  const cohortGranularities = Object.keys(cohortTab) as CohortGranularity[];
  const selectedCohortGranularity = cohortGranularities.find((key) => cohortTab[key] === datasets[0].tab);

  if (selectedCohortGranularity && selectedCohortGranularity !== currentGranularity) {
    dispatch({ type: 'SET_COHORT_GRANULARITY', cohortGranularity: selectedCohortGranularity });
  }
};
