import { MemoryUsageHelper } from 'scichart';
import type { MemoryUsageHistory } from './MemoryDebugger';

export const getMemoryDebugData = () => {
  const { undeletedObjectsMap, uncollectedObjectsMap } = MemoryUsageHelper.objectRegistry;

  const undeletedIds = Array.from(undeletedObjectsMap.keys());
  const uncollectedIds = Array.from(uncollectedObjectsMap.keys());
  const collectedNotDeletedIds = undeletedIds.filter((id) => !uncollectedObjectsMap.has(id));
  const deletedNotCollectedIds = uncollectedIds.filter((id) => !undeletedObjectsMap.has(id));

  return {
    undeletedIds,
    uncollectedIds,
    collectedNotDeletedIds,
    deletedNotCollectedIds,
  };
};

export const getAverage = (arr: number[]) => {
  return arr.reduce((acc, val) => acc + val, 0) / arr.length;
};

export const getMemoryHistoryUpdater = ({ history, capacity }: { history: MemoryUsageHistory; capacity: number }) => {
  return (key: keyof MemoryUsageHistory, value: number) => {
    const { values: previousValues, peak: previousPeak } = history[key];

    return {
      values: [...previousValues, value].slice(-capacity),
      peak: previousPeak === undefined ? value : Math.max(previousPeak, value),
    };
  };
};

/**
 * The ID is a combination of the type and a unique identifier.
 *
 * @example getObjectTypeFromId('XyDataSeries_80e3905a-4ed6-4418-95d8-2c919de5b5a3') // => 'XyDataSeries'
 */
const getObjectTypeFromId = (id: string) => id.split('_')[0];

/**
 * Returns a record with the object types as keys and the count of objects of that type as values, sorted by count in descending order.
 *
 * @example getObjectTypeSortedCountMap(['XyDataSeries_80e3905a-4ed6-4418-95d8-2c919de5b5a3', 'XyDataSeries_80e3905a-4ed6-4418-95d8-2c919de5b5a3', 'XyScatterRenderableSeries_80e3905a-4ed6-4418-95d8-2c919de5b5a3']) // => { XyDataSeries: 2, XyScatterRenderableSeries: 1 }
 */
const getObjectTypeSortedCountMap = (ids: string[]) => {
  const objectCountMap = ids.reduce<Record<string, number>>((objectTypeCountMap, id) => {
    const objectType = getObjectTypeFromId(id);
    objectTypeCountMap[objectType] ??= 0;
    objectTypeCountMap[objectType]++;

    return objectTypeCountMap;
  }, {});

  const sortedEntries = Object.entries(objectCountMap).sort(([, aCount], [, bCount]) => bCount - aCount);

  return Object.fromEntries(sortedEntries);
};

export const logObjectRegistry = () => {
  /* eslint-disable no-console */
  console.groupCollapsed('Full object registry');
  MemoryUsageHelper.objectRegistry.log();
  console.groupEnd();

  const { collectedNotDeletedIds } = getMemoryDebugData();
  if (collectedNotDeletedIds.length === 0) return;

  const objectTypeSortedCountMap = getObjectTypeSortedCountMap(collectedNotDeletedIds);

  console.group('Collected not deleted (object types, sorted)');
  console.table(objectTypeSortedCountMap);
  console.groupEnd();
  /* eslint-enable no-console */
};
