import type { ReactElement } from 'react';
import React from 'react';
import type { PillProps } from './Pill';
import { Pill } from './Pill';
import './PillRow.css';

interface PillRowProps {
  pills: PillProps[];
  onPillClose: (pill: PillProps) => void;
}

export const PillRow = ({ pills, onPillClose }: PillRowProps): ReactElement | null => {
  const [firstPill, ...otherPills] = pills ?? [];

  if (pills.length === 0) return null;

  return (
    <div className='PillRow'>
      {firstPill && (
        <Pill
          key={firstPill.label}
          label={firstPill.label}
          icon={firstPill.icon}
          onClose={() => onPillClose(firstPill)}
        />
      )}
      {otherPills.length > 0 && <Pill label={`+${otherPills.length}`} notShowOnFocus />}
    </div>
  );
};
