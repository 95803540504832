/* eslint-disable camelcase */
import type { AxiosError } from 'axios';
import type { NextApiRequest, NextApiResponse } from 'next';
import type { ValidBusinessDataSettingsResponse as ValidBusinessDataSettingsResponse } from './settings/valid/index.page';
import { businessDataRequest, createEmptyTableCells, fillTableCellsWithData } from './utils';
import { handleApiError } from '@/lib/request/error';
import { ExternalRoutes } from 'pages/apiRoutes';
import { addMonths, startOfMonth, subMonths } from 'date-fns';
import type { BusinessDataSettingsResponse as BusinessDataSettingsResponse } from './settings/index.page';
import { getAimHeaders } from '@/lib/utils';
import { z } from 'zod';
import { validateRequestBody } from '@/api/validators';

export const AllMetrics = {
  RecurringNetRevenue: 'Recurring Net Revenue',
  NonRecurringNetRevenue: 'Non-Recurring Net Revenue',
  OtherIncome: 'Other Income',
  CostOfGoodsSold: 'Cost of Goods Sold (COGS)',
  ResearchAndDevelopmentExpenses: 'Research & Development Expenses (R&D)',
  SalesAndMarketingExpenses: 'Sales and Marketing Expenses',
  GeneralAdminOtherOperatingExpenses: 'General, Admin and Other Operating Expenses',
  CapitalizedRD: 'Capitalized R&D',
  FixedAssets: 'Fixed Assets',
  CurrentAssets: 'Current Assets (excluding Cash)',
  CashAndEquivalents: 'Cash and Equivalents',
  Equity: 'Equity',
  InterestBearingDebt: 'Interest-bearing Debt',
  NonCurrentLiabilities: 'Non-current Liabilities',
  CurrentLiabilities: 'Current Liabilities',
  CashFlowInFromOperatingActivities: 'Cash Flow In From Operating Activities',
  CashFlowOutFromOperatingActivities: 'Cash Flow Out From Operating Activities',
  CashFlowFromInvestingActivities: 'Cash Flow From Investing Activities (Capex)',
  CashFlowFromFinancingActivities: 'Cash Flow From Financing Activities',
  InvoicedAmount: 'Invoiced Amount',
  NumberOfActiveCustomers: 'Number of Active Customers',
  DirectMarketingSpend: 'Direct Marketing Spend',
  IndirectMarketingSpend: 'Indirect Marketing Spend',
} as const satisfies Record<string, string>;

export type Metric = (typeof AllMetrics)[keyof typeof AllMetrics];

const rawDataPointSchema = z.object({
  value: z.string().nullable(),
  date: z.string(),
  is_forecast: z.boolean().optional(),
  forecast_name: z.enum(['base_case']).optional(),
});

const dataPointPostSchema = z.object({
  value: z.number().nullable(),
  date: z.string(),
  is_forecast: z.boolean().optional(),
  forecast_name: z.enum(['base_case']).optional(),
});

export type DataPointPost = z.infer<typeof dataPointPostSchema>;

const apiBusinessDataSchema = z.object({
  metric: z.nativeEnum(AllMetrics),
  market: z.string(),
  currency: z.string().optional(),
  data_points: rawDataPointSchema.array(),
  unit: z.string().optional(),
});

const businessDataBodySchema = z.object({
  data: apiBusinessDataSchema
    .extend({
      data_points: dataPointPostSchema.array(),
    })
    .array(),
});

export type ApiBusinessData = z.infer<typeof apiBusinessDataSchema>;
export type DataPoint = z.infer<typeof rawDataPointSchema>;

export interface ApiBusinessDataResponse {
  error: boolean;
  message: string;
  data: { tenant_project_code: string; data: ApiBusinessData[] };
}

export interface ApiBusinessDataBody {
  tenant_project_code: string;
  user_id: string;
  data: ApiBusinessData[];
}

export interface DataCell {
  value?: number | null;
  id: string;
  hasBeenChanged?: boolean;
  forecast?: boolean;
  date: string;
  forecastName?: 'base_case';
  firstReportDate?: string;
}

export type BreakdownData = Record<string, { cells: DataCell[] }>;

export type ReportingInterval = 'quarterly' | 'monthly';

export type FinancialData = Record<
  string,
  {
    cells: DataCell[];
    unit?: string;
    currency?: string;
    breakdownData: BreakdownData;
    reportingInterval?: ReportingInterval;
    firstReportDate?: string;
  }
>;

const extendedMetrics: Metric[] = Object.values(AllMetrics);
const defaultMetrics: Metric[] = Object.values([
  AllMetrics.RecurringNetRevenue,
  AllMetrics.NonRecurringNetRevenue,
  AllMetrics.OtherIncome,
  AllMetrics.CostOfGoodsSold,
  AllMetrics.ResearchAndDevelopmentExpenses,
  AllMetrics.SalesAndMarketingExpenses,
  AllMetrics.GeneralAdminOtherOperatingExpenses,
  AllMetrics.CashAndEquivalents,
  AllMetrics.NumberOfActiveCustomers,
  AllMetrics.InvoicedAmount,
  AllMetrics.DirectMarketingSpend,
  AllMetrics.IndirectMarketingSpend,
]);

const NO_OF_FROM_AND_FUTURE_MONTHS = 36;

export default async function handler(
  req: NextApiRequest,
  res: NextApiResponse<FinancialData | unknown>,
): Promise<void> {
  const { projectCode } = getAimHeaders(req.headers);
  const today = new Date();
  const from = subMonths(today, NO_OF_FROM_AND_FUTURE_MONTHS);
  const to = addMonths(today, NO_OF_FROM_AND_FUTURE_MONTHS);
  const fromAsStr = startOfMonth(from).toUTCString();
  const toAsStr = startOfMonth(to).toUTCString();

  if (req.method === 'GET') {
    await businessDataRequest<ApiBusinessDataResponse>(
      ExternalRoutes.BusinessData.replace('{project_code}', projectCode),
    )
      .then(async (response) => {
        const validSettings = await businessDataRequest<ValidBusinessDataSettingsResponse>(
          ExternalRoutes.ValidBusinessDataSettings,
        )
          .then((res) => res)
          .catch(() => undefined);
        if (!validSettings) {
          res.status(500);
          return;
        }

        const businessDataSettings = await businessDataRequest<BusinessDataSettingsResponse>(
          ExternalRoutes.BusinessDataSettings.replace('{project_code}', projectCode),
        )
          .then((res) => res.data)
          .catch(() => undefined);

        const extendedKeyFinancialList = !!businessDataSettings?.extended_key_financial_list;
        const metricList = extendedKeyFinancialList
          ? validSettings.data.metrics
          : validSettings.data.metrics.filter((metric) => defaultMetrics.includes(metric as Metric));
        metricList.sort((a, b) => extendedMetrics.indexOf(a as Metric) - extendedMetrics.indexOf(b as Metric));

        const emptyTableCells = createEmptyTableCells(metricList, fromAsStr, toAsStr, validSettings);

        if (!response?.data?.data) {
          res.status(200).json(emptyTableCells);
          return;
        }

        const tableCellsWithData = fillTableCellsWithData(
          response.data.data,
          fromAsStr,
          toAsStr,
          metricList,
          emptyTableCells,
        );
        res.status(200).json(tableCellsWithData);
        return;
      })
      .catch((error: AxiosError) => handleApiError(res, error));
  }

  if (req.method === 'POST') {
    const { arkUserId, projectCode } = getAimHeaders(req.headers);
    const { parsedBody } = validateRequestBody(businessDataBodySchema, req);
    if (!parsedBody.success) return parsedBody.sendInvalidBodyResponse(res);

    const { data: businessDataArray } = parsedBody.data;

    const body = {
      tenant_project_code: projectCode,
      user_id: arkUserId,
      data: businessDataArray,
    };

    await businessDataRequest<unknown>(ExternalRoutes.BusinessData, 'POST', body)
      .then((response) => res.status(200).json(response))
      .catch((error: AxiosError) => handleApiError(res, error));
  }
}
