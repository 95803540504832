import type { MouseEvent } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import React from 'react';
import cn from 'classnames';
import './BottomSheetOverlay.css';

type BottomSheetOverlayProps = {
  onClose?: () => void;
  open: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const BottomSheetOverlay = ({ onClose, open, ...rest }: BottomSheetOverlayProps) => {
  const backdropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  const handleBackdropClick = (e: MouseEvent<HTMLDivElement>) => {
    if (backdropRef.current === e.target && onClose) onClose();
  };

  return (
    <div
      ref={backdropRef}
      className={cn('BottomSheet-overlay', open ? 'open' : 'closed')}
      onClick={handleBackdropClick}
      {...rest}
    ></div>
  );
};
