import { createContext } from 'react';

export type InsightCategory =
  | 'growth'
  | 'marketingEfficiency'
  | 'monetisation'
  | 'retention'
  | 'financialStrength'
  | 'activity'
  | 'scores'
  | 'benchmarking'
  | 'dataValidation';

export const InsightContext = createContext<{
  category: InsightCategory;
  FSEventOnExpandInsight: () => void;
  missingChartDataIndicator?: {
    display: boolean;
    onClick: (chartTitle?: string, nonReliableForecast?: boolean) => void;
    tooltipMessage: string;
  };
} | null>(null);
