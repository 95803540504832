import { useEffect, useRef, useState } from 'react';

export const useResizeObserver = <T extends HTMLElement>() => {
  const elementRef = useRef<T | null>(null);

  const [elementSize, setElementSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const element = elementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver(([element]) => {
      const { width, height } = element.contentRect;

      setElementSize({ width, height });
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, [elementRef]);

  return [elementRef, elementSize] as const;
};
