import { CommonValidator, validateRequestQuery } from '@/api/validators';
import { arrowRequest } from '@/lib/request/request';
import { getAimHeaders } from '@/lib/utils';
import type { NextApiRequest, NextApiResponse } from 'next';
import { z } from 'zod';
import type { BaseDataPoint } from '../utils';
import { getDimension, getUnit, mapTimeGranularityToDateDimension } from '../utils';
import { handleApiError } from '@/lib/request/error';
import {
  getMetricBase,
  getMetricMoM,
  getMetricYoY,
  GROWTH_RATE_WINDOW_SIZES,
  GROWTH_TIME_GRANULARITIES,
  type GrowthRateArrowRow,
} from './utils';

export const config = {
  api: {
    responseLimit: false, // Mutes response size limit warnings, which can cause noise in the logs.
  },
};

const activeCustomerRetentionQuerySchema = z.object({
  countryCode: CommonValidator.countryCode,
  timeGranularity: z.enum(GROWTH_TIME_GRANULARITIES),
  windowSize: z.enum(GROWTH_RATE_WINDOW_SIZES),
});

export enum GrowthRateMetric {
  SalesNet = 'metric-1',
  AnnualisedSubscriptionsSalesNet = 'metric-3',
  AnnualisedSalesNet = 'metric-4',
}

export const GROWTH_RATE_GROWTH_METRIC = [
  'yearOverYear',
  'yearOverYearRef',
  'quarterOverQuarter',
  'quarterOverQuarterRef',
  'monthOverMonthRef',
  'monthOverMonth',
] as const;

export type GrowthRateGrowthMetric = (typeof GROWTH_RATE_GROWTH_METRIC)[number];

type GrowthRateMetrics = Record<GrowthRateGrowthMetric, BaseDataPoint[]> & {
  baseMetric: BaseDataPoint[];
};

export interface GrowthRateResponse {
  meta: { displayUnit: string; xMin: string; xMax: string };
  metrics: Record<GrowthRateMetric, GrowthRateMetrics>;
}

const getRowTimestamp = (row: GrowthRateArrowRow) => {
  if ('date_quarter' in row) return row.date_quarter;
  if ('date_month' in row) return row.date_month;
  return row.date_day;
};

const convertToPercentage = (value: bigint | null) => {
  if (value === null) return null;
  return (Number(value) - 1) * 100; // Ask Maria why -1
};

export default async function handler(req: NextApiRequest, res: NextApiResponse): Promise<void> {
  const { projectCode } = getAimHeaders(req.headers);

  const { parsedQuery } = validateRequestQuery(activeCustomerRetentionQuerySchema, req);
  if (!parsedQuery.success) return parsedQuery.sendInvalidQueryResponse(res);

  const { countryCode, timeGranularity, windowSize } = parsedQuery.data;

  const url = new URL(`${process.env.NEXT_PUBLIC_DATA_EXTRACTOR_URL}/tenants/${projectCode}/insights/growth-rate`);
  url.searchParams.set('country_code', countryCode);
  url.searchParams.set('granularity', timeGranularity);
  url.searchParams.set('window_size', windowSize);

  const urlReference = new URL(
    `${process.env.NEXT_PUBLIC_DATA_EXTRACTOR_URL}/tenants/${projectCode}/insights/growth-rate`,
  );
  urlReference.searchParams.set('country_code', 'ALL');
  urlReference.searchParams.set('granularity', timeGranularity);
  urlReference.searchParams.set('window_size', windowSize);

  try {
    const table = await arrowRequest(url.toString());
    const tableArray = table.toArray();

    const tableReferenceData = await arrowRequest(urlReference.toString());
    const tableArrayReference = tableReferenceData.toArray();

    const response: GrowthRateResponse = {
      meta: {
        displayUnit: getUnit(table, 'annualized_subscription_sales_net_run_rate'),
        ...getDimension(table, mapTimeGranularityToDateDimension(timeGranularity)),
      },
      metrics: {
        [GrowthRateMetric.SalesNet]: {
          yearOverYear: [],
          yearOverYearRef: [],
          monthOverMonth: [],
          monthOverMonthRef: [],
          quarterOverQuarter: [],
          quarterOverQuarterRef: [],
          baseMetric: [],
        },
        [GrowthRateMetric.AnnualisedSubscriptionsSalesNet]: {
          yearOverYear: [],
          yearOverYearRef: [],
          monthOverMonth: [],
          monthOverMonthRef: [],
          quarterOverQuarter: [],
          quarterOverQuarterRef: [],
          baseMetric: [],
        },
        [GrowthRateMetric.AnnualisedSalesNet]: {
          yearOverYear: [],
          yearOverYearRef: [],
          monthOverMonth: [],
          monthOverMonthRef: [],
          quarterOverQuarter: [],
          quarterOverQuarterRef: [],
          baseMetric: [],
        },
      },
    };

    tableArray.forEach((row: GrowthRateArrowRow) => {
      const date = new Date(getRowTimestamp(row)).toISOString();

      response.metrics[GrowthRateMetric.SalesNet].yearOverYear.push({
        date,
        value: convertToPercentage(row[getMetricYoY(windowSize, timeGranularity, 'sales_net')]),
      });
      response.metrics[GrowthRateMetric.SalesNet].baseMetric.push({
        date,
        value: Number(row[getMetricBase(windowSize, timeGranularity, 'sales_net')]),
      });

      response.metrics[GrowthRateMetric.AnnualisedSalesNet].yearOverYear.push({
        date,
        value: convertToPercentage(row[getMetricYoY(windowSize, timeGranularity, 'annualized_sales_net')]),
      });
      response.metrics[GrowthRateMetric.AnnualisedSalesNet].baseMetric.push({
        date,
        value: Number(row[getMetricBase(windowSize, timeGranularity, 'annualized_sales_net')]),
      });

      response.metrics[GrowthRateMetric.AnnualisedSubscriptionsSalesNet].yearOverYear.push({
        date,
        value: convertToPercentage(row.annualized_subscription_sales_net_growth_rate_year_over_year),
      });
      response.metrics[GrowthRateMetric.AnnualisedSubscriptionsSalesNet].baseMetric.push({
        date,
        value: Number(row.annualized_subscription_sales_net_run_rate),
      });

      if ('date_quarter' in row) {
        response.metrics[GrowthRateMetric.SalesNet].quarterOverQuarter.push({
          date,
          value: convertToPercentage(row.sales_net_growth_rate_quarter_over_quarter),
        });

        response.metrics[GrowthRateMetric.AnnualisedSalesNet].quarterOverQuarter.push({
          date,
          value: convertToPercentage(row.annualized_sales_net_growth_rate_quarter_over_quarter),
        });

        response.metrics[GrowthRateMetric.AnnualisedSubscriptionsSalesNet].quarterOverQuarter.push({
          date,
          value: convertToPercentage(row.annualized_subscription_sales_net_growth_rate_quarter_over_quarter),
        });
      }

      if ('date_month' in row || 'date_day' in row) {
        response.metrics[GrowthRateMetric.SalesNet].monthOverMonth.push({
          date,
          value: convertToPercentage(row[getMetricMoM(windowSize, timeGranularity, 'sales_net')]),
        });
        response.metrics[GrowthRateMetric.AnnualisedSalesNet].monthOverMonth.push({
          date,
          value: convertToPercentage(row[getMetricMoM(windowSize, timeGranularity, 'annualized_sales_net')]),
        });

        response.metrics[GrowthRateMetric.AnnualisedSubscriptionsSalesNet].monthOverMonth.push({
          date,
          value: convertToPercentage(row.annualized_subscription_sales_net_growth_rate_month_over_month),
        });
      }
    });

    tableArrayReference.forEach((row: GrowthRateArrowRow) => {
      const date = new Date(getRowTimestamp(row)).toISOString();

      response.metrics[GrowthRateMetric.SalesNet].yearOverYearRef.push({
        date,
        value: convertToPercentage(row[getMetricYoY(windowSize, timeGranularity, 'sales_net')]),
      });

      response.metrics[GrowthRateMetric.AnnualisedSalesNet].yearOverYearRef.push({
        date,
        value: convertToPercentage(row[getMetricYoY(windowSize, timeGranularity, 'annualized_sales_net')]),
      });

      response.metrics[GrowthRateMetric.AnnualisedSubscriptionsSalesNet].yearOverYearRef.push({
        date,
        value: convertToPercentage(row.annualized_subscription_sales_net_growth_rate_year_over_year),
      });

      if ('date_quarter' in row) {
        response.metrics[GrowthRateMetric.SalesNet].quarterOverQuarterRef.push({
          date,
          value: convertToPercentage(row.sales_net_growth_rate_quarter_over_quarter),
        });

        response.metrics[GrowthRateMetric.AnnualisedSalesNet].quarterOverQuarterRef.push({
          date,
          value: convertToPercentage(row.annualized_sales_net_growth_rate_quarter_over_quarter),
        });

        response.metrics[GrowthRateMetric.AnnualisedSubscriptionsSalesNet].quarterOverQuarterRef.push({
          date,
          value: convertToPercentage(row.annualized_subscription_sales_net_growth_rate_quarter_over_quarter),
        });
      }

      if ('date_month' in row || 'date_day' in row) {
        response.metrics[GrowthRateMetric.SalesNet].monthOverMonthRef.push({
          date,
          value: convertToPercentage(row[getMetricMoM(windowSize, timeGranularity, 'sales_net')]),
        });

        response.metrics[GrowthRateMetric.AnnualisedSalesNet].monthOverMonthRef.push({
          date,
          value: convertToPercentage(row[getMetricMoM(windowSize, timeGranularity, 'annualized_sales_net')]),
        });

        response.metrics[GrowthRateMetric.AnnualisedSubscriptionsSalesNet].monthOverMonthRef.push({
          date,
          value: convertToPercentage(row.annualized_subscription_sales_net_growth_rate_month_over_month),
        });
      }
    });

    res.status(200).json(response);
  } catch (error) {
    handleApiError(res, error);
  }
}
