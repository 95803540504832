export const getPermittedSettings = ({
  hasAdminPrivileges,
  hasDeckViewerRole,
  hasSubscriptionsEnabled,
}: {
  hasAdminPrivileges: boolean;
  hasDeckViewerRole: boolean;
  hasSubscriptionsEnabled: boolean;
}) => {
  return [
    'account',
    ...(hasSubscriptionsEnabled ? ['subscription' as const] : []),
    ...(!hasDeckViewerRole ? ['user-management' as const] : []),
    'notifications',
    ...(hasAdminPrivileges ? ['admin-only' as const] : []),
  ] as const;
};

export type SettingsTab = ReturnType<typeof getPermittedSettings>[number];
