import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';
import type { BadgeType } from '../Badge/Badge';
import { Badge } from '../Badge/Badge';
import { Checkbox } from '../Input';

import './Cell.css';

interface CellProps {
  children?: ReactNode;
  onClick?: () => void;
  width?: string;
  floatRight?: boolean;
}

export const Cell = ({ children, onClick, width, floatRight }: CellProps): ReactElement => {
  return (
    <td onClick={onClick} className={cn(onClick && 'clickableCell', width)}>
      <div className={cn(floatRight && 'floatRight')}>{children}</div>
    </td>
  );
};

interface CheckboxCellProps {
  toggleCell: () => void;
  toggled: boolean;
}

export const CheckboxCell = ({ toggleCell, toggled }: CheckboxCellProps): ReactElement => {
  return (
    <Cell>
      <Checkbox onChange={() => toggleCell()} initialValue={toggled} />
    </Cell>
  );
};

interface ImageLabelCellProps {
  imgSrc?: string;
  label: string;
}

export const ImageLabelCell = ({ imgSrc, label }: ImageLabelCellProps): ReactElement => {
  return (
    <Cell>
      <div className='ImageLabelCell'>
        {imgSrc && <img src={imgSrc} width='36px' id='data-source' className='ImageLabelCell-image' />}
        <label htmlFor='data-source' className='ImageLabelCell-label'>
          {label}
        </label>
      </div>
    </Cell>
  );
};

interface BadgeCellProps {
  label: string;
  type?: BadgeType;
  icon?: ReactElement;
}

export const BadgeCell = ({ label, type, icon }: BadgeCellProps): ReactElement => {
  return (
    <Cell>
      <Badge type={type} startIcon={icon}>
        {label}
      </Badge>
    </Cell>
  );
};

interface NameAndEmailProps {
  fullName: string;
  email: string;
  role?: string;
}

export const NameAndEmailCell = ({ fullName, email, role }: NameAndEmailProps): ReactElement => {
  return (
    <Cell>
      <div className='nameEmailCell'>
        <div className='nameAndRole'>
          <div className='fullName'>{fullName}</div>
          {role && <Badge type='neutral'>{role}</Badge>}
        </div>
        <div className='email'>{email}</div>
      </div>
    </Cell>
  );
};
