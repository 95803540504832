import { ContextMenu, Popover } from 'aim-components';
import { useContext, useState } from 'react';
import { MIN_NUMBER_OF_TENANTS_TO_SHOW_SEARCH, useTenants } from './hooks';
import InviteUserModal from '@/components/settings/InviteUserModal/InviteUserModal';
import { useTranslation } from 'next-i18next';
import { TenantListItem } from './TenantListItem';
import { TenantSearch } from './TenantSearch';
import { UserContext } from '@/components/authentication/UserContext';
import { getUserPermissions } from '@/lib/permissions/role';
import { TenantSelectorTrigger } from '@/components/layouts/navigation/navigation-menu/tenant-selector/TenantSelectorTrigger';

export const TenantSelector = () => {
  const { t } = useTranslation('common', { keyPrefix: 'tenantContextMenu' });
  const { tenant, tenants, selectableTenants, recentTenants, isLoading } = useTenants();
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [showTenantSelectorContextMenu, setShowTenantSelectorContextMenu] = useState(false);
  const user = useContext(UserContext);
  const hasDeckViewerRole = getUserPermissions(user.role).hasDeckViewerRole;
  const isAimUser = !hasDeckViewerRole;

  const numberOfSelectableTenants = selectableTenants.length;
  const numberOfRecentTenants = recentTenants.length;

  return (
    <>
      <InviteUserModal showModal={showInviteUserModal} setShowModal={setShowInviteUserModal} />

      <Popover
        placement='bottom-start'
        open={showTenantSelectorContextMenu}
        onOpenChange={setShowTenantSelectorContextMenu}
        offset={{ mainAxis: 8 }}
        renderPopoverTrigger={(triggerProps) => {
          // We should not show a dropdown when the deck viewer only has access to one tenant because then there is no action for the user
          if (hasDeckViewerRole && tenants.length === 1) {
            return <TenantSelectorTrigger tenant={tenant} icon={null} {...triggerProps} />;
          }

          return (
            <TenantSelectorTrigger
              tenant={tenant}
              onClick={() => setShowTenantSelectorContextMenu((prev) => !prev)}
              icon={showTenantSelectorContextMenu ? 'dropdown-up' : 'dropdown-down'}
              {...triggerProps}
            />
          );
        }}
      >
        <ContextMenu>
          <ContextMenu.Title>{tenant}</ContextMenu.Title>
          {isAimUser && (
            <ContextMenu.List>
              <ContextMenu.ListItem
                label={t('inviteUsers')}
                icon='invite-user'
                onClick={() => setShowInviteUserModal(true)}
              />
            </ContextMenu.List>
          )}

          {isLoading ? (
            <ContextMenu.Spinner />
          ) : (
            <>
              {numberOfSelectableTenants > 0 && (
                <>
                  <ContextMenu.Divider />

                  {numberOfSelectableTenants > MIN_NUMBER_OF_TENANTS_TO_SHOW_SEARCH ? (
                    <>
                      {/* With many tenants to choose from: show recently visited tenants (if any), followed by the ability to search for tenants. */}
                      <ContextMenu.Title>
                        {t(numberOfRecentTenants > 0 ? 'switchTenantMostRecent' : 'switchTenant')}
                      </ContextMenu.Title>
                      <ContextMenu.List>
                        {recentTenants.map((tenant) => (
                          <TenantListItem key={tenant} tenant={tenant} />
                        ))}

                        <TenantSearch tenants={tenants} />
                      </ContextMenu.List>
                    </>
                  ) : (
                    <>
                      {/* With few tenants to choose from: display them directly in the menu. We don't care about recently visited tenants or searching here. */}
                      <ContextMenu.Title>{t('switchTenant')}</ContextMenu.Title>
                      <ContextMenu.List>
                        {selectableTenants.map((tenant) => (
                          <TenantListItem key={tenant} tenant={tenant} />
                        ))}
                      </ContextMenu.List>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </ContextMenu>
      </Popover>
    </>
  );
};
