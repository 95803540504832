import { Routes } from '@/lib/routes';
import { IntercomContext, setIntercomVisibility } from 'aim-utils';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

const ROUTES_WITH_INTERCOM = [
  Routes.Insights,
  Routes.Benchmarking,
  Routes.Data,
  Routes.Loans,
  Routes.Forecasting,
  Routes.Settings,
] as const satisfies ReadonlyArray<Routes>;

export const IntercomContextProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const blockers = useRef<string[]>([]);
  const hasBlocker = useRef(false);

  const isOnRouteWithIntercom = useMemo(() => {
    return ROUTES_WITH_INTERCOM.some((routeWithIntercom) => router.pathname.includes(routeWithIntercom));
  }, [router.pathname]);

  const updateIntercomBlocker = useCallback(
    (blockerKey: string, { hideIntercom }: { hideIntercom: boolean }) => {
      blockers.current = hideIntercom
        ? [...blockers.current, blockerKey]
        : blockers.current.filter((b) => b !== blockerKey);

      hasBlocker.current = blockers.current.length > 0;
      setIntercomVisibility(isOnRouteWithIntercom && !hasBlocker.current);
    },
    [isOnRouteWithIntercom],
  );

  useEffect(() => {
    setIntercomVisibility(isOnRouteWithIntercom && !hasBlocker.current);
  }, [isOnRouteWithIntercom]);

  // ? Resets Intercom visibility when the component unmounts, such as when logging out.
  useEffect(
    () => () => {
      blockers.current = [];
      hasBlocker.current = false;
      setIntercomVisibility(true);
    },
    [],
  );

  const contextValue = useMemo(() => ({ updateIntercomBlocker }), [updateIntercomBlocker]);

  return <IntercomContext.Provider value={contextValue}>{children}</IntercomContext.Provider>;
};
