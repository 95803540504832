import { useEffect, useRef, useState } from 'react';
import type { MutableRefObject } from 'react';

export function useElementDimensions() {
  const elementRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const [elementSize, setElementSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize(): void {
      setElementSize({
        width: elementRef.current?.clientWidth,
        height: elementRef.current?.clientHeight,
      });
    }

    window.addEventListener('orientationchange', handleResize);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [elementRef]);

  return [elementRef, elementSize.width, elementSize.height] as const;
}
