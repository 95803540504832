import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';

import './Section.css';

export interface SectionProps {
  children: ReactNode;
  condensed?: boolean;
  color?: 'primary' | 'blue';
}

export const Section = ({ children, condensed, color = 'primary' }: SectionProps): ReactElement => {
  return <section className={cn('Section', color, condensed && 'condensed')}>{children}</section>;
};
