import { useEffect, useRef } from 'react';

export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current) {
        if (event instanceof MouseEvent) {
          // check if click is inside bounding box
          const bbox = ref.current.getBoundingClientRect();
          const isInsideBoundingBox =
            event.x >= bbox.left && event.x <= bbox.right && event.y >= bbox.top && event.y <= bbox.bottom;
          if (!isInsideBoundingBox) {
            callback && callback();
          }
        } else {
          if (!ref.current.contains(event.target as Node)) {
            callback && callback();
          }
        }
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [callback]);

  return ref;
};
