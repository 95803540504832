import type { UiNode } from '@ory/client';
import type {
  ANode,
  ImageNode,
  InputNode,
  OryNodeList,
  PostRecoveryResponse,
  PostRecoverySuccess,
  PostSignUpResponse,
  PostSignUpResponseSuccess,
  PostVerificationResponse,
  PostVerificationSuccess,
  ScriptNode,
  TextNode,
  UiFlowResponse,
} from './types';
import type { PostLoginResponse } from './types';
import type { PostLoginResponseSuccess } from './types';

export const isInputNode = (node: UiNode): node is InputNode => {
  return node.type === 'input';
};

export const isTextNode = (node: UiNode): node is TextNode => {
  return node.type === 'text';
};

export const isImageNode = (node: UiNode): node is ImageNode => {
  return node.type === 'img';
};

export const isANode = (node: UiNode): node is ANode => {
  return node.type === 'a';
};

export const isScriptNode = (node: UiNode): node is ScriptNode => {
  return node.type === 'script';
};

export const oryNode = <T = InputNode>(node: OryNodeList) => {
  return node as T;
};

export const isLoginSuccess = (response: PostLoginResponse): response is PostLoginResponseSuccess => {
  return response.status === 200;
};

export const isRegistrationSuccess = (response: PostSignUpResponse): response is PostSignUpResponseSuccess => {
  return response.status === 200;
};

export const isVerificationSuccess = (response: PostVerificationResponse): response is PostVerificationSuccess => {
  return response.status === 200;
};

export const isRecoverySuccess = (response: PostRecoveryResponse): response is PostRecoverySuccess => {
  try {
    return (
      // Yes, this is ugly, Ory is not consistent with their API.
      response.status === 200 &&
      ((response.data as unknown as UiFlowResponse).messages?.length === 0 ||
        ((response.data as unknown as UiFlowResponse).messages?.length === 1 &&
          (response.data as unknown as UiFlowResponse).messages?.[0].id === 1060003))
    );
  } catch {
    return false;
  }
};

export const oryErrorMessageMapping = (errorId?: number | string) => {
  switch (errorId) {
    case 4070006:
    case 'self_service_flow_replaced':
    case 'self_service_flow_expired':
    case 'security_csrf_violation':
      return 'invalidCode';
    default:
      return 'generalError';
  }
};
