import { datadogLogs } from '@datadog/browser-logs';
import pino from 'pino'; // eslint-disable-line no-restricted-imports

export function initializeLogger(): void {
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN ?? '',
    site: process.env.NEXT_PUBLIC_DD_SITE,
    forwardConsoleLogs: 'all',
    forwardErrorsToLogs: true,
    service: 'aim-frontend',
    env: process.env.NEXT_PUBLIC_ENV,
    // * This is injected by the Dockerfile, as a part of the build pipeline in Github.
    version: process.env.NEXT_PUBLIC_DATADOG_VERSION,
  });
}

/**
 * Custom server side logging interface, to easily swap out implementations (logging libraries) if needed.
 */
interface ServerLogger {
  info: (message: string, data?: unknown) => void;
  warn: (message: string, data?: unknown) => void;
  error: (message: string, data?: unknown) => void;
}

class PinoLogger implements ServerLogger {
  private pinoLogger: pino.Logger;

  constructor() {
    this.pinoLogger = pino({
      formatters: {
        level: (label) => ({ level: label }),
      },
      messageKey: 'message',
      nestedKey: 'data',
    });
  }

  info(message: string, data?: unknown): void {
    this.pinoLogger.info(data, message);
  }

  warn(message: string, data?: unknown): void {
    this.pinoLogger.warn(data, message);
  }

  error(message: string, data?: unknown): void {
    this.pinoLogger.error(data, message);
  }
}

/**
 * This logger is used for server side logging in NextJS.
 */
export const serverLogger: ServerLogger = new PinoLogger();
