import { scaleTime } from 'd3';
import type { Annotation } from './types';

export const createAnnotationPositions = (
  startValue: string,
  endValue: string,
  width: number,
  annotations: Annotation[],
) => {
  const annotationMarkerSize = { width: 12, height: 3 };
  const annotationPaddingBottom = 10;
  const startDate = new Date(startValue);
  const endDate = new Date(endValue);
  const timeScale = scaleTime().domain([startDate, endDate]).range([0, width]);

  // TODO: check bar charts
  const mappedAnnotations = annotations.map((annotation) => ({
    ...annotation,
    leftOffset: timeScale(new Date(annotation.startDate)),
  }));

  return {
    annotationMarkerSize,
    annotationPaddingBottom,
    mappedAnnotations,
    timeScale,
  };
};

export const isValidMessage = (message: string) => message.trim().length > 0;
