import type { OffsetOptions, Placement } from '@floating-ui/react';
import {
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  autoUpdate,
  hide,
  offset as popoverOffset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import React, { useEffect } from 'react';

interface PopoverProps {
  renderPopoverTrigger?: (triggerProps: Record<string, unknown>) => ReactNode;
  children: ReactNode;
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  placement?: Placement;
  offset?: OffsetOptions;
  disableHover?: boolean;
}

const PopoverComponent = ({
  renderPopoverTrigger,
  open,
  onOpenChange,
  children,
  placement,
  offset,
  disableHover,
}: PopoverProps) => {
  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();

  const isNested = parentId !== null;

  const { refs, floatingStyles, context } = useFloating({
    nodeId,
    open: open,
    onOpenChange,
    placement: placement ?? (isNested ? 'right-start' : 'bottom-start'),
    middleware: [popoverOffset(offset), shift(), hide()],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context, { bubbles: true });

  const hover = useHover(context, {
    enabled: isNested && !disableHover,
    delay: { open: 100 },
    handleClose: safePolygon({ blockPointerEvents: true }),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, hover]);

  useEffect(() => {
    if (!tree) return;

    const handleTreeClick = () => onOpenChange && onOpenChange(false);
    tree.events.on('click', handleTreeClick);

    return () => tree.events.off('click', handleTreeClick);
  }, [tree, onOpenChange]);

  return (
    <FloatingNode id={nodeId}>
      {renderPopoverTrigger && renderPopoverTrigger({ ref: refs.setReference, ...getReferenceProps() })}

      {open && (
        <FloatingPortal>
          <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            {children}
          </div>
        </FloatingPortal>
      )}
    </FloatingNode>
  );
};

export const Popover = (props: PopoverProps) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <PopoverComponent {...props} />
      </FloatingTree>
    );
  }

  return <PopoverComponent {...props} />;
};
