import type { ReactNode } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './ChartContainer.mobile.module.css';
import { ChartHeaderMobile } from '../ChartHeader';
import { BottomSheet } from '../../BottomSheet';
import { Description } from '../../Description/Description';
import { BREAKPOINT, ChartContext, DeckChartContext, useIntercomContext } from 'aim-utils';
import { MetricSelectMobile } from './MetricSelect.mobile';
import cn from 'classnames';
import { ForecastChartContext } from '../ForecastChartContext';

export interface ChartContainerMobileProps {
  children: ReactNode;
  title: ReactNode;
  fullscreen?: boolean;
  setFullscreen?: (open: boolean) => void;
  disableFullscreen?: boolean;
  informationHtml?: string | ReactNode;
  copyChartLink?: () => void;
  height?: 'unset' | undefined;
  nonReliableForecast?: boolean;
  _forceRequestFullscreen?: boolean; // ! This is useful for development purposes (e.g. in Storybook), to more easily interact with the fullscreen state using the Fullscreen API
  badge?: ReactNode;
  legend?: ReactNode;
}

export const ChartContainerMobile = ({
  children,
  title,
  fullscreen,
  setFullscreen,
  informationHtml,
  copyChartLink,
  _forceRequestFullscreen = false,
  disableFullscreen,
  height,
  nonReliableForecast,
  badge,
  legend,
}: ChartContainerMobileProps) => {
  const isDesktopDevice = window.screen.width > BREAKPOINT.Mobile; // ? Checks screen width, not viewport width, to determine if the device is a desktop device.
  const { updateIntercomBlocker } = useIntercomContext();
  const deckChartContext = useContext(DeckChartContext);
  const { fixedChartState } = useContext(ChartContext);

  useEffect(() => {
    // ? This takes care of the case when the fullscreen mode is exited by pressing the ECC key or similar.
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && setFullscreen) {
        setFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, [setFullscreen]);

  useEffect(() => {
    updateIntercomBlocker('mobile-chart-fullscreen', { hideIntercom: fullscreen ?? false });
  }, [fullscreen, updateIntercomBlocker]);

  const handleFullscreenChange = useCallback(
    (newFullscreenState: boolean) => {
      if (!setFullscreen) return;

      setFullscreen(newFullscreenState);

      const fullscreenApiSupported = 'requestFullscreen' in document.documentElement;
      if (!fullscreenApiSupported) return;

      // ! Does not request fullscreen using the Fullscreen API on desktop devices, unless forced.
      if (isDesktopDevice && _forceRequestFullscreen !== true) return;

      if (newFullscreenState) {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
        }
      } else if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    },
    [_forceRequestFullscreen, isDesktopDevice, setFullscreen],
  );

  const [showInformation, setShowInformation] = useState(false);

  return (
    <ForecastChartContext.Provider value={{ nonReliableForecast }}>
      <div
        className={cn(styles.chartContainerMobile, height && styles.unsetHeight)}
        data-fullscreen={fullscreen}
        data-desktop-device={isDesktopDevice}
      >
        <BottomSheet open={showInformation} onClose={() => setShowInformation(false)}>
          <BottomSheet.Content>
            {typeof informationHtml === 'string' || informationHtml === undefined ? (
              <Description html={informationHtml} />
            ) : (
              <Description> {informationHtml}</Description>
            )}
          </BottomSheet.Content>
        </BottomSheet>
        {/* TODO: Do we want to pass this in as a "header" prop instead? */}
        <ChartHeaderMobile
          title={title}
          customTitle={deckChartContext?.title}
          fullscreen={fullscreen}
          setFullscreen={handleFullscreenChange}
          disableFullscreen={disableFullscreen}
          showInfo={informationHtml ? () => setShowInformation(true) : undefined}
          copyChartLink={fixedChartState ? undefined : copyChartLink}
          hideFullScreen={setFullscreen === undefined}
          badge={badge}
          legend={legend}
        />
        <main className={styles.content} onClick={() => setFullscreen && !fullscreen && setFullscreen(true)}>
          {children}
        </main>
      </div>
    </ForecastChartContext.Provider>
  );
};

ChartContainerMobile.HeaderMetricSelect = MetricSelectMobile;
