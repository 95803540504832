import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { TenantContext } from '../tenant/context';
import useSWRImmutable from 'swr/immutable';
import { ApiRoutes } from 'pages/apiRoutes';
import { fetcher } from '@/lib/request/request';
import { type TenantInfo } from '@/api/tenant/index.page';
import { useMobile, type CountryCode } from 'aim-utils';
import axios from 'axios';
import { ErrorScreen } from 'aim-components';
import GeneralError from '@/components/common/error/GeneralError';
import { NavigationLayout } from '../layouts/navigation/NavigationLayout';
import { capitalizePersonName, type UserSession } from '@/lib/utils';
import { IntercomContextProvider } from '../common/external/IntercomContextProvider';
import { useTranslation } from 'next-i18next';
import { useTenantHistory } from './hooks/useTenantHistory';
import { SubscriptionPlansProvider } from '@/components/subscription-plans/SubscriptionPlanContext';
import { NavigationContextProvider } from '@/components/layouts/navigation/navigation-menu/NavigationContext';
import { ProductTourContextProvider } from '../common/product-tour-context/ProductTourContext';
import { CommandPalette } from '@/components/common/command-palette/CommandPalette';
import { MemoryDebuggerContextProvider } from '@/components/common/memory-debugger/MemoryDebuggerContext';
import { UserContext } from './UserContext';

interface AuthenticatedContentProps {
  children: ReactNode;
  userSession: UserSession;
  includeNavigationMobile?: boolean;
  includeNavigationDesktop?: boolean;
  includeFooter?: boolean;
}

const AuthenticatedContent = ({
  userSession,
  children,
  includeNavigationMobile = true,
  includeNavigationDesktop = true,
  includeFooter = true,
}: AuthenticatedContentProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { slug } = router.query;
  const [authenticated, setAuthenticated] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const { mobileView } = useMobile();

  useEffect(() => {
    const interceptor = axios.interceptors.request.use((config) => {
      if (userSession?.arkUserId && slug) {
        if (config.url?.match(/^\/api/)) {
          config.headers.set('x-aim-tenant', slug);
        }
      }

      return config;
    });

    if (userSession.arkUserId) {
      setAuthenticated(true);
      if (slug) {
        sessionStorage.setItem('aim_tenant', slug as string);
      }
    } else {
      setAuthenticated(false);
    }
    setInitiated(true);

    return () => axios.interceptors.request.eject(interceptor);
  }, [slug, userSession?.arkUserId, userSession?.tenant]);

  const { data: tenantInfo, isLoading: isLoadingTenantInfo } = useSWRImmutable(
    authenticated ? ApiRoutes.TenantInfo : null,
    fetcher<TenantInfo>,
  );
  const { recentTenants, isLoadingTenantHistory } = useTenantHistory({ enabled: authenticated });

  if (initiated && !authenticated) {
    return (
      <ErrorScreen title={t('errorScreen.common.title')} logo='/images/gilion-white-registered-trademark.png'>
        <GeneralError errorSource='AuthenticatedContent' />
      </ErrorScreen>
    );
  }

  if (authenticated && userSession.loggedIn) {
    return (
      <MemoryDebuggerContextProvider>
        <TenantContext.Provider
          value={{
            countryCode: tenantInfo?.countryCode as CountryCode,
            interestedInLoanSince: tenantInfo?.interestedInLoanSince ?? null,
            ownership: tenantInfo?.ownership,
            projectCode: tenantInfo?.projectCode,
            createdAt: tenantInfo?.createdAt,
            loading: isLoadingTenantInfo,
          }}
        >
          <ProductTourContextProvider>
            <SubscriptionPlansProvider>
              <UserContext.Provider
                value={{
                  displayName: capitalizePersonName(userSession.displayName ?? ''),
                  email: userSession.email,
                  role: userSession.role,
                  tenant: slug as string,
                  arkUserId: userSession.arkUserId,
                  created: userSession.created,
                  recentTenants: { tenants: recentTenants, isLoading: isLoadingTenantHistory },
                }}
              >
                <IntercomContextProvider>
                  <NavigationContextProvider>
                    {userSession.role === 'ADMIN' && <CommandPalette />}
                    {mobileView ? (
                      <>
                        {!includeNavigationMobile && children}
                        {includeNavigationMobile && (
                          <NavigationLayout includeFooter={includeFooter}>{children}</NavigationLayout>
                        )}
                      </>
                    ) : (
                      <>
                        {!includeNavigationDesktop && children}
                        {includeNavigationDesktop && (
                          <NavigationLayout includeFooter={includeFooter}>{children}</NavigationLayout>
                        )}
                      </>
                    )}
                  </NavigationContextProvider>
                </IntercomContextProvider>
              </UserContext.Provider>
            </SubscriptionPlansProvider>
          </ProductTourContextProvider>
        </TenantContext.Provider>
      </MemoryDebuggerContextProvider>
    );
  }

  return null;
};

export default AuthenticatedContent;
