import type { IconName } from 'aim-components';
import { AnnotationContext, Button, Icon, NotificationBadge, Typography } from 'aim-components';
import styles from './NavigationHeader.module.css';
import { useCallback, useContext, useMemo } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useNavigationContext } from '@/components/layouts/navigation/navigation-menu/NavigationContext';
import { UpgradeSubscriptionPlanNavigationHeaderButton } from '@/components/subscription-plans/navigation-header-call-to-actions/UpgradeSubscriptionPlanHeaderButton';
import { MobileHeaderUpgradePlanCallToAction } from '@/components/subscription-plans/navigation-header-call-to-actions/MobileHeaderUpgradePlanCallToAction';

interface NavigationHeaderProps {
  deckViewer: boolean;
  expandMobileMenu: () => void;
  expandNotificationsPanel: () => void;
  unreadNotifications: boolean;
}

export const NavigationHeader = ({
  deckViewer,
  expandMobileMenu,
  expandNotificationsPanel,
  unreadNotifications,
}: NavigationHeaderProps) => {
  const { modalNavigationHeaderConfig } = useNavigationContext();
  const router = useRouter();

  const { setDisplayAnnotationSidePanel } = useContext(AnnotationContext);

  const goBack = useCallback(() => router.back(), [router]);

  const handleStartButtonClick = useCallback(() => {
    if (!modalNavigationHeaderConfig) return expandMobileMenu();
    if (modalNavigationHeaderConfig.startButton?.onClick) return modalNavigationHeaderConfig.startButton.onClick();
    if (modalNavigationHeaderConfig.startButton?.type === 'back') return goBack();
    if (modalNavigationHeaderConfig.startButton?.type === 'menu') return expandMobileMenu();
  }, [expandMobileMenu, goBack, modalNavigationHeaderConfig]);

  const handleEndButtonClick = useCallback(() => {
    if (!modalNavigationHeaderConfig) return expandNotificationsPanel();
    if (modalNavigationHeaderConfig.endButton?.onClick) return modalNavigationHeaderConfig.endButton.onClick();
    if (modalNavigationHeaderConfig.endButton?.type === 'close') return goBack();
  }, [expandNotificationsPanel, goBack, modalNavigationHeaderConfig]);

  const endButtonIcon: IconName | JSX.Element = useMemo(() => {
    const type = modalNavigationHeaderConfig?.endButton?.type;
    if (type === 'close') return 'close';
    if (type === 'ellipsis') return 'menu-vertical';

    return (
      <Icon.Stack>
        <Icon icon='bell-solid' size='medium' className={styles.solidIcon} />
        <Icon icon='bell' size='medium' className={styles.regularIcon} />
      </Icon.Stack>
    );
  }, [modalNavigationHeaderConfig?.endButton?.type]);

  return (
    <>
      <header className={styles.header}>
        {!deckViewer && (
          <>
            <UpgradeSubscriptionPlanNavigationHeaderButton className={styles.upgradeSubscriptionPlanButton} />
            <div className={styles.headerIconButtons}>
              <div className={styles.headerIconButton}>
                <Button
                  startIcon={
                    <Icon.Stack>
                      <Icon icon='annotation-solid' size='medium' className={styles.solidIcon} />
                      <Icon icon='annotation' size='medium' className={styles.regularIcon} />
                    </Icon.Stack>
                  }
                  onClick={() => setDisplayAnnotationSidePanel(true)}
                  size='medium'
                  type='tertiary'
                  color='white'
                />
              </div>
              <div className={styles.headerIconButton}>
                {unreadNotifications && <NotificationBadge color='red' className={styles.badge} />}
                <Button
                  startIcon={
                    <Icon.Stack>
                      <Icon icon='bell-solid' size='medium' className={styles.solidIcon} />
                      <Icon icon='bell' size='medium' className={styles.regularIcon} />
                    </Icon.Stack>
                  }
                  onClick={() => expandNotificationsPanel()}
                  size='medium'
                  type='tertiary'
                  color='white'
                />
              </div>
            </div>
          </>
        )}
      </header>
      <header className={styles.mobileHeader}>
        <section className={styles.mobileHeaderContent}>
          <div className={styles.headerIconButton}>
            {modalNavigationHeaderConfig?.startButton !== null && (
              <Button
                startIcon={
                  <Icon
                    icon={modalNavigationHeaderConfig?.startButton?.type === 'back' ? 'arrow-left' : 'menu-bars'}
                    role='button'
                    size='medium'
                    color='white'
                    className={styles.mobileMenuIcon}
                  />
                }
                onClick={handleStartButtonClick}
                size='medium'
                type='tertiary'
                color='white'
              />
            )}
          </div>
          {modalNavigationHeaderConfig?.title ? (
            <Typography variant='label2' className={styles.modalTitle}>
              {modalNavigationHeaderConfig.title}
            </Typography>
          ) : (
            <Image
              src='/svg/gilion-white-no-trademark.svg'
              alt='Gilion'
              width={80}
              height={20}
              role='button'
              className={styles.aimLogo}
            />
          )}
          <div className={styles.headerIconButton}>
            {!modalNavigationHeaderConfig && unreadNotifications && (
              <NotificationBadge color='red' className={styles.badge} />
            )}

            {modalNavigationHeaderConfig?.endButton !== null && (
              <Button
                startIcon={endButtonIcon}
                onClick={handleEndButtonClick}
                size='medium'
                type='tertiary'
                color='white'
              />
            )}
          </div>
        </section>

        <MobileHeaderUpgradePlanCallToAction />
      </header>
    </>
  );
};
