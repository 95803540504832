import type { LoginFlow, LogoutFlow, RegistrationFlow, SettingsFlow, Session, VerificationFlow } from '@ory/client';
import { isANode, isImageNode, isInputNode, isScriptNode, isTextNode, oryNode } from './utils';
import type { UiFlowResponse, PostLoginResponse, PostSignUpResponse } from './types';

export const parseUiFlow = (json: RegistrationFlow | LoginFlow | SettingsFlow) => {
  const result: UiFlowResponse = {
    id: json.id,
    action: json.ui.action,
    method: json.ui.method,
    nodes: {},
    messages: json.ui.messages || [],
    state: json.state,
  };

  json.ui.nodes.forEach((node) => {
    if (isTextNode(node)) {
      result.nodes[node.attributes.id] = node;
    }
    if (isInputNode(node)) {
      // Handle OIDC provider selection
      if (node.group === 'oidc') {
        result.nodes[`${node.attributes.name}-${node.attributes.value}`] = node;
      } else {
        result.nodes[node.attributes.name] = node;
      }
    }
    if (isImageNode(node)) {
      result.nodes[node.attributes.id] = node;
    }
    if (isANode(node)) {
      result.nodes[node.attributes.id] = node;
    }
    if (isScriptNode(node)) {
      result.nodes[node.attributes.id] = node;
    }
  });

  return result;
};

export const parseLoginFlow = (json: unknown) => {
  return json as Session;
};

export const parseRegistrationFlow = (json: unknown) => {
  return json as SettingsFlow;
};

export const parseVerificationFlow = (json: unknown) => {
  return json as VerificationFlow;
};

const fetchGetCommon = {
  credentials: 'include',
  headers: {
    Accept: 'application/json',
  },
} as const satisfies RequestInit;

const fetchPostCommon = {
  method: 'POST',
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
} as const satisfies RequestInit;

const getFlow = async (url: string) => {
  const response = await fetch(url, fetchGetCommon);
  const data = await response.json();

  if (data.error) {
    throw { status: response.status, data: data.error };
  }

  return { status: response.status, data };
};

const postFlow = async (url: string, body: Record<string, unknown>) => {
  const response = await fetch(url, {
    ...fetchPostCommon,
    body: JSON.stringify(body),
  });

  const data = await response.json();

  if (data.error) {
    throw { status: response.status, data: data.error };
  }

  return { status: response.status, data };
};

export const getRegistrationFlow = async (url: string) => {
  const { status, data } = await getFlow(`${process.env.NEXT_PUBLIC_ORY_URL}${url}`);
  return { status, data: parseUiFlow(data) };
};

export const getVerificationFlow = async (url: string, email: string) => {
  const { status, data } = await getFlow(`${process.env.NEXT_PUBLIC_ORY_URL}${url}`);
  if (data.state === 'choose_method') {
    const parsed = parseUiFlow(data);
    const { status: newStatus, data: newFlow } = await postFlow(
      `${process.env.NEXT_PUBLIC_ORY_URL}/self-service/verification?flow=${data.id}`,
      {
        // eslint-disable-next-line camelcase
        csrf_token: oryNode(parsed.nodes['csrf_token']).attributes.value,
        email,
        method: 'code',
      },
    );
    return { status: newStatus, data: parseUiFlow(newFlow) };
  }

  return { status, data: parseUiFlow(data) };
};

export const getLoginFlow = async (url: string) => {
  const { status, data } = await getFlow(`${process.env.NEXT_PUBLIC_ORY_URL}${url}`);
  return { status, data: parseUiFlow(data) };
};

export const getSettingsFlow = async (url: string) => {
  const { status, data } = await getFlow(`${process.env.NEXT_PUBLIC_ORY_URL}${url}`);
  return { status, data: parseUiFlow(data) };
};

export const getRecoveryFlow = async (url: string) => {
  const { status, data } = await getFlow(`${process.env.NEXT_PUBLIC_ORY_URL}${url}`);
  return { status, data: parseUiFlow(data) };
};

export const getLogoutFlow = async () => {
  const { status, data } = await getFlow(
    `${process.env.NEXT_PUBLIC_ORY_URL}/self-service/logout/browser?return_to=${window.location.origin}`,
  );
  return { status, data: data as LogoutFlow };
};

export const postRegistrationFlow = async (id: string, body: Record<string, unknown>): Promise<PostSignUpResponse> => {
  const { status, data } = await postFlow(
    `${process.env.NEXT_PUBLIC_ORY_URL}/self-service/registration?flow=${id}`,
    body,
  );

  if (status === 200) {
    return { status, data: parseRegistrationFlow(data) };
  }
  return { status, data: parseUiFlow(data) };
};

export const postLoginFlow = async (id: string, body: Record<string, unknown>): Promise<PostLoginResponse> => {
  const { status, data } = await postFlow(`${process.env.NEXT_PUBLIC_ORY_URL}/self-service/login?flow=${id}`, body);

  if (status === 200) {
    return { status, data: parseLoginFlow(data) };
  }
  return { status, data: parseUiFlow(data) };
};

export const postSettingsFlow = async (
  id: string,
  body: Record<string, unknown>,
): Promise<{ status: number; data: UiFlowResponse }> => {
  const { status, data } = await postFlow(`${process.env.NEXT_PUBLIC_ORY_URL}/self-service/settings?flow=${id}`, body);

  return { status, data: parseUiFlow(data) };
};

export const postRecoveryFlow = async (id: string, body: Record<string, unknown>) => {
  const { status, data } = await postFlow(`${process.env.NEXT_PUBLIC_ORY_URL}/self-service/recovery?flow=${id}`, body);
  return { status, data: parseUiFlow(data) };
};

export const postInitiateRecoveryFlow = async (id: string, body: Record<string, unknown>) => {
  const { status, data } = await postFlow(`${process.env.NEXT_PUBLIC_ORY_URL}/self-service/recovery?flow=${id}`, body);
  return { status, data: parseUiFlow(data) };
};

export const postVerificationFlow = async (id: string, body: Record<string, unknown>) => {
  const { status, data } = await postFlow(
    `${process.env.NEXT_PUBLIC_ORY_URL}/self-service/verification?flow=${id}`,
    body,
  );

  return { status, data: parseUiFlow(data) };
};
