import { datadogLogs } from '@datadog/browser-logs';

interface LoggerInitParams {
  token?: string;
  site?: string;
  env?: string;
  name?: string;
}

interface LoggerParams {
  message: string;
  context?: object;
}

interface ErrorLoggerParams extends LoggerParams {
  error: Error;
}

export function initialize({ token, site, env, name }: LoggerInitParams) {
  datadogLogs.init({
    clientToken: token ?? '',
    site,
    service: name,
    env,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
  });
}

export const logger = {
  log: ({ message, context }: LoggerParams) => {
    if (typeof document !== 'undefined') {
      datadogLogs.logger.info(message, context);
    } else {
      // No server side logger implemented
    }
  },
  warn: ({ message, context }: LoggerParams) => {
    if (typeof document !== 'undefined') {
      datadogLogs.logger.warn(message, context);
    } else {
      // No server side logger implemented
    }
  },
  error: ({ message, context, error }: ErrorLoggerParams) => {
    if (typeof document !== 'undefined') {
      datadogLogs.logger.error(message, context, error);
    } else {
      // No server side logger implemented
    }
  },
};
