import { type ReactNode, Children, useCallback, createContext, useContext } from 'react';
import { Button } from '../../Button';
import { Typography } from '../../Typography';
import styles from './ScenarioBadge.module.css';
import React from 'react';
import { HoverTooltip } from '../../HoverTooltip';

interface ScenarioBadgeProps<TDatasetId extends string = string> {
  option: { label: string; value: TDatasetId; icon?: ReactNode };
  value: string;
  onRemove?: (optionValue: TDatasetId) => void;
}

const ScenarioBadgeContext = createContext({ readonly: false });

const ScenarioBadge = <TDatasetId extends string = string>({
  option,
  value,
  onRemove,
}: ScenarioBadgeProps<TDatasetId>) => {
  const { readonly } = useContext(ScenarioBadgeContext);
  const handleOnRemove = useCallback(() => {
    if (onRemove) onRemove(option.value);
  }, [onRemove, option.value]);

  return (
    <HoverTooltip message={option.label} position='top'>
      <div className={styles.scenarioBadge}>
        <div className={styles.badgeStart}>
          <div className={styles.labelWrapper}>
            {option.icon && option.icon}
            <Typography variant='text2' color='secondary' className={styles.label}>
              {option.label}
            </Typography>
          </div>
          <Typography variant='text1' className={styles.value}>
            {value}
          </Typography>
        </div>
        {onRemove && !readonly && (
          <Button
            endIcon='close'
            type='tertiary'
            color='white'
            onClick={handleOnRemove}
            className={styles.removeButton}
          />
        )}
      </div>
    </HoverTooltip>
  );
};

interface ScenarioBadgesProps {
  children: ReactNode;
  maxNumberOfBadges?: number;
  numberOfColumns?: number;
  onAdd?: () => void;
  readonly?: boolean;
}

export const ScenarioBadges = ({
  children,
  maxNumberOfBadges = 4,
  numberOfColumns = 2,
  onAdd,
  readonly = false,
}: ScenarioBadgesProps) => {
  const numberOfBadges = Children.count(children);

  return (
    <ScenarioBadgeContext.Provider value={{ readonly }}>
      <div
        className={styles.scenarioBadges}
        style={{
          ['--scenario-badges-column-count' as string]: numberOfColumns,
        }}
      >
        {children}
        {onAdd && !readonly && numberOfBadges < maxNumberOfBadges && (
          <Button type='tertiary' color='white' endIcon='add' onClick={onAdd} className={styles.addScenarioButton} />
        )}
      </div>
    </ScenarioBadgeContext.Provider>
  );
};

ScenarioBadges.Badge = ScenarioBadge;
