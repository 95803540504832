import React from 'react';
import type { D3LineChartConfig } from '../../types';

interface BackgroundProps {
  chartConfig: D3LineChartConfig;
  width: number;
  yScale: d3.ScaleLinear<number, number, never>;
}

interface GradientBackgroundProps {
  startColor: string;
  stopColor: string;
  width: number;
  y0: number;
  height: number;
}

interface GradientProps {
  startColor: string;
  stopColor: string;
  id: string;
}

const Background = ({ chartConfig, width, yScale }: BackgroundProps) => {
  const { limitLine, fillBackground } = chartConfig ?? {};
  return (
    <>
      {limitLine?.colorAbove && (
        <GradientBackground
          startColor={limitLine?.colorAbove}
          stopColor={'rgba(0,0,0,0)'}
          width={width}
          y0={0}
          height={yScale(limitLine?.value)}
        />
      )}
      {limitLine?.colorUnder && (
        <GradientBackground
          startColor={'rgba(0,0,0,0)'}
          stopColor={limitLine?.colorUnder}
          width={width}
          y0={yScale(limitLine?.value)}
          height={yScale(0) - yScale(limitLine?.value)}
        />
      )}
      {fillBackground && (
        <GradientBackground
          startColor={'rgba(0,0,0,0)'}
          stopColor={fillBackground}
          width={width}
          y0={0}
          height={yScale(0)}
        />
      )}
    </>
  );
};
export default Background;

const Gradient = ({ startColor, stopColor, id }: GradientProps) => {
  return (
    <defs>
      <linearGradient id={id} x1='0%' y1='0%' x2='0%' y2='100%'>
        <stop offset='0%' stopColor={startColor} />
        <stop offset='100%' stopColor={stopColor} />
      </linearGradient>
    </defs>
  );
};

export const GradientBackground = ({ startColor, stopColor, width, y0, height }: GradientBackgroundProps) => {
  const uniqueGradientKey = Math.random().toString(36).substring(2, 7);
  return (
    <g>
      <Gradient startColor={startColor} stopColor={stopColor} id={uniqueGradientKey} />
      <rect
        x={0}
        y={y0}
        width={Math.abs(width)}
        height={Math.abs(height)}
        fill={`url(#${uniqueGradientKey})`}
        opacity='0.3'
      />
    </g>
  );
};
