import type {
  FocusEventHandler,
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  ReactElement,
  ChangeEvent,
  FocusEvent,
  ClipboardEventHandler,
} from 'react';
import React from 'react';
import cn from 'classnames';
import { Label } from '../Label';
import './TextInput.css';
import { formatNumberInput } from '../../../utils/utils';

export type TextInputVariant =
  | 'hero'
  | 'title1'
  | 'title2'
  | 'text1'
  | 'text2'
  | 'text3'
  | 'label1'
  | 'label2'
  | 'label3';
export interface TextInputProps {
  name?: string;
  placeholder?: string;
  hint?: string;
  disabled?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  noStyling?: boolean;
  noBorder?: boolean;
  value?: string | number | null;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onPaste?: ClipboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  validationError?: string;
  isValid?: boolean;
  icon?: ReactElement;
  label?: string;
  type?: HTMLInputTypeAttribute | 'formattedNumber';
  acceptedFileTypes?: string;
  textarea?: boolean;
  rows?: number;
  variant?: TextInputVariant;
  condensed?: boolean;
  showValidationErrors?: boolean;
}

export const TextInput = ({
  name,
  placeholder,
  hint,
  disabled,
  autoComplete = '',
  autoFocus,
  noStyling,
  noBorder,
  value,
  onChange,
  onClick,
  isValid,
  validationError,
  icon,
  label,
  type = 'text',
  onFocus,
  onBlur,
  onPaste,
  onKeyDown,
  acceptedFileTypes,
  textarea = false,
  rows,
  variant,
  condensed = false,
  showValidationErrors = true,
}: TextInputProps): ReactElement => {
  const InputElement = textarea ? 'textarea' : 'input';

  if (type === 'formattedNumber') {
    return (
      <FormattedNumberInput
        name={name}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus={autoFocus}
        disabled={disabled}
        value={formatNumberInput(value ?? undefined)}
        onBlur={onBlur}
        onPaste={onPaste}
        hint={hint}
        isValid={isValid}
        validationError={validationError}
        icon={icon}
      />
    );
  }

  return (
    <div>
      <Label label={label} disabled={disabled} htmlFor={name} />
      <div
        className={cn(
          noStyling === true && 'noStyling',
          'TextInput-container',
          isValid === false && 'invalid',
          condensed && 'condensed',
        )}
      >
        <InputElement
          className={cn('TextInput', variant, value && type === 'file' && 'uploaded', noBorder === true && 'noBorder')}
          role='textbox'
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onClick={(e) => {
            e.stopPropagation();
            onClick && onClick();
          }}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          defaultValue={value ?? undefined}
          type={type}
          onFocus={onFocus}
          onBlur={onBlur}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          accept={acceptedFileTypes}
          rows={rows}
          onWheel={(e) => e.currentTarget.blur()}
          id={name}
        />
        {(isValid !== undefined || (icon && !noStyling)) && icon}
        {type !== 'hidden' && showValidationErrors && (
          <div className='TextInput-HintErrorContainer'>
            {hint && !(isValid === false && validationError) && <p className='TextInput-hint'>{hint}</p>}
            {isValid === false && <p className='TextInput-error'>{validationError}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

const FormattedNumberInput = ({ value, onChange, onBlur, onPaste, ...rest }: TextInputProps): ReactElement => {
  //data used in event handler functions should not be formatted
  const getUnformattedEventData = <
    T extends ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | FocusEvent<HTMLInputElement>,
  >(
    event: T,
  ) => {
    return { ...event, target: { value: event.target.value.replace(/\s/g, '') } } as T;
  };

  return (
    <TextInput
      onChange={(e) => {
        e.target.value = formatNumberInput(e.target.value);
        onChange && onChange(getUnformattedEventData(e));
      }}
      value={formatNumberInput(value ?? undefined)}
      onBlur={(e) => onBlur && onBlur(getUnformattedEventData(e))}
      onPaste={() => onPaste}
      {...rest}
    />
  );
};
