import type { ReactElement, MouseEvent } from 'react';
import React from 'react';
import cn from 'classnames';
import { Icon } from '../../Icon';
import type { Option, SelectableOption } from './Select';
import './SelectOption.css';
import { Typography } from '../../Typography';
import { useMobile } from 'aim-utils';

export interface SelectOptionProps<TValue extends string | number = string> {
  option: SelectableOption<TValue>;
  onSelect?: (option: Option<TValue>, e: MouseEvent) => void;
  isSelected?: boolean;
  condensed?: boolean;
  disabled?: boolean;
  className?: string;
  iconDisplayMode?: 'always' | 'selected';
}

export const SelectOption = <TValue extends string | number = string>({
  option,
  onSelect,
  isSelected,
  condensed,
  disabled = false,
  className,
  iconDisplayMode = 'selected',
}: SelectOptionProps<TValue>): ReactElement => {
  const { mobileView } = useMobile();
  const showIcon = iconDisplayMode === 'always' ? true : isSelected;
  return (
    <li
      className={cn(
        'SelectOption',
        isSelected && 'selected',
        condensed && 'condensed',
        disabled && 'disabled',
        className,
      )}
      role='button'
      onClick={!disabled && onSelect ? (e: MouseEvent) => onSelect(option, e) : undefined}
      onMouseDown={(e) => e.preventDefault()}
    >
      <div style={{ display: 'flex', gap: 'var(--size-xs)', alignItems: 'center' }}>
        {showIcon && option.icon}
        <div className='labelContainer'>
          <Typography variant='label3'> {option.label}</Typography>
          {option.newFilterFlag && !mobileView && (
            <Typography variant='label3' color='primaryBlue'>
              <label className='newlabel'>New</label>
            </Typography>
          )}
        </div>
      </div>
      <Icon icon='success' style={{ visibility: isSelected ? 'visible' : 'hidden' }} />
    </li>
  );
};
