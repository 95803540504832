import type { ReactElement, ReactNode } from 'react';
import React from 'react';

import './Table.css';

export const Table = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <table className='Table'>
      <tbody>{children}</tbody>
    </table>
  );
};
