import type { MutableRefObject, ReactElement } from 'react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScatterController,
  TimeScale,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'react-chartjs-2';
import { useAdjustedYMax, useCapYValue, useChartArea, useChartData } from './hooks';
import type { ChartMobileProps, ChartOptionsWithCustomOptions } from './types';
import { getChartOptions, getChartType, getYAxisTitle, getYAxisUnit } from './utils';
import './Chart.css';
import { useChartTooltip } from './Tooltip/hooks';
import { EMPTY_ARRAY_STATIC_REFERENCE } from '../../utils';
import { useTooltipPlugin } from './hooks.mobile';
import { min } from 'date-fns';
import { Typography } from '../Typography';
import { ForecastChartContext } from './ForecastChartContext';

ChartJS.register([
  LineController,
  BarController,
  TimeScale,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  ChartDataLabels,
  ScatterController,
  Filler,
  ChartAnnotationPlugin,
]);

ChartJS.defaults.font = { family: 'ArketypSans', size: 13 };

export const ChartMobile = ({
  datasetFilterSettings = {},
  datasets = [],
  selectedDatasetsOptions,
  xAxis,
  yAxis,
  y1Axis,
  responsive = true,
  expanded = false,
  labels = EMPTY_ARRAY_STATIC_REFERENCE,
  xmin = 0,
  xmax = labels.length - 1,
  handleHoverDataPoint,
  handleTooltipChange,
  formatTooltipTitle,
  forecastWarning,
  onClick,
  badgeContent,
}: ChartMobileProps): ReactElement => {
  const [chartOptions, setChartOptions] = useState<ChartOptionsWithCustomOptions>();
  const [isScatterPlot, isAreaChart, isBarChart] = getChartType(datasets);
  const forecastChartContext = useContext(ForecastChartContext);

  const [xMin, setXMin] = useState(xmin);
  const [xMax, setXMax] = useState(xmax);

  useEffect(() => {
    setXMin(xmin);
    setXMax(xmax);
  }, [xmin, xmax]);

  const chartRef = useRef<ChartJS & { formatTooltipTitle?: ChartMobileProps['formatTooltipTitle'] }>(null);

  const { singleDataset, filteredDataIsGreyedOut } = datasetFilterSettings ?? {};
  const yAxisUnit = getYAxisUnit(singleDataset, datasets, selectedDatasetsOptions, yAxis);
  const yAxisTitle = getYAxisTitle(singleDataset, selectedDatasetsOptions, yAxis?.title);

  const { hoveredDatasetLabel } = useChartTooltip(
    datasets,
    chartRef,
    selectedDatasetsOptions,
    !filteredDataIsGreyedOut,
    isAreaChart,
    expanded,
    handleTooltipChange,
  );

  const chartData = useChartData(
    datasets,
    selectedDatasetsOptions,
    chartRef.current,
    !filteredDataIsGreyedOut,
    yAxisUnit,
    hoveredDatasetLabel,
    labels[xMin],
    labels[xMax],
    expanded,
  );

  const adjustedFixedYMax = useAdjustedYMax(datasets, labels[xMin], labels[xMax]);
  const { capYValueYAxis, capYValueY1Axis } = useCapYValue(datasets, labels[xmin], labels[xmax], yAxis, yAxis);

  const chartContainerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const chartContainerExpandedRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const [, updateChartArea] = useChartArea();
  //Disable zoom for first release
  //useGestures(labels.length - 1, chartContainerExpandedRef, xMin, xMax, setXMin, setXMax, xmin, xmax, chartArea);
  const tooltipPlugin = useTooltipPlugin(handleHoverDataPoint);

  const forecastStartDates = datasets
    .filter(
      (dataset) =>
        dataset.highlightOnHover &&
        (dataset.filterable === false || selectedDatasetsOptions.find((option) => option.label === dataset.label)),
    )
    .map((dataset) => new Date(dataset.data[0]?.x ?? new Date(labels[xmin])));
  const forecastIsDisplayed =
    forecastChartContext?.nonReliableForecast && forecastStartDates
      ? new Date(labels[xmax]) > new Date(min(forecastStartDates))
      : false;
  const xAxisHeight = chartRef.current?.scales.x?.height ?? 0;

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.formatTooltipTitle = formatTooltipTitle;
    }
  }, [chartRef, formatTooltipTitle]);

  useEffect(() => {
    setChartOptions({
      ...getChartOptions(
        {
          ...xAxis,
          linear: isScatterPlot,
          offset: isBarChart,
          fontSize: 10,
          min: labels[xMin],
          max: labels[xMax],
        },
        {
          ...yAxis,
          unit: yAxisUnit,
          fontSize: 10,
          title: '',
          fixedWidth: 45,
          fixedMin: capYValueYAxis.min ?? yAxis?.fixedMin,
          fixedMax: adjustedFixedYMax ?? capYValueYAxis.max ?? yAxis?.fixedMax,
        },
        {
          ...y1Axis,
          fontSize: 10,
          title: '',
          fixedWidth: 45,
          display: !!y1Axis && y1Axis.display !== false,
          fixedMin: capYValueY1Axis.min ?? y1Axis?.fixedMin,
          fixedMax: capYValueY1Axis.max ?? y1Axis?.fixedMax,
        },
        {
          displayMobileTooltip: expanded,
          timeGranularity: xAxis?.granularity,
        },
        {
          layout: {
            padding: {
              top: 32,
              right: 16,
              left: 16,
            },
          },
        },
      ),
      responsive,
      onResize: ({ chartArea }) => {
        updateChartArea(chartArea);
      },
      animation: {
        onProgress() {
          updateChartArea(this.chartArea);
        },
      },
    });
  }, [
    expanded,
    isAreaChart,
    isBarChart,
    isScatterPlot,
    labels,
    responsive,
    chartRef,
    xAxis,
    xMax,
    xMin,
    yAxis,
    yAxisTitle,
    yAxisUnit,
    adjustedFixedYMax,
    updateChartArea,
    formatTooltipTitle,
    y1Axis,
    capYValueYAxis.min,
    capYValueYAxis.max,
    capYValueY1Axis.min,
    capYValueY1Axis.max,
  ]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.formatTooltipTitle = formatTooltipTitle;
    }
  }, [chartRef, formatTooltipTitle]);

  return (
    <div className='Chart-content-wrapper'>
      {badgeContent && (
        <div className='Chart-badge-container'>
          <Typography variant='text2'>{badgeContent}</Typography>
        </div>
      )}
      <div className='Chart-canvasContainer' ref={expanded ? chartContainerExpandedRef : chartContainerRef}>
        <Chart
          type='line'
          data={{ datasets: chartData.datasets, labels }}
          options={chartOptions}
          ref={chartRef}
          data-testid='chart'
          plugins={[tooltipPlugin]}
          onClick={onClick}
        />
        {forecastWarning && forecastIsDisplayed && (
          <div className='missing-data-button-wrapper' style={{ bottom: xAxisHeight + (expanded ? 45 : 8) }}>
            {forecastWarning}
          </div>
        )}
      </div>
      {xAxis?.title && (
        <Typography variant='labelAxis' className={!expanded ? 'mobileChartAxisTitle' : undefined}>
          {xAxis?.title}
        </Typography>
      )}
    </div>
  );
};
