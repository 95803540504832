import type { FocusEventHandler, ChangeEventHandler, ReactElement, ClipboardEventHandler } from 'react';
import React from 'react';
import './FreeTextForm.css';
import classNames from 'classnames';
import { TextArea } from '../TextArea';

export type TextInputVariant =
  | 'hero'
  | 'title1'
  | 'title2'
  | 'text1'
  | 'text2'
  | 'text3'
  | 'label1'
  | 'label2'
  | 'label3';
export interface FreeTextFormProps {
  name?: string;
  textareaPlaceholder?: string;
  disabled?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  textareaValue?: string | number | null;
  titleValue?: string | number | null;
  rows?: number;
  autoHeight?: boolean;
  titleVariant?: TextInputVariant;
  titlePlaceholder?: string;
  isInvalid?: boolean;
  textareaVariant?: string;
  titleValidationError?: string;
  onInputChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onPaste?: ClipboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const FreeTextForm = ({
  name,
  textareaPlaceholder,
  disabled,
  autoComplete = '',
  autoFocus,
  titleValue,
  textareaValue,
  titleVariant,
  isInvalid,
  textareaVariant,
  titlePlaceholder,
  rows,
  autoHeight,
  titleValidationError,
  onChange,
  onInputChange,
  onClick,
  onBlur,
  onPaste,
  onKeyDown,
}: FreeTextFormProps): ReactElement => {
  return (
    <div className={'Free-text-container'}>
      <TextArea
        className={classNames('Free-text-textInput', titleVariant, isInvalid && 'Free-text-invalid')}
        role='textbox'
        name={name}
        placeholder={titlePlaceholder}
        onChange={onInputChange}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick();
        }}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        defaultValue={titleValue ?? undefined}
        onBlur={onBlur}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
        onWheel={(e) => e.currentTarget.blur()}
        id={name}
        rows={1}
      />
      {isInvalid && titleValidationError && <div className='Free-text-textInput-invalid'>{titleValidationError}</div>}
      <TextArea
        className={classNames('Free-text-textarea', textareaVariant)}
        role='textbox'
        name={name}
        placeholder={textareaPlaceholder}
        onChange={onChange}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick();
        }}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        defaultValue={textareaValue ?? undefined}
        onBlur={onBlur}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
        rows={rows}
        autoHeight={autoHeight}
        onWheel={(e) => e.currentTarget.blur()}
        id={name}
      />
    </div>
  );
};
