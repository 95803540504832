import { CommonValidator, validateRequestQuery } from '@/api/validators';
import { arrowRequest } from '@/lib/request/request';
import { getAimHeaders } from '@/lib/utils';
import type { NextApiRequest, NextApiResponse } from 'next';
import { z } from 'zod';
import { getDimension } from '../utils';
import { createTimeGranularityTuple } from '@/components/insights/charts/common/types';
import { handleApiError } from '@/lib/request/error';

export enum ActiveCustomerRetentionMetric {
  RetentionRate = 'metric-1',
  ChurnRate = 'metric-2',
}

export const ACTIVE_CUSTOMER_RETENTION_GROWTH_METRIC = [
  'yearOverYear',
  'quarterOverQuarter',
  'monthOverMonth',
] as const;
export type ActiveCustomerRetentionGrowthMetric = (typeof ACTIVE_CUSTOMER_RETENTION_GROWTH_METRIC)[number];

export const ACTIVE_CUSTOMER_RETENTION_TIME_GRANULARITIES = createTimeGranularityTuple('quarters', 'months', 'days');
export type ActiveCustomerRetentionTimeGranularity = (typeof ACTIVE_CUSTOMER_RETENTION_TIME_GRANULARITIES)[number];

const activeCustomerRetentionQuerySchema = z.object({
  countryCode: CommonValidator.countryCode,
  timeGranularity: z.enum(ACTIVE_CUSTOMER_RETENTION_TIME_GRANULARITIES),
});

type YearOverYearRowData = {
  retained_active_customers_since_prev_year: bigint | null;
  churned_active_customers_since_prev_year: bigint | null;
  customer_retention_rate_year_over_year: number | null;
  customer_churn_rate_year_over_year: number | null;
};

type MonthOverMonthRowData = {
  retained_active_customers_since_prev_month: bigint | null;
  churned_active_customers_since_prev_month: bigint | null;
  customer_retention_rate_month_over_month: number | null;
  customer_churn_rate_month_over_month: number | null;
};

type QuarterOverQuarterRowData = {
  retained_active_customers_since_prev_quarter: bigint | null;
  churned_active_customers_since_prev_quarter: bigint | null;
  customer_retention_rate_quarter_over_quarter: number | null;
  customer_churn_rate_quarter_over_quarter: number | null;
};

type ActiveCustomerRetentionArrowRow = YearOverYearRowData &
  (
    | ({ date_day: number } & MonthOverMonthRowData)
    | ({ date_month: number } & MonthOverMonthRowData)
    | ({ date_quarter: number } & QuarterOverQuarterRowData)
  );

type ActiveCustomerRetentionDataPoint = {
  date: string;
  rate: number | null;
  amount: number | null;
};

type ActiveCustomerRetentionResponseMetric = {
  yearOverYear: ActiveCustomerRetentionDataPoint[];
  yearOverYearRef: ActiveCustomerRetentionDataPoint[];
  monthOverMonth: ActiveCustomerRetentionDataPoint[];
  monthOverMonthRef: ActiveCustomerRetentionDataPoint[];
  quarterOverQuarter: ActiveCustomerRetentionDataPoint[];
  quarterOverQuarterRef: ActiveCustomerRetentionDataPoint[];
};

export interface ActiveCustomerRetentionResponse {
  meta: { displayUnit: string; xMin: string; xMax: string };
  metrics: {
    retained: ActiveCustomerRetentionResponseMetric;
    churned: ActiveCustomerRetentionResponseMetric;
  };
}

const getRowTimestamp = (row: ActiveCustomerRetentionArrowRow) => {
  if ('date_quarter' in row) return row.date_quarter;
  if ('date_month' in row) return row.date_month;
  return row.date_day;
};

const timeGranularityMap = {
  days: 'date_day',
  months: 'date_month',
  quarters: 'date_quarter',
} as const satisfies Record<(typeof ACTIVE_CUSTOMER_RETENTION_TIME_GRANULARITIES)[number], string>;

const convertToPercentage = (value: number | null) => {
  if (value === null) return null;
  return Number(value) * 100;
};

export default async function handler(req: NextApiRequest, res: NextApiResponse): Promise<void> {
  const { projectCode } = getAimHeaders(req.headers);

  const { parsedQuery } = validateRequestQuery(activeCustomerRetentionQuerySchema, req);
  if (!parsedQuery.success) return parsedQuery.sendInvalidQueryResponse(res);

  const { countryCode, timeGranularity } = parsedQuery.data;

  const url = new URL(
    `${process.env.NEXT_PUBLIC_DATA_EXTRACTOR_URL}/tenants/${projectCode}/insights/active-customer-retention`,
  );

  const urlReference = new URL(
    `${process.env.NEXT_PUBLIC_DATA_EXTRACTOR_URL}/tenants/${projectCode}/insights/active-customer-retention`,
  );
  urlReference.searchParams.set('country_code', 'ALL');
  urlReference.searchParams.set('granularity', timeGranularity);

  url.searchParams.set('country_code', countryCode);
  url.searchParams.set('granularity', timeGranularity);

  try {
    const table = await arrowRequest(url.toString());

    const tableArray = table.toArray();

    const response: ActiveCustomerRetentionResponse = {
      meta: {
        displayUnit: '%',
        ...getDimension(table, timeGranularityMap[timeGranularity]),
      },
      metrics: {
        retained: {
          yearOverYear: [],
          yearOverYearRef: [],
          monthOverMonth: [],
          monthOverMonthRef: [],
          quarterOverQuarter: [],
          quarterOverQuarterRef: [],
        },
        churned: {
          yearOverYear: [],
          yearOverYearRef: [],
          monthOverMonth: [],
          monthOverMonthRef: [],
          quarterOverQuarter: [],
          quarterOverQuarterRef: [],
        },
      },
    };

    tableArray.forEach((row: ActiveCustomerRetentionArrowRow) => {
      const date = new Date(getRowTimestamp(row)).toISOString();

      response.metrics.retained.yearOverYear.push({
        date,
        rate: convertToPercentage(row.customer_retention_rate_year_over_year),
        amount: Number(row.retained_active_customers_since_prev_year),
      });

      response.metrics.churned.yearOverYear.push({
        date,
        rate: convertToPercentage(row.customer_churn_rate_year_over_year),
        amount: Number(row.churned_active_customers_since_prev_year),
      });

      if ('date_quarter' in row) {
        response.metrics.retained.quarterOverQuarter.push({
          date,
          rate: convertToPercentage(row.customer_retention_rate_quarter_over_quarter),
          amount: Number(row.retained_active_customers_since_prev_quarter),
        });

        response.metrics.churned.quarterOverQuarter.push({
          date,
          rate: convertToPercentage(row.customer_churn_rate_quarter_over_quarter),
          amount: Number(row.churned_active_customers_since_prev_quarter),
        });
      }

      if ('date_month' in row || 'date_day' in row) {
        response.metrics.retained.monthOverMonth.push({
          date,
          rate: convertToPercentage(row.customer_retention_rate_month_over_month),
          amount: Number(row.retained_active_customers_since_prev_month),
        });

        response.metrics.churned.monthOverMonth.push({
          date,
          rate: convertToPercentage(row.customer_churn_rate_month_over_month),
          amount: Number(row.churned_active_customers_since_prev_month),
        });
      }
    });

    const tableReference = await arrowRequest(urlReference.toString());
    tableReference.toArray().forEach((row: ActiveCustomerRetentionArrowRow) => {
      const date = new Date(getRowTimestamp(row)).toISOString();

      response.metrics.retained.yearOverYearRef.push({
        date,
        rate: convertToPercentage(row.customer_retention_rate_year_over_year),
        amount: Number(row.retained_active_customers_since_prev_year),
      });

      response.metrics.churned.yearOverYearRef.push({
        date,
        rate: convertToPercentage(row.customer_churn_rate_year_over_year),
        amount: Number(row.churned_active_customers_since_prev_year),
      });

      if ('date_quarter' in row) {
        response.metrics.retained.quarterOverQuarterRef.push({
          date,
          rate: convertToPercentage(row.customer_retention_rate_quarter_over_quarter),
          amount: Number(row.retained_active_customers_since_prev_quarter),
        });

        response.metrics.churned.quarterOverQuarterRef.push({
          date,
          rate: convertToPercentage(row.customer_churn_rate_quarter_over_quarter),
          amount: Number(row.churned_active_customers_since_prev_quarter),
        });
      }

      if ('date_month' in row || 'date_day' in row) {
        response.metrics.retained.monthOverMonthRef.push({
          date,
          rate: convertToPercentage(row.customer_retention_rate_month_over_month),
          amount: Number(row.retained_active_customers_since_prev_month),
        });

        response.metrics.churned.monthOverMonthRef.push({
          date,
          rate: convertToPercentage(row.customer_churn_rate_month_over_month),
          amount: Number(row.churned_active_customers_since_prev_month),
        });
      }
    });

    res.status(200).json(response);
  } catch (error) {
    handleApiError(res, error);
  }
}
