import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ComponentPropsWithoutRef,
  type ElementType,
  type PropsWithChildren,
} from 'react';
import cn from 'classnames';
import styles from './ScrollHint.module.css';
import { clamp } from 'aim-utils';

type ScrollHintProps<TElementProp extends ElementType> = PropsWithChildren<
  ComponentPropsWithoutRef<TElementProp> & {
    as?: TElementProp;
  }
>;

const SCROLL_HINT_MAX_SIZE_PX = 32;

export const ScrollHint = <TElementProp extends ElementType>({
  as,
  className,
  children,
  ...props
}: ScrollHintProps<TElementProp>) => {
  const elementRef = useRef(null);
  const Element = as ?? 'div';

  // * We initialize these as the max size, so the scroll hints don't suddenly appear, causing a flicker.
  const [showTopHint, setShowTopHint] = useState(SCROLL_HINT_MAX_SIZE_PX);
  const [showBottomHint, setShowBottomHint] = useState(SCROLL_HINT_MAX_SIZE_PX);

  const calculateScrollHints = useCallback((el: Element) => {
    const distanceToTop = el.scrollTop;
    const distanceToBottom = el.scrollHeight - el.clientHeight - el.scrollTop;

    const topHint = Math.round(clamp({ value: distanceToTop, min: 0, max: SCROLL_HINT_MAX_SIZE_PX }));
    const bottomHint = Math.round(clamp({ value: distanceToBottom, min: 0, max: SCROLL_HINT_MAX_SIZE_PX }));

    setShowTopHint(topHint);
    setShowBottomHint(bottomHint);
  }, []);

  useEffect(() => {
    const element = elementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver(([element]) => {
      calculateScrollHints(element.target);
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, [calculateScrollHints]);

  return (
    <Element
      ref={elementRef}
      style={{
        ['--scroll-hint-top' as string]: `${showTopHint}px`,
        ['--scroll-hint-bottom' as string]: `${showBottomHint}px`,
      }}
      className={cn(className, styles.scrollHint)}
      onScroll={({ currentTarget }) => calculateScrollHints(currentTarget)}
      {...props}
    >
      {children}
    </Element>
  );
};
