import React, { useCallback, useState } from 'react';
import { MobileFullScreenModal } from '../MobileFullModal/MobileFullModal';
import { TextInput } from '../Input';
import { Icon } from '../Icon';
import ConnectorAdd from './ConnectorList/ConnectorAdd';
import type { Connector } from './types';
import { ConnectorSearchList } from '../';

interface ConnectorSearchModalProps {
  connectors: Connector[];
  showModal: boolean;
  showAddOption?: boolean;
  placeholder: string;
  closeModal: () => void;
  onCheckboxChange: (connector: Connector) => void;
  handleAddConnector?: (searchString: string) => void;
}

const ConnectorSearchModal = ({
  showModal,
  connectors,
  showAddOption,
  placeholder,
  closeModal,
  onCheckboxChange,
  handleAddConnector,
}: ConnectorSearchModalProps) => {
  const [searchString, setSearchString] = useState('');

  const onSelectConnector = useCallback(
    (connector: Connector) => {
      onCheckboxChange(connector);
      closeModal();
      setSearchString('');
    },
    [closeModal, onCheckboxChange],
  );

  return (
    <MobileFullScreenModal
      onBack={closeModal}
      underlinedTitle
      title={
        <TextInput
          placeholder={placeholder}
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
          icon={<Icon icon='search' />}
          autoFocus
          noStyling
        />
      }
      open={showModal}
    >
      {searchString.length > 0 && showAddOption && (
        <ConnectorAdd
          searchString={searchString}
          handleAddConnector={(searchString) => {
            handleAddConnector && handleAddConnector(searchString);
            closeModal();
          }}
        />
      )}
      <ConnectorSearchList onCheckboxChange={onSelectConnector} connectors={connectors} searchString={searchString} />
    </MobileFullScreenModal>
  );
};

export default ConnectorSearchModal;
