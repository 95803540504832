import React, { useMemo } from 'react';
import type { D3LineChartConfig } from '../../types';

interface XAxisProps {
  width: number;
  height: number;
  xScale: d3.ScalePoint<string>;
  chartConfig: D3LineChartConfig;
  labels?: string[];
}

interface YAxisProps {
  width: number;
  yScale: d3.ScaleLinear<number, number, never>;
  unit: string;
}

export const XAxis = ({ width, height, xScale, chartConfig, labels }: XAxisProps) => {
  const ticks = useMemo(() => {
    if (labels && xScale) {
      return labels.map((label) => ({
        label,
        xOffset: xScale(label),
      }));
    }
  }, [labels, xScale]);
  return (
    <g transform={`translate(0, ${height})`}>
      {ticks && chartConfig?.onlyStartAndEndLabel ? (
        <>
          <AxisLabel text={new Date(ticks[0].label).getUTCFullYear()} transform='translateY(20px)' textAnchor='start' />
          <g transform={`translate(${width}, 0)`}>
            <AxisLabel text='Today' transform='translateY(20px)' textAnchor='end' />
          </g>
        </>
      ) : (
        ticks?.map(({ label, xOffset }) => (
          <g key={label} transform={`translate(${xOffset}, 0)`}>
            <AxisLabel text={label} transform='translateY(20px)' key={label} textAnchor='middle' />
          </g>
        ))
      )}
    </g>
  );
};

export const YAxis = ({ width, yScale, unit }: YAxisProps) => {
  const ticks = useMemo(() => {
    if (yScale) {
      const pixelsPerTick = 200;
      const numberOfTicksTarget = Math.max(3, Math.floor(width / pixelsPerTick));
      return yScale.ticks(numberOfTicksTarget).map((value) => ({
        value,
        xOffset: yScale(value),
      }));
    }
  }, [width, yScale]);

  return (
    <g>
      {ticks?.map(({ value, xOffset }) => (
        <g key={value} transform={`translate(0, ${xOffset})`}>
          <path d={`M 0 0 H ${width}`} fill='none' stroke='currentColor' strokeWidth={0.3} strokeDasharray={3} />
          <AxisLabel text={`${value} ${unit}`} transform='translateX(-2px)' key={value} textAnchor='end' />
        </g>
      ))}
    </g>
  );
};

const AxisLabel = ({
  text,
  transform,
  textAnchor,
}: {
  text: string | number;
  transform: string;
  textAnchor: 'middle' | 'end' | 'start';
}) => {
  return (
    <text
      className='d3AxisLabel'
      style={{
        transform: transform,
        textAnchor: textAnchor,
      }}
    >
      {text}
    </text>
  );
};
