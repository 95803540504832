import type { ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';

import './Typography.css';

type TypographyVariant =
  | 'hero'
  | 'bigNumber'
  | 'title1'
  | 'title2'
  | 'subtitle'
  | 'label1'
  | 'label2'
  | 'label3'
  | 'labelCaps'
  | 'labelAxis'
  | 'text1'
  | 'text2'
  | 'text3';

export type TypographyColor = 'dark' | 'critical' | 'secondary' | 'primaryBlue' | 'primary';

export interface TypographyProps {
  children: ReactNode;
  variant: TypographyVariant;
  className?: string;
  color?: TypographyColor;
  truncate?: boolean;
  onClick?: () => void;
  as?: ElementType;
}

type ElementType = 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'label' | 'span';

const typographyVariantMap: Record<TypographyVariant, ElementType> = {
  hero: 'h1',
  bigNumber: 'p',
  title1: 'h1',
  title2: 'h2',
  subtitle: 'h4',
  label1: 'label',
  label2: 'label',
  label3: 'label',
  labelCaps: 'label',
  labelAxis: 'label',
  text1: 'p',
  text2: 'p',
  text3: 'p',
} as const;

export function Typography({ children, variant, className, color, truncate, onClick, as }: TypographyProps) {
  const TextElement = as ?? typographyVariantMap[variant];

  return (
    <TextElement className={cn('Typography', variant, className, color, truncate && 'truncate')} onClick={onClick}>
      {children}
    </TextElement>
  );
}
