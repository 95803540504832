import React from 'react';
import { DeltaArrow } from './DeltaArrow';
import { DeltaLabel } from './DeltaLabel';

interface DeltaIndicatorProps {
  score: number;
  delta: number;
  innerArcRadius: number;
  outerArcRadius: number;
  svgWidth: number;
  svgHeight: number;
  delay: number;
  deltaLabelCurrent?: string;
  deltaLabelPrev?: string;
  displayTicks?: boolean;
}

export const DeltaIndicator = ({
  score,
  innerArcRadius,
  outerArcRadius,
  delta,
  svgWidth,
  svgHeight,
  delay,
  deltaLabelCurrent = '',
  deltaLabelPrev = '',
  displayTicks = false,
}: DeltaIndicatorProps) => {
  return (
    <>
      {delta !== 0 && (
        <>
          <DeltaArrow
            score={score}
            delta={delta}
            innerArcRadius={innerArcRadius - (displayTicks ? 20 : 0)}
            svgHeight={svgHeight}
            svgWidth={svgWidth}
            delay={delay}
          />
          <DeltaLabel
            score={score}
            delta={delta}
            deltaLabelPrev={deltaLabelPrev}
            deltaLabelCurrent={deltaLabelCurrent}
            innerArcRadius={innerArcRadius}
            outerArcRadius={outerArcRadius}
            svgHeight={svgHeight}
            svgWidth={svgWidth}
            delay={delay}
          />
        </>
      )}
    </>
  );
};
