import { Button, toast } from 'aim-components';
import { useTranslation } from 'next-i18next';
import { useCallback, useRef, useState } from 'react';
import { deleteAllUserSettings } from '@/lib/settings';
import { ProfileRow } from '../profile-settings/ProfileRow';
import { useRouter } from 'next/router';
import { TOASTER_DURATION } from 'aim-components/src/components/Toaster/Toaster';

export const ClearUserSettings = () => {
  const router = useRouter();
  const { t } = useTranslation('settings', { keyPrefix: 'profileComponent.clearUserSettings' });
  const [isProcessing, setIsProcessing] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const clearUserSettings = useCallback(async () => {
    setIsProcessing(true);
    localStorage.clear();
    const deleteAllUserSettingsResponse = await deleteAllUserSettings();

    if (deleteAllUserSettingsResponse.success) {
      toast({ message: t('toaster_success'), type: 'success' });
      timeoutRef.current = setTimeout(() => router.reload(), TOASTER_DURATION);
    } else {
      toast({ message: t('toaster_error'), type: 'critical' });
      setIsProcessing(false);
    }

    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [t, router]);

  return (
    <ProfileRow title={t('title')} subTitle={t('subtitle')}>
      <Button onClick={clearUserSettings} type='secondary' loading={isProcessing} color='white'>
        {t('button')}
      </Button>
    </ProfileRow>
  );
};
