import { useRef, useState, useEffect } from 'react';

interface UseIntersectionObserverProps {
  margin?: IntersectionObserverInit['rootMargin'];
  threshold?: IntersectionObserverInit['threshold'];
}

export const useIntersectionObserver = (
  { margin, threshold }: UseIntersectionObserverProps = {
    margin: '250px',
    threshold: 0,
  },
) => {
  const intersectionRef = useRef<HTMLDivElement>(null);
  const [isIntersecting, setIntersecting] = useState(false);
  const [hasIntersected, setHasIntersected] = useState(false);

  if (isIntersecting && !hasIntersected) {
    setHasIntersected(true);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
      rootMargin: margin,
      threshold,
    });

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => observer.disconnect();
  }, [margin, threshold, intersectionRef]);

  return [intersectionRef, hasIntersected, isIntersecting] as const;
};
