/* eslint-disable no-console */
import {
  NumericAxis,
  RolloverModifier,
  SciChartSurface,
  SmoothStackedMountainRenderableSeries,
  StackedMountainCollection,
  XyDataSeries,
} from 'scichart';
import React, { useEffect } from 'react';

const generateXValues = (start: number, numberOfValues: number) => {
  const values: number[] = [];

  for (let i = 0; i < numberOfValues; i++) {
    values.push(start + i);
  }

  return values;
};

const generateYValues = (numberOfValues: number) => {
  const values: number[] = [];

  for (let i = 0; i < numberOfValues; i++) {
    values.push(Math.random() * 100);
  }

  return values;
};

const numberOfSeries = 50;
const numberOfValues = 50;

const scichartId = 'scichart-id';

// Minimal example trying to use a `StackedMountainCollection` together with `SciChartOverview` – which does not seem to be supported.
export const RolloverPerformanceTestRaw = () => {
  useEffect(() => {
    const initChart = async () => {
      const { sciChartSurface, wasmContext } = await SciChartSurface.create(scichartId);

      const xAxis = new NumericAxis(wasmContext);
      const yAxis = new NumericAxis(wasmContext);

      sciChartSurface.xAxes.add(xAxis);
      sciChartSurface.yAxes.add(yAxis);

      const rollover = new RolloverModifier({
        showTooltip: false,
      });

      sciChartSurface.chartModifiers.add(rollover);

      const xValues = generateXValues(1992, numberOfValues);

      const allSeries: SmoothStackedMountainRenderableSeries[] = [];

      for (let i = 0; i < numberOfSeries; i++) {
        const yValues = generateYValues(numberOfValues);

        const dataSeries = new XyDataSeries(wasmContext, {
          xValues,
          yValues,
        });

        const series = new SmoothStackedMountainRenderableSeries(wasmContext, {
          fill: i % 2 ? '#882B91' : '#bbaadd',
          dataSeries,
        });

        allSeries.push(series);
      }

      const collection = new StackedMountainCollection(wasmContext);
      collection.add(...allSeries);

      sciChartSurface.renderableSeries.add(collection);
    };

    initChart();
  }, []);

  return <div id={scichartId} style={{ width: 800, height: 400 }} />;
};
