import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import { Typography } from '../../Typography';
import './ChartSidebar.css';
import cn from 'classnames';

type SidebarVerticalContentGap = 'normal' | 'condensed';

export interface ChartSidebarProps {
  title?: string;
  opened?: boolean;
  children?: ReactNode;
  onTransitionEnd?: () => void;
  verticalContentGap?: SidebarVerticalContentGap;
  overlay?: boolean;
}

export const ChartSidebar = ({
  title,
  opened,
  children,
  onTransitionEnd,
  verticalContentGap = 'normal',
  overlay = false,
}: ChartSidebarProps): ReactElement<ChartSidebarProps> => {
  return (
    <div
      className={cn('ChartSidebar', { overlay })}
      data-state={opened ? 'opened' : 'closed'}
      data-vertical-content-gap={verticalContentGap}
      onTransitionEnd={onTransitionEnd}
    >
      <div className='ChartSidebar-content-padding'>
        <Typography variant='label2' className='title'>
          {title}
        </Typography>
        <div className='ChartSidebar-scrollable-content'>{children}</div>
      </div>
    </div>
  );
};
